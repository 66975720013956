export function validateAndReturnDivisionNumber(number: number) {
  if (number > 1000000) {
    return 1000000;
  }
  if (number < 1000000 && number >= 1000) {
    return 1000;
  }
  return 1;
}

export function validateAndReturnAbreviation(number: number) {
  if (number > 1000000) {
    return " m";
  }
  if (number < 1000000 && number >= 1000) {
    return " k";
  }
  if (number < 1000) {
    return "";
  }
}
