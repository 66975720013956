import { CountryCode, CountryData } from "configuration/languages"
import { ProgressMessage } from "redux/Connector/types"
import { AsyncStatus } from "redux/types"

export interface AppNavigationState {
    selectedCountryData?: CountryData,
    language?: CountryCode,
    loadingAppComponent: LoadingAppComponentDictionary
    loadingScenarioComponent: LoadingScenarioComponentDictionary
    loadingState: LoadingState
    loadingStateCounter: LoadingStateCounter
    isHidden: boolean
    userToken: string | null
    isTokenExpired: boolean
    modalRender: ModalRender
    pipeline: {
        pipelineProgress: ProgressMessage
        isPipelineRunning: boolean
    }
    permissionContexts: any
}

export enum LoadingAppComponent {
    Scenarios = 'Scenarios',
    CompleteStructures = 'CompleteStructures',
    UserFlatData = 'UserFlatData',
    TaskDescriptions = 'TaskDescriptions',
    PermissionContexts = 'PermissionContexts',
    ResourcePriorities = 'ResourcePriorities',
    Resources = 'Resources',
    DemandDataRange = 'DemandDataRange',
    ItemOperation = 'ItemOperation',
    ItemResource = 'ItemResource'
}

export enum LoadingScenarioComponent {
    PlanningGrids = 'PlanningGrids',
    Items = 'Items',
    Periods = 'Periods',
    ItemAttributesBundle = 'ItemAttributesBundle',
    PlanningGridAllocations = 'PlanningGridAllocations',
    ResourceGroup = 'ResourceGroup',
    PlanningGridResources = 'PlanningGridResources',
    Capacities = 'Capacities',
    ScenarioSummarized = 'ScenarioSummarized',
}

export type LoadingAppComponentDictionary = {
    [Key in LoadingAppComponent]: AsyncStatus
}

export type LoadingScenarioComponentDictionary = {
    [Key in LoadingScenarioComponent]: AsyncStatus
}

export enum LoadingStateColor {
    Yellow = 'Yellow',//Load Pre Scenario selecionado
    Red = 'Red',
    Blue = 'Blue'
}
export type LoadingStateCounter = {
    [Key in LoadingStateColor]: LoadingStateData
}
export interface LoadingStateData {
    loadingCount: number
    loadingTarget: number
}
export interface LoadingState extends LoadingStateData {
    isVisible: boolean
}
export interface ModalRender {
    modalType: ModalType
    data: any
    callback?: () => void
}
export enum ModalType {
    Closed = 'Closed',
    QuestionModal = 'QuestionModal',
    MultiData = 'InsertData',
    ScriptCreateModal = 'ScriptCreateModal',
    TaskCreateModal = 'TaskCreateModal',
    TaskEditModal = 'TaskEditModal',
    ScriptButtonCreateModal = 'ScriptButtonCreateModal',
    ScenarioEditModal = 'ScenarioEditModal',
    ScenarioCreateModal = 'ScenarioCreateModal',
    ScenarioCopyModal = 'ScenarioCopyModal'
}