import { Trans } from '@lingui/macro'
import NeoSwitch from 'components/NeoSwitch'
import IconButton from 'pages/PlanningPage/components/PlanningGridPanel/components/IconButton'
import { AiFillPlayCircle, AiFillPauseCircle } from 'react-icons/ai'
import { BiReset } from 'react-icons/bi'
import { ImStatsBars2 } from 'react-icons/im'
import { resetPlanningPageState, applyOccupationConstraint } from 'redux/AppScenarioState'
import { ModuleSwitchProps, ModuleAnimationProps } from '.'
import { TippyComponent } from 'pages/PlanningPage/components/PlanningGrid/components/TippyComponent'
import { ToolTipOccupationPageOptions } from 'api/data/enums'
import { useLingui } from '@lingui/react'


export function ModuleSwitchOccupationPage({ firstText, localToggle, secondText, checked }: ModuleSwitchProps) {
    return (
        <>
            <p className="inline-block">{firstText}</p>
            <NeoSwitch className="inline-block" onChange={() => { localToggle() }} checked={checked} />
            <p className="inline-block">{secondText}</p>
        </>
    )
}


export function ModuleAnimationOccupationPage(props: ModuleAnimationProps) {
    const { i18n } = useLingui()
    const { dispatch, localApplyOccupationConstraintAnimation, localStopOccupationConstraintAnimation, periodIndexRef } = props
    return (
        <>
            <div className='h-full self-end pr-8 space-y-2 items-center'>
                <TippyComponent tooltipText={i18n.t(ToolTipOccupationPageOptions.reset)}>
                    <IconButton icon={BiReset} onClick={() => {
                        dispatch(resetPlanningPageState());
                        periodIndexRef.current = 0;
                    }} />
                </TippyComponent>
                <TippyComponent tooltipText={i18n.t(ToolTipOccupationPageOptions.calculateWithoutAnimation)}>
                    <IconButton icon={ImStatsBars2} onClick={() => dispatch(applyOccupationConstraint())} />
                </TippyComponent>
                <div className='flex align-middle gap-1'>
                    <TippyComponent tooltipText={i18n.t(ToolTipOccupationPageOptions.calculateWithAnimation)}>
                        <IconButton icon={AiFillPlayCircle} onClick={() => localApplyOccupationConstraintAnimation()} />
                    </TippyComponent>
                    <TippyComponent tooltipText={i18n.t(ToolTipOccupationPageOptions.pauseAnimation)}>
                        <IconButton fullIcon icon={AiFillPauseCircle} onClick={() => localStopOccupationConstraintAnimation()} />
                    </TippyComponent>
                </div>
            </div>
        </>
    )
}