import { AppStockState } from "./types";
import { stockPolicyAxesTypeToLanguage, stockPolicyCompanyOptionsToLanguage } from "locales/helper";
import { i18n } from "@lingui/core";
import { SelectCompanyMenuProps, StockPolicyAxesType } from "api/data/enums";

export const initialState: AppStockState = {
    isRecalculating: false,
    selectedMainFilter: {
        id: 0,
        name: () => stockPolicyCompanyOptionsToLanguage(SelectCompanyMenuProps.All),
    },
    selectedSecondaryFilter: {
        id: 0,
        code: i18n.t(`Todos`),
        name: i18n.t(`Todos`),
    },
    stockPolicyAxes: {
        filter: () => stockPolicyAxesTypeToLanguage(StockPolicyAxesType.Uncertainty),
        xAxis: () => stockPolicyAxesTypeToLanguage(StockPolicyAxesType.Complexity),
        yAxis: () => stockPolicyAxesTypeToLanguage(StockPolicyAxesType.Volume),
    },
    stockFilterOptions: [
        { name: i18n.t(`P - Incerteza Baixa`), id: 3, code: i18n.t(`P`) },
        { name: i18n.t(`M - Incerteza Média`), id: 2, code: i18n.t(`M`) },
        { name: i18n.t(`G - Incerteza Alta`), id: 1, code: i18n.t(`G`) },
    ],
    stockEditableTableValidation: {
        isEditable: false,
    },
    segmentationData: {
        data: [],
    },
    stockInventory: {
        data: [],
    },
    stockStrategyOptions: [],
    supplyStrategyEditedObjects: [],
    showingItemsRedux: {
        segmentationShowingItems: [],
        summaryShowingItems: [],
    },
    uniqueScenarios: [],
    selectedScenario: {
        name: "",
        createdOn: "",
        id: 0,
    },
    selectedTimestamp: "S00",
    uniqueTimestamps: [],
    selectedGraphLabel: "",
    scenarioStatusOptions: [],
    inventoryHealthFilteredByCompany: [],
    scenariosOptions: [],
    SelectCompanyMenu: {
        CompanyOptions: []
    }
};
