import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { forceExecuteTaskByTypeWithBodyAsync } from 'api/scheduler/lib/task';
import NeoActionButton from 'components/Buttons/NeoActionButton';
import nPlanModalRender from 'components/Modals';
import { useCallback } from 'react'
import { GiSave } from 'react-icons/gi';
import { saveAsScenarioAsync, savePlanningGridAsync } from 'redux/AppScenarioState';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { notifyPromise } from 'utils/notification';


function SaveScenarioContainer() {
    const dispatch = useAppDispatch();
    const selectedScenarioId = useAppSelector(state => state.appScenarioState.selectedScenarioId)
    const hasSelectedScenario = selectedScenarioId !== undefined;
    const { i18n } = useLingui();

    const onSaveScenario = useCallback((scenarioId: number) => {
        nPlanModalRender.question({
            headerText: i18n.t(`Deseja Salvar o Cenário Atual?`),
            acceptButtonText: i18n.t(`Confirmar`),
            cancelButtonText: i18n.t(`Cancelar`),
            acceptCallbackAsync: async () => {
                notifyPromise(
                    Promise.resolve(dispatch(savePlanningGridAsync({}))).then(() => {
                        notifyPromise(
                            Promise.resolve(forceExecuteTaskByTypeWithBodyAsync({
                                queryParams: {
                                    taskType: "ExecProcedure",
                                },
                                body: {
                                    order: 0,
                                    data: {
                                        "Stored Procedure": "NTransform.fn_EvaluateSavedScenario",
                                        "scenario": `${scenarioId}`
                                    },
                                },
                            })),
                            {
                                pending: i18n.t(`Disparando Procedimento`),
                                success: i18n.t(`Recalculando Cenário, Aguarde!`),
                                error: i18n.t(`Erro ao Disparar Procedimento!`)
                            })
                    }),
                    {
                        pending: i18n.t(`Salvando Cenário Atual`),
                        success: i18n.t(`Cenário Salvo!`),
                        error: i18n.t(`Erro ao Salvar Cenário!`)
                    }
                )
            }
        });
    }, [])

    const onSaveAsScenario = useCallback((scenarioId: number) => {
        nPlanModalRender.multiData({
            headerText: i18n.t(`Deseja Salvar o Cenário Atual?`),
            acceptButtonText: i18n.t(`Confirmar`),
            cancelButtonText: i18n.t(`Cancelar`),
            multiDataInputs: [
                {
                    dataType: 'text',
                    header: i18n.t('Nome do Cenário'),
                    name: 'scenarioName',
                    placeholder: i18n.t('Nome do Cenário')
                }
            ],
            sendCallbackAsync: async (data) => {
                if (!data['scenarioName'].value) return;
                notifyPromise(
                    Promise.resolve(dispatch(saveAsScenarioAsync({ scenarioName: data['scenarioName'].value }))),
                    {
                        pending: i18n.t(`Salvando Cenário Atual`),
                        success: i18n.t(`Cenário Salvo!`),
                        error: i18n.t(`Erro ao Salvar Cenário!`)
                    }
                )
            }
        });
    }, [])

    if (!hasSelectedScenario) return <></>
    return (
        <div className='flex min-w-min w-auto h-full group'>
            <GiSave className='place-self-center w-8 h-8 text-yellow-500'></GiSave>
            <div className='flex invisible group-hover:visible'>
                <NeoActionButton className='h-1/2 place-self-center' onClick={() => onSaveScenario(selectedScenarioId)}><Trans>Salvar</Trans></NeoActionButton>
                <NeoActionButton className='h-1/2 place-self-center' onClick={() => onSaveAsScenario(selectedScenarioId)}><Trans>Salvar Como</Trans></NeoActionButton>
            </div>
        </div>
    )
}

export default SaveScenarioContainer
