import { Button, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import React from 'react'
import axios from 'axios'
import { dataUrl } from 'api/configuration';
import Manage from './components/Manage';
import defaultDashIMG from 'assets/images/dashdefault.png'
import { confirmAlert } from 'react-confirm-alert';
import { notifySuccess } from 'utils/notification';
export default function(props) {
    const [analytics, setAnalytics] = React.useState<any>([]);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [currentIdEditing, setCurrentIdEditing] = React.useState(0);
    React.useEffect(() => {
        refreshData()
    }, [props.user])
    function refreshData() {
        axios.get("analyticlink", { baseURL: dataUrl}).then(({data}) => {
            if(data.type === "success") {
                setAnalytics(data.analyticLinks)
            }
        })
    }
    function handleDelete(id) {
        confirmAlert({
            title: ``,
            message: `Deseja deletar o relatório?`,
            buttons: [
                {
                    label: "Sim",
                    onClick: () => {
                        axios.delete(`analyticlink/${id}`,{baseURL: dataUrl}).then(({data}) => {
                            if(data.type === "success") {
                                notifySuccess({message: data.message });
                                refreshData()
                            }
                                
                        })
                    }
                },
                {
                    label: "Não",
                    onClick: () => { }
                }
            ]
        });
    }
    return (
        <React.Fragment>
            <Manage currentIdEditing={currentIdEditing} open={modalOpen} close={() => { setModalOpen(false); setCurrentIdEditing(0); refreshData(); } } />
            <h2 style={{fontWeight: "bold", textAlign: "center", fontSize: "18px", padding: "20px"}}>Relatórios</h2>
            {(props?.user?.role?.admin || props?.user?.role?.rolePermissions?.filter(f => f.controller?.name?.toLowerCase() === "analyticlink")[0]?.create) && 
                <Button style={{background: "#EAB308", borderRadius: "25px", width: "240px"}} variant="contained" onClick={() => setModalOpen(true)}>Novo</Button>
            }
            <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                {analytics.map((report, index) => 
                    <div style={{padding: "20px", height: "500px", maxHeight: "500px", minHeight: "500px"}}  key={index}>
                    <Card sx={{maxWidth: 250}}>
                        <CardMedia 
                            component="img"
                            height={150}
                            image={report.image || defaultDashIMG}
                            alt={report.imageName}
                        />
                        <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            {report.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" style={{maxHeight: "90px", minHeight: "90px", overflow: "auto"}}>
                            {report.description}
                        </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small" variant="outlined" color="primary" onClick={() => { window.open(report.url, '_blanck', 'noopener,noreferrer') }}>Acessar</Button>
                            {(props?.user?.role?.admin || props?.user?.role?.rolePermissions?.filter(f => f.controller?.name?.toLowerCase() === "analyticlink")[0]?.update)
                                && <Button size="small" variant="outlined" color="warning" onClick={() => { setCurrentIdEditing(report.id); setModalOpen(true)}}>Editar</Button>}
                            {
                                (props?.user?.role?.admin || props?.user?.role?.rolePermissions?.filter(f => f.controller?.name?.toLowerCase() === "analyticlink")[0]?.delete)
                                && <Button size="small" variant="outlined" color="error" onClick={() => handleDelete(report.id)}>Deletar</Button>}
                        </CardActions>
                    </Card>
                    
                </div>)}
            </div>
        </React.Fragment>
    )
}