
import { identityUrl } from "api/configuration";
import axios from 'axios'
import { deleteBaseAsync, getBaseAsync, postBaseAsync, RequestPathParameters } from 'api/';

export const getAsync = async <TType>(parameters: RequestPathParameters): Promise<TType | any> => {
    return getBaseAsync({ baseUrl: identityUrl, ...parameters });
}

export const postAsync = async <TType>(parameters: RequestPathParameters): Promise<TType | any> => {
    return postBaseAsync({ baseUrl: identityUrl, ...parameters });
}

export const deleteAsync = async <TType>(parameters: RequestPathParameters): Promise<TType | any> => {
    return deleteBaseAsync({ baseUrl: identityUrl, ...parameters });
}

export const putAsync = async (url, body) => {
    return await axios.put(url, body, { baseURL: identityUrl })
}
