import React from 'react'
import { Button, InputLabel, TextField, Box, Paper, Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import Modal from '@mui/material/Modal';
import { createRoleAsync, getRoleAsync, updateRoleAsync } from 'api/user/lib/role';
import { getRolePermissionsAsync, putRolePermissionsAsync } from 'api/user/lib/rolePermission';
import Switch from 'react-switch'

export default function PermissionManage({open, currentEditing, onClose}) {
    const [rolePermissions, setRolePermissions] = React.useState<any>([])
    React.useEffect(() => {
        (async () => {
            if(currentEditing == null) return;
            var res = await getRolePermissionsAsync(currentEditing)
            if(res['type'] === 'success')
                setRolePermissions(res['data']);
        })()
    }, [currentEditing])
    const handleSave = async () => {
        var res = await putRolePermissionsAsync(rolePermissions)
        if(res['type'] == 'success')
            onClose()
    }
    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="xl"
        >
            <DialogTitle textAlign={'center'}>Editar Permissões</DialogTitle>
            <DialogContent>
                <TableContainer sx={ { height: 550 } }>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead style={ { backgroundColor: "#f8f8f8", zIndex: 1 } }>
                            <TableRow>
                                <TableCell>
                                    Controller
                                </TableCell>
                                <TableCell style={ { maxWidth: "250px", textOverflow: "ellipsis" } } >
                                    Descrição
                                </TableCell>
                                <TableCell>
                                    Ler
                                </TableCell>
                                <TableCell>
                                    Criar
                                </TableCell>
                                <TableCell>
                                    Editar
                                </TableCell>
                                <TableCell>
                                    Excluir
                                </TableCell>
                                <TableCell>
                                    Executar
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { rolePermissions?.sort((a, b) => b.controller.name < a.controller.name ? 1 : -1).map((rolePermission, index) =>
                                <TableRow key={ index } style={ { backgroundColor: index % 2 === 0 ? "white" : "#f0f0f0" } }>
                                    <TableCell>
                                        { rolePermission?.controller?.name }
                                    </TableCell>
                                    <TableCell title={ rolePermission?.controller?.description }>
                                        { rolePermission?.controller?.description }
                                    </TableCell>
                                    <TableCell>
                                        <Switch onColor="#EAB308" checked={ rolePermission?.read } onChange={ (checked) => {
                                            rolePermission.read = checked;
                                            setRolePermissions(prevState => ([...prevState]));
                                        } } />
                                    </TableCell>
                                    <TableCell>
                                        <Switch onColor="#EAB308" checked={ rolePermission?.create } onChange={ (checked) => {
                                            rolePermission.create = checked;
                                            setRolePermissions(prevState => ([...prevState]));
                                        } } />
                                    </TableCell>
                                    <TableCell>
                                        <Switch onColor="#EAB308" checked={ rolePermission?.update } onChange={ (checked) => {
                                            rolePermission.update = checked;
                                            setRolePermissions(prevState => ([...prevState]));
                                        } } />
                                    </TableCell>
                                    <TableCell>
                                        <Switch onColor="#EAB308" checked={ rolePermission?.delete } onChange={ (checked) => {
                                            rolePermission.delete = checked;
                                            setRolePermissions(prevState => ([...prevState]));
                                        } } />
                                    </TableCell>
                                    <TableCell>
                                        <Switch onColor="#EAB308" checked={ rolePermission?.execute } onChange={ (checked) => {
                                            rolePermission.execute = checked;
                                            setRolePermissions(prevState => ([...prevState]));
                                        } } />
                                    </TableCell>
                                </TableRow>
                            ) }
                        </TableBody>

                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color='error' onClick={ onClose }>Cancelar</Button>
                <Button variant="contained" onClick={ handleSave }>Salvar</Button>
            </DialogActions>
        </Dialog>
    )
}