import { areEqual, GridChildComponentProps } from 'react-window'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { isProductSelected, onMpsChangeValue } from 'redux/AppScenarioState';
import { memo, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useUserChangeableState } from 'utils';
import { BsFillArrowDownCircleFill, BsFillArrowUpCircleFill } from 'react-icons/bs'
import { TiLockClosed, TiLockOpen } from 'react-icons/ti'
import { getStockStatusColor } from 'utils/chartHelper';
import NeoTooltip from 'components/Tooltip/NeoTooltip';
import { Dictionary } from 'utils/types';
import { PlanningGridData } from 'redux/AppScenarioState/dataTypes';
import { t } from '@lingui/macro';
import MaterialFlowPlanningGridRowContainer from '../MaterialFlow/MaterialFlowPlanningGridRowContainer';
import { stockStatusTypeToLanguage } from 'locales/helper';


interface PlanningRowInput {
    planningGrids: Dictionary<PlanningGridData[]>,
    handleOnLockFromStart: (itemId: number, productPeriodIndex: number) => void,
    handleOnUnlockFromIndexToEnd: (itemId: number, productPeriodIndex: number) => void,
    handleOnSingleLockClick: (itemId: number, productPeriodIndex: number) => void,
    handleOnDownClick: (itemId: number, productPeriodIndex: number) => void,
    handleOnUpClick: (itemId: number, productPeriodIndex: number) => void
    handleHighlightMaterialFlowResources: (itemId: number, productPeriodIndex: number) => void
    handleHighlightMaterialFlowResourcesClear: (itemId: number, productPeriodIndex: number) => void
}
const PlanningGridRow = memo((props: GridChildComponentProps) => {
    const planningRowInput: PlanningRowInput = props.data;

    const dispatch = useAppDispatch();
    const itemId = useAppSelector(state => state.appScenarioState.filterData.orderedItemIds[props.rowIndex]);
    const isSelected = useAppSelector(state => isProductSelected(state.appScenarioState, itemId));
    const planningGridByProductIdByPlanningGridIndex = planningRowInput.planningGrids[itemId][props.columnIndex]

    const [localMps, setLocalMps] = useState(planningGridByProductIdByPlanningGridIndex.mps);
    useLayoutEffect(() => {
        setLocalMps(planningGridByProductIdByPlanningGridIndex.mps)
    }, [planningGridByProductIdByPlanningGridIndex.mps])

    const [mpsHandler, setMpsHandler] = useUserChangeableState(planningGridByProductIdByPlanningGridIndex.mps);
    let timeOut: NodeJS.Timeout;
    useEffect(() => {
        if (mpsHandler.changedByUser === true) {
            setLocalMps(mpsHandler.value)
            timeOut = setTimeout(() => {
                //modificar dados do Redux
                dispatch(onMpsChangeValue({ itemId: itemId, productPeriodIndex: props.columnIndex, newMps: mpsHandler.value ?? 0 }));
                planningRowInput.handleHighlightMaterialFlowResourcesClear(itemId, props.columnIndex);
                planningRowInput.handleHighlightMaterialFlowResources(itemId, props.columnIndex);
            }, 500)
        }
        return () => clearTimeout(timeOut);
    }, [mpsHandler])

    //TODO seria mais interessante ter componentes separados e não juntos a cada Row?
    const neoTipsLeft = useMemo(() => {
        return (
            <NeoTooltip className='invisible group-hover:visible cursor-pointer basis-1/4 w-full h-full px-1'
                tooltipMessage={planningGridByProductIdByPlanningGridIndex.locked ? t`Destrava períodos deste até o fim.` : t`Trava periodos do inicio até este.`}>
                {
                    planningGridByProductIdByPlanningGridIndex.locked ?
                        <TiLockClosed onClick={() => planningRowInput.handleOnUnlockFromIndexToEnd(itemId, props.columnIndex)} className='w-full h-full' />
                        :
                        <TiLockOpen onClick={() => planningRowInput.handleOnLockFromStart(itemId, props.columnIndex)} className='w-full h-full' />
                }
            </NeoTooltip>
        )
    }, [planningGridByProductIdByPlanningGridIndex.locked])

    const neoTipsRight = useMemo(() => {
        return (
            //TODO criar NeoLockButton
            <NeoTooltip className='invisible group-hover:visible cursor-pointer basis-1/4 w-full h-full px-1' tooltipMessage={t`Trava/Destrava somente este período.`}>
                {
                    planningGridByProductIdByPlanningGridIndex.locked ?
                        <TiLockOpen onClick={() => planningRowInput.handleOnSingleLockClick(itemId, props.columnIndex)} className='w-full h-full' />
                        :
                        <TiLockClosed onClick={() => planningRowInput.handleOnSingleLockClick(itemId, props.columnIndex)} className='w-full h-full' />
                }
            </NeoTooltip>
        )
    }, [planningGridByProductIdByPlanningGridIndex.locked])

    return (
        <>
            <div key={`${props.rowIndex}-${props.columnIndex}`} style={props.style}>
                <div className={`flex flex-row select-none h-60 w-66 text-sm border-t-2 ${isSelected ? 'bg-gray-300' : ''}`}>
                    <div className={`flex flex-col w-full h-full items-center justify-evenly`}>
                        <p>{planningGridByProductIdByPlanningGridIndex.demand?.toLocaleString()}</p>
                        <p>{planningGridByProductIdByPlanningGridIndex.orders?.toLocaleString()}</p>
                        {
                            planningGridByProductIdByPlanningGridIndex.locked ?
                                <p>{localMps?.toLocaleString()}</p>
                                :
                                <div className='group flex flex-row items-center'
                                    onFocus={event => planningRowInput.handleHighlightMaterialFlowResources(itemId, props.columnIndex)}
                                    onBlur={event => planningRowInput.handleHighlightMaterialFlowResourcesClear(itemId, props.columnIndex)}
                                >
                                    <BsFillArrowDownCircleFill onClick={() => planningRowInput.handleOnDownClick(itemId, props.columnIndex)} className='invisible group-hover:visible cursor-pointer basis-1/4 w-full h-full px-1' />
                                    <input aria-label='mps' className='basis-2/4 h-6 w-14 px-1' value={localMps} id='planInput' type='number'
                                        onChange={event => setMpsHandler({ value: Number(event.target.value), changedByUser: true })}
                                    />
                                    <BsFillArrowUpCircleFill onClick={() => planningRowInput.handleOnUpClick(itemId, props.columnIndex)} className='invisible group-hover:visible cursor-pointer basis-1/4  w-full h-full px-1' />
                                </div>
                        }
                        <p>{planningGridByProductIdByPlanningGridIndex.targetStock?.toLocaleString()}</p>
                        <p>{planningGridByProductIdByPlanningGridIndex.openingStock?.toLocaleString()}</p>
                        <p>{planningGridByProductIdByPlanningGridIndex.closingStock?.toLocaleString()}</p>
                        <p>{planningGridByProductIdByPlanningGridIndex.totalDaysOfCover?.toLocaleString()}</p>
                        <div className='group flex flex-row items-center'>
                            {neoTipsLeft}
                            <p className='rounded-md w-auto p-1' style={{ background: `${getStockStatusColor(planningGridByProductIdByPlanningGridIndex.stockStatus)}` }} >{stockStatusTypeToLanguage(planningGridByProductIdByPlanningGridIndex.stockStatus)}</p>
                            {/* <p className='rounded-md w-auto p-1' style={{ background: `rgb(${getStockStatusColor(planningGridByProductIdByPlanningGridIndex.stockStatus)})` }} >{planningGridByProductIdByPlanningGridIndex.stockStatus}</p> */}
                            {neoTipsRight}
                        </div>
                    </div>
                </div>
                <MaterialFlowPlanningGridRowContainer
                    itemId={itemId}
                    columnIndex={props.columnIndex}
                />
            </div>
        </>
    )
}, areEqual)

export default PlanningGridRow