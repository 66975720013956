import { ManagementRoutes, managementRoutesTranslator } from 'configuration/routes'
import ConfigurationPanelItem from './ConfigurationPanelItem';
import { FiLogOut } from 'react-icons/fi'
import { useContext, useEffect } from 'react';
import { t, Trans } from '@lingui/macro';
import DefaultProfile from 'assets/images/defaultUser.png'
import { UserContext } from 'components/UserProvider';
import LanguageDropdown from './LanguageDropdown';
import { useAppSelector } from 'redux/hooks';
import { useLingui } from '@lingui/react';
interface Props {
    isSelected: boolean;
    user: any;
    close: () => void;
}

function ConfigurationPanel(props: Props) {
    const { logout } = useContext(UserContext)
    useLingui()

    return (
        <>
            <div className={`absolute z-50 flex flex-col ${props.isSelected ? `visible` : `invisible`} rounded-xl shadow cursor-default divide-y divide-gray-300 mt-4 mr-4 w-72 h-auto bg-white right-0`}>
                <div className='flex items-center w-full h-28'>
                    <img src={props.user?.photo || DefaultProfile} alt='' className='flex-none ml-4 w-20 h-20 rounded-lg'></img>
                    <div className='w-full h-full m-4'>
                        <div className='flex mt-2'>
                            <div>
                                <p className='w-24 mt-1 font-semibold self-center'>{props.user?.firstName}</p>
                                <p className='h-4 text-sm text-gray-400 w-full '>{props.user?.role?.name}</p>
                            </div>
                        </div>
                        {/* <div className='w-auto mt-4 transition duration-500 shadow-lg transform hover:scale-110 cursor-pointer text-center font-medium text-sm text-white self-center p-1 rounded-2xl bg-red-600'>
                            <Trans>Meu Perfil</Trans> 
                        </div> */}
                    </div>
                </div>
                {
                    <>
                        <LanguageDropdown />
                        {
                            ManagementRoutes.map((routeData, index) => (
                                <div className='flex flex-col w-full h-auto' key={`${index}-${routeData.pageName}`} onClick={() => props.close()}>
                                    {
                                        routeData.subPages?.map((subPageData, index) => (
                                            <ConfigurationPanelItem key={`${index}-${subPageData.subPageName}`} icon={subPageData.icon} pageName={managementRoutesTranslator(subPageData.subPageName)} pageUrl={`${routeData.pageUrl}/${subPageData.subPageUrl}`} />
                                        ))
                                    }
                                </div>
                            ))
                        }
                        <div className='group flex flex-row cursor-pointer items-center h-10 hover:bg-blue-50' onClick={logout}>
                            <FiLogOut className='fill-current  m-4 w-4 h-4 group-hover:text-blue-400' />
                            <div className='text-lg text-gray-400 group-hover:text-blue-400'><Trans>Sair</Trans> </div>
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default ConfigurationPanel