import { RequestParamsParameters, RequestSignalParameter } from 'api'
import { Item } from 'api/data/types'
import { getAsync } from '../index'
const route = 'items'

// ItemsController
export function getItemByIdAsync(itemId: number, parameters?: RequestSignalParameter): Promise<Item> {
    return getAsync({ url: `/${route}/${itemId}`, ...parameters })
}
export function getItemDetailsByIdAsync(itemId: number, parameters?: RequestSignalParameter): Promise<Item> {
    return getAsync({ url: `/${route}/${itemId}/details`, ...parameters })
}
export function getItemsAsync(parameters?: RequestSignalParameter): Promise<Item[]> {
    return getAsync({ url: `/${route}`, ...parameters })
}

export function getItemImageByImageNameAsync(parameters?: RequestParamsParameters<{ imageName: string }, null>): Promise<Blob> {
    return getAsync({ url: `/${route}/images`, responseType: 'blob', ...parameters })
}

export function getItemImageByItemIdAsync(itemId: number, parameters?: RequestSignalParameter): Promise<Blob> {
    return getAsync({ url: `/${route}/${itemId}/images`, responseType: 'blob', ...parameters })
}