import { getTableDataAsync, putTableDataAllAsync, putTableDataAsync } from "api/data";
import Loader from "components/Loader/NeoLoader";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import TableEditionDataBody from "./components/TableEditionDataBody";
import TableEditionDataCell from "./components/TableEditionDataCell";
import TableEditionDataCointainer from "./components/TableEditionDataContainer";
import TableEditionDataHeader from "./components/TableEditionDataHeader";
import TableEditionDataRow from "./components/TableEditionDataRow";
import TableEditionDataTable from "./components/TableEditionDataTable";
import * as renders from './components/renders'
import TableEditionDataMenu from "./components/Menu";
import { getLabelWidth } from 'utils/index'
import LogPopover from "./components/LogPopover";
import { confirmAlert } from "react-confirm-alert";
import { t } from "@lingui/macro";
import { Box, Button,  Paper, Typography } from "@mui/material";
import RequiredFilterPopup from "./components/RequiredFilter";
import AddData from "./components/AddData";
const TableEditionDataPage = () => {
    //dados
    const { id } = useParams()
    const [data, setData] = React.useState<any>([])
    const [dataAux, setDataAux] = React.useState<any>([]);
    const [columns, setColumns] = React.useState<any>([]);
    const [totalWidth, setTotalWidth] = React.useState(0);
    const [totalHeight, setTotalHeight] = React.useState(60)
    const [table, setTable] = React.useState<any>({})
    const [height, setHeight] = React.useState(0);
    const [needRefresh, setNeedRefresh] = React.useState(false)
    const [requiredFilter, setRequiredFilter] = React.useState<any>(false);
    const [loading, setLoading] = React.useState(true)
    const [orderBy, setOrderBy] = React.useState<string | null>(null)
    const [columnOrderBy, setColumnOrderBy] = React.useState<any>(null)
    const [editionMode, setEditionMode] = React.useState(false);
    const [editionAllMode, setEditionAllMode] = React.useState(false);
    const [scroll, setScroll] = React.useState({ top: 0, left: 0 });
    const [foreignFields, setForeignsFields] = React.useState<any>();
    const [contextMenu, setContextMenu] = React.useState<any>(null);
    const [modalAddData, setModalAddData] = React.useState<boolean>(false);
    const handleCloseModalAddData = () => {
        setModalAddData(false);
    }
    const handleOpenModalAddData = () => {
        setModalAddData(true);
    }
    const handleOpenContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        setContextMenu(
        contextMenu === null
            ? {
                mouseX: event.clientX + 2,
                mouseY: event.clientY - 6,
            }
            : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
              // Other native context menus might behave different.
              // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
            null,
        );
    };
    const handleCloseContextMenu = () => {
        setContextMenu(null);
    };
    const handleEditAll = (value, column, colsPK) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                            <Typography variant="h5" component="h2" gutterBottom>
                                <Box fontWeight="fontWeightBold" fontSize={24}>
                                    {t`Modo de edição em massa ligado`}
                                </Box>
                            </Typography>
                            <Typography variant="h5" component="h2" gutterBottom>
                                <Box fontSize={18}>
                                    {t`Você tem certeza que deseja aplicar essa alteração em todas as linhas filtradas?`}
                                </Box>
                            </Typography>
                            <Typography variant="h5" component="h2" gutterBottom>
                                <Box fontWeight="fontWeightBold" fontSize={18}>
                                    {t`${dataAux.length} Linhas serão afetadas `}
                                </Box>
                            </Typography>
                            <Box mt={3} gap={5} display="flex" justifyContent="space-around">
                                <Button variant="contained" color="error" onClick={onClose}>
                                    Não
                                </Button>
                                <Button variant="contained" color="success" onClick={() => {
                                    // let dataCopy:any = [...data]
                                    let dataPost: any = new Array;
                                    dataAux.map(row => {
                                        row[column.field] = value;
                                        let row2 = data.filter(f => f.id === row.id)[0]
                                        row2[column.field] = value;
                                        let PK = "";
                                        colsPK.map(col => {
                                            PK += `${col.field},${col.sqlType},${row[col.field]};`
                                            return col;
                                        })
                                        dataPost.push({
                                            value: value,
                                            columnId: column.id,
                                            pks: PK.slice(0, -1)
                                        })
                                    })
                                    putTableDataAllAsync(dataPost).then(res => {
                                        if(res.type === "success") {
                                            setDataAux(prevState => ([...prevState]));
                                            setData(prevState => ([...prevState]));
                                        }
                                        
                                    })
                                    
                                    onClose();
                                }}>
                                    Sim
                                </Button>
                            </Box>
                        </Box>
                );
            }
        })
        
    }
    const handleOrderBy = (column) => {
        let dataCopy = [...dataAux];
        let orderCopy = orderBy;
        dataCopy.sort((a, b) => {
            try {
                if (column.foreignKey) {
                    if (a[column.field] !== null && b[column.field] !== null) {
                        let valueOne = foreignFields[column.field].filter(v => v.id === a[column.field])[0].name;
                        let valueTwo = foreignFields[column.field].filter(v => v.id === b[column.field])[0].name;
                        return valueOne > valueTwo ? 1 : -1;
                    } else if (a[column.field] === null) {
                        return 1
                    } else {
                        return -1
                    }
                }
                else {
                    return a[column.field] > b[column.field] ? 1 : -1
                }
            } catch (err) {
                return -1
            }
        })
        if(columnOrderBy !== column.id)
            orderCopy = "desc"
        if (orderCopy === "desc") {
            setOrderBy("asc")
            setDataAux(dataCopy)
        }
        else {
            setOrderBy("desc")
            setDataAux(dataCopy.reverse())
        }
        setColumnOrderBy(column.id)
    }
    const loadData = React.useCallback(async() => {
        setLoading(true)
        let res = await getTableDataAsync(id)
        if(res.type !== "success")
            return;
        setData(res.data)
        if(Array.isArray(res.columns) && res.columns.filter(f => f.sortDefault).length > 0) {
            let dataCopy = [...res.data];
            let column = res.columns.filter(f => f.sortDefault)[0]
            dataCopy.sort((a, b) => {
                try {
                    if (column.foreignKey) {
                        if (a[column.field] != null && b[column.field] != null) {
                            let valueOne = res.foreignFields[column.field].filter(v => v.id === a[column.field])[0].name;
                            let valueTwo = res.foreignFields[column.field].filter(v => v.id === b[column.field])[0].name;
                            return valueOne > valueTwo ? 1 : -1;
                        } else if (a[column.field] === null) {
                            return 1
                        } else {
                            return -1
                        }
                    }
                    else {
                        return a[column.field] > b[column.field] ? 1 : -1
                    }
                } catch (err) {
                    return -1
                }
            })
            setOrderBy("asc")
            setDataAux(dataCopy)
            setColumnOrderBy(column.id)
        }
        else {
            setDataAux(res.data)
        }
        let totalWidth = 0;
        res.columns.filter(col => !col.hidden).map(col => {
            if (col.width < 90) col.width = 90;
            col.init = totalWidth;
            totalWidth += col.width
            col.filterValue = "";
            col.filterSignal = "=";
            col.field = col.field.toLowerCase();
            if (col.editorAll)
                col.editorAllRef = React.createRef();
            switch (col.viewType) {
                case "checkbox":
                    col.render = renders.checkBoxRender;
                    break;
                case "data":
                    col.render = renders.dateRender;
                    break;
                case "hora":
                    col.render = renders.timeRender;
                    break;
                case "datahora":
                    col.render = renders.dateTimeRender;
                    break;
                case "color":
                    col.render = renders.colorRender;
                    break;
                default:
                    col.render = renders.defaultRender;
            }
            return col;
        })
        setColumns(res.columns.sort((a,b) => a.order > b.order ? 1 : -1))
        setTotalWidth(totalWidth)
        setTotalHeight(res.totalRows * 35 + 80)
        setForeignsFields(res.foreignFields)
        setLoading(false)
        setTable(res.table)
        setRequiredFilter(res.table.requiredFilter)
    },[id])
    const handleRequiredFilter = () =>{
        var column = columns.filter(a => a.field.toLowerCase() === table.columnRequiredFilter?.toLowerCase())[0]
        if(column == null)
            setRequiredFilter(false);
        if(Array.isArray(column.filterValue) && column.filterValue.length > 0)
            setRequiredFilter(false);
        if(column.filterValue != null && column.filterValue !== "")
            setRequiredFilter(false);
    }
    useEffect(() => {
        loadData()
    },[id, loadData])
    useEffect(() => {
        var totalWidth = 0
        columns?.filter(col => !col.hidden).map(col => {
            col.init = totalWidth;
            totalWidth += col.width
            return col
        })
        setTotalWidth(totalWidth)
        setData(prevState => [...prevState]);
    },[columns])
    const getData = () => {
        let items = Array<any>();
        let index = scroll.top / 35;
        let totalItems = height / 35 + 10;
        if(totalItems < 10)
            totalItems = 10
        for(var i = Math.floor(index) - 10; i < Math.floor(index) + totalItems; i++) {
            if(dataAux.length > i && i >= 0)
            {
                let row = dataAux[i];
                row.index = i;
                items.push(row);
            }
        }
        return items;
    }
    const getColumns = () => {
        let returnedColumns = [...columns.filter(c => !c.hidden)];
        return returnedColumns.filter(col => (col.init + col.width) >= scroll.left && col.init <= scroll.left + window.innerWidth)
    }
    const setFilter = (columnId, filterSignal, filterValue) => {
        //cópia array de colunas
        let columnsCopy: any = columns;
        let columnAlter = columnsCopy.filter(f => f.id === columnId)[0]
        if (filterSignal !== undefined)
            columnAlter.filterSignal = filterSignal;
        if(filterValue !== undefined)
            columnAlter.filterValue = filterValue;
        let filteredData = [...data];
        for(var col of columnsCopy){
            if(col.filterValue !== "" && col.filterValue != null){
                filteredData = filteredData.filter(row => {
                    var value = row[col.field];
                    if (col.foreignKey && value != null) {
                        value = foreignFields[col.field].filter(f => f.id === value)[0].name;
                    }
                    if(!Array.isArray(col.filterValue)) {
                        if (col.filterValue === "null" || col.filterValue == null) {
                            return value == null;
                        }
                        if (col.filterValue === "vazio") {
                            return value === "";
                        }
                        if (value === undefined || value === null)
                            return false;
                    }
                    if(col.filterType === 1) {
                        if(isNaN(col.filterValue)) {
                            return true;
                        }
                        switch(col.filterSignal){
                            case ">=":
                                return !isNaN(value) && Number(value) >= Number(col.filterValue)
                            case "=":
                                return !isNaN(value) && Number(value) == Number(col.filterValue)
                            case "<=":
                                return !isNaN(value) && Number(value) <= Number(col.filterValue)
                            case "≠":
                                return !isNaN(value) && Number(value) != Number(col.filterValue)
                            case "<":
                                return !isNaN(value) && Number(value) < Number(col.filterValue)
                            case ">":
                                return !isNaN(value) && Number(value) > Number(col.filterValue)
                            default:
                                return false;
                        }
                    } else if(col.filterType === 2) {
                        switch(col.filterSignal) {
                            case "=":
                                return value.toLowerCase() == col.filterValue.toLowerCase()
                            case "≠":
                                // eslint-disable-next-line eqeqeq
                                return value.toLowerCase() != col.filterValue.toLowerCase()
                            case "⊃":
                                return value.toLowerCase().includes(col.filterValue.toLowerCase())
                            case "⊅":
                                return !value.toLowerCase().includes(col.filterValue.toLowerCase())
                            case "^":
                                return value.toLowerCase().startsWith(col.filterValue.toLowerCase())
                            case "$":
                                return value.toLowerCase().endsWith(col.filterValue.toLowerCase())
                            default:
                                return true;
                        }
                    } else if(col.filterType === 3) {
                        if(col.filterValue !== true && col.filterValue !== false) return true;
                        return value === col.filterValue;
                    } else if(col.filterType === 4) {
                        if(!Array.isArray(col.filterValue)) return true;
                        if(col.filterValue.length === 0) return true;
                        if((value === null || value === undefined) && col.filterValue.includes("null")) return true;
                        try {
                            var date = new Date(value)
                            const month = date.getMonth() + 1; 
                            const year = date.getFullYear().toString();
                            const day = date.getDate();
                            const formattedMonth = `${month.toString().padStart(2, '0')}.${year.toString()}`;
                            const formattedDay = `${day.toString().padStart(2, '0')}.${month.toString().padStart(2, '0')}.${year.toString()}`;
                            if (col.filterValue.includes(year)) return true;
                            if (col.filterValue.includes(formattedMonth)) return true;
                            if (col.filterValue.includes(formattedDay)) return true;
                        }  //not is date
                        catch {
                            return false;
                        }
                        return false;
                    } else if(col.filterType === 5) {
                        if (col.filterValue.length === 0) return true;
                        if(col.filterValue.includes("null") && value === null || value === undefined) return true;
                        if(col.filterValue.includes(value)) return true;
                        return false;
                    } 
                    return true;
                })
            }
        }
        setDataAux(filteredData);
        setColumns(columnsCopy);
        setTotalHeight(filteredData.length * 35);
    }

    const handleUpdateWidthColumns = () => {
        handleCloseContextMenu();
        let columnsCopy = columns;
        columnsCopy.map(col => {
            var text = col.header
            col.width = getLabelWidth(text) + 25
            if(col.width < 90)
            col.width = 90;
        })
        dataAux.map(row => {
            columnsCopy.map(col => {
                var text = row[col.field]
                if(col.foreignKey && text != null)
                    text = foreignFields[col.field].filter(f => f.id === text)[0].name;
                if(text != null) {
                    var width = getLabelWidth(text) + 10
                    if(col.width < width) {
                        col.width = width;
                    }
                    
                        
                }
            })
        })
        setColumns([...columnsCopy]);
    }

    const anchorPopoverRef = React.useRef<any>(null);
    const [anchorElPopover, setAnchorElPopover] = React.useState(null);

    const handleOpenPopover = (event) => {
        setAnchorElPopover(event.currentTarget);
        handleCloseContextMenu();
    };
    const handleClosePopover = () => {
        if(needRefresh){
            loadData();
            setNeedRefresh(false)
        }
            
        setAnchorElPopover(null);
    };
    const anchorPopoverPosition = () => {
        const anchor = anchorPopoverRef.current?.getBoundingClientRect();
        return { top: anchor?.top || 0, left: anchor?.right || 0 };
    };
    return (
        <React.Fragment>
            {loading && <Loader />}
            <Typography variant="h5" textAlign={"center"} sx={{ p: 1 }}>{table.title}</Typography>
            {requiredFilter && 
            <RequiredFilterPopup 
                open={requiredFilter} 
                handleRequiredFilter={handleRequiredFilter}
                column={columns.filter(a => a.field.toLowerCase() === table.columnRequiredFilter?.toLowerCase())[0]}
                data={data}
                foreignFields={foreignFields}
                setFilter={setFilter}
            />}
            {!requiredFilter &&
            <TableEditionDataCointainer setScroll={setScroll} setHeight={setHeight} ref={anchorPopoverRef} handleContextMenu={handleOpenContextMenu}>
                    <AddData open={modalAddData} onClose={handleCloseModalAddData} onSave={loadData} foreignFields={foreignFields} columns={columns} />
                    <TableEditionDataMenu
                        handleClose={handleCloseContextMenu}
                        contextMenu={contextMenu}
                        editionMode={editionMode}
                        editionAllMode={editionAllMode}
                        setEditionMode={setEditionMode}
                        setEditionAllMode={setEditionAllMode}
                        loadData={loadData}
                        handleUpdateWidthColumns={handleUpdateWidthColumns}
                        handleOpenPopover={handleOpenPopover}
                        rows={data}
                        columns={columns}
                        title={table.title}
                        foreignFields={foreignFields}
                        onAddDataClick={handleOpenModalAddData}
                    />
                    <LogPopover 
                        open={Boolean(anchorElPopover)} 
                        anchorEl={anchorElPopover} 
                        handleClose={handleClosePopover}
                        anchorPosition={anchorPopoverPosition}
                        setNeedRefresh={setNeedRefresh}
                    />
                    <TableEditionDataTable totalHeight={totalHeight} totalWidth={totalWidth}>
                        <TableEditionDataHeader data={data} foreignFields={foreignFields} setFilter={setFilter} columns={columns} handleOrderBy={handleOrderBy} orderBy={orderBy} columnOrderBy={columnOrderBy} setColumns={setColumns} editionMode={editionMode} setEditionMode={setEditionMode} loadData={loadData}/>
                        <TableEditionDataBody >
                            {getData().map(row  =>
                                <TableEditionDataRow
                                    key={row.index}
                                    style={{
                                        height: "35px",
                                        position: "absolute",
                                        top: `${row.index * 35 + 80}px `,
                                        background: row.index % 2 === 0 ? "rgb(245, 246, 252)" : "rgb(247, 247, 245)"
                                    }}
                                    totalWidth={totalWidth}
                                >
                                        {getColumns().map((col) => {
                                            return (
                                                !col.hidden &&
                                                <TableEditionDataCell
                                                    key={col.id}
                                                    style={{
                                                        position: "absolute",
                                                        left: `${col.init}px`,
                                                        width: `${col.width}px`,
                                                        height: "100%"
                                                    }}
                                                    row={row}
                                                    column={col}
                                                    editionMode={editionMode}
                                                    setData={setData}
                                                    foreignFields={foreignFields}
                                                    colsPK={columns.filter(f => f.primaryKey)}
                                                    editionAllMode={editionAllMode}
                                                    handleEditAll={handleEditAll}
                                                />
                                            )
                                        })}
                                </TableEditionDataRow>
                            )}
                        </TableEditionDataBody>
                    </TableEditionDataTable>
                </TableEditionDataCointainer>
                }
        </React.Fragment>
    )
}

export default TableEditionDataPage;