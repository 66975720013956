import { forwardRef } from "react"

const TableEditionDataBody = forwardRef((props:any, ref: any) => {
    return (
        <div ref={ref} onContextMenu={props.handleContextMenu}>
            {props.children}
        </div>
    )
})

export default TableEditionDataBody