import { useUniqueId } from 'utils';
import './styles.css'

interface Props {
    className?: string
    checked?: boolean
    onChange: () => void;
}

function NeoSwitch(props: Props) {
    const { className, checked, onChange } = props
    const id = useUniqueId();
    return (
        <div className={className}>
            <label className='flex cursor-pointer' htmlFor={`${id}`}>
                <div className='flex w-full h-full'>
                    <div className='relative place-self-center'>
                        <input checked={checked} onChange={onChange} id={`${id}`} type="checkbox" className='sr-only' />
                        <div className='w-10 h-4 bg-gray-400 rounded-full shadow-inner'></div>
                        <div className="dot absolute w-6 h-6 bg-white rounded-full border-2 border-gray-400 shadow -left-1 -top-1 transition checked:bg-yellow-400"></div>
                    </div>
                </div>
            </label>
        </div>
    )
}

export default NeoSwitch
