import { getPowerBIByIdAsync } from "api/data";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";


const Report = () => {
    const { id } = useParams()
    const [ report, setReport ] = React.useState<any>({});
    useEffect(() => {
        getPowerBIByIdAsync(id).then((res) => {
            setReport(res)
        })
    },[id])
    return (
        <iframe
            title={report.title}
            width="100%"
            height="100%"
            src={report.link} frameBorder={0} allowFullScreen={true}
        >
        </iframe>
    )
};

export default Report;