import { Fragment, useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { TiArrowUnsorted } from "react-icons/ti";
import { AiOutlineCheck } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";
import { setSelectedGraphLabel } from "redux/AppStockState/reducer";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function SelectGraphLabel() {
    const { i18n } = useLingui()
    const dispatch = useDispatch();
    const { selectedGraphLabel } = useAppSelector((state) => state.appStockState);

    useEffect(() => {
        dispatch(
            setSelectedGraphLabel({
                label: i18n.t(`Valor`),
            })
        );
    }, []);

    return (
        <Listbox
            value={selectedGraphLabel}
            onChange={(e) =>
                dispatch(
                    setSelectedGraphLabel({
                        label: e,
                    })
                )
            }
        >
            {({ open }) => (
                <>
                    <div className="relative">
                        <Listbox.Button className=" whitespace-nowrap relative w-fit cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6 ">
                            <span className="flex items-center">
                                <span className="ml-3">{selectedGraphLabel}</span>
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                <TiArrowUnsorted
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                />
                            </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute w-fit z-10 mt-1 max-h-56 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <Listbox.Option
                                    className={({ active }) =>
                                        classNames(
                                            active ? "bg-indigo-600 text-white" : "text-gray-900",
                                            "relative cursor-default select-none py-2 pl-3 pr-9"
                                        )
                                    }
                                    value={i18n.t(`Valor`)}
                                >
                                    {({ selected, active }) => (
                                        <>
                                            <div className="flex items-center">
                                                <span
                                                    className={classNames(
                                                        selected ? "font-semibold" : "font-normal",
                                                        "ml-3 block truncate"
                                                    )}
                                                >
                                                    <Trans>Valor</Trans>
                                                </span>
                                            </div>

                                            {selected ? (
                                                <span
                                                    className={classNames(
                                                        active ? "text-white" : "text-indigo-600",
                                                        "absolute inset-y-0 right-0 flex items-center pr-4"
                                                    )}
                                                >
                                                    <AiOutlineCheck
                                                        className="h-5 w-5"
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                            ) : null}
                                        </>
                                    )}
                                </Listbox.Option>
                                <Listbox.Option
                                    className={({ active }) =>
                                        classNames(
                                            active ? "bg-indigo-600 text-white" : "text-gray-900",
                                            "relative cursor-default select-none py-2 pl-3 pr-9"
                                        )
                                    }
                                    value={i18n.t(`Itens`)}
                                >
                                    {({ selected, active }) => (
                                        <>
                                            <div className="flex items-center">
                                                <span
                                                    className={classNames(
                                                        selected ? "font-semibold" : "font-normal",
                                                        "ml-3 block truncate"
                                                    )}
                                                >
                                                    <Trans>Itens</Trans>
                                                </span>
                                            </div>

                                            {selected ? (
                                                <span
                                                    className={classNames(
                                                        active ? "text-white" : "text-indigo-600",
                                                        "absolute inset-y-0 right-0 flex items-center pr-4"
                                                    )}
                                                >
                                                    <AiOutlineCheck
                                                        className="h-5 w-5"
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                            ) : null}
                                        </>
                                    )}
                                </Listbox.Option>
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    );
}
