enum OutboundNotificationType {
    Direct = 'Direct',
    LoadAllMessages = 'LoadAllMessages'
}

const _outbound = `OutboundMessage`;

export const OutboundMessage = {
    Direct: `${_outbound}.${OutboundNotificationType.Direct}`,
    LoadAllMessages: `${_outbound}.${OutboundNotificationType.LoadAllMessages}`
}

export interface OutboundDirectMessage {
    badgeIcon: string
    title: string
    message: string
    subject: string
    isRead: boolean
    time: string
}