import { RequestSignalParameter, RequestParamsParameters } from 'api';
import { PlanningGrid, PlanningGridDetails } from 'api/data/types';
import { getAsync, putAsync } from '..';

const route = 'planning-grids'
interface UrlQuery {
    scenarioId?: number
}

//PlanningGridController
export const getPlanningGridByIdAsync = async (planningGridId: number, parameters?: RequestSignalParameter): Promise<PlanningGrid> => {
    return getAsync({ url: `/${route}/${planningGridId}`, ...parameters });
}
export const getPlanningGridDetailsByIdAsync = async (planningGridId: number, parameters?: RequestSignalParameter): Promise<PlanningGridDetails> => {
    return getAsync({ url: `/${route}/${planningGridId}/details`, ...parameters });
}
export const getPlanningGridsByScenarioIdAsync = async (parameters?: RequestParamsParameters<UrlQuery, null>): Promise<PlanningGrid[]> => {
    return getAsync({ url: `/${route}`, ...parameters });
}
export const getPlanningGridsDetailsByScenarioIdAsync = async (parameters?: RequestParamsParameters<UrlQuery, null>): Promise<PlanningGridDetails[]> => {
    return getAsync({ url: `/${route}/details`, ...parameters });
}
//TODO num futuro enviar dados do scenario + planningGrids
export const updatePlanningGridsAsync = async (parameters?: RequestParamsParameters<null, PlanningGrid[]>): Promise<null> => {
    return putAsync({ url: `/${route}`, ...parameters });
}