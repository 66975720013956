import { Trans } from '@lingui/macro';
import React, { ReactNode } from 'react'
import { ScenarioSummarizedData } from 'redux/AppScenarioState/dataTypes';

interface SumarizedPanelRowProps {
    bgColorClass: string,
    children: ReactNode;
}

function SumarizedPanelRow(props: SumarizedPanelRowProps) {
    const { bgColorClass, children } = props

    return (
        <div className={`h-auto w-full ${bgColorClass} border-solid	border border-gray-200 divide-y divide-gray-100`}>
            {children}
        </div>
    )
}
const MemoizedSumarizedPanelRow = React.memo(SumarizedPanelRow)

interface Props {
    scenarioSummarizedData: ScenarioSummarizedData[]
    scenarioName: string
}
function SumarizedPanel(props: Props) {
    const { scenarioSummarizedData, scenarioName } = props;
    const sumarizedDatasetLastIndex = scenarioSummarizedData.length - 1;
    const sumarizedDatasetCount = scenarioSummarizedData.length;
    const summedDataset: ScenarioSummarizedData = {
        id: 0,
        storageResource: scenarioSummarizedData[0].storageResource,
        // storageResourceId: scenarioSummarizedData[0].storageResourceId,
        lastUpdatedOn: scenarioSummarizedData[0].lastUpdatedOn,
        period: scenarioSummarizedData[0].period,
        scenarioId: scenarioSummarizedData[0].scenarioId,

        demand: scenarioSummarizedData.map(x => x.demand).reduce((total, value) => total + value),
        mps: scenarioSummarizedData.map(x => x.mps).reduce((total, value) => total + value),
        revenue: scenarioSummarizedData.map(x => x.revenue).reduce((total, value) => total + value),

        fixedCost: scenarioSummarizedData.map(x => x.fixedCost).reduce((total, value) => total + value),
        variableCost: scenarioSummarizedData.map(x => x.variableCost).reduce((total, value) => total + value),

        totalDaysOfCover: scenarioSummarizedData[sumarizedDatasetLastIndex].totalDaysOfCover,
        closingStock: scenarioSummarizedData[sumarizedDatasetLastIndex].closingStock,
        storageUnits: scenarioSummarizedData.map(x => x.storageUnits).reduce((total, value) => total + value),

        stockStockout: scenarioSummarizedData.map(x => x.stockStockout).reduce((total, value) => total + value),
        stockStockoutCost: scenarioSummarizedData.map(x => x.stockStockoutCost).reduce((total, value) => total + value),
        stockRisk: scenarioSummarizedData.map(x => x.stockRisk).reduce((total, value) => total + value),
        stockRiskCost: scenarioSummarizedData.map(x => x.stockRiskCost).reduce((total, value) => total + value),
        stockExcess: scenarioSummarizedData.map(x => x.stockExcess).reduce((total, value) => total + value),

        usedPercentage: scenarioSummarizedData.map(x => x.usedPercentage).reduce((total, value) => total + value) / sumarizedDatasetCount,
        idlenessCost: scenarioSummarizedData.map(x => x.idlenessCost).reduce((total, value) => total + value),
        totalCost: scenarioSummarizedData.map(x => x.totalCost).reduce((total, value) => total + value),
        totalProfit: scenarioSummarizedData.map(x => x.totalProfit).reduce((total, value) => total + value),
        stockExcessCost: scenarioSummarizedData.map(x => x.stockExcessCost).reduce((total, value) => total + value),
    };
    return (
        <div className='shrink-0 h-full w-48  px-1 space-y-1 text-right select-none'>
            <div className='mt-6 h-auto w-full bg-gray-400 border-solid	border border-gray-200 text-center'>
                <Trans comment='Resumo de comparação de cenários' >Resumo</Trans>
            </div>
            <MemoizedSumarizedPanelRow bgColorClass='bg-green-100'>
                <p>{summedDataset.demand.toLocaleString()}</p>
                <p>{summedDataset.mps.toLocaleString()}</p>
                <p>{summedDataset.revenue.toLocaleString()}</p>
            </MemoizedSumarizedPanelRow>
            <MemoizedSumarizedPanelRow bgColorClass='bg-red-100'>
                <p>{summedDataset.fixedCost.toLocaleString()}</p>
                <p>{summedDataset.variableCost.toLocaleString()}</p>
            </MemoizedSumarizedPanelRow>
            <MemoizedSumarizedPanelRow bgColorClass='bg-blue-100'>
                <p>{summedDataset.totalDaysOfCover.toLocaleString()}</p>
                <p>{summedDataset.closingStock.toLocaleString()}</p>
                <p>{summedDataset.storageUnits.toLocaleString()}</p>
            </MemoizedSumarizedPanelRow>
            <MemoizedSumarizedPanelRow bgColorClass='bg-purple-100'>
                <p>{summedDataset.stockStockout.toLocaleString()}</p>
                <p>{summedDataset.stockStockoutCost.toLocaleString()}</p>
                <p>{summedDataset.stockRisk.toLocaleString()}</p>
                <p>{summedDataset.stockRiskCost.toLocaleString()}</p>
                <p>{summedDataset.stockExcess.toLocaleString()}</p>
                <p>{summedDataset.stockExcessCost.toLocaleString()}</p>
            </MemoizedSumarizedPanelRow>
            <MemoizedSumarizedPanelRow bgColorClass='bg-yellow-100'>
                <p>{(summedDataset.usedPercentage * 100).toFixed(0)}%</p>
                <p>{summedDataset.idlenessCost.toLocaleString()}</p>
                <p>{summedDataset.totalCost.toLocaleString()}</p>
                <p>{summedDataset.totalProfit.toLocaleString()}</p>
            </MemoizedSumarizedPanelRow>
        </div>
    )
}

export default SumarizedPanel
export const MemoizedSumarizedPanel = React.memo(SumarizedPanel);
