import Tippy from "@tippyjs/react";
import { FcInfo } from "react-icons/fc";

interface ToolTipProps {
  tooltipText: string;
}

export function InfoTooltip({ tooltipText }: ToolTipProps) {
  return (
    <div className="flex gap-2 justify-between items-center h-4">
      <Tippy
        delay={[200, 50]}
        animation="fade"
        offset={[10, 10]}
        maxWidth={1000}
        content={
          <span className="bg-blue-200 border border-gray-600 rounded-md font-semibold text-xs p-1 w-fit ">
            {tooltipText}
          </span>
        }
      >
        <label className="text-sm hover:cursor-help">
          {" "}
          <FcInfo size={14} />
        </label>
      </Tippy>
    </div>
  );
}
