import { RequestParamsParameters, RequestSignalParameter } from 'api';
import { Capacity } from 'api/data/types';
import { getAsync } from '..';
const route = 'capacities'

interface UrlQuery {
    scenarioId?: number
}

export const getCapacityByIdAsync = async (capacityId: number, parameters?: RequestSignalParameter): Promise<Capacity> => {
    return await getAsync({ url: `/${route}/${capacityId}`, ...parameters })
}
export const getCapacityDetailsByIdAsync = async (capacityId: number, parameters?: RequestSignalParameter): Promise<Capacity> => {
    return await getAsync({ url: `/${route}/${capacityId}/details`, ...parameters })
}
export const getCapacitiesByScenarioIdAsync = async (parameters?: RequestParamsParameters<UrlQuery, null>): Promise<Capacity[]> => {
    return await getAsync({ url: `/${route}`, ...parameters })
}
export const getCapacitiesDetailsByScenarioIdAsync = async (parameters?: RequestParamsParameters<UrlQuery, null>): Promise<Capacity[]> => {
    return await getAsync({ url: `/${route}/details`, ...parameters })
}
//
// export const getCapacitiesAsync = async (scenarioId: number, parameters?: RequestSignalParameter): Promise<Capacity> => {
//     return await getAsync<Capacity>({ url: `/capacities/${scenarioId}`, ...parameters })
// }
// export const getCapacitiesAsync = async (scenarioId: number, parameters?: RequestSignalParameter): Promise<Capacity> => {
//     return await getAsync<Capacity>({ url: `/capacities/${scenarioId}`, ...parameters })
// }