import React, { forwardRef } from 'react'
import { ListOnScrollProps, VariableSizeList } from 'react-window'
import AutoSizer, { Size } from "react-virtualized-auto-sizer";
import GridExpandedHeaderCol from '../components/GridExpandedHeaderCol';
import { useAppSelector } from 'redux/hooks';

interface Props {
    itemCount: number
    itemSize: (index: number) => number
    estimatedItemSize: number
    onScroll?: ((props: ListOnScrollProps) => any)
    itemData?: any
    height: number
}

const GridExpandedHeaderColumnContainer = forwardRef((props: Props, ref: any) => {
    const { itemCount, height, itemSize, estimatedItemSize, itemData, onScroll } = props

    return (
        <AutoSizer>
            {(size: Size) => (
                <VariableSizeList
                    itemSize={itemSize}
                    height={height}
                    width={size.width}
                    className="no-scrollbars"
                    itemCount={itemCount}
                    onScroll={onScroll}
                    ref={ref}
                    itemData={itemData}
                    layout="horizontal"
                    estimatedItemSize={estimatedItemSize}
                >
                    {GridExpandedHeaderCol}
                </VariableSizeList>
            )}
        </AutoSizer>
    )
})

export default GridExpandedHeaderColumnContainer
