import React, { memo } from 'react'
import { areEqual, ListChildComponentProps } from 'react-window'
import { PeriodDataOld } from 'redux/types';

const GridCollapsedHeaderCol = memo((props: ListChildComponentProps) => {
    const periods: PeriodDataOld[] = props.data;
    const periodData = periods[props.index];

    return (
        <div key={props.index} style={props.style} className='flex flex-col h-full w-full text-center'>
            <div className='flex h-1/2 items-center border-b-2'>
                <p className='w-full font-bold'>{periodData.yearNumber}</p>
            </div>

            <div className='flex h-1/2 items-center border-b-2'>
                <p className='w-full font-bold align-middle'> {periodData.monthName}</p>
            </div>
        </div>
    )
}, areEqual)

export default GridCollapsedHeaderCol
