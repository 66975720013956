import { t } from "@lingui/macro";
import { HighlightedStatusType, StockStatusType } from "api/data/enums";
import { FixedFilterData, ItemAttribute, ItemAttributesBundle, ItemAttributesCustomBundle, ItemPlanner, ItemStockGroup, PlanningGridAllocationDetails, ResourceGroupDetails } from "api/data/types";
import dayjs from "dayjs";
import { PlanningChartData } from "pages/PlanningPage/components/PlanningGrid/types";
import { TreeNodeProps } from "react-dropdown-tree-select";
import { PeriodDataOld } from "redux/types";
import { Dictionary } from "utils/types";
import { PlanningGridGlobalValues, PlanningGridData, PlanningGridAllocationData, PeriodData, ItemData, CapacityData, GlobalPlanningGridTargetType, CompleteStructureData, CompleteStructureNode, ItemStockGroupData } from "./dataTypes";
import { ResourceAggregator, OccupationChartScenario, AppScenarioState, ChartDataset } from "./types";
import { StockStatusDictionary, SearchCriteriasDictionary, FilterCriteria } from "./FilterCriteria/types";
import { handleSelectItemAttributeToGroupModule } from "./_modules";
import { i18n } from "@lingui/core";

export const executeIfExists = (dataNameForLog: string, data: any[], action: () => any) => {
    if (data === undefined || data.length === 0) {
        console.error(`${dataNameForLog} is undefined or length is 0`)
    } else {
        return action();
    }
}

//helper functions
function parse_rgb_string(rgb) {
    rgb = rgb.replace(/[^\d,]/g, '').split(',');
    return rgb;
}

/**
 * Set Hex Alpha
 * @param {string} color - color in rgba 
 * @param {number} opacity - the opacity [0.5..1]
 * @returns {string} rgba with alpha
 */
export const applyOpacity = (color: string, opacity: number) => {
    var alpha = opacity === undefined ? 0.5 : 1 - opacity;
    let rgba = parse_rgb_string(color);
    return `rgba(${rgba[0]}, ${rgba[1]}, ${rgba[2]}, ${alpha})`;
}

export const calculateMpsByPartialProcessTime = (fullProcessTime: number, partialProcessTime: number, mps: number) => {
    const fromMps = Math.ceil(mps * ((fullProcessTime - partialProcessTime) / fullProcessTime));
    const toMps = mps - fromMps;
    return { fromMps, toMps };
}

export const clearAllDatasetsByPeriodIndex = (productSetupTime: number, periodIndex: number, productId: number, resources: ResourceAggregator[], chartDataByResourceGroup: Dictionary<Dictionary<OccupationChartScenario | undefined>>) => {
    for (let i = 0; i < resources.length; i++) {
        const localResource = resources[i];
        let outputDatasets = chartDataByResourceGroup[localResource.resourceGroupName][localResource.resourceName]?.datasets
        if (outputDatasets) {
            const foundProcessDataset = outputDatasets.find(dataset => dataset.label === `${localResource.id}|${productId}`);
            if (foundProcessDataset) {
                if (foundProcessDataset.data[periodIndex] > 0) {
                    const setupTimeChartDataset = outputDatasets.find(x => x.order === (foundProcessDataset.order - 1))
                    if (setupTimeChartDataset) {
                        setupTimeChartDataset.data[periodIndex] -= productSetupTime
                        //TODO ou estamos calculando o setupErrado ou fazendo algo bem errado
                        if (setupTimeChartDataset.data[periodIndex] < 0)
                            setupTimeChartDataset.data[periodIndex] = 0;
                    }
                }
                foundProcessDataset.data[periodIndex] = 0;
                // return;
            }
        }
    }
}

export const helperHighlightMaterialFlowResourcesClearAll = (state: AppScenarioState) => {
    const completeStructures = state.completeStructures
    for (let i = 0; i < completeStructures.length; i++) {
        const completeStructure = completeStructures[i];
        const planningGrids = state.planningGridsDataByItemId[completeStructure.parentId]
        if (planningGrids !== undefined) {
            for (let k = 0; k < planningGrids.length; k++) {
                const planningGrid = planningGrids[k];
                planningGrid.isMpsHighlighted = false;
                planningGrid.isOpeningStockHighlighted = false;
                planningGrid.mpsHighlightedStatusType = HighlightedStatusType.Normal;
                planningGrid.openingStockHighlightedStatusType = HighlightedStatusType.Normal;
            }
        }
    }
}

export const createChartLabelData = (resourceId: string | number, itemId: string | number, itemCode: number | string, operationId: number | string): string => {
    return `${resourceId}|${itemId}|${itemCode}|${operationId}`;
}

export const getChartLabelData = (label: string): { itemId: number, resourceId: number, itemCode: string, operationId: number } => {
    const splitedLabel = label.split('|');
    return { resourceId: Number(splitedLabel[0]), itemId: Number(splitedLabel[1]), itemCode: splitedLabel[2], operationId: Number(splitedLabel[3]) }
}

export const clearAllDatasetsOnAllResources = (periodLength: number, productId: number, resources: ResourceAggregator[], chartDataByResourceGroup: Dictionary<Dictionary<OccupationChartScenario | undefined>>) => {
    for (let i = 0; i < resources.length; i++) {
        const localResource = resources[i];
        let outputDatasets = chartDataByResourceGroup[localResource.resourceGroupName][localResource.resourceName]?.datasets
        if (outputDatasets) {
            const foundDataset = outputDatasets.find(dataset => dataset.label === `${localResource.id}|${productId}`);
            if (foundDataset) {
                foundDataset.data = Array(periodLength).fill(0);
            }
        }
    }
}
export const calculatePercentageTargetStockByItemId = (
    percentage: number,
    planningGridGlobalValuesByItemId: PlanningGridGlobalValues,
    isWeekly: boolean,
    planningGrids: PlanningGridData[]
) => {

    const localIsTargetStockByDays = planningGridGlobalValuesByItemId.globalTargetStockType
    planningGridGlobalValuesByItemId.globalTargetStock *= percentage
    const targetStock = planningGridGlobalValuesByItemId.globalTargetStock;

    if (localIsTargetStockByDays) {
        for (let index = 0; index < planningGrids.length; index++) {
            const planningGrid = planningGrids[index];
            planningGrid.targetStock = calculateStockDaysToStockUnits(index, targetStock, planningGrids, isWeekly);
            planningGrid.targetDaysOfCover = targetStock;
        }
        //In Days
    } else {
        for (let index = 0; index < planningGrids.length; index++) {
            const planningGrid = planningGrids[index];
            planningGrid.targetStock = targetStock;
            planningGrid.targetDaysOfCover = 0;
        }
    }
}
export const calculateTargetStockByItemId = (
    targetStock: number,
    planningGridGlobalValuesByItemId: PlanningGridGlobalValues,
    isWeekly: boolean,
    planningGrids: PlanningGridData[],
    globalTargetStockType: GlobalPlanningGridTargetType,
) => {

    planningGridGlobalValuesByItemId.globalTargetStockType = globalTargetStockType
    planningGridGlobalValuesByItemId.globalTargetStock = targetStock

    if (globalTargetStockType === GlobalPlanningGridTargetType.UnitaryGlobalTargetValue) {
        for (let index = 0; index < planningGrids.length; index++) {
            const planningGrid = planningGrids[index];
            planningGrid.targetStock = targetStock;
            planningGrid.targetDaysOfCover = 0;
        }
    } else {
        for (let index = 0; index < planningGrids.length; index++) {
            const planningGrid = planningGrids[index];
            planningGrid.targetStock = calculateStockDaysToStockUnits(index, targetStock, planningGrids, isWeekly);
            planningGrid.targetDaysOfCover = targetStock;
        }
        //In Days
    }
}


export const calculatePercentageMinimunStockByItemId = (
    percentage: number,
    planningGridGlobalValuesByItemId: PlanningGridGlobalValues,
    isWeekly: boolean,
    planningGrids: PlanningGridData[]
) => {

    const localIsMinStockByDay = planningGridGlobalValuesByItemId.globalMinStockType;
    planningGridGlobalValuesByItemId.globalMinStock *= percentage
    const minimunStock = planningGridGlobalValuesByItemId.globalMinStock;

    //TODO transformar num metodo que recebe um action()
    if (localIsMinStockByDay) {
        for (let index = 0; index < planningGrids.length; index++) {
            const planningGrid = planningGrids[index];
            planningGrid.minStock = calculateStockDaysToStockUnits(index, minimunStock, planningGrids, isWeekly);
            planningGrid.minDaysOfCover = minimunStock;
        }
        //In Days
    } else {
        for (let index = 0; index < planningGrids.length; index++) {
            const planningGrid = planningGrids[index];
            planningGrid.minStock = minimunStock
            planningGrid.minDaysOfCover = 0;
        }
    }
}

export const calculateMinimunStockByItemId = (
    minimunStock: number,
    planningGridGlobalValuesByItemId: PlanningGridGlobalValues,
    isWeekly: boolean,
    planningGrids: PlanningGridData[],
    globalMinStockType: GlobalPlanningGridTargetType,
) => {

    planningGridGlobalValuesByItemId.globalMinStockType = globalMinStockType
    planningGridGlobalValuesByItemId.globalMinStock = minimunStock

    //TODO transformar num metodo que recebe um action()
    if (globalMinStockType === GlobalPlanningGridTargetType.UnitaryGlobalTargetValue) {
        for (let index = 0; index < planningGrids.length; index++) {
            const planningGrid = planningGrids[index];
            planningGrid.minStock = minimunStock
            planningGrid.minDaysOfCover = 0;
        }
    } else {
        for (let index = 0; index < planningGrids.length; index++) {
            const planningGrid = planningGrids[index];
            planningGrid.minStock = calculateStockDaysToStockUnits(index, minimunStock, planningGrids, isWeekly);
            planningGrid.minDaysOfCover = minimunStock;
        }
        //In Days
    }
}

const getResourcesKeys = (periodId: number, resources: ResourceAggregator[], resourceAllocationsByResourceIdByPeriodId: Dictionary<Dictionary<PlanningGridAllocationData>>) => {
    let resourceIdsWithMps: number[] = []
    const firstResourceKey = Number(Object.keys(resourceAllocationsByResourceIdByPeriodId)[0]);
    for (const [resourceId, resourceAllocationByPeriodId] of Object.entries(resourceAllocationsByResourceIdByPeriodId)) {
        if (resourceAllocationByPeriodId[periodId]?.totalProcessTime > 0) {
            resourceIdsWithMps.push(Number(resourceId));
        }
    }
    const firstResourceFound = resources.filter(resource => resource.id === firstResourceKey)[0];

    return { resourceIdsWithMps, firstResourceFound, firstResourceKey }
}

const insertDataOnIndex = (periodIndex: number, quantity: number, label: string, datasets: ChartDataset[]) => {
    const foundDataset = datasets.find(dataset => dataset.label === label);
    if (foundDataset) {
        foundDataset.data[periodIndex] = quantity
        foundDataset.label = label
    }
}

let itemOrderCount = 0;
const calculateChartOrderByAttributeId = () => {
    return {
        itemOrder: itemOrderCount++,
        setupOrder: itemOrderCount++
    }
}

export const updateOccupationPageByItem = (
    periodLength: number,
    productId: number,
    productAttribute1Id: number,
    mpsPerUnitOfTime: number,
    productSetupTime: number,
    datasetId: number | undefined,
    possibleResources: ResourceAggregator[],
    planningGrids: PlanningGridData[],
    planningGridAllocationsByResourceIdByPeriodId: Dictionary<Dictionary<PlanningGridAllocationData>>,
    periodsData: PeriodData[],
    chartDataByResourceGroup: Dictionary<Dictionary<OccupationChartScenario | undefined>>,
    singlePeriod: boolean = false
) => {
    if (!datasetId) {
        console.error('updateOccupationPageByProduct, datasetId is null');
        return;
    }
    const { setupOrder } = calculateChartOrderByAttributeId();

    const forLength = (singlePeriod ? 1 : planningGrids.length)
    for (let periodIndex = 0; periodIndex < forLength; periodIndex++) {
        const planningGrid = planningGrids[periodIndex];
        const periodId = periodsData[periodIndex].id;

        if (planningGrid.mps === 0) {
            delete planningGridAllocationsByResourceIdByPeriodId[periodId];
            clearAllDatasetsByPeriodIndex(productSetupTime, periodIndex, productId, possibleResources, chartDataByResourceGroup);
            continue;
        }
        if (planningGrid.mps === undefined) {
            console.error(`on updateOccupationPageByItem, planningGrid.mps is null`);
            throw new Error(`on updateOccupationPageByItem, planningGrid.mps is null`)
        }
        const quantityOfTime = Math.round(planningGrid.mps / mpsPerUnitOfTime);
        const { firstResourceFound, firstResourceKey, resourceIdsWithMps } = getResourcesKeys(periodId, possibleResources, planningGridAllocationsByResourceIdByPeriodId);

        //update resourceAllocationsByResourceIdByPeriodId
        if (resourceIdsWithMps.length === 1) {
            planningGridAllocationsByResourceIdByPeriodId[resourceIdsWithMps[0]][periodId].totalProcessTime = quantityOfTime
            planningGridAllocationsByResourceIdByPeriodId[resourceIdsWithMps[0]][periodId].totalSetupTime = productSetupTime
            planningGridAllocationsByResourceIdByPeriodId[resourceIdsWithMps[0]][periodId].totalAllocatedTime = quantityOfTime + productSetupTime;
        } else if (resourceIdsWithMps.length > 1) {
            for (let i = 0; i < resourceIdsWithMps.length; i++) {
                const resourceId = resourceIdsWithMps[i];
                delete planningGridAllocationsByResourceIdByPeriodId[resourceId][periodId];
            }
        }
        if (resourceIdsWithMps.length !== 1) {
            if (!planningGridAllocationsByResourceIdByPeriodId[firstResourceKey]) {
                planningGridAllocationsByResourceIdByPeriodId[firstResourceKey] = {}
            }
            //TODO great-fix
            // planningGridAllocationsByResourceIdByPeriodId[firstResourceKey][periodId] =
            // createResourceAllocation(
            //     datasetId,
            //     periodId,
            //     Number(firstResourceKey),
            //     productId,
            //     quantityOfTime,
            //     productSetupTime
            // )
        }

        //update chartDatasets
        const resourceName = (resourceIdsWithMps.length === 1 ? possibleResources.find(resource => resource.id === resourceIdsWithMps[0])?.resourceName : firstResourceFound?.resourceName) ?? '';
        if (!chartDataByResourceGroup[firstResourceFound.resourceGroupName] ||
            !chartDataByResourceGroup[firstResourceFound.resourceGroupName][resourceName]) {
            continue;
        };
        const outputLabel = `${resourceIdsWithMps.length === 1 ? resourceIdsWithMps[0] : firstResourceKey}|${productId}`;

        let outputDatasets = chartDataByResourceGroup
        [firstResourceFound.resourceGroupName]
        [resourceName]?.datasets

        if (outputDatasets) {
            const outputDataset = outputDatasets.find(dataset => dataset.label === outputLabel);
            if (outputDataset) {

                if (resourceIdsWithMps.length > 1) {
                    clearAllDatasetsOnAllResources(periodLength, productId, possibleResources, chartDataByResourceGroup);
                }

                let outputSetupDataset = outputDatasets.find(x => x.order === setupOrder)
                if (!outputSetupDataset) {
                    outputSetupDataset = {
                        order: setupOrder,
                        type: "bar",
                        label: i18n.t(`Setup`),
                        fill: true,
                        stack: 0,
                        data: Array(periodLength).fill(0),
                        backgroundColor: ['#000000'],
                        barPercentage: 0.7
                    }
                    outputDatasets.push(outputSetupDataset)
                }
                if (outputDataset.data[periodIndex] === 0)
                    outputSetupDataset.data[periodIndex] += productSetupTime

                outputDataset.data[periodIndex] = quantityOfTime
                outputDataset.label = outputLabel

            } else {
                //TODO caso nao seja encontrado o processDataset no outputDatasets, devese adicionar?
            }
        } else {
            throw new Error('updateOccupationPageByProduct, outputDatasets is null')
        }
    }
}

export const updateOccupationPageByPeriodIndex = (
    periodLength: number,
    productId: number,
    periodId: number,
    periodIndex: number,
    unitOfTime: number,
    productSetupTime: number,
    datasetId: number | undefined,
    possibleResources: ResourceAggregator[],
    productBackgroundColor: string,
    resourceAllocationsByResourceIdByPeriodId: Dictionary<Dictionary<PlanningGridAllocationDetails>>,
    chartDataByResourceGroup: Dictionary<Dictionary<OccupationChartScenario | undefined>>
) => {
    if (!datasetId) {
        console.error('updateOccupationPageByPeriodIndex, datasetId is null');
        return;
    }
    const { firstResourceFound, firstResourceKey, resourceIdsWithMps } = getResourcesKeys(periodId, possibleResources, resourceAllocationsByResourceIdByPeriodId);
    //if there is not resources with ProcessTime we should create on the first resource
    if (resourceIdsWithMps.length === 0) {
        //redux update
        if (!resourceAllocationsByResourceIdByPeriodId[firstResourceKey]) {
            resourceAllocationsByResourceIdByPeriodId[firstResourceKey] = {}
        }
        //TODO great-fix
        // resourceAllocationsByResourceIdByPeriodId[firstResourceKey][periodId] =
        //     createResourceAllocation(
        //         datasetId,
        //         periodId,
        //         Number(firstResourceKey),
        //         productId,
        //         unitOfTime,
        //         productSetupTime
        //     )

        //chart update
        let outputDatasets = chartDataByResourceGroup[firstResourceFound.resourceGroupName][firstResourceFound.resourceName]?.datasets
        if (outputDatasets) {
            const outputLabel = `${firstResourceKey}|${productId}`;
            const foundDatasetIndex = outputDatasets.findIndex(dataset => dataset.label === outputLabel);
            if (foundDatasetIndex !== -1) {
                const outputResourceDataset = outputDatasets[foundDatasetIndex]
                outputResourceDataset.data[periodIndex] = unitOfTime + productSetupTime
                outputResourceDataset.label = outputLabel
            } else {
                const outputResourceDataset: ChartDataset = {
                    order: 50,
                    type: "bar",
                    label: outputLabel,
                    fill: true,
                    stack: 0,
                    data: Array(periodLength).fill(0),
                    backgroundColor: [productBackgroundColor],
                    borderColor: ['rgba(0, 0, 0, 1)'],
                    borderWidth: 0,
                }
                outputResourceDataset.data[periodIndex] = unitOfTime + productSetupTime;
            }

        }
        //If there is only one resource with processTime we should add the unitOfTime to the resource
    } else if (resourceIdsWithMps.length === 1) {
        //redux update
        resourceAllocationsByResourceIdByPeriodId[resourceIdsWithMps[0]][periodId].totalProcessTime = unitOfTime
        resourceAllocationsByResourceIdByPeriodId[resourceIdsWithMps[0]][periodId].totalAllocatedTime = unitOfTime + productSetupTime;

        //chart update
        const outputLabel = `${firstResourceKey}|${productId}`;
        let outputDatasets = chartDataByResourceGroup[firstResourceFound.resourceGroupName][firstResourceFound.resourceName]?.datasets
        if (outputDatasets) {
            const foundDatasetIndex = outputDatasets.findIndex(dataset => dataset.label === outputLabel);
            if (foundDatasetIndex !== -1) {
                const outputResourceDataset = outputDatasets[foundDatasetIndex]
                outputResourceDataset.data[periodIndex] = unitOfTime + productSetupTime
                outputResourceDataset.label = outputLabel
            }
        }
        //If there are many resources with processTime we should add the unitOfTime to the first resource we found
    } else {
        //Clean all unitOfTime from this period on all resources
        for (let i = 0; i < resourceIdsWithMps.length; i++) {
            const resourceId = resourceIdsWithMps[i];
            delete resourceAllocationsByResourceIdByPeriodId[resourceId][periodId];
        }
        //redux update
        //TODO great-fix
        // resourceAllocationsByResourceIdByPeriodId[firstResourceKey][periodId] =
        //     createResourceAllocation(
        //         datasetId,
        //         periodId,
        //         Number(firstResourceKey),
        //         productId,
        //         unitOfTime,
        //         productSetupTime
        //     )

        //chart update                
        const outputLabel = `${firstResourceKey}|${productId}`;

        //need to clean all periods on all resources
        for (let i = 0; i < possibleResources.length; i++) {
            const localResource = possibleResources[i];
            let outputDatasets = chartDataByResourceGroup[localResource.resourceGroupName][localResource.resourceName]?.datasets
            if (outputDatasets) {
                const foundDatasetIndex = outputDatasets.findIndex(dataset => dataset.label === `${localResource.id}|${productId}`);
                if (foundDatasetIndex !== -1) {
                    outputDatasets[foundDatasetIndex].data = Array(periodLength).fill(0);
                }
            }
        }

        //fill the first period on the first resource
        let outputDatasets = chartDataByResourceGroup[firstResourceFound.resourceGroupName][firstResourceFound.resourceName]?.datasets
        if (outputDatasets) {
            insertDataOnIndex(periodIndex, unitOfTime + productSetupTime, outputLabel, outputDatasets);
        }
    }
}

export const createCompleteStructureNodes = (completeStructuresByItemMaster: Dictionary<CompleteStructureData[]>): {
    completeStructureNodes: Dictionary<Dictionary<CompleteStructureNode>>,
    completeStructureMaxLevel: Dictionary<number>,
    completeStructureOrderDataByItemMestre: Dictionary<{ isExpanded: boolean, data: { itemId: number, level: number }[] }>
} => {
    const completeStructureNodes: Dictionary<Dictionary<CompleteStructureNode>> = {}
    const completeStructureMaxLevel: Dictionary<number> = {}
    const completeStructureOrderDataByItemMestre: Dictionary<{ isExpanded: boolean, data: { itemId: number, level: number }[] }> = {}

    for (const [itemMasterId, completeStructureDatas] of Object.entries(completeStructuresByItemMaster)) {
        completeStructureNodes[itemMasterId] = {}
        let baseCompleteStructureNode: CompleteStructureNode = completeStructureDatas[0]
        if (!baseCompleteStructureNode.nodes) baseCompleteStructureNode.nodes = {};
        for (let i = 0; i < completeStructureDatas.length; i++) {
            const completeStructureNode: CompleteStructureNode = completeStructureDatas[i];
            if (!completeStructureOrderDataByItemMestre[itemMasterId]) {
                completeStructureOrderDataByItemMestre[itemMasterId] = {
                    isExpanded: false,
                    data: []
                }
            }

            const billOfMaterialKeys = completeStructureNode.stringBOM.split('.');
            if (i === 0) {
                completeStructureMaxLevel[itemMasterId] = billOfMaterialKeys.length;
                completeStructureNodes[itemMasterId][billOfMaterialKeys[0]] = completeStructureNode;
                continue;
            } else {
                completeStructureOrderDataByItemMestre[itemMasterId].data.push({ itemId: completeStructureNode.childId, level: completeStructureNode.level });
            }

            if (completeStructureMaxLevel[itemMasterId] < billOfMaterialKeys.length) {
                completeStructureMaxLevel[itemMasterId] = billOfMaterialKeys.length;
            }

            for (let j = 1; j < billOfMaterialKeys.length; j++) {
                const bomKey = billOfMaterialKeys[j];
                if (!bomKey) continue;
                if (!baseCompleteStructureNode.nodes[bomKey]) {
                    baseCompleteStructureNode.nodes[bomKey] = completeStructureDatas[i];
                }
            }

        }
    }
    return { completeStructureNodes, completeStructureMaxLevel, completeStructureOrderDataByItemMestre };
}

export const createAttributesFilter = (itemIds: number[], itemById: Dictionary<ItemData>, itemAttributes: ItemAttributesBundle) => {
    const localAttributesFilter: ItemAttributesCustomBundle<number[]> = {
        ItemAttribute1: {},
        ItemAttribute2: {},
        ItemAttribute3: {},
        ItemAttribute4: {},
        ItemAttribute5: {},
        ItemAttribute6: {},
        ItemAttribute7: {},
        ItemAttribute8: {},
        ItemAttribute9: {},
        ItemAttribute10: {},
    }
    const pushToGenericAttribute = <TAttribute>(itemId: number, itemAttribute: TAttribute, attributeId?: number) => {
        if (!attributeId) return
        if (!itemAttribute[attributeId]) {
            itemAttribute[attributeId] = []
        }

        itemAttribute[attributeId].push(itemId);
    }

    for (let i = 0; i < itemIds.length; i++) {
        const item = itemById[itemIds[i]];
        if (!item) continue
        pushToGenericAttribute(item.id, localAttributesFilter.ItemAttribute1, item.itemAttribute1Id);
        pushToGenericAttribute(item.id, localAttributesFilter.ItemAttribute2, item.itemAttribute2Id);
        pushToGenericAttribute(item.id, localAttributesFilter.ItemAttribute3, item.itemAttribute3Id);
        pushToGenericAttribute(item.id, localAttributesFilter.ItemAttribute4, item.itemAttribute4Id);
        pushToGenericAttribute(item.id, localAttributesFilter.ItemAttribute5, item.itemAttribute5Id);
        pushToGenericAttribute(item.id, localAttributesFilter.ItemAttribute6, item.itemAttribute6Id);
        pushToGenericAttribute(item.id, localAttributesFilter.ItemAttribute7, item.itemAttribute7Id);
        pushToGenericAttribute(item.id, localAttributesFilter.ItemAttribute8, item.itemAttribute8Id);
        pushToGenericAttribute(item.id, localAttributesFilter.ItemAttribute9, item.itemAttribute9Id);
        pushToGenericAttribute(item.id, localAttributesFilter.ItemAttribute10, item.itemAttribute10Id);

    }
    return localAttributesFilter;
}

export const createResourcesSelection = (planningGroups: ResourceGroupDetails[]) => {
    let resourceSelection: ResourceAggregator[] = []
    for (let i = 0; i < planningGroups.length; i++) {
        const planningGroup = planningGroups[i];
        for (let a = 0; a < planningGroup.resources.length; a++) {
            const planningResource = planningGroup.resources[a];
            resourceSelection.push({
                resourceGroupName: planningGroup.name,
                resourceName: planningResource.name,
                id: planningResource.id,
                finiteCapacity: planningResource.finiteCapacity
            })
        }
    }
    return resourceSelection;
}



export const createResourcesSelectionWithProcessTime = (planningGridAllocation: Dictionary<Dictionary<Dictionary<PlanningGridAllocationDetails>>>) => {
    let resourceSelection: ResourceAggregator[] = []

    // console.log(planningGridAllocation)
    // for (let i = 0; i < planningGroups.length; i++) {
    //     const planningGroup = planningGroups[i];
    //     for (let a = 0; a < planningGroup.resources.length; a++) {
    //         const planningResource = planningGroup.resources[a];
    //         resourceSelection.push({
    //             resourceGroupName: planningGroup.name,
    //             resourceName: planningResource.name,
    //             id: planningResource.id,
    //             finiteCapacity: planningResource.finiteCapacity
    //         })
    //     }
    // }
    return resourceSelection;
}





export const hexToRGBA = (hex: string, alpha: number = 1.0) => {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
}

export const simpleRandomColor = () => {
    let letters = '0123456789ABCDEF'.split('');
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

export const splitChartLabel = (label: string): { itemId: number, resourceId: number } => {
    const splitedLabel = label.split('|');
    return { resourceId: Number(splitedLabel[0]), itemId: Number(splitedLabel[1]) }
}

export const createOccupationChartData = (
    colorByProductId: Dictionary<string>,
    periods: PeriodData[],
    planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId: Dictionary<Dictionary<Dictionary<Dictionary<PlanningGridAllocationDetails>>>>,
    capacityByPeriodIdByResourceId: Dictionary<Dictionary<CapacityData>>,
    addedResource: ResourceAggregator,
    minifiedPeriods: string[],
    productIdByAttributes: ItemAttributesCustomBundle<number[]>,
    itemById: Dictionary<ItemData>
): OccupationChartScenario => {
    let capacity: number[] = [];
    //Capacity
    for (let a = 0; a < periods.length; a++) {
        let period = periods[a];
        let resourceCapacityByResourceId = capacityByPeriodIdByResourceId[period.id]
        if (!resourceCapacityByResourceId) continue
        //resourceCapacity of a period on a determined resource
        let resourceCapacity = resourceCapacityByResourceId[addedResource.id];
        capacity.push(resourceCapacity.totalAvailableHours);
    }

    let capacityLine = {
        order: 1,
        type: "line",
        label: t({ message: 'Capacidade Disponivel', comment: 'Label da capacidade na OccupationPage' }),
        fill: true,
        data: capacity,
        backgroundColor: ['rgba(156, 163, 175,0.3)'],
        borderColor: ['rgba(255, 99, 132, 1)'],
        pointBackgroundColor: ['rgba(255, 99, 132, 1)'],
        pointBorderWidth: 1,
        pointStyle: "rectRot",
        radius: 6,
        dragData: true,
        pointHitRadius: 25
    }


    //AllocatedResources
    let itemProcessTimeByItemIdByPeriodRank: Dictionary<number[]> = {}
    let itemSetupTimeByItemIdByPeriodRank: Dictionary<number[]> = {}
    const orderedPeriodIds: Dictionary<number> = {}

    for (let i = 0; i < periods.length; i++) {
        const period = periods[i];
        orderedPeriodIds[period.id] = period.rank;
    }

    const itemIdsToResourceIds: Dictionary<string> = {}
    const parsedAddedResourceId = String(addedResource.id);
    for (const [itemId, planningGridAllocationByResourceIdByOperationIdByPeriodId] of Object.entries(planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId)) {
        for (const [resourceId, planningGridAllocationByOperationIdByPeriodId] of Object.entries(planningGridAllocationByResourceIdByOperationIdByPeriodId)) {
            if (parsedAddedResourceId !== resourceId) continue;

            for (const [operationId, planningGridAllocationByPeriodId] of Object.entries(planningGridAllocationByOperationIdByPeriodId)) {
                for (const [periodId, planningGridAllocation] of Object.entries(planningGridAllocationByPeriodId)) {
                    if (!itemProcessTimeByItemIdByPeriodRank[itemId]) {
                        itemProcessTimeByItemIdByPeriodRank[itemId] = Array(periods.length).fill(0)
                        itemSetupTimeByItemIdByPeriodRank[itemId] = Array(periods.length).fill(0)
                    }
                    itemIdsToResourceIds[itemId] = createChartLabelData(resourceId, itemId, itemById[itemId].itemCode, operationId);
                    itemProcessTimeByItemIdByPeriodRank[itemId][orderedPeriodIds[periodId]] += planningGridAllocation.totalProcessTime;
                    itemSetupTimeByItemIdByPeriodRank[itemId][orderedPeriodIds[periodId]] += planningGridAllocation.totalSetupTime;
                }
            }
        }
    }
    let resourceAllocation: any[] = []

    //IMPORTANT!
    //Used attribute to group setup time
    //itemAttribute 
    const attributeToGroupItems = handleSelectItemAttributeToGroupModule(productIdByAttributes, Object.keys(itemById).map(Number));

    // TODO o que vai definir diferentes setupTimes?
    for (const [attributeId, itemIds] of Object.entries(attributeToGroupItems)) {
        const { itemOrder, setupOrder } = calculateChartOrderByAttributeId();

        let setupByAttribute: number[] = []

        for (let i = 0; i < itemIds.length; i++) {
            const itemId = itemIds[i];

            if (!itemSetupTimeByItemIdByPeriodRank[itemId]) continue;

            if (setupByAttribute.length === 0) setupByAttribute = Array(periods.length).fill(0)

            for (let k = 0; k < itemSetupTimeByItemIdByPeriodRank[itemId].length; k++) {
                if (setupByAttribute[k] === 0) {
                    const setupTime = itemSetupTimeByItemIdByPeriodRank[itemId][k];
                    setupByAttribute[k] += setupTime;
                }
            }

            const processTimeData = itemProcessTimeByItemIdByPeriodRank[itemId];
            resourceAllocation.push({
                order: itemOrder,
                type: "bar",
                label: itemIdsToResourceIds[itemId],
                fill: true,
                stack: 0,
                data: processTimeData,
                backgroundColor: colorByProductId[itemId],
                borderColor: ['rgba(0, 0, 0, 1)'],
                borderRadius: 4,
                dragData: false
            })
        }

        // if (resourceAllocation.length === 0) {
        //     throw Error('The productIdByAttributes.itemAttribute(N) returned no values for the occupationChart')
        // }

        if (setupByAttribute.length > 0) {
            resourceAllocation.push({
                order: setupOrder,
                type: "bar",
                label: i18n.t(`Setup`),
                fill: true,
                stack: 0,
                data: setupByAttribute,
                backgroundColor: '#000000',
                barPercentage: 0.7,
                dragData: false
            })
        }
    }

    let localChartData: any[] = []
    localChartData.push(capacityLine)
    for (let i = 0; i < resourceAllocation.length; i++) {
        localChartData.push(resourceAllocation[i])
    }

    let occupationChartDataset: OccupationChartScenario = {
        datasets: localChartData,
        labels: minifiedPeriods,
        stacked: true
    }
    return occupationChartDataset;
}

export const getResourceInfoById = (resourceId: number, resourceGroupById: Dictionary<ResourceGroupDetails>) => {
    for (const planningGroup of Object.values(resourceGroupById)) {
        for (let k = 0; k < planningGroup.resources.length; k++) {
            const planningResource = planningGroup.resources[k];
            if (planningResource.id === resourceId) return { planningGroupName: planningGroup.name, planningResource: planningResource };
        }
    }
    return { planningGroupName: undefined, planningResource: undefined };
}


export const getPossibleResources = (resourceAllocationsByResourceIdByPeriodId: Dictionary<Dictionary<PlanningGridAllocationDetails>>, resourceGroupById: Dictionary<ResourceGroupDetails>): ResourceAggregator[] => {
    const firstResourceId = Number(Object.keys(resourceAllocationsByResourceIdByPeriodId)[0]);
    // const firstPeriodId = Number(Object.keys(resourceAllocationsByResourceIdByPeriodId[firstResourceId])[0]);
    //TODO great-fix > pego somente o primeiro, tem que construir metodo novo
    // const firstResourceId = resourceAllocationsByResourceIdByPeriodId[resourceId][firstPeriodId].resourceId[0].id;
    let resources: ResourceAggregator[] = [];

    for (const resourceGroup of Object.values(resourceGroupById)) {
        for (let j = 0; j < resourceGroup.resources.length; j++) {
            const resource = resourceGroup.resources[j];
            if (resource.id === firstResourceId) {
                for (let k = 0; k < resourceGroup.resources.length; k++) {
                    const selectedResource = resourceGroup.resources[k];
                    resources.push({
                        id: selectedResource.id,
                        resourceGroupName: resourceGroup.name,
                        resourceName: selectedResource.name,
                        finiteCapacity: selectedResource.finiteCapacity
                    })
                }
                break;
            }
        }
    }

    return resources;
}

//TODO é necessário?
const getoutputChartTransferData = (
    inputProductId: number,
    outputResourceAllocationId: number,
    outputDatasets: ChartDataset[])
    : {
        outputDataset: ChartDataset,
        outputLabel: string
    } => {
    // const inputProductId = inputResourceDataset.label.split('|')[1];
    let outputDataset = outputDatasets.filter(x => x.label.split('|')[1] === inputProductId.toString())[0]
    if (isNaN(outputResourceAllocationId)) {
        //TODO NAO gostei da Gambiarrinha
        outputResourceAllocationId = Number(outputDatasets[outputDatasets.length - 1].label.split('|')[0]);
    }
    const outputLabel = `${outputResourceAllocationId}|${inputProductId}`;

    return { outputDataset, outputLabel }
}

export const transferChartData = (
    isSameChart: boolean,
    periodLength: number,
    inputTotalProcessTime: number,
    inputTotalSetupTime: number,
    inputDatasets: ChartDataset[],
    inputChartPeriodIndex: number,
    inputDatasetsIndex: number,
    outputChartPeriodIndex: number,
    outputDatasets: ChartDataset[],
    outputResourceId: number,
    partialValueToTransfer?: number
) => {
    const inputChartDatasetsIndex = inputDatasetsIndex;
    const inputDataset = inputDatasets[inputChartDatasetsIndex]
    const processDatasetOrder = inputDatasets[inputChartDatasetsIndex].order;
    const setupDatasetOrder = processDatasetOrder - 1;

    const inputSetupTimeDataset = outputDatasets.find(x => x.order === (setupDatasetOrder))
    if (!inputSetupTimeDataset) {
        throw new Error("Couldn't find the item's setup time on this chart!")
    }

    if (isSameChart) {
        const chartSetupTimeData = inputSetupTimeDataset.data
        const chartProcessTimeData = inputDataset.data
        let restOfInputTotalProcessTime = 0

        if (chartProcessTimeData[outputChartPeriodIndex] === 0)
            chartSetupTimeData[outputChartPeriodIndex] += inputTotalSetupTime

        if (partialValueToTransfer) {
            restOfInputTotalProcessTime = inputTotalProcessTime - partialValueToTransfer
            chartProcessTimeData[outputChartPeriodIndex] += partialValueToTransfer
        } else {
            chartSetupTimeData[inputChartPeriodIndex] -= inputTotalSetupTime
            chartProcessTimeData[outputChartPeriodIndex] += inputTotalProcessTime
        }
        chartProcessTimeData[inputChartPeriodIndex] = restOfInputTotalProcessTime

    } else {

        const { itemId } = splitChartLabel(inputDataset.label)
        const { outputLabel, outputDataset } = getoutputChartTransferData(itemId, outputResourceId, outputDatasets);

        let outputSummedSetupTimeChartDataset = outputDatasets.find(x => x.order === (setupDatasetOrder))
        let processTimeToTransfer = 0;
        let inputProcessTimeToSet = 0;

        if (partialValueToTransfer && partialValueToTransfer !== inputTotalProcessTime) {
            inputProcessTimeToSet = inputTotalProcessTime - partialValueToTransfer
            processTimeToTransfer = partialValueToTransfer

        } else {
            inputSetupTimeDataset.data[inputChartPeriodIndex] -= inputTotalSetupTime
            processTimeToTransfer = inputTotalProcessTime
        }

        inputDataset.data[inputChartPeriodIndex] = inputProcessTimeToSet
        if (outputDataset && outputSummedSetupTimeChartDataset) {
            if (outputDataset.data[outputChartPeriodIndex] === 0)
                outputSummedSetupTimeChartDataset.data[outputChartPeriodIndex] += inputTotalSetupTime

            outputDataset.data[outputChartPeriodIndex] += processTimeToTransfer
            outputDataset.label = outputLabel
        } else {
            addNewChartDatasetToOutputDataset(
                inputDataset,
                inputSetupTimeDataset,
                periodLength,
                processTimeToTransfer,
                inputTotalSetupTime,
                outputLabel,
                outputChartPeriodIndex,
                outputDatasets);
        }
    }
}

export const addNewChartDatasetToOutputDataset = (
    inputResourceDataset: ChartDataset,
    inputSetupDataset: ChartDataset,
    periodLength: number,
    inputTotalProcessTime: number,
    inputTotalSetupTime: number,
    outputLabel: string,
    toChartPeriodIndex: number,
    outputDatasets: ChartDataset[]
) => {
    let newOutputDataset: ChartDataset = initializeChartDataset(inputResourceDataset, periodLength);
    newOutputDataset.data[toChartPeriodIndex] = inputTotalProcessTime
    newOutputDataset.label = outputLabel
    outputDatasets.push(newOutputDataset)

    let outputSummedSetupTimeData = outputDatasets.find(x => x.order === inputSetupDataset.order)

    if (outputSummedSetupTimeData) {
        outputSummedSetupTimeData.data[toChartPeriodIndex] += inputTotalSetupTime
    } else {
        let newSetupOutputDataset: ChartDataset = initializeChartDataset(inputSetupDataset, periodLength);
        newSetupOutputDataset.data[toChartPeriodIndex] = inputTotalSetupTime
        outputDatasets.push(newSetupOutputDataset)
    }

}

const initializeChartDataset = (chartDataset: ChartDataset, periodLength: number) => {
    return {
        ...chartDataset,
        data: Array(periodLength).fill(0)
    }
}

interface PeriodSlicer {
    fromIndex: number
    toIndex: number
}


export const createItemPlannerSearchTree = (
    itemIdsByItemPlannerIds: Dictionary<number[]>,
    itemPlannerById: Dictionary<ItemPlanner>,
): TreeNodeProps => {
    let attributeTree: TreeNodeProps[] = []
    let totalItems = 0;

    for (const [itemPlannerId, itemIds] of Object.entries(itemIdsByItemPlannerIds)) {
        if (itemPlannerById[Number(itemPlannerId)] !== undefined) {
            totalItems += itemIds.length;
            attributeTree.push({
                label: `${itemPlannerById[Number(itemPlannerId)].name} (${itemIds.length})`,
                value: `${itemPlannerById[Number(itemPlannerId)].id}`,
            })
        }
    }
    return {
        label: t({ message: `Todos (${totalItems})` }),
        value: 'All',
        children: attributeTree
    }
}

export const createItemStockGroupSearchTree = (
    itemIdsByStockGroupIds: Dictionary<number[]>,
    itemStockGroupById: Dictionary<ItemStockGroup>,
): TreeNodeProps => {
    let attributeTree: TreeNodeProps[] = []
    let totalItems = 0;

    for (const [itemstockGroupId, itemIds] of Object.entries(itemIdsByStockGroupIds)) {
        if (itemStockGroupById[Number(itemstockGroupId)] !== undefined) {
            totalItems += itemIds.length;
            attributeTree.push({
                label: `${itemStockGroupById[Number(itemstockGroupId)].name} (${itemIds.length})`,
                value: `${itemStockGroupById[Number(itemstockGroupId)].id}`,
            })
        }
    }
    return {
        label: t({ message: `Todos (${totalItems})` }),
        value: 'All',
        children: attributeTree
    }
}
// export const createItemLevelSearchTree =(itemIdsByLevel:Dictionary<number[]>, itemById:Dictionary<ItemData>):TreeNodeProps =>{

//     let attributeTree: TreeNodeProps[] = []
//     let totalItems = 0;

//     for (const [itemLevel, itemIds] of Object.entries(itemIdsByLevel)) {
//             totalItems += itemIds.length;
//             attributeTree.push({
//                 label: `${itemLevel} (${itemIds.length})`,
//                 value: `${itemIds}`,
//         })
//     }
//     return {
//         label: t({ message: `Todos (${totalItems})` }),
//         value: 'All',
//         children: attributeTree
//     }
// }


export const createStockStatusWithPeriodTypeSearchTree = (
    periodsIdToIndex: Dictionary<number>,
    itemIdsByStockStatusByPeriod: StockStatusDictionary<Dictionary<number[]>>,
    minifiedPeriods: string[],
    periods: PeriodData[]
): TreeNodeProps => {
    const itemsTree: TreeNodeProps[] = [];
    for (const [stockStatusName, itemIdsByPeriodId] of Object.entries(itemIdsByStockStatusByPeriod)) {
        const uniqueItems = new Set<number>();
        const localItemsTree: TreeNodeProps = {
            label: '',
            value: `${stockStatusName}`
        };

        localItemsTree.children = []
        // let counter = 0
        for (let i = 0; i < periods.length; i++) {
            const periodId = periods[i].id;
            const periodIndex = periodsIdToIndex[periodId]
            const itemIds = itemIdsByPeriodId[periodId]

            if (itemIds) {
                localItemsTree.children.push({
                    label: `${minifiedPeriods[periodIndex]} (${itemIds.length})`,
                    value: `${stockStatusName}|${periodId}`
                })
                for (let a = 0; a < itemIds.length; a++) {
                    const itemId = itemIds[a];
                    if (!uniqueItems.has(itemId)) {
                        uniqueItems.add(itemId)
                    }
                }
            } else {
                localItemsTree.children.push({
                    label: `${minifiedPeriods[periodIndex]} (0)`,
                    value: '',
                    disabled: true
                })
            }
        }
        localItemsTree.label = `${stockStatusName} (${uniqueItems.size})`
        itemsTree.push(localItemsTree);
    }

    return {
        label: t({ message: 'Todos' }),
        value: 'All',
        children: itemsTree
    }
}

export const createItemLevelSearchTree = (itemIdsByLevel: Dictionary<number[]>): TreeNodeProps => {
    const itemsTree: TreeNodeProps[] = [];

    for (const [level, itemIds] of Object.entries(itemIdsByLevel)) {
        itemsTree.push({
            label: `${level} (${itemIds.length})`,
            value: `${level}`,
        })
    }

    return {
        label: t({ message: 'Todos' }),
        value: 'All',
        children: itemsTree
    }
}

export const createStockStatusTypeSearchTree = (itemIdsByStockStatus: StockStatusDictionary<number[]>): TreeNodeProps => {
    const itemsTree: TreeNodeProps[] = [];

    for (const [stockStatusName, itemIds] of Object.entries(itemIdsByStockStatus)) {
        itemsTree.push({
            label: `${stockStatusName} (${itemIds.length})`,
            value: `${stockStatusName}`,
        })
    }

    return {
        label: t({ message: 'Todos' }),
        value: 'All',
        children: itemsTree
    }
}

export const createItemCodeSearchTree = (itemById: Dictionary<ItemData>, itemsIdsWithPlanningGrid: number[]): TreeNodeProps => {
    const itemsTree: TreeNodeProps[] = Object.values(itemsIdsWithPlanningGrid).map(itemId => {
        return {
            label: itemById[itemId].itemCode,
            value: `${itemById[itemId].id}`
        }
    });

    return {
        label: t({ message: 'Todos' }),
        value: 'All',
        children: itemsTree
    }
}

export const createSimpleAttributesTreeWithId = (attribute: Dictionary<ItemAttribute>, itemIdsByAttributeId: Dictionary<number[]>): TreeNodeProps => {
    let attributeTree: TreeNodeProps[] = []
    let totalItems = 0;
    for (const [attributeId, itemIds] of Object.entries(itemIdsByAttributeId)) {
        totalItems += itemIds.length;
        attributeTree.push({
            label: `${attribute[Number(attributeId)].id} (${itemIds.length})`,
            value: `${attributeId}`,

        })
    }
    return {
        label: t({ message: `Todos (${totalItems})` }),
        value: 'All',
        children: attributeTree
    }
}

export const createSimpleAttributesTree = (attribute: Dictionary<ItemAttribute>, itemIdsByAttributeId: Dictionary<number[]>): TreeNodeProps => {
    let attributeTree: TreeNodeProps[] = []
    let totalItems = 0;

    for (const [attributeId, itemIds] of Object.entries(itemIdsByAttributeId)) {
        totalItems += itemIds.length;
        attributeTree.push({
            label: `${attribute[Number(attributeId)].name} (${itemIds.length})`,
            value: `${attributeId}`,

        })
    }
    return {
        label: t({ message: `Todos (${totalItems})` }),
        value: 'All',
        children: attributeTree
    }
}


export const createItemCodeAttributesTree = (attribute: Dictionary<ItemAttribute>, itemIdsByAttributeId: Dictionary<number[]>, itemById: Dictionary<ItemData>): TreeNodeProps => {
    const attributeTree: TreeNodeProps[] = Object.entries(itemIdsByAttributeId).map(attributeKeyValue => {
        return {
            label: `${attribute[Number(attributeKeyValue[0])].name} (${attributeKeyValue[1].length})`,
            value: `${attributeKeyValue[0]}`,
            children: attributeKeyValue[1].map(itemId => {
                return {
                    label: itemById[itemId].itemCode,
                    value: `${attributeKeyValue[0]}|${itemId}`
                }
            })
        }
    });

    return {
        label: t({ message: `Todos (${Object.keys(itemIdsByAttributeId).length})` }),
        value: 'All',
        children: attributeTree
    }

}

export const createAttributesTree = (attributes: ItemAttributesCustomBundle<ItemAttribute>): TreeNodeProps => {
    const attributeTree: TreeNodeProps[] = Object.values(attributes.ItemAttribute1).map(attribute1 => {
        return {
            label: attribute1.name,
            value: `${attribute1.id}`,
            children: Object.values(attributes.ItemAttribute2).map(attribute2 => {
                return {
                    label: attribute2.name,
                    value: `${attribute1.id}|${attribute2.id}`,
                    children: Object.values(attributes.ItemAttribute3).map(attribute3 => {
                        return {
                            label: attribute3.name,
                            value: `${attribute1.id}|${attribute2.id}|${attribute3.id}`
                        }
                    })
                }
            })
        }
    });

    return {
        label: t({ message: 'Todos' }),
        value: 'All',
        children: attributeTree
    }

}
export const createSelectedAttributeFilters = (selectedAttributes: any, attributeList: any) => {
    if (selectedAttributes.length === 0) {
        const selectedAttributesFiltered = attributeList.map((value, index) => {
            return {
                attributeCode: value.attributeCode,
                attributeName: value.attributeName,
            };
        }).filter((c) => c !== undefined);
        return selectedAttributesFiltered
    }
    if (selectedAttributes[0].attributeCode === 'none') {
        return []
    } else {
        const selectedAttributesFiltered = selectedAttributes.map((value, index) => {
            return {
                attributeCode: value.attributeCode,
                attributeName: value.attributeName,
            };
        }).filter((c) => c !== undefined);
        return selectedAttributesFiltered
    }
}

export const createSelectedFixedCriteriaFilters = (selectedFixedCriteriaOptions: FixedFilterData[], FixedCriteriaFilterOptions: FixedFilterData[]): FixedFilterData[] => {
    if (selectedFixedCriteriaOptions.length === 0) {
        const selectedFixedCriteriaFiltered: FixedFilterData[] = FixedCriteriaFilterOptions.map((value, index) => {
            return {
                filterId: value.filterId,
                filterCriteria: FilterCriteria[value.filterCriteria],
                label: value.label,
                placeholder: value.placeholder,
                checked: true
            };
        }).filter((c) => c !== undefined);
        return selectedFixedCriteriaFiltered
    }
    if (selectedFixedCriteriaOptions[0].filterCriteria === 'none') {
        return []
    } else {
        const selectedFixedCriteriaFiltered: FixedFilterData[] = selectedFixedCriteriaOptions.map((value, index) => {
            return {
                filterId: value.filterId,
                filterCriteria: FilterCriteria[value.filterCriteria],
                label: value.label,
                checked: value.checked,
                placeholder: value.placeholder
            };
        }).filter((c) => c !== undefined);
        return selectedFixedCriteriaFiltered
    }

}

// export const createAttributesTree = (attributes: ItemAttributesBundle<ItemAttribute>): TreeNodeProps => {
//     const attributeTree: TreeNodeProps[] = Object.values(attributes.itemAttribute1).map(attribute1 => {
//         return {
//             label: attribute1.name,
//             value: `${attribute1.id}`,
//             children: Object.values(attributes.itemAttribute2).map(attribute2 => {
//                 return {
//                     label: attribute2.name,
//                     value: `${attribute1.id}|${attribute2.id}`,
//                     children: Object.values(attributes.itemAttribute3).map(attribute3 => {
//                         return {
//                             label: attribute3.name,
//                             value: `${attribute1.id}|${attribute2.id}|${attribute3.id}`
//                         }
//                     })
//                 }
//             })
//         }
//     });

//     return {
//         label: t({ message: 'Todos' }),
//         value: 'All',
//         children: attributeTree
//     }

// }

export const calculateWeeklyToMontlyPlanningGrids = (planningGrids: Dictionary<PlanningGridData[]>, complexPeriods: PeriodDataOld[]) => {
    let monthlyPlanningGridDataByProductId: Dictionary<PlanningGridData[]> = {}
    let periodIndex = 0;
    let periodSlicers: PeriodSlicer[] = []
    for (let i = 0; i < complexPeriods.length; i++) {
        const period = complexPeriods[i];
        periodSlicers.push({
            fromIndex: periodIndex,
            toIndex: periodIndex + period.periods.length
        })
        periodIndex += period.periods.length;
    }

    for (var key in planningGrids) {
        const planningGridsByProductData = planningGrids[key];
        let monthlyPlanningGridsByProductId: PlanningGridData[] = []
        for (let a = 0; a < periodSlicers.length; a++) {
            const periodSlicer = periodSlicers[a];
            let slicedPlanningGrids = planningGridsByProductData.slice(periodSlicer.fromIndex, periodSlicer.toIndex);
            //TODO verificar o  porque planningGrid tem menos periodos!
            if (slicedPlanningGrids.length < 1) continue;
            const lastPlanningGrid = slicedPlanningGrids[slicedPlanningGrids.length - 1];

            //TODO great-fix
            let planningGrid: PlanningGridData = { ...lastPlanningGrid };
            planningGrid.id = 0
            planningGrid.demand = slicedPlanningGrids.map(x => x.demand ?? 0).reduce((sum, a) => sum + a, 0)
            planningGrid.locked = true
            planningGrid.mps = slicedPlanningGrids.map(x => x.mps ?? 0).reduce((sum, a) => sum + a, 0)
            planningGrid.openingStock = slicedPlanningGrids[0].openingStock
            planningGrid.orders = slicedPlanningGrids.map(x => x.orders ?? 0).reduce((sum, a) => sum + a, 0)
            // planningGrid.isMinStockByDay = false
            // planningGrid.isTargetStockByDay = false
            // planningGrid.targetStockByDays = 0
            // planningGrid.minStockByDays = 0

            monthlyPlanningGridsByProductId.push(planningGrid)
        }
        monthlyPlanningGridDataByProductId[key] = monthlyPlanningGridsByProductId
    }
    return monthlyPlanningGridDataByProductId;
}
//StockStatus
export const calculateStockStatus = (planningGrids: Dictionary<PlanningGridData[]>) => {
    for (let key in planningGrids) {
        let localPlanningGrids = planningGrids[key];
        localPlanningGrids = updateStockStatusByPeriod(localPlanningGrids)
    }
    return planningGrids;
}

export const updateStockStatusByPeriod = (planningGridsByPeriod: PlanningGridData[]) => {
    for (let index = 0; index < planningGridsByPeriod.length; index++) {
        let planningGrid = planningGridsByPeriod[index];
        planningGrid.stockStatus = calculateByFinalStockStatusType(planningGrid);
    }
    return planningGridsByPeriod;
}

export const calculateStockDaysToStockUnits = (startingIndex: number, stockDaysCoverage: number, planningGrids: PlanningGridData[], isWeekly: boolean = true) => {
    let calculatedStockValueFromDays = 0;
    let daysByPeriod = isWeekly ? 7 : 30;
    for (let index = startingIndex + 1; index < planningGrids.length; index++) {
        const localPlanningGridByDay = planningGrids[index];
        if (localPlanningGridByDay.demand === undefined) {
            console.error(`on calculateMpsStepByStep, localPlanningGridByDay.demand is null`);
            throw new Error(`on calculateMpsStepByStep, localPlanningGridByDay.demand is null`)
        }
        if (stockDaysCoverage === 0) break;
        if (stockDaysCoverage >= daysByPeriod) {
            stockDaysCoverage -= daysByPeriod;
            calculatedStockValueFromDays += localPlanningGridByDay.demand;
        } else {
            let restOfDemand = (localPlanningGridByDay.demand / daysByPeriod) * stockDaysCoverage;
            calculatedStockValueFromDays += restOfDemand;
            break;
        }
    }
    return Math.ceil(calculatedStockValueFromDays);
}

//MPS
export const calculateMpsStepByStep = (
    appScenarioState: AppScenarioState,
    itemId: number,
    itemPeriodIndex: number,
    applyMpsRules = true,
    isWeekly: boolean = true
) => {
    let periods: PeriodData[] = appScenarioState.periods
    let currentPlanningGridGlobalValue: PlanningGridGlobalValues = appScenarioState.planningGridGlobalValuesByItemId[itemId]
    let planningGridsDataByItemId: Dictionary<PlanningGridData[]> = appScenarioState.planningGridsDataByItemId

    let closingStock = 0;
    let planningGridsByItemId = planningGridsDataByItemId[itemId]

    if (itemPeriodIndex > 0) {
        let localClosingStock = planningGridsByItemId[itemPeriodIndex - 1].closingStock
        if (localClosingStock === undefined) {
            console.error(`on calculateMpsStepByStep, localClosingStock is null`);
            throw new Error(`on calculateMpsStepByStep, localClosingStock is null`)
        }
        closingStock = localClosingStock;
    }

    const { globalMinStock, globalTargetStock, globalMinStockType, globalTargetStockType } = currentPlanningGridGlobalValue

    for (let forPeriodIndex = itemPeriodIndex; forPeriodIndex < periods.length; forPeriodIndex++) {
        let localPlanningGrid = planningGridsByItemId[forPeriodIndex];

        let { minimumReorder, multipleReorder } = planningGridsDataByItemId[itemId][0]

        if (forPeriodIndex > 0) {
            localPlanningGrid.openingStock = closingStock;
        }

        if (localPlanningGrid.openingStock === undefined) {
            console.error(`on calculateMpsStepByStep, localPlanningGrid.openingStock is null`);
            throw new Error(`on calculateMpsStepByStep, localPlanningGrid.openingStock is null`)
        } if (localPlanningGrid.orders === undefined) {
            console.error(`on calculateMpsStepByStep, localPlanningGrid.orders is null`);
            throw new Error(`on calculateMpsStepByStep, localPlanningGrid.orders is null`)
        } if (localPlanningGrid.mps === undefined) {
            console.error(`on calculateMpsStepByStep, minStock is null`);
            throw new Error(`on calculateMpsStepByStep, minStock is null`)
        } if (localPlanningGrid.demand === undefined) {
            console.error(`on calculateMpsStepByStep, localPlanningGrid.demand is null`);
            throw new Error(`on calculateMpsStepByStep, localPlanningGrid.demand is null`)
        }
        let totalSupply = localPlanningGrid.openingStock + localPlanningGrid.orders + localPlanningGrid.mps;
        closingStock = totalSupply - localPlanningGrid.demand;

        //Se closingStock for menor que o estoque minimo, devesse aumentar o MPS com regras.
        if (!localPlanningGrid.locked && applyMpsRules) {
            //Caso não esteja lockado, deve-se calcular um novo MPS, então descarta-se o valor antigo
            totalSupply = localPlanningGrid.openingStock + localPlanningGrid.orders;
            closingStock = totalSupply - localPlanningGrid.demand;

            let localMinStock = localPlanningGrid.minStock;
            let localTargetStock = localPlanningGrid.targetStock;
            if (globalTargetStockType === GlobalPlanningGridTargetType.DailyGlobalTargetValue) {
                localTargetStock = calculateStockDaysToStockUnits(forPeriodIndex, globalTargetStock, planningGridsByItemId, isWeekly);
            }
            if (globalMinStockType === GlobalPlanningGridTargetType.DailyGlobalTargetValue) {
                localMinStock = calculateStockDaysToStockUnits(forPeriodIndex, globalMinStock, planningGridsByItemId, isWeekly);
            }
            if (localMinStock === undefined) {
                console.error(`on calculateMpsStepByStep, localMinStock is null`);
                throw new Error(`on calculateMpsStepByStep, localMinStock is null`)
            }
            if (closingStock < localMinStock) {
                if (localTargetStock === undefined) {
                    console.error(`on calculateMpsStepByStep, localTargetStock is null`);
                    throw new Error(`on calculateMpsStepByStep, localTargetStock is null`)
                }
                let nextMpsOrder = Math.abs(localPlanningGrid.openingStock - localTargetStock - localPlanningGrid.demand + localPlanningGrid.orders);
                if (minimumReorder === undefined) {
                    console.error(`on calculateMpsStepByStep, minimumReorder is null`);
                    throw new Error(`on calculateMpsStepByStep, minimumReorder is null`)
                } if (multipleReorder === undefined) {
                    console.error(`on calculateMpsStepByStep, multipleReorder is null`);
                    throw new Error(`on calculateMpsStepByStep, multipleReorder is null`)
                }
                nextMpsOrder = applyMPSRules(minimumReorder, multipleReorder, nextMpsOrder);

                //TODO check if this fix is working
                while (nextMpsOrder > localTargetStock) {
                    nextMpsOrder -= multipleReorder;
                }
                if (nextMpsOrder < minimumReorder)
                    nextMpsOrder = minimumReorder;

                localPlanningGrid.mps = nextMpsOrder;
                localPlanningGrid.isDirty = true;

                totalSupply = localPlanningGrid.openingStock + localPlanningGrid.orders + nextMpsOrder;
                closingStock = totalSupply - localPlanningGrid.demand;
            } else if (closingStock >= localMinStock) {
                localPlanningGrid.mps = 0;
                localPlanningGrid.isDirty = true;
            }
        }

        //Update closingStocks
        localPlanningGrid.closingStock = closingStock;
        localPlanningGrid = calculateTotalDaysOfOfCover(localPlanningGrid, periods)
    }
    planningGridsByItemId = updateStockStatusByPeriod(planningGridsByItemId)
    updateAllItemIdsByStockStatus(appScenarioState)
}
const resetItemIdsByStockStatusByPeriod = (itemIdsByStockStatus: StockStatusDictionary<Dictionary<number[]>>) => {
    itemIdsByStockStatus.MTO = {}
    itemIdsByStockStatus.Alto = {}
    itemIdsByStockStatus.Excesso = {}
    itemIdsByStockStatus.Falta = {}
    itemIdsByStockStatus.Risco = {}
    itemIdsByStockStatus.Ruptura = {}
    itemIdsByStockStatus.Trancado = {}
    itemIdsByStockStatus.Normal = {}
}
const resetItemIdsByStockStatus = (itemIdsByStockStatus: StockStatusDictionary<number[]>) => {
    itemIdsByStockStatus.MTO = []
    itemIdsByStockStatus.Alto = []
    itemIdsByStockStatus.Excesso = []
    itemIdsByStockStatus.Falta = []
    itemIdsByStockStatus.Risco = []
    itemIdsByStockStatus.Ruptura = []
    itemIdsByStockStatus.Trancado = []
    itemIdsByStockStatus.Normal = []
}

const removeItemIdFromStockStatusDictionary = (itemIdsByStockStatus: StockStatusDictionary<number[]>, itemId: number) => {
    itemIdsByStockStatus.MTO = itemIdsByStockStatus.MTO.filter(x => x !== itemId)
    itemIdsByStockStatus.Alto = itemIdsByStockStatus.Alto.filter(x => x !== itemId)
    itemIdsByStockStatus.Excesso = itemIdsByStockStatus.Excesso.filter(x => x !== itemId)
    itemIdsByStockStatus.Falta = itemIdsByStockStatus.Falta.filter(x => x !== itemId)
    itemIdsByStockStatus.Risco = itemIdsByStockStatus.Risco.filter(x => x !== itemId)
    itemIdsByStockStatus.Ruptura = itemIdsByStockStatus.Ruptura.filter(x => x !== itemId)
    itemIdsByStockStatus.Trancado = itemIdsByStockStatus.Trancado.filter(x => x !== itemId)
    itemIdsByStockStatus.Normal = itemIdsByStockStatus.Normal.filter(x => x !== itemId)
}
//Update StockStatusDictionary
export const updateItemIdsByStockStatusByItemId = (appScenarioState: AppScenarioState, itemId: number) => {
    let planningGridsByItemId = appScenarioState.planningGridsDataByItemId[itemId]
    let itemIdsByStockStatus = appScenarioState.filterData.itemIdsByStockStatus
    removeItemIdFromStockStatusDictionary(itemIdsByStockStatus, itemId)
    let localItemIdsByStockStatus: StockStatusDictionary<Set<number>> = {
        Baixo: new Set(itemIdsByStockStatus.Baixo),
        MTO: new Set(itemIdsByStockStatus.MTO),
        Alto: new Set(itemIdsByStockStatus.Alto),
        Excesso: new Set(itemIdsByStockStatus.Excesso),
        Falta: new Set(itemIdsByStockStatus.Falta),
        Risco: new Set(itemIdsByStockStatus.Risco),
        Ruptura: new Set(itemIdsByStockStatus.Ruptura),
        Trancado: new Set(itemIdsByStockStatus.Trancado),
        Normal: new Set(itemIdsByStockStatus.Normal)
    }

    for (let i = 0; i < planningGridsByItemId.length; i++) {
        switch (planningGridsByItemId[i].stockStatus) {
            case StockStatusType.Low:
                if (!localItemIdsByStockStatus.Baixo.has(itemId))
                    localItemIdsByStockStatus.Baixo.add(itemId)
                break;
            case StockStatusType.Excess:
                if (!localItemIdsByStockStatus.Excesso.has(itemId))
                    localItemIdsByStockStatus.Excesso.add(itemId)
                break;
            case StockStatusType.High:
                if (!localItemIdsByStockStatus.Alto.has(itemId))
                    localItemIdsByStockStatus.Alto.add(itemId)
                break;
            case StockStatusType.MTO:
                if (!localItemIdsByStockStatus.MTO.has(itemId))
                    localItemIdsByStockStatus.MTO.add(itemId)
                break;
            case StockStatusType.Locked:
                if (!localItemIdsByStockStatus.Trancado.has(itemId))
                    localItemIdsByStockStatus.Trancado.add(itemId)
                break;
            case StockStatusType.Normal:
                if (!localItemIdsByStockStatus.Normal.has(itemId))
                    localItemIdsByStockStatus.Normal.add(itemId)
                break;
            case StockStatusType.Risk:
                if (!localItemIdsByStockStatus.Risco.has(itemId))
                    localItemIdsByStockStatus.Risco.add(itemId)
                break;
            case StockStatusType.Shortage:
                if (!localItemIdsByStockStatus.Falta.has(itemId))
                    localItemIdsByStockStatus.Falta.add(itemId)
                break;
            case StockStatusType.Stockout:
                if (!localItemIdsByStockStatus.Ruptura.has(itemId))
                    localItemIdsByStockStatus.Ruptura.add(itemId)
                break;
            default:
                break;
        }
    }

    itemIdsByStockStatus = {
        Baixo: Array.from(localItemIdsByStockStatus.Baixo.values()),
        MTO: Array.from(localItemIdsByStockStatus.MTO.values()),
        Alto: Array.from(localItemIdsByStockStatus.Alto.values()),
        Excesso: Array.from(localItemIdsByStockStatus.Excesso.values()),
        Falta: Array.from(localItemIdsByStockStatus.Falta.values()),
        Risco: Array.from(localItemIdsByStockStatus.Risco.values()),
        Ruptura: Array.from(localItemIdsByStockStatus.Ruptura.values()),
        Trancado: Array.from(localItemIdsByStockStatus.Trancado.values()),
        Normal: Array.from(localItemIdsByStockStatus.Normal.values())
    }
}
export const updateAllItemIdsByStockStatusByPeriod = (appScenarioState: AppScenarioState): StockStatusDictionary<Dictionary<number[]>> => {
    resetItemIdsByStockStatusByPeriod(appScenarioState.filterData.itemIdsByStockStatusByPeriod)

    // let itemIdsByStockStatus = appScenarioState.filterData.itemIdsByStockStatusByPeriod

    let localItemIdsByStockStatus: StockStatusDictionary<Dictionary<Set<number>>> = {
        Baixo: {},
        MTO: {},
        Alto: {},
        Excesso: {},
        Falta: {},
        Risco: {},
        Ruptura: {},
        Trancado: {},
        Normal: {}
    }
    let returnType: StockStatusDictionary<Dictionary<number[]>> = {
        Baixo: {},
        MTO: {},
        Alto: {},
        Excesso: {},
        Falta: {},
        Risco: {},
        Ruptura: {},
        Trancado: {},
        Normal: {}
    }

    let planningGrids = appScenarioState.planningGridsDataByItemId
    for (const [keyItemId, planningGridsData] of Object.entries(planningGrids)) {
        const itemId = Number(keyItemId)

        for (let i = 0; i < planningGridsData.length; i++) {
            const localPlanningGrid = planningGridsData[i];
            switch (localPlanningGrid.stockStatus) {
                case StockStatusType.High:
                    if (!localItemIdsByStockStatus.Alto[localPlanningGrid.periodId])
                        localItemIdsByStockStatus.Alto[localPlanningGrid.periodId] = new Set()
                    if (!localItemIdsByStockStatus.Alto[localPlanningGrid.periodId].has(itemId))
                        localItemIdsByStockStatus.Alto[localPlanningGrid.periodId].add(itemId)
                    break;
                case StockStatusType.MTO:
                    if (!localItemIdsByStockStatus.MTO[localPlanningGrid.periodId])
                        localItemIdsByStockStatus.MTO[localPlanningGrid.periodId] = new Set()
                    if (!localItemIdsByStockStatus.MTO[localPlanningGrid.periodId].has(itemId))
                        localItemIdsByStockStatus.MTO[localPlanningGrid.periodId].add(itemId)
                    break;
                case StockStatusType.Risk:
                    if (!localItemIdsByStockStatus.Risco[localPlanningGrid.periodId])
                        localItemIdsByStockStatus.Risco[localPlanningGrid.periodId] = new Set()
                    if (!localItemIdsByStockStatus.Risco[localPlanningGrid.periodId].has(itemId))
                        localItemIdsByStockStatus.Risco[localPlanningGrid.periodId].add(itemId)
                    break;
                case StockStatusType.Shortage:
                    if (!localItemIdsByStockStatus.Falta[localPlanningGrid.periodId])
                        localItemIdsByStockStatus.Falta[localPlanningGrid.periodId] = new Set()
                    if (!localItemIdsByStockStatus.Falta[localPlanningGrid.periodId].has(itemId))
                        localItemIdsByStockStatus.Falta[localPlanningGrid.periodId].add(itemId)
                    break;
                case StockStatusType.Excess:
                    if (!localItemIdsByStockStatus.Excesso[localPlanningGrid.periodId])
                        localItemIdsByStockStatus.Excesso[localPlanningGrid.periodId] = new Set()
                    if (!localItemIdsByStockStatus.Excesso[localPlanningGrid.periodId].has(itemId))
                        localItemIdsByStockStatus.Excesso[localPlanningGrid.periodId].add(itemId)
                    break;
                case StockStatusType.Locked:
                    if (!localItemIdsByStockStatus.Trancado[localPlanningGrid.periodId])
                        localItemIdsByStockStatus.Trancado[localPlanningGrid.periodId] = new Set()
                    if (!localItemIdsByStockStatus.Trancado[localPlanningGrid.periodId].has(itemId))
                        localItemIdsByStockStatus.Trancado[localPlanningGrid.periodId].add(itemId)
                    break;
                case StockStatusType.Normal:
                    if (!localItemIdsByStockStatus.Normal[localPlanningGrid.periodId])
                        localItemIdsByStockStatus.Normal[localPlanningGrid.periodId] = new Set()
                    if (!localItemIdsByStockStatus.Normal[localPlanningGrid.periodId].has(itemId))
                        localItemIdsByStockStatus.Normal[localPlanningGrid.periodId].add(itemId)
                    break;
                case StockStatusType.Stockout:
                    if (!localItemIdsByStockStatus.Ruptura[localPlanningGrid.periodId])
                        localItemIdsByStockStatus.Ruptura[localPlanningGrid.periodId] = new Set()
                    if (!localItemIdsByStockStatus.Ruptura[localPlanningGrid.periodId].has(itemId))
                        localItemIdsByStockStatus.Ruptura[localPlanningGrid.periodId].add(itemId)
                    break;
                default:
                    break;
            }
        }
        for (const [periodId, itemIdsSet] of Object.entries(localItemIdsByStockStatus.Excesso)) {
            returnType.Excesso[Number(periodId)] = Array.from(itemIdsSet.values())
        }
        for (const [periodId, itemIdsSet] of Object.entries(localItemIdsByStockStatus.Alto)) {
            returnType.Alto[Number(periodId)] = Array.from(itemIdsSet.values())
        }
        for (const [periodId, itemIdsSet] of Object.entries(localItemIdsByStockStatus.Trancado)) {
            returnType.Trancado[Number(periodId)] = Array.from(itemIdsSet.values())
        }
        for (const [periodId, itemIdsSet] of Object.entries(localItemIdsByStockStatus.MTO)) {
            returnType.MTO[Number(periodId)] = Array.from(itemIdsSet.values())
        }
        for (const [periodId, itemIdsSet] of Object.entries(localItemIdsByStockStatus.Normal)) {
            returnType.Normal[Number(periodId)] = Array.from(itemIdsSet.values())
        }
        for (const [periodId, itemIdsSet] of Object.entries(localItemIdsByStockStatus.Risco)) {
            returnType.Risco[Number(periodId)] = Array.from(itemIdsSet.values())
        }
        for (const [periodId, itemIdsSet] of Object.entries(localItemIdsByStockStatus.Falta)) {
            returnType.Falta[Number(periodId)] = Array.from(itemIdsSet.values())
        }
        for (const [periodId, itemIdsSet] of Object.entries(localItemIdsByStockStatus.Ruptura)) {
            returnType.Ruptura[Number(periodId)] = Array.from(itemIdsSet.values())
        }

    }
    return returnType;
}

export const updateAllItemIdsByStockStatus = (appScenarioState: AppScenarioState): StockStatusDictionary<number[]> => {
    resetItemIdsByStockStatus(appScenarioState.filterData.itemIdsByStockStatus)
    let itemIdsByStockStatus = appScenarioState.filterData.itemIdsByStockStatus
    let localItemIdsByStockStatus: StockStatusDictionary<Set<number>> = {
        Baixo: new Set(itemIdsByStockStatus.Baixo),
        MTO: new Set(itemIdsByStockStatus.MTO),
        Alto: new Set(itemIdsByStockStatus.Alto),
        Excesso: new Set(itemIdsByStockStatus.Excesso),
        Falta: new Set(itemIdsByStockStatus.Falta),
        Risco: new Set(itemIdsByStockStatus.Risco),
        Ruptura: new Set(itemIdsByStockStatus.Ruptura),
        Trancado: new Set(itemIdsByStockStatus.Trancado),
        Normal: new Set(itemIdsByStockStatus.Normal)
    }
    let planningGrids = appScenarioState.planningGridsDataByItemId

    for (const [keyItemId, planningGridsData] of Object.entries(planningGrids)) {

        const itemId = Number(keyItemId)
        for (let i = 0; i < planningGridsData.length; i++) {
            switch (planningGridsData[i].stockStatus) {
                case StockStatusType.Excess:
                    if (!localItemIdsByStockStatus.Excesso.has(itemId))
                        localItemIdsByStockStatus.Excesso.add(itemId)
                    break;
                case StockStatusType.High:
                    if (!localItemIdsByStockStatus.Alto.has(itemId))
                        localItemIdsByStockStatus.Alto.add(itemId)
                    break;
                case StockStatusType.Locked:
                    if (!localItemIdsByStockStatus.Trancado.has(itemId))
                        localItemIdsByStockStatus.Trancado.add(itemId)
                    break;
                case StockStatusType.MTO:
                    if (!localItemIdsByStockStatus.MTO.has(itemId))
                        localItemIdsByStockStatus.MTO.add(itemId)
                    break;
                case StockStatusType.Normal:
                    if (!localItemIdsByStockStatus.Normal.has(itemId))
                        localItemIdsByStockStatus.Normal.add(itemId)
                    break;
                case StockStatusType.Risk:
                    if (!localItemIdsByStockStatus.Risco.has(itemId))
                        localItemIdsByStockStatus.Risco.add(itemId)
                    break;
                case StockStatusType.Shortage:
                    if (!localItemIdsByStockStatus.Falta.has(itemId))
                        localItemIdsByStockStatus.Falta.add(itemId)
                    break;
                case StockStatusType.Stockout:
                    if (!localItemIdsByStockStatus.Ruptura.has(itemId))
                        localItemIdsByStockStatus.Ruptura.add(itemId)
                    break;
                default:
                    break;
            }
        }
    }
    return {
        Baixo: Array.from(localItemIdsByStockStatus.Baixo.values()),
        MTO: Array.from(localItemIdsByStockStatus.MTO.values()),
        Alto: Array.from(localItemIdsByStockStatus.Alto.values()),
        Excesso: Array.from(localItemIdsByStockStatus.Excesso.values()),
        Falta: Array.from(localItemIdsByStockStatus.Falta.values()),
        Risco: Array.from(localItemIdsByStockStatus.Risco.values()),
        Ruptura: Array.from(localItemIdsByStockStatus.Ruptura.values()),
        Trancado: Array.from(localItemIdsByStockStatus.Trancado.values()),
        Normal: Array.from(localItemIdsByStockStatus.Normal.values())
    }
}
//Days of cover
const calculateTotalDaysOfOfCover = (planningGrid: PlanningGridData, periods: PeriodData[]) => {
    if (planningGrid.openingStock === undefined) {
        console.error(`on calculateTotalDaysOfOfCover, planningGrid.openingStock is null`);
        throw new Error(`on calculateTotalDaysOfOfCover, planningGrid.openingStock is null`)
    } if (planningGrid.orders === undefined) {
        console.error(`on calculateTotalDaysOfOfCover, planningGrid.orders is null`);
        throw new Error(`on calculateTotalDaysOfOfCover, planningGrid.orders is null`)
    } if (planningGrid.mps === undefined) {
        console.error(`on calculateTotalDaysOfOfCover, planningGrid.mps is null`);
        throw new Error(`on calculateTotalDaysOfOfCover, planningGrid.mps is null`)
    }
    let totalSupply = planningGrid.openingStock + planningGrid.orders + planningGrid.mps;
    let totalDaysOfCover = 0;
    let temporaryTotalSupply = totalSupply;
    let daysInPeriod = 0;
    let demand = 0;
    let demandPerDay = 0;
    if (planningGrid.demand === undefined) {
        console.error(`on calculateTotalDaysOfOfCover, planningGrid.demand is null`);
        throw new Error(`on calculateTotalDaysOfOfCover, planningGrid.demand is null`)
    }
    for (let periodIndex = 0; periodIndex < periods.length; periodIndex++) {
        daysInPeriod = getDaysInPeriod(periods[periodIndex]);
        demand = planningGrid.demand;
        demandPerDay = demand / daysInPeriod;

        for (let depandPerDayIndex = 0; depandPerDayIndex < daysInPeriod; depandPerDayIndex++) {
            temporaryTotalSupply -= demandPerDay;
            if (temporaryTotalSupply < 0) {
                let dayOfCoverRest = temporaryTotalSupply / demandPerDay;
                totalDaysOfCover += 1 + dayOfCoverRest;
                break;
            }
            totalDaysOfCover++;
        }
        if (temporaryTotalSupply < 0) break;
    }

    if (temporaryTotalSupply > 0) {
        while (temporaryTotalSupply > 0) {
            //TODO o que fazer se tiver demanda zerada?
            if (demandPerDay <= 0) break;
            temporaryTotalSupply -= demandPerDay;
            if (temporaryTotalSupply > 0) {
                totalDaysOfCover++;
            } else {
                let dayOfCoverRest = temporaryTotalSupply / demandPerDay;
                totalDaysOfCover += 1 + dayOfCoverRest;
            }
        }
    }
    planningGrid.totalDaysOfCover = Number.parseFloat(totalDaysOfCover.toFixed(2));

    return planningGrid;
}

export const applyMinimumRuleOnly = (minimumReorderQuantity: number, value: number): number => {
    if (value < minimumReorderQuantity) return minimumReorderQuantity;
    return value
}

export const applyMultipleReorderRuleOnly = (multipleReorder: number, value: number): number => {
    if (value < multipleReorder) {
        return multipleReorder
    };
    if (value % multipleReorder === 0) return value;
    value = Math.ceil(value / multipleReorder) * multipleReorder;
    return value;
}

export const applyMPSRules = (minimumReorderQuantity: number, reorderMultiple: number, value: number): number => {
    value = applyMinimumRuleOnly(minimumReorderQuantity, value);
    if (value < reorderMultiple) {
        return reorderMultiple
    };
    if (value % reorderMultiple === 0) return value;
    value = Math.ceil(value / reorderMultiple) * reorderMultiple;
    return value;
}

export const getDaysInPeriod = (period: PeriodData) => {
    let startTime = dayjs(period.startTime);
    let endTime = dayjs(period.endTime);
    return endTime.diff(startTime, 'day');
}


export const calculateByInitialStockStatusType = (planningGridData: PlanningGridData): StockStatusType => {
    const { openingStock, targetStock, minStock, maxStock, safetyStock } = planningGridData;
    let status: StockStatusType = StockStatusType.Shortage;
    if (openingStock === undefined) {
        console.error(`on calculateStockStatusType, openingStock is null`);
        throw new Error(`on calculateStockStatusType, openingStock is null`)
    } if (safetyStock === undefined) {
        console.error(`on calculateStockStatusType, safetyStock is null`);
        throw new Error(`on calculateStockStatusType, safetyStock is null`)
    } if (minStock === undefined) {
        console.error(`on calculateStockStatusType, minStock is null`);
        throw new Error(`on calculateStockStatusType, minStock is null`)
    } if (targetStock === undefined) {
        console.error(`on calculateStockStatusType, targetStock is null`);
        throw new Error(`on calculateStockStatusType, targetStock is null`)
    } if (maxStock === undefined) {
        console.error(`on calculateStockStatusType, maxStock is null`);
        throw new Error(`on calculateStockStatusType, maxStock is null`)
    }
    //Regra de Status
    //MTO
    if (targetStock === 0) {
        return StockStatusType.MTO
        //Shortage
    } else if (openingStock < 0) {
        return StockStatusType.Stockout
    } else if ((openingStock >= 0) && (openingStock < safetyStock)) {
        return StockStatusType.Risk
        //Normal
    } else if ((openingStock >= safetyStock) && (openingStock < minStock)) {
        return StockStatusType.Low
        //Baixo
        //High
    } else if ((openingStock > targetStock) && (openingStock <= maxStock)) {
        return StockStatusType.High
        //Excess
    } else if (openingStock > maxStock) {
        return StockStatusType.Excess
    }
    return status;
}

export const calculateByFinalStockStatusType = (planningGridData: PlanningGridData): StockStatusType => {
    const { locked, closingStock, targetStock, minStock, maxStock, safetyStock } = planningGridData;
    let status: StockStatusType = StockStatusType.Shortage;
    if (closingStock === undefined) {
        console.error(`on calculateStockStatusType, closingStock is null`);
        throw new Error(`on calculateStockStatusType, closingStock is null`)
    } if (safetyStock === undefined) {
        console.error(`on calculateStockStatusType, safetyStock is null`);
        throw new Error(`on calculateStockStatusType, safetyStock is null`)
    } if (minStock === undefined) {
        console.error(`on calculateStockStatusType, minStock is null`);
        throw new Error(`on calculateStockStatusType, minStock is null`)
    } if (targetStock === undefined) {
        console.error(`on calculateStockStatusType, targetStock is null`);
        throw new Error(`on calculateStockStatusType, targetStock is null`)
    } if (maxStock === undefined) {
        console.error(`on calculateStockStatusType, maxStock is null`);
        throw new Error(`on calculateStockStatusType, maxStock is null`)
    }
    //Regra de Status
    //Locked
    if (locked) {
        return StockStatusType.Locked
        //MTO
    } else if (targetStock === 0) {
        return StockStatusType.MTO
        //Shortage
    } else if (closingStock < 0) {
        return StockStatusType.Stockout
    } else if ((closingStock >= 0) && (closingStock < safetyStock)) {
        return StockStatusType.Risk
        //Normal
    } else if ((closingStock >= safetyStock) && (closingStock < minStock)) {
        return StockStatusType.Low
        //Baixo
    } else if ((closingStock >= minStock) && (closingStock <= targetStock)) {
        return StockStatusType.Normal
        //High
    } else if ((closingStock > targetStock) && (closingStock <= maxStock)) {
        return StockStatusType.High
        //Excess
    } else if (closingStock > maxStock) {
        return StockStatusType.Excess
    }
    return status;
}

export const calculateChartStockStatusType = (planningGridData: PlanningChartData): StockStatusType => {
    // const { closingStock, targetStock, minStock } = planningGridData;
    const { locked, closingStock, targetStock, minStock, maxStock, safetyStock } = planningGridData;
    let status: StockStatusType = StockStatusType.Shortage;

    //Regra de Status
    //Locked n~ao precisa?
    // if (locked) {
    //     return NewStockStatusType.Locked
    //     //MTO
    // } else 
    if (targetStock === 0) {
        return StockStatusType.MTO
        //Shortage
    } else if (closingStock < 0) {
        return StockStatusType.Shortage
    } else if ((closingStock >= safetyStock) && (closingStock < minStock)) {
        return StockStatusType.Risk
        //Normal
    } else if ((closingStock >= minStock) && (closingStock <= targetStock)) {
        return StockStatusType.Normal
        //High
    } else if ((closingStock > targetStock) && (closingStock <= maxStock)) {
        return StockStatusType.High
        //Excess
    } else if (closingStock > maxStock) {
        return StockStatusType.Excess
    }
    return status;
}

export const resetSearchCriteriaData = (criteriasData: SearchCriteriasDictionary<any>) => {
    for (const criteriaKey of Object.keys(criteriasData)) {
        if (criteriasData[criteriaKey])
            delete (criteriasData[criteriaKey])
    }
}
export const localApplyUpMultipleReorder = (state: AppScenarioState, payload: { itemId: number, itemPeriodIndex: number }, shouldUpdateOccupationPageByProduct: boolean = true) => {
    const { itemId, itemPeriodIndex } = payload;
    if (state.planningGridsDataByItemId[itemId][itemPeriodIndex].locked) return;

    let item = state.itemById[itemId];
    let planningGrid = state.planningGridsDataByItemId[itemId][itemPeriodIndex];
    //TODO great-fix buscar pelo planningGrid?
    // const minimunReorderMultiple = state.planningGrids.filter(x => x.itemId === item.id)[0].multipleReorder;
    const minimunReorderMultiple = state.planningGridsDataByItemId[item.id][0].multipleReorder; //.filter(x => x.itemId === item.id)[0].multipleReorder;

    // const minimunReorderMultiple = item.reorderMultiple;
    if (minimunReorderMultiple === undefined) {
        console.error(`on localApplyUpMultipleReorder, minimunReorderMultiple is null`);
        throw new Error(`on localApplyUpMultipleReorder, minimunReorderMultiple is null`)
    } if (planningGrid.mps === undefined) {
        console.error(`on localApplyUpMultipleReorder, planningGrid.mps is null`);
        throw new Error(`on localApplyUpMultipleReorder, planningGrid.mps is null`)
    }
    let currentMps = applyMultipleReorderRuleOnly(minimunReorderMultiple, planningGrid.mps + minimunReorderMultiple);
    planningGrid.mps = currentMps;
    planningGrid.isDirty = true;

    calculateMpsStepByStep(state, itemId, itemPeriodIndex, false, state.isWeekly);

    // updateOccupationPageByItem(
    //     state.periods.length,
    //     productId,
    //     state.itemById[productId].itemAttribute1Id,
    //     state.mpsPerUnitOfTime[productId],
    //     state.setupTimeByItemId[productId],
    //     state.selectedDatasetId,
    //     getPossibleResources(state.planningGridAllocationsByItemIdByResourceIdByPeriodId[productId], state.resourceGroupById),
    //     state.planningGridsDataByItemId[productId],
    //     state.planningGridAllocationsByItemIdByResourceIdByPeriodId[productId],
    //     state.periods,
    //     state.chartDataByResourceGroup,
    //     true
    // )
}
export const localApplyDownMultipleReorder = (state: AppScenarioState, payload: { itemId: number, itemPeriodIndex: number }, shouldUpdateOccupationPageByProduct: boolean = true) => {
    const { itemId, itemPeriodIndex } = payload;
    if (state.planningGridsDataByItemId[itemId][itemPeriodIndex].locked) return;
    const planningGrids = state.planningGridsDataByItemId[itemId]

    //Somente o primeiro que interessa, então por que ter estes valores repetidos em todos planningGrids?
    let planningGrid = planningGrids[itemPeriodIndex];
    if (planningGrid.mps === 0 || planningGrid.locked) return;

    const firstMultipleReorder = planningGrids[0].multipleReorder;

    if (firstMultipleReorder === undefined) {
        console.error(`on localApplyDownMultipleReorder, firstMultipleReorder is null`);
        throw new Error(`on localApplyDownMultipleReorder, firstMultipleReorder is null`)
    } if (planningGrid.mps === undefined) {
        console.error(`on localApplyDownMultipleReorder, planningGrid.mps is null`);
        throw new Error(`on localApplyDownMultipleReorder, planningGrid.mps is null`)
    } let currentMps = applyMultipleReorderRuleOnly(firstMultipleReorder, planningGrid.mps);

    if (planningGrid.mps === currentMps) {
        currentMps -= firstMultipleReorder;
    }
    planningGrid.mps = currentMps;
    planningGrid.isDirty = true;

    calculateMpsStepByStep(state, itemId, itemPeriodIndex, false, state.isWeekly);
    // updateAllItemIdsByStockStatus(state);

    // if (shouldUpdateOccupationPageByProduct) {
    //     updateOccupationPageByItem(
    //         state.periods.length,
    //         productId,
    //         state.itemById[productId].itemAttribute1Id ?? 0,
    //         state.mpsPerUnitOfTime[productId],
    //         state.setupTimeByItemId[productId],
    //         state.selectedDatasetId,
    //         getPossibleResources(state.planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId[productId], state.resourceGroupById),
    //         state.planningGridsDataByItemId[productId],
    //         state.planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId[productId],
    //         state.periods,
    //         state.chartDataByResourceGroup,
    //         true
    //     )
    // }
}