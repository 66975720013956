import { Dropdown } from 'primereact/dropdown';
import { CountryData, SupportedCountriesLanguages } from 'configuration/languages';
import Flag from 'react-flagkit';
import { t } from '@lingui/macro';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setLanguage, setSelectedCountryData } from 'redux/AppNavigationState';

interface Props {
    onSelect?: () => void;
}


function LanguageDropdown(props: Props) {
    const { onSelect } = props
    const dispatch = useAppDispatch();
    const selectedCountry = useAppSelector(state => state.appNavigationState.selectedCountryData);

    const handleOnSelectCountry = (countryData: CountryData) => {
        dispatch(setSelectedCountryData(countryData))
        dispatch(setLanguage(countryData.code))
        onSelect?.();
    }

    const countryOptionTemplate = (option: CountryData) => {
        return (
            <div className="flex align-items-center">
                <Flag country={`${option.flagCode}`} size={30} className='pr-2' />
                <div>{option.name}</div>
            </div>
        );
    };

    const panelFooterTemplate = () => {
        return (
            <div className="py-2 px-3">
                {selectedCountry ? (
                    <span>
                        <b>{selectedCountry.name}</b> selecionado.
                    </span>
                ) : (
                    t`Nenhum país selecionado` + '.'
                )}
            </div>
        );
    };

    const selectedCountryTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <Flag country={`${option.flagCode}`} size={30} className='pr-2' />
                    <div>{option.name}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    return (
        <div className="card flex justify-content-center z-auto">
            <Dropdown
                value={selectedCountry}
                onChange={(e) => handleOnSelectCountry(e.value)}
                options={SupportedCountriesLanguages}
                optionLabel="name"
                placeholder={t`Selecione um país`}
                valueTemplate={selectedCountryTemplate}
                itemTemplate={countryOptionTemplate}
                className="w-full md:w-14rem h-10 bg-white"
                panelFooterTemplate={panelFooterTemplate}
            />
        </div>

    )
}

export default LanguageDropdown
