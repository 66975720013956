import { getAsync, postAsync, putAsync, deleteAsync } from '..';

export function getTableDataAsync(tableId: any): Promise<any> {
    return getAsync<any>({ url: `/tableeditiondata/${tableId}` })
}

export function postTableDataAsync(data: any): Promise<any> {
    return postAsync<any>({ url: "/tableeditiondata", body: { ...data } });
}

export function putTableDataAsync(data: any): Promise<any> {
    return putAsync<any>({ url: `/tableeditiondata`, body: { ...data } })
}
export function putTableDataAllAsync(data: any): Promise<any> {
    return putAsync<any>({ url: `/tableeditiondata/editorAll`, body: [ ...data ] })
}

export function deleteTableDataAsync(data: any): Promise<any> {
    return deleteAsync<any>({ url: '/tableeditiondata', queryParams: { ...data } });
}