import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialState } from "./initialState";
import { ActionType } from "redux/types";
import {
    InventoryHealth,
    scenarioStatusOptions,
    SegmentationDataType,
    StockInventoryData,
    StockTypes,
    StrategyOptions,
    supplyStrategyEditedObjectsProps,
    uniqueScenarios,
} from "pages/StockPolicyPage/types";
import { Scenario } from "api/data/types";
import { loadInventoryDataAsync, loadSegmentationDataAsync } from "redux/AppScenarioState";
import { stockPolicyAxesTypeToLanguage } from "locales/helper";
import { StockPolicyAxesType } from "api/data/enums";

//Async calls

//Slices
export const appStockSlice = createSlice({
    name: ActionType.AppStockState,
    initialState,
    reducers: {
        setIsRecalculating: (state, action: PayloadAction<{ validation: boolean }>) => {
            state.isRecalculating = action.payload.validation
        },
        setStockEditableTableValidation: (
            state,
            action: PayloadAction<{ isEditable: boolean }>
        ) => {
            state.stockEditableTableValidation.isEditable = action.payload.isEditable;
        },
        setMainStockFilter: (
            state,
            action: PayloadAction<{ mainFilterName: () => string; id: number }>
        ) => {
            state.selectedMainFilter.name = action.payload.mainFilterName;
            state.selectedMainFilter.id = action.payload.id;
        },
        setSelectCompanyMenu: (
            state,
            action: PayloadAction<{ CompanyOptions: { id: number, name: () => string }[] }>
        ) => {
            const options: { id: number, name: () => string }[] = []
            for (let i = 0; i < action.payload.CompanyOptions.length; i++) {
                options.push(
                    {
                        id: action.payload.CompanyOptions[i].id,
                        name: () => action.payload.CompanyOptions[i].name()
                    }
                )
            }
            state.SelectCompanyMenu.CompanyOptions = options


        },

        setSecondaryStockFilter: (
            state,
            action: PayloadAction<{
                secondaryFilterName: string;
                id: number;
                code: string;
            }>
        ) => {
            state.selectedSecondaryFilter.name = action.payload.secondaryFilterName;
            state.selectedSecondaryFilter.id = action.payload.id;
            state.selectedSecondaryFilter.code = action.payload.code;
        },
        setStockPolicyAxes: (
            state,
            action: PayloadAction<{
                xAxis: () => string;
                yAxis: () => string;
                filter: () => string;
                types: StockTypes;
            }>
        ) => {
            state.stockPolicyAxes.xAxis = action.payload.xAxis;
            state.stockPolicyAxes.yAxis = action.payload.yAxis;
            state.stockPolicyAxes.filter = action.payload.filter;

            switch (action.payload.filter()) {
                case stockPolicyAxesTypeToLanguage(StockPolicyAxesType.Volume):
                    state.stockFilterOptions = action.payload.types.Volume;
                    break;
                case stockPolicyAxesTypeToLanguage(StockPolicyAxesType.Complexity):
                    state.stockFilterOptions = action.payload.types.Complexity;
                    break;
                case stockPolicyAxesTypeToLanguage(StockPolicyAxesType.Uncertainty):
                default:
                    state.stockFilterOptions = action.payload.types.Uncertainty;
                    break;
            }
        },
        setFilterOptions: (
            state,
            action: PayloadAction<{ filter: string; types: StockTypes }>
        ) => {
            switch (action.payload.filter) {
                case stockPolicyAxesTypeToLanguage(StockPolicyAxesType.Volume):
                    state.stockFilterOptions = action.payload.types.Volume;
                    break;
                case stockPolicyAxesTypeToLanguage(StockPolicyAxesType.Complexity):
                    state.stockFilterOptions = action.payload.types.Complexity;
                    break;
                case stockPolicyAxesTypeToLanguage(StockPolicyAxesType.Uncertainty):
                default:
                    state.stockFilterOptions = action.payload.types.Uncertainty;
                    break;
            }
        },
        setSegmentationData: (
            state,
            action: PayloadAction<{ data: SegmentationDataType[] }>
        ) => {
            state.segmentationData.data = action.payload.data;
        },

        setInventoryData: (
            state,
            action: PayloadAction<{ data: StockInventoryData[] }>
        ) => {
            state.stockInventory.data = action.payload.data;
        },
        setStockStrategyOptions: (
            state,
            action: PayloadAction<{ optionsArray: StrategyOptions[] }>
        ) => {
            state.stockStrategyOptions = action.payload.optionsArray;
        },
        setSupplyStrategyEditedObjects: (
            state,
            action: PayloadAction<{ array: supplyStrategyEditedObjectsProps[] }>
        ) => {
            state.supplyStrategyEditedObjects = action.payload.array;
        },
        setShowingItemsReducer: (
            state,
            action: PayloadAction<{
                segmentationShowingItems: {
                    items: SegmentationDataType[][];
                    type: any;
                    xAxisType: any;
                }[];
                summaryShowingItems: {
                    items: StockInventoryData[][];
                    type: any;
                    xAxisType: any;
                }[];
            }>
        ) => {
            state.showingItemsRedux.segmentationShowingItems =
                action.payload.segmentationShowingItems;
            state.showingItemsRedux.summaryShowingItems =
                action.payload.summaryShowingItems;
        },
        setUniqueScenarios: (
            state,
            action: PayloadAction<{ uniqueScenarios: uniqueScenarios[] }>
        ) => {
            const orderedScenariosWithDate = action.payload.uniqueScenarios.sort(
                function (a, b) {
                    return (
                        new Date(b.cenarioData).getTime() -
                        new Date(a.cenarioData).getTime()
                    );
                }
            );

            state.uniqueScenarios = orderedScenariosWithDate;
        },
        setSelectedScenario: (
            state,
            action: PayloadAction<{
                scenario: { id: number; name: string; createdOn: string };
            }>
        ) => {
            state.selectedScenario = action.payload.scenario;
        },
        setUniqueTimestamps: (
            state,
            action: PayloadAction<{ uniqueTimestamps: string[] }>
        ) => {
            state.uniqueTimestamps = action.payload.uniqueTimestamps;
        },
        setSelectedTimestamp: (
            state,
            action: PayloadAction<{ timestamp: string }>
        ) => {
            state.selectedTimestamp = action.payload.timestamp;
        },
        setSelectedGraphLabel: (
            state,
            action: PayloadAction<{ label: string }>
        ) => {
            state.selectedGraphLabel = action.payload.label;
        },
        setScenarioStatusOptions: (
            state,
            action: PayloadAction<{ scenarioStatusOptions: scenarioStatusOptions[] }>
        ) => {
            state.scenarioStatusOptions = action.payload.scenarioStatusOptions;
        },
        setinventoryHealthFilteredByCompany: (
            state,
            action: PayloadAction<{
                inventoryHealthFilteredByCompany: InventoryHealth[];
            }>
        ) => {
            state.inventoryHealthFilteredByCompany =
                action.payload.inventoryHealthFilteredByCompany;
        },
        setScenariosOptions: (
            state,
            action: PayloadAction<{ scenarioOptions: Scenario[] }>
        ) => {
            let uniqueScenarios: {
                id: number;
                name: string;
                createdOn: string;
            }[] = [];

            if (action.payload.scenarioOptions !== undefined) {
                const scenarioMap = new Map();

                action.payload.scenarioOptions.forEach((pos) => {
                    const { id, createdOn, name } = pos;

                    if (!scenarioMap.has(id)) {
                        scenarioMap.set(id, name);
                        uniqueScenarios.push({ id, name, createdOn });
                    }
                });
            }
            state.scenariosOptions = uniqueScenarios.sort((a, b) => b.createdOn.localeCompare(a.createdOn))

        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadInventoryDataAsync.fulfilled, (state, action) => {
                state.stockInventory.data = action.payload.inventoryData;
            })
            .addCase(loadSegmentationDataAsync.fulfilled, (state, action) => {
                state.segmentationData.data = action.payload.segmentationData
            })
    }
});

export const {
    setMainStockFilter,
    setSecondaryStockFilter,
    setStockPolicyAxes,
    setFilterOptions,
    setStockEditableTableValidation,
    setSegmentationData,
    setStockStrategyOptions,
    setSupplyStrategyEditedObjects,
    setShowingItemsReducer,
    setInventoryData,
    setUniqueScenarios,
    setSelectedScenario,
    setSelectedTimestamp,
    setUniqueTimestamps,
    setSelectedGraphLabel,
    setScenarioStatusOptions,
    setinventoryHealthFilteredByCompany,
    setScenariosOptions,
    setSelectCompanyMenu,
    setIsRecalculating
} = appStockSlice.actions;

export default appStockSlice.reducer;
