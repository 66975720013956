import { Menu } from '@headlessui/react'
import { Trans } from '@lingui/macro';
import { DropdownProps } from './types';

function NeoActionDropdownButton(props: DropdownProps<any>) {
    const { className, dropdownOptions, dropDownIcon, dropDownText } = props
    const Icon = dropDownIcon;

    return (
        <div className={`flex min-w-fit h-full place-content-center text-right self-center ` + className}>
            <Menu as="div" className={`relative inline-block text-left ${props.className} bg-gray-700 rounded-md`}>
                <Menu.Button className="flex min-w-min h-full items-center px-2 text-white 
                rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 
                focus-visible:ring-white focus-visible:ring-opacity-75">
                    <div className='flex w-full h-full justify-between'>
                        {
                            dropDownText &&
                            <div className='text-base font-medium ml-1'>{dropDownText}</div>
                        }
                        {
                            Icon &&
                            (
                                dropDownText ?
                                    <Icon className='flex w-full h-full ml-4 mr-1 place-self-end' />
                                    :
                                    <Icon className='flex w-full h-full place-self-end' />
                            )

                        }
                    </div>
                </Menu.Button>
                <Menu.Items className="absolute left-0 min-w-max mt-2 z-60  bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="px-1 py-1 ">
                        {
                            Object.values(dropdownOptions).map((dropdownOption, index) => (
                                <Menu.Item key={`menu-item-${dropdownOption.optionName}-${index}`}>
                                    {({ active }) => (
                                        <button onClick={dropdownOption.onSelect}
                                            key={`${dropdownOption.optionName}-${index}`}
                                            className={`${active ? 'bg-gray-400 text-white' : 'text-gray-900'
                                                } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                        >
                                            {dropdownOption.optionName}
                                        </button>
                                    )}
                                </Menu.Item>
                            ))
                        }
                    </div>
                </Menu.Items>
            </Menu>
        </div>
    )
}

export default NeoActionDropdownButton