import React from 'react'
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField } from '@mui/material';
import Switch from 'react-switch'
import { confirmAlert } from 'react-confirm-alert';
import { t } from '@lingui/macro';
import ManageUser from './components/ManageUser'
import MenuContext from './components/MenuContext';
import { getUsersAsync } from 'api/user';
function UserList() {
    const [modalOpen, setModalOpen] = React.useState(false);
    const [users, setUsers] = React.useState<Array<any>>([]);
    const [filter, setFilter] = React.useState("");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [currentEditing, setCurrengEditing] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState<any>(null)

    const handleOpenModal = () => {
        setModalOpen(true);
    }

    const handleCloseModal = () => {
        setModalOpen(false);
    }
    
    const handleAddUser = () => {
        setCurrengEditing(null)
        handleOpenModal()
    }

    const handleEdit = (event) => {
        var id = event.target.dataset.id;
        if(id == null) return;
        setCurrengEditing(id);
        handleOpenModal();
    }

    const handleDeleteUser = () => {
        confirmAlert({
            title: ``,
            message: t`Deseja deletar o usuário?`,
            buttons: [
                {
                    label: t`Sim`,
                    onClick: () => {

                    }
                },
                {
                    label: t`Não`,
                    onClick: () => { }
                }
            ]
        });
    }
    
    const handleOpenContextMenu = (event) => {
        event.preventDefault();
        setAnchorEl(
            anchorEl === null
                ? {
                    mouseX: event.clientX,
                    mouseY: event.clientY,
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                // Other native context menus might behave different.
                // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        );
    };

    const handleCloseContextMenu = () => {
        setAnchorEl(null);
    }

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
    setPage(newPage);
    };

    const getUsers = () => {
        if(!filter || filter === "")
            return users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        return users.filter(u =>
                u.firstName?.toLowerCase().includes(filter)
            ||  u.lastName?.toLowerCase().includes(filter)
            ||  u.username?.toLowerCase().includes(filter)
            ||  u.email?.toLowerCase().includes(filter)
            ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRefreshData = React.useCallback(async () => {
        var res = await getUsersAsync();
        if(res['type'] === 'success' && Array.isArray(res['data']))
            setUsers(res['data'])
    },[])

    React.useEffect(() => {
        handleRefreshData()
    }, [handleRefreshData])

    

    return (
        <React.Fragment>
            <ManageUser open={modalOpen} onClose={handleCloseModal} currentEditing={currentEditing} onRefreshData={handleRefreshData}/>
            <MenuContext anchorEl={ anchorEl } onClose={ handleCloseContextMenu } onAddUser={ handleAddUser } onRefreshData={ handleRefreshData }/>
            <Paper variant="elevation">
                <TableContainer sx={ { height: "calc(100vh - 140px)", width: '100%' } } onContextMenu={ handleOpenContextMenu }>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead style={{backgroundColor: "#FFF8E1", height: "70px"}} >
                            <TableRow>
                                <TableCell width={150}>
                                    Login
                                </TableCell>
                                <TableCell  width={250}>
                                    Email
                                </TableCell>
                                <TableCell  width={150}>
                                    Nome
                                </TableCell>
                                <TableCell  width={200}>
                                    Sobrenome
                                </TableCell>
                                <TableCell  width={150}>
                                    Grupo
                                </TableCell>
                                <TableCell  width={100}>
                                    Ativo
                                </TableCell>
                                <TableCell  width={100}> 
                                    Bloqueado
                                </TableCell>
                                <TableCell width={100}>
                                    Active Diretory
                                </TableCell>
                                <TableCell width={150}>
                                    Domínio AD
                                </TableCell>
                                <TableCell width={240}>
                                    Último Login
                                </TableCell>
                                <TableCell align="right" width={75}>
                                    Procurar:
                                </TableCell>
                                <TableCell width={150}>
                                    <TextField variant="standard" value={filter} onChange={({target: { value}}) => setFilter(value)}/>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {getUsers()?.map((user, index) => 
                                <TableRow key={index} style={{backgroundColor: index % 2 === 0 ? "white" : "rgb(247, 247, 245)"}}>
                                    <TableCell>
                                        {user.username}
                                    </TableCell>
                                    <TableCell>
                                        {user.email}
                                    </TableCell>
                                    <TableCell>
                                        {user.firstName}
                                    </TableCell>
                                    <TableCell>
                                        {user.lastName}
                                    </TableCell>
                                    <TableCell>
                                        {user.role?.name}
                                    </TableCell>
                                    <TableCell>
                                        <Switch onChange={() => {}} checked={user.activated} disabled={true}/>
                                    </TableCell>
                                    <TableCell>
                                        <Switch onChange={() => {}} checked={user.blocked} disabled={true}/>
                                    </TableCell>
                                    <TableCell>
                                        <Switch onChange={() => {}} checked={user.isAD} disabled={true}/>
                                    </TableCell>
                                    <TableCell>
                                        {user.domain}
                                    </TableCell>
                                    <TableCell>
                                        {user.lastLogin && new Date(user.lastLogin).toLocaleString()}
                                    </TableCell>
                                    <TableCell>
                                        <Button variant="contained" onClick={handleEdit} data-id={user['id']}>Editar</Button>
                                    </TableCell>
                                    <TableCell>
                                        <Button variant="contained" color="error" onClick={handleDeleteUser} data-id={user['id']}>Desativar</Button>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    component="span"
                    count={ users.length }
                    page={ page }
                    onPageChange={ handleChangePage }
                    rowsPerPage={ rowsPerPage }
                    onRowsPerPageChange={ handleChangeRowsPerPage }
                />
            </Paper>
        </React.Fragment>
    )
}

export default UserList