import React from "react";
import { deleteTableButtonAsync } from "api/data";
import { Trans } from "@lingui/macro";

//TODO não deveria ser DeleteButton? nome do arquivo também.
export default class AddButton extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            ...this.props.deleteId
        }
    }
    async handleDelete() {
        deleteTableButtonAsync(this.state.id).then(res => {
            this.props.closeModal();
        })
    }
    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        if (prevProps.tableButtonId !== this.props.tableButtonId) {
            this.setState({ ...this.props.tableButtonId })
        }
    }
    render() {
        return (
            <React.Fragment>
                {/* Main modal */}
                {this.props.open &&
                    <>
                        <div
                            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                        >
                            <div className="relative w-full my-6 mx-auto max-w-md">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                                    {/*header*/}
                                    <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                        <h3 className="text-3xl font-semibold text-gray-600">
                                            <Trans>Deseja mesmo deletar o botão "{this.state.name}"?</Trans>
                                        </h3>
                                    </div>
                                    <div className="relative p-6 flex-auto">
                                        <form id='createTaskForm' className='flex-row w-full'>
                                            {/*footer*/}
                                            <div className="flex items-center justify-evenly mt-3 pt-3 border-blueGray-200 rounded-b">
                                                <button
                                                    className="modal-accept-button"
                                                    onClick={() => this.props.closeModal()}>
                                                    <Trans>CANCELAR</Trans>
                                                </button>
                                                <button
                                                    className='modal-close-button'
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        this.handleDelete();
                                                    }}
                                                >
                                                    <Trans>DELETAR</Trans>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                }

            </React.Fragment>

        )
    }
}