import { RequestSignalParameter } from 'api';
import { ResourceGroup, ResourceGroupDetails } from 'api/data/types';
import { getAsync } from '..';

const route = 'resource-groups'

export const getResourceGroupByIdAsync = async (resourceGroupId: number, parameters?: RequestSignalParameter): Promise<ResourceGroup> => {
    return await getAsync({ url: `/${route}/${resourceGroupId}`, ...parameters });
}
export const getResourceGroupDetailsByIdAsync = async (resourceGroupId: number, parameters?: RequestSignalParameter): Promise<ResourceGroupDetails> => {
    return await getAsync({ url: `/${route}/${resourceGroupId}/details`, ...parameters });
}
export const getResourceGroupsAsync = async (parameters?: RequestSignalParameter): Promise<ResourceGroup[]> => {
    return await getAsync({ url: `/${route}`, ...parameters });
}
export const getResourceGroupsDetailsAsync = async (parameters?: RequestSignalParameter): Promise<ResourceGroupDetails[]> => {
    return await getAsync({ url: `/${route}/details`, ...parameters });
}