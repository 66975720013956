import NeoActionButton from "components/Buttons/NeoActionButton";
import NeoPanel from "components/Page/NeoPanel";
import { selectScenarioPageDataState, setModalState, } from "redux/AppNavigationState";
import { ModalType } from "redux/AppNavigationState/types";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { AsyncStatus } from "redux/types";
import NeoLoader from "components/Page/NeoLoader";
import { t, Trans } from "@lingui/macro";
import { useCallback, useRef, useState } from "react";
import { RiDeleteBin2Line } from "react-icons/ri";
import { deleteScenariosByIdsAsync } from "api/data";
import { loadScenariosAsync } from "redux/AppScenarioState";
import { DropdownProps } from "components/Buttons/types";
import { notifyPromise } from "utils/notification";
import ScenarioRowContainer from "./components/ScenarioRowContainer";
import { FaBars } from "react-icons/fa";
import nPlanModalRender from "components/Modals";
import {
    forceExecuteTaskByTypeAsync,
    forceExecuteTaskByTypeWithBodyAsync,
} from "api/scheduler/lib/task";

export enum ScenarioPageDropDownOptions {
    Delete = "Delete",
}

function ScenarioPage() {
    const dispatch = useAppDispatch();
    const handleOnNewScenario = () => {
        dispatch(setModalState(ModalType.ScenarioCreateModal));
    };
    const handleOnGenerateOrders = useCallback(() => {
        nPlanModalRender.question({
            headerText: t`Gerar Ordens de Produção e Requisição de Compras?`,
            acceptButtonText: t`Confirmar`,
            cancelButtonText: t`Cancelar`,
            acceptCallbackAsync: async () => {
                notifyPromise(
                    forceExecuteTaskByTypeWithBodyAsync({
                        queryParams: {
                            taskType: "ExecProcedure",
                        },
                        body: {
                            order: 0,
                            data: {
                                "Stored Procedure": "NEOTransform.sp208_Gerar_OP_RC",
                            },
                        },
                    }),
                    {
                        pending: t`Disparando tarefa de Gerar Ordens`,
                        success: t`Tarefa Disparada!`,
                        error: t`Erro ao disparar a tarefa!`,
                    }
                );
            },
        });
    }, []);

    const handleOnIntegrarDados = useCallback(() => {
        nPlanModalRender.question({
            headerText: t`Deseja Integrar dados?`,
            acceptButtonText: t`Confirmar`,
            cancelButtonText: t`Cancelar`,
            acceptCallbackAsync: async () => {
                notifyPromise(
                    forceExecuteTaskByTypeAsync({
                        queryParams: {
                            taskType: "IntegrateNeoScenario",
                        },
                    }),
                    {
                        pending: t`Disparando tarefa de Integração de dados`,
                        success: t`Tarefa Disparada!`,
                        error: t`Erro ao disparar a tarefa!`,
                    }
                );
            },
        });
    }, []);

    const selectedScenariosRef = useRef<Set<number>>(new Set());
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

    const onChangeCheckBoxScenario = (id: number) => {
        if (selectedScenariosRef.current.has(id))
            selectedScenariosRef.current.delete(id);
        else selectedScenariosRef.current.add(id);

        setIsDropdownOpen(selectedScenariosRef.current.size > 1);
    };

    const onDeleteAction = (scenarioIds: number[]) => {
        notifyPromise(deleteScenariosByIdsAsync({ body: scenarioIds }), {
            pending: t`Deletando ${selectedScenariosRef.current.size} cenários!`,
            success: t`Cenários deletados!`,
            error: t`Erro ao deletar cenários!`,
        }).then(() => {
            dispatch(loadScenariosAsync({}));
            selectedScenariosRef.current = new Set();
        });
    };

    const scenarioPageDataState = useAppSelector(selectScenarioPageDataState);

    const dropdownProps: DropdownProps<Partial<ScenarioPageDropDownOptions>> = {
        dropDownIcon: FaBars,
        dropdownOptions: {
            Delete: {
                optionName: t`Deletar`,
                onSelect: () => {
                    if (selectedScenariosRef.current.size > 0) {
                        nPlanModalRender.question({
                            headerText: t`Deseja deletar ${selectedScenariosRef.current?.size} Cenários?`,
                            acceptButtonText: t`Confirmar`,
                            cancelButtonText: t`Cancelar`,
                            acceptCallbackAsync: async () =>
                                onDeleteAction(Array.from(selectedScenariosRef.current)),
                        });
                    }
                },
                optionIcon: RiDeleteBin2Line,
            },
        },
    };

    if (scenarioPageDataState !== AsyncStatus.READY) return <NeoLoader />;

    return (
        <NeoPanel>
            <div className="flex grow">
                <div className="flex-shrink-0">
                    <NeoActionButton onClick={() => handleOnIntegrarDados()}>
                        <Trans>Integrar Dados</Trans>
                    </NeoActionButton>{" "}
                </div>
                {/* {
                    isDropdownOpen &&
                    <div className='w-8 h-8'><NeoActionDropdownButton {...dropdownProps} /></div>
                } */}
                <div className="grow h-full text-center">
                    <p className="inline font-semibold text-2xl">
                        <Trans>Cenário</Trans>
                    </p>
                </div>
                <div className="flex-shrink-0">
                    <NeoActionButton onClick={handleOnGenerateOrders}>
                        <Trans>Gerar Ordens</Trans>
                    </NeoActionButton>
                </div>
                <div className="flex-shrink-0">
                    <NeoActionButton onClick={handleOnNewScenario}>
                        <Trans>Novo Cenário</Trans>
                    </NeoActionButton>
                </div>
            </div>
            <div className="flex-col grow h-full">
                <div className="flex flex-row grow h-12  text-center items-center">
                    {/* <div className="ml-4 w-8 h-8">
                        {isDropdownOpen && <NeoActionDropdownButton {...dropdownProps} />}
                    </div> */}
                    {/* <div className='pl-4 w-4'><input className='group-hover:visible checked:visible invisible mr-1 w-4 focus:ring-0' disabled={true} checked={true} type='checkbox'></input></div> */}
                    <div className="w-120">
                        <Trans>Cenário</Trans>
                    </div>
                    <div className="w-30">
                        <Trans>Status</Trans>
                    </div>
                    <div className="w-48">
                        <Trans>Criado Por</Trans>
                    </div>
                    <div className="w-48">
                        <Trans>Data de Criação</Trans>
                    </div>
                    <div className="w-48">
                        <Trans>Data de Modificação</Trans>
                    </div>
                    <div className="w-48">
                        <Trans>Data Inicial</Trans>
                    </div>
                    <div className="w-48">
                        <Trans>Data Final</Trans>
                    </div>
                    <div className="w-24">
                        <Trans>Tipo</Trans>
                    </div>
                    {/* <div className='w-24'>Horizonte</div> */}
                    {/* <div className='w-24'>Congelado</div> */}
                    <div className="w-24">
                        <Trans comment="Botão com dropdown de açoes para um cenário: deletar, copiar, editar etc.">
                            Ações
                        </Trans>
                    </div>
                </div>
                <div className="grow h-full">
                    <ScenarioRowContainer
                        itemSize={60}
                        onChangeCheckBoxScenario={onChangeCheckBoxScenario}
                        selectedScenariosRef={selectedScenariosRef}
                    />
                </div>
            </div>
        </NeoPanel>
    );
}

export default ScenarioPage;
