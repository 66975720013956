export const notificationData = [
    {
        badgeIcon: 'badgeIcon',
        message: 'message',
        title: 'title',
        subject: 'subject',
        isRead: false,
        time: '05/05/2023'

    },
    {
        badgeIcon: 'badgeIcon',
        title: 'title',
        message: 'message',
        subject: 'subject',
        isRead: true,
        time: '05/05/2023'
    },
    {
        badgeIcon: 'badgeIcon',
        title: 'title',
        message: 'message',
        subject: 'subject',
        isRead: false,
        time: '05/05/2023'

    },
    {
        badgeIcon: 'badgeIcon',
        title: 'title',
        message: 'message',
        subject: 'subject',
        isRead: true,
        time: '05/05/2023'

    },
    {
        badgeIcon: 'badgeIcon',
        title: 'title',
        message: 'message',
        subject: 'subject',
        isRead: false,
        time: '05/05/2023'

    },
    {
        badgeIcon: 'badgeIcon',
        title: 'title',
        message: 'message',
        subject: 'subject',
        isRead: true,
        time: '05/05/2023'

    },
    {
        badgeIcon: 'badgeIcon',
        title: 'title',
        message: 'message',
        subject: 'subject',
        isRead: false,
        time: '05/05/2023'

    },
    {
        badgeIcon: 'badgeIcon',
        title: 'title',
        message: 'message',
        subject: 'subject',
        isRead: true,
        time: '05/05/2023'

    },
    {
        badgeIcon: 'badgeIcon',
        title: 'title',
        message: 'message',
        subject: 'subject',
        isRead: false,
        time: '05/05/2023'

    },
    {
        badgeIcon: 'badgeIcon',
        title: 'title',
        message: 'message',
        subject: 'subject',
        isRead: true,
        time: '05/05/2023'

    },
]