import { logoutAsync } from "api/auth/lib";
import { getSessionAsync } from "api/user/lib/session";
import React, { createContext, useEffect, useState } from "react";

export const UserContext = createContext<any>(null);

export const UserProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<any>(null);
    const [user, setUser] = useState({});
    const login = async (token) => {
        localStorage.setItem(process.env.REACT_APP_NPLAN_TOKEN_NAME ?? "", token)
        await handleFindUser()
    };
    const logout = async () => {
        await logoutAsync();
        localStorage.removeItem(process.env.REACT_APP_NPLAN_TOKEN_NAME ?? "")
        setIsAuthenticated(false);
        
    };
    const handleFindUser = React.useCallback(async () => {
        try {
            const res = await getSessionAsync()
            if (res['type'] === 'success') {
                setUser(res['data'])
                setIsAuthenticated(true)
            } else {
                setIsAuthenticated(false)
            }
        }catch {
            setIsAuthenticated(false)
        }
        
    },[])
    useEffect(() => {
        handleFindUser()
    }, [handleFindUser])
    return (
        <UserContext.Provider value={ { user, isAuthenticated, login, logout } }>
            {children}
        </UserContext.Provider>
    );
};