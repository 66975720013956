import NotificationFeedContainer from './NotificationFeed';
import { RiCheckboxCircleFill } from 'react-icons/ri';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { t } from '@lingui/macro';
import { useNotificationCenter } from "react-toastify/addons/use-notification-center"
import { notificationData } from '../mockDbNotification';

interface Data {
    exclude: boolean
}

// function App() {
//     const { notifications } = useNotificationCenter<Data>({
//         data: [
//             { id: "anId", createdAt: Date.now(), data: { exclude: false } },
//             { id: "anotherId", createdAt: Date.now(), data: { exclude: true } }
//         ],
//         sort: (l, r) => l.createdAt - r.createdAt,
//         filter: (item) => item.data.exclude === false
//     })
// }

interface Props {
    isSelected: boolean;
}

function NotificationPanel(props: Props) {

    //TODO verificar quais mensagens estão lidas para mostrar na aba de notificação

    return (
        <>
            <div className={`absolute z-50 flex flex-col items-center ${props.isSelected ? `visible` : `invisible`} mt-5 w-120 h-96 right-0 rounded-lg shadow bg-white`}>

                <div className='flex flex-row content-evenly w-full h-16 border-b border-gray-200 rounded justify-center items-center'>
                    <div className='flex items-center h-full w-full py-4'>
                        <div className='inline-block rounded-full ml-4 w-fit h-fit p-1 bg-yellow-600'>
                            <p className='lining-nums align-middle place-self-center w-full text-center'>{notificationData.length}</p>
                        </div>
                        <h1 className='inline-block ml-2 text-xl align-middle font-bold text-black'>{t`Notificacões`}</h1>
                    </div>

                    {/* <div className='flex ml-2 h-full w-full items-center'>
                        <RiCheckboxCircleFill className='fill text-green-500 w-5 h-5'></RiCheckboxCircleFill>
                        <a className='select-none cursor-pointer text-lg font-medium ml-2 text-blue-600'>{t`Marcar todas como lidas`}</a>
                    </div> */}
                </div>

                <NotificationFeedContainer />

                {/* <div className='flex-none border-t border-gray-200 flex w-full h-14 justify-center items-center rounded-b-lg cursor-pointer text-gray-400 hover:text-gray-600'>
                    <p className='flex items-center select-none font-semibold text-xl'> {t`Ver todas as notificações`}
                        <span>
                            <AiOutlineArrowRight className='ml-2 w-5 h-5' />
                        </span>
                    </p>
                </div> */}
            </div>
        </>
    )
}

export default NotificationPanel;