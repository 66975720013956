import { i18n } from "@lingui/core"
import { FixedFilterData } from "api/data/types"
import { FilterCriteria } from "redux/AppScenarioState/FilterCriteria/types"

//TODO gambiarra, melhorar depois
export const GetFixedFilterCriteriaByFilterCriteria = (filterCriteria: FilterCriteria): FixedFilterData => {
    const foundFilterCriteria = FilterCriteriaFilters.find(x => x.filterCriteria === filterCriteria);
    if (foundFilterCriteria) return foundFilterCriteria
    else return FilterCriteriaFilters[0];
}

export const FilterCriteriaFilters: FixedFilterData[] =
    [
        {
            filterId: 1,
            filterCriteria: FilterCriteria[FilterCriteria.ItemCode],
            label: () => i18n.t(`Código do Item`),
            placeholder: () => i18n.t(`Código do Item`),
            checked: true

        },
        {
            filterId: 2,
            filterCriteria: FilterCriteria[FilterCriteria.StockStatusTypeWithPeriod],
            label: () => i18n.t('Status com Período'),
            placeholder: () => i18n.t('Status com Período'),
            checked: true
        },
        {
            filterId: 3,
            filterCriteria: FilterCriteria[FilterCriteria.Level],
            label: () => i18n.t('Level'),
            placeholder: () => i18n.t('Level'),
            checked: true
        },
        {
            filterId: 4,
            filterCriteria: FilterCriteria[FilterCriteria.ItemPlanner],
            label: () => i18n.t('Planejador'),
            placeholder: () => i18n.t('Planejador'),
            checked: true
        },
        {
            filterId: 5,
            filterCriteria: FilterCriteria[FilterCriteria.ItemStockGroup],
            label: () => i18n.t('Grupo de Estoque'),
            placeholder: () => i18n.t('Grupo de Estoque'),
            checked: true
        },
    ]

