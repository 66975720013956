import React from 'react'
import { BsCircle, BsCircleFill } from 'react-icons/bs'
import { ListChildComponentProps } from 'react-window'
import { OutboundDirectMessage } from 'redux/SignalRConnector/Outbound/types'

function NotificationFeedItem(props: ListChildComponentProps<OutboundDirectMessage[]>) {
    const { badgeIcon, isRead, message, subject, time, title } = props.data[props.index]


    return (
        <>
            <div className=' flex border-t border-b border-gray-100 flex-row p-2 items-center grow h-fit h bg-white hover:bg-blue-50'
                style={{ minHeight: '95px' }}>
                <div className='flex-none rounded-full w-12 h-12 '>
                    <img src='favicon.ico' />
                </div>
                <div className='flex flex-col pl-3 h-full w-full'>
                    <div className='w-full h-full' style={{ maxWidth: '90%' }}>
                        <p className='font-bold text-base text-gray-500'>{message}</p>
                    </div>

                    <div className='flex w-full h-full justify-between'>
                        <p className='text-gray-400'>{subject}</p>
                    </div>
                    <div className='flex w-full h-full justify-between'>
                        <p className='text-gray-400'>2 dias atrás</p>
                    </div>
                </div>
                <div className='h-full mr-2 text-yellow-600 '>
                    {isRead ? <BsCircle /> : <BsCircleFill />}
                </div>
            </div>
        </>
    )
}

export default NotificationFeedItem
