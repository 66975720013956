import Tippy from "@tippyjs/react";
import {
  validateAndReturnAbreviation,
  validateAndReturnDivisionNumber,
} from "../utils";

interface ToolTipProps {
  label: string;
  value: any;
  tooltipText: string;
  type: "Value" | `Days`;
  color: string;
}

export function ToolTipComponent({
  label,
  tooltipText,
  value,
  type,
  color,
}: ToolTipProps) {
  return (
    <div className="flex gap-2 justify-between items-center h-4">
      <Tippy
        delay={[200, 50]}
        animation="fade"
        offset={[10, 10]}
        content={
          <span
            className="bg-blue-200 border border-gray-600 rounded-md font-semibold text-xs p-1 w-fit "
            style={{ backgroundColor: color }}
          >
            {tooltipText}
          </span>
        }
      >
        <label className="text-sm cursor-help">{label}</label>
      </Tippy>

      {type === "Value" ? (
        <p className="font-bold">
          {isNaN(value)
            ? "0"
            : (value / validateAndReturnDivisionNumber(value)).toFixed(2) +
              validateAndReturnAbreviation(value)}
        </p>
      ) : (
        <p className="font-bold">
          {isNaN(value) ? "0" : Math.round(value) + " d"}
          {}
        </p>
      )}
    </div>
  );
}
