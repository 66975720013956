import { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FaArrowRight, FaUser, FaCalendar, FaUndo, FaArrowDown } from 'react-icons/fa';
import { Grid } from '@mui/material';
import { putTableDataAsync } from 'api/data';
import { Trans } from '@lingui/macro';


function LogPopoverItem(props: any): JSX.Element {

    const [id, type, value] = props.row.pks.split(',');





    function getVariant(text, maxWidth): any {
        if (text == null || text == "")
            return 10
        const canvas = document.createElement("canvas");
        const ctx: any = canvas.getContext("2d");
        const typography = [26, 24, 20, 16, 12, 10];
        let fontSize = typography.shift();
        text = text.split(" ");
        ctx.font = `${fontSize}px sans-serif`;
        text.map(word => {
            while (typography.length > 0) {
                var width = ctx.measureText(word).width
                if (width > maxWidth) {
                    fontSize = typography.shift();
                    ctx.font = `${fontSize}px sans-serif`;
                } else {
                    break;
                }
            }


        })
        return fontSize;
    }
    const undoData = (row) => {
        let obj = {
            columnId: row.columnId,
            pks: row.pks,
            value: row.oldValue
        }
        putTableDataAsync(obj).then((res) => {
            if (res.type === "success") {
                props.setNeedRefresh(true);
                props.refreshData()
            }
        })
    }
    return (
        <Box sx={{ width: "100%", height: "100%", background: "#E5E5E5", borderRadius: 7, p: 2, display: "flex", flexDirection: "column" }}>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={12} md={9}>
                    <Box display="flex" alignItems="left" gap={1} flexDirection={"column"}>
                        <Box display="flex" flexDirection={'column'} alignItems="center" flex={1} mr={1}>
                            <Box display={'flex'} width={'100%'}>
                                <Typography variant="h6" sx={{
                                    // height: "25px",
                                    textAlign: "center",
                                    color: 'black',
                                    m: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: "left",
                                    fontSize: "20px"
                                }}>
                                    <Trans>Tabela</Trans>:
                                </Typography>
                                <Typography variant="h6" sx={{
                                    // height: "25px",
                                    textAlign: "center",
                                    color: 'black',
                                    marginLeft: '5px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: "left",
                                    fontSize: "20px",
                                    fontWeight: 'normal'
                                }}>
                                    {` ${props.row.tableName} `}
                                </Typography>
                            </Box>

                            <Box display={'flex'} width={'100%'}>
                                <Typography variant="h6" sx={{
                                    // height: "25px",
                                    textAlign: "center",
                                    color: 'black',
                                    m: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: "left",
                                    fontSize: "20px"
                                }}>
                                    <Trans>Coluna</Trans>:
                                </Typography>
                                <Typography variant="h6" sx={{
                                    width: "100%",
                                    // height: "25px",
                                    textAlign: "center",
                                    color: 'black',
                                    marginLeft: '5px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: "left",
                                    fontSize: "20px",
                                    fontWeight: 'normal'
                                }}>
                                    {` ${props.row.columnName}`}
                                </Typography>
                            </Box>

                            <Box display={'flex'} width={'100%'} gap={1}>
                                <Typography variant="h6" sx={{
                                    // height: "25px",
                                    textAlign: "center",
                                    color: 'black',
                                    m: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: "left",
                                    fontSize: "20px"
                                }}>
                                    <Trans>Linha</Trans>:
                                </Typography>
                                <Typography variant="h6" sx={{
                                    // height: "25px",
                                    textAlign: "center",
                                    color: 'black',
                                    marginLeft: '2px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: "left",
                                    fontSize: "20px",
                                    fontWeight: 'normal'
                                }}>
                                    {` ${value} `}
                                </Typography>
                                <Typography variant="h6" sx={{
                                    // height: "25px",
                                    textAlign: "center",
                                    color: 'black',
                                    m: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: "left",
                                    fontSize: "20px"
                                }}>
                                    <Trans>Chave Estrangeira</Trans>:
                                </Typography>
                                <Typography variant="h6" sx={{
                                    // height: "25px",
                                    textAlign: "center",
                                    color: 'black',
                                    marginLeft: '2px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: "left",
                                    fontSize: "20px",
                                    fontWeight: 'normal'
                                }}>
                                    {` ${id} `}
                                </Typography>
                                <Typography variant="h6" sx={{
                                    // height: "25px",
                                    textAlign: "center",
                                    color: 'black',
                                    m: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: "left",
                                    fontSize: "20px"
                                }}>
                                    <Trans>Tipo</Trans>:
                                </Typography>
                                <Typography variant="h6" sx={{
                                    // height: "25px",
                                    textAlign: "center",
                                    color: 'black',
                                    marginLeft: '2px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: "left",
                                    fontSize: "20px",
                                    fontWeight: 'normal'
                                }}>
                                    {` ${type} `}
                                </Typography>
                            </Box>

                        </Box>
                        <Box display="flex" flexDirection={'column'} alignItems="start" flex={1} mr={1}>
                            <Box display={'flex'} width={'100%'}>
                                <Typography variant="h6" sx={{
                                    // height: "25px",
                                    textAlign: "center",
                                    color: 'black',
                                    m: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: "left",
                                    fontSize: "20px"
                                }}>
                                    <Trans>Valor Anterior</Trans>:
                                </Typography>
                                <Typography
                                    gap={2}
                                    maxWidth={175}
                                    variant={"h5"}
                                    fontSize={'20px'}
                                    marginLeft={'5px'}
                                    display={"flex"}
                                    alignItems="center"
                                    component="div"
                                    style={{
                                        hyphens: "auto", wordBreak: "break-word"
                                    }}
                                >
                                    {`${props.row.oldValue}`}
                                </Typography>
                            </Box>

                            {/* <Typography gap={2} variant="h5" display={"flex"} justifySelf='center' alignItems="center" component="div">
                                <FaArrowDown size={20} />
                            </Typography> */}
                            <Box display={'flex'} width={'100%'}>
                                <Typography variant="h6" sx={{
                                    // height: "25px",
                                    textAlign: "center",
                                    color: 'black',
                                    m: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: "left",
                                    fontSize: "20px"
                                }}>
                                    <Trans>Novo Valor</Trans>:
                                </Typography>
                                <Typography
                                    gap={2}
                                    maxWidth={175}
                                    variant={"h5"}
                                    fontSize={'20px'}
                                    marginLeft={'5px'}
                                    display={"flex"}
                                    alignItems="center"
                                    component="div"
                                    style={{
                                        hyphens: "auto", wordBreak: "break-word"
                                    }}
                                >
                                    {`${props.row.newValue}`}
                                </Typography>
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center" flex={1} mr={1}>
                            <Typography fontSize={'18px'} gap={2} variant="subtitle1" display={"flex"} alignItems="center" component="div">
                                <FaUser /> {props.row.userName}
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" flex={1}>
                            <Typography fontSize='18px' gap={2} variant="subtitle1" display={"flex"} alignItems="center" component="div">
                                <FaCalendar /> {new Date(props.row.date).toLocaleDateString() + " " + new Date(props.row.date).toLocaleTimeString()}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Box display="flex" justifyContent="center" sx={{ cursor: "pointer" }}>
                        <FaUndo style={{ fontSize: '24px' }} onClick={() => undoData(props.row)} />
                    </Box>
                </Grid>
            </Grid>
        </Box >

    );
}

export default LogPopoverItem;