import React from 'react'
import { TextField, Box, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Switch, Button } from '@mui/material';
import { getRoleAsync } from 'api/user/lib/role';

const ManageRole = ({ open, onClose, currentEditing, onRefreshData }) => {
    const [group, setGroup] = React.useState<any>({
        id: 0,
        name: "",
        admin: false
    })
    
    const handleChange = (event: any) => {
        const value = event.target.type == 'checkbox' ? event.target.checked : event.target.value
        const field = event.target.name;
        setGroup(prevGroup => ({ ...prevGroup, [field]: value }))
    }

    const handleSave = async () => {
        // const res = group['id'] == 0 ? await apiIdentity.post('role', group) : await apiIdentity.put('role', group)
        // if (res['type'] == 'success') {
        //     onRefreshData();
        //     onClose();
        // }
    }
    React.useEffect(() => {
        (async () => {
            if (currentEditing == null) {
                setGroup({
                    id: 0,
                    name: "",
                    admin: false
                })
            }
            else {
                var res = await getRoleAsync(currentEditing);
                if (res['type'] === 'success')
                    setGroup(res['data'])
            }
        })()
    }, [currentEditing])
    return (
        <Dialog
            open={ open }
            onClose={ onClose }
        >
            <DialogTitle>
                { group['id'] == 0 ? 'Criar' : 'Editar' } Grupo
            </DialogTitle>
            <DialogContent>
                <Box display={ 'flex' } flexDirection={ 'column' } gap={ 1 } width={ 300 }>
                    <Box>
                        <Typography variant='caption'>Nome</Typography>
                        <TextField
                            fullWidth
                            name="name"
                            value={ group['name'] }
                            onChange={ handleChange }
                            variant='standard'
                            size='small'
                        />
                    </Box>
                    <Box display={ 'flex' } flexDirection={ 'column' }>
                        <Typography variant='caption'>Admin</Typography>
                        <Switch
                            checked={ group['admin'] }
                            name="admin"
                            onChange={ handleChange }
                        />
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Box width={ '100%' } p={ 2 } gap={ 2 } display={ 'flex' } flexDirection={ 'column' }>
                    <Button fullWidth variant='contained' onClick={ handleSave }>Salvar</Button>
                    <Button fullWidth variant='contained' color='error' onClick={ onClose }>Cancelar</Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
}

export default ManageRole;