import { MutableRefObject, useState } from "react";
import { ListChildComponentProps } from "react-window";
import DayJS from "dayjs";
import { DropdownPropsDictionary } from "components/Buttons/types";
import NeoActionDropdownButton from "components/Buttons/NeoActionDropdownButton";
import { ScenarioData } from "redux/AppScenarioState/dataTypes";
import { FaBars } from "react-icons/fa";
import { hexToRgb } from "@mui/material";
import { ScenarioRowDropDownOptions } from "./ScenarioRowContainer";

interface Props {
	scenarios: ScenarioData[];
	selectedScenariosRef: MutableRefObject<Set<number>>;
	onChangeCheckBoxScenario: (id: number) => void;
	onTryDelete: (scenarioId: number, scenarioName: string) => void;
	onSetToCurrent: (scenarioId: number, scenarioName: string) => void;
	dropdownOptions: () => DropdownPropsDictionary<ScenarioRowDropDownOptions>;
	periodTypeNames: string[];
	scenarioStatusData: {
		scenarioStatusName: string;
		scenarioStatusColor: string;
	}[];
}
function ScenarioRow(props: ListChildComponentProps<Props>) {
	const {
		onSetToCurrent,
		onTryDelete,
		onChangeCheckBoxScenario,
		scenarios,
		selectedScenariosRef,
		scenarioStatusData,
		dropdownOptions,
		periodTypeNames,
	} = props.data;
	const scenarioData: ScenarioData = scenarios[props.index];
	const periodTypeName = periodTypeNames[props.index];
	const { scenarioStatusColor, scenarioStatusName } =
		scenarioStatusData[props.index];
	const isChecked: boolean = selectedScenariosRef.current.has(scenarioData.id);
	const [checked, setChecked] = useState(isChecked);

	const localDropdownOptions = dropdownOptions();
	localDropdownOptions.Delete.onSelect = () =>
		onTryDelete(scenarioData.id, scenarioData.name);
	localDropdownOptions.SetToCurrent.onSelect = () =>
		onSetToCurrent(scenarioData.id, scenarioData.name);

	const onCheckerClick = () => {
		setChecked(!checked);
		onChangeCheckBoxScenario(scenarioData.id);
	};

	return (
		<div
			style={props.style}
			className={`flex flex-row h-full items-center  text-center ${props.index % 2 === 0 ? "bg-gray-200" : ""}`}
		>
			{/* <div className="ml-4 w-8 h-8">
				<input
					className="mr-4 w-4 focus:ring-0 text-center"
					checked={isChecked}
					type="checkbox"
					onChange={() => onCheckerClick()}
				></input>
			</div> */}
			<div className="w-120 text-start" style={{ paddingLeft: '8px' }}>{scenarioData.name}</div>
			<div
				style={{ backgroundColor: hexToRgb(scenarioStatusColor) }}
				className={`w-30  rounded-lg text-white font-bold `}
			>
				{scenarioStatusName}
			</div>
			<div className="w-48">{scenarioData.createdBy || "NeoAp"}</div>
			<div className="w-48">
				{scenarioData.createdOn
					? DayJS(scenarioData.createdOn).format("DD/MM/YYYY HH:MM:ss")
					: "-"}
			</div>
			<div className="w-48">
				{scenarioData.lastUpdatedOn
					? DayJS(scenarioData.lastUpdatedOn).format("DD/MM/YYYY HH:MM:ss")
					: "-"}
			</div>
			<div className="w-48">
				{DayJS(scenarioData.startDate).format("DD/MM/YYYY")}
			</div>
			<div className="w-48">
				{DayJS(scenarioData.endDate).format("DD/MM/YYYY")}
			</div>
			<div className="w-24">{periodTypeName}</div>
			{/* <div className='w-24'>{scenarioDataset.planningHorizon}</div> */}
			{/* <div className='w-24'>{scenarioDataset.frozenHorizon}</div> */}
			<div
				className="w-24 h-full"
				style={{ display: "flex", justifyContent: "center" }}
			>
				<NeoActionDropdownButton
					className="w-8 h-8"
					dropDownIcon={FaBars}
					dropdownOptions={localDropdownOptions}
				/>
			</div>
		</div>
	);
}

export default ScenarioRow;
