import { RequestParamsParameters, RequestSignalParameter } from "api";
import { ScriptParameter } from "api/data/types";
import { deleteAsync, getAsync, postAsync } from "api/scheduler";
import { Script } from "api/scheduler/types";
export const route = '/scripts/'

export const createScriptsAsync = async (parameters: RequestParamsParameters<null, Script>): Promise<Script> => {
    return await postAsync({ url: route, ...parameters });
}

export const getScriptsAsync = async (parameters?: RequestSignalParameter): Promise<Script[]> => {
    return await getAsync<Script[]>({ url: route, ...parameters });
}

export const getScriptAsync = async (scriptId: number, parameters?: RequestSignalParameter): Promise<Script[]> => {
    return await getAsync<Script>({ url: `${route}${scriptId}`, ...parameters });
}

export const ExecuteScriptByIdAsync = async (scriptId: number, parameters?: RequestParamsParameters<null, ScriptParameter | undefined>): Promise<void> => {
    return await postAsync({ url: `${route}${scriptId}/execute`, ...parameters });
}

export function deleteScriptByIdAsync(scriptId: number, parameters?: RequestSignalParameter): Promise<void> {
    return deleteAsync({ url: `${route}${scriptId}`, ...parameters });
}