import { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { StockInventoryData } from "../types";
import {
    validateAndReturnAbreviation,
    validateAndReturnDivisionNumber,
} from "../utils/formatValuesMillions";
import { Trans } from "@lingui/macro";

interface StatisticsProps {
    totalItensQuantity: number;
    totalSafetyStockValue: string;
    averageSafetyStockDays: string;
    totalCycleStockValue: string;
    averageCycleStockDays: string;
    averageServiceLevelPerc: string;
    totalStockValue: string;
    totalStockDays: string;
}

export function SummaryHeader() {
    const { stockInventory, selectedMainFilter } = useAppSelector(
        (state) => state.appStockState
    );
    const [statistics, setStatistics] = useState<StatisticsProps>(
        {} as StatisticsProps
    );

    const calculateStatistics = useCallback(
        (arr: StockInventoryData[]) => {
            if (selectedMainFilter.id === 0) {
                let totalItensQuantity = 0;
                let totalSafetyStockValue = 0;
                let totalSafetyStockDays = 0;
                let totalCycleStockValue = 0;
                let totalCycleStockDays = 0;
                let totalServiceLevelPerc = 0;
                let totalWeight = 0;
                let totalStockValue = 0;
                let totalStockDays = 0;

                for (let i = 0; i < arr.length; i++) {
                    const weight = arr[i].cycleStockValue;
                    totalItensQuantity += arr[i].itensQuantity;
                    totalSafetyStockValue += arr[i].safetyStockValue;
                    totalSafetyStockDays += arr[i].safetyStockDays * weight;
                    totalCycleStockValue += arr[i].cycleStockValue;
                    totalCycleStockDays += arr[i].cycleStockDays * weight;
                    totalServiceLevelPerc += arr[i].serviceLevelPerc * weight;
                    totalWeight += weight;
                }

                const averageSafetyStockDays =
                    totalSafetyStockDays / totalCycleStockValue;
                const averageCycleStockDays =
                    totalCycleStockDays / totalCycleStockValue;
                const averageServiceLevelPerc =
                    totalServiceLevelPerc / totalCycleStockValue;

                setStatistics({
                    totalItensQuantity: totalItensQuantity,
                    totalSafetyStockValue:
                        (
                            totalSafetyStockValue /
                            validateAndReturnDivisionNumber(totalSafetyStockValue)
                        ).toFixed(1) + validateAndReturnAbreviation(totalSafetyStockValue),
                    averageSafetyStockDays: averageSafetyStockDays.toFixed(1),
                    totalCycleStockValue:
                        (
                            totalCycleStockValue /
                            validateAndReturnDivisionNumber(totalCycleStockValue)
                        ).toFixed(1) + validateAndReturnAbreviation(totalCycleStockValue),
                    averageCycleStockDays: averageCycleStockDays.toFixed(1),
                    averageServiceLevelPerc: averageServiceLevelPerc.toFixed(1),
                    totalStockValue:
                        (
                            (totalCycleStockValue + totalSafetyStockValue) /
                            validateAndReturnDivisionNumber(totalCycleStockValue)
                        ).toFixed(1) + validateAndReturnAbreviation(totalCycleStockValue),
                    totalStockDays: (
                        averageCycleStockDays + averageSafetyStockDays
                    ).toFixed(1),
                });
            } else {
                let filteredArray = arr.filter(
                    (c) => c.companyId === selectedMainFilter.id
                );

                let totalItensQuantity = 0;
                let totalSafetyStockValue = 0;
                let totalSafetyStockDays = 0;
                let totalCycleStockValue = 0;
                let totalCycleStockDays = 0;
                let totalServiceLevelPerc = 0;
                let totalWeight = 0;

                for (let i = 0; i < filteredArray.length; i++) {
                    const weight = filteredArray[i].cycleStockValue;
                    totalItensQuantity += filteredArray[i].itensQuantity;
                    totalSafetyStockValue += filteredArray[i].safetyStockValue;
                    totalSafetyStockDays += filteredArray[i].safetyStockDays * weight;
                    totalCycleStockValue += filteredArray[i].cycleStockValue;
                    totalCycleStockDays += filteredArray[i].cycleStockDays * weight;
                    totalServiceLevelPerc += filteredArray[i].serviceLevelPerc * weight;
                    totalWeight += weight;
                }

                const averageSafetyStockDays =
                    totalSafetyStockDays / totalCycleStockValue;
                const averageCycleStockDays =
                    totalCycleStockDays / totalCycleStockValue;
                const averageServiceLevelPerc =
                    totalServiceLevelPerc / totalCycleStockValue;

                setStatistics({
                    totalItensQuantity: totalItensQuantity,
                    totalSafetyStockValue:
                        (
                            totalSafetyStockValue /
                            validateAndReturnDivisionNumber(totalSafetyStockValue)
                        ).toFixed(1) + validateAndReturnAbreviation(totalSafetyStockValue),
                    averageSafetyStockDays: averageSafetyStockDays.toFixed(1),
                    totalCycleStockValue:
                        (
                            totalCycleStockValue /
                            validateAndReturnDivisionNumber(totalCycleStockValue)
                        ).toFixed(1) + validateAndReturnAbreviation(totalCycleStockValue),
                    averageCycleStockDays: averageCycleStockDays.toFixed(1),
                    averageServiceLevelPerc: averageServiceLevelPerc.toFixed(1),
                    totalStockValue:
                        (
                            (totalCycleStockValue + totalSafetyStockValue) /
                            validateAndReturnDivisionNumber(totalCycleStockValue)
                        ).toFixed(1) + validateAndReturnAbreviation(totalCycleStockValue),
                    totalStockDays: (
                        averageCycleStockDays + averageSafetyStockDays
                    ).toFixed(1),
                });
            }
        },
        [selectedMainFilter.id]
    );

    useEffect(() => {
        calculateStatistics(stockInventory.data);
    }, [stockInventory, selectedMainFilter]);

    return (
        <div className="flex w-full m-x-0  h-fit rounded-md text-center border p-1 border-gray-200 " >
            <div className="flex flex-1 flex-col items-center justify-start ">
                <span>
                    <p className="text-md font-black ">{statistics.totalItensQuantity}</p>
                </span>
                <span>
                    <p className="" style={{ fontSize: "10px" }}>
                        <Trans>ITENS</Trans>
                    </p>
                </span>
            </div>
            <div className="flex flex-1 flex-col items-center justify-start ">
                <span>
                    <p className="text-md font-black ">
                        <Trans>R$</Trans> {statistics.totalSafetyStockValue}
                    </p>
                </span>
                <span>
                    <p className="" style={{ fontSize: "10px" }}>
                        <Trans>EST. SEG. (VALOR)</Trans>
                    </p>
                </span>
            </div>
            <div className="flex flex-1 flex-col items-center justify-start ">
                <span>
                    <p className="text-md font-black ">
                        <Trans>R$</Trans> {statistics.totalCycleStockValue}
                    </p>
                </span>
                <span>
                    <p className="" style={{ fontSize: "10px" }}>
                        <Trans> EST. CICLO (VALOR)</Trans>
                    </p>
                </span>
            </div>
            <div className="flex flex-1 flex-col items-center justify-start ">
                <span>
                    <p className="text-md font-black ">
                        <Trans>R$</Trans> {statistics.totalStockValue}
                    </p>
                </span>
                <span>
                    <p className="" style={{ fontSize: "10px" }}>
                        <Trans> EST. TOTAL (VALOR)</Trans>
                    </p>
                </span>
            </div>
            <div className="flex flex-1 flex-col items-center justify-start ">
                <span>
                    <p className="text-md font-black ">
                        {statistics.averageSafetyStockDays} <Trans>Dias</Trans>
                    </p>
                </span>
                <span>
                    <p className="" style={{ fontSize: "10px" }}>
                        <Trans>EST. SEG. (DIAS)</Trans>
                    </p>
                </span>
            </div>

            <div className="flex flex-1 flex-col items-center justify-start ">
                <span>
                    <p className="text-md font-black ">
                        {statistics.averageCycleStockDays} <Trans>Dias</Trans>
                    </p>
                </span>
                <span>
                    <p className="" style={{ fontSize: "10px" }}>
                        <Trans>EST. CICLO (DIAS)</Trans>
                    </p>
                </span>
            </div>
            <div className="flex flex-1 flex-col items-center justify-start ">
                <span>
                    <p className="text-md font-black ">
                        {statistics.totalStockDays} <Trans>Dias</Trans>
                    </p>
                </span>
                <span>
                    <p className="" style={{ fontSize: "10px" }}>
                        <Trans>EST. TOTAL (DIAS)</Trans>
                    </p>
                </span>
            </div>
            <div className="flex flex-1 flex-col items-center justify-start ">
                <span>
                    <p className="text-md font-black ">
                        {statistics.averageServiceLevelPerc}%
                    </p>
                </span>
                <span>
                    <p className="" style={{ fontSize: "10px" }}>
                        <Trans> ATENDIMENTO</Trans>
                    </p>
                </span>
            </div>
        </div>
    );
}
