import { StockInventoryData } from "../types";

export function aggregateData(data: StockInventoryData[]) {
  const distinctConcatenatedIds = [
    ...new Set(
      data.map(
        (obj) =>
          `${obj.classificationVolumeId}-${obj.classificationUncertaintyId}-${obj.classificationComplexityId}`
      )
    ),
  ];

  const result = distinctConcatenatedIds.map((concatenatedId) => {
    const filteredObjects = data.filter(
      (obj) =>
        `${obj.classificationVolumeId}-${obj.classificationUncertaintyId}-${obj.classificationComplexityId}` ===
        concatenatedId
    );

    const totalCycleStockValue = filteredObjects.reduce(
      (sum, obj) => sum + obj.cycleStockValue,
      0
    );

    const weightedSumSafetyStockDays = filteredObjects.reduce(
      (sum, obj) => sum + obj.cycleStockValue * obj.safetyStockDays,
      0
    );
    const weightedAvgSafetyStockDays =
      weightedSumSafetyStockDays / totalCycleStockValue;

    const weightedSumCycleStockDays = filteredObjects.reduce(
      (sum, obj) => sum + obj.cycleStockValue * obj.cycleStockDays,
      0
    );
    const weightedAvgCycleStockDays =
      weightedSumCycleStockDays / totalCycleStockValue;

    const weightedSumServiceLevelPerc = filteredObjects.reduce(
      (sum, obj) => sum + obj.cycleStockValue * obj.serviceLevelPerc,
      0
    );
    const weightedAvgServiceLevelPerc =
      weightedSumServiceLevelPerc / totalCycleStockValue;

    const totalItensQuantity = filteredObjects.reduce(
      (sum, obj) => sum + obj.itensQuantity,
      0
    );
    const totalSafetyStockValue = filteredObjects.reduce(
      (sum, obj) => sum + obj.safetyStockValue,
      0
    );

    // Extracting the first object to include all other keys from the original object
    const {
      classificationVolumeId,
      classificationUncertaintyId,
      classificationComplexityId,
      classificationComplexity,
      classificationComplexityPriority,
      classificationUncertainty,
      classificationUncertaintyPriority,
      classificationVolume,
      classificationVolumePriority,
      color,
      company,
      companyId,
    } = filteredObjects[0];
    return {
      classificationComplexity,
      classificationComplexityId,
      classificationComplexityPriority,
      classificationUncertainty,
      classificationUncertaintyId,
      classificationUncertaintyPriority,
      classificationVolume,
      classificationVolumeId,
      classificationVolumePriority,
      serviceLevelPerc: weightedAvgServiceLevelPerc,
      company,
      companyId,
      cycleStockDays: weightedAvgCycleStockDays,
      cycleStockValue: totalCycleStockValue,
      itensQuantity: totalItensQuantity,
      safetyStockDays: weightedAvgSafetyStockDays,
      safetyStockValue: totalSafetyStockValue,
      color,
    };
  });

  return result;
}
