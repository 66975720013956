import { identityUrl } from "api/configuration";
import { BaseRequestParameters, deleteBaseAsync, getBaseAsync, postBaseAsync, putBaseAsync } from 'api/';

export const getAsync = async <TType>(parameters: BaseRequestParameters): Promise<TType | any> => {
    return getBaseAsync({ baseUrl: identityUrl, ...parameters });
}

export const postAsync = async <TType>(parameters: BaseRequestParameters): Promise<TType | any> => {
    return postBaseAsync({ baseUrl: identityUrl, ...parameters });
}

export const deleteAsync = async <TType>(parameters: BaseRequestParameters): Promise<TType | any> => {
    return deleteBaseAsync({ baseUrl: identityUrl, ...parameters });
}

export const putAsync = async <TType>(parameters: BaseRequestParameters): Promise<TType | any> => {
    return putBaseAsync({ baseUrl: identityUrl, ...parameters });
}