import { RootState } from 'redux/store';
import { AsyncStatus } from 'redux/types';
import { appNavigationSlice } from './reducers';

export const selectIsHidden = (state: RootState) => state.appNavigationState.isHidden;

export const selectScenarioPageDataState = (state: RootState) => {
    const loadingAppComponents = state.appNavigationState.loadingAppComponent
    if (loadingAppComponents.Scenarios === AsyncStatus.READY &&
        loadingAppComponents.UserFlatData === AsyncStatus.READY
    ) return AsyncStatus.READY
    else return AsyncStatus.LOADING
}
export const selectPlanningPageDataState = (state: RootState) => {
    const loadingComponents = state.appNavigationState.loadingScenarioComponent
    if (loadingComponents.Items === AsyncStatus.READY &&
        loadingComponents.PlanningGrids === AsyncStatus.READY &&
        loadingComponents.Periods === AsyncStatus.READY &&
        loadingComponents.ItemAttributesBundle === AsyncStatus.READY
    ) return AsyncStatus.READY
    else return AsyncStatus.LOADING
}

export const selectOccupationPageDataState = (state: RootState) => {
    const loadingComponents = state.appNavigationState.loadingScenarioComponent
    if (loadingComponents.PlanningGridAllocations === AsyncStatus.READY &&
        loadingComponents.ResourceGroup === AsyncStatus.READY &&
        loadingComponents.ItemAttributesBundle === AsyncStatus.READY &&
        loadingComponents.Capacities === AsyncStatus.READY &&
        loadingComponents.PlanningGrids === AsyncStatus.READY

    ) return AsyncStatus.READY
    else return AsyncStatus.LOADING
}

//TODO melhorar este metodo
export const selectIsTokenExpired = (state: RootState) => state.appNavigationState.isTokenExpired;

export * from './reducers'
export default appNavigationSlice.reducer;