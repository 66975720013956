
export interface CountryData {
    name: string,
    code: CountryCode,
    flagCode: FlagCode
}

export enum FlagCode {
    UnitedStates = 'US',
    Brazil = 'BR',
    Mexico = 'MX',
    Spain = 'ES',
}

export enum CountryCode {
    enUS = 'en-US',
    ptBR = 'pt-BR',
    esMX = 'es-MX',
    esES = 'es-MX',
}

export const SupportedCountriesLanguages: CountryData[] = [
    { name: 'United States', code: CountryCode.enUS, flagCode: FlagCode.UnitedStates },
    { name: 'Brasil', code: CountryCode.ptBR, flagCode: FlagCode.Brazil },
    // { name: 'México', code: CountryCode.esMX, flagCode: FlagCode.Mexico },
    { name: 'España', code: CountryCode.esES, flagCode: FlagCode.Spain }
];

export const getCountryDataByCountryCode = (countryCode: CountryCode | string): CountryData => {
    const foundSupportedCountry = SupportedCountriesLanguages.find((supportedCountriesLanguage) => supportedCountriesLanguage.code === countryCode);

    if (foundSupportedCountry)
        return foundSupportedCountry;
    else
        return SupportedCountriesLanguages[0]
}