import React, { useState } from 'react'
import { applyMultipleEditOnSelectedItems, selectSelectedItemsCount } from 'redux/AppScenarioState';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import EditableInput from '../../PlanningGrid/components/EditableInput'
import { t, Trans } from '@lingui/macro';
import NeoActionButton from 'components/Buttons/NeoActionButton';

export enum MultipleEditMode {
    Days,
    Unit,
    Percentage
}

const MultpleEditModeCharacterSelector = (inputMode: MultipleEditMode): string => {
    switch (inputMode) {
        case MultipleEditMode.Days:
            return t({ message: 'D', comment: 'unidade/letra para simbolizar Dias, ex: D' })
        case MultipleEditMode.Percentage:
            return t({ message: '%', comment: 'unidade/letra para simbolizar Porcentagem/Multiplicação, ex: %' })
        case MultipleEditMode.Unit:
        default:
            return t({ message: 'Un', comment: 'unidade/letra para simbolizar Unidades, ex: Un' })
    }
}

function MultipleEditPanel() {
    const dispatch = useAppDispatch();

    const selectedItemsCount = useAppSelector(selectSelectedItemsCount)

    const [targetStockMultipleMode, setTargetStockMultipleMode] = useState<MultipleEditMode>(MultipleEditMode.Days);
    const [minStockMultipleMode, setMinStockMultipleMode] = useState<MultipleEditMode>(MultipleEditMode.Days);

    const [targetStock, setTargetStock] = useState<undefined | number>(undefined);
    const [minStock, setMinStock] = useState<undefined | number>(undefined);

    const [minimunReorderQuantity, setMinimunReorderQuantity] = useState<undefined | number>(undefined);
    const [reorderMultiple, setReorderMultiple] = useState<undefined | number>(undefined);

    const applyOnAllSelectedItems = () => {
        dispatch(applyMultipleEditOnSelectedItems({
            minStockMultipleMode: minStockMultipleMode,
            targetStockMultipleMode: targetStockMultipleMode,
            minStock: Number(minStock),
            targetStock: Number(targetStock)
        }))
    }

    return (
        <div className='absolute left-0 top-8 z-50 bg-white border border-black rounded-b-lg flex flex-col w-auto h-auto text-sm space-y-1 p-4'>
            <p className='font-bold pb-1'>
                <Trans>Itens Selecionados: {selectedItemsCount}</Trans>
            </p>
            <EditableInput text={t`Estoque Alvo`}
                unit={MultpleEditModeCharacterSelector(targetStockMultipleMode)}
                id='targetStock'
                inputValue={targetStock} onSimpleChange={setTargetStock}
                type='number'
                onChangeUnit={() => setTargetStockMultipleMode((targetStockMultipleMode + 1) % 3)} />
            <EditableInput text={t`Ponto de Reposição`}
                unit={MultpleEditModeCharacterSelector(minStockMultipleMode)}
                id='minStock'
                inputValue={minStock} onSimpleChange={setMinStock}
                type='number'
                onChangeUnit={() => setMinStockMultipleMode((minStockMultipleMode + 1) % 3)} />
            <EditableInput text={t`Lote Mínimo`} unit={t({ message: 'Un', comment: 'unidade/letra para simbolizar Unidades, ex: Un' })} id='minimumReorderQuantity' inputValue={minimunReorderQuantity} onSimpleChange={setMinimunReorderQuantity} type='number' />
            <EditableInput text={t`Lote Multiplo`} unit={t({ message: 'Un', comment: 'unidade/letra para simbolizar Unidades, ex: Un' })} id='reorderMultiple' inputValue={reorderMultiple} onSimpleChange={setReorderMultiple} type='number' />
            <NeoActionButton onClick={() => applyOnAllSelectedItems()}>{t({ message: 'Aplicar' })}</NeoActionButton>
        </div>
    )
}
export default MultipleEditPanel
