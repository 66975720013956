import NeoLoader from "components/Page/NeoLoader"
import { useAppSelector } from "redux/hooks"
import { AsyncStatus } from "redux/types"
import StockPagePolicy from "../StockPolicyPage"

export function StockPolicyPageContaniner() {
    const scenariosLoadStatus = useAppSelector((state) => state.appNavigationState.loadingAppComponent.Scenarios)
    if (scenariosLoadStatus !== AsyncStatus.READY) return <NeoLoader />

    return (
        <>
            <StockPagePolicy />
        </>
    )
}