import { UserContext } from 'components/UserProvider';
import { initAxios } from 'configuration/axios';
import React, { useContext, useEffect } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

interface Props {
    children?: React.ReactNode
}

function RequireAuth(props: Props) {
    const navigate = useNavigate();
    useEffect(() => {
        initAxios(); 
    },[])
    const [valid, setValid] = React.useState(false)
    const { user, isAuthenticated } = useContext(UserContext)
    React.useEffect(() => {
        if(isAuthenticated == null) return;
        if(isAuthenticated == false) navigate('/login')
        if(isAuthenticated == true) setValid(true)
    },[isAuthenticated]);
    return (
        <React.Fragment>
            {
                !valid && <div style={{ width: "100%", height: "100vh", fontWeight: "bold", display: "flex", justifyContent: "center", alignItems: "center" }}><h1>Carregando...</h1></div>
            }
            {
                valid && props.children
            }
        </React.Fragment>
    )
}
export default RequireAuth
