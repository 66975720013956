import { FilterCriteriaFilters } from "pages/PlanningPage/components/PlanningGridPanel/utils/FixedCriteriaFilters";
import { AppScenarioState } from "./types";

export const initialState: AppScenarioState = {
    // colorByColorAttributeId: {},
    selectedAttributesToShow: [],
    itemOperations: [],
    itemResources: [],
    operationTypes: [],
    selectedFiltersToShow: [],
    attributeOptions: { options: [] },
    FixedCriteriaFilterOptions: FilterCriteriaFilters,
    periodTypeById: {},
    completeStructures: [],
    completeStructuresNodeByItemId: {},
    completeStructuresMaxLevelByItemId: {},
    completeStructuresByItemMaster: {},
    completeStructureOrderDataByItemMaster: {},
    scenarioCategoriesById: {},
    scenarioCriteriaById: {},
    planningGridGlobalValuesByItemId: {},
    itemsIdsWithPlanningGrid: [],
    itemIdPeriodIdByPlanningGridId: {},
    setupTimeByPeriodIdByItemIdByResourceId: {},
    periodTypes: [],
    itemPlannerById: {},
    itemStockGroupById: {},
    capacities: [],
    demandDataRanges: {
        initialDate: new Date(),
        finalDate: new Date(),
    },
    itemAttributes: {
        itemAttribute1: {},
        itemAttribute2: {},
        itemAttribute3: {},
        itemAttribute4: {},
        itemAttribute5: {},
        itemAttribute6: {},
        itemAttribute7: {},
        itemAttribute8: {},
        itemAttribute9: {},
        itemAttribute10: {},
    },
    items: [],
    periods: [],
    planningGridAllocationsDetails: [],
    planningGridResources: [],
    planningStatuses: [],
    productionTypes: [],
    resources: [],
    resourceGroupDetails: [],
    scenarios: [],
    scenarioCategories: [],
    scenarioCriterias: [],
    scenarioPriorities: [],
    scenarioStatuses: [],
    scenarioSummaries: [],
    resetState: {
        chartDataByResourceGroup: {},
        planningGridsByItemId: {},
        planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId: {},
        selectedResources: [],
    },
    occupationPagePreferences: {
        isColorByProduct: true,
        isSetupHidden: false,
        isFiniteCapacity: false,
        hasProcessTime: false
    },
    chartDataByResourceGroup: {},
    colorByProductId: {},
    colorByAttributeId: {},
    complexPeriods: [],
    filterData: {
        itemIdsByItemPlannerIds: {},
        itemIdsByStockGroupIds: {},
        itemIdsByLevel: {},
        text: "",
        orderedItemIds: [],
        searchCriteriasData: {
            none: undefined,
            Level: undefined,
            ItemPlanner: undefined,
            ItemStockGroup: undefined,
            // StockStatusType: undefined,
            StockStatusTypeWithPeriod: undefined,
            ItemCode: undefined,
            ResourceGroup: undefined,
            OccupationPageResourceAndPeriod: undefined,
            OccupationPageResourceGroup: undefined,
            ItemAttribute1: undefined,
            ItemAttribute2: undefined,
            ItemAttribute3: undefined,
            ItemAttribute4: undefined,
            ItemAttribute5: undefined,
            ItemAttribute6: undefined,
            ItemAttribute7: undefined,
            ItemAttribute8: undefined,
            ItemAttribute9: undefined,
            ItemAttribute10: undefined,
        },
        itemIdsByStockStatus: {
            Baixo: [],
            MTO: [],
            Alto: [],
            Excesso: [],
            Falta: [],
            Risco: [],
            Ruptura: [],
            Trancado: [],
            Normal: [],
        },
        itemIdsByStockStatusByPeriod: {
            Baixo: {},
            MTO: {},
            Alto: {},
            Excesso: {},
            Falta: {},
            Risco: {},
            Ruptura: {},
            Trancado: {},
            Normal: {},
        },
        itemsIdsByAttributeId: {
            ItemAttribute1: {},
            ItemAttribute2: {},
            ItemAttribute3: {},
            ItemAttribute4: {},
            ItemAttribute5: {},
            ItemAttribute6: {},
            ItemAttribute7: {},
            ItemAttribute8: {},
            ItemAttribute9: {},
            ItemAttribute10: {},
        },
        itemIdsByResourceGroup: {},
    },
    filterResourceData: {
        resourcesProcessTimeData: {},
        resourceWithProcessTime: [],
        orderedResourcesIds: [],
        resourceSearchCriteriasData: {
            none: undefined,
            FiniteCapacity: undefined,
            ProcessTime: undefined
        },
        resourcesFiltered: []

    },
    isExpandedPeriods: true,
    isExpandedProducts: true,
    isWeekly: false,
    minifiedPeriods: [],
    periodsIdToIndex: {},
    planningGridsDataByItemId: {},
    resourceGroupById: {},
    planningStatusData: [],
    setupTimeByItemId: {},
    itemById: {},
    planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId: {},
    capacityByPeriodIdByResourceId: {},
    resourcesToSelect: [],
    resourcesToSelectFilteredByFiniteCapacity: [],
    selectedItemIds: [],
    selectedResources: [],
    attributeListByAttributeCode: {},
    stockStatusByCode: {},
    attributeList: [],
    stockStatus: [],
    userData: {
        demandDateRange: {
            finalDate: new Date(),
            initialDate: new Date(),
        },
    },
    forceRedraw: {
        FilterModule: false,
    },
};
