import { buildStyles, CircularProgressbar, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useAppSelector } from 'redux/hooks';
import { NeoYellow } from 'utils';

function DownloadProgressContainer() {
    const isVisible = useAppSelector((state) => state.appNavigationState.loadingState.isVisible)
    const loadingCount = useAppSelector((state) => state.appNavigationState.loadingState.loadingCount)
    const loadingTarget = useAppSelector((state) => state.appNavigationState.loadingState.loadingTarget)

    const yellowCounter = useAppSelector((state) => state.appNavigationState.loadingStateCounter.Yellow)
    const blueCounter = useAppSelector((state) => state.appNavigationState.loadingStateCounter.Blue)
    const redCounter = useAppSelector((state) => state.appNavigationState.loadingStateCounter.Red)

    if (!isVisible) return <></>

    return (
        <div className='flex-none w-14 h-14 self-center'>
            <CircularProgressbarWithChildren
                value={((yellowCounter.loadingCount / yellowCounter.loadingTarget) * 100)}
                strokeWidth={8}
                text={`${((loadingCount / loadingTarget) * 100).toFixed(0)}%`}
                styles={buildStyles({
                    textColor: NeoYellow,
                    pathColor: NeoYellow,
                    trailColor: "transparent"
                })}
            >
                <div style={{ width: "84%" }}>
                    <CircularProgressbarWithChildren
                        value={((redCounter.loadingCount / redCounter.loadingTarget) * 100)}
                        strokeWidth={8}
                        styles={buildStyles({
                            pathColor: "#f00",
                            trailColor: "transparent"
                        })}
                    >
                        <div style={{ width: "84%" }}>
                            <CircularProgressbar
                                value={((blueCounter.loadingCount / blueCounter.loadingTarget) * 100)}
                                styles={buildStyles({
                                    trailColor: "transparent"
                                })}
                            />
                        </div>
                    </CircularProgressbarWithChildren>
                </div>
            </CircularProgressbarWithChildren>
        </div>
    )
}

export default DownloadProgressContainer