import { AiOutlineCloseCircle } from 'react-icons/ai'
import { nPlanModalProps } from '.'
import { useEffect } from 'react'
export interface QuestionModalProps extends nPlanModalProps {
    headerText: string,
    bodyText?: string,
    acceptButtonText: string,
    cancelButtonText?: string,
    acceptCallbackAsync?: () => Promise<void>
}

function QuestionModal(props: QuestionModalProps) {
    const { finishCallback, headerText, bodyText, acceptButtonText, cancelButtonText, acceptCallbackAsync: acceptCallback, cancelCallback, exitCallback } = props
    useEffect(() => {
        const close = (e) => {
            if (e.key === 'Escape') {
                props.exitCallback?.()
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])
    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative w-full my-6 mx-auto max-w-md">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex  items-center justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                            <div className='w-11/12'>
                                <h3 className="text-3xl font-semibold text-gray-600">
                                    {headerText}
                                </h3>
                            </div>
                            <div className='w-1/12 h-full cursor-pointer flex items-center flex-col justify-center'>
                                <AiOutlineCloseCircle
                                    className="ml-auto self-center text-gray-600 hover:text-gray-900 float-right "
                                    onClick={() => exitCallback?.()}
                                    size={40}
                                >
                                </AiOutlineCloseCircle>
                            </div>
                        </div>
                        {/*body*/}
                        {
                            bodyText &&
                            <div className="relative flex-auto px-6 py-3">
                                <p className=''>
                                    {bodyText}
                                </p>
                                {/*footer*/}
                            </div>
                        }
                        <div className={`flex items-center justify-evenly pb-3 pt-3 ${bodyText ? 'mt-3 border-t border-solid border-blueGray-200' : ''} rounded-b`}>
                            {
                                cancelButtonText &&
                                <button
                                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                                    onClick={() => cancelCallback?.()}
                                >
                                    {cancelButtonText}
                                </button>
                            }
                            <button
                                className='w-fit px-4 h-10 font-semibold text-lg rounded-full text-white items-center justify-center bg-yellow-400 focus:outline-none'
                                onClick={() => acceptCallback?.().then(() => finishCallback?.())}
                            >
                                {acceptButtonText}
                            </button>
                        </div>

                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}

export default QuestionModal
