import "utils/css/styles.css";
import { AiOutlineClear, AiTwotoneCalendar } from "react-icons/ai";
import { BiMessageAltDetail } from "react-icons/bi";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import IconButton from "./components/IconButton";
import { resetFilters, resetSelectedItems, toggleExpandedPeriods, toggleExpandProductRow, } from "redux/AppScenarioState";
import "react-dropdown-tree-select/dist/styles.css";
import { RiCheckboxMultipleLine, RiEditLine } from "react-icons/ri";
import { Trans } from '@lingui/macro';
import { useState } from "react";
import React from "react";
import MultipleEditPanel from "./components/MultipleEditPanel";
import { HandlePlanningGridPanelFilterModule } from "./_modules";
import { AttributeSelectionDropdownMenuMemoized } from "./components/AttributeSelectionDropdown";
import { FixedCriteriasSelectionDropdownMenuMemoized } from "./components/FixedCriteriasSelectionDropdown";
import { TippyComponent } from "../PlanningGrid/components/TippyComponent";
import { ToolTipPlanningGridOptions } from "api/data/enums";
import { useLingui } from "@lingui/react";


function PlanningGridPanel() {
    const dispatch = useAppDispatch();
    const isWeekly = useAppSelector((store) => store.appScenarioState.isWeekly);
    const { i18n } = useLingui()

    const [isMultipleEditOpen, setIsMultipleEditOpen] = useState(false);

    return (
        <div className="flex grow h-fit z-20">
            <div className="flex w-full flex-col h-full">
                <div className="text-center w-full text-xl mb-2">
                    <Trans comment="Titulo no topo do painel do PlanningGrid">Grid de Planejamento</Trans>
                </div>
                <div className="flex w-full justify-center items-center h-auto space-x-10 flex-wrap">
                    <div className="ml-28 grid grid-rows-1 grid-flow-col h-auto gap-1 ">
                        <TippyComponent tooltipText={i18n.t(ToolTipPlanningGridOptions.multipleEdit)}>
                            <span className="relative">
                                <IconButton icon={RiEditLine} onClick={() => setIsMultipleEditOpen(!isMultipleEditOpen)} />
                                {isMultipleEditOpen && <MultipleEditPanel />}
                            </span>
                        </TippyComponent>
                        <TippyComponent tooltipText={i18n.t(ToolTipPlanningGridOptions.removeFilters)}>
                            <IconButton icon={AiOutlineClear} onClick={() => dispatch(resetFilters())} />
                        </TippyComponent>
                        <TippyComponent tooltipText={i18n.t(ToolTipPlanningGridOptions.selectAll)}>
                            <IconButton icon={RiCheckboxMultipleLine} onClick={() => dispatch(resetSelectedItems())} />
                        </TippyComponent>
                        {/* <IconButton icon={BsGraphUp} onClick={() => dispatch(recalculateSelectedItemMps())} /> */}
                        <TippyComponent tooltipText={i18n.t(ToolTipPlanningGridOptions.periodChanger)}>
                            <IconButton icon={AiTwotoneCalendar} onClick={() => dispatch(toggleExpandedPeriods())} disabled={!isWeekly} />
                        </TippyComponent>
                        {/* <IconButton icon={BiMessageAltDetail} onClick={() => dispatch(toggleExpandProductRow())} /> */}
                        <AttributeSelectionDropdownMenuMemoized />
                        <FixedCriteriasSelectionDropdownMenuMemoized />
                    </div>
                    <HandlePlanningGridPanelFilterModule />
                </div>
            </div>
            {/*TODO tem que redraw quando limpar os filtros, se não nao fica limpo a UI*/}
            {/* <NeoActionButton onClick={onClearFilters}><Trans>Limpar Filtros</Trans></NeoActionButton> */}

            {/* <div className='flex shrink-0 h-full items-center'>
                <Trans
                    id='Salvar Scenário'
                    render={({ translation }) =>
                        <NeoActionButton onClick={handleOnSaveDataset}>{translation?.toString()}</NeoActionButton>
                    } />
            </div> */}
        </div>
    );
}

export const PlanningGridPanelMemoized = React.memo(PlanningGridPanel);
