import { notifyLoading, notifyPromise } from "utils/notification";
import { updateTaskByIdAsync } from "api/scheduler/lib/task";
import { ScriptButton, Task, TaskParameter } from "api/scheduler/types";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineCloseCircle } from 'react-icons/ai'
import update from 'immutability-helper'
import { setModalState } from "redux/AppNavigationState";
import { ModalType } from "redux/AppNavigationState/types";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { t, Trans } from "@lingui/macro";

interface FormScriptButtonData extends ScriptButton {
    path: string
}
function TaskEditModal() {
    const dispatch = useAppDispatch();
    const task = useAppSelector((state) => state.appNavigationState.modalRender.data);
    const [localTask, setLocalTask] = useState<Task>(task);

    const { handleSubmit } = useForm<FormScriptButtonData>();

    const onCloseModal = () => {
        dispatch(setModalState(ModalType.Closed))
    }

    const handleOnSubmit = async () => {
        if (task.id === undefined) return;
        if (localTask.id === undefined) return;

        await updateTaskByIdAsync(localTask.id, { body: localTask });

        notifyLoading({
            message: t`Salvando Edição`,
            timer: 3000,
            action: onCloseModal
        })
    }

    const handleOnNameChange = async (newName: string) => {
        setLocalTask(
            update(localTask, {
                $apply: (task: Task) => {
                    return {
                        ...task,
                        name: newName
                    }
                }
            })
        )
    }

    const handleOnDescriptionChange = async (newDescription: string) => {
        setLocalTask(
            update(localTask, {
                $apply: (task: Task) => {
                    return {
                        ...task,
                        description: newDescription
                    }
                }
            })
        )
    }

    const handleOnParameterChange = async (index: number, newValue: string) => {
        setLocalTask(
            update(localTask, {
                taskParameters: {
                    [index]: {
                        $apply: (taskParameter: TaskParameter) => {
                            return {
                                ...taskParameter,
                                value: newValue
                            }
                        }
                    }
                }
            })
        )
    }

    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative w-full my-6 mx-auto max-w-md">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                            <h3 className="text-3xl font-semibold text-gray-600">
                                <Trans>Editar Task</Trans>
                            </h3>
                            <AiOutlineCloseCircle
                                className="ml-auto self-center text-black float-right w-8 h-8"
                                onClick={onCloseModal}
                            >
                            </AiOutlineCloseCircle>
                        </div>

                        {/*body*/}
                        <div className="relative p-6 flex-auto">
                            <form id='createTaskForm' className='flex-row w-full' onSubmit={handleSubmit(handleOnSubmit)}>

                                <div>
                                    <h5 className='text-gray-600 my-2'>
                                        <Trans>Nome</Trans>:
                                    </h5>

                                    <input
                                        className='flex w-full border text-black appearance rounded-lg'
                                        maxLength={30}
                                        type='text'
                                        name='name'
                                        id='name'
                                        value={localTask.name}
                                        placeholder='Execute Stored Procedure'
                                        onChange={event => handleOnNameChange(event.target.value)}
                                    />

                                    <h5 className='text-gray-600 my-2'>
                                        <Trans>Descrição</Trans>:
                                    </h5>
                                    <input
                                        className='flex w-full border text-black appearance rounded-lg'
                                        maxLength={30}
                                        type='text'
                                        name='description'
                                        id='description'
                                        value={localTask.description}
                                        placeholder='Executes a stored procedure in a database'
                                        onChange={event => handleOnDescriptionChange(event.target.value)}
                                    />
                                </div>
                                {
                                    localTask.taskParameters?.map((taskParameter, index) => (
                                        <div key={index}>
                                            <h5 className='text-gray-600 my-2'>
                                                {localTask.taskDescription.name} - [{localTask.taskDescription.type.toUpperCase()}]
                                            </h5>
                                            <input
                                                className='flex w-full border text-black appearance rounded-lg'
                                                maxLength={30}
                                                type='text'
                                                name={`parameter-${localTask.taskDescription.parameterDescriptions[index].name}`}
                                                id={`parameter-${localTask.taskDescription.parameterDescriptions[index].name}-${index}`}
                                                placeholder={localTask.taskDescription.parameterDescriptions[index].description}
                                                value={taskParameter.value}
                                                onChange={(event) => handleOnParameterChange(index, event?.target.value)}
                                            />
                                        </div>
                                    ))
                                }

                                {/*footer*/}
                                <div className="flex items-center justify-evenly mt-3 pt-3 border-t border-solid border-blueGray-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                                        onClick={onCloseModal}>
                                        <Trans>Fechar</Trans>
                                    </button>
                                    <button
                                        className='w-20 h-10 font-semibold text-lg rounded-full text-white items-center justify-center bg-yellow-400 focus:outline-none'>
                                        <Trans>Salvar</Trans>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}

export default TaskEditModal
