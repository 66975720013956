import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionType, AsyncStatus } from 'redux/types';
import { initialState } from './initialState';
import { RootState } from 'redux/store';
import { ProgressMessage } from 'redux/Connector/types';
import { IsNullOrEmpty } from 'utils/extensions';
import { OutboundDirectMessage } from 'redux/SignalRConnector/Outbound/types';
import update from 'immutability-helper';


export { setIsHidden } from 'api/localStorage/NavigationBar'

export const appNotificationSlice = createSlice({
    name: ActionType.AppNavigation,
    initialState,
    reducers: {
        loadAllDirectMessages: (state, action: PayloadAction<OutboundDirectMessage[]>) => {
            state.messages = action.payload;
        },
        addNewDirectMessage: (state, action: PayloadAction<OutboundDirectMessage>) => {
            state.messages = update(state.messages, { $push: [action.payload] })
        }
    },
    extraReducers: (builder) => {
    }
})

export const {
    addNewDirectMessage,
    loadAllDirectMessages
} = appNotificationSlice.actions;