import React, { forwardRef, useEffect } from 'react'
const TableEditionDataCointainer = forwardRef((props: any, ref: any) => {
    const containerRef = React.useRef<any>()
    function handleScroll({currentTarget: { scrollTop, scrollLeft }}) {
        props.setScroll({top: scrollTop, left: scrollLeft})
    }
    useEffect(() => {
        props.setHeight(containerRef.current?.clientHeight)
    }, [containerRef, props])
    return (
        <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "calc(100% - 55px)" }} ref={ref} onContextMenu={props.handleContextMenu}>
            <div style={{overflow: "auto", height: "100%", width: "100%"}} ref={containerRef} onScroll={handleScroll}>
                {props.children}
            </div>
        </div>
    )
})
export default TableEditionDataCointainer;