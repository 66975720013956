import Tippy from "@tippyjs/react";

import { ReactNode } from "react";

interface ToolTipProps {
  tooltipText: string;
  color: string;
  children: ReactNode
}

export function ToolTipComponentReactNodeChildren({
  tooltipText,
  color,
  children
}: ToolTipProps) {
  return (
    <div className="flex gap-2 justify-between items-center ">
      <Tippy
        delay={[200, 50]}
        animation="fade"
        offset={[10, 10]}
        content={
          <span
            className="bg-blue-200 border border-gray-600 rounded-md font-semibold text-xs p-1 w-fit "
            style={{ backgroundColor: color }}
          >
            {tooltipText}
          </span>
        }
      >
        <div>
          {children}
        </div>
      </Tippy>

    </div>
  );
}
