import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createComplexPeriodDataMonthly, createComplexPeriodDataWeekly, createPropertyDictionary, createPropertyRangeDictionary, } from "redux/helpers";
import { ActionType } from "redux/types";
import { Dictionary } from "utils/types";
import { loadDemandDataRangesAsync, loadItemAttributesBundleAsync, loadCapacitiesByScenarioIdAsync, loadScenariosAsync, loadResourceGroupDetailsAsync, loadItemsAsync, loadPlanningGridsByScenarioIdAsync, loadPeriodsByScenarioIdAsync, loadPlanningGridAllocationsDetailsByScenarioIdAsync, loadPlanningGridResourcesByScenarioIdAsync, loadUserDataFlatAsync, loadResourcesDetailsAsync, loadCompleteStructureAsync, saveAsScenarioAsync, loadItemOperationAsync, } from ".";
import { createResourcesSelection, calculateMpsStepByStep, calculateByInitialStockStatusType, applyMPSRules, applyMultipleReorderRuleOnly, calculateStockStatus, createOccupationChartData, transferChartData, calculateMpsByPartialProcessTime, getResourceInfoById, createAttributesFilter, splitChartLabel, applyOpacity, resetSearchCriteriaData, updateAllItemIdsByStockStatus, calculateMinimunStockByItemId, calculateTargetStockByItemId, calculatePercentageTargetStockByItemId, calculatePercentageMinimunStockByItemId, updateAllItemIdsByStockStatusByPeriod, createCompleteStructureNodes, helperHighlightMaterialFlowResourcesClearAll, localApplyDownMultipleReorder, executeIfExists, localApplyUpMultipleReorder, createResourcesSelectionWithProcessTime, } from "./helpers";
import { initialState } from "./initialState";
import { OccupationChartScenario, OccupationSelectedElementData, ResourceAggregator, AppScenarioState, ForceRedrawType, } from "./types";
import { createDictionaryRange, createMinifiedPeriods, createDictionary, } from "../helpers";
import { applyCriteriaByFilterCriteria } from "./FilterCriteria";
import { HighlightedStatusType, StockStatusType } from "api/data/enums";
import { CapacityData, GlobalPlanningGridTargetType, ItemData, PeriodData, PlanningGridData, PlanningGridResourceData, ResourceData, ResourcesProcessTimeData, UserDataFlat, } from "./dataTypes";
import { AttributeList, CompleteStructure, FixedFilterData, PlanningGridAllocationDetails, ResourceGroupDetails, } from "api/data/types";
import { notifyClientError } from "api/errorHandling/helper";
import { MultipleEditMode } from "pages/PlanningPage/components/PlanningGridPanel/components/MultipleEditPanel";
import { handleLoadItemAttributeBundleModule, handleSelectItemAttributeIdModule, } from "./_modules";
import { FilterCriteria, SearchList } from "./FilterCriteria/types";
import { FilterCriteriaFilters, GetFixedFilterCriteriaByFilterCriteria } from "pages/PlanningPage/components/PlanningGridPanel/utils/FixedCriteriaFilters";
import { TreeNode } from "react-dropdown-tree-select";
import { resourceFilterCriteria } from "./ResourceFilterCriteria/types";
import { applyResourceCriteriaByFilterCriteria } from "./ResourceFilterCriteria";
import { applyOccupationConstraintByPeriodIndex } from "./FiniteCapacity";

const createOccupationChartDataBySelectedResources = (state: AppScenarioState) => {
    const selectedResources = state.selectedResources;
    for (let i = 0; i < selectedResources.length; i++) {
        const resource = selectedResources[i];
        state.chartDataByResourceGroup[resource.resourceGroupName][resource.resourceName] = createOccupationChartData(
            state.colorByProductId,
            state.periods,
            state.planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId,
            state.capacityByPeriodIdByResourceId,
            resource,
            state.minifiedPeriods,
            state.filterData.itemsIdsByAttributeId,
            state.itemById
        );
    }
};

export const appScenarioSlice = createSlice({
    name: ActionType.AppScenarioState,
    initialState,
    reducers: {
        //TODO modifica somente os dados naa chart e nao no redux
        finishOccupationCapacityDrag: (state, action: PayloadAction<{ newDatasetData: number[]; datasetIndex: number; resourceGroupName: string; resourceName: string; }>) => {
            let { newDatasetData, datasetIndex, resourceGroupName, resourceName } = action.payload;

            let { chartDataByResourceGroup } = state;
            let chartData = chartDataByResourceGroup[resourceGroupName][resourceName];
            let capacityDataset = chartData?.datasets[datasetIndex];
            if (!capacityDataset) return;
            capacityDataset.data = newDatasetData;
        },
        resetPlanningPageState: (state) => {
            state.chartDataByResourceGroup = state.resetState.chartDataByResourceGroup;
            state.chartDataByResourceGroup = state.resetState.chartDataByResourceGroup;
            state.planningGridsDataByItemId = state.resetState.planningGridsByItemId;
            state.planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId = state.resetState.planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId;
            state.selectedResources = state.resetState.selectedResources;
            createOccupationChartDataBySelectedResources(state);
        },
        applyOccupationConstraintAnimation: (state, action: PayloadAction<number>) => {
            applyOccupationConstraintByPeriodIndex(state, action.payload);
        },
        applyOccupationConstraint: (state) => {
            for (let periodIndex = 0; periodIndex < state.periods.length; periodIndex++) {
                applyOccupationConstraintByPeriodIndex(state, periodIndex);
            }
        },
        applyCriterias: (state) => {
            state.filterData.orderedItemIds = applyCriteriaByFilterCriteria(state);
            state.selectedItemIds = state.filterData.orderedItemIds;
        },
        applyResourceCriterias: (state) => {
            state.filterResourceData.orderedResourcesIds = applyResourceCriteriaByFilterCriteria(state).resourcesIdsToSelect
            state.filterResourceData.resourcesFiltered = applyResourceCriteriaByFilterCriteria(state).resourcesFiltered

        },
        planningFilterByResources: (state, action: PayloadAction<string[]>) => {
            if (action.payload.length === 0) {
                delete state.filterData.searchCriteriasData.ResourceGroup;
                return;
            }
            state.filterData.searchCriteriasData.ResourceGroup = action.payload;
        },
        clearFilter: (state) => {
            resetSearchCriteriaData(state.filterData.searchCriteriasData);
            helperHighlightMaterialFlowResourcesClearAll(state);
        },
        filterBy: (state, action: PayloadAction<{ filterCriteria: FilterCriteria; searchList: SearchList<any>[]; }>) => {
            const { searchList, filterCriteria } = action.payload;
            // const searchCriteriasData = state.filterData.searchCriteriasData;
            resetSearchCriteriaData(state.filterData.searchCriteriasData);
            // if (searchList.length === 0 || searchList[0].id === "All") {
            //     if (searchCriteriasData[filterCriteria])
            //         delete (searchCriteriasData[filterCriteria])
            // }
            state.filterData.searchCriteriasData[filterCriteria] = searchList;
        },
        resourceFilterBy: (state, action: PayloadAction<{ resourceFilterCriteria: resourceFilterCriteria }>) => {
            const { resourceFilterCriteria } = action.payload
            state.filterResourceData.resourceSearchCriteriasData[resourceFilterCriteria] = resourceFilterCriteria
        },
        resourceFilterByDelete: (state, action: PayloadAction<{ resourceFilterCriteria: resourceFilterCriteria }>) => {
            const { resourceFilterCriteria } = action.payload

            const resourceSearchCriteriasData = state.filterResourceData.resourceSearchCriteriasData

            delete (resourceSearchCriteriasData[resourceFilterCriteria])

        },
        toggleIsSetupHidden: (state) => {
            state.occupationPagePreferences.isSetupHidden =
                !state.occupationPagePreferences.isSetupHidden;
        },
        toggleIsFiniteCapacity: (state) => {
            state.occupationPagePreferences.isFiniteCapacity =
                !state.occupationPagePreferences.isFiniteCapacity;

        },
        toggleHasProcessTime: (state) => {
            state.occupationPagePreferences.hasProcessTime =
                !state.occupationPagePreferences.hasProcessTime;
        },
        toggleIsColorByProduct: (state) => {
            const isColorByProduct = !state.occupationPagePreferences.isColorByProduct;
            state.occupationPagePreferences.isColorByProduct = isColorByProduct;

            for (let resourceGroupChartData of Object.values(state.chartDataByResourceGroup)) {
                for (let resourceChartData of Object.values(resourceGroupChartData)) {
                    let resourceDatasets = resourceChartData?.datasets;
                    if (resourceDatasets) {
                        for (let i = 0; i < resourceDatasets.length; i++) {
                            let dataset = resourceDatasets[i];
                            if (dataset.order % 2 === 0) {
                                const { itemId } = splitChartLabel(dataset.label);
                                if (state.occupationPagePreferences.isColorByProduct) {
                                    dataset.backgroundColor = [state.colorByProductId[itemId]];
                                    dataset.borderWidth = 0;
                                } else {
                                    dataset.borderWidth = 2;
                                    const itemAttributeIdToColor = state.colorByAttributeId[handleSelectItemAttributeIdModule(state.itemById[itemId])];
                                    dataset.backgroundColor = [applyOpacity(itemAttributeIdToColor, 0),];
                                }
                            }
                        }
                    }
                }
            }
        },
        updateOccupationPageByProductId: () => {
            // const { productId } = action.payload;
            notifyClientError({
                title: "Method not implemented.",
                details: "updateOccupationPageByItem not implemented.",
                type: "NotImplementedError",
            });
            return;
            // updateOccupationPageByItem(
            //     state.periods.length,
            //     productId,
            //     state.itemById[productId].itemAttribute1Id ?? 0,
            //     state.mpsPerUnitOfTime[productId],
            //     state.setupTimeByItemId[productId],
            //     state.selectedDatasetId,
            //     getPossibleResources(state.planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId[productId], state.resourceGroupById),
            //     state.planningGridsDataByItemId[productId],
            //     state.planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId[productId],
            //     state.periods,
            //     state.chartDataByResourceGroup
            // )
        },
        clearSelectedOccupationData: (state) => {
            state.selectedChartElement = undefined;
        },
        setSelectedOccupationData: (state, action: PayloadAction<OccupationSelectedElementData>) => {
            state.selectedChartElement = action.payload;
        },
        transferChartProducts: (state, action: PayloadAction<{ inputChartId: number; inputDatasetsIndex: number; inputResourceId: number; inputChartPeriodIndex: number; outputChartId: number; outputResourceId: number; outputChartPeriodIndex: number; totalProcessTime: number; totalSetupTime: number; outputPlanningGroupName: string; outputPlanningResourceName: string; partialProcessTime?: number; }>) => {
            const {
                inputChartPeriodIndex,
                inputDatasetsIndex,
                inputResourceId,
                inputChartId,
                outputChartPeriodIndex,
                outputResourceId,
                outputChartId,
                partialProcessTime,
                totalProcessTime,
                totalSetupTime,
                outputPlanningGroupName,
                outputPlanningResourceName,
            } = action.payload;

            if (inputChartId === outputChartId && inputChartPeriodIndex === outputChartPeriodIndex) {
                return;
            }
            const { planningGroupName: inputPlanningGroupName, planningResource: inputPlanningResource, } = getResourceInfoById(inputResourceId, state.resourceGroupById);

            if (!inputPlanningGroupName) return;
            const inputDatasets = state.chartDataByResourceGroup[inputPlanningGroupName][inputPlanningResource?.name]?.datasets;
            const outputDatasets = state.chartDataByResourceGroup[outputPlanningGroupName][outputPlanningResourceName]?.datasets;

            if (!outputDatasets || !inputDatasets) return;

            const isSameChart = inputChartId === outputChartId;

            transferChartData(
                isSameChart,
                state.periods.length,
                totalProcessTime,
                totalSetupTime,
                inputDatasets,
                inputChartPeriodIndex,
                inputDatasetsIndex,
                outputChartPeriodIndex,
                outputDatasets,
                outputResourceId,
                partialProcessTime
            );
        },
        clearPartialValue: (state) => {
            state.partialValue = undefined;
        }, transferResourceAllocation: (state, action: PayloadAction<{ inputOperationId: number, inputPlanningResourceId: number; inputPeriodId: number; inputItemId: number; outputPlanningResourceId: number; outputPeriodId: number; outputChartDatasetsIndex: number; partialValue?: number; }>) => {
            const { inputOperationId, inputPlanningResourceId, inputPeriodId, inputItemId, outputPlanningResourceId, outputPeriodId, outputChartDatasetsIndex, partialValue, } = action.payload;

            let inputPlanningGridAllocatedByPeriodId = state.planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId[inputItemId][inputPlanningResourceId][inputOperationId];
            let inputPlanningGridAllocated = inputPlanningGridAllocatedByPeriodId[inputPeriodId];
            const { periodId: inputPlanningGridAllocatedPeriodId } = state.itemIdPeriodIdByPlanningGridId[inputPlanningGridAllocated.planningGridId];

            if (!state.planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId[inputItemId]) {
                state.planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId[inputItemId] = {};
            }
            if (!state.planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId[inputItemId][outputPlanningResourceId]) {
                state.planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId[inputItemId][outputPlanningResourceId] = {};
            }
            let outputPlanningGridAllocatedDetailsByPeriodId = state.planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId[inputItemId][outputPlanningResourceId][inputOperationId];
            let outputPlanningGridAllocatedDetails = outputPlanningGridAllocatedDetailsByPeriodId[outputPeriodId];
            //TODO quando n~ao se encontra um planningGridAllocated tewm que criar um novo!

            //If there is no outputResourceAllocated we should create a new one with the outputPeriodId
            if (inputPlanningGridAllocated) {
                if (partialValue) {
                    let restOfInputTotalValue = inputPlanningGridAllocated.totalProcessTime - partialValue;
                    if (outputPlanningGridAllocatedDetails) {
                        const { periodId: outputPlanningGridAllocatedPeriodId } = state.itemIdPeriodIdByPlanningGridId[outputPlanningGridAllocatedDetails.planningGridId];
                        outputPlanningGridAllocatedDetails.totalProcessTime += partialValue;
                        outputPlanningGridAllocatedDetails.totalSetupTime = inputPlanningGridAllocated.totalSetupTime;
                        outputPlanningGridAllocatedDetails.totalAllocatedTime += partialValue;

                        outputPlanningGridAllocatedDetailsByPeriodId[outputPlanningGridAllocatedPeriodId] = outputPlanningGridAllocatedDetails;
                    } else {
                        //TODO great-fix
                        outputPlanningGridAllocatedDetailsByPeriodId[outputPeriodId] = {
                            ...inputPlanningGridAllocated,
                            totalAllocatedTime: partialValue + inputPlanningGridAllocated.totalSetupTime,
                            periodId: outputPeriodId,
                            // planningResourceId: outputPlanningResourceId,
                            totalProcessTime: partialValue,
                        };
                    }
                    if (restOfInputTotalValue !== 0) {
                        inputPlanningGridAllocatedByPeriodId[inputPlanningGridAllocatedPeriodId].totalProcessTime = restOfInputTotalValue;
                        inputPlanningGridAllocatedByPeriodId[inputPlanningGridAllocatedPeriodId].totalAllocatedTime = restOfInputTotalValue + inputPlanningGridAllocated.totalSetupTime;
                    } else {
                        delete inputPlanningGridAllocatedByPeriodId[inputPlanningGridAllocatedPeriodId];
                    }
                } else {
                    if (outputPlanningGridAllocatedDetails) {
                        const { periodId: outputPlanningGridAllocatedPeriodId } = state.itemIdPeriodIdByPlanningGridId[outputPlanningGridAllocatedDetails.planningGridId];
                        outputPlanningGridAllocatedDetails.totalProcessTime += inputPlanningGridAllocated.totalProcessTime;
                        outputPlanningGridAllocatedDetails.totalSetupTime = inputPlanningGridAllocated.totalSetupTime;
                        outputPlanningGridAllocatedDetails.totalAllocatedTime += inputPlanningGridAllocated.totalProcessTime;

                        outputPlanningGridAllocatedDetailsByPeriodId[outputPlanningGridAllocatedPeriodId] = outputPlanningGridAllocatedDetails;
                    } else {
                        //TODO great-fix
                        outputPlanningGridAllocatedDetailsByPeriodId[outputPeriodId] = {
                            ...inputPlanningGridAllocated,
                            // planningResourceId: outputPlanningResourceId,
                            periodId: outputPeriodId,
                        };
                    }
                    if (outputChartDatasetsIndex !== 0) {
                        delete inputPlanningGridAllocatedByPeriodId[inputPlanningGridAllocatedPeriodId];
                    }
                }

                return;
            }
            // console.error('transferResourceAllocationPartition, params:', resourceAllocationTransferData)
        },
        setChartDataByResource: (state, action: PayloadAction<ResourceAggregator>) => {
            const addedResource = action.payload;
            let chartDataByResourceGroupDict = state.chartDataByResourceGroup[addedResource.resourceGroupName][addedResource.resourceName];
            if (!chartDataByResourceGroupDict) {
                state.chartDataByResourceGroup[addedResource.resourceGroupName][addedResource.resourceName] =
                    createOccupationChartData(
                        state.colorByProductId,
                        state.periods,
                        state.planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId,
                        state.capacityByPeriodIdByResourceId,
                        addedResource,
                        state.minifiedPeriods,
                        state.filterData.itemsIdsByAttributeId,
                        state.itemById
                    );
            }
        },
        clearSelectedResources: (state) => {
            state.selectedResources = [];
        },
        setSelectedResources: (state, action: PayloadAction<ResourceAggregator[]>) => {
            state.selectedResources = action.payload;
            state.resetState.selectedResources = action.payload;
        },
        //AppOccupationState
        updatePlanningPageByUnitOfTime: (state, action: PayloadAction<{ itemId: number; fromPeriodId: number; toPeriodId: number; processTime: number; partialProcessTime?: number; }>) => {
            const {
                itemId,
                fromPeriodId,
                toPeriodId,
                processTime,
                partialProcessTime,
            } = action.payload;
            const fromPeriodIndex = state.periodsIdToIndex[fromPeriodId];
            const toPeriodIndex = state.periodsIdToIndex[toPeriodId];
            const startingIndex = Math.min(fromPeriodIndex, toPeriodIndex);
            let planningGrids = state.planningGridsDataByItemId[itemId];

            const fromPeriodMPS = planningGrids[fromPeriodIndex].mps
            if (fromPeriodMPS === undefined) {
                console.error(`on updatePlanningPageByUnitOfTime, fromPeriodMPS is null`);
                throw new Error(`on updatePlanningPageByUnitOfTime, fromPeriodMPS is null`);
            }
            let { fromMps, toMps } = calculateMpsByPartialProcessTime(processTime, partialProcessTime ?? processTime, fromPeriodMPS);
            planningGrids[fromPeriodIndex].mps = fromMps;
            planningGrids[fromPeriodIndex].isDirty = true;

            let toPeriodMPS = planningGrids[toPeriodIndex].mps
            if (toPeriodMPS === undefined) {
                console.error(`on updatePlanningPageByUnitOfTime, toPeriodMPS is null`);
                throw new Error(`on updatePlanningPageByUnitOfTime, toPeriodMPS is null`);
            } toPeriodMPS += toMps;
            planningGrids[toPeriodIndex].mps = toPeriodMPS;

            planningGrids[toPeriodIndex].isDirty = true;
            calculateMpsStepByStep(
                state,
                itemId,
                startingIndex,
                false,
                state.isWeekly
            );
        },
        applyMultipleEditOnSelectedItems: (state, action: PayloadAction<{
            targetStockMultipleMode: MultipleEditMode;
            minStockMultipleMode: MultipleEditMode;
            targetStock: undefined | number;
            minStock: undefined | number;
        }>) => {
            let { minStockMultipleMode, targetStockMultipleMode, minStock, targetStock, } = action.payload;
            let selectedItemIds = state.selectedItemIds;

            for (let i = 0; i < selectedItemIds.length; i++) {
                const itemId = selectedItemIds[i];
                if (targetStock) {
                    if (targetStockMultipleMode === MultipleEditMode.Percentage) {
                        calculatePercentageTargetStockByItemId(
                            targetStock,
                            state.planningGridGlobalValuesByItemId[itemId],
                            state.isWeekly,
                            state.planningGridsDataByItemId[itemId]
                        );
                    } else {
                        const globalTargetStockType = targetStockMultipleMode === MultipleEditMode.Days
                            ? GlobalPlanningGridTargetType.DailyGlobalTargetValue
                            : GlobalPlanningGridTargetType.UnitaryGlobalTargetValue;
                        calculateTargetStockByItemId(
                            targetStock,
                            state.planningGridGlobalValuesByItemId[itemId],
                            state.isWeekly,
                            state.planningGridsDataByItemId[itemId],
                            globalTargetStockType
                        );
                    }
                }
                if (minStock) {
                    if (minStockMultipleMode === MultipleEditMode.Percentage) {
                        calculatePercentageMinimunStockByItemId(
                            minStock,
                            state.planningGridGlobalValuesByItemId[itemId],
                            state.isWeekly,
                            state.planningGridsDataByItemId[itemId]
                        );
                    } else {
                        const globalMinStockType =
                            minStockMultipleMode === MultipleEditMode.Days
                                ? GlobalPlanningGridTargetType.DailyGlobalTargetValue
                                : GlobalPlanningGridTargetType.UnitaryGlobalTargetValue;
                        calculateMinimunStockByItemId(
                            minStock,
                            state.planningGridGlobalValuesByItemId[itemId],
                            state.isWeekly,
                            state.planningGridsDataByItemId[itemId],
                            globalMinStockType
                        );
                    }
                }
                calculateMpsStepByStep(state, itemId, 0, true, state.isWeekly);
            }
        },
        setIsMinStockByDaysByItemId: (state, action: PayloadAction<{ itemId: number; globalMinStockType: GlobalPlanningGridTargetType; }>) => {
            let { itemId, globalMinStockType: isMinStockByDay } = action.payload;
            //TODO como voltar a utilizar Valores individuais?
            state.planningGridGlobalValuesByItemId[itemId].globalMinStockType = isMinStockByDay;
        },
        setIsTargetStockByDaysByItemId: (state, action: PayloadAction<{ itemId: number; globalTargetStockType: GlobalPlanningGridTargetType; }>) => {
            let { itemId, globalTargetStockType } = action.payload;
            //TODO como voltar a utilizar Valores individuais?
            state.planningGridGlobalValuesByItemId[itemId].globalTargetStockType = globalTargetStockType;
        },
        setMininimunStockByItemId: (state, action: PayloadAction<{ itemId: number; newMinimunStock: number }>) => {
            let { itemId, newMinimunStock } = action.payload;
            let { globalMinStockType } = state.planningGridGlobalValuesByItemId[itemId];
            calculateMinimunStockByItemId(
                newMinimunStock,
                state.planningGridGlobalValuesByItemId[itemId],
                state.isWeekly,
                state.planningGridsDataByItemId[itemId],
                globalMinStockType
            );
        },
        setTargetStockByItemId: (state, action: PayloadAction<{ itemId: number; newTargetStock: number }>) => {
            let { itemId, newTargetStock } = action.payload;
            let { globalTargetStockType } = state.planningGridGlobalValuesByItemId[itemId];
            calculateTargetStockByItemId(
                newTargetStock,
                state.planningGridGlobalValuesByItemId[itemId],
                state.isWeekly,
                state.planningGridsDataByItemId[itemId],
                globalTargetStockType
            );
        },
        setSelectedScenarioId: (state, action: PayloadAction<number>) => {
            state.selectedScenarioId = action.payload;
        },
        removeDatasetById: (state, action: PayloadAction<number>) => {
            state.scenarios = state.scenarios?.filter((x) => x.id !== action.payload);
        },
        highlightMaterialFlowResources: (state, action: PayloadAction<{ itemId: number; productPeriodIndex: number }>) => {
            const { itemId, productPeriodIndex } = action.payload;
            const planningGridsByItemId = state.planningGridsDataByItemId[itemId];
            const planningGridsByItemIdByPeriod = planningGridsByItemId[productPeriodIndex];

            const completeStructuresByItemMestre = state.completeStructuresByItemMaster[itemId];
            // const completeStructuresNodeByItemId = state.completeStructuresNodeByItemId[itemId]
            const completeStructureOrderDataByItemMestre = state.completeStructureOrderDataByItemMaster[itemId];

            const selectedPeriodMps = planningGridsByItemIdByPeriod.mps;
            if (selectedPeriodMps === 0) return;

            for (let i = 0; i < completeStructureOrderDataByItemMestre.data.length; i++) {
                const { itemId: materialItemId } = completeStructureOrderDataByItemMestre.data[i];
                const localCompleteStructure = completeStructuresByItemMestre.find((x) => x.parentId === itemId);
                if (selectedPeriodMps === undefined) {
                    console.error(`on highlightMaterialFlowResources, selectedPeriodMps is null`);
                    throw new Error(`on highlightMaterialFlowResources, selectedPeriodMps is null`);
                } let restOfSelectedMps = selectedPeriodMps;

                if (localCompleteStructure) {
                    const materialPlanningGrids = state.planningGridsDataByItemId[materialItemId];
                    const materialPlanningGridByPeriod = materialPlanningGrids[productPeriodIndex];

                    const proportion = 1 / localCompleteStructure.masterProportionalQuantity;
                    if (materialPlanningGridByPeriod.mps === undefined) {
                        console.error(`on highlightMaterialFlowResources, materialPlanningGridByPeriod.mps is null`);
                        throw new Error(`on highlightMaterialFlowResources, materialPlanningGridByPeriod.mps is null`);
                    }
                    const producedQuantity = materialPlanningGridByPeriod.mps * proportion;
                    restOfSelectedMps -= producedQuantity;
                    //1000
                    if (materialPlanningGridByPeriod.mps !== 0)
                        materialPlanningGridByPeriod.isMpsHighlighted = true;

                    if (restOfSelectedMps > 0) {
                        materialPlanningGridByPeriod.mpsHighlightedStatusType = HighlightedStatusType.Shortage;

                        for (let k = productPeriodIndex - 1; k >= 0; k--) {
                            if (k === -1) {
                                materialPlanningGrids[0].isOpeningStockHighlighted = true;
                                if (materialPlanningGrids[0].openingStock === undefined) {
                                    console.error(`on highlightMaterialFlowResources, materialPlanningGrids[0].openingStock is null`);
                                    throw new Error(`on highlightMaterialFlowResources, materialPlanningGrids[0].openingStock is null`);
                                }
                                if (restOfSelectedMps - materialPlanningGrids[0].openingStock > 0)
                                    materialPlanningGrids[0].openingStockHighlightedStatusType = HighlightedStatusType.Shortage;
                                break;
                            }
                            const localMaterialPlanningGrid = materialPlanningGrids[k];
                            if (localMaterialPlanningGrid.mps === 0) continue;

                            if (localMaterialPlanningGrid.mps === undefined) {
                                console.error(`on highlightMaterialFlowResources, localMaterialPlanningGrid.mps is null`);
                                throw new Error(`on highlightMaterialFlowResources, localMaterialPlanningGrid.mps is null`);
                            } restOfSelectedMps -= localMaterialPlanningGrid.mps;
                            localMaterialPlanningGrid.isMpsHighlighted = true;
                            if (restOfSelectedMps > 0)
                                localMaterialPlanningGrid.mpsHighlightedStatusType = HighlightedStatusType.Shortage;
                            else
                                localMaterialPlanningGrid.mpsHighlightedStatusType = HighlightedStatusType.Normal;
                            if (restOfSelectedMps <= 0) {
                                localMaterialPlanningGrid.mpsHighlightedStatusType = HighlightedStatusType.Normal;
                                break;
                            }
                        }
                    } else {
                        materialPlanningGridByPeriod.mpsHighlightedStatusType = HighlightedStatusType.Normal;
                    }
                }
            }
        },
        highlightMaterialFlowResourcesClear: (state, action: PayloadAction<{ itemId: number; productPeriodIndex: number }>) => {
            const { itemId, productPeriodIndex } = action.payload;
            const completeStructuresByItemMestre = state.completeStructuresByItemMaster[itemId];
            const completeStructureOrderDataByItemMestre = state.completeStructureOrderDataByItemMaster[itemId];
            for (let i = 0; i < completeStructureOrderDataByItemMestre.data.length; i++) {
                const { itemId: materialItemId } = completeStructureOrderDataByItemMestre.data[i];
                const localCompleteStructure = completeStructuresByItemMestre.find((x) => x.parentId === itemId);
                if (localCompleteStructure) {
                    const materialPlanningGrids = state.planningGridsDataByItemId[materialItemId];
                    for (let k = productPeriodIndex; k > -1; k--) {
                        const materialPlanningGridByPeriod = materialPlanningGrids[k];
                        if (materialPlanningGridByPeriod !== undefined) {
                            materialPlanningGridByPeriod.isMpsHighlighted = false;
                            materialPlanningGridByPeriod.mpsHighlightedStatusType = HighlightedStatusType.Normal;
                            materialPlanningGridByPeriod.openingStockHighlightedStatusType = HighlightedStatusType.Normal;
                        }
                    }
                    // const materialPlanningGridByPeriod = materialPlanningGrids[productPeriodIndex]
                }
            }
        },
        highlightMaterialFlowResourcesClearAll: (state) => {
            helperHighlightMaterialFlowResourcesClearAll(state);
        },
        applyUpMultipleReorder: (state, action: PayloadAction<{ itemId: number; itemPeriodIndex: number }>) => {
            localApplyUpMultipleReorder(state, action.payload);
        },
        applyDownMultipleReorder: (state, action: PayloadAction<{ itemId: number; itemPeriodIndex: number }>) => {
            localApplyDownMultipleReorder(state, action.payload);
        },
        lockPlanningGridFromStart: (state, action: PayloadAction<{ itemId: number; productPeriodIndex: number }>) => {
            const { itemId: productId, productPeriodIndex } = action.payload;
            for (let index = 0; index < productPeriodIndex + 1; index++) {
                state.planningGridsDataByItemId[productId][index].locked = true;
                state.planningGridsDataByItemId[productId][index].stockStatus = StockStatusType.Locked;
            }
        },
        unlockPlanningGridFromIndexToEnd: (state, action: PayloadAction<{ itemId: number; productPeriodIndex: number }>) => {
            const { itemId: productId, productPeriodIndex } = action.payload;
            let planningGrids = state.planningGridsDataByItemId[productId];

            for (let index = productPeriodIndex; index < planningGrids.length; index++) {
                planningGrids[index].locked = false;
                const status = calculateByInitialStockStatusType(planningGrids[index]);
                planningGrids[index].stockStatus = status;
            }
        },
        setPlanningGridLock: (state, action: PayloadAction<{ itemId: number; productPeriodIndex: number }>) => {
            const { itemId: productId, productPeriodIndex } = action.payload;
            let planningGrids = state.planningGridsDataByItemId[productId];

            const locked = !planningGrids[productPeriodIndex].locked;
            planningGrids[productPeriodIndex].locked = locked;

            const status = calculateByInitialStockStatusType(planningGrids[productPeriodIndex]);
            planningGrids[productPeriodIndex].stockStatus = status;
        },
        applyStockStatusRulesToAll: (state) => {
            state.planningGridsDataByItemId = calculateStockStatus(
                state.planningGridsDataByItemId
            );
        },
        toggleExpandProductRow: (state) => {
            state.isExpandedProducts = !state.isExpandedProducts;
        },
        toggleExpandedPeriods: (state) => {
            state.isExpandedPeriods = !state.isExpandedPeriods;
        },

        onMpsChangeValue: (state, action: PayloadAction<{ itemId: number; productPeriodIndex: number; newMps: number; }>) => {
            const { itemId, productPeriodIndex, newMps } = action.payload;
            let planningGrid = state.planningGridsDataByItemId[itemId][productPeriodIndex];
            planningGrid.mps = newMps;
            planningGrid.isDirty = true;
            calculateMpsStepByStep(state, itemId, productPeriodIndex, false, state.isWeekly);
            // updateOccupationPageByItem(
            //     state.periods.length,
            //     productId,
            //     //TODO find a way to improve this behavior
            //     state.itemById[productId].itemAttribute1Id ?? 0,
            //     state.mpsPerUnitOfTime[productId],
            //     state.setupTimeByItemId[productId],
            //     state.selectedDatasetId,
            //     getPossibleResources(state.planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId[productId], state.resourceGroupById),
            //     state.planningGridsDataByItemId[productId],
            //     state.planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId[productId],
            //     state.periods,
            //     state.chartDataByResourceGroup,
            //     true
            // )
        },
        recalculateSelectedItemMps: (state) => {
            for (let i = 0; i < state.selectedItemIds.length; i++) {
                const itemId = state.selectedItemIds[i];
                calculateMpsStepByStep(state, itemId, 0, true, state.isWeekly);
                // const possibleResources = getPossibleResources(state.planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId[itemId], state.resourceGroupById)
                // const productSetupTime = state.setupTimeByItemId[itemId]
                // const planningGrids = state.planningGridsDataByItemId[itemId];
                // updateOccupationPageByItem(
                //     state.periods.length,
                //     itemId,
                //     state.itemById[itemId].itemAttribute1Id ?? 0,
                //     state.mpsPerUnitOfTime[itemId],
                //     productSetupTime,
                //     state.selectedDatasetId,
                //     possibleResources,
                //     planningGrids,
                //     state.planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId[itemId],
                //     state.periods,
                //     state.chartDataByResourceGroup,
                // )
            }
        },
        recalculateAllScenarioMpsByProductId: (state, action: PayloadAction<number>) => {
            const itemId = action.payload;
            calculateMpsStepByStep(state, itemId, 0, true, state.isWeekly);
            // updateOccupationPageByItem(
            //     state.periods.length,
            //     productId,
            //     state.itemById[productId].itemAttribute1Id ?? 0,
            //     state.mpsPerUnitOfTime[productId],
            //     state.setupTimeByItemId[productId],
            //     state.selectedDatasetId,
            //     getPossibleResources(state.planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId[productId], state.resourceGroupById),
            //     state.planningGridsDataByItemId[productId],
            //     state.planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId[productId],
            //     state.periods,
            //     state.chartDataByResourceGroup
            // )
        },
        recalculatePeriodsMps: (state, action: PayloadAction<{ itemId: number; productPeriodIndex: number }>) => {
            const { itemId, productPeriodIndex } = action.payload;
            calculateMpsStepByStep(state, itemId, productPeriodIndex, true, state.isWeekly);
            // updateOccupationPageByItem(
            //     state.periods.length,
            //     productId,
            //     //TODO find a way to improve this behavior
            //     state.itemById[productId].itemAttribute1Id ?? 0,
            //     state.mpsPerUnitOfTime[productId],
            //     state.setupTimeByItemId[productId],
            //     state.selectedDatasetId,
            //     getPossibleResources(state.planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId[productId], state.resourceGroupById),
            //     state.planningGridsDataByItemId[productId],
            //     state.planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId[productId],
            //     state.periods,
            //     state.chartDataByResourceGroup
            // )
        },
        setMultipleReorder: (state, action: PayloadAction<{ multipleReorder: number; itemId: number }>) => {
            const { multipleReorder, itemId } = action.payload;
            if (state.planningGridsDataByItemId[itemId]) {
                state.planningGridsDataByItemId[itemId][0].multipleReorder = multipleReorder;
            } else {
                console.error(`method [setReorderMultipleQuantity] not found id ${itemId} on dictionary [planningGridsByItemId]`);
            }
        },
        setMinimunReorder: (state, action: PayloadAction<{ minimumReorder: number; itemId: number }>) => {
            const { minimumReorder, itemId } = action.payload;
            if (state.planningGridsDataByItemId[itemId]) {
                state.planningGridsDataByItemId[itemId][0].minimumReorder = minimumReorder;
            } else {
                console.error(`method [setMinimumReorderQuantity] not found id ${itemId} on dictionary [planningGridsByItemId]`);
            }
        },
        applyMPSRulesOnProductSpecificPlanningGrid: (state, action: PayloadAction<{ itemId: number; planningGridIndex: number }>) => {
            const { itemId, planningGridIndex } = action.payload;

            if (!state.planningGridsDataByItemId[itemId]) {
                throw new Error(`applyMPSRulesOnProductSpecificPlanningGrid, itemId = ${itemId} not found on planningGridsByItemId`);
            }
            const { minimumReorder, multipleReorder } = state.planningGridsDataByItemId[itemId][0];
            const mps = state.planningGridsDataByItemId[itemId][planningGridIndex].mps;
            if (minimumReorder === undefined) {
                console.error(`on applyMPSRulesOnProductSpecificPlanningGrid, minimumReorder is null`);
                throw new Error(`on applyMPSRulesOnProductSpecificPlanningGrid, minimumReorder is null`);
            } if (multipleReorder === undefined) {
                console.error(`on applyMPSRulesOnProductSpecificPlanningGrid, multipleReorder is null`);
                throw new Error(`on applyMPSRulesOnProductSpecificPlanningGrid, multipleReorder is null`);
            } if (mps === undefined) {
                console.error(`on applyMPSRulesOnProductSpecificPlanningGrid, mps is null`);
                throw new Error(`on applyMPSRulesOnProductSpecificPlanningGrid, mps is null`);
            }
            state.planningGridsDataByItemId[itemId][planningGridIndex].mps = applyMPSRules(minimumReorder, multipleReorder, mps);
            state.planningGridsDataByItemId[itemId][planningGridIndex].isDirty = true;
        },
        setSelectedItemIds: (state, action: PayloadAction<number[]>) => {
            state.selectedItemIds = action.payload;
        },
        setItemRowSelectedProducts: (state, action: PayloadAction<{ shiftKey: boolean, ctrlKey: boolean, itemId: number }>) => {
            let { itemId, ctrlKey, shiftKey } = action.payload;
            if (state.selectedItemIds.length === 1 && itemId === state.selectedItemIds[0])
                return;

            const orderedItemIds = state.filterData.orderedItemIds;

            let itemIndexOnSelectedIds = state.selectedItemIds.indexOf(itemId);
            //click comum
            if (!ctrlKey && !shiftKey) {
                state.selectedItemIds = [itemId];
            } else if (ctrlKey && !shiftKey) {
                if (itemIndexOnSelectedIds < 0) {
                    state.selectedItemIds.push(itemId);
                } else {
                    state.selectedItemIds.splice(itemIndexOnSelectedIds, 1);
                }
            } else if (shiftKey && !ctrlKey) {
                let selectedIds = state.selectedItemIds;
                const lastItemId = selectedIds[selectedIds.length - 1];
                const lastItemIndex = orderedItemIds.indexOf(lastItemId);
                const selectedItemIndex = orderedItemIds.indexOf(itemId);

                if (itemId > lastItemId) {
                    for (let i = lastItemIndex + 1; i <= selectedItemIndex; i++) {
                        state.selectedItemIds.push(orderedItemIds[i]);
                    }
                } else if (itemId < lastItemId) {
                    //se o primeiro das selecionadas não forem o inicio?
                    const firstItemId = selectedIds[0];
                    const firstItemIndex = orderedItemIds.indexOf(firstItemId);
                    state.selectedItemIds = [];

                    if (selectedItemIndex < firstItemIndex) {
                        for (let i = selectedItemIndex; i <= lastItemIndex; i++) {
                            state.selectedItemIds.push(orderedItemIds[i]);
                        }
                    } else {
                        for (let i = firstItemIndex; i <= selectedItemIndex; i++) {
                            state.selectedItemIds.push(orderedItemIds[i]);
                        }
                    }
                }
            }
        },
        renameScenario: (state, action: PayloadAction<{ datasetId: number; newName: string }>) => {
            let { datasetId, newName } = action.payload;
            if (state.scenarios && state.scenarios[datasetId]) {
                state.scenarios[datasetId].name = newName;
            }
        },
        resetSelectedItems: (state) => {
            state.selectedItemIds = state.filterData.orderedItemIds;
        },
        resetFilters: (state) => {
            state.filterData.orderedItemIds = state.itemsIdsWithPlanningGrid;
            state.selectedItemIds = state.itemsIdsWithPlanningGrid;
            state.forceRedraw.FilterModule = !state.forceRedraw.FilterModule;
        },
        createFilterAttributes: (state) => {
            state.filterData.itemsIdsByAttributeId = createAttributesFilter(
                state.itemsIdsWithPlanningGrid,
                state.itemById,
                state.itemAttributes
            );
        },
        forceRedraw: (state, action: PayloadAction<ForceRedrawType>) => {
            state.forceRedraw[action.payload] = !state.forceRedraw[action.payload];
        },
        toggleExpandMaterialFlowData: (state, action: PayloadAction<number>) => {
            state.completeStructureOrderDataByItemMaster[action.payload].isExpanded = !state.completeStructureOrderDataByItemMaster[action.payload].isExpanded;
        },
        setInitialAttributeOptions: (state) => {
            const { attributeList } = state
            const attributeListData = attributeList.map((attribute) => {
                return {
                    label: attribute.attributeName,
                    value: attribute.attributeCode,
                    checked: true,
                };
            });
            state.attributeOptions = { options: attributeListData }

        },
        updateAttributeOptions: (state, action: PayloadAction<{ selectedAttribute }>) => {
            const newAttributeOptions = state.attributeOptions.options.map((option, index) => {
                if (option.label === action.payload.selectedAttribute.label) {
                    return {
                        label: option.label,
                        value: option.value,
                        checked: action.payload.selectedAttribute.checked,
                    }
                } else {
                    return {
                        label: option.label,
                        value: option.value,
                        checked: option.checked,
                    }
                }
            })
            state.attributeOptions = { options: newAttributeOptions }
        },
        setSelectedAttributes: (state, action: PayloadAction<{ selectedAttributes }>) => {
            if (action.payload.selectedAttributes.length === 0) {
                state.selectedAttributesToShow = [{
                    attributeCode: 'none',
                    attributeName: 'none'
                }]
                return
            }
            const selectedAttributes = action.payload.selectedAttributes.map((value, index) => {
                if (value.checked === true) {
                    return {
                        attributeCode: value.value,
                        attributeName: value.label,
                    };
                }
            }).filter((c) => c !== undefined);

            state.selectedAttributesToShow = selectedAttributes as AttributeList[]
        },
        initializeFixedCriteriaOptions: (state) => {
            const filterData = FilterCriteriaFilters.map((filter) => {
                return {
                    label: filter.label,
                    value: filter.filterCriteria,
                    filterCriteria: FilterCriteria[filter.filterCriteria],
                    placeholder: filter.placeholder,
                    filterId: filter.filterId,
                    checked: true,
                };
            });
            state.FixedCriteriaFilterOptions = filterData
        },
        updateFixedCriteriaOptions: (state, action: PayloadAction<{ selectedFixedCriteria }>) => {
            const newFixedCriteriaOptions = state.FixedCriteriaFilterOptions.map((filter, index) => {
                if (filter.label === action.payload.selectedFixedCriteria.label) {
                    return {
                        label: filter.label,
                        value: filter.filterCriteria,
                        filterCriteria: FilterCriteria[filter.filterCriteria],
                        placeholder: filter.placeholder,
                        filterId: filter.filterId,
                        checked: action.payload.selectedFixedCriteria.checked,
                    }
                } else {
                    return {
                        label: filter.label,
                        value: filter.filterCriteria,
                        filterCriteria: FilterCriteria[filter.filterCriteria],
                        placeholder: filter.placeholder,
                        filterId: filter.filterId,
                        checked: filter.checked,

                    }
                }
            })
            state.FixedCriteriaFilterOptions = newFixedCriteriaOptions
        },

        setSelectedFilters: (state, action: PayloadAction<
            {
                label: () => string;
                value: string;
                filterCriteria: string;
                placeholder: string;
                filterId: string | number;
                checked: boolean | undefined;
            }[]>) => {
            const selectedNodesData = action.payload;

            if (selectedNodesData.length === 0) {
                const nullValue = [{
                    filterId: 0,
                    filterCriteria: 'none',
                    label: () => 'none',
                    placeholder: () => 'none',
                    checked: undefined
                }]
                state.selectedFiltersToShow = nullValue as FixedFilterData[]
                return
            }

            const selectedValues: FixedFilterData[] = selectedNodesData.map((value, index) => {
                return {
                    filterId: Number(value.filterId),
                    label: value.label,
                    filterCriteria: FilterCriteria[value.filterCriteria],
                    placeholder: GetFixedFilterCriteriaByFilterCriteria(FilterCriteria[value.filterCriteria]).placeholder,
                    checked: value.checked
                }
            }).filter((c) => c !== undefined);


            state.selectedFiltersToShow = selectedValues
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadItemAttributesBundleAsync.fulfilled, (state, action) => {
                handleLoadItemAttributeBundleModule(state, action.payload);
            })
            .addCase(loadResourcesDetailsAsync.fulfilled, (state, action) => {
                const resources: ResourceData[] = action.payload;
                state.resources = resources;
            })
            .addCase(loadResourceGroupDetailsAsync.fulfilled, (state, action) => {
                const resourceGroupDetails: ResourceGroupDetails[] = action.payload;
                //TODO remover o array caso n ao for usado.
                state.resourceGroupDetails = resourceGroupDetails;
                state.resourceGroupById = createDictionary(resourceGroupDetails, (x) => x.id);
                state.resourcesToSelect = createResourcesSelection(resourceGroupDetails);

                //Create chartDataByResourceGroup by adding the ResourceGroupNames
                let chartRefDict: Dictionary<Dictionary<OccupationChartScenario | undefined>> = {};
                for (let index = 0; index < resourceGroupDetails.length; index++) {
                    const planningGroup = resourceGroupDetails[index];
                    if (!chartRefDict[planningGroup.name]) {
                        chartRefDict[planningGroup.name] = {};
                    }
                    for (let a = 0; a < planningGroup.resources.length; a++) {
                        const planningResource = planningGroup.resources[a];
                        chartRefDict[planningGroup.name][planningResource.name] = undefined;
                    }
                }
                state.resetState.chartDataByResourceGroup = chartRefDict;
                state.chartDataByResourceGroup = chartRefDict;
            })
            .addCase(loadUserDataFlatAsync.fulfilled, (state, action) => {
                const userDataflat: UserDataFlat = action.payload;
                state.scenarioStatuses = userDataflat.scenarioStatuses;
                state.scenarioCriterias = userDataflat.scenarioCriterias.map((x) => { return { value: 0, ...x }; });
                state.scenarioCriteriaById = createDictionary(state.scenarioCriterias, (x) => x.id);
                //se não houver nenhum scenario criado previamente não terá scenarioPriorities, então devemos iniciar com tudo zerado
                if (userDataflat.scenarioPriorities.length === 0) {
                    for (let i = 0; i < state.scenarioCriterias.length; i++) {
                        const scenarioCriteria = state.scenarioCriterias[i];
                        state.scenarioPriorities.push({
                            scenarioCriteriaId: scenarioCriteria.id,
                            scenarioId: 0,
                            value: 0,
                        });
                    }
                } else {
                    state.scenarioPriorities = userDataflat.scenarioPriorities;
                }
                state.scenarioCategories = userDataflat.scenarioCategories;
                state.scenarioCategoriesById = createDictionary(state.scenarioCategories, (x) => x.id);
                // let planningGridAllocationByX = createDictionary

                state.productionTypes = userDataflat.productionTypes;
                state.planningStatuses = userDataflat.planningStatus;
                state.periodTypes = userDataflat.periodTypes;
                state.periodTypeById = createDictionary(userDataflat.periodTypes, (x) => x.id);

                //TODO melhorar estes metodos de checagem de erro
                state.itemPlannerById = executeIfExists("userDataflat.itemPlanners", userDataflat.itemPlanners, () => createDictionary(userDataflat.itemPlanners, (x) => x.id));
                state.itemStockGroupById = executeIfExists("userDataflat.itemStockGroups", userDataflat.itemStockGroups, () => createDictionary(userDataflat.itemStockGroups, (x) => x.id));
                state.stockStatusByCode = executeIfExists("userDataflat.stockStatus", userDataflat.stockStatus, () => createDictionary(userDataflat.stockStatus, (x) => x.code)); state.stockStatus = userDataflat.stockStatus;
                state.attributeListByAttributeCode = executeIfExists("userDataflat.attributeList", userDataflat.attributeList, () => createDictionary(userDataflat.attributeList, (x) => x.attributeCode));
                state.attributeList = userDataflat.attributeList;
                state.operationTypes = userDataflat.operationTypes;
            })
            .addCase(loadPlanningGridsByScenarioIdAsync.fulfilled, (state, action) => {
                const planningGrids: PlanningGridData[] = action.payload.map(
                    (planningGrid) => ({
                        stockStatus: StockStatusType.Shortage,
                        isMpsHighlighted: false,
                        isOpeningStockHighlighted: false,
                        mpsHighlightedStatusType: HighlightedStatusType.Normal,
                        openingStockHighlightedStatusType: HighlightedStatusType.Normal,
                        isDirty: false,
                        ...planningGrid,
                    })
                );

                state.planningGridsDataByItemId = createDictionaryRange<PlanningGridData, number>(planningGrids, (x) => x.itemId);

                for (const planningGridId of Object.keys(state.planningGridsDataByItemId)) {
                    state.planningGridGlobalValuesByItemId[planningGridId] =
                    {
                        globalMinStockType: GlobalPlanningGridTargetType.IndividualTargetValue,
                        globalTargetStockType: GlobalPlanningGridTargetType.IndividualTargetValue,
                        globalMinStock: 0,
                        globalTargetStock: 0,
                    };
                }

                //TODO reset gambiarra
                state.resetState.planningGridsByItemId = state.planningGridsDataByItemId;

                //TODO Fix productIds by ResourceGroup
                // state.filterData.itemIdsByResourceGroup = {}//createPropertyDictionary(action.payload.products, x => x.planningResourceGroup, x => x.id)

                //Regra de Status
                state.planningGridsDataByItemId = calculateStockStatus(state.planningGridsDataByItemId);

                //seleciona todos os itens (que tenham um planningGrid) para mostrar no planningChart
                state.selectedItemIds = Array.from(new Set(planningGrids.map((planningGrid) => planningGrid.itemId)));

                //TODO checar se faz sort correto pelo ID
                const orderedItems = state.selectedItemIds.sort(function (a, b) { return a - b; });
                state.filterData.orderedItemIds = orderedItems;
                state.itemsIdsWithPlanningGrid = orderedItems;

                state.filterData.itemIdsByStockStatus = updateAllItemIdsByStockStatus(state);

                state.filterData.itemIdsByStockStatusByPeriod = updateAllItemIdsByStockStatusByPeriod(state);
            })
            .addCase(loadItemsAsync.fulfilled, (state, action) => {

                //TODO SERIAO MUDAR PARA PLANNING GRID -  TABELA ITENS PODE MUDAR O VALOR
                const items: ItemData[] = action.payload;
                state.items = items;
                state.itemById = createDictionary<ItemData, number>(items, (x) => x.id);
                state.filterData.itemIdsByItemPlannerIds = createPropertyRangeDictionary(state.items, (x) => x.itemPlannerId, (x) => x.id);
                state.filterData.itemIdsByStockGroupIds = createPropertyRangeDictionary(state.items, (x) => x.itemStockGroupId, (x) => x.id);
                state.filterData.itemIdsByLevel = createPropertyRangeDictionary(state.items, (x) => x.level, (x) => x.id);
            })
            .addCase(loadPlanningGridResourcesByScenarioIdAsync.fulfilled, (state, action) => {
                const planningGridResources: PlanningGridResourceData[] = action.payload;
                state.planningGridResources = planningGridResources;
            })
            .addCase(loadPlanningGridAllocationsDetailsByScenarioIdAsync.fulfilled, (state, action) => {
                const planningGridAllocationsDetails: PlanningGridAllocationDetails[] = action.payload;
                let localPlanningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId: Dictionary<Dictionary<Dictionary<Dictionary<PlanningGridAllocationDetails>>>> = {};

                const planningGrids = Object.values(state.planningGridsDataByItemId);
                //Fazer dicionario de periodIdByPlanningGridId
                const itemIdPeriodIdByPlanningGridId: Dictionary<{ periodId: number; itemId: number; }> = {};
                for (let i = 0; i < planningGrids.length; i++) {
                    const localPlanningGrids = planningGrids[i];
                    for (let j = 0; j < localPlanningGrids.length; j++) {
                        const localPlanningGrid = localPlanningGrids[j];
                        itemIdPeriodIdByPlanningGridId[localPlanningGrid.id] = {
                            itemId: localPlanningGrid.itemId,
                            periodId: localPlanningGrid.periodId,
                        };
                    }
                }
                state.itemIdPeriodIdByPlanningGridId = itemIdPeriodIdByPlanningGridId;

                const localSetupTimeByItemId: Dictionary<number> = {};
                const localSetupTimeByPeriodIdByItemIdByResourceId: Dictionary<Dictionary<Dictionary<number>>> = {};
                for (let i = 0; i < planningGridAllocationsDetails.length; i++) {
                    const planningGridAllocation = planningGridAllocationsDetails[i];
                    if (!planningGridAllocation.itemOperationId) planningGridAllocation.itemOperationId = 0
                    const { itemId, periodId } = itemIdPeriodIdByPlanningGridId[planningGridAllocation.planningGridId];

                    if (!localSetupTimeByPeriodIdByItemIdByResourceId[periodId])
                        localSetupTimeByPeriodIdByItemIdByResourceId[periodId] = {};

                    if (!localSetupTimeByPeriodIdByItemIdByResourceId[periodId][itemId])
                        localSetupTimeByPeriodIdByItemIdByResourceId[periodId][itemId] = {};

                    localSetupTimeByPeriodIdByItemIdByResourceId[periodId][itemId][planningGridAllocation.resourceId] = planningGridAllocation.totalSetupTime;

                    if (!localSetupTimeByItemId[itemId])
                        localSetupTimeByItemId[itemId] = planningGridAllocation.totalSetupTime;
                }
                state.setupTimeByPeriodIdByItemIdByResourceId = localSetupTimeByPeriodIdByItemIdByResourceId;
                state.setupTimeByItemId = localSetupTimeByItemId;

                //TODO para arrumar OccupationPage tem que arrumar esta parte
                for (let i = 0; i < planningGridAllocationsDetails.length; i++) {
                    const localPlanningGridAllocation = planningGridAllocationsDetails[i];

                    for (let j = 0; j < localPlanningGridAllocation.planningGridResources.length; j++) {

                        const localPlanningGridResource = localPlanningGridAllocation.planningGridResources[j];

                        if (!itemIdPeriodIdByPlanningGridId[localPlanningGridResource.planningGridId]) {
                            console.error(`Not found in Dictionary itemIdPeriodIdByPlanningGridAllocation with localPlanningGridResource.planningGridId:${localPlanningGridResource.planningGridId}`);
                            continue;
                        }
                        const { itemId, periodId } = itemIdPeriodIdByPlanningGridId[localPlanningGridResource.planningGridId];

                        if (!localPlanningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId[itemId])
                            localPlanningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId[itemId] = {};

                        if (!localPlanningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId[itemId][localPlanningGridAllocation.resourceId])
                            localPlanningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId[itemId][localPlanningGridAllocation.resourceId] = {};

                        if (!localPlanningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId[itemId][localPlanningGridAllocation.resourceId][localPlanningGridAllocation.itemOperationId])
                            localPlanningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId[itemId][localPlanningGridAllocation.resourceId][localPlanningGridAllocation.itemOperationId] = {};

                        const totalAllocatedTime = localPlanningGridAllocation.totalProcessTime + localPlanningGridAllocation.totalSetupTime;

                        localPlanningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId[itemId][localPlanningGridAllocation.resourceId][localPlanningGridAllocation.itemOperationId][periodId] = {
                            ...localPlanningGridAllocation,
                            totalAllocatedTime: totalAllocatedTime,
                        };
                    }
                }

                let resourcesProcessTimeDataByResourceId: Dictionary<ResourcesProcessTimeData> = {}

                for (let i = 0; i < planningGridAllocationsDetails.length; i++) {
                    const localPlanningGridAllocation = planningGridAllocationsDetails[i];

                    for (let j = 0; j < localPlanningGridAllocation.planningGridResources.length; j++) {

                        const localPlanningGridResource = localPlanningGridAllocation.planningGridResources[j];

                        if (!itemIdPeriodIdByPlanningGridId[localPlanningGridResource.planningGridId]) {
                            console.error(
                                `Not found in Dictionary itemIdPeriodIdByPlanningGridAllocation with localPlanningGridResource.planningGridId:${localPlanningGridResource.planningGridId}`
                            );
                            continue;
                        }
                        const { periodId } = itemIdPeriodIdByPlanningGridId[localPlanningGridResource.planningGridId];

                        if (!resourcesProcessTimeDataByResourceId[localPlanningGridAllocation.resourceId])
                            resourcesProcessTimeDataByResourceId[localPlanningGridAllocation.resourceId] = { totalProcessTimeForScenario: 0, byPeriod: {} }

                        if (!resourcesProcessTimeDataByResourceId[localPlanningGridAllocation.resourceId].byPeriod[periodId])
                            resourcesProcessTimeDataByResourceId[localPlanningGridAllocation.resourceId].byPeriod[periodId] = { totalProcessTimeForPeriod: 0, byItemOperationId: {} }

                        let localResourcesProcessTimeDataByResourceIdByPeriod = resourcesProcessTimeDataByResourceId[localPlanningGridAllocation.resourceId].byPeriod[periodId]

                        localResourcesProcessTimeDataByResourceIdByPeriod.byItemOperationId[localPlanningGridAllocation.itemOperationId] = localPlanningGridAllocation.totalProcessTime

                        localResourcesProcessTimeDataByResourceIdByPeriod.totalProcessTimeForPeriod += localPlanningGridAllocation.totalProcessTime


                        resourcesProcessTimeDataByResourceId[localPlanningGridAllocation.resourceId].totalProcessTimeForScenario += localPlanningGridAllocation.totalProcessTime
                    }
                }

                const resourcesToSelectDic = createDictionary(state.resourcesToSelect, x => x.id)

                const ResourceAggregatorDataFilteredWithProcessTime: ResourceAggregator[] = []

                let resourceIds: number[] = []
                for (const [key] of Object.entries(resourcesProcessTimeDataByResourceId)) {
                    resourceIds.push(Number(key))
                }
                for (let l = 0; l < resourceIds.length; l++) {
                    if (resourcesProcessTimeDataByResourceId[resourceIds[l]].totalProcessTimeForScenario > 0) {
                        ResourceAggregatorDataFilteredWithProcessTime.push(resourcesToSelectDic[resourceIds[l]])
                    }
                }

                state.filterResourceData.resourceWithProcessTime = ResourceAggregatorDataFilteredWithProcessTime
                state.filterResourceData.resourcesProcessTimeData = resourcesProcessTimeDataByResourceId;

                state.planningGridAllocationsDetails = planningGridAllocationsDetails;
                state.planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId = localPlanningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId;
                //TODO reset gambiarra
                state.resetState.planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId = localPlanningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId;
            })
            .addCase(loadPeriodsByScenarioIdAsync.fulfilled, (state, action) => {
                let periods: PeriodData[] = action.payload;
                periods.sort((n1, n2) => n1.rank - n2.rank);

                //Causa dependencia do planningGridsByItemId
                //TODO POSSIVEL ERRO!
                const trulyPeriodLength = state.planningGridsDataByItemId[Object.keys(state.planningGridsDataByItemId)[0]].length;
                periods = periods.slice(0, trulyPeriodLength);

                state.periods = periods;

                //TODO finalizar metodo loadPlanningGridByScenarioIdAsync.fulfilled
                //TODO melhorar forma de identificaar mensal/semanal
                const periodTypeId = state.periods[0].periodTypeId;
                const periodType = state.periodTypes.filter((x) => x.id === periodTypeId)[0];

                //TODO como modificar a linguagem no backend?
                const isWeekly = periodType.name?.toLowerCase() === "semanal";
                state.isWeekly = isWeekly;

                //Creating complex period for a Monthly Dataset creates a wrong ComplexPeriodData
                if (isWeekly)
                    state.complexPeriods = createComplexPeriodDataWeekly(periods);
                else state.complexPeriods = createComplexPeriodDataMonthly(periods);

                state.minifiedPeriods = createMinifiedPeriods(state.complexPeriods);

                //Create indexer periodId to planningGrid period index.
                for (let i = 0; i < periods.length; i++) {
                    const period = periods[i];
                    state.periodsIdToIndex[period.id] = i;
                }
            })
            .addCase(loadCapacitiesByScenarioIdAsync.fulfilled, (state, action) => {
                const capacities: CapacityData[] = action.payload;
                state.capacities = capacities;
                // state.capacityByResourceId = createDictionary(capacities, capacity => capacity.resourceId);
                let localResourceCapacityByPeriodIdByResourceId: Dictionary<Dictionary<CapacityData>> = {};
                for (let i = 0; i < capacities.length; i++) {
                    const capacity = capacities[i];
                    //TODO -1 pra mostrar a capacidade correta
                    if (!localResourceCapacityByPeriodIdByResourceId[capacity.periodId])
                        localResourceCapacityByPeriodIdByResourceId[capacity.periodId] = {};

                    localResourceCapacityByPeriodIdByResourceId[capacity.periodId][capacity.resourceId] = capacity;
                }
                state.capacityByPeriodIdByResourceId = localResourceCapacityByPeriodIdByResourceId;
            })
            .addCase(loadScenariosAsync.fulfilled, (state, action) => {
                state.scenarios = action.payload;
            })
            .addCase(loadCompleteStructureAsync.fulfilled, (state, action) => {
                //completeStructures pode conter itemIds que não estão
                //presentes na planningGrid do scenario
                const completeStructures: CompleteStructure[] = [];
                for (let i = 0; i < action.payload.length; i++) {
                    const localCompleteStructure = action.payload[i];
                    if (
                        state.planningGridsDataByItemId[localCompleteStructure.parentId] &&
                        state.planningGridsDataByItemId[localCompleteStructure.childId]
                    ) {
                        completeStructures.push(localCompleteStructure);
                    }
                }

                state.completeStructures = completeStructures;
                const completeStructuresByItemMaster = createDictionaryRange(completeStructures, (x) => x.itemMaster);
                const { completeStructureNodes, completeStructureMaxLevel, completeStructureOrderDataByItemMestre } = createCompleteStructureNodes(completeStructuresByItemMaster);
                state.completeStructuresMaxLevelByItemId = completeStructureMaxLevel;
                state.completeStructuresNodeByItemId = completeStructureNodes;
                state.completeStructuresByItemMaster = completeStructuresByItemMaster;
                state.completeStructureOrderDataByItemMaster = completeStructureOrderDataByItemMestre;
            })
            .addCase(loadDemandDataRangesAsync.fulfilled, (state, action) => {
                state.userData.demandDateRange = action.payload;
            })
            .addCase(loadItemOperationAsync.fulfilled, (state, action) => {
                state.itemOperations = action.payload
            })
    },
});

export const {
    clearFilter,
    filterBy,
    applyOccupationConstraintAnimation,
    applyOccupationConstraint,
    applyCriterias,
    planningFilterByResources,
    toggleIsSetupHidden,
    toggleIsColorByProduct,
    setChartDataByResource,
    applyDownMultipleReorder,
    applyMPSRulesOnProductSpecificPlanningGrid,
    applyStockStatusRulesToAll,
    applyUpMultipleReorder,
    clearSelectedOccupationData,
    lockPlanningGridFromStart,
    onMpsChangeValue,
    recalculateAllScenarioMpsByProductId,
    recalculatePeriodsMps,
    recalculateSelectedItemMps,
    removeDatasetById,
    renameScenario,
    setIsMinStockByDaysByItemId,
    setIsTargetStockByDaysByItemId,
    setMinimunReorder,
    setMininimunStockByItemId,
    setPlanningGridLock,
    setMultipleReorder,
    setSelectedScenarioId,
    setSelectedOccupationData,
    setSelectedItemIds,
    setItemRowSelectedProducts,
    setSelectedResources,
    setTargetStockByItemId,
    transferResourceAllocation,
    toggleExpandProductRow,
    toggleExpandedPeriods,
    transferChartProducts,
    unlockPlanningGridFromIndexToEnd,
    updateOccupationPageByProductId,
    updatePlanningPageByUnitOfTime,
    applyMultipleEditOnSelectedItems,
    resetPlanningPageState,
    resetSelectedItems,
    createFilterAttributes,
    finishOccupationCapacityDrag,
    clearSelectedResources,
    resetFilters,
    forceRedraw,
    toggleExpandMaterialFlowData,
    highlightMaterialFlowResources,
    highlightMaterialFlowResourcesClear,
    highlightMaterialFlowResourcesClearAll,
    setSelectedAttributes,
    setSelectedFilters,
    setInitialAttributeOptions,
    initializeFixedCriteriaOptions,
    updateAttributeOptions,
    updateFixedCriteriaOptions,
    toggleIsFiniteCapacity,
    applyResourceCriterias,
    resourceFilterBy,
    resourceFilterByDelete,
    toggleHasProcessTime

} = appScenarioSlice.actions;
