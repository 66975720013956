import { useRef, useMemo, useCallback, useEffect } from "react";
import { Bar, getElementsAtEvent } from 'react-chartjs-2';
import Chart from 'chart.js/auto'
import 'chartjs-plugin-dragdata';
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { calculateSummedSelectedPlanningGrids, selectSelectedProductIds, applyMPSRulesOnProductSpecificPlanningGrid, onMpsChangeValue, selectCurrentScenario, selectPlanningGridsLocks, selectPlanningChartText } from 'redux/AppScenarioState';
import { getStockStatusColor } from "utils/chartHelper";
import { t } from "@lingui/macro"

const minStocksColor = 'rgba(255, 225, 117, 0.85)',
    targetStocksColor = 'rgba(143, 190, 229, 0.8)',
    productionColor = 'rgba(108, 135, 147, 0.85)',
    mpsColor = 'rgba(102, 187, 106, 0.85)',
    safetyStockColor = 'rgba(177, 18, 38, 0.3)',
    maxStockColor = 'rgba(0, 0, 255, 0.85)',
    demandColor = 'rgba(212, 118, 2, 0.85)';

interface Props {
    isChartHidden: boolean
}

function PlanningChart(props: Props) {

    const { isChartHidden } = props;
    const dispatch = useAppDispatch()
    const selectedIds = useAppSelector(selectSelectedProductIds)
    const selectedProductText = useAppSelector(selectPlanningChartText)
    const selectedIdsRef = useRef<number[]>([])

    useEffect(() => {
        selectedIdsRef.current = selectedIds
    }, [selectedIds])
    //
    const summedSelectedPlanningGrids = useAppSelector(calculateSummedSelectedPlanningGrids)

    const planningGridLocks = useAppSelector(selectPlanningGridsLocks)
    const planningGridLocksRef = useRef<boolean[]>([])
    useEffect(() => {
        planningGridLocksRef.current = planningGridLocks
    }, [planningGridLocks])

    const chartLabelsRef = useRef(useAppSelector(state => state.appScenarioState.minifiedPeriods));

    const currentDataset = useAppSelector(selectCurrentScenario);

    const onClickHandler = useCallback((event) => {
        if (selectedIdsRef.current.length === 1 && event.altKey && chartReference.current) {
            let planningGridIndex = getElementsAtEvent(chartReference.current, event)[0].index;
            dispatch(applyMPSRulesOnProductSpecificPlanningGrid({ itemId: selectedIdsRef.current[0], planningGridIndex: planningGridIndex }))
        }
    }, [])

    const getStockStatusColorMemoized: string[] = useMemo(() => {
        return summedSelectedPlanningGrids.map(x => {
            return `${getStockStatusColor(x.stockStatus)}`;
        })
    }, [summedSelectedPlanningGrids])

    const localOnDragStart = useCallback((e, element) => {
        if (selectedIdsRef.current.length !== 1)
            return false;
    }, []);

    const localOnDrag = useCallback((e, datasetIndex, index, value) => {
        if (value < 0 || selectedIdsRef.current.length !== 1 || planningGridLocksRef.current[index])
            return false;
        e.target.style.cursor = 'grabbing'
    }, []);

    const localOnDragEnd = useCallback((e, datasetIndex, index, value) => {
        if (value < 0 || selectedIdsRef.current.length !== 1 || planningGridLocksRef.current[index])
            return false;
        e.target.style.cursor = 'default'
        switch (datasetIndex) {
            case 2:
                dispatch(onMpsChangeValue({ itemId: selectedIdsRef.current[0], productPeriodIndex: index, newMps: value }))
                break;
            default:
                break;
        }
    }, []);

    const chartOptions = useMemo(() => ({
        dragData: true,
        dragDataRound: 0,
        tooltips: {
            backgroundColor: "rgba(255,255,255,0.6)",
            borderWidth: 0,
            borderColor: "rgba(163, 161, 251, 1)",
            bodyFontColor: "rgba(0, 0, 0, 0.8)",
            titleFontColor: "rgba(0, 0, 0, 0.8)"
        },
        elements: {
            point: { radius: 0, hitRadius: 0, borderWidth: 0 },
            line: { tension: 0 }
        },
        maintainAspectRatio: false,
        plugins: {
            dragData: {
                round: 0,
                showTooltip: false,
                onDragStart: localOnDragStart,
                onDrag: localOnDrag,
                onDragEnd: localOnDragEnd,
            },
            title: {
                display: true,
                position: 'top',
                text: `${currentDataset?.name} | ${selectedProductText}`
            },
        }
    }), [currentDataset, selectedProductText]);

    const chartReference = useRef();

    const chartData: any = useMemo(() => {
        return {
            labels: chartLabelsRef.current,
            stacked: true,
            datasets: [
                {
                    order: 0,
                    type: "line",
                    data: summedSelectedPlanningGrids.map(x => x.closingStock),
                    label: t({ id: 'Estoque Projetado', comment: 'Label no topo do PlanningGridChart' }),
                    lineTension: 0.15,
                    cubicInterpolationMode: "monotonic",
                    fill: false,
                    pointBackgroundColor: getStockStatusColorMemoized,
                    pointBorderWidth: 1,
                    borderWidth: 2,
                    pointStyle: "triangle",
                    radius: 8,
                    dragData: false,
                },
                {
                    order: 0,
                    type: 'bar',
                    label: t({ id: 'Produção', comment: 'Label no topo do PlanningGridChart' }),
                    data: summedSelectedPlanningGrids.map(x => x.orders),
                    borderColor: ["rgba(163, 161, 251, 1)"],
                    borderWidth: 1,
                    pointHitRadius: 10,
                    pointRadius: 6,
                    pointHoverRadius: 4,
                    backgroundColor: productionColor,
                    pointBackgroundColor: 'rgba(239,83,80,0.8)',
                    pointBorderColor: 'rgba(0,0,0,0.6)',
                    pointHoverBackgroundColor: "rgba(255, 255, 255, 1)",
                    pointHoverBorderColor: "rgba(163, 161, 251, 1)",
                    dragData: false
                },
                {
                    order: 1,
                    type: 'bar',
                    fillColor: "green",
                    label: t({ id: 'Plano', comment: 'Label no topo do PlanningGridChart' }),
                    data: summedSelectedPlanningGrids.map(x => x.mps),
                    backgroundColor: mpsColor,
                    borderWidth: 1,
                    pointHitRadius: 5,
                    pointHoverRadius: 4,
                    pointHoverBackgroundColor: "rgba(255, 255, 255, 1)",
                    pointHoverBorderColor: "rgba(163, 161, 251, 1)"
                },
                {
                    order: 2,
                    type: 'bar',
                    fillColor: "orange",
                    label: t({ id: 'Demanda', comment: 'Label no topo do PlanningGridChart' }),
                    data: summedSelectedPlanningGrids.map(x => x.demand),
                    backgroundColor: demandColor,
                    borderWidth: 1,
                    pointHitRadius: 5,
                    pointHoverRadius: 4,
                    pointHoverBackgroundColor: "rgba(255, 255, 255, 1)",
                    pointHoverBorderColor: "rgba(163, 161, 251, 1)",
                    dragData: false,
                },
                {
                    order: 3,
                    type: 'line',
                    label: t({ id: 'Ponto de Reposição', comment: 'Label no topo do PlanningGridChart' }),
                    fill: true,
                    data: summedSelectedPlanningGrids.map(x => x.minStock),
                    backgroundColor: minStocksColor,
                    lineTension: 0.15,
                    radius: 0,
                    dragData: false,
                },
                {
                    order: 4,
                    type: 'line',
                    label: t({ id: 'Estoque Alvo', comment: 'Label no topo do PlanningGridChart' }),
                    fill: true,
                    data: summedSelectedPlanningGrids.map(x => x.targetStock),
                    backgroundColor: targetStocksColor,
                    lineTension: 0.15,
                    radius: 0,
                    dragData: false,
                },
                {
                    order: 2,
                    type: 'line',
                    label: t({ id: 'Estoque de Segurança', comment: 'Label no topo do PlanningGridChart' }),
                    fill: true,
                    data: summedSelectedPlanningGrids.map(x => x.safetyStock),
                    backgroundColor: safetyStockColor,
                    lineTension: 0.15,
                    radius: 0,
                    dragData: false,
                },
                {
                    order: 6,
                    type: 'line',
                    label: t({ id: 'Estoque Máximo', comment: 'Label no topo do PlanningGridChart' }),
                    // fill: true,
                    data: summedSelectedPlanningGrids.map(x => x.maxStock),
                    // backgroundColor: maxStockColor,
                    // borderColor: maxStockColor,
                    lineTension: 0.15,
                    radius: 0,
                    dragData: false,
                },
            ]
        }
    }, [summedSelectedPlanningGrids]);

    const planningChartMemoized = useMemo(() => {
        return (
            <div className='h-80'>
                <Bar
                    options={chartOptions as any}
                    data={chartData}
                    ref={chartReference}
                    onClick={onClickHandler}
                />
            </div>
        )
    }, [chartData, chartOptions, onClickHandler])

    return (
        <>
            {
                !isChartHidden &&
                planningChartMemoized
            }
        </>
    )
}

export default PlanningChart