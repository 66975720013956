import { RequestParamsParameters, RequestSignalParameter } from "api"
import { Period, PeriodDetails } from "api/data/types"
import { getAsync } from ".."

const route = 'periods'
interface UrlQuery {
    scenarioId?: number
}

export const getPeriodByIdAsync = async (periodId: number, parameters?: RequestSignalParameter): Promise<Period> => {
    return await getAsync({ url: `/${route}/${periodId}`, ...parameters })
}
export const getPeriodDetailsByIdAsync = async (periodId: number, parameters?: RequestSignalParameter): Promise<PeriodDetails> => {
    return await getAsync({ url: `/${route}/${periodId}/details`, ...parameters })
}
export const getPeriodsByScenarioIdAsync = async (parameters?: RequestParamsParameters<UrlQuery, null>): Promise<Period[]> => {
    return await getAsync({ url: `/${route}`, ...parameters })
}
export const getPeriodsDetailsByScenarioIdAsync = async (parameters?: RequestParamsParameters<UrlQuery, null>): Promise<PeriodDetails[]> => {
    return await getAsync({ url: `/${route}/details`, ...parameters })
}