import { t } from '@lingui/macro'
import { Trans } from '@lingui/macro'
import { HighlightedStatusType, StockStatusType } from 'api/data/enums'
import React, { useEffect, useState } from 'react'
import { getStockStatusColor } from 'utils/chartHelper'
import { selectItemImageCached, setItemRowSelectedProducts } from 'redux/AppScenarioState'
import { HighlightedStatusTypeColor } from './helper'
import { useAppDispatch } from 'redux/hooks'

interface Props {
    isSelected: boolean,
    planningGridStatus: StockStatusType,
    firstPlanningGridOpeningStock: number,
    itemCode: string,
    itemDescription: string,
    isExpandedProductRow: boolean,
    itemId: number,
    materialItemId: number,
    isOpeningStockHighlighted: boolean,
    paddingLeft: number,
    openingStockHighlightedStatusType: HighlightedStatusType
}

function MaterialFlowItemRow(props: Props) {
    const {
        firstPlanningGridOpeningStock,
        isExpandedProductRow,
        isSelected,
        itemCode,
        itemDescription,
        planningGridStatus,
        materialItemId,
        isOpeningStockHighlighted,
        paddingLeft,
        openingStockHighlightedStatusType,
    } = props

    const [itemImage, setItemImage] = useState<string>();
    const dispatch = useAppDispatch();

    useEffect(() => {
        const fetchData = async () => {
            const imageObjectURL = await selectItemImageCached(materialItemId)
            setItemImage(imageObjectURL);
        }
        fetchData();
    }, [materialItemId]);

    const handleOnSelectProduct = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        dispatch(setItemRowSelectedProducts({ ctrlKey: event.ctrlKey, shiftKey: event.shiftKey, itemId: materialItemId }))
    }
    return (
        <div className={`flex flex-row w-full h-60 border-t-2 select-none ${isSelected ? 'bg-orange-200' : 'bg-yellow-50'}`}
            onClick={event => handleOnSelectProduct(event)}>
            <div className={`grow-0 flex-col h-full ${isExpandedProductRow ? 'w-80' : 'w-36'} `}
                style={{
                    minWidth: 320
                }}
            >
                <p className={`w-full font-bold h-16 p-1 pl-3 text-au text-left ${isExpandedProductRow ? 'text-xl' : 'text-sm'}`}>{itemDescription}</p>
                <div className='flex flex-row w-full h-auto  items-center justify-center'>
                    <div className={`rounded-md flex grow justify-self-start justify-center } mx-1`}>
                        <img src={itemImage} alt="itemImage" className='w-[80px] h-[120px]' />
                    </div>
                </div>
                <div className='grow flex ml-1 justify-center'>
                    <div className='flex rounded-md font-semibold h-auto w-max border-2  p-0.5'>{itemCode}</div>
                </div>
            </div>
            {
                isExpandedProductRow ?
                    <div className='flex flex-col w-24  items-center justify-center'
                        style={{ background: `${getStockStatusColor(planningGridStatus)}`, minWidth: 96 }}>
                        <div>
                            <p
                                className={`${isOpeningStockHighlighted && ' p-1 border-solid border-4 rounded-md  bg-white'}`}
                                style={{ borderColor: `rgb(${HighlightedStatusTypeColor(openingStockHighlightedStatusType)})` }}
                            >
                                {firstPlanningGridOpeningStock} {t({ message: 'Un', comment: 'unidade/letra para simbolizar Unidades, ex: Un' })}
                            </p>
                        </div>
                        <div>
                            <p>{planningGridStatus}</p>
                        </div>
                    </div>
                    :
                    <div className='flex flex-shrink-0 w-4'
                        style={{ background: `${getStockStatusColor(planningGridStatus)}` }} />
            }
            {
                isExpandedProductRow ?
                    <div className='flex flex-col grow h-full w-[189px] text-center justify-evenly text-sm'>
                        <p><Trans>Demanda</Trans></p>
                        <p><Trans>Programado</Trans></p>
                        <p><Trans>Plano</Trans></p>
                        <p><Trans>Estoque Alvo</Trans></p>
                        <p><Trans>Estoque Inicial</Trans></p>
                        <p><Trans>Estoque Final</Trans></p>
                        <p><Trans>Cobertura</Trans></p>
                        <p><Trans>Situação</Trans></p>
                    </div>
                    :
                    <div className='flex flex-col max-w-min h-full  text-center justify-evenly text-sm mx-1'>
                        <p><Trans>Dem</Trans></p>
                        <p><Trans>Pro</Trans></p>
                        <p><Trans>Pla</Trans></p>
                        <p><Trans>E. Alv</Trans></p>
                        <p><Trans>E. Ini</Trans></p>
                        <p><Trans>E. Fin</Trans></p>
                        <p><Trans>Cob</Trans></p>
                        <p><Trans>Sit</Trans></p>
                    </div>
            }
        </div>
    )
}

export default MaterialFlowItemRow
