import { Dictionary } from "utils/types";
import { AppScenarioState, OccupationChartScenario } from "../types";
import { FilterCriteria, FilterData, OccupationPageFilterData, OccupationPageFilterDataPeriod, SearchCriteriasDictionary, SearchList, StockStatusDictionary } from "./types";
import { getChartLabelData } from "../helpers";
import { selectItemAttribute1SearchTree, selectItemAttribute2SearchTree, selectItemAttribute3SearchTree, selectItemAttribute4SearchTree, selectItemAttribute5SearchTree, selectItemAttribute6SearchTree, selectItemAttribute7SearchTree, selectItemAttribute8SearchTree, selectItemAttribute9SearchTree, selectItemAttribute10SearchTree, selectItemPlannerSearchTree, selectItemStockGroupSearchTree, selectStockStatusWithPeriodTypeSearchTree } from "./selectors";
import { selectItemCodeSearchTree, selectItemLevelSearchTree } from "..";
import { i18n } from "@lingui/core";


export const FilterCriteriaToSelector = (filterCriteria: FilterCriteria) => {
    switch (filterCriteria) {
        case FilterCriteria.ItemAttribute1:
            return selectItemAttribute1SearchTree
        case FilterCriteria.ItemAttribute2:
            return selectItemAttribute2SearchTree
        case FilterCriteria.ItemAttribute3:
            return selectItemAttribute3SearchTree
        case FilterCriteria.ItemAttribute4:
            return selectItemAttribute4SearchTree
        case FilterCriteria.ItemAttribute5:
            return selectItemAttribute5SearchTree
        case FilterCriteria.ItemAttribute6:
            return selectItemAttribute6SearchTree
        case FilterCriteria.ItemAttribute7:
            return selectItemAttribute7SearchTree
        case FilterCriteria.ItemAttribute8:
            return selectItemAttribute8SearchTree
        case FilterCriteria.ItemAttribute9:
            return selectItemAttribute9SearchTree
        case FilterCriteria.ItemAttribute10:
            return selectItemAttribute10SearchTree
        case FilterCriteria.ItemCode:
        default:
            return selectItemCodeSearchTree;
        case FilterCriteria.ItemPlanner:
            return selectItemPlannerSearchTree;
        case FilterCriteria.ItemStockGroup:
            return selectItemStockGroupSearchTree;
        case FilterCriteria.Level:
            return selectItemLevelSearchTree;
        case FilterCriteria.StockStatusTypeWithPeriod:
            return selectStockStatusWithPeriodTypeSearchTree;

        //Being used on OccupationPage
        // case FilterCriteria.OccupationPageResourceAndPeriod:
        // case FilterCriteria.OccupationPageResourceGroup:
        // case FilterCriteria.ResourceGroup:
    }
}
//TODO checar se é performatico
export const intersectCustom = (a, b): number[] => {
    if (!a) return Array.from(new Set(b));
    if (!b) return Array.from(new Set(a));
    return Array.from(new Set(a.filter(Set.prototype.has, new Set(b))));
}

export const applyCriteriaByFilterCriteria = (appScenarioState: AppScenarioState, filterCriteriaKey?: FilterCriteria): number[] => {
    let orderedItemIds: number[] = appScenarioState.itemsIdsWithPlanningGrid;
    let searchCriteriasData: SearchCriteriasDictionary<any> = appScenarioState.filterData.searchCriteriasData;
    let filterData: FilterData = appScenarioState.filterData

    if (searchCriteriasData) {
        for (const criteriaKey of Object.keys(searchCriteriasData)) {
            let ids: number[] = [];
            if (searchCriteriasData[criteriaKey]) {
                switch (criteriaKey) {
                    case FilterCriteria.ItemCode:
                        ids = searchCriteriasData.ItemCode.map(item => Number(item.id))
                        break
                    case FilterCriteria.StockStatusTypeWithPeriod:
                        ids = applyStockStatusWithPeriodTypeCriteria(orderedItemIds, searchCriteriasData[criteriaKey], filterData.itemIdsByStockStatus, filterData.itemIdsByStockStatusByPeriod)
                        break;
                    case FilterCriteria.ItemPlanner:
                        ids = applyItemPlannerCriteria(orderedItemIds, searchCriteriasData[criteriaKey], filterData.itemIdsByItemPlannerIds)
                        break;
                    case FilterCriteria.ItemStockGroup:
                        ids = applyItemStockGroupCriteria(orderedItemIds, searchCriteriasData[criteriaKey], filterData.itemIdsByStockGroupIds)
                        break;
                    case FilterCriteria.Level:
                        ids = applyItemLevelCriteria(orderedItemIds, searchCriteriasData[criteriaKey], filterData.itemIdsByLevel)
                        break;
                    case FilterCriteria.ItemAttribute1:
                    case FilterCriteria.ItemAttribute2:
                    case FilterCriteria.ItemAttribute3:
                    case FilterCriteria.ItemAttribute4:
                    case FilterCriteria.ItemAttribute5:
                    case FilterCriteria.ItemAttribute6:
                    case FilterCriteria.ItemAttribute7:
                    case FilterCriteria.ItemAttribute8:
                    case FilterCriteria.ItemAttribute9:
                    case FilterCriteria.ItemAttribute10:
                        ids = applyAttributesCriteria(orderedItemIds, searchCriteriasData[criteriaKey], filterData.itemsIdsByAttributeId[criteriaKey])
                        break;
                    case FilterCriteria.OccupationPageResourceAndPeriod:
                        ids = applyOccupationPageResourceAndPeriodCriteria(searchCriteriasData[criteriaKey], appScenarioState.chartDataByResourceGroup)
                        break;
                    case FilterCriteria.OccupationPageResourceGroup:
                        ids = applyOccupationPageResourceGroup(searchCriteriasData[criteriaKey], appScenarioState.chartDataByResourceGroup)
                        break;
                    default:
                        throw Error(`Unknown criteria: ${criteriaKey}`);
                }
            }
            if (ids.length > 0)
                orderedItemIds = intersectCustom(orderedItemIds, ids);
        }
    }
    return orderedItemIds;
}

export const applyItemCodeCriteria = (
    selectedResourceGroupIds: number[],
    productIdsByResourceGroup: Dictionary<number[]>
): number[] => {
    let filteredInputs: number[] = [];
    for (let index = 0; index < selectedResourceGroupIds.length; index++) {
        const resourceGroupId = selectedResourceGroupIds[index];
        filteredInputs.push(...productIdsByResourceGroup[resourceGroupId])
    }
    return filteredInputs.sort(function (a, b) { return a - b })
}

export const applyResourceGroupCriteria = (
    selectedResourceGroupIds: number[],
    productIdsByResourceGroup: Dictionary<number[]>
): number[] => {
    let filteredInputs: number[] = [];
    for (let index = 0; index < selectedResourceGroupIds.length; index++) {
        const resourceGroupId = selectedResourceGroupIds[index];
        filteredInputs.push(...productIdsByResourceGroup[resourceGroupId])
    }
    return filteredInputs.sort(function (a, b) { return a - b })
}

export const applyAttributesCriteria = (
    itemIdsToFilter: number[],
    filterParams: SearchList<null>[],
    itemIdsByAttributeNId: Dictionary<number[]>): number[] => {

    const selectedParamValues = filterParams.map(x => x.id);
    const selectedIds: number[] = []

    if (selectedParamValues[0] === 'All') {
        for (const items of Object.values(itemIdsByAttributeNId)) {
            selectedIds.push(...items);
        }
    } else {
        for (let i = 0; i < selectedParamValues.length; i++) {
            const selectedId = selectedParamValues[i];
            let splitedIds = selectedId.split('|');
            if (splitedIds.length > 1) {
                selectedIds.push(Number(splitedIds[1]));
            } else {
                selectedIds.push(...itemIdsByAttributeNId[selectedId]);
            }
        }
    }

    const filteredProductIds =
        intersectCustom(itemIdsToFilter, selectedIds).sort(function (a, b) { return a - b })
    return filteredProductIds;
}

export const applyStockStatusTypeCriteria = (
    itemIdsToFilter: number[],
    filterParams: SearchList<null>[],
    itemIdsByStockStatus: StockStatusDictionary<number[]>): number[] => {

    const stockStatusName = filterParams.map(x => x.id);

    const selectedIds: number[] = []
    for (let i = 0; i < stockStatusName.length; i++) {
        const selectedId = stockStatusName[i];
        const teste: number[] = itemIdsByStockStatus[selectedId]
        selectedIds.push(...teste)
    }
    const filteredProductIds =
        intersectCustom(itemIdsToFilter, selectedIds).sort(function (a, b) { return a - b })
    return filteredProductIds;
}

export const applyStockStatusWithPeriodTypeCriteria = (
    itemIdsToFilter: number[],
    filterParams: SearchList<null>[],
    itemIdsByStockStatus: StockStatusDictionary<number[]>,
    itemIdsByStockStatusByPeriod: StockStatusDictionary<Dictionary<number[]>>
): number[] => {
    const stockStatusName = filterParams.map(x => x.id);

    const selectedIds: number[] = []
    for (let i = 0; i < stockStatusName.length; i++) {
        const splitedSelectedId = stockStatusName[i].split('|');
        let ids: number[] = []
        if (splitedSelectedId[1]) {
            ids = itemIdsByStockStatusByPeriod[splitedSelectedId[0]][splitedSelectedId[1]]

        } else {
            ids = itemIdsByStockStatus[splitedSelectedId[0]]
        }
        selectedIds.push(...ids)
    }
    const filteredProductIds =
        intersectCustom(itemIdsToFilter, selectedIds).sort(function (a, b) { return a - b })
    return filteredProductIds;
}

export const applyItemPlannerCriteria = (
    itemIdsToFilter: number[],
    filterParams: SearchList<null>[],
    itemIdsByItemPlannerIds: Dictionary<number[]>
): number[] => {
    const itemPlannerId = Number(filterParams[0].id)
    const ids = itemIdsByItemPlannerIds[itemPlannerId]
    const filteredProductIds =
        intersectCustom(itemIdsToFilter, ids).sort(function (a, b) { return a - b })
    return filteredProductIds;
}

export const applyItemStockGroupCriteria = (
    itemIdsToFilter: number[],
    filterParams: SearchList<null>[],
    itemIdsByStockGroupIds: Dictionary<number[]>
): number[] => {
    const itemStockGroupId = Number(filterParams[0].id)
    const ids = itemIdsByStockGroupIds[itemStockGroupId]
    const filteredProductIds =
        intersectCustom(itemIdsToFilter, ids).sort(function (a, b) { return a - b })
    return filteredProductIds;
}

export const applyItemLevelCriteria = (
    itemIdsToFilter: number[],
    filterParams: SearchList<null>[],
    itemIdsByLevel: Dictionary<number[]>
): number[] => {
    const itemLevel = Number(filterParams[0].id)
    const ids = itemIdsByLevel[itemLevel]
    const filteredProductIds =
        intersectCustom(itemIdsToFilter, ids).sort(function (a, b) { return a - b })
    return filteredProductIds;
}

export const applyOccupationPageResourceGroup = (
    filterParams: SearchList<OccupationPageFilterData>[],
    chartDataByResourceGroup: Dictionary<Dictionary<OccupationChartScenario | undefined>>
): number[] => {
    const occupationPageFilterData: OccupationPageFilterData | undefined = filterParams[0].data;
    if (!occupationPageFilterData) {
        throw console.error('occupationPageFilterData is null!')
    }
    const selectedResourceData = occupationPageFilterData.selectedResourceData;
    const ids: number[] = []
    const chartDataByResourceName = chartDataByResourceGroup[selectedResourceData.resourceGroupName];

    if (chartDataByResourceName) {
        const chartData = chartDataByResourceName[selectedResourceData.resourceName];
        if (chartData) {
            for (let i = 0; i < chartData.datasets.length; i++) {
                const dataset = chartData.datasets[i];
                if (dataset.label.toLowerCase() !== i18n.t(`setup`).toLowerCase() && dataset.label !== i18n.t(`Available Capacity`).toLowerCase()) {
                    const { resourceId, itemId, itemCode, operationId } = getChartLabelData(dataset.label)
                    ids.push(itemId);
                }
            }
        }
    }
    return ids;
}

export const applyOccupationPageResourceAndPeriodCriteria = (
    filterParams: SearchList<OccupationPageFilterDataPeriod>[],
    chartDataByResourceGroup: Dictionary<Dictionary<OccupationChartScenario | undefined>>
): number[] => {
    const occupationPageFilterData: OccupationPageFilterDataPeriod | undefined = filterParams[0].data;
    if (!occupationPageFilterData) {
        throw console.error('occupationPageFilterData is null!')
    }
    const selectedResourceData = occupationPageFilterData.selectedResourceData;
    const ids: number[] = []
    const chartDataByResourceName = chartDataByResourceGroup[selectedResourceData.resourceGroupName];

    if (chartDataByResourceName) {
        const chartData = chartDataByResourceName[selectedResourceData.resourceName];
        if (chartData) {
            for (let i = 0; i < chartData.datasets.length; i++) {
                const dataset = chartData.datasets[i];
                if (dataset.label.toLowerCase() !== i18n.t(`setup`).toLowerCase() && dataset.label !== i18n.t(`Available Capacity`).toLowerCase() && dataset.data[occupationPageFilterData.chartPeriodIndex] > 0) {
                    const { resourceId, itemId, itemCode, operationId } = getChartLabelData(dataset.label)
                    ids.push(itemId);
                }
            }
        }

    }
    return ids;
}