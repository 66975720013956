import React from "react";
import { getTableColumnsAsync, getTableDataAsync, putTableColumnAsync } from 'api/data'
import { useParams } from "react-router-dom";
import { defaultRender, checkBoxRender, filterRender } from './components/renders'
import { Button, MenuItem, Select, TextField } from "@mui/material";
import { t, Trans } from "@lingui/macro";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getLabelWidth } from "utils";
import NeoLoader from "components/Page/NeoLoader";


function withParams(Component) {
    return props => <Component { ...props } params={ useParams() } />;
}
class TableEditionColumn extends React.Component<any, any> {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            columns: [
                {
                    field: "field",
                    title: t`Campo`,
                    render: defaultRender,
                    align: "left",
                },
                {
                    field: "header",
                    title: t`Título`,
                    render: defaultRender,
                    align: "left",
                    editor: "text"
                },
                {
                    field: "width",
                    title: t`Largura`,
                    render: defaultRender,
                    align: "left",
                    editor: "number"
                },
                {
                    field: "align",
                    title: t`Alinhamento`,
                    render: defaultRender,
                    align: "left",
                    editor: "select"
                },
                {
                    field: "viewType",
                    title: t`Tipo de visualização`,
                    render: defaultRender,
                    align: "left",
                    editor: "select"
                },
                {
                    field: "numberDecimals",
                    title: t`Quantidade de decimais`,
                    render: defaultRender,
                    align: "left",
                    editor: "number"
                },
                {
                    field: "filterType",
                    title: t`Tipo de filtro`,
                    render: filterRender,
                    align: "center",
                    editor: "select"
                },
                {
                    field: "nullText",
                    title: t`Texto Null`,
                    render: defaultRender,
                    align: "left",
                    editor: "text"
                },
                {
                    field: "editable",
                    title: t`Editável`,
                    render: checkBoxRender,
                    align: "center",
                    editor: "checkbox"
                },
                {
                    field: "hidden",
                    title: t`Oculto`,
                    render: checkBoxRender,
                    align: "center",
                    editor: "checkbox"
                },
                {
                    field: "active",
                    title: t`Ativo`,
                    render: checkBoxRender,
                    align: "center",
                    editor: "checkbox"
                },
                {
                    field: "enableNull",
                    title: t`Permite Null`,
                    render: checkBoxRender,
                    align: "center",
                    editor: "checkbox"
                },
                {
                    field: "sortDefault",
                    title: t`Ordenador Padrão`,
                    render: checkBoxRender,
                    align: "center",
                    editor: "checkbox"
                },
                {
                    field: "editorAll",
                    title: t`Editor em Massa`,
                    render: checkBoxRender,
                    align: "center",
                    editor: "checkbox"
                },

                {
                    field: "foreignKey",
                    title: t`Chave Estrangeira`,
                    render: checkBoxRender,
                    align: "center",
                    editor: "checkbox"
                },
                {
                    field: "foreignSchema",
                    title: t`Schema Tabela Estrangeira`,
                    render: defaultRender,
                    align: "left",
                    editor: "text"
                },
                {
                    field: "foreignTable",
                    title: t`Tabela Estrangeira`,
                    render: defaultRender,
                    align: "left",
                    editor: "text"
                },
                {
                    field: "foreignField",
                    title: t`Campo Id Tabela Estrangeira`,
                    render: defaultRender,
                    align: "left",
                    editor: "text"
                },
                {
                    field: "foreignShowField",
                    title: t`Campo P/ Exibir`,
                    render: defaultRender,
                    align: "left",
                    editor: "text"
                },
                {
                    field: "order",
                    title: t`Ordem`,
                    render: defaultRender,
                    align: "left",
                    editor: "number"
                },

            ],
            select: {
                align: [
                    {
                        id: "center",
                        name: t`center`
                    },
                    {
                        id: "left",
                        name: t`left`
                    },
                    {
                        id: "rigth",
                        name: t`rigth`
                    }
                ],
                filterType: [
                    {
                        id: 1,
                        name: t`Número`
                    },
                    {
                        id: 2,
                        name: t`Texto`
                    },
                    {
                        id: 3,
                        name: t`Verdadeiro/Falso`
                    },
                    {
                        id: 4,
                        name: t`Data`
                    },
                    {
                        id: 5,
                        name: t`Select`
                    }
                ],
                viewType: [
                    {
                        id: "texto",
                        name: t`texto`
                    },
                    {
                        id: "inteiro",
                        name: t`inteiro`
                    },
                    {
                        id: "decimal",
                        name: t`decimal`
                    },
                    {
                        id: "data",
                        name: t`data`
                    },
                    {
                        id: "hora",
                        name: t`hora`
                    },
                    {
                        id: "datahora",
                        name: t`data e hora`
                    },
                    {
                        id: "checkbox",
                        name: t`checkbox`
                    },
                    {
                        id: "color",
                        name: t`cor`
                    },
                ]

            },
            rows: []
        }
    }
    async refreshData() {
        this.setState(prevState => ({ ...prevState, loading: true }))
        var tablesColumns = await getTableColumnsAsync(this.props.params.id);
        this.setState(prevState => ({ ...prevState, data: tablesColumns, loading: false }))
    }
    async handleAdjustWidth() {
        this.setState(prevState => ({ ...prevState, loading: true }))
        let res = await getTableDataAsync(this.props.params.id)
        let columnsCopy = [...this.state.data];
        columnsCopy.map(col => {
            var text = col.header
            col.width = getLabelWidth(text) + 25
            if (col.width < 90)
                col.width = 90;
        })
        var i = 0;
        res.data.map(row => {
            columnsCopy.filter(a => a.active).map(col => {
                var text = row[col.field]
                try {
                    if (col.foreignKey && Array.isArray(res.foreignFields[col.field]) && text != null)
                        text = res.foreignFields[col.field].filter(f => f.id === text)[0]?.name;
                    if (text != null) {
                        var width = getLabelWidth(text) + 10
                        if (col.width < width) {
                            col.width = width;
                        }
                    }
                }
                catch {

                }

            })
        })
        for (var column of columnsCopy)
            await this.saveData(column)
        this.setState(prevState => ({ ...prevState, data: columnsCopy, loading: false }));
    }
    componentDidMount(): void {
        this.refreshData();
    }
    async saveData(row) {
        putTableColumnAsync(row);
    }
    render() {
        return (
            this.state.loading ?
                <React.Fragment>
                    <NeoLoader />
                </React.Fragment>
                :
                <React.Fragment>
                    <h1 style={ { textAlign: "center", fontWeight: "bold", padding: "5px", borderBottom: "1px solid #004" } }><Trans>EDIÇÃO DE COLUNAS</Trans></h1>
                    <Button variant="contained" onClick={ () => this.handleAdjustWidth() } sx={ { width: "200px", mt: 2, mb: 2 } }><Trans>Ajustar Largura</Trans></Button>
                    <TableContainer component={ Paper }>
                        <Table>
                            <TableHead style={ { position: "sticky", top: "0", background: "white", zIndex: 1 } }>
                                <TableRow>
                                    { this.state.columns.map((column) =>
                                        <TableCell key={ column.id } style={ { padding: "5px 15px" } }>
                                            { column.title }
                                        </TableCell>
                                    ) }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { this.state.data?.sort((a, b) => a.order > b.order ? 1 : -1).map((row, index) =>
                                    <TableRow key={ row.id } style={ { backgroundColor: index % 2 === 0 ? "rgba(0, 0, 0, 0.04)" : "rgba(255, 255, 255)" } }>
                                        { this.state.columns.map((column, index) =>
                                            row.editing && column.editing && (column.editor === "text" || column.editor === "number") ?
                                                <TableCell style={ { minWidth: "100px" } }>
                                                    <TextField
                                                        fullWidth
                                                        style={ { minWidth: "100px" } }
                                                        inputProps={ { style: { height: '20px' } } }
                                                        autoFocus
                                                        value={ row[column.field] }
                                                        onChange={ ({ target: { value } }) => {
                                                            row[column.field] = value
                                                            this.setState(prevState => ({ ...prevState }));
                                                        } }
                                                        onKeyDown={ ({ key }) => {
                                                            if (key === 'Enter') {
                                                                row.editing = false;
                                                                column.editing = false;
                                                                this.setState(prevState => ({ ...prevState }));
                                                                this.saveData(row);
                                                            }
                                                            if (key === "Escape") {
                                                                row.editing = false;
                                                                column.editing = false;
                                                                this.setState(prevState => ({ ...prevState }));
                                                                this.refreshData();
                                                            }
                                                        } }
                                                        type={ column.editor }
                                                    >
                                                    </TextField>
                                                </TableCell>
                                                :
                                                row.editing && column.editing && column.editor === "select" ?
                                                    <TableCell style={ { minWidth: "100px" } }>
                                                        <Select
                                                            value={ row[column.field] }
                                                            fullWidth
                                                            variant="standard"
                                                            onChange={ ({ target: { value } }) => {
                                                                row[column.field] = value;
                                                                row.editing = false;
                                                                column.editing = false;
                                                                this.setState(prevState => ({ ...prevState }));
                                                                this.saveData(row);
                                                            } }
                                                            onClose={ () => {
                                                                row.editing = false;
                                                                column.editing = false;
                                                                this.setState(prevState => ({ ...prevState }));
                                                            } }
                                                            autoFocus
                                                        >
                                                            { this.state.select[column.field].map((row, index) =>
                                                                <MenuItem value={ row.id } key={ index }>{ row.name }</MenuItem>
                                                            ) }
                                                        </Select>
                                                    </TableCell>
                                                    :
                                                    <TableCell style={ { minWidth: "100px" } } align={ column.align }
                                                        key={ index }
                                                        onClick={ () => {
                                                            if (column.editor && column.editor === "checkbox") {
                                                                row[column.field] = !row[column.field];
                                                                this.setState(prevState => ({ ...prevState }));
                                                                this.saveData(row);
                                                                return;
                                                            }
                                                        } }
                                                        onDoubleClick={ () => {
                                                            if (column.editor) {
                                                                if (column.editor === "checkbox") return;
                                                                column.editing = true;
                                                                row.editing = true;
                                                                this.setState(prevState => ({ ...prevState }));
                                                            }
                                                        } }
                                                    >
                                                        { column.render(row, column) }
                                                    </TableCell>
                                        ) }
                                    </TableRow>
                                ) }
                            </TableBody>

                        </Table>
                    </TableContainer>
                </React.Fragment>
        )
    }
}

export default withParams(TableEditionColumn)