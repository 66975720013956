import React, { useContext, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import NBg from 'assets/images/nplan/logo/3t.png'
import { UserContext } from 'components/UserProvider';
import { Navigate, useNavigate } from 'react-router-dom';
import { createTheme, darkScrollbar, FormControlLabel, IconButton, InputAdornment, Paper, Switch, Typography } from '@mui/material';
import { Box, ThemeProvider } from '@mui/system';
import { MdOutlineVisibility, MdOutlineVisibilityOff } from 'react-icons/md';
import { postSessionAsync } from 'api/user/lib/session';
import { useLingui } from '@lingui/react';
const theme = createTheme({
    palette: {
        mode: "dark",
        secondary: {
            main: "#ffc90b"
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: (themeParam) => ({
                body: themeParam.palette.mode === 'dark' ? darkScrollbar() : null,
            }),
        },
    },
});
const LoginPage = () => {

    const { i18n } = useLingui()

    const [formLogin, setFormLogin] = React.useState({ username: "", password: "", keepLoggedIn: true });
    const [showPassword, setShowPassword] = useState(false);
    const { login, isAuthenticated } = useContext(UserContext);
    const navigate = useNavigate();
    const handleChangeForm = (event) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
        const field = event.target.name;
        setFormLogin(prevState => ({ ...prevState, [field]: value }))
    }
    const handleLogin = async () => {
        var res = await postSessionAsync(formLogin);
        if (res['type'] === "success") {
            await login(res['data'])
            navigate("/home")
        }
    };
    const handleShowPassword = () => {
        setShowPassword(true);
    }
    const handleHiddenPassword = () => {
        setShowPassword(false);
    }
    return (
        <>
            {isAuthenticated && <Navigate to={"/home"} />}
            <ThemeProvider theme={theme}>
                <Box sx={{ width: '100%', height: '100%' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            '& > :not(style)': {
                                m: 1,
                                width: 380,
                                height: 530,
                            },
                            height: "100%",
                            width: "100%",
                            background: "#121212",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <Paper sx={{ borderRadius: "25px" }}>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", padding: '2rem' }}>
                                <img src={NBg} alt="neo" style={{ width: "80%", height: "80%" }} />
                            </Box>
                            <Box
                                sx={{
                                    width: "100%",
                                    height: 300,
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    p: 5,
                                    gap: 4
                                }}
                            >
                                <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                                    <Typography variant='caption'>{i18n.t(`Usuário ou Email`)}</Typography>
                                    <TextField
                                        name="username"
                                        variant="standard"
                                        value={formLogin.username}
                                        fullWidth
                                        onChange={handleChangeForm}
                                        InputLabelProps={{ shrink: true }}
                                        size="medium"
                                    />
                                </Box>
                                <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                                    <Typography variant='caption'>{i18n.t(`Senha`)}</Typography>
                                    <TextField
                                        name="password"
                                        variant="standard"
                                        type={showPassword ? 'text' : 'password'}
                                        fullWidth
                                        value={formLogin.password}
                                        onChange={handleChangeForm}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onMouseDown={handleShowPassword}
                                                        onMouseLeave={handleHiddenPassword}
                                                    >
                                                        {showPassword ? <MdOutlineVisibilityOff /> : <MdOutlineVisibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Box>

                                <FormControlLabel
                                    control={
                                        <Switch checked={formLogin['keepLoggedIn']} />
                                    }
                                    onChange={handleChangeForm}
                                    label={i18n.t(`Manter conectado`)}
                                    name="keepLoggedIn"
                                />
                                <Button onClick={() => handleLogin()} type='submit' variant="contained" color="primary" sx={{ width: 125 }}                                >
                                    {i18n.t(`Acessar`)}
                                </Button>

                            </Box>
                            <Box sx={{ height: "50px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Typography>
                                    &copy; 2023 - {new Date().getFullYear()} nPLAN Supply Chain Planning
                                </Typography>
                            </Box>
                        </Paper >
                    </Box>
                </Box >
            </ThemeProvider >

        </>

    );
}

export default LoginPage;