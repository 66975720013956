import React from "react";
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { postTableButtonAsync } from "api/data";
import { t, Trans } from "@lingui/macro";


export default class AddButton extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            button: {
                name: "",
                procedureName: "",
                procedureSchema: "",
                tableEditionId: this.props.tableId
            }
        }
        this.handleSave.bind(this);
    }
    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        if (prevProps.tableId !== this.props.tableId) {
            this.setState(prevState => ({ ...prevState, button: { ...prevState.button, tableEditionId: this.props.tableId } }))
        }
    }
    async handleSave() {
        try {
            let data = await postTableButtonAsync(this.state.button);
            this.setState(prevState => ({ ...prevState, error: data.message }));
            if (data.type === "success")
                this.props.closeModal();
        } catch (err) {
            console.log(err);
            this.setState(prevState => ({ ...prevState, error: t`Falha na api, tente novamente` }))
            return;
        }
    }
    render() {
        return (
            <React.Fragment>
                {/* Main modal */}
                {!this.props.hidden &&
                    <>
                        <div
                            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                        >
                            <div className="relative w-full my-6 mx-auto max-w-md">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                                    {/*header*/}
                                    <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                        <h3 className="text-3xl font-semibold text-gray-600">
                                            <Trans>Novo Botão</Trans>
                                        </h3>
                                        <AiOutlineCloseCircle
                                            style={{ cursor: "pointer" }}
                                            className="ml-auto self-center text-black float-right w-8 h-8 cursor:pointer"
                                            onClick={() => this.props.closeModal()}
                                        >
                                        </AiOutlineCloseCircle>
                                    </div>
                                    {/*body*/}
                                    <div className="relative p-6 flex-auto">
                                        <form id='createTaskForm' className='flex-row w-full'>
                                            <div>
                                                <h5 className='text-red-600 my-2 text-center'>
                                                    {this.state.error}
                                                </h5>
                                                <h5 className='text-gray-600 my-2'>
                                                    <Trans>Nome</Trans>
                                                </h5>
                                                <input
                                                    className='border text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  '
                                                    name='name'
                                                    id='name'
                                                    value={this.state.button.name}
                                                    onChange={({ target: { value } }) => this.setState(prevState => ({ ...prevState, button: { ...prevState.button, name: value } }))}
                                                    autoFocus
                                                />
                                                <h5 className='text-gray-600 my-2'>
                                                    <Trans>Procedure</Trans>
                                                </h5>
                                                <input
                                                    className='border text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  '
                                                    name='procedure'
                                                    id='procedure'
                                                    value={this.state.button.procedureName}
                                                    onChange={({ target: { value } }) => this.setState(prevState => ({ ...prevState, button: { ...prevState.button, procedureName: value } }))} />
                                                <h5 className='text-gray-600 my-2'>
                                                    <Trans>Schema</Trans>
                                                </h5>
                                                <input
                                                    className='border text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  '
                                                    name='schema'
                                                    id='schema'
                                                    value={this.state.button.procedureSchema}
                                                    onChange={({ target: { value } }) => this.setState(prevState => ({ ...prevState, button: { ...prevState.button, procedureSchema: value } }))} />
                                            </div>
                                            {/*footer*/}
                                            <div className="flex items-center justify-evenly mt-3 pt-3 border-t border-solid border-blueGray-200 rounded-b">
                                                <button
                                                    className="modal-close-button"
                                                    onClick={() => this.props.closeModal()}>
                                                    <Trans>Fechar</Trans>
                                                </button>
                                                <button
                                                    className='modal-accept-button'
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        this.handleSave();
                                                    }}
                                                >
                                                    <Trans>Salvar</Trans>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                }

            </React.Fragment>

        )
    }
}