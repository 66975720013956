enum StoredProcedureNotification {
    ProgressNotification = 'ProgressNotification',
    Empty = 'Empty',
    Message = 'Message',
    Update = 'Update',
    Inputs = 'Inputs',
    Open = 'Open',
    Close = 'Close',
}
enum NeoAdvancedPlanningActions {
    ReloadScenarios = 'ReloadScenarios'
}

const _schedulerMessage = 'SchedulerMessage'

const _pipeline = `${_schedulerMessage}.Pipeline.`;
const _actions = `${_schedulerMessage}.Actions.`;

export const SchedulerMessage = {
    Pipeline: {
        ProgressNotification: `${_pipeline}${StoredProcedureNotification.ProgressNotification}`,
        Empty: `${_pipeline}${StoredProcedureNotification.Empty}`,
        Message: `${_pipeline}${StoredProcedureNotification.Message}`,
        Inputs: `${_pipeline}${StoredProcedureNotification.Inputs}`,
        Update: `${_pipeline}${StoredProcedureNotification.Update}`,
        Open: `${_pipeline}${StoredProcedureNotification.Open}`,
        Close: `${_pipeline}${StoredProcedureNotification.Close}`
    },
    Actions: {
        ReloadScenarios: `${_actions}${NeoAdvancedPlanningActions.ReloadScenarios}`,
    }
}

export interface ProgressMessage {
    title: string
    message: string
    percentage: string
    runningTime: string
    error: string
}