import { loadScenarioAppData } from 'api/helper'
import NeoLoader from 'components/Page/NeoLoader'
import Multiselect from 'multiselect-react-dropdown'
import { selectAvailableScenarios, selectCachedAvailableScenarios, selectCurrentScenarioId } from 'redux/AppScenarioState';
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { AsyncStatus } from 'redux/types';

function ScenarioDropdownSelector() {
    const dispatch = useAppDispatch();
    // const scenarios = useAppSelector(selectCachedAvailableScenarios)
    const scenarios = useAppSelector(selectAvailableScenarios)
    //selectAvailableScenarios
    const currentDatasetId = useAppSelector(selectCurrentScenarioId)

    // const loadAppDataStatus = useAppSelector((state) => state.appNavigationState.loadingState.loadAppDataStatus)
    const scenariosLoadStatus = useAppSelector((state) => state.appNavigationState.loadingAppComponent.Scenarios)
    if (scenariosLoadStatus !== AsyncStatus.READY) return <NeoLoader />

    const currentDataset = scenarios?.filter(x => x.id === currentDatasetId)[0]
    const onSelect = (selectedItem: { name: string, id: number }) => {
        handleOnSubmit(selectedItem[0].id)
    }

    const handleOnSubmit = (id: number) => {
        loadScenarioAppData(id, dispatch);
    }
    return (
        <>
            <Multiselect
                className='shrink w-full h-full'
                onSelect={onSelect}
                options={scenarios.map(x => ({ name: x.name, id: x.id }))}
                displayValue="name"
                //TODO Implement remove method, for when we deselect a scenario
                onRemove={() => console.warn("Implement Remove method")}
                selectedValues={currentDataset && [{ name: currentDataset?.name, id: currentDataset?.id }]}
                placeholder='Selecione um Cenário'
                style={{
                    chips: {
                        background: '#f59e0b'
                    },
                    searchBox: {
                        border: 'none',
                        'borderBottom': '1px solid #f59e0b',
                        'borderRadius': '0px'
                    }
                }}
                singleSelect
            />
        </>
    )
}

export default ScenarioDropdownSelector
