import { Dictionary } from "utils/types"
import { StockStatusType } from "./enums"
import { ItemAttributeData1, ItemAttributeData10, ItemAttributeData2, ItemAttributeData3, ItemAttributeData4, ItemAttributeData5, ItemAttributeData6, ItemAttributeData7, ItemAttributeData8, ItemAttributeData9, PlanningGridAllocationData } from "redux/AppScenarioState/dataTypes";
import { i18n } from '@lingui/core'
import { FilterCriteria } from "redux/AppScenarioState/FilterCriteria/types";
export const Months: (() => string)[] =
    [
        () => i18n.t(`Janeiro`),
        () => i18n.t(`Fevereiro`),
        () => i18n.t(`Março`),
        () => i18n.t(`Abril`),
        () => i18n.t(`Maio`),
        () => i18n.t(`Junho`),
        () => i18n.t(`Julho`),
        () => i18n.t(`Agosto`),
        () => i18n.t(`Setembro`),
        () => i18n.t(`Outubro`),
        () => i18n.t(`Novembro`),
        () => i18n.t(`Dezembro`),
    ]

// export const MonthsName =
//     [
//         `Janeiro`,
//         `Fevereiro`,
//         `Março`,
//         `Abril`,
//         `Maio`,
//         `Junho`,
//         `Julho`,
//         `Agosto`,
//         `Setembro`,
//         `Outubro`,
//         `Novembro`,
//         `Dezembro`
//     ]

export interface EditableEntity {
    isDirty: boolean
}
//ItemAttributes
export interface ItemAttributesBundleData {
    itemAttribute1: ItemAttributeData1[],
    itemAttribute2: ItemAttributeData2[],
    itemAttribute3: ItemAttributeData3[],
    itemAttribute4: ItemAttributeData4[],
    itemAttribute5: ItemAttributeData5[],
    itemAttribute6: ItemAttributeData6[],
    itemAttribute7: ItemAttributeData7[],
    itemAttribute8: ItemAttributeData8[],
    itemAttribute9: ItemAttributeData9[],
    itemAttribute10: ItemAttributeData10[]
}
export interface ItemAttributesBundle {
    itemAttribute1: Dictionary<ItemAttributeData1>,
    itemAttribute2: Dictionary<ItemAttributeData2>,
    itemAttribute3: Dictionary<ItemAttributeData3>,
    itemAttribute4: Dictionary<ItemAttributeData4>,
    itemAttribute5: Dictionary<ItemAttributeData5>,
    itemAttribute6: Dictionary<ItemAttributeData6>,
    itemAttribute7: Dictionary<ItemAttributeData7>,
    itemAttribute8: Dictionary<ItemAttributeData8>,
    itemAttribute9: Dictionary<ItemAttributeData9>,
    itemAttribute10: Dictionary<ItemAttributeData10>
}

export interface ItemAttributesCustomBundle<TType> {
    ItemAttribute1: Dictionary<TType>,
    ItemAttribute2: Dictionary<TType>,
    ItemAttribute3: Dictionary<TType>,
    ItemAttribute4: Dictionary<TType>,
    ItemAttribute5: Dictionary<TType>,
    ItemAttribute6: Dictionary<TType>,
    ItemAttribute7: Dictionary<TType>,
    ItemAttribute8: Dictionary<TType>,
    ItemAttribute9: Dictionary<TType>,
    ItemAttribute10: Dictionary<TType>
}

export interface ItemAttribute {
    id: number,
    name: string
}

//Capacity
export interface Capacity {
    scenarioId: number
    periodId: number
    resourceId: number
    totalAvailableHours: number
}
export interface CapacityDetails extends Capacity {
    scenario: CapacityDetails
    period: PeriodDetails
    resource: ResourceDetails
    totalAvailableHours: number
}

//DemandDateRanges
export interface DemandDateRanges {
    initialDate: Date,
    finalDate: Date
}

//Item
export interface Item {
    id: number
    itemCode: string
    itemDescription: string
    level: number
    leadTime: number
    weight: number
    itemAttribute1Id?: number
    itemAttribute2Id?: number
    itemAttribute3Id?: number
    itemAttribute4Id?: number
    itemAttribute5Id?: number
    itemAttribute6Id?: number
    itemAttribute7Id?: number
    itemAttribute8Id?: number
    itemAttribute9Id?: number
    itemAttribute10Id?: number
    itemPrice: number
    itemCost: number
    storageConversionFactor: number
    storageResourceId: number
    imageURL: string
    active: boolean
    createdBy: string
    createdOn: Date
    lastUpdatedOn: Date
    itemPlannerId: number
    itemStockGroupId: number
}
export interface ItemDetails extends Item {
    storageResource: StorageResource
}
//ItemResource
export interface ItemResource {
    id: number
    itemId: number
    resourceId: number
    resSpecificSetupTime?: number
    resSpecificRatePerHour?: number
    resSpecificMinimumReorderQuantity?: number
    resSpecificReorderMultiple?: number
    itemOperationId?: number
}

export interface ItemResourceDetails extends ItemResource {
    item: Item
    resource: Resource
    itemOperation?: ItemOperation
}
//OperationType
export interface OperationType {
    id: number,
    name: string,
    code: string
}
//ItemOperation
export interface ItemOperation {
    itemId: number
    itemDescription: number
    operationNo: number
    operationDescription?: number
    resourceGroup?: number
    operationType?: number
    setupTime?: number
    rateperHour?: number
    manHour?: number
    operators?: number
    alternativeSeq?: number
}
export interface ItemOperationDetails extends ItemOperation {
    item?: Item
    resourceGroup?: number
    operationType?: number
}

//Period
export interface Period {
    id: number
    scenarioId: number
    code: number
    periodTypeId: number
    description: string
    startTime: string
    endTime: string
    periodGroup: number
    periodGroupDescription: string
    rank: number
    bucketSize: number
    frozen: boolean
}

export interface PeriodDetails extends Period {
    scenario: ScenarioDetails
    periodType: PeriodType
}

//CompleteStructures
export interface CompleteStructure {
    itemMaster: number,
    parentId: number,
    parentDescription: number,
    parentStockGroup: number,
    childId: number,
    childDescription: number,
    childStockGroup: number,
    proportionalQuantity: number,
    masterProportionalQuantity: number
    stringBOM: string,
    level: number,
}

//PeriodType
export interface PeriodType {
    id: number,
    name: string,
    description?: string,
}

//ItemPlanner
export interface ItemPlanner {
    id: number,
    name: string,
}

//ItemStockGroup
export interface ItemStockGroup {
    id: number,
    name: string,
}

//PlanningGrid
export interface PlanningGrid {
    id: number
    scenarioId: number
    planningStatusId: number
    locked?: boolean
    periodId: number
    itemId: number
    resourceGroupId?: number
    setupTime?: number
    ratePerHour?: number
    leadTime?: number
    level?: number
    itemAttribute1?: ItemAttribute
    itemAttribute2?: ItemAttribute
    itemAttribute3?: ItemAttribute
    itemAttribute4?: ItemAttribute
    itemAttribute5?: ItemAttribute
    itemAttribute6?: ItemAttribute
    itemAttribute7?: ItemAttribute
    itemAttribute8?: ItemAttribute
    itemAttribute9?: ItemAttribute
    itemAttribute10?: ItemAttribute
    itemPrice?: number
    itemCost?: number
    storageConversionFactor?: number
    storageResourceId?: number
    demandForecast?: number
    demandSalesOrder?: number
    demand?: number
    orders?: number
    openingStock?: number
    closingStock?: number
    minDaysOfCover?: number
    targetDaysOfCover?: number
    minStock?: number
    targetStock?: number
    maxStock?: number
    safetyStock?: number
    minimumReorder?: number
    multipleReorder?: number
    mps?: number
    totalDaysOfCover?: number
}
export interface PlanningGridDetails extends PlanningGrid {
    scenario: ScenarioDetails
    planningStatus: PlanningStatus
    period: PeriodDetails
    item: ItemDetails
    resourceGroup: ResourceGroupDetails

    minStockByDays: number;
    isMinStockByDay: boolean;
    isTargetStockByDay: boolean;
    targetStockByDays: number;
    stockStatus: StockStatusType;
}
//PlanningGridAllocation
export interface PlanningGridAllocation {
    id: number
    planningGridId: number
    scenarioId: number
    resourceId: number
    itemOperationId: number
    totalProcessTime: number
    totalSetupTime: number
    productionTypeId: number
    mpsQuantity: number
    locked: number
    planningGridResourcesIds: number[]
}
//TODO gambiarra
export interface PlanningGridAllocationDetails extends PlanningGridAllocationData {
    // planningGrid: PlanningGridDetails
    // scenario: ScenarioDetails
    // resource: ResourceDetails
    productionType: ProductionType
    planningGridResources: PlanningGridResource[]
    itemOperation: ItemOperation
    //TODO gambiarra
    totalAllocatedTime: number
    partialProcessTime?: number
}
//PlanningGridResource
export interface PlanningGridResource {
    id: number
    planningGridId: number
    scenarioId: number
    resourceId: number
    planningGridAllocationId: number
    setupTimePerResource: number
    ratePerHourPerResource: number
    minimumReorderPerResource: number
    multipleReorderPerResource: number
}
export interface PlanningGridResourceDetails extends PlanningGridResource {
    planningGrid: PlanningGridDetails
    scenario: ScenarioDetails
    resource: ResourceDetails
    planningGridAllocation: PlanningGridAllocationDetails
}

//PlanningStatus
export interface PlanningStatus {
    code: string
}

//ProductionType
export interface ProductionType {
    code: string,
    id: number
}

//ResourceAtributes
interface ResourceAttributeBase {
    code: string,
    name?: string,
    value?: number

}
export interface ResourceAttribute1 extends ResourceAttributeBase { }
export interface ResourceAttribute2 extends ResourceAttributeBase { }
export interface ResourceAttribute3 extends ResourceAttributeBase { }
export interface ResourceAttribute4 extends ResourceAttributeBase { }
export interface ResourceAttribute5 extends ResourceAttributeBase { }
//Resource
export interface Resource {
    id: number
    name: string
    finiteCapacity: boolean
    efficiency: number
    setupRestriction: boolean
    resourceGroupId: number
    setupAttribute?: number
    setupLikeToLike?: number
    setupUnlikeToUnlike?: number
    resourceAttribute1Id?: number
    resourceAttribute2Id?: number
    resourceAttribute3Id?: number
    resourceAttribute4Id?: number
    resourceAttribute5Id?: number
    code?: string
    description?: string
}
export interface ResourceDetails extends Resource {
    resourceGroup: ResourceGroupDetails
    resourceAttribute1?: ResourceAttribute1
    resourceAttribute2?: ResourceAttribute2
    resourceAttribute3?: ResourceAttribute3
    resourceAttribute4?: ResourceAttribute4
    resourceAttribute5?: ResourceAttribute5
}

//ResourceGroup
export interface ResourceGroup {
    id: number
    name: string
    resourcesIds: number[]
}
export interface ResourceGroupDetails extends ResourceGroup {
    resources: Resource[]
}

//Scenario
export interface Scenario {
    id: number
    name: string
    periodTypeId: number
    scenarioStatusId: number
    scenarioCategoryId: number
    finiteProductionCapacity: boolean
    finiteSecondaryConstraintCapacity: boolean
    finiteStorageCapacity: boolean
    finiteLaborCapacity: boolean
    multiLevelSynchronism: boolean
    startDate: string
    frozenEndDate: string
    endDate: string
    isSummarizedDataUpToDate: boolean
    createdBy: string
    createdOn: string
    lastUpdatedOn: string
}
export interface ScenarioDetails extends Scenario {
    periodType: PeriodType
    scenarioStatus: ScenarioStatus
    ScenarioCategory: ScenarioCategory
}
export interface ScenarioSimplified {
    Id: number
    Name: string
    CreatedBy: string
    CreatedOn: Date
    LastUpdatedOn?: Date
}

//ScenarioCategory
export interface ScenarioCategory {
    id: number,
    name: string,
    description?: string
}

//ScenarioCriteria
export interface ScenarioCriteria {
    id: number
    name: string
    active: boolean
    displaySequence: number
}

//ScenarioPriority
export interface ScenarioPriority {
    scenarioId: number
    scenarioCriteriaId: number
    value: number
}
export interface ScenarioPriorityDetails extends ScenarioPriority {
    scenario: ScenarioDetails
    scenarioCriteria: ScenarioCriteria
}

//ScenarioStatus
export interface ScenarioStatus {
    id: number,
    name: string,
    description: string
    color: string
}

//ScenarioSummarized
export interface ScenarioSummarized {
    id: number
    scenarioId: number
    period: number
    // periodId: number
    demand: number
    mps: number
    revenue: number
    fixedCost: number
    variableCost: number
    totalDaysOfCover: number
    closingStock: number
    storageUnits: number
    storageResource: string
    // storageResourceId: number
    stockStockout: number
    stockStockoutCost: number
    stockRisk: number
    stockRiskCost: number
    stockExcess: number
    stockExcessCost: number
    idlenessCost: number
    usedPercentage: number
    totalCost: number
    totalProfit: number
    lastUpdatedOn: Date
}
export interface ScenarioSummarizedDetails extends ScenarioSummarized {
    scenario: ScenarioDetails
    // period: PeriodDetails
    // storageResource: StorageResource
}

//StockStatus
export interface StockStatus {
    code: string
    name: string
    sequence: number
    ruleFinalStock: string
    color: string
    ruleInitialStock: string
    stockProjectColor: string
    stockProjectName: string
    displayKPIName: string
}

export interface AttributeList {
    attributeCode: string
    attributeName: string
}
export interface FixedFilterData {
    filterId: number
    label: () => string
    filterCriteria: FilterCriteria
    placeholder: () => string
    checked: boolean | undefined
}

//StorageResource
export interface StorageResource {
    name: string
    totalCapacity: number
}

/** @deprecated This interface needs to be updated. */
export interface PaginationData<Tdata> {
    pageNumber: number,
    pageSize: number,
    firstPage: string,
    lastPage: string,
    totalPages: number,
    totalRecords: number,
    nextPage: string,
    data: Tdata
}

export interface ScriptParameter {
    order: number,
    data: any
}
/** @deprecated Use `ScenarioSummarized` instead */
export interface SumarizedDataset {
    id: number,
    demand: number,
    mps: number,
    revenue: number,
    fixedCost: number,
    variableCost: number,
    totalDaysOfCover: number,
    closingStock: number,
    storageUnits: number,
    storageResource: string,
    stockStockoutCost: number,
    stockStockout: number,
    stockRisk: number,
    stockRiskCost: number,
    stockExcess: number,
    stockExcessCost: number,
    idlenessCost: number,
    usedPercentage: number,
    totalCost: number,
    totalProfit: number,
}