import React from 'react'
import { Button, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from '@mui/material';
import ManageRole from './components/ManageRole'
import ManagePermission from './components/ManagePermission';
import MenuContext from './components/MenuContext';
import { getRoleAsync, getRolesAsync } from 'api/user/lib/role';
function RoleList() {
    const [roleOpen, setRoleOpen] = React.useState(false);
    const [permissionOpen, setPermissionOpen] = React.useState(false);
    const [roles, setRoles ] = React.useState<any>([]);
    const [filter, setFilter] = React.useState("");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [currentEditing, setCurrentEditing] = React.useState<any>(null);
    const [anchorEl, setAnchorEl] = React.useState<any>(null)

    const handleOpenRole = () => {
        setRoleOpen(true)
    }

    const handleCloseRole = () => {
        setRoleOpen(false)
    }

    const handleAdd = () => {
        setCurrentEditing(null);
        handleOpenRole();
    }
    const handleEdit = (event) => {
        var id = event.target.dataset.id;
        if(id == null) return;
        setCurrentEditing(id)
        handleOpenRole();
    }

    const handleOpenPermission = () => {
        setPermissionOpen(true)
    }
    const handleClosePermission = () => {
        setPermissionOpen(false)
    }

    const handleEditPermission = (event) => {
        var id = event.target.dataset.id;
        if (id == null) return;
        setCurrentEditing(id)
        handleOpenPermission();
    }

    const handleOpenContextMenu = (event) => {
        event.preventDefault();
        setAnchorEl(
            anchorEl === null
                ? {
                    mouseX: event.clientX,
                    mouseY: event.clientY,
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                // Other native context menus might behave different.
                // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        );
    };
    
    const handleCloseContextMenu = () => {
        setAnchorEl(null);
    }

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
    setPage(newPage);
    };

    function getUsers() : Array<any> {
        if(!filter || filter === "")
            return roles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        return roles.filter(u =>
                u.firstName?.toLowerCase().includes(filter)
            ||  u.lastName?.toLowerCase().includes(filter)
            ||  u.username?.toLowerCase().includes(filter)
            ||  u.email?.toLowerCase().includes(filter)
            ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const handleRefreshData = React.useCallback(async () => {
        const res = await getRolesAsync()
        if(res['type'] === 'success') 
            setRoles(res['data']);
    },[])
    React.useEffect(() => {
        handleRefreshData()
    }, [handleRefreshData])
    
    const handleDelete = (event) => {
        // deleteRoleAsync(id).then(res => {
            
        //     if(res.type === "success") {
        //         notifySuccess({message: res.message })
        //         refreshData()
        //     }
                
        // })
    }
    return (
        <React.Fragment>
            <ManageRole open={ roleOpen } onClose={ handleCloseRole } currentEditing={ currentEditing } onRefreshData={ handleRefreshData }/>
            <MenuContext anchorEl={anchorEl} onAddGroup={handleAdd} onRefreshData={handleRefreshData} onClose={handleCloseContextMenu} />
            <ManagePermission open={permissionOpen} onClose={handleClosePermission} currentEditing={currentEditing}/>
            <Paper variant="outlined" onContextMenu={handleOpenContextMenu}>
                <TableContainer sx={ { height: "calc(100vh - 140px)", width: '100%' } } onContextMenu={ handleOpenContextMenu }>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead sx={{bgcolor: "#FFF8E1", height: "70px"}} >
                            <TableRow>
                                <TableCell >
                                    Nome
                                </TableCell>
                                <TableCell title="Se ativo o usuário terá acesso ao sistema independentemente das permissões">
                                    Admin
                                </TableCell>
                                <TableCell align="right" >
                                    {/* Procurar:  */}
                                </TableCell>
                                <TableCell >
                                    {/* <TextField variant="standard" value={filter} onChange={({target: { value }}) => setFilter(value)}/> */}
                                </TableCell>
                                <TableCell >
                                    {/* <TextField variant="standard" value={filter} onChange={({target: { value }}) => setFilter(value)}/> */}
                                </TableCell>
                                <TableCell >
                                    {/* <TextField variant="standard" value={filter} onChange={({target: { value }}) => setFilter(value)}/> */}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {getUsers()?.map((role, index) => 
                                <TableRow key={index} style={{backgroundColor: index % 2 === 0 ? "white" : "rgb(247, 247, 245)"}}>
                                    <TableCell>
                                        {role.name}
                                    </TableCell>
                                    <TableCell title="Se ativo o usuário terá acesso ao sistema independentemente das permissões">
                                        <Switch checked={role.admin} />
                                    </TableCell>
                                    <TableCell>
                                        { !role.admin && <Button variant="contained" data-id={role['id']} onClick={ handleEditPermission }>Permissões</Button>}
                                    </TableCell>
                                    <TableCell>
                                        <Button variant="contained" data-id={role['id']} onClick={handleEdit}>Editar</Button>
                                    </TableCell>
                                    <TableCell>
                                        <Button variant="contained" data-id={role['id']} onClick={handleDelete} color='error'>Deletar</Button>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    component="span"
                    count={ roles.length }
                    page={ page }
                    onPageChange={ handleChangePage }
                    rowsPerPage={ rowsPerPage }
                    onRowsPerPageChange={ handleChangeRowsPerPage }
                />
            </Paper>
        </React.Fragment>
    )
}

export default RoleList