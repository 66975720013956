import AutoSizer, { Size } from "react-virtualized-auto-sizer";
import { FixedSizeList } from 'react-window'
import ComparisonLabel from './components/ComparisonLabel';
import ScenarioPeriodColumn from './components/ScenarioPeriodColumn';
import { MemoizedSumarizedPanel } from "./components/SumarizedPanel";
import { ScenarioSummarizedData } from "redux/AppScenarioState/dataTypes";
import { useAppSelector } from "redux/hooks";

interface Props {
    scenarioSummarizedData: ScenarioSummarizedData[],
    scenarioName: string,
    storageResource: string
}
function ComparisonPanelRow(props: Props) {
    const { scenarioSummarizedData, scenarioName, storageResource } = props;
    //TODO gambiarra pra mostrar periodos "corretamente"
    const minifiedPeriods = useAppSelector(state => state.appScenarioState.minifiedPeriods);

    return (
        <>
            <div className='flex flex-row h-auto w-full'>
                <ComparisonLabel />
                <MemoizedSumarizedPanel scenarioName={scenarioName} scenarioSummarizedData={scenarioSummarizedData} />
                <div className='flex flex-col w-full h-auto'>
                    <div className='h-6 w-auto bg-gray-400 text-center ml-1 border-solid border border-gray-200'>
                        {scenarioName}
                    </div>
                    <AutoSizer>
                        {(size: Size) => (
                            <FixedSizeList
                                className="no-scrollbars"
                                height={size.height - 24}
                                itemCount={scenarioSummarizedData.length}
                                itemSize={170}
                                width={size.width}
                                layout="horizontal"
                                itemData={{ scenarioSummarizedData, minifiedPeriods }}
                            >
                                {ScenarioPeriodColumn}
                            </FixedSizeList>
                        )}
                    </AutoSizer>
                </div>
            </div>
        </>
    )
}

export default ComparisonPanelRow
