import React from 'react'

export default function TableEditionDataRow(props: any) {
    
    return (
        <React.Fragment>
            <div style={props.style}>
                <div style={{width: `${props.totalWidth}px`, position:"relative", height:"35px"}}>
                    {props.children}
                </div>
            </div>
        </React.Fragment>
    )
}