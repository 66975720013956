import { Box, ClickAwayListener, MenuItem, Select, Switch, TextField } from '@mui/material';
import { putTableDataAsync } from 'api/data';
import React, { useEffect } from 'react'
import { notifyError } from 'utils/notification';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { SketchPicker } from 'react-color';

export default function TableEditionDataCell(props: any) {
    const [editing, setEditing ] = React.useState(false);
    const [value, setValue] = React.useState<any>()
    const [viewType, setViewType] = React.useState("text");
    function handleEdit() {
        if (props.editionMode && !editing && props.column.editable) 
            setEditing(true)
    }
    function handleValue(event) {
        setValue(event.target.value)
    }
    const handleColorValue = (event) => {
        setValue(event['hex'].toUpperCase())
    }
    async function handleSaveChanges(event?: any) {
        setEditing(false)
        if(!Array.isArray(props.colsPK) || props.colsPK.length < 1) {
            notifyError({message: "Verifique a chave primária da tabela"});
            return;
        }
        var newValue = value;
        if(event != null)
            newValue = event.target.type === 'checkbox' ? event.target.checked : event.target.value
        var originalValue = newValue;
        let PK = "";
        props.colsPK.map(col => {
            PK += `${col.field},${col.sqlType},${props.row[col.field]};`
            return col;
        })
        if (props.column.sqlType === "datetime" && props.column.viewType === "data") {
            newValue = newValue.toString().replaceAll("-", "");
            originalValue = new Date(value.split("-")[0], value.split("-")[1] - 1, value.split("-")[2])
        }            
        if (props.editionAllMode && props.column.editorAll) {
            props.handleEditAll(newValue.toString(), props.column, props.colsPK)
            return;
        }
        var res = await putTableDataAsync({
            value: newValue.toString(),
            columnId: props.column.id,
            pks: PK.slice(0, -1)
        })
        if(res.type === "success") {
            props.row[props.column.field] = originalValue;
            props.setData(prevState => ([...prevState]));
        }
    }
    useEffect(() => {
        if (props.column.sqlType === "datetime" && props.viewType === "data" && props.row[props.column.field] != null) {
            // Cria um objeto Date a partir da string
            let data = new Date(props.row[props.column.field]);
            // Formata a data em uma string no formato YYYY-MM-DD
            let dataFormatada = data.toISOString().substring(0, 10);
            setValue(dataFormatada)
        }
        else
            setValue(props.row[props.column.field])
    }, [props.row[props.column.field]])
    useEffect(() => {
        switch(props.column.viewType) {
            case "data":
                setViewType("date");
                break;
            case "datahora":
                setViewType("datetime-local");
                break;
            case "checkbox":
                setViewType("bool");
                break;
            case "color":
                setViewType("color");
                break
            default:
                setViewType("text");
                break;
        }
    },[props.column.viewType])
    return (
        <div style={props.style} onClick={handleEdit}>
            {/* Editando */}
            {editing && props.editionMode && !props.column.foreignKey && viewType === "bool" &&
                <ClickAwayListener onClickAway={() => setEditing(false)}>
                    <div style={{ height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", border: "2px solid rgb(234, 179, 8)", boxShadow: "inset 0 0 10px rgba(234, 179, 8, 0.2)" }}>
                        <Switch checked={value} onChange={handleSaveChanges} />
                    </div>
                </ClickAwayListener>
            }
            { editing && props.editionMode && !props.column.foreignKey && viewType === "color" &&
                <div>
                    <Box style={{position: 'absolute', zIndex: 2}}>
                        <Box sx={ { position: 'fixed', left: 0, top: 0, right: 0, bottom: 0 } } onClick={() => handleSaveChanges() } />
                        <SketchPicker color={ value ?? "#000000" } onChange={ handleColorValue } />
                    </Box> 
                </div>

            }
            {editing && props.editionMode && !props.column.foreignKey && viewType !== "bool" && viewType !== "color" &&
                <React.Fragment>
                    <ClickAwayListener onClickAway={() => setEditing(false)}>
                        <TextField  
                            sx={{
                                "& .MuiInputBase-root": {
                                    height: "35px"
                                }
                            }} 
                            autoFocus 
                            fullWidth 
                            style={{height: "100%"}} 
                            value={value} 
                            onChange={handleValue} 
                            onKeyDown={({key}) => {
                                if(key === "Enter"){
                                    setEditing(false)
                                    handleSaveChanges();
                                }
                                if(key === "Escape") {
                                    setEditing(false)
                                    setValue(props.row[props.column.field]);
                                }
                            }}
                            type={viewType}
                        />
                    </ClickAwayListener>
                </React.Fragment>
            }
            {
                editing && props.editionMode && props.column.foreignKey && viewType !== "bool" &&
                <React.Fragment>
                    <Select
                        sx={{
                            "& .MuiInputBase-root": {
                                height: "35px"
                            }
                        }}
                        autoFocus
                        fullWidth
                        style={{ height: "100%" }}
                        value={value}
                        onChange={handleSaveChanges}
                        onClose={() =>  setEditing(false)}
                        open={true}
                    >
                        {props.column.enableNull && 
                            <MenuItem value="null">{props.column.nullText || "NULL"}</MenuItem>
                        }
                        {props.foreignFields[props.column.field].map(element => 
                            <MenuItem key={element.id} value={element.id}>{element.name}</MenuItem>    
                        )}
                    </Select>
                </React.Fragment>
            }
            {/* Renderizando */}
            {(!editing || !props.editionMode) && 
                <div style={{padding: "0 4px", height: "100%", width: "100%", display: "flex", flexDirection: "row", alignItems: "center"}}>
                    {props.column.render(props.row, props.column, props.foreignFields)}
                </div>
            }
        </div>
    )
}