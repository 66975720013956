import { yupResolver } from "@hookform/resolvers/yup";
import { notifyLoading, notifyPromise } from "utils/notification";
import { createScriptsAsync } from "api/scheduler/lib/script";
import { Script } from "api/scheduler/types";
import NeoSwitch from "components/NeoSwitch";
import { Controller, useForm } from "react-hook-form";
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { setModalState } from "redux/AppNavigationState";
import { ModalType } from "redux/AppNavigationState/types";
import { getScriptsDataAsync } from "redux/AppSchedulerState";
import { useAppDispatch } from "redux/hooks";
import * as yup from 'yup';
import { t, Trans } from "@lingui/macro";

const scriptSchema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().required(),
})

export default function ScriptCreateModal() {
    const dispatch = useAppDispatch();
    const { getValues, setValue, register, handleSubmit, formState: { errors }, control } = useForm<Script>({ resolver: yupResolver(scriptSchema) });

    const handleOnSubmit = (scriptData: Script) => {
        createScriptsAsync({ body: scriptData });
        notifyLoading({
            message: t({ message: 'Criando novo Script' }),
            timer: 1000,
            action: () => {
                dispatch(getScriptsDataAsync());
                onCloseModal()
            }
        })
    }

    const onCloseModal = () => {
        dispatch(setModalState(ModalType.Closed))
    }

    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative w-full my-6 mx-auto max-w-md">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex  items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                            <h3 className="text-3xl font-semibold text-gray-600">
                                <Trans>Criar novo Script</Trans>
                            </h3>
                            <AiOutlineCloseCircle
                                className="ml-auto self-center text-black float-right w-8 h-8"
                                onClick={onCloseModal}
                            >
                            </AiOutlineCloseCircle>
                        </div>
                        {/*body*/}
                        <div className="relative p-6 flex-auto">
                            <form id='createScriptForm' className='flex-row w-full' onSubmit={handleSubmit(handleOnSubmit)}>
                                <div>
                                    <h5 className='text-gray-600 my-2'>
                                        <Trans>Nome do Script</Trans>:
                                    </h5>
                                    <input
                                        className='flex w-full border text-black appearance rounded-lg'
                                        maxLength={30}
                                        {...register('name')}
                                        type='text'
                                        name='name'
                                        id='name'
                                        placeholder='Nome do Script'
                                    />
                                    {errors.name && "Nome do Script é necessário."}
                                </div>
                                <div>
                                    <h5 className='text-gray-600 my-2'>
                                        <Trans>Descrição do Script</Trans>:
                                    </h5>
                                    <input
                                        className='flex w-full border text-black appearance rounded-lg'
                                        maxLength={50}
                                        {...register('description')}
                                        type='text'
                                        name='description'
                                        id='description'
                                        placeholder='Descrição do Script'
                                    />
                                    {errors.description && "Descrição do Script é necessária."}
                                </div>
                                <div>
                                    <h5 className='text-gray-600 my-2'>
                                        <Trans>Ativo</Trans>:
                                    </h5>
                                    <Controller
                                        name="active"
                                        control={control}
                                        defaultValue={true}
                                        rules={{ required: true }}
                                        render={({ field }) => <NeoSwitch checked={Boolean(field.value)} onChange={() => setValue('active', !field.value)} />
                                        }
                                    />
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-evenly mt-3 pt-3 border-t border-solid border-blueGray-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                                        onClick={onCloseModal}
                                    >
                                        <Trans>Fechar</Trans>
                                    </button>
                                    <button className='w-20 h-10 font-semibold text-lg rounded-full text-white items-center justify-center bg-yellow-400 focus:outline-none'>Salvar</button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    );
}