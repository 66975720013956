import { ToolTipComponentReactNodeChildren } from 'pages/StockPolicyPage/components/TooltipComponentNodeChildren'
import { ToolTipComponentOnlyText } from 'pages/StockPolicyPage/components/TooltipComponentOnlyText'
import React, { useRef } from 'react'

interface Props {
    disabled?: boolean
    blockOnClick?: boolean
    children?: React.ReactNode
    onClick?: () => void,
    form?: string,
    className?: string
    toolTip?: boolean
    toolTipText?: string
    toolTipBgColor?: string
}

function NeoActionButton(props: Props) {
    const wasPressed = useRef(false)

    const onLocalClick = () => {
        if (props.onClick && !wasPressed.current) {
            if (props.blockOnClick) {
                wasPressed.current = true;
            }
            props.onClick()
        }
    }
    if (props.toolTip) {
        return (
            <ToolTipComponentReactNodeChildren tooltipText={props.toolTipText as string} color={props.toolTipBgColor as string} >
                <button form={props.form} disabled={props.disabled} onClick={onLocalClick} className={'flex-shrink-0 cursor-pointer w-fit h-10 rounded-full m-2 px-4 bg-yellow-500 items-center text-white text-lg text-center font-bold hover:shadow-lg ' + props.className}>
                    {props.children}
                </button>
            </ToolTipComponentReactNodeChildren>
        )
    }
    return (
        <button form={props.form} disabled={props.disabled} onClick={onLocalClick} className={'flex-shrink-0 cursor-pointer w-fit h-10 rounded-full m-2 px-4 bg-yellow-500 items-center text-white text-lg text-center font-bold hover:shadow-lg ' + props.className}>
            {props.children}
        </button>
    )
}

export default NeoActionButton