import { useAppSelector } from "redux/hooks";
import { ShowingItemsProps } from "../types";
import { EditableCards } from "../components/EditableCards";
import { InventorySummaryCards } from "../components/InventorySummaryCards";
import { useEffect } from "react";

export function CardsContainer() {
    const { stockPolicyAxes, stockEditableTableValidation, showingItemsRedux } = useAppSelector(
        (state) => state.appStockState
    );



    return (
        <div className="flex w-full  h-full" style={{ marginTop: '-36px' }}>
            <div
                className="flex w-10  p-2 justify-center items-center"
                style={{
                    transform: "rotate(-90deg)",
                }}
            >
                <p className="font-bold font-md p-2 underline text-gray-800">
                    {stockPolicyAxes.yAxis()}
                </p>
            </div>
            <div className="flex  flex-col w-full justify-around mt-4">
                {stockEditableTableValidation.isEditable === false ? (
                    <InventorySummaryCards items={showingItemsRedux.summaryShowingItems} />
                ) : (
                    <EditableCards items={showingItemsRedux.segmentationShowingItems} />
                )}
            </div>
        </div>
    );
}
