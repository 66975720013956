import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@fontsource/roboto';
import { store } from 'redux/store'
import { Provider } from 'react-redux'
import { ProSidebarProvider } from 'react-pro-sidebar';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';
import { CssBaseline } from '@mui/material';
// if (process.env.NODE_ENV === 'development') {
//   const {whyDidYouUpdate} = require('why-did-you-update');
//   whyDidYouUpdate(React);
// }
const container = document.getElementById('root');
const root = createRoot(container as Element); // createRoot(container!) if you use TypeScript
// globalThis.IS_REACT_ACT_ENVIRONMENT = true;
const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(234, 179, 8)",
      contrastText: "white"
    },
  }
});
root.render(

  <Provider store={store}>
    <ProSidebarProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </ProSidebarProvider>
  </Provider>


);
