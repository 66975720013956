import DropDownTreeSelectorContainer from "./DropDownTreeSelectorContainer";
import { FilterCriteriaToSelector } from "redux/AppScenarioState/FilterCriteria";
import { FilterCriteria } from "redux/AppScenarioState/FilterCriteria/types";
import { AttributeList } from "api/data/types";
import { useAppSelector } from "redux/hooks";
import React from "react";
import { selectFilterAttributesOptions } from "redux/AppScenarioState/FilterCriteria/selectors";

export function AttributesDropDownContainer() {
    const selectedAttributes = useAppSelector(selectFilterAttributesOptions)
    return (
        <>
            {selectedAttributes.map((value: AttributeList, index) => (
                <DropDownTreeSelectorContainer
                    key={index}
                    filterCriteria={FilterCriteria[value.attributeCode]}
                    selector={FilterCriteriaToSelector(
                        FilterCriteria[value.attributeCode]
                    )}
                    placeholder={value.attributeName}
                />
            ))}
        </>
    )
}

export const AttributesDropDownContainerMemoized = React.memo(AttributesDropDownContainer)
