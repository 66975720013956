import React, { useState, FormEvent } from 'react'
import { IoMdClose } from 'react-icons/io';
// import LogoHeader from 'assets/images/logo-login.png';
import LoginImage from 'assets/images/cover/bg-login.jpg';
import LoginPanel from './components/Login/LoginPanel'
import { Trans } from '@lingui/macro';
import { Box, Container } from '@mui/material';

//TODO Utilizar React-Hook-Forms no loginPage
function LoginPage() {
    const [isRecover, setIsRecover] = useState<boolean>(false);
    return (
        <Box sx={{width: '100%', height: '100%'}}>
            { !isRecover && <LoginPanel /> }
        </Box>
        
    )
}

export default LoginPage