import { configureStore, Action, ThunkAction } from '@reduxjs/toolkit';
import appScenarioStateReducer from 'redux/AppScenarioState';
import appNavigationReducer from 'redux/AppNavigationState';
import appSchedulerReducer from 'redux/AppSchedulerState';
import appSumarizedReducer from 'redux/AppSumarizedState';
import appStockReducer from 'redux/AppStockState';
import appNotificationReducer from 'redux/AppNotificationState';

// import logger from 'redux-logger'

import { schedulerSignalConnector } from './Connector';
import { outboundSignalConnector } from './SignalRConnector/Outbound';
import { appNotificationSlice } from './AppNotificationState';
// const immutableInvariantMiddleware = createImmutableStateInvariantMiddleware({
//     ignoredPaths: ['ignoredPath', 'ignoredNested.one', 'ignoredNested.two'],
// })

export const store = configureStore({
    reducer: {
        appNavigationState: appNavigationReducer,
        appScenarioState: appScenarioStateReducer,
        appSchedulerState: appSchedulerReducer,
        appSumarizedState: appSumarizedReducer,
        appStockState: appStockReducer,
        appNotificationState: appNotificationReducer,
    },
    middleware: [schedulerSignalConnector, outboundSignalConnector],
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
    devTools: process.env.NODE_ENV !== 'production',
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
