import React from 'react'
import { PropagateLoader } from 'react-spinners'

function NeoLoader() {
    return (
        <div className='flex w-full h-full items-center place-content-center'>
            <PropagateLoader loading={true} color={'#f59e0b'} />
        </div>
    )
}

export default NeoLoader
