import React, { useState, useEffect, useRef } from 'react';

interface TooltipTextProps {
    text: string;
    width: number;
}

const TooltipText: React.FC<TooltipTextProps> = ({ text, width }) => {
    const [hasTooltip, setHasTooltip] = useState(false);
    const textRef = useRef<HTMLParagraphElement>(null);
    useEffect(() => {
        const span = document.createElement('span');
        span.innerHTML = text;
        span.style.position = 'absolute';
        span.style.visibility = 'hidden';
        span.style.whiteSpace = 'nowrap';

        document.body.appendChild(span);
        if(width < span.offsetWidth){
            setHasTooltip(true)
        }
        document.body.removeChild(span);
    }, []);

    return (
        <p
            title={hasTooltip ? text : ''}
            style={{display: "block"}}
        >
            {text}
        </p>
    );
};

export default TooltipText;
