import { withCallbacks } from "redux-signalr";
import { reloadPipelineProgress, setIsPipelineRunning, setPipelineProgress } from "redux/AppNavigationState";
import { loadInventoryDataAsync, loadScenariosAsync, loadSegmentationDataAsync } from "redux/AppScenarioState";
import { ProgressMessage, SchedulerMessage } from "./types";
import { setIsRecalculating } from "redux/AppStockState";


export const callbacks = withCallbacks()
    .add("schedulermessage.actions.inventorysummary", () => (dispatch, getState) => {
        const { appStockState } = getState()
        dispatch(loadInventoryDataAsync({ scenarioId: appStockState.selectedScenario.id }))
        dispatch(loadSegmentationDataAsync({}))
        dispatch(setIsRecalculating({ validation: false }))

    })
    .add("schedulermessage.actions.deletescenario", () => (dispatch, getState) => {
        dispatch(loadScenariosAsync({}));
    })
    .add(SchedulerMessage.Actions.ReloadScenarios, () => (dispatch, getState) => {
        dispatch(loadScenariosAsync({}));
    })
    .add(SchedulerMessage.Pipeline.Inputs, (pipelineProgress: ProgressMessage) => (dispatch, getState) => {
        dispatch(setPipelineProgress(pipelineProgress))
    })
    .add(SchedulerMessage.Pipeline.Message, (pipelineProgress: ProgressMessage) => (dispatch, getState) => {
        dispatch(setPipelineProgress(pipelineProgress))
    })
    .add(SchedulerMessage.Pipeline.Update, (pipelineProgress: ProgressMessage) => (dispatch, getState) => {
        dispatch(setPipelineProgress(pipelineProgress))
    })
    .add(SchedulerMessage.Pipeline.ProgressNotification, (pipelineProgress: ProgressMessage) => (dispatch, getState) => {
        dispatch(setPipelineProgress(pipelineProgress))
    })
    .add(SchedulerMessage.Pipeline.Open, (pipelineProgress: ProgressMessage) => (dispatch, getState) => {
        dispatch(setIsPipelineRunning(true));
        dispatch(setPipelineProgress(pipelineProgress))
    })
    .add(SchedulerMessage.Pipeline.Close, () => (dispatch, getState) => {
        dispatch(setIsPipelineRunning(false));
    })
    .add(SchedulerMessage.Pipeline.Empty, () => (dispatch, getState) => {
        // console.error(`${SchedulerMessage.Pipeline.Empty} Not Implemented`)
        // const { appNavigation } = getState();
        // dispatch(setPipelineProgress({
        //     message: '',
        //     percentage: '100',
        //     pipelineName: appNavigation.pipeline.pipelineProgress.pipelineName,
        //     runningTime: '',
        //     taskName: 'Finished'
        // }))
        dispatch(setIsPipelineRunning(false));
        //TODO melhorar forma de atualizar lista de Datasets
        // dispatch(loadScenariosAsync());
    })
