import { useRef, useMemo, useEffect } from 'react'
import { Bar } from 'react-chartjs-2';
import { CategoryScale } from 'chart.js';
import Chart from 'chart.js/auto'
import 'chartjs-plugin-dragdata';
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import React from 'react';
import { Dictionary } from 'utils/types';
import { useCallback } from 'react';
import { notifyError } from 'utils/notification';
import { clearSelectedOccupationData, setSelectedOccupationData, updatePlanningPageByUnitOfTime, transferChartProducts, setSelectedItemIds, finishOccupationCapacityDrag, filterBy, applyCriterias, transferResourceAllocation } from 'redux/AppScenarioState';
import { ResourceAggregator } from 'redux/AppScenarioState/types';
import { getChartLabelData } from 'redux/AppScenarioState/helpers';
import { PlanningGridAllocationData } from 'redux/AppScenarioState/dataTypes';
import { ResourceGroupDetails } from 'api/data/types';
import zoomPlugin from 'chartjs-plugin-zoom';
import { i18n } from "@lingui/core"
import { SearchList, FilterCriteria, OccupationPageFilterData, OccupationPageFilterDataPeriod } from 'redux/AppScenarioState/FilterCriteria/types';
import { useLingui } from '@lingui/react';
import { ToolTipComponent } from 'pages/StockPolicyPage/components/TooltipComponent';
import { ToolTipComponentReactNodeChildren } from 'pages/StockPolicyPage/components/TooltipComponentNodeChildren';

Chart.register(zoomPlugin);
Chart.register(CategoryScale)

const resetAllBorderWidth = () => {
    let charts = Chart.instances;
    for (const chart in charts) {
        const chartInstance = charts[chart];

        if (chartInstance.options?.plugins?.title?.text) continue;

        let datasets = chartInstance.data.datasets
        for (let i = 1; i < datasets.length; i++) {
            datasets[i].borderWidth = 0;
        }
        chartInstance.update('none');
    }
}

const createChartReferences = (resourceGroupById: Dictionary<ResourceGroupDetails>) => {
    let chartRefDict: Dictionary<Dictionary<React.RefObject<ChartJSOrUndefined<"bar", number[], string>>>> = {};
    for (const resourceGroup of Object.values(resourceGroupById)) {
        if (!chartRefDict[resourceGroup.name]) {
            chartRefDict[resourceGroup.name] = {}
        }
        //TODO precisa buscar pelo Id?
        for (let a = 0; a < resourceGroup.resources.length; a++) {
            const planningResource = resourceGroup.resources[a];
            chartRefDict[resourceGroup.name][planningResource.name] = React.createRef<ChartJSOrUndefined<"bar", number[], string>>()
        }
    }
    return chartRefDict;
}

const getOccupationLabel = (planningGridAllocation: PlanningGridAllocationData, itemCode: string): string[] => {
    let label: string[] = []
    // label.push(`Resource Id: ${resourceAllocated.id}`);
    label.push(`${i18n.t('Código do Item')}: ${itemCode}`);
    label.push(`${i18n.t('Tempo de Setup')}: ${planningGridAllocation.totalSetupTime.toFixed(2)}`);
    label.push(`${i18n.t('Tempo de Processo')}: ${planningGridAllocation.totalProcessTime.toFixed(2)}`);
    label.push(`${i18n.t('Total')}: ${planningGridAllocation.totalAllocatedTime.toFixed(2)}`);
    // label.push(`${i18n.t('ItemOperationId')}: ${planningGridAllocation.itemOperationId}`);
    return label
}

const OccupationChartContainer = () => {
    const dispatch = useAppDispatch();
    const resourceGroupById = useAppSelector(state => state.appScenarioState.resourceGroupById);
    const chartReferences = useRef(createChartReferences(resourceGroupById));
    const selectedResources = useAppSelector((state) => state.appScenarioState.selectedResources)
    const selectedResourcesRef = useRef<ResourceAggregator[]>();
    const resourceNamesByChartCanvasIdRef = useRef<Dictionary<{ resourceGroupName: string, resourceName: string }>>({});
    selectedResourcesRef.current = selectedResources
    const chartDataByResourceGroup = useAppSelector((state) => state.appScenarioState.chartDataByResourceGroup)
    const inputSelectedResourceAllocationRef = useRef<PlanningGridAllocationData>();
    const occupationPeriodsRef = useRef(useAppSelector(state => state.appScenarioState.periods))
    const inputResourceGroupResourcesRef = useRef<string>();
    const inputResourceLabelRef = useRef<string>();
    const selectedItemIdsRef = useRef<number[]>([]);
    const { i18n } = useLingui()


    //TODO verificar possível melhora na solução
    const chartIdToPlanningResourceId = useRef<Dictionary<number> | undefined>()
    chartIdToPlanningResourceId.current = undefined

    //TODO ver forma melhor de armazenar os dados do input?
    const inputTransferDataRef = useRef<{
        id: number,
        datasetIndex: number,
        chartPeriodIndex: number
    }>();

    const resourceAllocationsByProductIdByResourceIdByOperationIdByPeriodId: Dictionary<Dictionary<Dictionary<Dictionary<PlanningGridAllocationData>>>> = useAppSelector(state => state.appScenarioState.planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId);
    const resourceAllocationsByProductIdByResourceIdByOperationIdByPeriodIdRef = useRef<Dictionary<Dictionary<Dictionary<Dictionary<PlanningGridAllocationData>>>>>();

    useEffect(() => {
        resourceAllocationsByProductIdByResourceIdByOperationIdByPeriodIdRef.current = resourceAllocationsByProductIdByResourceIdByOperationIdByPeriodId
    }, [resourceAllocationsByProductIdByResourceIdByOperationIdByPeriodId])

    const getPlanningGridAllocationData = useCallback((productId: number, periodId: number, resourceId: number, operationId: number): PlanningGridAllocationData | undefined => {
        if (resourceAllocationsByProductIdByResourceIdByOperationIdByPeriodIdRef.current) {
            if (productId in resourceAllocationsByProductIdByResourceIdByOperationIdByPeriodIdRef.current) {
                const resourceAllocationsByResourceIdByOperationIdByPeriodId = resourceAllocationsByProductIdByResourceIdByOperationIdByPeriodIdRef.current[productId];
                if (resourceId in resourceAllocationsByResourceIdByOperationIdByPeriodId) {
                    const resourcesAllocationByOperatioIdByPeriodId = resourceAllocationsByResourceIdByOperationIdByPeriodId[resourceId];
                    if (operationId in resourcesAllocationByOperatioIdByPeriodId) {
                        const resourcesAllocationByPeriodId = resourcesAllocationByOperatioIdByPeriodId[operationId];
                        return resourcesAllocationByPeriodId[periodId];
                    }
                }
            }
        }
    }, [])

    const handleLabelTooltip = useCallback((context: any) => {
        const { resourceId, itemId, itemCode, operationId } = getChartLabelData(context.dataset.label)
        if (context.dataset.label.toLowerCase() === 'setup')
            return `${i18n.t({ id: 'Tempo de Setup', comment: 'Label quando passa mouse por cima de uma barra de setup' })}: ${context.dataset.data[context.dataIndex]}`

        if (!selectedResourcesRef.current || !resourceAllocationsByProductIdByResourceIdByOperationIdByPeriodIdRef.current) return ''
        //coluna X do grafico no caso.
        let periodIndex = context.parsed.x;
        const periodId = occupationPeriodsRef.current[periodIndex].id

        let planningGridAllocation = getPlanningGridAllocationData(itemId, periodId, resourceId, operationId);
        if (!planningGridAllocation) {
            let label: string[] = []

            label.push(`${i18n.t(`Capacidade Disponivel`)}: ${context.parsed.y}`)
            label.push(`${i18n.t('* Ao clicar no "diamante" será selecionado os itens deste período deste recurso.')}`);
            return label
        }
        return getOccupationLabel(planningGridAllocation, itemCode)
    }, [])

    const clearAllTransferSelection = useCallback(() => {
        dispatch(clearSelectedOccupationData())
        inputResourceGroupResourcesRef.current = undefined
        inputTransferDataRef.current = undefined;
        inputSelectedResourceAllocationRef.current = undefined;
        resetAllBorderWidth();
    }, [])

    const handleChartDataTransfer = useCallback((
        isControl: boolean,
        currentSelectedResourceAllocation: PlanningGridAllocationData,
        chartId: number,
        toDatasetIndex: number,
        toResourceId: number,
        chartPeriodIndex: number,
        periodId: number,
        resourceGroupName: string,
        resourceName: string,
        chartTranslateData: { x: number, y: number, height: number }
    ) => {
        if (inputSelectedResourceAllocationRef.current && inputTransferDataRef.current && !isControl) {
            //TODO Atualizar os dados do PlanningPage
            dispatch(transferChartProducts({
                // inputPlanningGridAllocation: inputSelectedResourceAllocationRef.current,
                inputChartId: inputTransferDataRef.current.id,
                inputDatasetsIndex: inputTransferDataRef.current.datasetIndex,

                //TODO great-fix
                // inputResourceId: 0,
                inputResourceId: inputSelectedResourceAllocationRef.current.resourceId,

                inputChartPeriodIndex: inputTransferDataRef.current.chartPeriodIndex,
                // outputPlanningGridAllocation: currentSelectedResourceAllocation,
                outputChartId: chartId,
                outputResourceId: toResourceId,
                outputChartPeriodIndex: chartPeriodIndex,
                totalProcessTime: inputSelectedResourceAllocationRef.current.totalProcessTime,
                totalSetupTime: inputSelectedResourceAllocationRef.current.totalSetupTime,
                partialProcessTime: inputSelectedResourceAllocationRef.current.partialProcessTime,
                outputPlanningGroupName: resourceGroupName,
                outputPlanningResourceName: resourceName,
            }));

            dispatch(transferResourceAllocation({
                inputOperationId: inputSelectedResourceAllocationRef.current.itemOperationId,
                inputPlanningResourceId: inputSelectedResourceAllocationRef.current.resourceId,
                inputPeriodId: inputSelectedResourceAllocationRef.current.periodId ?? 0,
                inputItemId: inputSelectedResourceAllocationRef.current.itemId ?? 0,
                outputPlanningResourceId: toResourceId,
                outputPeriodId: periodId,
                outputChartDatasetsIndex: chartPeriodIndex,
                partialValue: inputSelectedResourceAllocationRef.current.partialProcessTime
            }));

            if (inputSelectedResourceAllocationRef.current) {
                dispatch(updatePlanningPageByUnitOfTime({
                    itemId: inputSelectedResourceAllocationRef.current.itemId,
                    fromPeriodId: inputSelectedResourceAllocationRef.current.periodId,
                    toPeriodId: periodId,
                    processTime: inputSelectedResourceAllocationRef.current.totalProcessTime,
                    partialProcessTime: inputSelectedResourceAllocationRef.current.partialProcessTime
                }));
            }

            clearAllTransferSelection();
            //TODO melhorar forma de não transferir de RG001 - RG002
            inputResourceGroupResourcesRef.current = undefined
            return;
        } else if (!inputSelectedResourceAllocationRef.current) {
            inputSelectedResourceAllocationRef.current = currentSelectedResourceAllocation;
            inputTransferDataRef.current = {
                id: chartId,
                datasetIndex: toDatasetIndex,
                chartPeriodIndex: chartPeriodIndex
            };
        }

        //OccupationPage menu
        if (isControl) {
            //Set occupationChartMenu data
            dispatch(setSelectedOccupationData({
                resourceGroupName: resourceGroupName,
                resourceName: resourceName,
                itemId: 0,
                // productId: currentSelectedResourceAllocation.productId,
                setupTime: currentSelectedResourceAllocation.totalSetupTime,
                processTime: currentSelectedResourceAllocation.totalProcessTime,
                resourceAllocationId: toResourceId
            }))
        }
    }, [])

    const getResourceFromChartReferences = useCallback((chartId: number): ResourceAggregator | undefined => {
        if (chartIdToPlanningResourceId.current) {
            const planningResourceId = chartIdToPlanningResourceId.current[chartId]
            for (const planningGroup of Object.values(resourceGroupById)) {
                for (let j = 0; j < planningGroup.resources.length; j++) {
                    const planningResource = planningGroup.resources[j];
                    if (planningResource.id === planningResourceId) {
                        return {
                            resourceGroupName: planningGroup.name,
                            resourceName: planningResource.name,
                            id: planningResource.id,
                            finiteCapacity: planningResource.finiteCapacity
                        }
                    }
                }
            }
        }
    }, [resourceGroupById])


    const getResourceGroupNameByChartId = useCallback((chartId: number) => {
        for (const resourceGroup in chartReferences.current) {
            const resourceGroupChart = chartReferences.current[resourceGroup];
            if (!resourceGroupChart) continue;
            for (const resourceName in resourceGroupChart) {
                if (Number(resourceGroupChart[resourceName].current?.id) === chartId) {
                    return resourceGroup;
                }
            }
        }
    }, [])

    const setProductBorderWidth = useCallback((chartId: number, labelToFind: string, width: number) => {
        let resourceGroupName = getResourceGroupNameByChartId(chartId);
        if (!resourceGroupName) return;

        const productIdToFind = labelToFind.split('|')[1]
        let chartsToUpdate: Chart<"bar", number[], string>[] = []

        for (const resourceName in chartReferences.current?.[resourceGroupName]) {
            const resourceChart = chartReferences.current?.[resourceGroupName][resourceName].current;
            if (resourceChart) {
                let datasets = resourceChart.data.datasets
                const labels = datasets.map(dataset => dataset.label);
                if (datasets && labels) {
                    for (let i = 0; i < labels.length; i++) {
                        if (labels[i]?.split('|')[1] === productIdToFind) {
                            datasets[i].borderWidth = width;
                            chartsToUpdate.push(resourceChart);
                        }
                    }
                }
            }
        }
        for (const chartToUpdate of chartsToUpdate) {
            chartToUpdate.update('none');
        }
    }, [])

    const recalculateChartIdsToPlanningResourceIds = useCallback(() => {
        if (selectedResourcesRef.current) {
            chartIdToPlanningResourceId.current = {}
            let charts = Chart.instances
            let counter = 0;
            for (const key in charts) {
                //TODO se for adicionar titulos nos charts do OccupationPage, deve-se tratar de outra forma o chart do PlanningPage
                if (charts[key].options?.plugins?.title?.text) {
                    continue;
                }
                const chartId = charts[key].id;

                chartIdToPlanningResourceId.current[chartId] = selectedResourcesRef.current[counter++].id
            }
        }
    }, [])

    const getPlanningResourceId = (chartId: number) => {
        if (!chartIdToPlanningResourceId.current) return
        return chartIdToPlanningResourceId.current[chartId];
    }

    //TODO need to improve stability before reuse this method!
    const handleChartClick = useCallback((e, elements) => {
        recalculateChartIdsToPlanningResourceIds();

        const isControl: boolean = e.native.ctrlKey;
        const element = elements[0];

        //Caso não clique em nenhum elemento devese limpar os itens selecionados
        if (element === undefined) {
            //Should clear selected products if there are selected products
            clearAllTransferSelection();
            return;
        }
        //datasetIndex
        // datasetIndex 0 -> diamante
        const datasetIndex = element.datasetIndex;
        const chart = e.chart;
        const chartId = chart.id;

        let selectedResourceData = getResourceFromChartReferences(chartId)
        if (!selectedResourceData) {
            notifyError({
                message: `Error at getResourceFromChartReferences using chartId ${chartId}`,
                timer: 3000
            })
            return;
        }
        //PeriodIndex
        const chartPeriodIndex: number = element.index;

        //filter by period/line
        if (datasetIndex === 0) {
            let searchList: SearchList<OccupationPageFilterDataPeriod>[] = [
                {
                    id: '',
                    label: '',
                    data: {
                        chartId: chartId,
                        chartPeriodIndex: chartPeriodIndex,
                        selectedResourceData: selectedResourceData
                    }
                }
            ]
            dispatch(filterBy({ filterCriteria: FilterCriteria.OccupationPageResourceAndPeriod, searchList }));
            dispatch(applyCriterias());
            return;
        }

        const currentLabel = chart.data.datasets[datasetIndex].label

        if (currentLabel.toLowerCase() === i18n.t(`setup`).toLowerCase() || inputResourceLabelRef.current === currentLabel) {
            //Should clear selected products if there are selected products
            clearAllTransferSelection();
            return;
        }

        //If the user tries to transfer from one resourceGroup to another, should notify error.
        if (!inputResourceGroupResourcesRef.current) {
            inputResourceGroupResourcesRef.current = selectedResourceData.resourceGroupName;
        } else if (inputResourceGroupResourcesRef.current && inputResourceGroupResourcesRef.current !== selectedResourceData.resourceGroupName) {
            notifyError({
                message: `Can't transfer resources from ${inputResourceGroupResourcesRef.current} to ${selectedResourceData.resourceGroupName}`,
                timer: 2500
            })
            return;
        }

        const periodId = occupationPeriodsRef.current[chartPeriodIndex].id
        const { resourceId, itemId, itemCode, operationId } = getChartLabelData(currentLabel)

        //TODO temporary, to select more than one item
        if (isControl) {
            selectedItemIdsRef.current = [...selectedItemIdsRef.current, itemId]
        } else {
            selectedItemIdsRef.current = [itemId]
            resetAllBorderWidth();
        }
        //Get selected product data to use it on menus
        if (!inputSelectedResourceAllocationRef.current) {
            setProductBorderWidth(chartId, currentLabel, 5);
        }

        // recalculateChartIdsToPlanningResourceIds();
        const planningResourceId = getPlanningResourceId(chartId);
        if (planningResourceId === undefined) {
            console.error('recalculateAndGetPlanningResourceId returned undefined when using chartId', chartId);
            return;
        }

        dispatch(setSelectedItemIds(selectedItemIdsRef.current))

        //TODO Manter somente seleção no OccupationPage até estar estavel
        // return;

        let currentSelectedPlanningGridAllocationData: PlanningGridAllocationData | undefined = undefined;

        //datasetIndex === 0 -> Capacity's Dataset
        if (datasetIndex === 0) {
            currentSelectedPlanningGridAllocationData = {
                id: 0,
                planningGridId: 0,
                itemOperationId: 0,
                scenarioId: 0,
                resourceId: 0,
                totalProcessTime: 0,
                totalSetupTime: 0,
                productionTypeId: 0,
                mpsQuantity: 0,
                itemId: 0,
                periodId: 0,
                locked: 0,
                planningGridResourcesIds: [],
                totalAllocatedTime: 0,
            }
        } else {
            let tempPlanningGridAllocationData = getPlanningGridAllocationData(itemId, periodId, planningResourceId, operationId)
            if (tempPlanningGridAllocationData) {
                currentSelectedPlanningGridAllocationData = {
                    id: 0,
                    planningGridId: 0,
                    itemOperationId: 0,
                    scenarioId: 0,
                    resourceId: 0,
                    totalProcessTime: 0,
                    totalSetupTime: 0,
                    productionTypeId: 0,
                    mpsQuantity: 0,
                    itemId: 0,
                    periodId: 0,
                    locked: 0,
                    planningGridResourcesIds: [],
                    totalAllocatedTime: 0,

                }
            }
            // currentSelectedPlanningGridAllocationData = getResourceAllocated(itemId, periodId, planningResourceId)
            if (currentSelectedPlanningGridAllocationData && tempPlanningGridAllocationData) {
                currentSelectedPlanningGridAllocationData.id = tempPlanningGridAllocationData.id
                currentSelectedPlanningGridAllocationData.planningGridId = tempPlanningGridAllocationData.planningGridId
                currentSelectedPlanningGridAllocationData.scenarioId = tempPlanningGridAllocationData.scenarioId
                currentSelectedPlanningGridAllocationData.resourceId = tempPlanningGridAllocationData.resourceId
                currentSelectedPlanningGridAllocationData.totalProcessTime = tempPlanningGridAllocationData.totalProcessTime
                currentSelectedPlanningGridAllocationData.totalSetupTime = tempPlanningGridAllocationData.totalSetupTime
                currentSelectedPlanningGridAllocationData.productionTypeId = tempPlanningGridAllocationData.productionTypeId
                currentSelectedPlanningGridAllocationData.mpsQuantity = tempPlanningGridAllocationData.mpsQuantity
                currentSelectedPlanningGridAllocationData.locked = tempPlanningGridAllocationData.locked
                currentSelectedPlanningGridAllocationData.planningGridResourcesIds = tempPlanningGridAllocationData.planningGridResourcesIds
                currentSelectedPlanningGridAllocationData.totalAllocatedTime = tempPlanningGridAllocationData.totalAllocatedTime
                currentSelectedPlanningGridAllocationData.periodId = periodId
                currentSelectedPlanningGridAllocationData.itemId = itemId
            }
        }

        if (currentSelectedPlanningGridAllocationData === undefined) {
            console.error('getResourceAllocated returned undefined when using:', { itemId, periodId, resourceId });
            return;
        }

        handleChartDataTransfer(
            isControl,
            currentSelectedPlanningGridAllocationData,
            chartId,
            datasetIndex,
            planningResourceId,
            chartPeriodIndex,
            periodId,
            selectedResourceData.resourceGroupName,
            selectedResourceData.resourceName,
            { x: e.x, y: e.y, height: element.height }
        );
    }, [])

    const handleOnResourceGroupFilterClick = useCallback((resource: ResourceAggregator) => {
        let searchList: SearchList<OccupationPageFilterData>[] = [
            {
                id: '',
                label: '',
                data: {
                    chartId: resource.id,
                    selectedResourceData: resource
                }
            }
        ]
        dispatch(filterBy({ filterCriteria: FilterCriteria.OccupationPageResourceGroup, searchList }));
        dispatch(applyCriterias());
    }, [])


    const localOnDrag = useCallback((e, datasetIndex, index, value) => {
        if (datasetIndex !== 0) return;
        const chartIdTag = e.target.id;
        if (!resourceNamesByChartCanvasIdRef.current || resourceNamesByChartCanvasIdRef.current[chartIdTag] === undefined) return
        const { resourceGroupName, resourceName } = resourceNamesByChartCanvasIdRef.current[chartIdTag]
        const resourceChart = chartReferences.current?.[resourceGroupName][resourceName].current;
        let chartCapacityDataset = resourceChart?.data.datasets[datasetIndex];
        if (!chartCapacityDataset) return;
        let capacityDatasetCopy = [...chartCapacityDataset.data]

        if (e.shiftKey) {
            const difference = value - capacityDatasetCopy[index]
            capacityDatasetCopy = capacityDatasetCopy.map(x => x + difference);
        } else if (e.altKey) {
            capacityDatasetCopy = Array(capacityDatasetCopy.length).fill(value)
        } else {
            capacityDatasetCopy[index] = value
        }
        chartCapacityDataset.data = capacityDatasetCopy
    }, []);

    const localOnDragEnd = useCallback((e, datasetIndex, index, value) => {
        if (datasetIndex !== 0) return;

        const chartIdTag = e.target.id;
        if (!resourceNamesByChartCanvasIdRef.current || resourceNamesByChartCanvasIdRef.current[chartIdTag] === undefined) return
        const { resourceGroupName, resourceName } = resourceNamesByChartCanvasIdRef.current[chartIdTag]
        const resourceChart = chartReferences.current?.[resourceGroupName][resourceName].current;
        let chartCapacityDataset = resourceChart?.data.datasets[datasetIndex];
        if (!chartCapacityDataset) return;
        let capacityDatasetCopy = [...chartCapacityDataset.data]
        dispatch(finishOccupationCapacityDrag({ newDatasetData: capacityDatasetCopy, datasetIndex, resourceGroupName, resourceName }))
    }, []);

    const localChartOptions = useMemo(() => ({
        onClick: (e, elements) => handleChartClick(e, elements),
        dragData: true,
        dragDataRound: 1,
        scales: {
            y: {
                grace: '20'
            }
        },
        spanGaps: true, // enable for all datasets
        tooltips: {
            backgroundColor: "rgba(255,255,255,0.6)",
            borderWidth: 0,
            borderColor: "rgba(163, 161, 251, 1)",
            bodyFontColor: "rgba(0, 0, 0, 0.8)",
            titleFontColor: "rgba(0, 0, 0, 0.8)"
        },
        layout: {
            padding: {
                left: 15,
                right: 15,
                top: 15,
                bottom: 15
            }
        },
        elements: {
            point: { radius: 0, hitRadius: 0, borderWidth: 0 },
            line: { tension: 0 }
        },
        maintainAspectRatio: false,
        plugins: {
            dragData: {
                round: 0,
                showTooltip: false,
                // onDragStart: localOnDragStart,
                onDrag: localOnDrag,
                onDragEnd: localOnDragEnd,
            },
            zoom: {
                zoom: {
                    wheel: {
                        enabled: true,
                        modifierKey: 'ctrl'
                    },
                    mode: 'y',
                }
            },
            legend: {
                display: false
            },
            tooltip: {
                displayColors: false,
                callbacks: {
                    label: context => handleLabelTooltip(context)
                },
            }
        },
        //TODO ver quais animaçoes devem estar ativas ou não.
        // animations: false,
        // animation: {
        //     duration: 0
        // }
    }), [])





    if (resourceNamesByChartCanvasIdRef.current === null || chartReferences.current === null) return <></>
    // const resourceNamesByChartCanvasIdRef = ref;
    // const chartReferences = ref;
    return (
        <>
            {
                selectedResources.map((resource, index) => {
                    if (!chartReferences.current) return <></>
                    if (!resourceNamesByChartCanvasIdRef.current) return <></>

                    const resourceData = chartDataByResourceGroup[resource.resourceGroupName][resource.resourceName];

                    if (resourceData === undefined || resourceData.labels.length === 0) return (<></>)
                    const chartCanvasId = `chart-${index}`;
                    resourceNamesByChartCanvasIdRef.current[chartCanvasId] = { resourceGroupName: resource.resourceGroupName, resourceName: resource.resourceName }

                    if (!chartReferences.current[resource.resourceGroupName])
                        chartReferences.current[resource.resourceGroupName] = {}

                    if (!chartReferences.current[resource.resourceGroupName][resource.resourceName])
                        chartReferences.current[resource.resourceGroupName][resource.resourceName] = React.createRef<ChartJSOrUndefined<"bar", number[], string>>()

                    return (
                        <>
                            <div
                                key={`div-${chartCanvasId}-${index}`}
                            >
                                <div className='flex align-middle text-center w-full justify-center items-center'>
                                    <ToolTipComponentReactNodeChildren tooltipText={'Selecionar todos os itens deste recurso'} color={'#ecc94b'}>
                                        <h1
                                            className='font-bold align-middle text-center cursor-pointer'
                                            onClick={() => {
                                                handleOnResourceGroupFilterClick(resource)
                                            }}
                                        >
                                            {resource.resourceName}
                                        </h1>
                                    </ToolTipComponentReactNodeChildren>
                                </div>
                                <div
                                    className={`block h-96 w-full justify-center`}>
                                    <Bar
                                        id={chartCanvasId}
                                        key={`${index}-${chartCanvasId}`}
                                        options={localChartOptions as any}
                                        data={resourceData as any}
                                        ref={chartReferences.current[resource.resourceGroupName][resource.resourceName]}
                                    />
                                </div>
                            </div>
                        </>
                    )
                }
                )
            }
        </>
    )
}

export default OccupationChartContainer
