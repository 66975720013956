import { Menu } from '@headlessui/react'
import { useState } from 'react';
import { SiGithubactions } from 'react-icons/si'

interface Props {
    taskDescriptionNames: string[]
    onSelect: (option: string) => void;
}

function DropdownButton(props: Props) {
    const { taskDescriptionNames, onSelect } = props
    const [option, setOption] = useState(taskDescriptionNames[0]);

    const onLocalSelect = (option: string) => {
        setOption(option);
        onSelect(option);
    }

    return (
        <div className="flex place-content-center  text-right z-50">
            <Menu as="div" className="relative inline-block text-left">
                <Menu.Button className="flex min-w-min h-full items-center px-2 text-white bg-black 
                rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 
                focus-visible:ring-white focus-visible:ring-opacity-75">
                    <div className='flex w-full justify-between'>

                        <div className='text-base font-medium ml-1'>{option}</div>
                        <SiGithubactions className='w-6 h-6 ml-4 mr-1 place-self-end' />
                    </div>
                </Menu.Button>
                <Menu.Items className="absolute left-0 w-60 mt-2  bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="px-1 py-1 ">
                        {
                            taskDescriptionNames.map((taskDescriptionName, index) => (
                                <Menu.Item>
                                    {({ active }) => (
                                        <button onClick={() => onLocalSelect(taskDescriptionName)}
                                            key={`${taskDescriptionName}-${index}`}
                                            className={`${active ? 'bg-indigo-500 text-white' : 'text-gray-900'
                                                } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                        >
                                            {taskDescriptionName}
                                        </button>
                                    )}
                                </Menu.Item>
                            ))
                        }
                    </div>
                </Menu.Items>
            </Menu>
        </div>
    )
}
export default DropdownButton