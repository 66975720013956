
import { schedulerUrl } from "api/configuration";
import { deleteBaseAsync, getBaseAsync, postBaseAsync, putBaseAsync, RequestPathParameters } from 'api/';

export const getAsync = async <TType>(parameters: RequestPathParameters): Promise<TType | any> => {
    return getBaseAsync({ baseUrl: schedulerUrl, ...parameters });
}

export const postAsync = async (parameters: RequestPathParameters): Promise<any> => {
    return postBaseAsync({ baseUrl: schedulerUrl, ...parameters });
}

export const deleteAsync = async (parameters: RequestPathParameters): Promise<any> => {
    return deleteBaseAsync({ baseUrl: schedulerUrl, ...parameters });
}

export const putAsync = async (parameters: RequestPathParameters): Promise<any> => {
    return putBaseAsync({ baseUrl: schedulerUrl, ...parameters });
}