import { useAppSelector } from "redux/hooks";
import { EditableCardProps, EditedObjectProps, SegmentationDataType, supplyStrategyEditedObjectsProps, } from "../types";
import { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { getSupplyStrategyOptionsAsync } from "api/data/lib/stockPolicySupplyStrategy";
import { useDispatch } from "react-redux";
import { setStockStrategyOptions, setSupplyStrategyEditedObjects, } from "redux/AppStockState";
import { toast } from "react-toastify";
import { validateAndReplaceComma } from "../utils/ValidateAndReplaceComma";
import { sortArrayByTypeKeyPriority } from "../utils/sortArrayByKeyWithComparator";
import { Trans } from "@lingui/macro";

import { ToolTipComponentOnlyText } from "./TooltipComponentOnlyText";

export function EditableCards({ items }: EditableCardProps) {
    const dispatch = useDispatch();
    let filteredValues = items.map(item => {
        return item.items.map(c => {
            return c.map((z, index) => {
                return {
                    id: index,
                    classificationComplexityId: z.classificationComplexityId,
                    classificationUncertaintyId: z.classificationUncertaintyId,
                    classificationVolumeId: z.classificationVolumeId,
                    classificationComplexity: z.classificationComplexity.name,
                    classificationUncertainty: z.classificationUncertainty.name,
                    classificationVolume: z.classificationVolume.name,
                }
            })

        })
    })
    const [editedForm, setEditedForm] = useState<EditedObjectProps[]>([] as EditedObjectProps[]);

    const getSupplyStrategyOptions = useCallback(async () => {
        const { data } = await getSupplyStrategyOptionsAsync();
        dispatch(setStockStrategyOptions({ optionsArray: data }));
    }, []);

    const updateEditedItens = useCallback(async () => {
        dispatch(setSupplyStrategyEditedObjects({ array: editedForm as supplyStrategyEditedObjectsProps[], }));
    }, [editedForm]);

    useEffect(() => { getSupplyStrategyOptions(); }, []);

    useEffect(() => { updateEditedItens(); }, [editedForm]);

    const { stockPolicyAxes, stockStrategyOptions } = useAppSelector((state) => state.appStockState);

    function handleChange(id: number, type: string, value: string, card: SegmentationDataType) {
        const cardInfo = {
            classificationComplexityId: card.classificationComplexityId,
            classificationUncertaintyId: card.classificationUncertaintyId,
            classificationVolumeId: card.classificationVolumeId,
            supplyStrategyId: card.supplyStrategyId,
            companyId: card.companyId,
            color: card.color,
            safetyStockDays: card.safetyStockDays,
            cycleStockDays: card.cycleStockDays,
            serviceLevel: card.serviceLevel,
        };
        if (
            type !== "safetyStockDays" &&
            (validateAndReplaceComma(value) < 0 ||
                validateAndReplaceComma(value) > 100)
        ) {
            toast.warn("Os valores devem estar entre 0 e 100", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return;
        }
        if (type === "safetyStockDays") {
            const pattern = /^(C|[0-9]|[1-9][0-9]|100)$/i;
            if (!pattern.test(value)) {
                toast.warn(
                    "Os valores devem estar entre 0 e 100. Para campo calculado utilizar a letra 'c'.",
                    {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    }
                );
                return;
            }
        }

        switch (type) {
            case "supplyStrategy":
                setEditedForm((state) => {
                    const objIndex = state.findIndex((obj) => obj.id === id);
                    const formattedValue = validateAndReplaceComma(value);
                    if (objIndex !== -1) {
                        return state.map((obj) => {
                            if (obj.id === id) {
                                return { ...obj, supplyStrategyId: formattedValue };
                            }
                            return obj;
                        });
                    } else {
                        const rest = { ...cardInfo, supplyStrategyId: formattedValue };
                        return [...state, { id, ...rest }];
                    }
                });
                break;
            case "safetyStockDays":
                setEditedForm((state) => {
                    const objIndex = state.findIndex((obj) => obj.id === id);
                    const formattedValue = validateAndReplaceComma(value);
                    if (isNaN(formattedValue)) {
                        if (objIndex !== -1) {
                            return state.map((obj) => {
                                if (obj.id === id) {
                                    return { ...obj, safetyStockDays: null };
                                }
                                return obj;
                            });
                        } else {
                            const rest = { ...cardInfo, safetyStockDays: null };
                            return [...state, { id, ...rest }];
                        }
                    }
                    if (objIndex !== -1) {
                        return state.map((obj) => {
                            if (obj.id === id) {
                                return { ...obj, safetyStockDays: formattedValue };
                            }
                            return obj;
                        });
                    } else {
                        const rest = { ...cardInfo, safetyStockDays: formattedValue };
                        return [...state, { id, ...rest }];
                    }
                });
                break;
            case "cycleStockDays":
                setEditedForm((state) => {
                    const objIndex = state.findIndex((obj) => obj.id === id);
                    const formattedValue = validateAndReplaceComma(value);
                    if (objIndex !== -1) {
                        return state.map((obj) => {
                            if (obj.id === id) {
                                return { ...obj, cycleStockDays: formattedValue };
                            }
                            return obj;
                        });
                    } else {
                        const rest = { ...cardInfo, cycleStockDays: formattedValue };
                        return [...state, { id, ...rest }];
                    }
                });
                break;
            case "serviceLevel":
                setEditedForm((state) => {
                    const objIndex = state.findIndex((obj) => obj.id === id);
                    const formattedValue = validateAndReplaceComma(value);
                    if (objIndex !== -1) {
                        return state.map((obj) => {
                            if (obj.id === id) {
                                return { ...obj, serviceLevel: formattedValue };
                            }
                            return obj;
                        });
                    } else {
                        const rest = { ...cardInfo, serviceLevel: formattedValue };
                        return [...state, { id, ...rest }];
                    }
                });
                break;
        }
    }

    return (
        <>
            {sortArrayByTypeKeyPriority(items).map((item, index) => {
                return (
                    <div className="flex flex-col" key={index}>
                        {index === 0 ? (
                            <div className="w-full justify-center text-center">
                                <p className="font-semibold font-md p-2 underline text-gray-800">
                                    {stockPolicyAxes.xAxis()}
                                </p>
                            </div>
                        ) : null}
                        {index === 0 ? (
                            <div className="flex flex-row w-full p-2  justify-center">
                                <div className="flex items-center w-10 justify-center text-center"></div>

                                {item.xAxisType.map((column) => {
                                    for (let i = 0; i <= filteredValues.length; i++) {
                                        if (filteredValues[i] === undefined) {
                                            return
                                        }
                                        if (filteredValues[i].length === 0) {
                                            return
                                        }
                                        if (filteredValues[i][0].length === 0) {
                                            return
                                        }
                                        for (let j = 0; j < filteredValues[i][0].length; j++) {
                                            if (filteredValues[i][0][j].classificationComplexity === column.name || filteredValues[i][0][j].classificationUncertainty === column.name || filteredValues[i][0][j].classificationVolume === column.name) {
                                                return (
                                                    <div
                                                        className="flex flex-1 justify-center items-center"
                                                        key={uuidv4()}
                                                    >
                                                        <p className="font-semibold w-full flex-1 text-center">
                                                            {column.name}
                                                        </p>
                                                    </div>
                                                );
                                            }
                                        }
                                    }

                                })}
                            </div>
                        ) : null}

                        <div className=" ">
                            <div className="flex w-full justify-around gap-2">
                                <div
                                    className="flex items-center w-10 justify-center text-center"
                                    style={{
                                        transform: "rotate(-90deg)",
                                        WebkitTransformOrigin: "52%  50%",
                                    }}
                                >
                                    <p className="font-semibold text-sm whitespace-nowrap">
                                        {item.type.name}
                                    </p>
                                </div>
                                {item.items.map((cards, index) => {
                                    return (
                                        <div
                                            className="flex flex-row p-2 rounded-md mt-2 gap-2 flex-1 text-sm justify-around items-center text-center  "
                                            key={index}
                                        >
                                            {cards.map((card, index) => {
                                                return (
                                                    <div
                                                        className="flex flex-1 flex-col text-sm border border-gray-400 p-2 gap-x-4  gap-y-2 rounded-md"
                                                        style={{
                                                            backgroundColor: `${card.color}`,
                                                            boxShadow:
                                                                "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                                                            maxWidth: "200px",
                                                        }}
                                                        key={card.id}
                                                    >
                                                        <div className="flex gap-1 flex-1 justify-between items-center h-5">
                                                            <label className="w-fit text-xs">
                                                                <Trans>Estratégia</Trans>{" "}
                                                            </label>
                                                            <select
                                                                id="supplyStrategy"
                                                                className="w-fit text-xs h-8 rounded"
                                                                onChange={(e) =>
                                                                    handleChange(
                                                                        card.id,
                                                                        "supplyStrategy",
                                                                        e.target.value,
                                                                        card
                                                                    )
                                                                }
                                                            >
                                                                {stockStrategyOptions.map((option) => {
                                                                    return (
                                                                        <option
                                                                            key={option.id}
                                                                            value={option.id}
                                                                            selected={
                                                                                option.id === card.supplyStrategyId
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                        >
                                                                            {option.code}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>
                                                        <div className="flex gap-2 justify-between items-center h-5">
                                                            <label className=" text-xs">
                                                                <ToolTipComponentOnlyText
                                                                    label={"Estoque Seg."}
                                                                    tooltipText="Para inserir como campo calculado, digitar a letra 'c'."
                                                                />
                                                            </label>
                                                            <input
                                                                id="safetyStockDays"
                                                                className="shadow text-center h-5 appearance-none text-xs border rounded w-14 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                                type="text"
                                                                defaultValue={
                                                                    card.safetyStockDays === null
                                                                        ? "Calc."
                                                                        : card.safetyStockDays
                                                                }
                                                                onChange={(e) =>
                                                                    handleChange(
                                                                        card.id,
                                                                        "safetyStockDays",
                                                                        e.target.value.toLowerCase(),
                                                                        card
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div className="flex gap-2 justify-between items-center h-5">
                                                            <label className="text-xs">
                                                                <Trans>Estoque Ciclo</Trans>
                                                            </label>
                                                            <input
                                                                id="cycleStockDays"
                                                                className="shadow text-center h-5 appearance-none text-xs border rounded w-14 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                                type="text"
                                                                defaultValue={card.cycleStockDays}
                                                                onChange={(e) =>
                                                                    handleChange(
                                                                        card.id,
                                                                        "cycleStockDays",
                                                                        e.target.value,
                                                                        card
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div className="flex gap-2 justify-between items-center h-5">
                                                            <label className="text-xs whitespace-nowrap">
                                                                <Trans>Nível de Serviço </Trans>(%)
                                                            </label>
                                                            <input
                                                                id="serviceLevel"
                                                                className="shadow text-center h-5 appearance-none text-xs border rounded w-14 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                                type="text"
                                                                defaultValue={card.serviceLevel}
                                                                onChange={(e) =>
                                                                    handleChange(
                                                                        card.id,
                                                                        "serviceLevel",
                                                                        e.target.value,
                                                                        card
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                );
            })}
        </>
    );
}
