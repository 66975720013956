import { useState } from 'react'
import { PlanningGridPanelMemoized } from './components/PlanningGridPanel/PlanningGridPanel'
import { useAppSelector } from 'redux/hooks'
import { AsyncStatus } from 'redux/types'
import PlanningChart from './components/PlanningGridPanel/components/PlanningChart/PlanningChart'
import NeoLoader from 'components/Page/NeoLoader'
import OccupationPage from 'pages/OccupationPage/OccupationPage'
import { BiCollapse, BiExpand } from 'react-icons/bi'
import { BsFillLockFill, BsFillUnlockFill } from 'react-icons/bs'
import IconButton from './components/PlanningGridPanel/components/IconButton'
import { MdStackedBarChart } from 'react-icons/md'
import { PlanningGridMemoized } from './components/PlanningGrid/PlanningGrid'
import { selectPlanningPageDataState } from 'redux/AppNavigationState'
import { TippyComponent } from './components/PlanningGrid/components/TippyComponent'
import { planningGridToolTipOptionsToLanguage } from 'locales/helper'
import { ToolTipPlanningGridOptions } from 'api/data/enums'
import { useLingui } from '@lingui/react'

function PlanningPage() {
    const planningPageDataStatus = useAppSelector(selectPlanningPageDataState)
    const { i18n } = useLingui()

    const [isChartHidden, setIsChartHidden] = useState(false);
    const [isChartSticky, setIsChartSticky] = useState(false);
    const [isOccupationChartHidden, setIsOccupationChartHidden] = useState(true);

    if (planningPageDataStatus !== AsyncStatus.READY) return <NeoLoader />

    return (
        <>
            <div className={`h-auto w-full border rounded mb-2 bg-white ${isChartSticky && 'sticky top-0 z-60'}`}>
                <PlanningChart isChartHidden={isChartHidden} />
                <div className={`flex flex-row h-auto w-auto place-content-center space-x-2 p-1`}>
                    <TippyComponent tooltipText={i18n.t(ToolTipPlanningGridOptions.freezePanels)}>
                        <IconButton lock icon={isChartSticky ? BsFillLockFill : BsFillUnlockFill} onClick={() => setIsChartSticky(!isChartSticky)} />
                    </TippyComponent>
                    <TippyComponent tooltipText={i18n.t(ToolTipPlanningGridOptions.hideUnhide)}>
                        <IconButton lock icon={isChartHidden ? BiCollapse : BiExpand} onClick={() => setIsChartHidden(!isChartHidden)} />
                    </TippyComponent>
                    <TippyComponent tooltipText={i18n.t(ToolTipPlanningGridOptions.occupationPage)}>
                        <IconButton lock icon={MdStackedBarChart} onClick={() => setIsOccupationChartHidden(!isOccupationChartHidden)} />
                    </TippyComponent>
                </div>
            </div>
            {
                !isOccupationChartHidden && <OccupationPage isTopSticky={false} />
            }
            <PlanningGridPanelMemoized />
            <div className='flex flex-shrink-0 w-full h-screen bg-white space-y-10'>
                <PlanningGridMemoized />
            </div>
        </>
    )
}

export default PlanningPage