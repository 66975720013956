import React from 'react'

export function defaultRender(row: any, column: any) {
    return (
        <React.Fragment>
            {row[column.field]}
        </React.Fragment>
    )
} 

export function checkBoxRender(row: any, column: any) {
    return (
        <React.Fragment>
            <input type={"checkbox"} checked={row[column.field]} readOnly/>
        </React.Fragment>
    )
}