import { withCallbacks } from "redux-signalr";
import { OutboundDirectMessage, OutboundMessage } from "./types";
import { addNewDirectMessage, loadAllDirectMessages } from "redux/AppNotificationState";
import { notifyMessage } from "utils/notification";

export const schedulerCallbacks = withCallbacks()
    .add(OutboundMessage.Direct, (outboundmessage: OutboundDirectMessage) => (dispatch, getState) => {
        console.log("🚀 ~ addNewDirectMessage:", outboundmessage);
        notifyMessage({
            message: `${outboundmessage.title} - ${outboundmessage.message}`
        })
        dispatch(addNewDirectMessage(outboundmessage));
    })
    .add(OutboundMessage.LoadAllMessages, (outboundmessages: OutboundDirectMessage[]) => (dispatch, getState) => {
        console.log("🚀 ~ loadAllDirectMessages:", outboundmessages);
        dispatch(loadAllDirectMessages(outboundmessages));
    })
