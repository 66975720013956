import { deleteAsync, getAsync, postAsync } from '..';

export function getTableButtonsAsync(tableId): Promise<Array<any>> {
    return getAsync<Array<any>>({ url: `/tableeditionbutton/${tableId}`})
}

export function postTableButtonAsync(table: any): Promise<any> {
    return postAsync<any>({ url: "/tableeditionbutton", body: {...table}});
}

export function deleteTableButtonAsync(id:any): Promise<any> {
    return deleteAsync<any>({url: `/tableeditionbutton/${id}`});
}