import ReactDOM from "react-dom"
import { QuestionModalProps } from "./QuestionModal"
import { nPlanModalRenderHandler } from "./ModalManager";
import { ModalType } from "redux/AppNavigationState/types";
import { MultiDataModalProps } from "./MultiDataModal";
import { createRoot } from "react-dom/client";

export interface nPlanModalProps {
    rootToRemove?: HTMLElement
    closeModal?: () => void
    exitCallback?: () => void
    cancelCallback?: () => void
    finishCallback?: () => void
}
// export const handleQuestionModal = (modalProps: NPlanModalProps) => {
//     return <QuestionModal {...modalProps} />
// }

const nPlanModalRender = (modalType: ModalType, data: nPlanModalProps) => {
    const containerId = 'nplan-modal-container'
    let rootContainer = document.getElementById(containerId);

    if (!rootContainer) {
        rootContainer = document.createElement('div');
        rootContainer.id = containerId;
        document.body.appendChild(rootContainer);
    }

    data.closeModal = () => rootContainer?.remove();
    data.cancelCallback = () => rootContainer?.remove();
    data.exitCallback = () => rootContainer?.remove();
    data.finishCallback = () => rootContainer?.remove();

    createRoot(rootContainer).render(nPlanModalRenderHandler(modalType, rootContainer, data));
}

nPlanModalRender.question = (data: QuestionModalProps) => nPlanModalRender(ModalType.QuestionModal, data);
nPlanModalRender.multiData = (data: MultiDataModalProps) => nPlanModalRender(ModalType.MultiData, data);
// nPlanModalRender.warn = (t, o) => cogoToast(t, { ...o, type: 'warn' });
// nPlanModalRender.info = (t, o) => cogoToast(t, { ...o, type: 'info' });
// nPlanModalRender.error = (t, o) => cogoToast(t, { ...o, type: 'error' });
// nPlanModalRender.loading = (t, o) => cogoToast(t, { ...o, type: 'loading' });


export default nPlanModalRender;
