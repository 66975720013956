import React, { useCallback, useEffect } from 'react'
import ReactTooltip from 'react-tooltip';
import { hashCode, useHover, useUniqueId } from 'utils';

interface Props {
    className?: string,
    tooltipMessage: string,
    children?: React.ReactNode;
}

function NeoTooltip(props: Props) {
    const [hoverRef, isHovered] = useHover<HTMLDivElement>();
    const { children, tooltipMessage, className } = props

    return (
        <div ref={hoverRef} className={className + ' relative'}>
            {children}
            {
                isHovered &&
                // <ReactTooltip className='font-bold' id={id()} type='info' backgroundColor='rgb(234, 179, 8)'>
                <p className='absolute bottom-8 z-60 min-w-max h-auto font-bold bg-yellow-500 p-2 rounded-md'>{tooltipMessage}</p>
                // </ReactTooltip>
            }
        </div>
    )
}

export default NeoTooltip
