export interface ScriptButtonRoute {
    path: string,
    scriptButtons: ScriptButton[],
    subPaths?: ScriptButtonRoute[]
}
export interface ScriptButton {
    text: string,
    scriptIdToExecute: number,
    colorCode: string
}

export interface Script {
    id: number,
    name: string,
    description: string,
    averageDuration: number,
    active: boolean,
    hasError: boolean,
    tasks: Task[]
}
export interface FlatTask {
    name: string,
    description: string,
    active: boolean,
}
export interface Task {
    id?: number,
    name: string,
    description: string,
    active: boolean,
    toBeRemoved: boolean,
    taskDescription: TaskDescription,
    taskParameters: TaskParameter[],
}

export interface TaskDescription {
    name: string,
    description: string,
    type: string,
    parameterDescriptions: ParameterDescription[]
}

export interface TaskParameter {
    value: string,
    parameterDescription: ParameterDescription
}

export interface ParameterDescription {
    name: string,
    description: string,
    type: string,
}

export interface Schedule {
    scheduleTime: string,
    executionStartTime: string,
    executionEndTime: string,
    active: boolean,
    status: string,
    scriptData: Script
}

export enum TaskType {
    ExecProcedure = 'ExecProcedure'
}
export enum StoredProcedure {
    sp208_Gerar_OP_RC = 'NEOTransform.sp208_Gerar_OP_RC',
    GenerateNeoScenario = 'GenerateNeoScenario',
    IntegrateNeoScenario = 'IntegrateNeoScenario'
}