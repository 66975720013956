import React, { forwardRef, useCallback } from 'react'
import { ListOnScrollProps } from 'react-window';
import { selectComplexPeriods } from 'redux/AppScenarioState';
import { useAppSelector } from 'redux/hooks';
import { PlanningGridCommonWidth } from '../types';
import GridCollapsedHeaderColumnContainer from './GridCollapsedHeaderColumnContainer';
import GridExpandedHeaderColumnContainer from './GridExpandedHeaderColumnContainer';

interface Props {
    onScroll?: ((props: ListOnScrollProps) => any)
}

const GridHeaderColumnContainer = forwardRef((props: Props, ref: any) => {
    const { onScroll } = props
    const isExpandedPeriods = useAppSelector(state => state.appScenarioState.isExpandedPeriods);
    const complexPeriods = useAppSelector(selectComplexPeriods);
    const isWeekly = useAppSelector(state => state.appScenarioState.isWeekly)

    const gridHeaderWidth = useCallback((index: number) => {
        if (complexPeriods[index]) {
            return complexPeriods[index].periods.length * PlanningGridCommonWidth;
        }
        return PlanningGridCommonWidth * 4;
    }, [])
    return (
        <>
            {
                isExpandedPeriods ?
                    <GridExpandedHeaderColumnContainer
                        height={80}
                        itemSize={gridHeaderWidth}
                        estimatedItemSize={PlanningGridCommonWidth * 4}
                        itemCount={complexPeriods.length}
                        onScroll={onScroll}
                        ref={ref}
                        itemData={{ complexPeriods, isWeekly }}
                    />
                    :
                    <GridCollapsedHeaderColumnContainer
                        height={80}
                        itemSize={PlanningGridCommonWidth}
                        itemCount={complexPeriods.length}
                        onScroll={onScroll}
                        ref={ref}
                        itemData={complexPeriods}
                    />
            }
        </>
    )
})

export default GridHeaderColumnContainer
