import { RequestSignalParameter } from "api";
import { deleteAsync, getAsync, postAsync, putAsync } from "..";
import axios from 'axios'
import { identityUrl } from "api/configuration";
//UserController



export const getUsersAsync = async (parameters?: RequestSignalParameter): Promise<any> => {
    return getAsync<any>({ url: '/user/', ...parameters });
}

export const getUserAsync = async (userId: any) => {
    return getAsync<any>({ url: `/user/${userId}` });
}

export const createUserAsync = async (user) => {
    try {
        var { data } = await axios.post('user', user, { baseURL: identityUrl })
        return data
    }
    catch(ex) {
        return {
            type: "error",
            message: "Falha na solicitação",
            ex
        }
    }
}

export const updateUserAsync = async (user) => {
    try {
        var { data } = await axios.put('user', user, { baseURL: identityUrl })
        return data
    }
    catch(ex) {
        return {
            type: "error",
            message: "Falha na solicitação",
            ex
        }
    }
}

export const deleteUserAsync = async (userId: Number, parameters?: RequestSignalParameter) => {
    return deleteAsync({ url: `/user/${userId}`, ...parameters });
}

//TODO create async function
//TODO utilizar promissePost
// export const uploadUserPictureAsync = async (file: File | undefined) => {
//     if(file === undefined)
//         return;

//         const formData = new FormData();
//         formData.append("filename", file, file.name);
//         formData.append("destination", "images");

//         const headers : HeadersInit  = {
//             "content-type": "multipart/form-data"
//         };

//     return postAsync('/users/upload', {formData: formData}, headers)
// }