import { t } from "@lingui/macro";
import ProgressBar from "@ramonak/react-progress-bar";
import { tryCancelRunningTaskProcedureAsync } from "api/scheduler/lib/task";
import nPlanModalRender from "components/Modals";
import { MdOutlineCancelScheduleSend } from "react-icons/md";
import { clearPipelineProgress } from "redux/AppNavigationState";
// import { useEffect } from "react";
// import { useStopwatch } from "react-timer-hook";
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { notifyPromise } from "utils/notification";

function ProcedureBarPanel() {
    const dispatch = useAppDispatch();

    const pipelineProgress = useAppSelector((state) => state.appNavigationState.pipeline.pipelineProgress)
    const isPipelineRunning = useAppSelector((state) => state.appNavigationState.pipeline.isPipelineRunning)




    const handleOnCancelClick = () => {
        nPlanModalRender.question({
            headerText: t`Deseja finalizar a procedure?`,
            acceptButtonText: t`Confirmar`,
            cancelButtonText: t`Cancelar`,
            acceptCallbackAsync: async () => {
                notifyPromise(
                    tryCancelRunningTaskProcedureAsync(),
                    {
                        pending: t`Cancelando procedure.`,
                        success: t`Procedure cancelada!`,
                        error: t`Não existe procedure sendo executada!`
                    }
                ).then((response) => {
                    dispatch(clearPipelineProgress());
                })
            }
        });
    }
    if (!isPipelineRunning) return <></>

    return (
        <>
            <div className="flex flex-row items-center gap-3 ">
                <div className={`flex flex-col w-full h-full text-white `}>
                    {/* <div className={`${isPipelineRunning ? 'visible' : 'invisible'} flex flex-col w-full h-full text-white truncate`}> */}
                    <div className='ml-4 flex h-1/3'>
                        <div className='inline-block text-sm font-semibold text-yellow-500'>{pipelineProgress.title}</div>
                    </div>
                    <div className='ml-4 mr-1 flex h-1/3'>
                        <div className='inline-block text-sm flex-1 font-semibold'>
                            {pipelineProgress.message ? `${pipelineProgress.message} - ${pipelineProgress.percentage}` : null}
                        </div>
                    </div>
                    {/* <ProgressBar
                    className='mx-4 h-1/3'
                    bgColor="#FBBF24"
                    labelColor="#FFFFFF"
                    transitionDuration="0.1s"
                    // isLabelVisible={false}
                    // label={pipelineProgress.percentage}
                    completed={Number(pipelineProgress.percentage)}
                    maxCompleted={100}
                /> */}
                    {/* completed={Number(pipelineProgress.percentage) + seconds + minutes * 60} /> */}
                </div>
                <MdOutlineCancelScheduleSend className="flex h-12 w-12 text-white cursor-pointer" onClick={handleOnCancelClick} />

            </div>
        </>
    )
}

export default ProcedureBarPanel
