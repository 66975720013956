import { i18n } from "@lingui/core"
import { t } from "@lingui/macro"
import { SelectCompanyMenuProps, StockPolicyAxesType, StockStatusType, ToolTipOccupationPageOptions, ToolTipPlanningGridOptions } from "api/data/enums"

export const stockStatusTypeToLanguage = (stockStatusType: StockStatusType) => {
    switch (stockStatusType) {
        case StockStatusType.Normal:
            return i18n.t(`Normal`)
        case StockStatusType.Excess:
            return i18n.t(`Excesso`)
        case StockStatusType.High:
            return i18n.t(`Alto`)
        case StockStatusType.Locked:
            return i18n.t(`Trancado`)
        case StockStatusType.MTO:
            return i18n.t(`MTO`)
        case StockStatusType.Shortage:
            return i18n.t(`Falta`)
        case StockStatusType.Stockout:
            return i18n.t(`Ruptura`)
        case StockStatusType.Risk:
        default:
            return i18n.t(`Risco`)
    }
}
export const stockPolicyAxesTypeToLanguage = (stockPolicyAxesType: StockPolicyAxesType) => {
    switch (stockPolicyAxesType) {
        case StockPolicyAxesType.Complexity:
            return i18n.t(`Complexidade`)
        case StockPolicyAxesType.Uncertainty:
            return i18n.t(`Incerteza`)
        case StockPolicyAxesType.Volume:
        default:
            return i18n.t(`Volume`)
    }
}

export const stockPolicyCompanyOptionsToLanguage = (stockPolicyCompanyName: string) => {
    if (stockPolicyCompanyName === SelectCompanyMenuProps.All) {
        return i18n.t(`Todas`)
    } else {
        return stockPolicyCompanyName
    }

}
export const planningGridToolTipOptionsToLanguage = (plannigGridToolTipOptions: ToolTipPlanningGridOptions) => {
    switch (plannigGridToolTipOptions) {
        case ToolTipPlanningGridOptions.multipleEdit:
            return i18n.t(`Edição Multipla`)
        case ToolTipPlanningGridOptions.periodChanger:
            return i18n.t(`Mensal/Semanal`)
        case ToolTipPlanningGridOptions.removeFilters:
            return i18n.t(`Limpar Filtros`)
        case ToolTipPlanningGridOptions.selectAll:
            return i18n.t(`Selecionar Todos`)
        case ToolTipPlanningGridOptions.freezePanels:
            return i18n.t(`Congelar/Descongelar Gráfico`)
        case ToolTipPlanningGridOptions.hideUnhide:
            return i18n.t(`Esconder/Mostrar Gráfico`)
        case ToolTipPlanningGridOptions.occupationPage:
        default:
            return i18n.t(`Visão de Ocupação`)
    }
}
export const OccupationPageToolTipOptionsToLanguage = (occupationPageToolTipOptions: ToolTipOccupationPageOptions) => {
    switch (occupationPageToolTipOptions) {
        case ToolTipOccupationPageOptions.reset:
            return i18n.t(`Resetar`)
        case ToolTipOccupationPageOptions.calculateWithoutAnimation:
            return i18n.t(`Calcular sem animação`)
        case ToolTipOccupationPageOptions.calculateWithAnimation:
            return i18n.t(`Calcular com animação`)
        case ToolTipOccupationPageOptions.pauseAnimation:
        default:
            return i18n.t(`Parar animação`)
    }
}
