import { selectMaterialFlowItemDataByItemId } from 'redux/AppScenarioState';
import { useAppSelector } from 'redux/hooks';
import MaterialFlowItemRow from './MaterialFlowItemRow';

interface Props {
    itemId: number
    isExpandedProductRow: boolean
}

function MaterialFlowItemRowContainer(props: Props) {
    const { itemId, isExpandedProductRow } = props
    const completeStructureOrderDataByItemMestre = useAppSelector(state => state.appScenarioState.completeStructureOrderDataByItemMaster[itemId]);
    const materialFlowData = useAppSelector(state => selectMaterialFlowItemDataByItemId(state.appScenarioState, itemId));
    // const orderedItemIds = useAppSelector(state => state.appScenarioState.filterData.orderedItemIds)

    if (materialFlowData === undefined || materialFlowData.length === 0) return <></>
    return (
        <>
            {
                completeStructureOrderDataByItemMestre &&
                completeStructureOrderDataByItemMestre.isExpanded &&
                completeStructureOrderDataByItemMestre.data.map(
                    (value, index) => {
                        // if (!orderedItemIds.includes(materialFlowData[index].materialItemId)) {
                        //     return <></>
                        // }
                        const paddingLeft = value.level * 15
                        return (
                            <div key={`${value.itemId}-${index}`} style={
                                {
                                    // paddingLeft: paddingLeft,
                                    // width: 640
                                }
                            } className={`h-60 flex-col w-full `}>
                                <MaterialFlowItemRow
                                    {...materialFlowData[index]}
                                    isExpandedProductRow={isExpandedProductRow}
                                    itemId={itemId}
                                    paddingLeft={paddingLeft}
                                />

                            </div>
                        )
                    }
                )
            }
        </>
    )
}

export default MaterialFlowItemRowContainer
