import { RequestParamsParameters, RequestSignalParameter } from "api";
import { deleteAsync, getAsync, putAsync } from "..";
import axios from 'axios'
import { identityUrl } from "api/configuration";
//SessionController


export const getRolesPermissionsAsync = async (parameters?: RequestSignalParameter): Promise<any> => {
    return getAsync<any>({ url: '/rolepermission/', ...parameters });
}

export const getRolePermissionsAsync = async (roleId: any, parameters?: RequestSignalParameter): Promise<any> => {
    return getAsync<any>({ url: `/rolepermission/${roleId}`, ...parameters });
}


export const putRolePermissionsAsync = async (rolepermissions) => {
    return putAsync('/rolepermission', rolepermissions)
}

