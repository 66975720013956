import { useAppSelector } from "redux/hooks";
import { AttributesDropDownContainerMemoized } from "../components/AttributesDropDownContainer";
import { FixedCriteriasDropDownContainerMemoized } from "../components/FixedCriteriasDropDownContainer";

export function PlanningGridPanelFilterModule() {
	useAppSelector((state) => state.appScenarioState.forceRedraw.FilterModule);
	return (
		<>
			<FixedCriteriasDropDownContainerMemoized />
			<AttributesDropDownContainerMemoized />
		</>
	);
}
export default PlanningGridPanelFilterModule;
