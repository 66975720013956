import ImagePlaceholder from 'components/placeholder/ImagePlaceholder'
import { OccupationSelectedElementData } from 'redux/AppScenarioState/types'
import { useAppSelector } from 'redux/hooks'


function SelectedProductPanel() {
    const selectedChartElement: OccupationSelectedElementData | undefined = useAppSelector((state) => state.appScenarioState.selectedChartElement)
    const totalTime = !selectedChartElement ? 1 : selectedChartElement.processTime + selectedChartElement.setupTime// protect from division by zero
    const setupTimeText = !selectedChartElement ? 1 : `${(selectedChartElement.setupTime / totalTime) * 100}%`
    const processTimeText = !selectedChartElement ? 1 : `${(selectedChartElement.processTime / totalTime) * 100}%`

    return (
        <>
            {
                selectedChartElement &&
                <div className='flex w-full h-full items-center align-middle m-2 bg-slate-200 rounded-lg px-2'>
                    <div className='flex-col p-2 w-auto h-full flex-shrink-0 space-y-3'>
                        <div className='rounded-md w-20 h-20 border-2'>
                            <ImagePlaceholder index={selectedChartElement.itemId} />
                        </div>
                        <div className='rounded-md text-center w-20 h-8 border-2'>
                            <p>{selectedChartElement.itemId}</p>
                        </div>
                    </div>
                    <div className='inline-block w-full h-full'>
                        <div className='block text-xl font-black'>
                            {selectedChartElement.resourceGroupName}
                        </div>
                        <div className='block text-lg font-bold'>
                            {selectedChartElement.resourceName}
                        </div>
                        <div className='flex-col w-full text-lg font-medium'>
                            <div className='flex h-full '>
                                <span style={{ width: '10%' }} className="shadow-none flex flex-col text-center whitespace-nowrap  justify-center bg-red-500 rounded-l-lg">
                                    Setup Time: {selectedChartElement.setupTime}
                                </span>
                            </div>
                            <div className='flex h-full '>
                                <span style={{ marginLeft: '10%', width: '90%' }} className="shadow-none flex flex-col text-center whitespace-nowrap  justify-center bg-orange-500 rounded-r-lg">
                                    Process Time: {selectedChartElement.processTime}
                                </span>
                            </div>
                        </div>
                        <div className='block w-full h-full relative pt-1'>
                            <div className="overflow-hidden h-4 mb-4 text-xs flex rounded bg-amber-200">
                                <div style={{ width: setupTimeText }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500">{setupTimeText}</div>
                                <div style={{ width: processTimeText }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-orange-500">{processTimeText}</div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default SelectedProductPanel
