import axios, { AxiosRequestHeaders } from 'axios'
export interface BaseRequestParameters extends RequestPathParameters {
    baseUrl?: string
    responseType?: any
}
export interface RequestPathParameters extends RequestParamsParameters<any, any> {
    url: string
    header?: AxiosRequestHeaders
}
export interface RequestParamsParameters<TParams, TBody> extends RequestSignalParameter {
    queryParams?: TParams
    body?: TBody
}
export interface RequestSignalParameter {
    signal?: AbortSignal
}

export const getBaseAsync = async <TReturn>(parameters: BaseRequestParameters): Promise<TReturn | any> => {
    return (await axios.get<TReturn>(parameters.url,
        {
            responseType: parameters.responseType,
            params: parameters.queryParams,
            baseURL: parameters.baseUrl,
            signal: parameters.signal,
            data: parameters.body
        })).data
}
//TODO: https://localhost:6051/api/items/images?imageName=3000-003-PP
//tras tipo de erro que n~ao 'e tratado no backend, ver como tratar no front erros assim.'
export const postBaseAsync = async <TBody>(parameters: BaseRequestParameters): Promise<TBody | any> => {
    return (await axios.post<TBody>(parameters.url,
        parameters.body,
        {
            baseURL: parameters.baseUrl,
            headers: parameters.header,
            signal: parameters.signal,
            params: parameters.queryParams,
        })).data
}

export const deleteBaseAsync = async <TReturn>(parameters: BaseRequestParameters): Promise<TReturn | any> => {
    return (await axios.delete<TReturn>(parameters.url,
        {
            baseURL: parameters.baseUrl,
            signal: parameters.signal,
            params: parameters.queryParams,
            data: parameters.body
        })).data
}

export const putBaseAsync = async <TReturn>(parameters: BaseRequestParameters): Promise<TReturn | any> => {
    return (await axios.put<TReturn>(parameters.url,
        parameters.body,
        {
            baseURL: parameters.baseUrl,
            headers: parameters.header,
            signal: parameters.signal,
            params: parameters.queryParams,
        })).data
}