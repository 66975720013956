import { createDictionary } from "redux/helpers";
import { AppScenarioState, ResourceAggregator } from "../types";
import { ResourceSearchCriteriasDictionary, resourceFilterCriteria } from "./types";
import { ResourceGroupDetails } from "api/data/types";


// export const FilterCriteriaToSelector = (filterCriteria: FilterCriteria) => {
//     switch (filterCriteria) {
//         case FilterCriteria.ItemAttribute1:
//             return selectItemAttribute1SearchTree
//         case FilterCriteria.ItemAttribute2:
//             return selectItemAttribute2SearchTree
//         case FilterCriteria.ItemAttribute3:
//             return selectItemAttribute3SearchTree
//         case FilterCriteria.ItemAttribute4:
//             return selectItemAttribute4SearchTree
//         case FilterCriteria.ItemAttribute5:
//             return selectItemAttribute5SearchTree
//         case FilterCriteria.ItemAttribute6:
//             return selectItemAttribute6SearchTree
//         case FilterCriteria.ItemAttribute7:
//             return selectItemAttribute7SearchTree
//         case FilterCriteria.ItemAttribute8:
//             return selectItemAttribute8SearchTree
//         case FilterCriteria.ItemAttribute9:
//             return selectItemAttribute9SearchTree
//         case FilterCriteria.ItemAttribute10:
//             return selectItemAttribute10SearchTree
//         case FilterCriteria.ItemCode:
//         default:
//             return selectItemCodeSearchTree;
//         case FilterCriteria.ItemPlanner:
//             return selectItemPlannerSearchTree;
//         case FilterCriteria.ItemStockGroup:
//             return selectItemStockGroupSearchTree;
//         case FilterCriteria.Level:
//             return selectItemLevelSearchTree;
//         case FilterCriteria.StockStatusTypeWithPeriod:
//             return selectStockStatusWithPeriodTypeSearchTree;

//         //Being used on OccupationPage
//         // case FilterCriteria.OccupationPageResourceAndPeriod:
//         // case FilterCriteria.OccupationPageResourceGroup:
//         // case FilterCriteria.ResourceGroup:
//     }
// }
//TODO checar se é performatico
export const intersectCustom = (a, b): number[] => {
    if (!a) return Array.from(new Set(b));
    if (!b) return Array.from(new Set(a));
    return Array.from(new Set(a.filter(Set.prototype.has, new Set(b))));
}

export const applyResourceCriteriaByFilterCriteria = (appScenarioState: AppScenarioState): {
    resourcesIdsToSelect: number[],
    resourcesFiltered: ResourceAggregator[]
} => {
    let resourceGroupDetails: ResourceGroupDetails[] = appScenarioState.resourceGroupDetails;
    let resourceGroupToSelect: ResourceAggregator[] = appScenarioState.resourcesToSelect


    let resourcesIdsToSelect: number[] = appScenarioState.resourcesToSelect.map(resource => resource.id)
    let searchCriteriasData: ResourceSearchCriteriasDictionary<any> = appScenarioState.filterResourceData.resourceSearchCriteriasData;
    if (searchCriteriasData) {
        for (const criteriaKey of Object.keys(searchCriteriasData)) {
            let finalResourceIds: number[] = [];
            if (searchCriteriasData[criteriaKey]) {
                switch (criteriaKey) {
                    case resourceFilterCriteria.finiteCapacity:
                        finalResourceIds = createResourcesSelectionIdsFilteredByFiniteCapacity(resourceGroupDetails)
                        break
                    case resourceFilterCriteria.processTime:
                        finalResourceIds = appScenarioState.filterResourceData.resourceWithProcessTime.map(resource => resource.id)
                        break;
                    case resourceFilterCriteria.None:
                        finalResourceIds = resourcesIdsToSelect
                        break;
                    default:
                        throw Error(`Unknown criteria: ${criteriaKey}`);
                }
            }
            if (finalResourceIds.length > 0)
                resourcesIdsToSelect = intersectCustom(resourcesIdsToSelect, finalResourceIds);
        }

    }

    const localDictionary = createDictionary(resourceGroupToSelect, x => x.id)
    const resourcesFiltered: ResourceAggregator[] = []

    for (const [key, value] of Object.entries(localDictionary)) {
        const resourceId = Number(key)
        const localResourceAggregattor = value

        for (let i = 0; i < resourcesIdsToSelect.length; i++) {
            if (resourceId === resourcesIdsToSelect[i]) {
                resourcesFiltered.push(localResourceAggregattor)
            }
        }
    }



    return {
        resourcesIdsToSelect: resourcesIdsToSelect,
        resourcesFiltered: resourcesFiltered
    };
}

export const createResourcesSelectionIdsFilteredByFiniteCapacity = (planningGroups: ResourceGroupDetails[]) => {

    let resourceSelectionIds: number[] = []
    for (let i = 0; i < planningGroups.length; i++) {
        const planningGroup = planningGroups[i];
        for (let a = 0; a < planningGroup.resources.length; a++) {
            const planningResource = planningGroup.resources[a];
            if (planningResource.finiteCapacity === true) {
                resourceSelectionIds.push(planningResource.id,)
            }
        }
    }
    return resourceSelectionIds;
}

export const createResourcesSelectionFilteredByFiniteCapacity = (planningGroups: ResourceGroupDetails[]) => {

    let resourceSelection: ResourceAggregator[] = []
    for (let i = 0; i < planningGroups.length; i++) {
        const planningGroup = planningGroups[i];
        for (let a = 0; a < planningGroup.resources.length; a++) {
            const planningResource = planningGroup.resources[a];
            if (planningResource.finiteCapacity === true) {
                resourceSelection.push({
                    resourceGroupName: planningGroup.name,
                    resourceName: planningResource.name,
                    id: planningResource.id,
                    finiteCapacity: planningResource.finiteCapacity,
                })
            }
        }
    }
    return resourceSelection;
}


