export function sortArrayByTypeKeyPriority(array) {
  const comparator = (a, b) => a.type.priority - b.type.priority;

  // Create a copy of the array using spread syntax
  const newArray = [...array];

  // Use the Array.sort() method with a custom comparator function
  newArray.sort(comparator);

  return newArray;
}
