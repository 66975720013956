import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ScenarioSummarized, ScenarioSummarizedDetails } from "api/data/types";
import { ActionType } from "redux/types";
import { loadGetAvailableScenarioIdsForScenarioSummarizedAsync, loadScenarioSummarizedByScenarioIdAsync } from ".";
import { initialState } from "./initialState";
import { ScenarioSummarizedData } from "redux/AppScenarioState/dataTypes";

export const appSumarizedSlice = createSlice({
    name: ActionType.AppSumarizedState,
    initialState,
    reducers: {
        // recalculateSumarizedPeriodGroup: (state) => {
        //     let periodGroups = state.scenarioSummarizedByScenarioId.selectedPeriodGroups

        //     let localSumarizedData = createSumarizedDataList(state.scenarioSummarizedByScenarioId.sumarizedData);

        //     //Calculate new sumarizedData
        //     localSumarizedData = recalculateManySumarizedDataByPeriodGroups(localSumarizedData, periodGroups)
        //     for (let i = 0; i < localSumarizedData.length; i++) {
        //         const sumarizedData = localSumarizedData[i];
        //         state.scenarioSummarizedByScenarioId.sumarizedTreatedData[sumarizedData.dataset.id] = sumarizedData
        //     }
        // },
        // setSumarizedPeriodGroup: (state, action: PayloadAction<number>) => {

        //     let periodGroups = state.scenarioSummarizedByScenarioId.selectedPeriodGroups
        //     const groupIndex = action.payload
        //     if (periodGroups.find(x => x === groupIndex)) {
        //         let foundIndex = periodGroups.findIndex(x => x === groupIndex)
        //         periodGroups.splice(foundIndex, 1)
        //     } else {
        //         periodGroups.push(groupIndex)
        //     }

        //     let localSumarizedData = createSumarizedDataList(state.scenarioSummarizedByScenarioId.sumarizedData);

        //     //Calculate new sumarizedData
        //     localSumarizedData = recalculateManySumarizedDataByPeriodGroups(localSumarizedData, periodGroups)
        //     for (let i = 0; i < localSumarizedData.length; i++) {
        //         const sumarizedData = localSumarizedData[i];
        //         state.scenarioSummarizedByScenarioId.sumarizedTreatedData[sumarizedData.dataset.id] = sumarizedData
        //     }
        // },
        // collapseAllSumarizedPeriodGroup: (state, action: PayloadAction<boolean>) => {
        //     let periodGroups = state.scenarioSummarizedByScenarioId.selectedPeriodGroups

        //     let localSumarizedData = createSumarizedDataList(state.scenarioSummarizedByScenarioId.sumarizedData);
        //     periodGroups = localSumarizedData[0].singlePeriods.map(x => x.rank);

        //     //Calculate new sumarizedData
        //     localSumarizedData = recalculateManySumarizedDataByPeriodGroups(localSumarizedData, periodGroups)
        //     for (let i = 0; i < localSumarizedData.length; i++) {
        //         const sumarizedData = localSumarizedData[i];
        //         state.scenarioSummarizedByScenarioId.sumarizedTreatedData[sumarizedData.dataset.id] = sumarizedData
        //     }
        // },
        // removeTreatedSumarizedData: (state, action: PayloadAction<{ name: string, id: number }>) => {
        //     state.scenarioSummarizedByScenarioId.selectedSumarizedData = state.scenarioSummarizedByScenarioId.selectedSumarizedData.filter(x => x.id !== action.payload.id)
        // },
        // addTreatedSumarizedData: (state, action: PayloadAction<{ name: string, id: number }>) => {
        //     let dataset = state.scenarioSummarizedByScenarioId.sumarizedTreatedData[action.payload.id].dataset;
        //     state.scenarioSummarizedByScenarioId.selectedSumarizedData.push({ id: dataset.id, name: dataset.name })
        // }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadScenarioSummarizedByScenarioIdAsync.fulfilled, (state, action) => {
                const scenarioSummarizedData: ScenarioSummarizedData[] = action.payload;

                state.scenarioSummarizedByScenarioId[scenarioSummarizedData[0].scenarioId] = scenarioSummarizedData
                // console.log(scenarioSummarizedDetails)

                // let scenarioId: number = scenarioSummarizedDetails[0].scenarioId
                // let newSumarizedData = createSumarizedData(scenarioSummarizedDetails);
                // state.scenarioSummarizedByScenarioId.sumarizedData[scenarioId] = newSumarizedData;
                // state.scenarioSummarizedByScenarioId.sumarizedTreatedData[scenarioId] = newSumarizedData;
                // state.scenarioSummarizedByScenarioId.selectedSumarizedData.push({ id: scenarioId, name: action.payload[0].scenario.name })

                // if (state.scenarioSummarizedByScenarioId.selectedPeriodGroups.length === 0) {
                //     state.scenarioSummarizedByScenarioId.selectedPeriodGroups = newSumarizedData.singlePeriods.map(x => x.rank)
                // }
                // //TODO melhorar forma de calcular
                // let localSumarizedData = createSumarizedDataList(state.scenarioSummarizedByScenarioId.sumarizedData)
                // //Calculate new sumarizedData
                // localSumarizedData = recalculateManySumarizedDataByPeriodGroups(localSumarizedData, state.scenarioSummarizedByScenarioId.selectedPeriodGroups)
                // for (let i = 0; i < localSumarizedData.length; i++) {
                //     const sumarizedData = localSumarizedData[i];
                //     state.scenarioSummarizedByScenarioId.sumarizedTreatedData[sumarizedData.dataset.id] = sumarizedData
                // }
            })
            .addCase(loadGetAvailableScenarioIdsForScenarioSummarizedAsync.fulfilled, (state, action) => {
                const availableScenarioIds: number[] = action.payload;
                state.availableScenarioIds = availableScenarioIds;
            })
    },
})

export const {
    // setSumarizedPeriodGroup,
    // collapseAllSumarizedPeriodGroup,
    // recalculateSumarizedPeriodGroup,
    // removeTreatedSumarizedData,
    // addTreatedSumarizedData,
} = appSumarizedSlice.actions;