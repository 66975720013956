import { RequestParamsParameters, RequestSignalParameter } from "api";
import { PlanningGrid, Scenario, ScenarioDetails, ScenarioStatus } from "api/data/types"
import { postAsync, getAsync, putAsync, deleteAsync } from ".."
import { ScenarioCreationDataset } from "./types";

const route = 'scenarios'

// ScenarioController
export function getScenarioByIdAsync(scenarioId: number, parameters?: RequestSignalParameter): Promise<Scenario> {
    return getAsync({ url: `/${route}/${scenarioId}`, ...parameters })
}
export function getScenarioDetailsByIdAsync(scenarioId: number, parameters?: RequestSignalParameter): Promise<ScenarioDetails> {
    return getAsync({ url: `/${route}/${scenarioId}/details`, ...parameters })
}
export function getScenariosAsync(parameters?: RequestSignalParameter): Promise<Scenario[]> {
    return getAsync({ url: `/${route}/`, ...parameters })
}
export function getScenariosDetailsAsync(parameters?: RequestSignalParameter): Promise<ScenarioDetails[]> {
    return getAsync({ url: `/${route}/details`, ...parameters })
}
export function getScenarioStatusByIdAsync(scenarioId: number, parameters?: RequestSignalParameter): Promise<ScenarioStatus> {
    return getAsync({ url: `/${route}/${scenarioId}/status`, ...parameters })
}


export function createScenarioAsync(parameters?: RequestParamsParameters<null, ScenarioCreationDataset>): Promise<Scenario> {
    return postAsync({ url: `/${route}/`, ...parameters });
}

export function setScenarioToCurrentStatusAsync(scenarioId: number, parameters?: RequestSignalParameter): Promise<Scenario> {
    return postAsync({ url: `/${route}/${scenarioId}/set-to-current`, ...parameters });
}

//TODO checar, pois se retorna NoContent na vdd
export function updateScenarioByIdAsync<TReturn>(scenarioId: number, parameters: RequestParamsParameters<null, TReturn>): Promise<TReturn | null> {
    return putAsync<TReturn>({ url: `/${route}/${scenarioId}`, ...parameters })
}
export function deleteScenarioByIdAsync(scenarioId: number, parameters?: RequestSignalParameter): Promise<void> {
    return deleteAsync<Scenario>({ url: `/${route}/${scenarioId}`, ...parameters });
}
export function deleteScenariosByIdsAsync(parameters: RequestParamsParameters<null, number[]>): Promise<void> {
    return deleteAsync<Scenario>({ url: `/${route}`, ...parameters });
}
export const saveScenarioAsAsync = async (scenarioId: number, parameters?: RequestParamsParameters<null, { scenarioName: string, planningGrids: PlanningGrid[] }>): Promise<number> => {
    return postAsync({ url: `/${route}/${scenarioId}/clone`, ...parameters });
}