import { DependencyList, Dispatch, EffectCallback, MutableRefObject, SetStateAction, useEffect, useRef, useState } from "react";
import { setModalState, setModalData } from "redux/AppNavigationState";
import { ModalType } from "redux/AppNavigationState/types";
import { useAppDispatch } from "redux/hooks";
import { UserChangeableValue } from "./types";

export const NeoYellow = "#f49e2e"
export function useUserChangeableState<S>(value: S): [UserChangeableValue<S>, Dispatch<SetStateAction<UserChangeableValue<S>>>] {
    return useState<UserChangeableValue<S>>({ value: value, changedByUser: false })
}

export const useDelayedUseEffect = (effect: EffectCallback, delayTimer: number, deps?: DependencyList) => {
    useEffect(() => {
        const delayedEffect = setTimeout(() => effect(), delayTimer);
        return () => clearTimeout(delayedEffect)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps)
}

export const hashCode = (value: string) => {
    var hash = 0;
    if (value.length === 0) return hash;
    for (let i = 0; i < value.length; i++) {
        let char = value.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
}

let initialId: number = 0
export const useUniqueId = (prefix?: string): string => {
    prefix === '' ? prefix = String(hashCode(String(initialId))) : prefix = '';
    return `${prefix}-${initialId++}`
}

export const useModalManager = () => {
    const dispatch = useAppDispatch();
    function handleModalState(modalType: ModalType, data?: any, callback?: () => void) {
        dispatch(setModalState(modalType))
        if (data) dispatch(setModalData({ data: data, callback: callback }))
    }
    return { handleModalState }
}

export function useHover<T>(): [MutableRefObject<T>, boolean] {
    const [value, setValue] = useState<boolean>(false);
    const ref: any = useRef<T | null>(null);
    const handleMouseOver = (): void => setValue(true);
    const handleMouseOut = (): void => setValue(false);
    useEffect(
        () => {
            const node: any = ref.current;
            if (node) {
                node.addEventListener("mouseover", handleMouseOver);
                node.addEventListener("mouseout", handleMouseOut);
                return () => {
                    node.removeEventListener("mouseover", handleMouseOver);
                    node.removeEventListener("mouseout", handleMouseOut);
                };
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [ref.current] // Recall only if ref changes
    );
    return [ref, value];
}



export function getLabelWidth(text) {
    const label = document.createElement("label");
    label.style.position = "absolute";
    label.style.top = "-9999px";
    label.style.left = "-9999px";
    label.style.visibility = "hidden";
    label.style.whiteSpace = "nowrap";
    label.style.fontWeight = "400";
    label.style.fontSize = "1rem";
    label.style.lineHeight = "1.5"
    label.style.letterSpacing = "0.00938em"
    label.style.fontFamily = '"Roboto","Helvetica","Arial",sans-serif'
    label.textContent = text;
    document.body.appendChild(label);
    const width = label.offsetWidth;
    document.body.removeChild(label);
    return width;
}

export const isNullOrEmpty = (text) => {
    return text == null || text.trim() == ""
}

export const getContrastColor = (hexColor) => {
    if (hexColor == null)
        return "white"
    hexColor = hexColor.replace("#", "");
    const r = parseInt(hexColor.substr(0, 2), 16);
    const g = parseInt(hexColor.substr(2, 2), 16);
    const b = parseInt(hexColor.substr(4, 2), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness <= 128 ? "white" : "black";
}