import React from 'react'
import * as filters from './Filters'
export default function TableEditionDataHeaderFilter(props: any) {
    return  (

        <div 
            style={{   
                position: "absolute", 
                left: `${props.column.init}px`, 
                width: `${props.column.width}px`, 
                height: "40px", 
                top: "21px",
                textAlign: "left", 
                textOverflow: "ellipsis", 
                whiteSpace: "nowrap",
                overflow: "hidden",
                padding: "0 4px",
                paddingTop: "5px"
            }}
            draggable
        >
            <div style={{display: "flex", flexDirection: "row"}}>   
                <div style={{ width: "100%", height: "40px", display: "flex", flexDirection: "row", justifyContent: "center" }}>
                    {props.column.filterType === 1 && <filters.NumberFilter column={props.column} setFilter={props.setFilter} />}
                    {props.column.filterType === 2 && <filters.TextFilter column={props.column} setFilter={props.setFilter} />}
                    {props.column.filterType === 3 && <filters.BooleanFilter column={props.column} setFilter={props.setFilter} />}
                    {props.column.filterType === 4 && <filters.DateFilter column={props.column} data={props.data} setFilter={props.setFilter} />}
                    {props.column.filterType === 5 && <filters.SelectFilter column={props.column} foreignFields={props.foreignFields} data={props.data} setFilter={props.setFilter} />}
                </div>
            </div>
            
        </div>
    )               
}