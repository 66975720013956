import React from 'react'
import { TextField, Box, Select, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Switch, Button } from '@mui/material';
import { getRoleAsync, getRolesAsync } from 'api/user/lib/role';
import { getUserAsync } from 'api/user';

const ManageUser = ({open, onClose, currentEditing, onRefreshData}) => {
    const [user, setUser] = React.useState<any>({
        username: "",
        firstName: "",
        lastName: "",
        domain: "",
        roleId: "",
        email: "",
        activated: true,
        blocked: false,
        isAD: true,
        id: 0
    })
    const [roles, setRoles ] = React.useState<any>([]);

    const handleChange = (event: any) => {
        const value = event.target.type == 'checkbox' ? event.target.checked : event.target.value
        const field = event.target.name;
        setUser(prevUser => ({...prevUser, [field]: value}))
    }

    const handleSave = async () => {
        // const res = user['id'] == 0 ? await postUserAsync(user) :  await putUserAsync('user', user)
        // if(res['type'] == 'success'){
        //     onRefreshData();
        //     onClose();
        // }
            
    }
    React.useEffect(() => {
        (async () => {
            if (currentEditing == null) {
                setUser({
                    username: "",
                    firstName: "",
                    lastName: "",
                    domain: "",
                    roleId: 1,
                    activated: true,
                    blocked: false,
                    isAD: true,
                    id: 0,
                    email: ""
                });
            }
            else {
                var res = await getUserAsync(currentEditing);
                if(res['type'] === 'success')
                    setUser(res['data'])
            }
        })()
    }, [currentEditing])
    React.useEffect(() => {
        (async () => {
            var res = await getRolesAsync()
            if(res['type'] === 'success')
                setRoles(res['data']);
        })()
    },[])
    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>
                {user['id'] == 0 ? 'Criar' : 'Editar'} Usuário
            </DialogTitle>
            <DialogContent>
                <Box display={'flex'} flexDirection={'column'} gap={1} width={300}>
                    <Box>
                        <Typography variant='caption'>Login</Typography>
                        <TextField
                            fullWidth
                            name="username"
                            value={user['username']}
                            onChange={handleChange}
                            variant='standard'
                            size='small'
                        />
                    </Box>
                    <Box>
                        <Typography variant='caption'>Grupo</Typography>
                        <Select
                            fullWidth
                            variant='standard'
                            value={user['roleId']}
                            onChange={handleChange}
                            name="roleId"
                            size='small'
                        >
                            {roles.map(role => 
                                <MenuItem key={role['id']} value={role['id']}>{role['name']}</MenuItem>    
                            )}
                        </Select>
                    </Box>
                    <Box>
                        <Typography variant='caption'>Email</Typography>
                        <TextField
                            fullWidth
                            name="email"
                            value={ user['email'] }
                            onChange={ handleChange }
                            variant='standard'
                            size='small'
                        />
                    </Box>
                    <Box>
                        <Typography variant='caption'>Nome</Typography>
                        <TextField
                            fullWidth
                            name="firstName"
                            value={ user['firstName'] }
                            onChange={ handleChange }
                            variant='standard'
                            size='small'
                        />
                    </Box>
                    <Box>
                        <Typography variant='caption'>Sobrenome</Typography>
                        <TextField
                            fullWidth
                            name="lastName"
                            value={ user['lastName'] }
                            onChange={ handleChange }
                            variant='standard'
                            size='small'
                        />
                    </Box>
                    <Box display={ 'flex' } flexDirection={ 'column' }>
                        <Typography variant='caption'>Ativo</Typography>
                        <Switch
                            checked={ user['activated'] }
                            name="activated"
                            onChange={ handleChange }
                        />
                    </Box>
                    <Box display={'flex'} flexDirection={'column'}>
                        <Typography variant='caption'>Active Directory</Typography>
                        <Switch
                            checked={user['isAD']}
                            name="isAD"
                            onChange={handleChange}
                        />
                    </Box>
                    <Box visibility={user['isAD'] == true ? 'visible' : 'hidden'}>
                        <Typography variant='caption'>Domínio</Typography>
                        <TextField
                            fullWidth
                            name="domain"
                            value={ user['domain'] }
                            onChange={ handleChange }
                            variant='standard'
                            size='small'
                        />
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Box width={'100%'} p={2} gap={2} display={'flex'} flexDirection={'column'}>
                    <Button fullWidth variant='contained' onClick={handleSave}>Salvar</Button>
                    <Button fullWidth variant='contained' color='error' onClick={onClose}>Cancelar</Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
}

export default ManageUser;