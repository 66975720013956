import { StockStatusType } from "api/data/enums";

export interface ProductCellData {
    itemCode: string,
    itemDesc: string,
    id: number,
    openingStock: number,
    targetStock: number,
    currentStock: number
}
export interface GridCellHeaderData {
    groupCount: number,
    rankCount: number
}

export interface PlanningChartData {
    closingStock: number,
    openingStock: number,
    orders: number,
    mps: number,
    demand: number,
    minStock: number,
    targetStock: number,
    maxStock: number
    safetyStock: number
    locked: boolean,
    stockStatus: StockStatusType
}

export const PlanningGridCommonWidth = 144;