import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getScriptsAsync } from "api/scheduler/lib/script";
import { getTaskByScriptIdAsync, getTaskDescriptionsAsync } from "api/scheduler/lib/task";
import { Script, ScriptButtonRoute, Task } from "api/scheduler/types";
import { ActionType, AsyncLoadProps, AsyncStatus } from "redux/types";
import update from 'immutability-helper'
import { getScriptButtonRouteAsync } from "api/scheduler/lib/buttons";
import { initialState } from "./initialState";
import { LoadingAppComponent, LoadingStateColor } from "redux/AppNavigationState/types";
import { baseProgressLoaderCreateAsyncThunk } from 'redux/helpers'
//Async calls
export const getScriptsDataAsync = createAsyncThunk(
    `${ActionType.AppSchedulerState}/getScriptsDataAsync`,
    async () => {
        const response = await getScriptsAsync();
        return response;
    }
);

export const updateTasksDataByScriptAsync = createAsyncThunk(
    `${ActionType.AppSchedulerState}/getTasksDataByScriptAsync`,
    async (scriptId: number) => {
        const response = await getTaskByScriptIdAsync(scriptId);
        return {
            scriptId: scriptId,
            tasks: response
        };
    }
);

export const setScriptButtonPathAsync = createAsyncThunk(
    `${ActionType.AppSchedulerState}/getScriptButtonRouteAsync`,
    async () => {
        const response = await getScriptButtonRouteAsync();
        return response;
    }
);

export const loadTaskDescriptionsAsync = createAsyncThunk(
    `${ActionType.AppSchedulerState}/loadTaskDescriptionsAsync`,
    async (props: AsyncLoadProps, { dispatch }) => {
        const { abortSignal } = props
        return await baseProgressLoaderCreateAsyncThunk(
            dispatch,
            {
                ...props,
                loadingStateColor: LoadingStateColor.Yellow,
                loadingAppComponent: LoadingAppComponent.TaskDescriptions
            },
            () => getTaskDescriptionsAsync({ signal: abortSignal })
        )
    }
);


//Slice
export const appSchedulerSlice = createSlice({
    name: ActionType.AppSchedulerState,
    initialState,
    reducers: {
        setScripts: (state, action: PayloadAction<Script[]>) => {
            state.scripts.value = action.payload;
        },
        setTasksOnScript: (state, action: PayloadAction<{ scriptId: number, tasks: Task[] }>) => {
            let scriptIndex = state.scripts.value.findIndex(x => x.id === action.payload.scriptId);
            state.scripts.value[scriptIndex].tasks = action.payload.tasks;
        },
        updateScriptTaskOrder: (state, action: PayloadAction<{ scriptId: number, tasks: Task[], dragIndex: number, hoverIndex: number }>) => {
            const { scriptId, tasks, dragIndex, hoverIndex } = action.payload;
            let scriptIndex = state.scripts.value.findIndex(x => x.id === scriptId);
            state.scripts.value[scriptIndex].tasks = update(tasks, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, tasks[dragIndex]],
                ],
            })
        },
        removeScriptById: (state, action: PayloadAction<number>) => {
            state.scripts.value = state.scripts.value.filter(x => x.id !== action.payload);
        },
        setScriptButtonRoute: (state, action: PayloadAction<ScriptButtonRoute[]>) => {
            state.scriptButtonRoute.value = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getScriptsDataAsync.pending, (state) => {
                state.scripts.status = AsyncStatus.LOADING;
            })
            .addCase(getScriptsDataAsync.fulfilled, (state, action) => {
                state.scripts.value = action.payload;
                state.scripts.status = AsyncStatus.READY;
            })
            .addCase(updateTasksDataByScriptAsync.pending, (state) => {
                state.scripts.status = AsyncStatus.LOADING;
            })
            .addCase(updateTasksDataByScriptAsync.fulfilled, (state, action) => {
                const { scriptId, tasks } = action.payload;
                let scriptIndex = state.scripts.value.findIndex(x => x.id === scriptId);
                state.scripts.value[scriptIndex].tasks = tasks;
                state.scripts.status = AsyncStatus.READY;
            })
            .addCase(setScriptButtonPathAsync.pending, (state) => {
                state.scripts.status = AsyncStatus.LOADING;
            })
            .addCase(setScriptButtonPathAsync.fulfilled, (state, action) => {
                let scriptButtonRoutes = action.payload;
                state.scriptButtonRoute.value = scriptButtonRoutes;

                for (let indexA = 0; indexA < scriptButtonRoutes.length; indexA++) {
                    const scriptButtonRoute = scriptButtonRoutes[indexA];
                    state.scriptButtonRouteDictionary[`/${scriptButtonRoute.path}`] = scriptButtonRoute.scriptButtons;

                    if (scriptButtonRoute.subPaths !== null && scriptButtonRoute.subPaths !== undefined) {
                        for (let indexB = 0; indexB < scriptButtonRoute.subPaths.length; indexB++) {
                            const element = scriptButtonRoute.subPaths[indexB];
                            state.scriptButtonRouteDictionary[`/${scriptButtonRoute.path}/${element.path}`] = element.scriptButtons;

                        }
                    }
                }
                state.scripts.status = AsyncStatus.READY;
            })

            .addCase(loadTaskDescriptionsAsync.pending, (state) => {
                state.scripts.status = AsyncStatus.LOADING;
            })
            .addCase(loadTaskDescriptionsAsync.fulfilled, (state, action) => {
                state.taskDescriptions.value = action.payload;
                state.scripts.status = AsyncStatus.READY;
            })
    },
})
//setTaskDescriptionsAsync
export const {
    setScripts,
    setTasksOnScript,
    setScriptButtonRoute,
    removeScriptById
} = appSchedulerSlice.actions;

export default appSchedulerSlice.reducer;