import React, { useContext } from "react";
import { Link } from "react-router-dom";
import {
  Sidebar,
  Menu,
  MenuItem as MenuItemDefault,
  SubMenu as SubMenuDefault,
  useProSidebar,
  sidebarClasses,
  menuClasses,
} from "react-pro-sidebar";
import { BiFoodMenu } from "react-icons/bi";
import { AiFillEye } from "react-icons/ai";
import { FaClipboardList, FaDatabase, FaTasks } from "react-icons/fa";
import Logo from "assets/images/nplan/logo/3t.png";
import { getPowerBIAsync, getTableGroupsAsync, getTablesAsync } from "api/data";
import { Box, Switch } from "@mui/material";
import { MdOutlineAnalytics } from "react-icons/md";
import TooltipText from "./components/TooltipText";
import { Trans, t } from "@lingui/macro";
import { UserContext, UserProvider } from "components/UserProvider";
import { i18n } from "@lingui/core";
import { IoHomeOutline } from "react-icons/io5";

const MenuItem = (props) => {
  return (
    <MenuItemDefault
      {...props}
      rootStyles={{
        ["." + menuClasses.button]: {
          "&:hover": {
            backgroundColor: "#131313",
          },
        },
      }}
    >
      {props.children}
    </MenuItemDefault>
  );
};

const SubMenu = (props) => {
  return (
    <SubMenuDefault
      {...props}
      rootStyles={{
        ["& > ." + menuClasses.button]: {
          "&:hover": {
            backgroundColor: "#131313",
          },
        },
        ["." + menuClasses.subMenuContent]: {
          backgroundColor: "rgb(25 35 50)",
        },
      }}
    />
  );
};

const ThreeSubMenu = (props) => {
  return (
    <SubMenuDefault
      {...props}
      rootStyles={{
        ["& > ." + menuClasses.button]: {
          "&:hover": {
            backgroundColor: "#131313",
          },
        },
        ["." + menuClasses.subMenuContent]: {
          backgroundColor: "rgb(20 30 50)",
        },
      }}
    />
  );
};

const NavigationSidebar = () => {
  const { collapseSidebar } = useProSidebar();
  const [tables, setTables] = React.useState<any>([]);
  const [groups, setGroups] = React.useState<any>([]);
  const [powerBI, setPowerBi] = React.useState<any>([]);
  const [collapsedMenu, setCollapsedMenu] = React.useState(true);

  React.useEffect(() => {
    getTableGroupsAsync().then((res) => {
      setTables(res);
    });
    getPowerBIAsync().then((res) => {
      if (res['type'] === 'success')
        setPowerBi(res);
    });

    var collapsed = localStorage.getItem("collapsedMenu");
    if (collapsed) setCollapsedMenu(collapsed === "true");
  }, []);
  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        maxWidth: "80px",
        zIndex: 100,
      }}
    >
      <Sidebar
        defaultCollapsed={true}
        onMouseEnter={() => collapsedMenu && collapseSidebar(false)}
        onMouseLeave={() => collapsedMenu && collapseSidebar(true)}
        rootStyles={{
          [`.${sidebarClasses.container}`]: {
            backgroundColor: "rgb(31 41 55)",
            color: "white",
          },
        }}
      >
        <Menu>
          <MenuItem
            onClick={() => {
              if (collapsedMenu) {
                setCollapsedMenu(false);
                collapseSidebar(true);
                localStorage.setItem("collapsedMenu", "false");
              } else {
                setCollapsedMenu(true);
                collapseSidebar(false);
                localStorage.setItem("collapsedMenu", "true");
              }
            }}
            icon={<Switch checked={collapsedMenu} size={"small"} />}
          >
            <Trans>Menu Dinâmico</Trans>
          </MenuItem>
        </Menu>
        <Box
          sx={{
            borderRadius: "35px",
            height: 100,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <div className="p-4">
              <img src={Logo} alt=""></img>
            </div>
          </div>
        </Box>
        <Menu>
          <MenuItem
            component={ <Link to="/home" /> }
            icon={ <IoHomeOutline size="1.6em" /> }
          >
            <TooltipText width={ 200 } text={ t`Home` }></TooltipText>
          </MenuItem>
          <MenuItem
            component={<Link to="/scenarios" />}
            icon={<BiFoodMenu size="1.6em" />}
          >
            <TooltipText width={200} text={t`Cenários`}></TooltipText>
          </MenuItem>
          <SubMenu label={t`Análise`} icon={<AiFillEye size="1.6em" />}>
            <MenuItem label="" component={<Link to="/analysis/occupation" />}>
              <TooltipText
                width={200}
                text={t`Visão de Ocupação`}
              ></TooltipText>
            </MenuItem>
            <MenuItem
              label=""
              component={<Link to="/analysis/comparison-page" />}
            >
              <TooltipText
                width={200}
                text={t`Comparação de Cenários`}
              ></TooltipText>
            </MenuItem>
            <MenuItem component={<Link to={`inventory-policy`} />}>
              <TooltipText
                width={200}
                text={i18n.t(`Política de Estoques`)}
              ></TooltipText>
            </MenuItem>
          </SubMenu>
          <MenuItem
            component={<Link to="/planning" />}
            icon={<FaClipboardList size="1.6em" />}
          >
            <TooltipText width={200} text={t`Planejamento`}></TooltipText>
          </MenuItem>
          <SubMenu
            label={t`Edição de Tabelas`}
            icon={<FaDatabase size="1.5em" />}
          >
            {tables
              ?.sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((table, index) => (
                <ThreeSubMenu label={table.name} key={index}>
                  {table.tablesEdition
                    ?.sort((a, b) => (a.title > b.title ? 1 : -1))
                    .map((tEdition) => (
                      <MenuItem
                        key={tEdition.id}
                        component={
                          <Link to={`tableedition/data/${tEdition.id}`} />
                        }
                      >
                        <TooltipText
                          width={140}
                          text={tEdition.title}
                        ></TooltipText>
                      </MenuItem>
                    ))}
                </ThreeSubMenu>
              ))}
          </SubMenu>
          <SubMenu
            label={t`Relatórios`}
            icon={<MdOutlineAnalytics size="1.5em" />}
          >
            <MenuItem component={<Link to={`analytics`} />}>
              <TooltipText
                width={200}
                text={"Reporting Services"}
              ></TooltipText>
            </MenuItem>

            <ThreeSubMenu label={"Power BI"}>
              {powerBI
                ?.filter((a) => a.active)
                .map((pb, index) => (
                  <MenuItem
                    key={pb.id}
                    component={<Link to={`powerbi/${pb.id}`} />}
                  >
                    <TooltipText width={140} text={pb.title}></TooltipText>
                  </MenuItem>
                ))}
            </ThreeSubMenu>
          </SubMenu>
        </Menu>
      </Sidebar>
    </div>
  );
};

export default NavigationSidebar;
