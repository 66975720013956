import React from 'react'
import { ListItemIcon, Menu, MenuItem } from '@mui/material';
import { FiRefreshCw } from 'react-icons/fi';
import { RiUserAddLine } from 'react-icons/ri';

const MenuContext = ({ anchorEl, onClose, onRefreshData, onAddUser }) => {
    const handleRefresh = () => {
        onRefreshData();
        onClose();
    };
    const handleAddUser = () => {
        onAddUser();
        onClose();
    };
    return (
        <>
            <Menu
                open={ Boolean(anchorEl) }
                onClose={ onClose }
                anchorReference="anchorPosition"
                anchorPosition={
                    anchorEl !== null ? {
                        top: anchorEl.mouseY,
                        left: anchorEl.mouseX,
                    } : undefined
                }
            >
                <MenuItem onClick={ handleRefresh }>
                    <ListItemIcon><FiRefreshCw /></ListItemIcon>
                    Atualizar dados
                </MenuItem>
                <MenuItem onClick={ handleAddUser }>
                    <ListItemIcon><RiUserAddLine /></ListItemIcon>
                    Adicionar Usuário
                </MenuItem>
            </Menu>
        </>
    );
}
export default MenuContext