import { useMemo } from "react";
import { sortArrayByKey } from "../utils";
import { useLingui } from "@lingui/react";
import { useAppSelector } from "redux/hooks";
import { useDispatch } from "react-redux";
import { setSelectCompanyMenu } from "redux/AppStockState";
import { stockPolicyCompanyOptionsToLanguage } from "locales/helper";
import { SelectCompanyMenuProps } from "api/data/enums";



const useDataTypesHandler = () => {
  const { i18n } = useLingui()
  const { segmentationData, stockEditableTableValidation } = useAppSelector(state => state.appStockState)
  const language = useAppSelector(state => state.appNavigationState.language);
  const dispatch = useDispatch()
  const companyTypes = useMemo(() => {
    if (stockEditableTableValidation.isEditable === false) {
      const result = [
        { id: 0, name: stockPolicyCompanyOptionsToLanguage(SelectCompanyMenuProps.All) },
        ...new Map(
          segmentationData.data.map((pos) => [pos.company.id, pos.company])
        ).values(),
      ];
      const newResult = result.map(object => { return { id: object.id, name: () => stockPolicyCompanyOptionsToLanguage(object.name) } })

      dispatch(setSelectCompanyMenu({ CompanyOptions: newResult }))
      return result
    } else {
      const result = [
        ...new Map(
          segmentationData.data.map((pos) => [pos.company.id, pos.company])
        ).values(),
      ];
      const newResult = result.map(object => { return { id: object.id, name: () => stockPolicyCompanyOptionsToLanguage(object.name) } })

      dispatch(setSelectCompanyMenu({ CompanyOptions: newResult }))
      return result

    }
  }, [segmentationData.data, stockEditableTableValidation.isEditable, language]);

  const complexityTypes = useMemo(() => {
    return sortArrayByKey(
      [
        ...new Map(
          segmentationData.data.map((pos) => [
            pos.classificationComplexity.id,
            pos.classificationComplexity,
          ])
        ).values(),
      ],
      "priority"
    );
  }, [segmentationData.data]);

  const volumeTypes = useMemo(() => {
    return sortArrayByKey(
      [
        ...new Map(
          segmentationData.data.map((pos) => [
            pos.classificationVolume.id,
            pos.classificationVolume,
          ])
        ).values(),
      ],
      "priority"
    );
  }, [segmentationData.data]);

  const uncertaintyTypes = useMemo(() => {
    return sortArrayByKey(
      [
        ...new Map(
          segmentationData.data.map((pos) => [
            pos.classificationUncertainty.id,
            pos.classificationUncertainty,
          ])
        ).values(),
      ],
      "priority"
    );
  }, [segmentationData.data]);

  const types = {
    Company: [...companyTypes],
    Complexity: [...complexityTypes],
    Volume: [...volumeTypes],
    Uncertainty: [...uncertaintyTypes],
  };

  return {
    types,
    companyTypes,
    complexityTypes,
    volumeTypes,
    uncertaintyTypes,
  };
};

export default useDataTypesHandler;
