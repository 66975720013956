import { t, Trans } from '@lingui/macro';
import { memo, MutableRefObject, useEffect, useState } from 'react';
import { areEqual, ListChildComponentProps } from 'react-window'
import { setItemRowSelectedProducts, selectItemImageCached, selectItemRowData } from 'redux/AppScenarioState';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useUserChangeableState } from 'utils';
import { getStockStatusColor } from 'utils/chartHelper';
import EditableInput from './EditableInput';
import { ItemRowInput } from '../containers/ItemRowListContainer';
import { GlobalPlanningGridTargetType } from 'redux/AppScenarioState/dataTypes';
import { ImTree } from 'react-icons/im';
import MaterialFlowItemRowContainer from '../MaterialFlow/MaterialFlowItemRowContainer';
import { stockStatusTypeToLanguage } from 'locales/helper';

const globalPlanningGridTargetTypeInverter = (value: GlobalPlanningGridTargetType): GlobalPlanningGridTargetType => {
    return ((value === GlobalPlanningGridTargetType.DailyGlobalTargetValue) || (value === GlobalPlanningGridTargetType.IndividualTargetValue)) ? GlobalPlanningGridTargetType.UnitaryGlobalTargetValue : GlobalPlanningGridTargetType.DailyGlobalTargetValue
}

const ItemRow = (props: ListChildComponentProps) => {
    const dispatch = useAppDispatch();
    const {
        isExpandedProductRow,
        updateItemGlobalTargetStockType,
        updateItemGlobalMinStockType,
        updateItemMinStock,
        updateItemTargetStock,
        updateItemMinimunReorderQuantity,
        updateItemReorderMultiple
    }: ItemRowInput = props.data.itemRowDataMemoized;
    const toggleSize: (index: number, itemId: number) => void = props.data.toggleSize

    const currentIndex = props.index;

    const {
        orderedItemId,
        isSelected,
        globalMinStockType,
        globalTargetStockType,
        planningGridStatus,
        firstPlanningGridOpeningStock,
        minimumReorder,
        multipleReorder,
        localTargetStockValue,
        localMinStockValue,
        itemCode,
        itemDescription
    } = useAppSelector(state => selectItemRowData(state.appScenarioState, currentIndex));

    const completeStructureOrderDataByItemMaster = useAppSelector(state => state.appScenarioState.completeStructureOrderDataByItemMaster[orderedItemId]);

    const [itemImage, setItemImage] = useState<string>();

    //TODO melhorar esta parte, pois acontece redraws desnecessarios!
    const [globalMinStockTypeHandler, setGlobalMinStockTypeHandler] = useUserChangeableState(globalMinStockType);
    useEffect(() => {
        setGlobalMinStockTypeHandler({ value: globalMinStockType, changedByUser: false })
    }, [globalMinStockType]);

    const [globalTargetStockTypeHandler, setGlobalTargetStockTypeHandler] = useUserChangeableState(globalTargetStockType);
    useEffect(() => {
        setGlobalTargetStockTypeHandler({ value: globalTargetStockType, changedByUser: false })
    }, [globalTargetStockType]);

    //Set targetStock from unit to Daily, and recalculate current value to match daily values
    useEffect(() => {
        if (globalTargetStockTypeHandler.changedByUser)
            return updateItemGlobalTargetStockType(localTargetStockValue, globalTargetStockTypeHandler, orderedItemId);
    }, [globalTargetStockTypeHandler])

    //Set minStock from unit to Daily, and recalculate current value to match daily values
    useEffect(() => {
        if (globalMinStockTypeHandler.changedByUser)
            updateItemGlobalMinStockType(localMinStockValue, globalMinStockTypeHandler, orderedItemId);
    }, [globalMinStockTypeHandler])

    const handleOnSelectProduct = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        dispatch(setItemRowSelectedProducts({ ctrlKey: event.ctrlKey, shiftKey: event.shiftKey, itemId: orderedItemId }))
    }

    //TODO melhorar performance/iteração!
    useEffect(() => {
        const fetchData = async () => {
            const imageObjectURL = await selectItemImageCached(orderedItemId)
            setItemImage(imageObjectURL);
        }
        fetchData();
    }, [orderedItemId]);

    return (
        <>
            <div key={currentIndex} style={props.style}
                className='flex-col w-full'>
                <div className={`flex flex-row h-60 w-full border-t-2 select-none ${isSelected ? 'bg-gray-300' : ''}`}
                    onClick={event => handleOnSelectProduct(event)}>
                    <div className={`grow-0 flex-col h-full ${isExpandedProductRow ? 'w-80' : 'w-36'} `}
                        style={{
                            minWidth: 320
                        }}>
                        <p className={`w-full font-bold h-16 p-1 pl-3 text-au text-left ${isExpandedProductRow ? 'text-xl' : 'text-sm'}`}>{itemDescription}</p>
                        <div className='flex flex-row w-full h-auto  items-center justify-center'>
                            {/* <div className={`flex flex-col px-2 w-full items-center space-y-3`}> */}
                            <div className={`rounded-md flex grow justify-self-start w-[80px] h-[120px]} mx-1`}>
                                <img src={itemImage} alt="itemImage" />
                            </div>


                            {/* </div> */}
                            {
                                isExpandedProductRow &&
                                <div className='flex flex-col w-auto h-auto text-sm space-y-1 pr-1'>
                                    {/* <Trans>Estoque Alvo</Trans> */}
                                    <EditableInput
                                        // text={t`Estoque Alvo`}
                                        textReactNode={<Trans>Estoque Alvo</Trans>}
                                        unit={globalTargetStockTypeHandler.value === GlobalPlanningGridTargetType.UnitaryGlobalTargetValue ?
                                            t({ message: 'Un', comment: 'unidade/letra para simbolizar Unidades, ex: Un' })
                                            :
                                            t({ message: 'D', comment: 'unidade/letra para simbolizar Dias, ex: D' })}
                                        id='targetStock'
                                        inputValue={localTargetStockValue}
                                        onUserChange={(value) => updateItemTargetStock(value, orderedItemId)}
                                        delay={500}
                                        type='number'
                                        onChangeUnit={() => setGlobalTargetStockTypeHandler({ value: globalPlanningGridTargetTypeInverter(globalTargetStockTypeHandler.value), changedByUser: true })} />
                                    <EditableInput
                                        textReactNode={<Trans>Ponto de Reposição</Trans>}
                                        // text={t`Ponto de Reposição`}
                                        unit={globalMinStockTypeHandler.value === GlobalPlanningGridTargetType.UnitaryGlobalTargetValue ?
                                            t({ message: 'Un', comment: 'unidade/letra para simbolizar Unidades, ex: Un' })
                                            :
                                            t({ message: 'D', comment: 'unidade/letra para simbolizar Dias, ex: D' })}
                                        id='minStock'
                                        inputValue={localMinStockValue}
                                        onUserChange={(value) => updateItemMinStock(value, orderedItemId)}
                                        delay={500}
                                        type='number'
                                        onChangeUnit={() => setGlobalMinStockTypeHandler({ value: globalPlanningGridTargetTypeInverter(globalMinStockTypeHandler.value), changedByUser: true })} />
                                    <EditableInput
                                        textReactNode={<Trans>Lote Mínimo</Trans>}
                                        // text={t`Lote Mínimo`}
                                        unit={t({ message: 'Un', comment: 'unidade/letra para simbolizar Unidades, ex: Un' })} id='minimumReorderQuantity' inputValue={minimumReorder}
                                        onUserChange={(value) => updateItemMinimunReorderQuantity(value, orderedItemId)}
                                        delay={500}
                                        type='number' />
                                    <EditableInput
                                        textReactNode={<Trans>Lote Multiplo</Trans>}
                                        // text={t`Lote Multiplo`}
                                        unit={t({ message: 'Un', comment: 'unidade/letra para simbolizar Unidades, ex: Un' })} id='reorderMultiple' inputValue={multipleReorder}
                                        onUserChange={(value) => updateItemReorderMultiple(value, orderedItemId)}
                                        delay={500}
                                        type='number' />
                                </div>
                            }
                        </div>
                        <div className='grow flex justify-start ml-1'>
                            <div className='flex rounded-md font-semibold h-auto w-max border-2  p-0.5'>{itemCode}</div>
                            {
                                completeStructureOrderDataByItemMaster &&
                                <ImTree className='ml-2 h-6 w-6 cursor-pointer' onClick={() => toggleSize(props.index, orderedItemId)} />
                            }
                        </div>
                    </div>
                    {
                        isExpandedProductRow ?
                            <div className='flex flex-col w-24  items-center justify-center'
                                style={{ background: `${getStockStatusColor(planningGridStatus)}`, minWidth: 96 }}>
                                <div>
                                    <p>{firstPlanningGridOpeningStock} {t({ message: 'Un', comment: 'unidade/letra para simbolizar Unidades, ex: Un' })}</p>
                                </div>
                                <div>
                                    <p>{stockStatusTypeToLanguage(planningGridStatus)}</p>
                                </div>
                            </div>
                            :
                            <div className='flex flex-shrink-0 w-4'
                                style={{ background: `${getStockStatusColor(planningGridStatus)}` }} />
                    }
                    {
                        isExpandedProductRow ?
                            <div className='flex flex-col w-52 h-full text-center justify-evenly text-sm'>
                                <p><Trans>Demanda</Trans></p>
                                <p><Trans>Programado</Trans></p>
                                <p><Trans>Plano</Trans></p>
                                <p><Trans>Estoque Alvo</Trans></p>
                                <p><Trans>Estoque Inicial</Trans></p>
                                <p><Trans>Estoque Final</Trans></p>
                                <p><Trans>Cobertura</Trans></p>
                                <p><Trans>Situação</Trans></p>
                            </div>
                            :
                            <div className='flex flex-col w-52 h-full text-center justify-evenly text-sm mx-1'>
                                <p><Trans>Dem</Trans></p>
                                <p><Trans>Pro</Trans></p>
                                <p><Trans>Pla</Trans></p>
                                <p><Trans>E. Alv</Trans></p>
                                <p><Trans>E. Ini</Trans></p>
                                <p><Trans>E. Fin</Trans></p>
                                <p><Trans>Cob</Trans></p>
                                <p><Trans>Sit</Trans></p>
                            </div>
                    }
                </div>
                {
                    completeStructureOrderDataByItemMaster &&
                    <MaterialFlowItemRowContainer itemId={orderedItemId} isExpandedProductRow={isExpandedProductRow} />
                }
            </div>
        </>
    )
}

export default ItemRow
