import React from 'react'
import { useAppSelector } from 'redux/hooks';
import ComparisonPanelRow from './ComparisonPanelRow/ComparisonPanelRow';

interface Props {
    selectedScenarioSumarizedData: { name: string, id: number }[]
}

function ComparisonPanelRowContainer(props: Props) {
    const { selectedScenarioSumarizedData } = props
    const scenarioSummarizedByScenarioId = useAppSelector(state => state.appSumarizedState.scenarioSummarizedByScenarioId);
    return (
        <>
            {
                selectedScenarioSumarizedData.map((selectedScenario, index) => {
                    if (!scenarioSummarizedByScenarioId[selectedScenario.id]) return <></>
                    return (<ComparisonPanelRow
                        key={index}
                        scenarioSummarizedData={scenarioSummarizedByScenarioId[selectedScenario.id]}
                        scenarioName={selectedScenario.name}
                        storageResource={String(index)}
                    />)
                })
            }
        </>
    )
}

export default ComparisonPanelRowContainer
