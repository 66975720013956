import DashboardPage from './pages/DashboardPage/DashboardPage'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import Authorization from 'components/Authorization';
import LoginPage from 'pages/LoginPage/LoginPage';
import { detect, fromUrl, fromStorage, fromNavigator } from "@lingui/detect-locale"
import { useEffect, useState } from 'react';
import { dynamicActivate } from 'locales';
import { ToastContainer } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';
import { UserProvider } from 'components/UserProvider';
import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import 'primereact/resources/primereact.css';                       // core css
import { trySetToDefaultCountryData } from 'redux/AppNavigationState';
import { CountryCode } from 'configuration/languages';

const DEFAULT_FALLBACK = CountryCode.enUS;

let defaultLocale = detect(
    // fromUrl("language"),
    fromStorage("language")
) ?? DEFAULT_FALLBACK

if (defaultLocale.length !== 5) defaultLocale = DEFAULT_FALLBACK

function App() {
    const dispatch = useAppDispatch();
    const language = useAppSelector(state => state.appNavigationState.language);
    if (language === undefined) {
        dispatch(trySetToDefaultCountryData(defaultLocale))
    }
    useEffect(() => {
        document.title = process.env.REACT_APP_TITLE ?? ""
    }, [])

    return (
        <>
            <I18nProvider i18n={i18n}>
                <ToastContainer />
                <PrimeReactProvider>
                    <BrowserRouter>
                        <UserProvider>
                            <Routes>
                                <Route path='login' element={<LoginPage />} />
                                <Route path='*' element={
                                    <Authorization>
                                        <DashboardPage />
                                    </Authorization>
                                }>
                                </Route>
                            </Routes>
                        </UserProvider>
                    </BrowserRouter>
                </PrimeReactProvider>
            </I18nProvider>
        </>
    );
}

export default App;
