import React from 'react'
import product001 from 'assets/images/productPlaceholder/product_image01.jpg'
import product002 from 'assets/images/productPlaceholder/product_image02.jpg'
import product003 from 'assets/images/productPlaceholder/product_image03.jpg'
import product004 from 'assets/images/productPlaceholder/product_image04.jpg'

const productPlaceholderImages: string[] = [
    product001,
    product002,
    product003,
    product004
]

export const getProductImageByIndex = (index: number) => {
    return productPlaceholderImages[index % 4];
}
interface Props {
    index: number
}

function ImagePlaceholder(props: Props) {
    const { index } = props
    return (
        <>
            <img src={getProductImageByIndex(index)} className='w-full h-full' />
        </>
    )
}

export default ImagePlaceholder
