import { Trans } from "@lingui/macro";
import { Checkbox, ListItemIcon, ListItemText, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AiOutlineCheckCircle, AiOutlineCloseCircle, AiOutlineMinusCircle } from 'react-icons/ai'
import { IoAddCircleOutline, IoRemoveCircleOutline } from "react-icons/io5";
export function NumberFilter(props: any) {
    return (
        <React.Fragment>
            <Select
                style={{ height: "42px", width: "42px", textAlign: "right", marginRight: "5px" }}
                onChange={({ target: { value } }) => {
                    props.setFilter(props.column.id, value, undefined)
                }}
                sx={{
                    "& MuiSelect-select": {
                        paddingRight: "0px"
                    }
                }}
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    }
                }}
                value={props.column.filterSignal}
                renderValue={(value) => <>{value}</>}
                variant="standard"
            >
                <MenuItem value={"="}><Trans>Igual</Trans></MenuItem>
                <MenuItem value={">="} ><Trans>Maior ou Igual</Trans></MenuItem>
                <MenuItem value={"<="}><Trans>Menor ou Igual</Trans></MenuItem>
                <MenuItem value={">"}><Trans>Maior</Trans></MenuItem>
                <MenuItem value={"<"}><Trans>Menor</Trans></MenuItem>
                <MenuItem value={"≠"}><Trans>Diferente</Trans></MenuItem>
            </Select>
            <TextField
                style={{ width: "calc(100% - 50px)", height: "40px" }}
                value={props.column.filterValue}
                onChange={({ target: { value } }) => {
                    props.setFilter(props.column.id, undefined, value)
                }}
                type={"text"}
                variant="standard"
            /> 
        </React.Fragment>
        
    )
}
export function TextFilter(props: any) {
    return (
        <>
            <Select
                style={{ height: "42px", width: "42px", textAlign: "right", marginRight: "5px" }}
                onChange={({ target: { value } }) => {
                    props.setFilter(props.column.id, value, undefined)
                }}
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    }
                }}
                value={ props.column.filterSignal ?? "="}
                renderValue={(value) =>
                    <div>
                        {value}
                    </div>
                }
                variant="standard"

            >
                <MenuItem value={"="}><Trans>Igual</Trans></MenuItem>
                <MenuItem value={"≠"}><Trans>Diferente</Trans></MenuItem>
                <MenuItem value={"⊃"} ><Trans>Contém</Trans></MenuItem>
                <MenuItem value={"⊅"}><Trans>Não Contém</Trans></MenuItem>
                <MenuItem value={"^"}><Trans>Começa Com</Trans></MenuItem>
                <MenuItem value={"$"}><Trans>Termina Com</Trans></MenuItem>
            </Select>
            <TextField
                style={{ width: "calc(100% - 50px)", height: "30px" }}
                value={ props.column.filterValue ?? ""}
                onChange={({ target: { value } }) => {
                    props.setFilter(props.column.id, undefined, value)
                }}
                type={"text"}
                variant="standard"
            />
        </>
    )
}


export function BooleanFilter(props: any) {
    return (
        <div style={{width: "100%", height: "34px", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <div style={{ borderRadius: "25px", position: "relative", border: "2px solid #95A5A6", minWidth: "90px", maxWidth: "90px", cursor: "pointer" }}>
                <div style={{ display: "flex", flexDirection: "row", width: "100%", height: "100%" }}>
                    <AiOutlineCloseCircle
                        style={{ width: "30px", height: "25px", color: props.column.filterValue === false ? "red" : "#95A5A6" }}
                        onClick={() => {
                            props.setFilter(props.column.id, undefined, false)
                        }}
                    />
                    <AiOutlineMinusCircle
                        style={{ width: "30px", height: "25px", color: props.column.filterValue !== false  && (props.column.filterValue === "" || !props.column.filterValue)  ? "black" : "#95A5A6" }}
                        onClick={() => {
                            props.setFilter(props.column.id, undefined, "")
                        }}
                    />
                    <AiOutlineCheckCircle
                        style={{ width: "30px", height: "25px", color: props.column.filterValue === true ? "green" : "#95A5A6" }}
                        onClick={() => {
                            props.setFilter(props.column.id, undefined, true)
                        }}
                    />

                </div>

                <span></span>
            </div>
        </div>
    )
}


export function DateFilter({data, column, setFilter}) {
    useEffect(() => {
        let newTreeData: any = [];
        for (var row of data)
            newTreeData.push(new Date(row[column.field]));
        const grouped = newTreeData.reduce((result: any, date: Date) => {
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();

            if (!result[year]) {
                result[year] = { children: {} };
            }

            if (!result[year].children[month]) {
                result[year].children[month] = { label: `${month.toString().padStart(2, '0')}.${year}`, children: [] };
            }
            if (!result[year].children[month].children[day])
                result[year].children[month].children[day] = day;
            return result
        }, {});

        const formatted: any[] = [];
        const addedDates: any[] = [];

        Object.keys(grouped).forEach((year) => {
            const yearData: any = { label: year, value: year, expanded: false, children: [] };

            Object.values(grouped[year].children).forEach((month: any) => {
                const monthData: any = { expanded: false, label: month.label.split(".")[0], value: month.label,  children: [] };

                month.children.forEach((day: number) => {
                    const formattedDate = {
                        value: `${day.toString().padStart(2, '0')}.${month.label}`, label: `${day.toString().padStart(2, '0')}`}

                    if (!addedDates.includes(formattedDate)) {
                        monthData.children.push(formattedDate);
                        addedDates.push(formattedDate);
                    }
                });

                yearData.children.push(monthData);
            });

            formatted.push(yearData);
        });
        setTreeData(formatted)
    }, [data])
    const [treeData, setTreeData] = useState<any>([])
    return (
        <Select
            style={{ width: "calc(100%)", height: "30px" }}
            sx={{
                "& MuiSelect-select": {
                    paddingRight: "0px"
                }
            }}
            fullWidth
            MenuProps={{
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                },
                transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                }
            }}
            value={Array.isArray(column.filterValue) ? column.filterValue : []}
            onChange={({target: { value }}) => { setFilter(column.id, undefined, value )}}
            renderValue={(value) => <>{value.join(", ").replaceAll(".", "/")}</>}
            variant="standard"
            multiple
        >
            <MenuItem value={"null"} key="null">
                <Checkbox checked={Array.isArray(column.filterValue) && column.filterValue.includes("null") ? true : false} sx={{ marginLeft: 2 }} />
                <ListItemText primary={column.nullText || "NULL"} />
            </MenuItem>
            {treeData.map((element) =>   
                <div key={element.value}>
                    <MenuItem value={element.value}  
                        onClick={() => {
                            var newFilter: any = [];
                            if(Array.isArray(column.filterValue)){
                                newFilter = column.filterValue;
                            }
                            if (column.filterValue.includes(element.value)) {
                                for (let i = newFilter.length - 1; i >= 0; i--) {
                                    if (newFilter[i].endsWith(element.value)) {
                                        newFilter.splice(i, 1);
                                    }
                                }
                            } else {
                                newFilter.push(element.value)
                                element.children.map(element2 => {
                                    newFilter.push(element2.value);
                                    element2.children.map(element3 => {
                                        newFilter.push(element3.value);
                                    })
                                })
                            }
                            setFilter(column.id, undefined, newFilter)
                        }}>
                        <ListItemIcon
                            style={{ minWidth: 0 }} sx={{ minWidth: 0, marginRight: 0 }}
                            onClick={(event) => { event.stopPropagation(); element.expanded = !element.expanded; setTreeData(prevState => ([...prevState])) }}>
                            {element.expanded ? <IoRemoveCircleOutline /> : <IoAddCircleOutline />}
                        </ListItemIcon>
                        <Checkbox
                            checked={Array.isArray(column.filterValue) && column.filterValue.includes(element.value) ? true : false}
                        />
                        <ListItemText primary={element.label} />
                    </MenuItem>
                    {element.expanded && element.children.map((element) => 
                        <div key={element.value}>
                            <MenuItem value={element.value} 
                                onClick={() => {
                                    var newFilter: any = [];
                                    if (Array.isArray(column.filterValue)) {
                                        newFilter = column.filterValue;
                                    }
                                    if (column.filterValue.includes(element.value)) {
                                        for (let i = newFilter.length - 1; i >= 0; i--) {
                                            if (newFilter[i].endsWith(element.value)) {
                                                newFilter.splice(i, 1);
                                            }
                                        }
                                    } else {
                                        newFilter.push(element.value)
                                        element.children.map(element2 => {
                                            newFilter.push(element2.value);
                                        })
                                    }
                                    setFilter(column.id, undefined, newFilter)
                                }}
                            >
                                <ListItemIcon
                                    style={{ minWidth: 0 }}
                                    sx={{ minWidth: 0, marginLeft: 4 }}
                                    onClick={(event) => { event.stopPropagation(); element.expanded = !element.expanded; setTreeData(prevState => ([...prevState])) }}>
                                    {element.expanded ? <IoRemoveCircleOutline /> : <IoAddCircleOutline />}
                                </ListItemIcon>
                                <Checkbox
                                    checked={Array.isArray(column.filterValue) && column.filterValue.includes(element.value) ? true : false}
                                />
                                <ListItemText primary={element.label} />
                            </MenuItem>
                            {
                                element.expanded && element.children.map((element) => 
                                    <MenuItem value = {element.value} key={element.value}
                                        onClick={() => {
                                            var newFilter: any = [];
                                            if (Array.isArray(column.filterValue)) {
                                                newFilter = column.filterValue;
                                            }
                                            if (column.filterValue.includes(element.value)) {
                                                for (let i = newFilter.length - 1; i >= 0; i--) {
                                                    if (newFilter[i].endsWith(element.value)) {
                                                        newFilter.splice(i, 1);
                                                    }
                                                }
                                            } else {
                                                newFilter.push(element.value)
                                            }
                                            setFilter(column.id, undefined, newFilter)
                                        }}
                                    >
                                        <Checkbox
                                            checked={Array.isArray(column.filterValue) && column.filterValue.includes(element.value) ? true : false}
                                            sx={{marginLeft: 8}}
                                        />
                                        <ListItemText primary={element.label} />
                                    </MenuItem>
                                )
                            }
                        </div>
                    )}    
                </div>         
            )}
                
        </Select>
    );
}




export function SelectFilter({ data, foreignFields, column, setFilter }) {
    useEffect(() => {
        const uniqueValues: any = [...new Set(data.map(item => (item[column.field] != null  ? item[column.field] : 'null')))];
        let menuItemsN: any = [];
        if (uniqueValues.includes("null"))
            menuItemsN.push({value: "null", label: column.nullText !== null && column.nullText !== "" ? column.nullText : "null"});
        if(column.foreignKey)
            foreignFields[column.field].filter(a => uniqueValues.includes(a.id)).sort((a, b) => a.name > b.name ? 1 : -1).map(a => {
                menuItemsN.push({value: a.name, label: a.name});
            })
        else
            uniqueValues.filter(a => a !== "null").sort((a,b) => a > b ? 1 : -1).map(a =>{
                menuItemsN.push({value: a, label: a})
            })
        setMenuItems(menuItemsN)
    }, [data])
    const [menuItems, setMenuItems] = useState<any>([])
    return (
        <Select
            style={{ width: "calc(100%)", height: "30px" }}
            sx={{
                "& MuiSelect-select": {
                    paddingRight: "0px"
                }
            }}
            fullWidth
            MenuProps={{
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                },
                transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                }
            }}
            value={Array.isArray(column.filterValue) ? column.filterValue : []}
            onChange={({ target: { value } }) => {  setFilter(column.id, undefined, value) }}
            variant="standard"
            multiple
        >
            {menuItems.map(a => 
                <MenuItem value={a.value} key={a.value}>{a.label}</MenuItem>    
            )}
            {/* {foreignFields[column.field].filter(a => menuItems.includes(a.id)).sort()} */}
        </Select>
    );
}