import { HighlightedStatusType, StockStatusType } from "api/data/enums";
import { AttributeList, Capacity, CompleteStructure, DemandDateRanges, EditableEntity, Item, ItemAttribute, ItemPlanner, ItemStockGroup, OperationType, Period, PeriodType, PlanningGrid, PlanningGridAllocation, PlanningGridResource, PlanningStatus, ProductionType, Resource, ResourceGroup, Scenario, ScenarioCategory, ScenarioCriteria, ScenarioPriority, ScenarioStatus, ScenarioSummarized, StockStatus, StorageResource } from "api/data/types";
import { Dictionary } from "utils/types";

export interface UserDataFlat {
    planningStatus: PlanningStatus[]
    periodTypes: PeriodType[]
    productionTypes: ProductionType[]
    scenarioCategories: ScenarioCategory[]
    scenarioCriterias: ScenarioCriteria[]
    scenarioPriorities: ScenarioPriority[]
    scenarioStatuses: ScenarioStatus[]
    itemPlanners: ItemPlanner[]
    itemStockGroups: ItemStockGroup[]
    stockStatus: StockStatus[]
    attributeList: AttributeList[]
    operationTypes: OperationType[]
}

export enum GlobalPlanningGridTargetType {
    IndividualTargetValue = 'IndividualTargetValue',
    DailyGlobalTargetValue = 'DailyGlobalTargetValue',
    UnitaryGlobalTargetValue = 'UnitaryGlobalTargetValue'
}

export interface PlanningGridGlobalValues {
    globalMinStock: number;
    globalMinStockType: GlobalPlanningGridTargetType;
    globalTargetStock: number;
    globalTargetStockType: GlobalPlanningGridTargetType;
    // stockStatus: StockStatusType;
}
export interface PlanningGridData extends PlanningGrid, EditableEntity {
    // minStockByDays: number;
    // isMinStockByDay: boolean;
    // isTargetStockByDay: boolean;
    // targetStockByDays: number;
    stockStatus: StockStatusType;
    isMpsHighlighted: boolean;
    mpsHighlightedStatusType: HighlightedStatusType;
    isOpeningStockHighlighted: boolean;
    openingStockHighlightedStatusType: HighlightedStatusType;
}

export interface ScenarioData extends Scenario { }
export interface CompleteStructureData extends CompleteStructure { }
export interface CompleteStructureNode extends CompleteStructureData {
    nodes?: Dictionary<CompleteStructureNode>
}

export interface ScenarioSummarizedData extends ScenarioSummarized { }
export interface StorageResourceData extends StorageResource { }

export interface PeriodTypeData extends PeriodType { }
export interface ItemPlannerData extends ItemPlanner { }
export interface ItemStockGroupData extends ItemStockGroup { }

export interface PlanningStatusData extends PlanningStatus { }

export interface ItemAttributeData1 extends ItemAttribute { }
export interface ItemAttributeData2 extends ItemAttribute { }
//Utilizado somente no NPLAN
export interface ItemAttributeData3 extends ItemAttribute {
    colour: string,
    colourDesc: string
}
export interface ItemAttributeData4 extends ItemAttribute { }
export interface ItemAttributeData5 extends ItemAttribute { }
export interface ItemAttributeData6 extends ItemAttribute { }
export interface ItemAttributeData7 extends ItemAttribute { }
export interface ItemAttributeData8 extends ItemAttribute { }
export interface ItemAttributeData9 extends ItemAttribute { }
export interface ItemAttributeData10 extends ItemAttribute { }

export interface ProductionTypeData extends ProductionType { }

export interface ItemData extends Item { }

export interface ScenarioCategoryData extends ScenarioCategory { }

export interface ScenarioCriteriaData extends ScenarioCriteria {
    value: number
}
export interface ScenarioPriorityData extends ScenarioPriority { }

export interface ScenarioStatusData extends ScenarioStatus { }
export interface PlanningGridAllocationData extends PlanningGridAllocation {
    totalAllocatedTime: number
    partialProcessTime?: number
    periodId: number
    itemId: number

}
export interface PlanningGridResourceData extends PlanningGridResource { }

export interface CapacityData extends Capacity { }

export interface DemandDateRangesData extends DemandDateRanges { }

export interface ResourceGroupData extends ResourceGroup { }

export interface ResourceData extends Resource { }

export interface PeriodData extends Period { }

export interface ResourcesProcessTimeData {
    totalProcessTimeForScenario: number
    byPeriod: Dictionary<{
        totalProcessTimeForPeriod: number
        byItemOperationId: Dictionary<number>
    }>

}