import { RequestParamsParameters, RequestSignalParameter } from "api";
import { ScriptParameter } from "api/data/types";
import { getAsync, postAsync, putAsync } from "api/scheduler";
import { FlatTask, Task, TaskDescription } from "api/scheduler/types";
export const route = 'tasks'

export const createTaskForScriptByIdAsync = async (scriptId: number, parameters: RequestParamsParameters<null, Task>): Promise<Task> => {
    return postAsync({ url: `/scripts/${scriptId}/${route}`, ...parameters },);
}

export const updateTaskByIdAsync = async (taskId: number, parameters: RequestParamsParameters<null, Task>): Promise<Task> => {
    return putAsync({ url: `/${route}/${taskId}`, ...parameters });
}

export const updateTasksForScriptByIdAsync = async (scriptId: number, parameters: RequestParamsParameters<null, Task[]>): Promise<Task[]> => {
    return putAsync({ url: `/scripts/${scriptId}/${route}`, ...parameters });
}
export const getTasksAsync = async (): Promise<Task[]> => {
    return getAsync<Task[]>({ url: route });
}

export const getTaskByNameAsync = async (taskName: string): Promise<Task[]> => {
    return getAsync<Task[]>({ url: `/${route}/${taskName}` });
}

export const getTaskByScriptIdAsync = async (scriptId: number): Promise<Task[]> => {
    return getAsync<Task[]>({ url: `/scripts/${scriptId}/${route}` });
}

export const getFlatTaskByScriptIdAsync = async (scriptId: number): Promise<FlatTask[]> => {
    return getAsync<Task[]>({ url: `/scripts/${scriptId}/${route}/flat` });
}

export const getTaskDescriptionsAsync = async (parameters?: RequestSignalParameter): Promise<TaskDescription[]> => {
    return getAsync({ url: `/task-descriptions`, ...parameters });
}

export function executeSingleTaskByIdAsync(taskId: number, parameters: RequestParamsParameters<null, ScriptParameter>): Promise<void> {
    return postAsync({ url: `/tasks/${taskId}/force-execute`, ...parameters });
}
export function executeSingleTaskByTypeAsync(taskType: string, parameters: RequestParamsParameters<null, ScriptParameter>): Promise<void> {
    return postAsync({ url: `/tasks/${taskType}/force-execute/type`, ...parameters });
}

export const forceExecuteTaskByTypeAsync = async (parameters: RequestParamsParameters<{ taskType: string }, null>): Promise<void> => {
    return getAsync({ url: `/tasks/force-execute`, ...parameters });
}

export const forceExecuteTaskByTypeWithBodyAsync = async (parameters: RequestParamsParameters<{ taskType: string }, { order: number, data: any }>): Promise<void> => {
    return postAsync({ url: `/tasks/force-execute`, ...parameters });
}

export const tryCancelRunningTaskProcedureAsync = async (): Promise<boolean> => {
    return getAsync({ url: `/tasks/cancel` });
}