
import DropdownTreeSelect, { TreeData, TreeNode } from "react-dropdown-tree-select";
import { FilterCriteria } from "redux/AppScenarioState/FilterCriteria/types";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { initializeFixedCriteriaOptions, setSelectedFilters, updateFixedCriteriaOptions } from "redux/AppScenarioState/reducers";
import { useLingui } from "@lingui/react";

function FixedCriteriasSelectionDropdownMenu() {
    const dispatch = useAppDispatch();
    const { i18n } = useLingui();
    const { FixedCriteriaFilterOptions } = useAppSelector((state) => state.appScenarioState);
    const localTreeData: TreeData = FixedCriteriaFilterOptions.map((filter) => {
        return {
            label: filter.label(),
            filterCriteria: filter.filterCriteria,
            value: filter.placeholder()
        }
    });

    const onChangeSearch = (currentNode, selectedNodes: TreeNode[]) => {
        const selectedNodesData = selectedNodes.map((node) => {
            return {
                label: () => node.label,
                value: node.value,
                filterCriteria: node.value,
                placeholder: node.placeholder,
                filterId: node.filterId,
                checked: node.checked
            };
        })

        dispatch(updateFixedCriteriaOptions({ selectedFixedCriteria: currentNode }))
        dispatch(setSelectedFilters(selectedNodesData))
    };
    return (
        <DropdownTreeSelect
            className="min-w-fit hide-selected-text mx-2"
            inlineSearchInput={true}
            texts={{ placeholder: "Filtros" }}
            showDropdown={"default"}
            data={localTreeData}
            keepTreeOnSearch={true}
            keepChildrenOnSearch={true}
            onChange={onChangeSearch}
        />
    );
}

export const FixedCriteriasSelectionDropdownMenuMemoized = React.memo(FixedCriteriasSelectionDropdownMenu);
