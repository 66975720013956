import { RootState } from "redux/store"
import { createSelector } from "reselect"
import { createStockStatusTypeSearchTree, createItemPlannerSearchTree, createItemStockGroupSearchTree, createStockStatusWithPeriodTypeSearchTree, createSimpleAttributesTree, createItemCodeAttributesTree, createItemLevelSearchTree, createSelectedAttributeFilters, createSelectedFixedCriteriaFilters } from "../helpers"

//StockStatusType
export const selectStockStatusTypeSearchTree = createSelector(
    (_state_: RootState) => _state_.appScenarioState.filterData.itemIdsByStockStatus,
    (itemIdsByStockStatus) => createStockStatusTypeSearchTree(itemIdsByStockStatus)
)

export const selectItemPlannerSearchTree = createSelector(
    (_state_: RootState) => _state_.appScenarioState.filterData.itemIdsByItemPlannerIds,
    (_state_: RootState) => _state_.appScenarioState.itemPlannerById,
    (itemIdsByItemPlannerIds, itemPlannerById) => createItemPlannerSearchTree(itemIdsByItemPlannerIds, itemPlannerById)
)

export const selectItemStockGroupSearchTree = createSelector(
    (_state_: RootState) => _state_.appScenarioState.filterData.itemIdsByStockGroupIds,
    (_state_: RootState) => _state_.appScenarioState.itemStockGroupById,
    (itemIdsByStockGroupIds, itemStockGroupById) => createItemStockGroupSearchTree(itemIdsByStockGroupIds, itemStockGroupById)
)

export const selectStockStatusWithPeriodTypeSearchTree = createSelector(
    (_state_: RootState) => _state_.appScenarioState.filterData.itemIdsByStockStatusByPeriod,
    (_state_: RootState) => _state_.appScenarioState.periodsIdToIndex,
    (_state_: RootState) => _state_.appScenarioState.minifiedPeriods,
    (_state_: RootState) => _state_.appScenarioState.periods,
    (itemIdsByStockStatusByPeriod, periodsIdToIndex, minifiedPeriods, periods) => createStockStatusWithPeriodTypeSearchTree(periodsIdToIndex, itemIdsByStockStatusByPeriod, minifiedPeriods, periods)
)

export const selectItemByLevelSearchTree = createSelector(
    (_state_: RootState) => _state_.appScenarioState.filterData.itemIdsByLevel,
    (itemIdsByLevel) => createItemLevelSearchTree(itemIdsByLevel)
)

export const selectFilterAttributesOptions = createSelector(
    (_state_: RootState) => _state_.appScenarioState.selectedAttributesToShow,
    (_state_: RootState) => _state_.appScenarioState.attributeList,
    (selectedAttributesToShow, attributeList) => createSelectedAttributeFilters(selectedAttributesToShow, attributeList)
)

export const selectFilterFixedCriteriaOptions = createSelector(
    (_state_: RootState) => _state_.appScenarioState.selectedFiltersToShow,
    (_state_: RootState) => _state_.appScenarioState.FixedCriteriaFilterOptions,
    (selectedAttributesToShow, FixedCriteriaFilterOptions) => createSelectedFixedCriteriaFilters(selectedAttributesToShow, FixedCriteriaFilterOptions)

)

//Select ItemAttributeN
export const selectItemAttribute1SearchTree = createSelector(
    (_state_: RootState) => _state_.appScenarioState.itemAttributes.itemAttribute1,
    (_state_: RootState) => _state_.appScenarioState.filterData.itemsIdsByAttributeId.ItemAttribute1,
    (attributes1, itemsIdsByAttributeId) => createSimpleAttributesTree(attributes1, itemsIdsByAttributeId)
)

export const selectItemAttribute2SearchTree = createSelector(
    (_state_: RootState) => _state_.appScenarioState.itemAttributes.itemAttribute2,
    (_state_: RootState) => _state_.appScenarioState.filterData.itemsIdsByAttributeId.ItemAttribute2,
    (attributes2, itemsIdsByAttributeId) => createSimpleAttributesTree(attributes2, itemsIdsByAttributeId)
)

export const selectItemAttribute3SearchTree = createSelector(
    (_state_: RootState) => _state_.appScenarioState.itemAttributes.itemAttribute3,
    (_state_: RootState) => _state_.appScenarioState.filterData.itemsIdsByAttributeId.ItemAttribute3,
    (attributes3, itemsIdsByAttributeId) => createSimpleAttributesTree(attributes3, itemsIdsByAttributeId)
)

export const selectItemAttribute4SearchTree = createSelector(
    (_state_: RootState) => _state_.appScenarioState.itemAttributes.itemAttribute4,
    (_state_: RootState) => _state_.appScenarioState.filterData.itemsIdsByAttributeId.ItemAttribute4,
    (attributes4, itemsIdsByAttributeId) => createSimpleAttributesTree(attributes4, itemsIdsByAttributeId)
)

export const selectItemAttribute5SearchTree = createSelector(
    (_state_: RootState) => _state_.appScenarioState.itemAttributes.itemAttribute5,
    (_state_: RootState) => _state_.appScenarioState.filterData.itemsIdsByAttributeId.ItemAttribute5,
    (attributes5, itemsIdsByAttributeId) => createSimpleAttributesTree(attributes5, itemsIdsByAttributeId)
)

export const selectItemAttribute6SearchTree = createSelector(
    (_state_: RootState) => _state_.appScenarioState.itemAttributes.itemAttribute6,
    (_state_: RootState) => _state_.appScenarioState.filterData.itemsIdsByAttributeId.ItemAttribute6,
    (attributes6, itemsIdsByAttributeId) => createSimpleAttributesTree(attributes6, itemsIdsByAttributeId)
)

export const selectItemAttribute7SearchTree = createSelector(
    (_state_: RootState) => _state_.appScenarioState.itemAttributes.itemAttribute7,
    (_state_: RootState) => _state_.appScenarioState.filterData.itemsIdsByAttributeId.ItemAttribute7,
    (attributes7, itemsIdsByAttributeId) => createSimpleAttributesTree(attributes7, itemsIdsByAttributeId)
)

export const selectItemAttribute8SearchTree = createSelector(
    (_state_: RootState) => _state_.appScenarioState.itemAttributes.itemAttribute8,
    (_state_: RootState) => _state_.appScenarioState.filterData.itemsIdsByAttributeId.ItemAttribute8,
    (attributes8, itemsIdsByAttributeId) => createSimpleAttributesTree(attributes8, itemsIdsByAttributeId)
)

export const selectItemAttribute9SearchTree = createSelector(
    (_state_: RootState) => _state_.appScenarioState.itemAttributes.itemAttribute9,
    (_state_: RootState) => _state_.appScenarioState.filterData.itemsIdsByAttributeId.ItemAttribute9,
    (attributes9, itemsIdsByAttributeId) => createSimpleAttributesTree(attributes9, itemsIdsByAttributeId)
)

export const selectItemAttribute10SearchTree = createSelector(
    (_state_: RootState) => _state_.appScenarioState.itemAttributes.itemAttribute10,
    (_state_: RootState) => _state_.appScenarioState.filterData.itemsIdsByAttributeId.ItemAttribute10,
    (attributes10, itemsIdsByAttributeId) => createSimpleAttributesTree(attributes10, itemsIdsByAttributeId)
)

//Select ItemCode AttributeN
export const selectItemCodeAttribute1SearchTree = createSelector(
    (_state_: RootState) => _state_.appScenarioState.itemAttributes.itemAttribute1,
    (_state_: RootState) => _state_.appScenarioState.filterData.itemsIdsByAttributeId.ItemAttribute1,
    (_state_: RootState) => _state_.appScenarioState.itemById,
    (attributes1, itemsIdsByAttributeId, itemById) => createItemCodeAttributesTree(attributes1, itemsIdsByAttributeId, itemById)
)
export const selectItemCodeAttribute2SearchTree = createSelector(
    (_state_: RootState) => _state_.appScenarioState.itemAttributes.itemAttribute2,
    (_state_: RootState) => _state_.appScenarioState.filterData.itemsIdsByAttributeId.ItemAttribute2,
    (_state_: RootState) => _state_.appScenarioState.itemById,
    (attributes2, itemsIdsByAttributeId, itemById) => createItemCodeAttributesTree(attributes2, itemsIdsByAttributeId, itemById)
)
export const selectItemCodeAttribute3SearchTree = createSelector(
    (_state_: RootState) => _state_.appScenarioState.itemAttributes.itemAttribute3,
    (_state_: RootState) => _state_.appScenarioState.filterData.itemsIdsByAttributeId.ItemAttribute3,
    (_state_: RootState) => _state_.appScenarioState.itemById,
    (attributes3, itemsIdsByAttributeId, itemById) => createItemCodeAttributesTree(attributes3, itemsIdsByAttributeId, itemById)
)
export const selectItemCodeAttribute4SearchTree = createSelector(
    (_state_: RootState) => _state_.appScenarioState.itemAttributes.itemAttribute4,
    (_state_: RootState) => _state_.appScenarioState.filterData.itemsIdsByAttributeId.ItemAttribute4,
    (_state_: RootState) => _state_.appScenarioState.itemById,
    (attributes4, itemsIdsByAttributeId, itemById) => createItemCodeAttributesTree(attributes4, itemsIdsByAttributeId, itemById)
)
export const selectItemCodeAttribute5SearchTree = createSelector(
    (_state_: RootState) => _state_.appScenarioState.itemAttributes.itemAttribute5,
    (_state_: RootState) => _state_.appScenarioState.filterData.itemsIdsByAttributeId.ItemAttribute5,
    (_state_: RootState) => _state_.appScenarioState.itemById,
    (attributes5, itemsIdsByAttributeId, itemById) => createItemCodeAttributesTree(attributes5, itemsIdsByAttributeId, itemById)
)

export const selectItemCodeAttribute6SearchTree = createSelector(
    (_state_: RootState) => _state_.appScenarioState.itemAttributes.itemAttribute6,
    (_state_: RootState) => _state_.appScenarioState.filterData.itemsIdsByAttributeId.ItemAttribute6,
    (_state_: RootState) => _state_.appScenarioState.itemById,
    (attributes6, itemsIdsByAttributeId, itemById) => createItemCodeAttributesTree(attributes6, itemsIdsByAttributeId, itemById)
)

export const selectItemCodeAttribute7SearchTree = createSelector(
    (_state_: RootState) => _state_.appScenarioState.itemAttributes.itemAttribute7,
    (_state_: RootState) => _state_.appScenarioState.filterData.itemsIdsByAttributeId.ItemAttribute7,
    (_state_: RootState) => _state_.appScenarioState.itemById,
    (attributes7, itemsIdsByAttributeId, itemById) => createItemCodeAttributesTree(attributes7, itemsIdsByAttributeId, itemById)
)

export const selectItemCodeAttribute8SearchTree = createSelector(
    (_state_: RootState) => _state_.appScenarioState.itemAttributes.itemAttribute8,
    (_state_: RootState) => _state_.appScenarioState.filterData.itemsIdsByAttributeId.ItemAttribute8,
    (_state_: RootState) => _state_.appScenarioState.itemById,
    (attributes8, itemsIdsByAttributeId, itemById) => createItemCodeAttributesTree(attributes8, itemsIdsByAttributeId, itemById)
)

export const selectItemCodeAttribute9SearchTree = createSelector(
    (_state_: RootState) => _state_.appScenarioState.itemAttributes.itemAttribute9,
    (_state_: RootState) => _state_.appScenarioState.filterData.itemsIdsByAttributeId.ItemAttribute9,
    (_state_: RootState) => _state_.appScenarioState.itemById,
    (attributes9, itemsIdsByAttributeId, itemById) => createItemCodeAttributesTree(attributes9, itemsIdsByAttributeId, itemById)
)

export const selectItemCodeAttribute10SearchTree = createSelector(
    (_state_: RootState) => _state_.appScenarioState.itemAttributes.itemAttribute10,
    (_state_: RootState) => _state_.appScenarioState.filterData.itemsIdsByAttributeId.ItemAttribute10,
    (_state_: RootState) => _state_.appScenarioState.itemById,
    (attributes10, itemsIdsByAttributeId, itemById) => createItemCodeAttributesTree(attributes10, itemsIdsByAttributeId, itemById)
)
