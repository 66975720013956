import { createAsyncThunk, createSelector } from "@reduxjs/toolkit";
import { getAvailableScenarioIdsForScenarioSummarizedAsync, getScenarioSummarizedByScenarioIdAsync, getScenarioSummarizedDetailsByScenarioIdAsync } from "api/data/lib/scenarioSummarized";
import { ActionType } from "redux/types";
import { appSumarizedSlice } from "./reducers";
import { incrementProgressLoader, incrementColoredProgressLoader, setLoadingScenarioComponentReady } from "redux/AppNavigationState";
import { LoadingScenarioComponent, LoadingStateColor } from "redux/AppNavigationState/types";
import { RootState } from "redux/store";

export const loadScenarioSummarizedByScenarioIdAsync = createAsyncThunk(
    `${ActionType.AppSumarizedState}/loadScenarioSummarizedByScenarioIdAsync`,
    async (scenarioId: number) => {
        const response = await getScenarioSummarizedByScenarioIdAsync({ queryParams: { scenarioId: scenarioId } });
        return response;
    }
)

export const loadGetAvailableScenarioIdsForScenarioSummarizedAsync = createAsyncThunk(
    `${ActionType.AppSchedulerState}/GetAvailableScenarioIdsForScenarioSummarizedAsync`,
    async (props, { dispatch }) => {
        const response = await getAvailableScenarioIdsForScenarioSummarizedAsync();
        dispatch(incrementProgressLoader())
        dispatch(incrementColoredProgressLoader(LoadingStateColor.Yellow))
        dispatch(setLoadingScenarioComponentReady(LoadingScenarioComponent.ScenarioSummarized))
        return response;
    }
)

export const selectAvailableScenariosForScenarioSumarized = (state: RootState) => state.appSumarizedState.availableScenarioIds

export const selectCachedAvailableScenariosForScenarioSumarized = createSelector(
    (_state_: RootState) => selectAvailableScenariosForScenarioSumarized(_state_),
    (_state_: RootState) => _state_.appScenarioState.scenarios,
    (availableScenarios, scenarios) => scenarios.filter(scenario => availableScenarios.includes(scenario.id))
)
export const selectCachedAvailableScenariosForScenarioSumarizedOptions = createSelector(
    (_state_: RootState) => selectCachedAvailableScenariosForScenarioSumarized(_state_),
    (availableForScenarioSumarized) => availableForScenarioSumarized.map(x => ({ name: x.name, id: x.id }))
)

export * from './reducers'
export default appSumarizedSlice.reducer;