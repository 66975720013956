import React from "react";
import { t, Trans } from "@lingui/macro";
import Modal from '@mui/material/Modal';
import Box from "@mui/material/Box";
import { Button, FormControlLabel, MenuItem, Select, Switch, TextField } from "@mui/material";
import { PostTableAsync, PutTableAsync } from "api/data";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};
export default function ManageTable({hidden, close, reloadData, tableEdition, groups}) {
    const [table, setTable] = React.useState<any>({
        schema: "",
        name: "",
        tableEditionGroupId: 1,
        title: "",
        insertData: false,
        deleteData: false,
        requiredFilter: false,
        columnRequiredFilter: "",
        id: 0
    });
    const [loading, setLoading] = React.useState(false);
    React.useEffect(() => {
        setTable(tableEdition)
    },[tableEdition])
    const saveData = async () => {
        setLoading(true)
        try {
            var res = table.id === 0 ?  await PostTableAsync(table) : await PutTableAsync(table)
            if(res.type === "success") {
                setLoading(false)
                reloadData()
                close()
            }
        }
        catch {
            setLoading(false)
        }
    }
    return(
        <React.Fragment>
        <Modal
            open={!hidden}
            onClose={close}
        >
            <Box sx={style}>
                <div style={{ display: "flex", flexDirection: "column", gap: "20px"}}>
                    <h3 style={{textAlign: "center", textTransform: "uppercase", fontWeight: "bold", padding: "10px"}}>Editar Tabela</h3>
                    <Select value={table.tableEditionGroupId} onChange={({target: { value}}) => setTable(prev => ({...prev, tableEditionGroupId: value}))}>
                        {groups?.map((group, index) =>
                            <MenuItem key={index} value={group.id}>{group.name}</MenuItem>
                        )}
                    </Select>
                    <TextField label="Título" value={table.title} onChange={({target: { value  }}) => setTable(prev => ({...prev, title: value}))} />
                    <TextField label="Schema" value={table.schema} onChange={({target: {value}}) => setTable(prev => ({...prev, schema: value}))} />
                    <TextField label="Nome" value={table.name} onChange={({target: {value}}) => setTable(prev => ({...prev, name: value}))} />
                    <FormControlLabel 
                        control={<Switch aria-label="Teste" checked={table.insertData} onChange={({target: { checked }}) => setTable(prev => ({...prev, insertData: checked}))} />}
                        label="Inserir Dados"
                    />
                    <FormControlLabel 
                        control={<Switch aria-label="Teste" checked={table.deleteData} onChange={({target: { checked }}) => setTable(prev => ({...prev, deleteData: checked}))} />}
                        label="Excluir Dados"
                    />
                    <FormControlLabel
                        control={<Switch aria-label="Teste" checked={table.requiredFilter} onChange={({ target: { checked } }) => setTable(prev => ({ ...prev, requiredFilter: checked }))} />}
                        label="Filtro Obrigatório"
                    />
                    {table.requiredFilter &&  <TextField label="Coluna para filtro obrigatório" value={table.columnRequiredFilter} onChange={({ target: { value } }) => setTable(prev => ({ ...prev, columnRequiredFilter: value }))} />}
                    <Button variant="contained" onClick={saveData} disabled={loading}>
                        Salvar
                    </Button>
                    <Button variant="contained" color="error" onClick={close} disabled={loading}>
                        Cancelar
                    </Button>
                </div>
            </Box>
        </Modal>
        </React.Fragment>
        

    )
}
