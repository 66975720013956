import { getAsync, putAsync } from '..';

export function getAllTableColumnAsync(tableId: any): Promise<any> {
    return getAsync<any>({ url: `/tableeditioncolumn/all/${tableId}` })
}
export function getTableColumnsAsync(tableId: number): Promise<Array<any>> {
    return getAsync<Array<any>>({ url: `/tableeditioncolumn/${tableId}` })
}

export function putTableColumnAsync(table: any): Promise<any> {
    return putAsync<any>({ url: `/tableeditioncolumn`, body: { ...table } });
}