import { AsyncStatus } from "redux/types";
import { AppNavigationState, ModalType } from "./types";

export const initialState: AppNavigationState = {
    loadingStateCounter: {
        Blue: {
            loadingCount: 0,
            loadingTarget: 0
        },
        Red: {
            loadingCount: 0,
            loadingTarget: 0
        },
        Yellow: {
            loadingCount: 0,
            loadingTarget: 0
        }
    },
    loadingAppComponent: {
        CompleteStructures: AsyncStatus.EMPTY,
        DemandDataRange: AsyncStatus.EMPTY,
        PermissionContexts: AsyncStatus.EMPTY,
        ResourcePriorities: AsyncStatus.EMPTY,
        Scenarios: AsyncStatus.EMPTY,
        TaskDescriptions: AsyncStatus.EMPTY,
        UserFlatData: AsyncStatus.EMPTY,
        Resources: AsyncStatus.EMPTY,
        ItemOperation: AsyncStatus.EMPTY,
        ItemResource: AsyncStatus.EMPTY
    },
    loadingScenarioComponent: {
        Capacities: AsyncStatus.EMPTY,
        ItemAttributesBundle: AsyncStatus.EMPTY,
        Items: AsyncStatus.EMPTY,
        Periods: AsyncStatus.EMPTY,
        PlanningGridAllocations: AsyncStatus.EMPTY,
        PlanningGridResources: AsyncStatus.EMPTY,
        PlanningGrids: AsyncStatus.EMPTY,
        ResourceGroup: AsyncStatus.EMPTY,
        ScenarioSummarized: AsyncStatus.EMPTY
    },
    loadingState: {
        isVisible: false,
        loadingCount: 0,
        loadingTarget: 0
    },
    isHidden: true,
    isTokenExpired: true,
    userToken: null,
    modalRender: {
        modalType: ModalType.Closed,
        data: {},
        callback: undefined
    },
    pipeline: {
        pipelineProgress: {
            message: '',
            error: '',
            title: '',
            runningTime: '',
            percentage: ''
        },
        isPipelineRunning: false
    },
    permissionContexts: []
}