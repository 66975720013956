import NeoLoader from 'components/Page/NeoLoader';
import Multiselect from 'multiselect-react-dropdown';
import { useDispatch } from 'react-redux';
import { loadScenarioSummarizedByScenarioIdAsync, selectCachedAvailableScenariosForScenarioSumarizedOptions } from 'redux/AppSumarizedState';
import { useAppSelector } from 'redux/hooks';
import { AsyncStatus } from 'redux/types';
import { useState } from 'react';
import { t } from '@lingui/macro';
import ComparisonPanelRowContainer from './components/ComparisonPanelRowContainer';

function ComparisonPage() {
    const dispatch = useDispatch();
    const availableForScenarioSumarizedOptions = useAppSelector(selectCachedAvailableScenariosForScenarioSumarizedOptions);
    const scenarioSummarizedByScenarioId = useAppSelector(state => state.appSumarizedState.scenarioSummarizedByScenarioId);

    const [selectedScenarioSumarizedData, setSelectedScenarioSumarizedData] = useState<{ name: string, id: number }[]>([]);

    const periodLoadStatus = useAppSelector((state) => state.appNavigationState.loadingScenarioComponent.Periods)
    if (periodLoadStatus !== AsyncStatus.READY) return <NeoLoader />

    const onSelect = (selectedList: { name: string, id: number }[], selectedItem: { name: string, id: number }) => {
        if (scenarioSummarizedByScenarioId[selectedItem.id] === undefined)
            dispatch(loadScenarioSummarizedByScenarioIdAsync(selectedItem.id));

        setSelectedScenarioSumarizedData([...selectedList])
    }

    const onRemove = (selectedList: { name: string, id: number }[], selectedItem: { name: string, id: number }) => {
        setSelectedScenarioSumarizedData([...selectedList])
    }

    return (
        <div className='grow w-full h-auto mb-16 pb-1 bg-white rounded'>
            <Multiselect
                className='shrink w-full h-28'
                isObject={true}
                onRemove={onRemove}
                onSelect={onSelect}
                options={availableForScenarioSumarizedOptions}
                displayValue="name"
                placeholder={t`Selecione um ou mais Cenários`}
            />
            <div className='flex flex-col h-auto w-full space-y-5'>
                <ComparisonPanelRowContainer selectedScenarioSumarizedData={selectedScenarioSumarizedData} />
            </div>
        </div>
    )
}

export default ComparisonPage
