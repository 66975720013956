import React from 'react'

interface Props {
    children?: React.ReactNode
}

function NeoPanel({ children }: Props) {
    return (
        <div className='grow flex-col max-h-screen mb-36'>
            <div className={`flex flex-col h-full w-full justify-center`}>
                {children}
            </div>
        </div>
    )
}

export default NeoPanel
