import React from "react";
import { useParams } from "react-router-dom";
import { defaultRender } from './components/renders'
import AddButton from './components/AddButton'
import { getTableButtonsAsync } from "api/data";
import Delete from './components/Delete'
import { t, Trans } from "@lingui/macro";
function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}
class TableEditionButton extends React.Component<any, any> {
    constructor(props) {
        super(props)
        this.state = {
            modalOpen: true,
            modalDeleteOpen: false,
            tableDeleteId: {

            },
            data: [],
            columns: [
                {
                    field: "name",
                    title: t`Campo`,
                    render: defaultRender
                },
                {
                    field: "procedureName",
                    title: t`Procedure`,
                    render: defaultRender
                },
                {
                    field: "procedureSchema",
                    title: t`Schema`,
                    render: defaultRender
                },
                // {
                //     field: "mask",
                //     title: "Máscara",
                //     render: defaultRender
            ],
            columnEditing: {}
        }
    }
    async refreshData() {
        var tablesColumns = await getTableButtonsAsync(this.props.params.id);
        this.setState(prevState => ({ ...prevState, data: tablesColumns }))
    }
    componentDidMount(): void {
        this.refreshData();
    }
    render() {
        return (
            <React.Fragment>
                <AddButton tableId={this.props.params.id} hidden={this.state.modalOpen} closeModal={() => { this.setState(prevState => ({ ...prevState, modalOpen: true })); this.refreshData() }} reloadData={this.refreshData} />
                <Delete tableButtonId={this.state.tableDeleteId} open={this.state.modalDeleteOpen} closeModal={() => { this.setState(prevState => ({ ...prevState, modalDeleteOpen: false })); this.refreshData() }} reloadData={this.refreshData} />
                <h1 style={{ textAlign: "center", fontWeight: "bold", padding: "5px", borderBottom: "1px solid #004" }}><Trans>EDIÇÃO DE BOTÕES</Trans></h1>
                <button onClick={() => this.setState(prevState => ({ ...prevState, modalOpen: false }))} className="flex-shrink-0 cursor-pointer h-10 rounded-full m-2 py-1 px-4 bg-yellow-500 items-center text-white text-lg text-center font-bold hover:shadow-lg w-60">
                    <Trans>NOVO</Trans>
                </button>
                <table className="table-auto" style={{ textAlign: "center", whiteSpace: "nowrap", marginBottom: "115px" }}>
                    <thead>
                        <tr>
                            {this.state.columns.map((column, index) =>
                                <th key={index}>
                                    {column.title}
                                </th>
                            )}
                            <th>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.data?.sort((a, b) => a.order > b.order ? 1 : -1).map((row, index) =>
                            <tr style={{ border: "1px solid black" }} key={index}>
                                {this.state.columns.map((column, index) =>
                                    <td style={{ border: "1px solid black" }} key={index}>
                                        {column.render(row, column)}
                                    </td>
                                )}
                                <td style={{ border: "1px solid black", textAlign: "center", width: "200px" }}>
                                    <button
                                        className="flex-shrink-0 cursor-pointer h-9 rounded-full  py-1 bg-yellow-500 items-center text-white text-md text-center font-bold hover:shadow-lg w-60"
                                        onClick={() => {
                                            this.setState(prevState => ({ ...prevState, modalDeleteOpen: true, tableDeleteId: row }))
                                        }}
                                    >
                                        DELETAR
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </React.Fragment>
        )
    }

}

export default withParams(TableEditionButton)