import React, { ReactNode } from 'react'
import { useDispatch } from 'react-redux'
import { ListChildComponentProps } from 'react-window'
import { PeriodData, ScenarioSummarizedData } from 'redux/AppScenarioState/dataTypes';

interface Props {
    bgColorClass: string,
    children: ReactNode;
}

function DatasetPeriodColumnRow(props: Props) {
    const { bgColorClass, children } = props

    return (
        <div className={`h-auto w-full ${bgColorClass} border-solid	border border-gray-200 divide-y divide-gray-100`}>
            {children}
        </div>
    )
}
const MemoizedDatasetPeriodColumnRow = React.memo(DatasetPeriodColumnRow)

function ScenarioPeriodColumn(props: ListChildComponentProps<{ scenarioSummarizedData: ScenarioSummarizedData[], minifiedPeriods: string[] }>) {
    const scenarioSummarizedData: ScenarioSummarizedData = props.data.scenarioSummarizedData[props.index]

    const minifiedPeriod = props.data.minifiedPeriods[props.index]
    // const scenarioSummarizedData = sumarizedData.sumarizedDataset[props.index]
    // const dispatch = useDispatch();

    // const onPeriodSelect = (periodGroup: number) => {
    //     dispatch(setSumarizedPeriodGroup(periodGroup))
    // }

    return (
        <div key={props.index} style={props.style} className='flex-none h-full w-auto px-1 space-y-1 text-right'>
            <div className='h-auto w-auto bg-gray-300 text-center border-solid	border border-gray-200 divide-y divide-gray-100'>
                {/* <div className='h-auto w-auto bg-gray-300 text-center border-solid	border border-gray-200 divide-y divide-gray-100 cursor-pointer' onClick={() => onPeriodSelect(period.group)}> */}
                {
                    <p>{minifiedPeriod}</p>
                    // <p>{period.monthName}-{period.startDay}-{period.endDay}</p>
                }
            </div>

            <MemoizedDatasetPeriodColumnRow bgColorClass='bg-green-100'>
                <p>{scenarioSummarizedData.demand.toLocaleString()}</p>
                <p>{scenarioSummarizedData.mps.toLocaleString()}</p>
                <p>{scenarioSummarizedData.revenue.toLocaleString()}</p>
            </MemoizedDatasetPeriodColumnRow>

            <MemoizedDatasetPeriodColumnRow bgColorClass='bg-red-100'>
                <p>{scenarioSummarizedData.fixedCost.toLocaleString()}</p>
                <p>{scenarioSummarizedData.variableCost.toLocaleString()}</p>
            </MemoizedDatasetPeriodColumnRow>

            <MemoizedDatasetPeriodColumnRow bgColorClass='bg-blue-100'>
                <p>{scenarioSummarizedData.totalDaysOfCover.toLocaleString()}</p>
                <p>{scenarioSummarizedData.closingStock.toLocaleString()}</p>
                <p>{scenarioSummarizedData.storageUnits.toLocaleString()}</p>
            </MemoizedDatasetPeriodColumnRow>

            <MemoizedDatasetPeriodColumnRow bgColorClass='bg-purple-100'>
                <p>{scenarioSummarizedData.stockStockout.toLocaleString()}</p>
                <p>{scenarioSummarizedData.stockStockoutCost.toLocaleString()}</p>
                <p>{scenarioSummarizedData.stockRisk.toLocaleString()}</p>
                <p>{scenarioSummarizedData.stockRiskCost.toLocaleString()}</p>
                <p>{scenarioSummarizedData.stockExcess.toLocaleString()}</p>
                <p>{scenarioSummarizedData.stockExcessCost.toLocaleString()}</p>
            </MemoizedDatasetPeriodColumnRow>

            <MemoizedDatasetPeriodColumnRow bgColorClass='bg-yellow-100'>
                <p>{(scenarioSummarizedData.usedPercentage * 100).toFixed(0)}%</p>
                <p>{scenarioSummarizedData.idlenessCost.toLocaleString()}</p>
                <p>{scenarioSummarizedData.totalCost.toLocaleString()}</p>
                <p>{scenarioSummarizedData.totalProfit.toLocaleString()}</p>
            </MemoizedDatasetPeriodColumnRow>

        </div>
    )
}

export default ScenarioPeriodColumn
