import { RequestParamsParameters, RequestSignalParameter } from "api";
import { deleteAsync, getAsync } from "..";
import axios from 'axios'
import { identityUrl } from "api/configuration";
//SessionController


export const getRolesAsync = async () => {
    return getAsync<any>({ url: '/role/' });
}

export const getRoleAsync = async (roleId: any) => {
    return getAsync<any>({ url: `/role/${roleId}` });
}

export const createRoleAsync = async (role) => {
    try {
        var { data } = await axios.post('role', role, { baseURL: identityUrl })
        return data
    }
    catch(ex) {
        return {
            type: "error",
            message: "Falha na solicitação",
            ex
        }
    }
}

export const updateRoleAsync = async (role) => {
    try {
        var { data } = await axios.put('role', role, { baseURL: identityUrl })
        return data
    }
    catch(ex) {
        return {
            type: "error",
            message: "Falha na solicitação",
            ex
        }
    }
}
export const deleteRoleAsync = async (roleId: Number, parameters?: RequestSignalParameter) => {
    return deleteAsync({ url: `/role/${roleId}`, ...parameters });
}
