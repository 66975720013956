import { useState } from 'react';
import ConfigurationPanel from './components/ConfigurationPanel';
import { RiNotification2Fill, RiUserFill } from 'react-icons/ri'
import NotificationPanel from './components/NotificationPanel';
import ProcedureBarPanel from 'components/ProcedureBarPanel/ProcedureBarPanel';
import ScenarioDropdownSelector from './components/ScenarioDropdownSelector';
import DownloadProgressContainer from './components/DownloadProgressContainer';
import { ClickAwayListener } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import SaveScenarioContainer from './components/SaveScenarioContainer';
//@ts-ignore
import { ModuleSwitchLanguage } from './_modules/module-APP_TARGET';
interface Props {
    handleOnMenuHidMenu: () => void;
    isMenuCollapsed: boolean;
    user: any;
}
function TopPanel(props: Props) {
    const [isConfigurationPanelOpen, setIsConfigurationPanelOpen] = useState(false);
    const [isNotificationPanelOpen, setIsNotificationPanelOpen] = useState(false);

    const handleOnProfileClick = () => {
        if (isNotificationPanelOpen) {
            setIsNotificationPanelOpen(false);
        }
        setIsConfigurationPanelOpen(!isConfigurationPanelOpen);
    }
    const handleOnNotificationClick = () => {
        if (isConfigurationPanelOpen) {
            setIsConfigurationPanelOpen(false);
        }
        setIsNotificationPanelOpen(!isNotificationPanelOpen);
    }

    return (
        <div className='flex items-center h-16 w-full bg-gray-800'>
            <div className='flex h-full w-full items-center'>
                <div style={{ marginLeft: "82px", width: "300px" }} className='grow shrink-0 min-w-max w-64 h-full mr-10'>
                    <ScenarioDropdownSelector />
                </div>
                <DownloadProgressContainer />
                <SaveScenarioContainer />

                <div className='flex w-full items-center place-content-end'>
                    <div className='shrink-0 w-fit h-full mr-10'>
                        <ProcedureBarPanel />
                    </div>

                    {/* <ModuleSwitchLanguage handleToggleLanguage={() => toggleLanguage(appLanguage)} appLanguage={appLanguage} /> */}

                    <ClickAwayListener onClickAway={() => setIsNotificationPanelOpen(false)}>
                        <span className='mr-4 h-full text-white' >
                            <div className='relative flex w-full h-full cursor-pointer items-center' onClick={handleOnNotificationClick}>
                                <RiNotification2Fill className='h-6 w-4 mr-2' />
                                <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-red-400 top-0 right-1 opacity-75" />
                                <span className="absolute inline-flex rounded-full h-2 w-2 bg-red-500 top-0 right-1.5" />
                            </div>
                            {
                                <NotificationPanel isSelected={isNotificationPanelOpen} />
                            }
                        </span>
                    </ClickAwayListener>
                    <ClickAwayListener onClickAway={() => setIsConfigurationPanelOpen(false)}>
                        <span className='mr-4 h-full ' >
                            <div className='relative h-full py-4'>
                                <RiUserFill className='h-6 w-4 mr-2' style={{ cursor: "pointer", color: "white" }} onClick={handleOnProfileClick} />
                                {isConfigurationPanelOpen &&
                                    <ConfigurationPanel close={() => setIsConfigurationPanelOpen(false)} user={props.user} isSelected={isConfigurationPanelOpen} />
                                }
                            </div>
                        </span>
                    </ClickAwayListener>
                </div>

            </div>
        </div>
    )
}

export default TopPanel
