export function sortArrayByKey(array, key) {
  // Create a copy of the array using spread syntax
  const newArray = [...array];

  // Use the Array.sort() method with a custom comparator function
  newArray.sort(function (a, b) {
    // Retrieve the values for the specified key
    const aValue = a[key];
    const bValue = b[key];

    // Compare the values
    if (aValue < bValue) {
      return -1;
    } else if (aValue > bValue) {
      return 1;
    } else {
      return 0;
    }
  });

  return newArray;
}
