import { useEffect } from 'react'
import { nPlanModalProps } from '.'
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { useFieldArray, useForm } from 'react-hook-form';
import { Dictionary } from 'utils/types';

//HTMLInputTypeAttribute
type MultiInputTypeAttribute =
    | 'number'
    | 'password'
    | 'text'
export interface MultiDataInputs {
    dataType: MultiInputTypeAttribute
    header?: string;
    name: string;
    defaultValue?: string | number | ReadonlyArray<string>;
    // defaultChecked?: boolean | undefined;
    className?: string;
    placeholder?: string,
    required?: boolean,
    value?: any
}
export interface MultiDataOutputs {
    dataType: MultiInputTypeAttribute
    name: string;
    required?: boolean,
    value: any
}

export interface MultiDataModalProps extends nPlanModalProps {
    headerText: string,
    bodyText?: string,
    multiDataInputs: MultiDataInputs[]
    acceptButtonText: string,
    cancelButtonText?: string,
    sendCallbackAsync?: (multiDataInputs: Dictionary<MultiDataOutputs>) => Promise<void>
}

interface LocalMultiDataInputs extends MultiDataInputs {
    value?: any
}

function MultiDataModal(props: MultiDataModalProps) {
    const { headerText, bodyText, acceptButtonText, cancelButtonText, sendCallbackAsync, cancelCallback, exitCallback } = props
    const multiDataInputs: LocalMultiDataInputs[] = props.multiDataInputs

    const { register, handleSubmit, formState: { isSubmitting }, control } = useForm({
        defaultValues: {
            multiDataInputs: multiDataInputs
        }
    });
    const { fields } = useFieldArray({
        name: "multiDataInputs",
        control: control
    });

    useEffect(() => {
        const close = (e) => {
            if (e.key === 'Escape') {
                exitCallback?.()
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])

    const handleOnsendCallback = (multiDataInputs: LocalMultiDataInputs[]) => {
        if (!sendCallbackAsync) {
            exitCallback?.()
            return
        };
        const multiDataObject: Dictionary<MultiDataOutputs> = {}
        for (let i = 0; i < multiDataInputs.length; i++) {
            const multiDataInput = multiDataInputs[i];
            multiDataObject[multiDataInput.name] = {
                dataType: multiDataInput.dataType,
                name: multiDataInput.name,
                value: multiDataInput.value,
                required: multiDataInput.required
            }
        }
        sendCallbackAsync?.(multiDataObject)
        exitCallback?.()
    }

    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <form id='createScriptForm' className='flex-row w-full' onSubmit={handleSubmit(data => handleOnsendCallback(data.multiDataInputs))}>
                    <div className="relative w-full my-6 mx-auto max-w-md">
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex  items-center justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                <div className='w-11/12'>
                                    <h3 className="text-3xl font-semibold text-gray-600">
                                        {headerText}
                                    </h3>
                                </div>
                                <div className='w-1/12 h-full cursor-pointer flex items-center flex-col justify-center'>
                                    <AiOutlineCloseCircle
                                        className="ml-auto self-center text-gray-600 hover:text-gray-900 float-right "
                                        onClick={() => exitCallback?.()}
                                        size={40}
                                    >
                                    </AiOutlineCloseCircle>
                                </div>
                            </div>
                            {/*body*/}
                            {
                                bodyText &&
                                <div className="relative flex-auto px-6 py-3">
                                    <p className=''>
                                        {bodyText}
                                    </p>
                                    {/*footer*/}
                                </div>
                            }
                            {
                                multiDataInputs &&
                                fields.map((field, index) => (
                                    <li key={index}>
                                        <div className='relative p-6 flex-auto'>
                                            {field.header && <h5 className='modal-header'>{field.header}</h5>}
                                            <input className='modal-input'
                                                {...register(`multiDataInputs.${index}.value`, { required: field.required })}
                                                type={field.dataType}
                                                defaultValue={field.defaultValue}
                                                placeholder={field.placeholder}
                                            />
                                            {/* {errors.description && "Descrição do Script é necessária."} */}
                                        </div>
                                    </li>
                                ))
                            }
                            <div className={`flex items-center justify-evenly pb-3 pt-3 ${bodyText ? 'mt-3 border-t border-solid border-blueGray-200' : ''} rounded-b`}>
                                {
                                    cancelButtonText &&
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                                        onClick={() => cancelCallback?.()}
                                    >
                                        {cancelButtonText}
                                    </button>
                                }
                                <button className='w-fit px-4 h-10 font-semibold text-lg rounded-full text-white items-center justify-center bg-yellow-400 focus:outline-none'>
                                    {acceptButtonText}
                                </button>
                            </div>

                        </div>
                    </div>
                </form>
            </div >
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}

export default MultiDataModal
