import {
  AiFillPlayCircle,
  AiOutlineStop,
  AiOutlinePauseCircle,
} from "react-icons/ai";
import { setSelectedTimestamp } from "redux/AppStockState";
import { useState, useEffect, useCallback } from "react";
import { useAppSelector } from "redux/hooks";
import { useDispatch } from "react-redux";
import { InventoryHealthComponentProps } from "../types";
import { GraphComponent } from "./GraphComponent";
import { InfoTooltip } from "./InfoToolTip";
import { Trans } from "@lingui/macro";
import { v4 as uuidv4 } from "uuid";
import { replaceDotWithComma } from "../utils/replaceDotWIthComma";
import { ToolTipComponentReactNodeChildren } from "./TooltipComponentNodeChildren";
import { useLingui } from "@lingui/react";


function classNames(...classes) { return classes.filter(Boolean).join(" "); }

export function InventoryHealthComponent({ scenarioStatusOptions, scenarioTimestamp, inventoryHealthFilteredByCompany, totalItemsNumber, totalValue, }: InventoryHealthComponentProps) {
  const dispatch = useDispatch();
  const { selectedScenario, selectedTimestamp } = useAppSelector((state) => state.appStockState);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [pausedIndex, setPausedIndex] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const { i18n } = useLingui()
  useEffect(() => { setCurrentIndex(0); }, [selectedScenario]);

  useEffect(() => { dispatch(setSelectedTimestamp({ timestamp: scenarioTimestamp[currentIndex] })); }, [scenarioTimestamp, currentIndex]);

  useEffect(() => {
    let intervalId;

    const iterate = () => {
      setCurrentIndex((prevIndex) => {
        if (prevIndex + 1 >= scenarioTimestamp.length) {
          setIsRunning(false);
          setIsFinished(true);
          setPausedIndex(0);
          return prevIndex; // Keep the last index as selected
        }
        return prevIndex + 1;
      });
    };

    if (isRunning) {
      intervalId = setInterval(iterate, 500);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [scenarioTimestamp, isRunning, selectedScenario]);

  const startIteration = () => {
    if (!isRunning) {
      setIsRunning(true);
      setIsFinished(false);
      setCurrentIndex(pausedIndex);
    }
  };

  const pauseIteration = () => {
    setIsRunning(false);
    setPausedIndex(currentIndex);
  };

  const stopIteration = () => {
    setIsRunning(false);
    setCurrentIndex(0);
    setPausedIndex(0);
    setIsFinished(false);
    dispatch(setSelectedTimestamp({ timestamp: scenarioTimestamp[0] })); // Set the first value as selected
  };
  const formatGraphMoneyValue = useCallback((number: number) => {
    // Formata o número com duas casas decimais
    let numeroFormatado = number.toLocaleString("pt-BR", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    // Adiciona o símbolo de R$ e o espaço em branco para o alinhamento à direita
    let numeroFormatadoComSimbolo = numeroFormatado.padStart(10);
    return numeroFormatadoComSimbolo;
  }, []);

  return (
    <div className="flex flex-col items-center  justify-start w-full h-full p-2 gap-1">
      <div className="flex w-full flex-col items-start p-1 justify-start ">
        <p className="text-lg font-black text-left">
          <Trans>Situação do Estoque</Trans>
        </p>
      </div>
      {/* ----- TABELA DE VALORES ----- */}
      <div className="flex gap-2 max-h-fit  mb-2 flex-col justify-start items-center  border  rounded-md">
        <div className="flex flex-col w-full">
          <div className="overflow-x-auto">
            <table className=" divide-y divide-gray-200 table-fixed ">
              <thead className="bg-gray-50">
                <tr className="bg-gray-100">
                  <th scope="col" className="py-1 w-40  text-lg font-bold text-center text-gray-500 uppercase">
                    <Trans>Situação</Trans>
                  </th>
                  <th scope="col" className="py-1 w-40 text-lg font-bold text-center text-gray-500 uppercase">
                    <Trans>Valor</Trans>{" "}
                  </th>
                  <th scope="col" className="py-1 w-30 text-lg font-bold text-center text-gray-500 uppercase">
                    <Trans>Valor</Trans> %
                  </th>
                  <th scope="col" className="py-1 w-20 text-lg font-bold  text-center text-gray-500 uppercase">
                    <Trans>Itens</Trans>
                  </th>
                  <th scope="col" className="py-1 w-20 text-lg font-bold text-center  text-gray-500 uppercase">
                    <Trans>Itens</Trans> %
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 ">
                {inventoryHealthFilteredByCompany
                  .filter((c) => c.estoqueStatusCodigoDesc === "Ruptura")
                  .map((statusData, index) => {
                    const estoqueValorPositive = Math.abs(
                      statusData.estoqueValor
                    );
                    return (
                      <tr key={uuidv4()} className="h-1 w-full bg-red-50">
                        <td className="relative text-center text-lg text-red-600 font-medium  whitespace-nowrap">
                          <div
                            className="flex justify-start items-center gap-2"
                            style={{ paddingLeft: "10%" }}
                          >
                            <div>
                              <InfoTooltip tooltipText={statusData.description} />
                            </div>
                            <div style={{ borderRadius: "50%", background: `${statusData.color}`, width: "14px", height: "14px", border: "1px solid #333", }}></div>
                            <p>{statusData.estoqueStatusCodigoDesc}</p>

                          </div>
                        </td>
                        <td className="font-semibold p text-right text-lg text-red-600 whitespace-nowrap">
                          <div className="flex justify-between">
                            <div>
                              <Trans>R$</Trans>
                            </div>
                            <div>{formatGraphMoneyValue(estoqueValorPositive)}</div>
                          </div>
                        </td>
                        <td className=" w-20 p text-right text-lg  text-red-600 whitespace-nowrap">
                          <p style={{ paddingRight: "25%" }}>{replaceDotWithComma(((estoqueValorPositive / totalValue) * 100).toFixed(1)) + ` %`}</p>
                        </td>
                        <td className=" p text-right text-lg  font-medium  text-red-600 whitespace-nowrap">
                          <p style={{ paddingRight: "25%" }}>{statusData.quantidadeDeItens}</p>
                        </td>
                        <td className=" p text-right text-lg w-20 font-medium text-red-600  whitespace-nowrap">
                          <p style={{ paddingRight: "15%" }}>{replaceDotWithComma(((statusData.quantidadeDeItens / totalItemsNumber) * 100).toFixed(1)) + ` %`}</p>
                        </td>
                      </tr>
                    );
                  })}
                {inventoryHealthFilteredByCompany
                  .filter((c) => c.estoqueStatusCodigoDesc !== "Ruptura")
                  .map((statusData, index) => {
                    return (
                      <tr key={uuidv4()} className="h-1">
                        <td className=" p text-center text-lg font-medium text-gray-800 whitespace-nowrap">
                          <div className="flex justify-start items-center gap-2" style={{ paddingLeft: "10%" }}>
                            <div>
                              <InfoTooltip tooltipText={statusData.description} />
                            </div>
                            <div style={{ borderRadius: "50%", background: `${statusData.color}`, width: "14px", height: "14px", border: "1px solid #333", }}></div>
                            <p>{statusData.estoqueStatusCodigoDesc}</p>
                          </div>
                        </td>
                        <td className=" flex justify-between p  font-semibold text-right text-lg text-gray-800 whitespace-nowrap">
                          <div>R$</div>
                          <div>{formatGraphMoneyValue(statusData.estoqueValor)}</div>
                        </td>
                        <td className=" p text-right text-lg text-gray-800 whitespace-nowrap">
                          <p style={{ paddingRight: "25%" }}>{replaceDotWithComma(((statusData.estoqueValor / totalValue) * 100).toFixed(1)) + ` %`}</p>
                        </td>
                        <td className=" p text-right text-lg font-medium  whitespace-nowrap">
                          <p style={{ paddingRight: "25%" }}>{statusData.quantidadeDeItens}</p>
                        </td>
                        <td className=" p text-right text-lg font-medium  whitespace-nowrap">
                          <p style={{ paddingRight: "15%" }}>{replaceDotWithComma(((statusData.quantidadeDeItens / totalItemsNumber) * 100).toFixed(1)) + ` %`}</p>
                        </td>
                      </tr>
                    );
                  })}
                <tr className="h-5 bg-gray-200 ">
                  <td className=" p text-center text-lg font-medium text-gray-800 whitespace-nowrap">
                    Total
                  </td>
                  <td className="w-40 font-semibold p text-right text-lg text-gray-800 whitespace-nowrap">
                    <div className="flex justify-between">
                      <div>R$</div>
                      <div>
                        {formatGraphMoneyValue(
                          inventoryHealthFilteredByCompany
                            .filter(
                              (c) => c.estoqueStatusCodigoDesc !== "Ruptura"
                            )
                            .reduce(
                              (accumulator, currentValue) =>
                                accumulator + currentValue.estoqueValor,
                              0
                            )
                        )}
                      </div>
                    </div>
                  </td>
                  <td className="w-20 p  text-right text-lg text-gray-800 whitespace-nowrap">
                    <p style={{ paddingRight: "25%" }}>100,0 %</p>
                  </td>
                  <td className="w-20 p text-right text-lg font-medium  whitespace-nowrap">
                    <p style={{ paddingRight: "25%" }}>
                      {inventoryHealthFilteredByCompany
                        .filter((c) => c.estoqueStatusCodigoDesc !== "Ruptura")
                        .reduce(
                          (accumulator, currentValue) =>
                            accumulator + currentValue.quantidadeDeItens,
                          0
                        )}
                    </p>
                  </td>
                  <td className="p text-right w-20 text-lg font-medium  whitespace-nowrap">
                    <p style={{ paddingRight: "15%" }}>100,0 %</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* ----- GRÁFICO ----- */}
      <GraphComponent
        inventoryHealthFilteredByCompany={inventoryHealthFilteredByCompany}
      />

      <div className="flex flex-col w-full">
        <div className="h-fit flex w-full justify-center items-center text-right ">
          {scenarioStatusOptions.map((status) => {
            return (
              <div
                style={{ backgroundColor: `${status.color}` }}
                className="flex-1 h-8 items-center text-right justify-center flex"
                key={uuidv4()}
              >
                <strong>{status.estoqueStatusCodigoDesc}</strong>
              </div>
            );
          })}
        </div>
        <div className="h-fit w-full flex gap-1 justify-start overflow-auto items-center text-right ">
          <div className="flex items-center justify-center">
            {!isRunning ? (
              <button className="hover:brightness-90" onClick={() => { startIteration(); }}>
                <ToolTipComponentReactNodeChildren tooltipText={i18n.t(`Iniciar animação`)} color={""}>
                  <AiFillPlayCircle size={30} color="#3730A3" />
                </ToolTipComponentReactNodeChildren>
              </button>
            ) : (
              <div className="flex gap-2 items-center justify-center">
                <button className="hover:brightness-90" onClick={() => { stopIteration(); }}>
                  <ToolTipComponentReactNodeChildren tooltipText={i18n.t(`Parar animação`)} color={""}>
                    <AiOutlineStop size={30} color="#3730A3" />
                  </ToolTipComponentReactNodeChildren>
                </button>
                <button className="hover:brightness-90" onClick={() => { pauseIteration(); }}>
                  <ToolTipComponentReactNodeChildren tooltipText={i18n.t(`Pausar animação`)} color={""}>
                    <AiOutlinePauseCircle size={30} color="#3730A3" />
                  </ToolTipComponentReactNodeChildren>
                </button>
              </div>
            )}
          </div>
          {scenarioTimestamp.map((timestamp, index) => {
            return (
              <div key={index} className="flex-1  my-1 h-fit items-center text-right justify-center flex">
                <button className={classNames(timestamp === selectedTimestamp ? "bg-indigo-800 text-white" : "text-gray-900 bg-white", "inline-flex items-center rounded-md  p-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300")}
                  onClick={() => dispatch(setSelectedTimestamp({ timestamp, }))}                >
                  {timestamp}
                </button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
