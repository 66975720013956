import { notifyError } from "utils/notification";
import { ScriptButton } from "api/scheduler/types";
import { useForm } from "react-hook-form";
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setModalState } from "redux/AppNavigationState";
import { ModalType } from "redux/AppNavigationState/types";
import { HexColorPicker } from "react-colorful";
import { Trans } from "@lingui/macro";
interface FormScriptButtonData extends ScriptButton {
    path: string
}

function ScriptButtonCreateModal() {
    const dispatch = useAppDispatch();
    const scriptId: number = useAppSelector((state) => state.appNavigationState.modalRender.data);
    const scriptName = useAppSelector((state) => state.appSchedulerState.scripts.value.filter(x => x.id === scriptId)[0].name);
    const [colorCode, setColorCode] = useState("#b32aa9");
    const [buttonName, setButtonName] = useState('');

    const { register, handleSubmit } = useForm<FormScriptButtonData>();

    const handleOnSubmit = async (data: any) => {
        notifyError({
            message: "Not Implemented",
            timer: 3000,
            action: onCloseModal
        })
    }

    const onCloseModal = () => {
        dispatch(setModalState(ModalType.Closed))
    }

    return (
        <><div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
        >
            <div className="relative w-full my-6 mx-auto max-w-md">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                        <h3 className="text-3xl font-semibold text-gray-600">
                            <Trans>Criar novo ScriptButton</Trans>
                        </h3>
                        <AiOutlineCloseCircle
                            className="ml-auto self-center text-black float-right w-8 h-8"
                            onClick={onCloseModal}
                        >
                        </AiOutlineCloseCircle>
                    </div>

                    {/*body*/}
                    <div className="relative p-6 flex-auto">
                        <form id='createTaskForm' className='flex-row w-full' onSubmit={handleSubmit(handleOnSubmit)}>
                            <div>
                                <div className="w-full h-auto">
                                    <h5 className='inline-block text-gray-600 my-2'>
                                        <Trans>Nome do Script</Trans>: <p className="font-bold">{scriptName}</p>
                                    </h5>
                                    <div
                                        className='select-none inline-block cursor-pointer w-auto h-10 rounded-full m-2 py-2 px-4 items-center text-white text-lg text-center font-bold hover:shadow-lg'
                                        style={{ backgroundColor: `${colorCode}` }}
                                    >
                                        {buttonName}
                                    </div>
                                </div>

                                <h5 className='text-gray-600 my-2'>
                                    <Trans>Rota</Trans>:
                                </h5>
                                <input
                                    className='flex w-full border text-black appearance rounded-lg'
                                    maxLength={30}
                                    {...register('path')}
                                    type='text'
                                    name='path'
                                    id='path'
                                    placeholder='/manage/scripts'
                                />

                                <h5 className='text-gray-600 my-2'>
                                    <Trans>Texto do ScriptButton</Trans>:
                                </h5>
                                <input
                                    className='flex w-full border text-black appearance rounded-lg'
                                    maxLength={30}
                                    type='text'
                                    name='scriptButtonText'
                                    id='scriptButtonText'
                                    placeholder='Execute Data Import'
                                    onChange={(event) => setButtonName(event.target.value)}
                                />

                                <div className="flex my-2 w-full place-content-center">
                                    <HexColorPicker className="inline-block" color={colorCode} onChange={setColorCode} />
                                    <div className="mx-2">
                                        <h5 className='text-gray-600 my-2'>
                                            <Trans>Código da cor</Trans>:
                                        </h5>
                                        <input
                                            className='flex w-full border text-black appearance rounded-lg'
                                            maxLength={30}
                                            {...register('colorCode')}
                                            type='text'
                                            name='colorCode'
                                            id='colorCode'
                                            placeholder='#FFFFFF'
                                            value={colorCode}
                                            onChange={(event) => setColorCode(event.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/*footer*/}
                            <div className="flex items-center justify-evenly mt-3 pt-3 border-t border-solid border-blueGray-200 rounded-b">
                                <button
                                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                                    onClick={onCloseModal}
                                >
                                    <Trans>Fechar</Trans>
                                </button>
                                <button className='w-20 h-10 font-semibold text-lg rounded-full text-white items-center justify-center bg-yellow-400 focus:outline-none'>
                                    <Trans>Salvar</Trans>
                                </button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}

export default ScriptButtonCreateModal
