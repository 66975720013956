import { Dispatch } from "redux";
import { setSelectedScenarioId, loadCapacitiesByScenarioIdAsync, loadItemAttributesBundleAsync, loadDemandDataRangesAsync, loadScenariosAsync, loadResourceGroupDetailsAsync, loadItemsAsync, loadPlanningGridsByScenarioIdAsync, loadPeriodsByScenarioIdAsync, loadPlanningGridAllocationsDetailsByScenarioIdAsync, loadPlanningGridResourcesByScenarioIdAsync, loadUserDataFlatAsync, loadResourcesDetailsAsync, clearSelectedResources, loadCompleteStructureAsync, initializeFixedCriteriaOptions, loadItemResourceAsync, loadItemOperationAsync } from "redux/AppScenarioState";
import { hideProgressLoader, setProgressLoaderTarget, showProgressLoader, setAdditiveProgressLoaderTarget, setColoredProgressLoaderTarget, resetLoadingScenarioComponentStatus, clearColoredProgressLoader } from "redux/AppNavigationState";
import { loadTaskDescriptionsAsync } from "redux/AppSchedulerState";
import { LoadingStateColor } from "redux/AppNavigationState/types";
import { loadGetAvailableScenarioIdsForScenarioSummarizedAsync } from "redux/AppSumarizedState";

//Inicializa dados da aplicação estaticos sem necessidade de chamadas http
export const initialzeStaticAppData = async (dispatch: Dispatch<any>) => {
    dispatch(initializeFixedCriteriaOptions());
}
//Criar metodo generico que conta o array de dispatchs
//separar quais sao yellow,red,blue
let hideLoadAppDataTimeout: NodeJS.Timeout;
//TODO roda primeiro
export const loadAppData = async (dispatch: Dispatch<any>) => {
    dispatch(clearColoredProgressLoader(LoadingStateColor.Red))
    dispatch(clearColoredProgressLoader(LoadingStateColor.Blue))

    const userDataPromise = Promise.resolve(dispatch(loadUserDataFlatAsync({})));
    const yellowAsyncThunkActions = [
        () => dispatch(loadScenariosAsync({ asyncDependencies: [userDataPromise] })),
        () => dispatch(loadTaskDescriptionsAsync({})),
        () => dispatch(loadResourcesDetailsAsync({})),
        () => dispatch(loadDemandDataRangesAsync({})),
        () => dispatch(loadItemOperationAsync({})),
        () => dispatch(loadItemResourceAsync({})),
        () => dispatch(loadGetAvailableScenarioIdsForScenarioSummarizedAsync())
    ]

    //contabilizar o userDataPromise
    const yellowLoadCount = yellowAsyncThunkActions.length + 1
    dispatch(setProgressLoaderTarget(yellowLoadCount))

    dispatch(setColoredProgressLoaderTarget({ color: LoadingStateColor.Yellow, number: yellowLoadCount }))
    //Reset para não aparecer preenchido

    dispatch(showProgressLoader())
    await Promise.all(yellowAsyncThunkActions.map(actions => actions()));

    hideLoadAppDataTimeout = setTimeout(() => {
        dispatch(hideProgressLoader())
        dispatch(setProgressLoaderTarget(0))
    }, 2500);
}
let controller: AbortController;
let abortSignal: AbortSignal;

let hideLoadScenarioAppDataTimeout: NodeJS.Timeout;

//TODO roda quando seleciona um scenario
export const loadScenarioAppData = async (scenarioId: number, dispatch: Dispatch<any>) => {
    dispatch(clearSelectedResources())

    let localAborted: boolean = false;
    if (abortSignal !== undefined) {
        controller.abort();
    }
    if (controller === undefined || controller.signal.aborted) {
        controller = new AbortController();
        abortSignal = controller.signal;
        abortSignal.onabort = () => {
            localAborted = true;
            clearTimeout(hideLoadScenarioAppDataTimeout)
        };
    }

    clearTimeout(hideLoadAppDataTimeout)
    await dispatch(resetLoadingScenarioComponentStatus())
    dispatch(clearColoredProgressLoader(LoadingStateColor.Red))
    dispatch(clearColoredProgressLoader(LoadingStateColor.Blue))

    if (!hideLoadAppDataTimeout) {
        dispatch(setProgressLoaderTarget(0))
    }

    dispatch(setSelectedScenarioId(scenarioId))

    //RED
    const planningGridsByScenarioIdPromise = Promise.resolve(dispatch(loadPlanningGridsByScenarioIdAsync({ abortSignal, scenarioId })));
    const itemsPromise = Promise.resolve(dispatch(loadItemsAsync({ abortSignal })));

    const redAsyncThunkActions = [
        //RED
        () => dispatch(loadPeriodsByScenarioIdAsync({ abortSignal, scenarioId, asyncDependencies: [planningGridsByScenarioIdPromise, itemsPromise] })),
        () => dispatch(loadItemAttributesBundleAsync({ abortSignal, asyncDependencies: [planningGridsByScenarioIdPromise, itemsPromise] })),
        () => dispatch(loadCompleteStructureAsync({ abortSignal, asyncDependencies: [planningGridsByScenarioIdPromise] })),
    ]
    const redLoadCount = redAsyncThunkActions.length + 2

    const blueAsyncThunkActions = [
        //BLUE
        () => dispatch(loadPlanningGridAllocationsDetailsByScenarioIdAsync({ abortSignal, scenarioId, asyncDependencies: [planningGridsByScenarioIdPromise, itemsPromise] })),
        () => dispatch(loadResourceGroupDetailsAsync({ abortSignal })),
        () => dispatch(loadPlanningGridResourcesByScenarioIdAsync({ abortSignal, scenarioId })),
        () => dispatch(loadCapacitiesByScenarioIdAsync({ abortSignal, scenarioId })),
    ]
    const blueLoadCount = blueAsyncThunkActions.length

    dispatch(setAdditiveProgressLoaderTarget(redLoadCount + blueLoadCount))

    dispatch(setColoredProgressLoaderTarget({ color: LoadingStateColor.Red, number: redLoadCount }))
    dispatch(setColoredProgressLoaderTarget({ color: LoadingStateColor.Blue, number: blueLoadCount }))
    dispatch(showProgressLoader())

    const redAsync = Promise.all([...redAsyncThunkActions.map(actions => actions())]);
    const blueAsync = Promise.all([...blueAsyncThunkActions.map(actions => actions())]);
    await Promise.all([redAsync, blueAsync])

    if (!localAborted)
        hideLoadScenarioAppDataTimeout = setTimeout(() => dispatch(hideProgressLoader()), 2500);
}