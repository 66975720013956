import { RequestParamsParameters, RequestSignalParameter } from "api";
import { getAsync, postAsync } from "api/scheduler";
import { ScriptButtonRoute } from "api/scheduler/types";
export const route = '/buttons/'


export const getScriptButtonRouteAsync = async (parameters?: RequestSignalParameter): Promise<ScriptButtonRoute[]> => {
    return getAsync<ScriptButtonRoute[]>({ url: `${route}`, ...parameters });
}
export const createScriptButtonsAsync = async (parameters: RequestParamsParameters<null, ScriptButtonRoute[]>): Promise<ScriptButtonRoute[]> => {
    return postAsync({ url: `${route}`, ...parameters });
}
