import React, { useEffect } from "react";
import DropdownTreeSelect, { TreeNode } from "react-dropdown-tree-select";
import { setInitialAttributeOptions, setSelectedAttributes, updateAttributeOptions } from "redux/AppScenarioState/reducers";
import { useAppDispatch, useAppSelector } from "redux/hooks";

export function AttributeSelectionDropdownMenu() {
    const dispatch = useAppDispatch();
    const { attributeOptions } = useAppSelector((state) => state.appScenarioState);

    useEffect(() => {
        dispatch(setInitialAttributeOptions())
    }, [])

    const onChangeSearch = (currentNode, selectedNodes: TreeNode[]) => {
        const newDataValue = selectedNodes.map((node) => {
            return { label: node.label, value: node.value, checked: node.checked };
        })
        dispatch(setSelectedAttributes({ selectedAttributes: newDataValue }))
        dispatch(updateAttributeOptions({ selectedAttribute: currentNode }))
    };
    return (
        <DropdownTreeSelect
            className="min-w-fit hide-selected-text mx-2"
            inlineSearchInput={true}
            texts={{ placeholder: "Atributos" }}
            showDropdown={"default"}
            data={attributeOptions.options}
            keepTreeOnSearch={true}
            keepChildrenOnSearch={true}
            onChange={onChangeSearch}
        />
    );
}
export const AttributeSelectionDropdownMenuMemoized = React.memo(AttributeSelectionDropdownMenu);
