import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import * as filters from './Filters'
const RequiredFilterPopup = ({ open, handleRequiredFilter, column, data, foreignFields, setFilter }) => {
    return (
        <Dialog open={open}>
            <DialogTitle textAlign={"center"}>Filtro Obrigatório</DialogTitle>
            <DialogContent sx={{ maxWidth: 300, minWidth: 300 }}>
                {column?.filterType === 1 && <filters.NumberFilter column={column} setFilter={setFilter} />}
                {column?.filterType === 2 && <filters.TextFilter column={column} setFilter={setFilter} />}
                {column?.filterType === 3 && <filters.BooleanFilter column={column} setFilter={setFilter} />}
                {column?.filterType === 4 && <filters.DateFilter column={column} data={data} setFilter={setFilter} />}
                {column?.filterType === 5 && <filters.SelectFilter column={column} foreignFields={foreignFields} data={data} setFilter={setFilter} />}
            </DialogContent>
            <DialogActions sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Button onClick={handleRequiredFilter} color="primary" variant='contained'>
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RequiredFilterPopup;