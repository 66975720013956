import React, { useCallback, useRef, useState } from 'react'
import { VariableSizeList } from 'react-window'
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { PlanningGridCommonWidth } from './types';
import { selectDynamicPeriodCount, selectItemsLength, toggleExpandMaterialFlowData } from 'redux/AppScenarioState';
import ItemRowListContainer from './containers/ItemRowListContainer';
import PlanningGridRowContainer from './containers/PlanningGridRowContainer';
import GridHeaderColumnContainer from './containers/GridHeaderColumnContainer';
import { Trans } from '@lingui/macro';
import update from 'immutability-helper'

function PlanningGrid() {
    const dispatch = useAppDispatch();
    const dynamicPeriodCount = useAppSelector(selectDynamicPeriodCount);
    const itemsLength = useAppSelector(selectItemsLength);
    const completeStructuresByItemMestre = useAppSelector(state => state.appScenarioState.completeStructuresByItemMaster);
    const orderedItemIds = useAppSelector(state => state.appScenarioState.filterData.orderedItemIds)

    const headerColumnRef = useRef<VariableSizeList>(null);
    const itemListRef = useRef<any>(null);//FixedSizeList
    const gridRef = useRef<any>(null);//FixedSizeGrid

    const handleGridScroll = useCallback(e => {
        if (e.scrollUpdateWasRequested) return;
        itemListRef.current?.scrollTo(e.scrollTop);
        headerColumnRef?.current?.scrollTo(e.scrollLeft);
    }, []);

    const handleHeaderListScroll = useCallback(e => {
        if (e.scrollUpdateWasRequested) return;
        gridRef.current?.scrollTo({ scrollLeft: e.scrollOffset });
    }, []);

    const handleProductScroll = useCallback(e => {
        if (e.scrollUpdateWasRequested) return;
        gridRef.current?.scrollTo({ scrollTop: e.scrollOffset });
    }, []);

    const itemRowToggleSizeRef = useRef<boolean[]>(new Array(itemsLength).fill(false));
    const [rowSize, setRowSize] = useState<number[]>(new Array(itemsLength).fill(240));
    const calculateItemSize = (index: number) => {
        return rowSize[index]
    }
    const handleToggleSizeContainer = (index: number, itemId: number) => {
        dispatch(toggleExpandMaterialFlowData(itemId));
        if (itemListRef.current) {
            itemListRef.current.resetAfterIndex(index);
        }
        if (gridRef.current) {
            gridRef.current.resetAfterRowIndex(index);
        }
        itemRowToggleSizeRef.current[itemId] = !itemRowToggleSizeRef.current[itemId]
        //TODO filter completeStructureList
        const completeStructuresByItemMestreById = completeStructuresByItemMestre[itemId]
        // let completeStructureLenghtFiltered = 0;
        // for (let i = 0; i < completeStructuresByItemMestreById.length; i++) {
        //     const localCompleteStructuresByItemMestreById = completeStructuresByItemMestreById[i];
        //     if (orderedItemIds.includes(localCompleteStructuresByItemMestreById.childId)) {
        //         completeStructureLenghtFiltered++;
        //     }
        // }
        if (itemRowToggleSizeRef.current[itemId]) {
            setRowSize(update(rowSize, { [index]: { $set: completeStructuresByItemMestreById.length * 240 } })
            )
        } else {
            setRowSize(update(rowSize, { [index]: { $set: 240 } })
            )
        }
    }

    return (
        <div className='flex flex-row h-full max-h-screen w-full'>
            <div className='flex grow flex-col max-w-xl h-full'>
                <div className={`flex flex-shrink-0 w-auto h-20 text-lg font-medium sticky top-0 z-10 bg-white`}>
                    <div className='inline-block w-32 border-r-2 border-opacity-50 border-gray-400 align-bottom'>
                        <div className='h-1/2'></div>
                        <p><Trans>Produto</Trans></p>
                    </div>

                    <div className='inline-block w-full'>
                        <div className='block border-b-2 border-opacity-50 border-gray-400 h-1/2'>
                            <p style={{ paddingLeft: '10px' }}><Trans>Detalhes</Trans></p>
                        </div>

                        <div className='block h-1/2'>
                            <p style={{ paddingLeft: '10px' }}><Trans>Descrição</Trans></p>
                        </div>
                    </div>
                </div>
                <div className='flex grow h-96'>
                    <ItemRowListContainer
                        itemSize={240}
                        onScroll={handleProductScroll}
                        ref={itemListRef}
                        calculateItemSize={calculateItemSize}
                        itemListRef={itemListRef}
                        itemsLength={itemsLength}
                        toggleSizeContainer={handleToggleSizeContainer}
                    />
                </div>
            </div>
            <div className='flex grow flex-col h-full'>
                <div className='flex h-20 border-l-2 sticky top-0 z-10 bg-white'>
                    <GridHeaderColumnContainer ref={headerColumnRef} onScroll={handleHeaderListScroll} />
                </div>
                <div className='flex grow h-96'>
                    <PlanningGridRowContainer
                        columnCount={dynamicPeriodCount}
                        columnWidth={PlanningGridCommonWidth}
                        calculateItemSize={calculateItemSize}
                        onScroll={handleGridScroll}
                        ref={gridRef}
                    />
                </div>
            </div>
        </div>
    )
}

// export default PlanningGrid
export const PlanningGridMemoized = React.memo(PlanningGrid);
