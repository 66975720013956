import { t } from '@lingui/macro'
import React from 'react'
import Switch from 'react-switch'
export function defaultRender(row: any, column: any) {
    return (
        <React.Fragment>
            { row[column.field] }
        </React.Fragment>
    )
}

export function checkBoxRender(row: any, column: any) {
    return (
        <React.Fragment>
            <Switch onColor='#EAB308' offColor='#6e6d6b' onChange={ () => { } } checked={ row[column.field] } readOnly />
        </React.Fragment>
    )
}

export function filterRender(row: any, column: any) {
    return (
        <React.Fragment>
            {
                row[column.field] === 1 ?
                    t`Número`
                    :
                    row[column.field] === 2 ?
                        t`Texto`
                        :
                        row[column.field] === 3 ?
                            t`Verdadeiro/Falso`
                            :
                            row[column.field] === 4 ?
                                t`Data`
                                :
                                t`Select`
            }
        </React.Fragment>
    )
}