import { Dictionary } from "utils/types";
import { calculateMpsStepByStep, createChartLabelData, getChartLabelData, localApplyDownMultipleReorder } from "../helpers";
import { AppScenarioState, ChartDataset, OccupationChartScenario, ResourceAggregator } from "../types";
import { PlanningGridData } from "../dataTypes";
import { PlanningGrid } from "api/data/types";
import { i18n } from "@lingui/core";

export const applyOccupationConstraintByPeriodIndex = (state: AppScenarioState, periodIndex: number) => {
    let { resourcesToSelect, selectedResources, chartDataByResourceGroup, itemById, planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId, setupTimeByItemId, periods, planningGridsDataByItemId } = state;

    let capacityDataByResourceNameByPeriodIndex: Dictionary<Dictionary<{ capacityOnPeriod: number, usedCapacity: number, resourceGroup: string }>> =
        getResourceGroupCapacities(selectedResources, chartDataByResourceGroup, setupTimeByItemId);

    for (let i = 0; i < selectedResources.length; i++) {
        const selectedResourceByIndex = selectedResources[i];
        let resourceChartData = chartDataByResourceGroup[selectedResourceByIndex.resourceGroupName][selectedResourceByIndex.resourceName];

        if (resourceChartData) {
            const resourceChartDatasets = resourceChartData.datasets;
            //TODO Gambiarra?
            if (periodIndex > periods.length) periodIndex = periods.length - 1;

            const periodId = periods[periodIndex].id;

            //Pegar capacidade utilizada no dado periodo
            let {
                datasetIndexByItemId,
                itemConstraintPriority,
                itemsUsedCapacityOnPeriod,
                usedCapacityOnPeriodByItem
            } = calculateUsedCapacityOnPeriodByItemId(periodIndex, resourceChartDatasets, planningGridsDataByItemId)

            usedCapacityOnPeriodByItem = sumCapacityUsedOnPeriod(itemsUsedCapacityOnPeriod);
            //fim do setup da variaveis necessarias no periodoIndex

            let allocationForItems = findAllocationForItemsOnPeriodOnResourceGroup(
                periods.length,
                selectedResourceByIndex,
                periodIndex,
                itemsUsedCapacityOnPeriod,
                capacityDataByResourceNameByPeriodIndex);

            //Se não tiver espaço nos outros periodos? -> remover produção?
            const { usedCapacityOnPeriodAfterRealocate } = realocateSelectedItemsWithoutSufficientCapacity(
                state,
                periodId,
                periodIndex,
                usedCapacityOnPeriodByItem,
                selectedResourceByIndex,
                allocationForItems,
                resourcesToSelect,
                resourceChartDatasets,
                capacityDataByResourceNameByPeriodIndex,
                datasetIndexByItemId,
                itemConstraintPriority,
            )
            usedCapacityOnPeriodByItem = usedCapacityOnPeriodAfterRealocate;

            //Restringir os produtos até estarem dentro da capacidade do periodo, mas antes devesse tentar mover para
            //outros periodos
            const capacityData = resourceChartData.datasets[0].data;
            const capacityDataOnPeriod = capacityData[periodIndex]

            //TODO pegar o setupTime utilizado em um dado periodo
            //Gambiarra? talvez melhor salvar o valor somente?
            const setupOnPeriod = resourceChartData.datasets
                .filter(x => x.label.toLowerCase() === 'setup')
                .map(x => x.data[periodIndex])
                .reduce((acc, curr) => acc + curr, 0);

            while ((usedCapacityOnPeriodByItem + setupOnPeriod) > capacityDataOnPeriod) {
                let priorityItem = getProductHightPriority(itemConstraintPriority)
                let item = itemById[priorityItem.itemId];
                const itemId = item.id;
                const localMPS = state.planningGridsDataByItemId[itemId][periodIndex].mps

                if (localMPS === 0) {
                    delete itemConstraintPriority[itemId];
                    continue;
                }

                const planningGridByProductId = planningGridsDataByItemId[priorityItem.itemId];
                if (!planningGridByProductId) continue;

                //Temos itemId e periodIndex

                localApplyDownMultipleReorder(state, { itemId: priorityItem.itemId, itemPeriodIndex: periodIndex }, false);
                const minimunReorderMultiple = state.planningGridsDataByItemId[itemId][0].multipleReorder;//gambiarra?

                if (localMPS === undefined) {
                    console.error(`on applyOccupationConstraintByPeriodIndex, localMPS is null`);
                    throw new Error(`on applyOccupationConstraintByPeriodIndex, localMPS is null`)
                }
                const localMpsPerUnitOfTime = localMPS / itemsUsedCapacityOnPeriod[itemId]

                if (minimunReorderMultiple === undefined) {
                    console.error(`on applyOccupationConstraintByPeriodIndex, minimunReorderMultiple is null`);
                    throw new Error(`on applyOccupationConstraintByPeriodIndex, minimunReorderMultiple is null`)
                }
                const quantityOfTime = Math.round(minimunReorderMultiple / localMpsPerUnitOfTime);

                const planningGridOnPeriod: PlanningGrid = planningGridByProductId[periodIndex];
                if (planningGridOnPeriod.targetStock === undefined) {
                    console.error(`on applyOccupationConstraintByPeriodIndex, planningGridOnPeriod.targetStock is null`);
                    throw new Error(`on applyOccupationConstraintByPeriodIndex, planningGridOnPeriod.targetStock is null`)
                }
                if (planningGridOnPeriod.closingStock === undefined) {
                    console.error(`on applyOccupationConstraintByPeriodIndex, planningGridOnPeriod.closingStock is null`);
                    throw new Error(`on applyOccupationConstraintByPeriodIndex, planningGridOnPeriod.closingStock is null`)
                }
                let priorityScore = planningGridOnPeriod.closingStock / planningGridOnPeriod.targetStock;
                if (isNaN(priorityScore) || priorityScore < 0) priorityScore = 0;
                itemConstraintPriority[priorityItem.itemId] = priorityScore // valor de 0 a 1 +-
                usedCapacityOnPeriodByItem -= quantityOfTime;

                ////////////////////
                //Atualiza chartDataByResourceGroup
                const datasetIndex = datasetIndexByItemId[itemId]
                const itemDataset = resourceChartData.datasets[datasetIndex];

                itemDataset.data[periodIndex] -= quantityOfTime;
                const { resourceId, itemCode, operationId } = getChartLabelData(itemDataset.label)

                if (itemDataset.data[periodIndex] <= 0 || planningGridOnPeriod.mps === 0) {
                    usedCapacityOnPeriodByItem -= itemDataset.data[periodIndex];//TODO gambiarra? fix pois há pequenas diferenças de valores
                    itemDataset.data[periodIndex] = 0;

                    let itemsWithSameOrderCounter = resourceChartData.datasets.filter(x => (x.order === itemDataset.order) && (x.data[periodIndex] > 0)).length

                    //TODO implementar metodo para adicionar/remover setupTime por produto/periodIndex
                    let outputSummedSetupTimeChartDataset = resourceChartData.datasets.find(x => x.order === (itemDataset.order + 1) && x.type !== 'line')
                    const productSetupTime: number = setupTimeByItemId[itemId];
                    if (outputSummedSetupTimeChartDataset && itemsWithSameOrderCounter === 0) {
                        outputSummedSetupTimeChartDataset.data[periodIndex] -= productSetupTime
                        if (outputSummedSetupTimeChartDataset.data[periodIndex] < 0) outputSummedSetupTimeChartDataset.data[periodIndex] = 0
                    };
                }
                // const restOfValues = resourceChartData.datasets
                //     .filter(x => x.label.toLowerCase() !== 'setup')
                //     .map(x => x.data[periodIndex])
                //     .reduce((acc, curr) => acc + curr, 0) - 120;

                //Precisa atualizar planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId
                const fromPlanningGridAllocation = planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId[itemId][selectedResourceByIndex.id][operationId][periodId];
                if (fromPlanningGridAllocation) {
                    fromPlanningGridAllocation.totalAllocatedTime -= quantityOfTime
                    fromPlanningGridAllocation.totalProcessTime -= quantityOfTime
                    if (fromPlanningGridAllocation.totalAllocatedTime <= 0) {
                        delete planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId[itemId][selectedResourceByIndex.id][periodId]
                    }
                }
            }
        }
    }
}
export const calculatePriorityScore = (fromPeriodIndex: number, planningGridDataByItemId: PlanningGridData[]) => {
    const planningGridOnPeriod: PlanningGridData = planningGridDataByItemId[fromPeriodIndex];
    if (planningGridOnPeriod.targetStock === undefined) {
        console.error(`on calculatePriorityScore, planningGridOnPeriod.targetStock is null`);
        throw new Error(`on calculatePriorityScore, planningGridOnPeriod.targetStock is null`)
    } if (planningGridOnPeriod.closingStock === undefined) {
        console.error(`on calculatePriorityScore, planningGridOnPeriod.closingStock is null`);
        throw new Error(`on calculatePriorityScore, planningGridOnPeriod.closingStock is null`)
    }
    let priorityScore = planningGridOnPeriod.closingStock / planningGridOnPeriod.targetStock;
    if (isNaN(priorityScore) || priorityScore < 0)
        priorityScore = 0;
    return priorityScore;
}

export const calculateUsedCapacityOnPeriodByItemId = (currentPeriodIndex: number, datasets: ChartDataset[], planningGridsDataByItemId: Dictionary<PlanningGridData[]>): {
    itemConstraintPriority: Dictionary<number>,
    datasetIndexByItemId: Dictionary<number>,
    itemsUsedCapacityOnPeriod: Dictionary<number>,
    usedCapacityOnPeriodByItem: number
} => {
    let itemConstraintPriority: Dictionary<number> = {}
    let datasetIndexByItemId: Dictionary<number> = {}
    let itemsUsedCapacityOnPeriod: Dictionary<number> = {}
    let usedCapacityOnPeriodByItem = 0;

    for (let datasetIndex = 1; datasetIndex < datasets.length; datasetIndex++) {
        const dataset = datasets[datasetIndex];

        const { itemId, resourceId, itemCode, operationId } = getChartLabelData(dataset.label);
        if (dataset.label.toLowerCase() === i18n.t(`setup`).toLowerCase()) {
            usedCapacityOnPeriodByItem += dataset.data[datasetIndex];
            continue;
        } else if (isNaN(itemId) || isNaN(resourceId)) continue;

        datasetIndexByItemId[itemId] = datasetIndex;

        if (dataset.data[currentPeriodIndex] > 0) {
            //Se somar setupTime teremos muitos setupTime repetidos somados!
            //TODO criar estrututura para informar quais estão no mesmo periodo e 
            //compartilham o mesmo setupTime!
            itemsUsedCapacityOnPeriod[itemId] = dataset.data[currentPeriodIndex]
            // valor de 0 a 1 +-
            itemConstraintPriority[itemId] = calculatePriorityScore(currentPeriodIndex, planningGridsDataByItemId[itemId]);
        }
    }

    usedCapacityOnPeriodByItem += sumCapacityUsedOnPeriod(itemsUsedCapacityOnPeriod);

    return {
        itemConstraintPriority,
        datasetIndexByItemId,
        itemsUsedCapacityOnPeriod,
        usedCapacityOnPeriodByItem
    }
}

export const sumCapacityUsedOnPeriod = (productsUsedCapacityOnPeriod: Dictionary<number>): number => {
    return Object.values(productsUsedCapacityOnPeriod).reduce((acc, curr) => acc + curr, 0);
}


export interface AllocationForItems {
    resourceName: string
    resourceGroupName: string
    toPeriodIndex: number
    itemId: number
    capacityUsed: number
}
export const findAllocationForItemsOnPeriodOnResourceGroup = (
    periodLength: number,
    analysedResourceAggregator: ResourceAggregator,
    analysedPeriodIndex: number,
    itemsUsedCapacityOnPeriod: Dictionary<number>,
    capacityDataByResourceNameByPeriodIndex: Dictionary<Dictionary<{ capacityOnPeriod: number, usedCapacity: number, resourceGroup: string }>>
) => {
    let allocationForItems: AllocationForItems[] = []
    const resourceCapacityOnAnalysedPeriod = capacityDataByResourceNameByPeriodIndex[analysedResourceAggregator.resourceName][analysedPeriodIndex].capacityOnPeriod;
    let resourceUsedCapacityOnAnalysedPeriod = capacityDataByResourceNameByPeriodIndex[analysedResourceAggregator.resourceName][analysedPeriodIndex].usedCapacity;

    let minUsedCapacity = Math.min(...Object.values(itemsUsedCapacityOnPeriod))

    for (let periodIndex = 0; periodIndex < periodLength; periodIndex++) {
        for (const [resourceName, resourceCapacityVacancyByPeriodindex] of Object.entries(capacityDataByResourceNameByPeriodIndex)) {

            if (resourceCapacityOnAnalysedPeriod > resourceUsedCapacityOnAnalysedPeriod || Object.keys(itemsUsedCapacityOnPeriod).length === 1) {
                return allocationForItems;
            }
            const capacityDataOnPeriodIndex = resourceCapacityVacancyByPeriodindex[periodIndex];
            if (capacityDataOnPeriodIndex.capacityOnPeriod > capacityDataOnPeriodIndex.usedCapacity) {
                let availableCapacity = capacityDataOnPeriodIndex.capacityOnPeriod - capacityDataOnPeriodIndex.usedCapacity;
                //se a capacidade disponivel for menor do que o minimo necessário para a menor capacidade utilizada pelo item nem continuamos
                if (availableCapacity < minUsedCapacity) continue;

                //tentamos encaixar o item com a capacidade mais proxima do disponivel para não sobrar um valor disponivel pequeno que poderá fazer falta depois!
                let itemWithNearestCapacityUsed = getItemWithNearestUsedCapacityValue(itemsUsedCapacityOnPeriod, availableCapacity);
                if (!itemWithNearestCapacityUsed) continue;

                allocationForItems.push({ resourceName, resourceGroupName: capacityDataOnPeriodIndex.resourceGroup, toPeriodIndex: periodIndex, itemId: itemWithNearestCapacityUsed.productId, capacityUsed: itemWithNearestCapacityUsed.capacityUsed });
                delete itemsUsedCapacityOnPeriod[itemWithNearestCapacityUsed.productId];
                minUsedCapacity = Math.min(...Object.values(itemsUsedCapacityOnPeriod))

                resourceUsedCapacityOnAnalysedPeriod -= itemWithNearestCapacityUsed.capacityUsed;
            }
        }
    }
    return allocationForItems;
}

export const realocateSelectedItemsWithoutSufficientCapacity = (
    state: AppScenarioState,
    periodId: number,
    fromPeriodIndex: number,
    usedCapacityOnPeriod: number,
    selectedResource: ResourceAggregator,
    allocationForItems: AllocationForItems[],
    resourcesToSelect: ResourceAggregator[],
    resourceChartDatasets: ChartDataset[],
    capacityDataByResourceNameByPeriod: Dictionary<Dictionary<{ capacityOnPeriod: number, usedCapacity: number }>>,
    datasetIndexByItemId: Dictionary<number>,
    itemConstraintPriority: Dictionary<number>,
): {
    usedCapacityOnPeriodAfterRealocate: number
} => {
    const { planningGridsDataByItemId, chartDataByResourceGroup, itemById, setupTimeByItemId, periods, planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId } = state

    for (let i = 0; i < allocationForItems.length; i++) {
        const { resourceGroupName, resourceName, capacityUsed, itemId, toPeriodIndex } = allocationForItems[i];

        const toResourceId = resourcesToSelect.find(resource => resource.resourceName === resourceName)?.id;
        if (!toResourceId) throw new Error("Resource not found");

        //Transferir de fato o produto de um periodo para outro.
        capacityDataByResourceNameByPeriod[resourceName][toPeriodIndex].usedCapacity += capacityUsed;

        usedCapacityOnPeriod -= capacityUsed;
        const planningGridByProductId = planningGridsDataByItemId[itemId];


        // Atualiza PlanningGrids
        let toPeriodIndexMPS = planningGridByProductId[toPeriodIndex].mps
        if (toPeriodIndexMPS === undefined) {
            console.error(`on realocateSelectedItemsWithoutSufficientCapacity, planningGridByProductId[toPeriodIndex].mps is null`);
            throw new Error(`on realocateSelectedItemsWithoutSufficientCapacity, planningGridByProductId[toPeriodIndex].mps is null`)
        }
        toPeriodIndexMPS += planningGridByProductId[fromPeriodIndex].mps ?? 0;
        planningGridByProductId[toPeriodIndex].mps = toPeriodIndexMPS;

        planningGridByProductId[fromPeriodIndex].mps = 0;
        calculateMpsStepByStep(state, itemId, fromPeriodIndex, false, state.isWeekly);

        //Atualiza ChartDataByResourceGroup
        let outputResourceChartData = chartDataByResourceGroup[selectedResource.resourceGroupName][resourceName];
        //TODO muito provavel que não vai funcionar em muitos casos, (transferir de um resource group pra outro)
        if (!outputResourceChartData) {
            outputResourceChartData = chartDataByResourceGroup[resourceGroupName][resourceName]
        }
        if (!outputResourceChartData) throw new Error("outputResourceChartData not found");

        //Atualiza Dataset do Item
        const fromItemDataset = resourceChartDatasets[datasetIndexByItemId[itemId]];
        const { resourceId, itemCode, operationId } = getChartLabelData(fromItemDataset.label)

        const outputItemDataset = {
            ...fromItemDataset,
            data: Array(fromItemDataset.data.length).fill(0),
            label: createChartLabelData(toResourceId, itemId, itemById[itemId].itemCode, operationId)
        }

        //Caso não exista o dataset no outro recurso, é criado um novo
        let toDatasetIndex = outputResourceChartData.datasets.findIndex(dataset => dataset.label === outputItemDataset.label);
        const toItemDataset = outputResourceChartData.datasets;
        if (toDatasetIndex === -1) {
            toItemDataset.push(outputItemDataset)
            toDatasetIndex = toItemDataset.length - 1;
        }

        let itemsWithSameOrderCounter = toItemDataset.filter(x => (x.order === fromItemDataset.order) && (x.data[toPeriodIndex] > 0)).length
        const itemSetupTime: number = setupTimeByItemId[itemId];
        //Deves-se adicionar setupTime caso o recurso seja outro.
        if (toItemDataset[toDatasetIndex].data[toPeriodIndex] === 0) {
            let toSetupTimeChartDataset = toItemDataset.find(x => x.order === (fromItemDataset.order + 1) && x.type !== 'line')
            //Somar setupTime se não existir ainda neste recurso?
            if (toSetupTimeChartDataset && itemsWithSameOrderCounter === 0) {
                toSetupTimeChartDataset.data[toPeriodIndex] += itemSetupTime;
            }
        }

        toItemDataset[toDatasetIndex].data[toPeriodIndex] += fromItemDataset.data[fromPeriodIndex]
        fromItemDataset.data[fromPeriodIndex] = 0;

        let fromSetupTimeChartDataset = resourceChartDatasets.find(x => x.order === (fromItemDataset.order + 1) && x.type !== 'line')

        //TODO deve-se remover somente se não houver outros itens com a mesma order neste mesmo period!
        itemsWithSameOrderCounter = resourceChartDatasets.filter(x => (x.order === fromItemDataset.order) && x.data[fromPeriodIndex] > 0).length
        if (fromSetupTimeChartDataset && itemsWithSameOrderCounter === 1) {
            fromSetupTimeChartDataset.data[fromPeriodIndex] -= itemSetupTime;
            if (fromSetupTimeChartDataset.data[fromPeriodIndex] < 0) fromSetupTimeChartDataset.data[fromPeriodIndex] = 0;
        }

        //Precisa atualizar planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId
        const toPeriodId = periods[toPeriodIndex].id;
        const fromPlanningGridAllocation = planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId[itemId][selectedResource.id][operationId][periodId];

        if (!planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId[itemId][toResourceId])
            planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId[itemId][toResourceId] = {}

        if (!planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId[itemId][toResourceId][operationId])
            planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId[itemId][toResourceId][operationId] = {}

        const toResourceAllocation = planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId[itemId][toResourceId][operationId][toPeriodId];

        //TODO deve-se manter o Id do como NULL para ser gravado no banco
        if (!toResourceAllocation) {
            planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId[itemId][toResourceId][operationId][toPeriodId] = {
                ...fromPlanningGridAllocation,
                periodId: toPeriodId
            }
        } else {
            toResourceAllocation.totalAllocatedTime += fromPlanningGridAllocation.totalAllocatedTime
            toResourceAllocation.totalProcessTime += fromPlanningGridAllocation.totalProcessTime
        }

        if (toResourceId === selectedResource.id) {
            planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId[itemId][selectedResource.id][operationId][periodId].totalAllocatedTime = 0;
            planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId[itemId][selectedResource.id][operationId][periodId].totalProcessTime = 0;
        } else {
            delete planningGridAllocationsByItemIdByResourceIdByOperationIdByPeriodId[itemId][selectedResource.id][periodId]
        }

        //TODO remove o priorityScore do produto que foi transferido
        // delete itemConstraintPriority[itemId];
    }
    return { usedCapacityOnPeriodAfterRealocate: usedCapacityOnPeriod }
}

export const findAllocationForProductsOnPeriod = (
    analysedPeriodIndex: number,
    productsUsedCapacityOnPeriod: Dictionary<number>,
    capacityVacancyByPeriodIndex: Dictionary<{ capacityOnPeriod: number, usedCapacity: number }>
) => {
    const capacityOnAnalysedPeriod = capacityVacancyByPeriodIndex[analysedPeriodIndex].capacityOnPeriod;
    let usedCapacityOnAnalysedPeriod = capacityVacancyByPeriodIndex[analysedPeriodIndex].usedCapacity;

    let productCapacityDataToTransfer: { toPeriodIndex: number, productId: number, capacityUsed: number }[] = [];

    for (const [periodIndex, capacityData] of Object.entries(capacityVacancyByPeriodIndex)) {
        if (capacityOnAnalysedPeriod > usedCapacityOnAnalysedPeriod || Object.keys(productsUsedCapacityOnPeriod).length === 1) {
            return productCapacityDataToTransfer;
        }
        if (capacityData.capacityOnPeriod > capacityData.usedCapacity) {
            let unusedCapacity = capacityData.capacityOnPeriod - capacityData.usedCapacity;
            let productWithNearestCapacityUsed = getItemWithNearestUsedCapacityValue(productsUsedCapacityOnPeriod, unusedCapacity);
            if (!productWithNearestCapacityUsed) continue;
            productCapacityDataToTransfer.push({ toPeriodIndex: parseInt(periodIndex), productId: productWithNearestCapacityUsed.productId, capacityUsed: productWithNearestCapacityUsed.capacityUsed });
            delete productsUsedCapacityOnPeriod[productWithNearestCapacityUsed.productId];
            usedCapacityOnAnalysedPeriod -= productWithNearestCapacityUsed.capacityUsed;
        }
    }
    return productCapacityDataToTransfer;
}

export const getItemWithNearestUsedCapacityValue = (
    productsUsedCapacityOnPeriod: Dictionary<number>,
    tagetValue: number): { productId: number, capacityUsed: number } | undefined => {
    let productWithLowestUsedCapacity: { productId: number, capacityUsed: number } = { productId: 0, capacityUsed: Number.MIN_VALUE };

    for (const [productId, capacityUsed] of Object.entries(productsUsedCapacityOnPeriod)) {
        if (capacityUsed === tagetValue) return { productId: Number(productId), capacityUsed: capacityUsed };
        if (capacityUsed < tagetValue && capacityUsed > productWithLowestUsedCapacity.capacityUsed) {
            productWithLowestUsedCapacity = { productId: Number(productId), capacityUsed: capacityUsed };
        }
    }
    if (productWithLowestUsedCapacity.capacityUsed === Number.MIN_VALUE) return undefined;
    return productWithLowestUsedCapacity;
}

export const getProductHightPriority = (productConstraintPriority: Dictionary<number>): { itemId: number, priority: number } => {
    let productPriority: { itemId: number, priority: number } = { itemId: 0, priority: -1 };
    for (const [productId, priorityScore] of Object.entries(productConstraintPriority)) {
        if (priorityScore > productPriority.priority) {
            productPriority.itemId = Number(productId);
            productPriority.priority = priorityScore;
        }
    }
    return productPriority;
}

export const getProductLowPriority = (productConstraintPriority: Dictionary<number>): { productId: number, priority: number } => {
    let productPriority: { productId: number, priority: number } = { productId: 0, priority: Number.MAX_VALUE };
    for (const [productId, priorityScore] of Object.entries(productConstraintPriority)) {
        if (priorityScore < productPriority.priority) {
            productPriority.productId = Number(productId);
            productPriority.priority = priorityScore;
        }
    }
    return productPriority;
}

export const getResourceGroupCapacities = (selectedResources: ResourceAggregator[], chartDataByResourceGroup: Dictionary<Dictionary<OccupationChartScenario | undefined>>, setupTimeByProductId: Dictionary<number>): Dictionary<Dictionary<{ capacityOnPeriod: number, usedCapacity: number, resourceGroup: string }>> => {
    let capacityDataByResourceNameByPeriodIndex: Dictionary<Dictionary<{ capacityOnPeriod: number, usedCapacity: number, resourceGroup: string }>> = {}

    for (let resourceIndex = 0; resourceIndex < selectedResources.length; resourceIndex++) {
        const selectedResource = selectedResources[resourceIndex];
        let selectedResourceChartData = chartDataByResourceGroup[selectedResource.resourceGroupName][selectedResource.resourceName];

        if (selectedResourceChartData) {
            const capacityData = selectedResourceChartData.datasets[0].data;

            for (let periodIndex = 0; periodIndex < capacityData.length; periodIndex++) {
                const capacityOnPeriod = capacityData[periodIndex];

                let usedCapacityOnPeriod = 0;
                for (let datasetIndex = 1; datasetIndex < selectedResourceChartData.datasets.length; datasetIndex++) {
                    const dataset = selectedResourceChartData.datasets[datasetIndex];

                    if (dataset.data[periodIndex] > 0) {

                        const { itemId, resourceId, itemCode, operationId } = getChartLabelData(dataset.label);
                        if (dataset.label.toLowerCase() === i18n.t(`setup`).toLowerCase()) {
                            usedCapacityOnPeriod += dataset.data[periodIndex];
                            continue;
                        } else if (isNaN(itemId) || isNaN(resourceId)) continue;

                        // const productSetupTime: number = setupTimeByProductId[productId];
                        usedCapacityOnPeriod += dataset.data[periodIndex];
                    }
                }
                if (!capacityDataByResourceNameByPeriodIndex[selectedResource.resourceName]) capacityDataByResourceNameByPeriodIndex[selectedResource.resourceName] = {}
                capacityDataByResourceNameByPeriodIndex[selectedResource.resourceName][periodIndex] = { capacityOnPeriod, usedCapacity: usedCapacityOnPeriod, resourceGroup: selectedResource.resourceGroupName };
            }
        }
    }
    return capacityDataByResourceNameByPeriodIndex;
}

export const getCapacityVacancyByPeriod = (capacityData: number[], chartDatasets: ChartDataset[], setupTimeByProductId: Dictionary<number>): Dictionary<{ capacityOnPeriod: number, usedCapacity: number }> => {
    let capacityVacancyByPeriod: Dictionary<{ capacityOnPeriod: number, usedCapacity: number }> = {};

    for (let periodIndex = 0; periodIndex < capacityData.length; periodIndex++) {
        let usedCapacityOnPeriod = 0;
        for (let datasetIndex = 1; datasetIndex < chartDatasets.length; datasetIndex++) {
            if (chartDatasets[datasetIndex].data[periodIndex] > 0) {
                const { itemId, resourceId, itemCode, operationId } = getChartLabelData(chartDatasets[datasetIndex].label);
                if (isNaN(itemId) || isNaN(resourceId)) continue;
                const productSetupTime: number = setupTimeByProductId[itemId];

                usedCapacityOnPeriod += chartDatasets[datasetIndex].data[periodIndex] + productSetupTime;
            }
        }

        capacityVacancyByPeriod[periodIndex] = { capacityOnPeriod: capacityData[periodIndex], usedCapacity: usedCapacityOnPeriod };
    }
    return capacityVacancyByPeriod;
}