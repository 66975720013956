import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionType, AsyncStatus } from 'redux/types';
import { LoadingAppComponent, LoadingScenarioComponent, LoadingStateColor, ModalType } from './types'
import { initialState } from './initialState';
import { RootState } from 'redux/store';
import { ProgressMessage } from 'redux/Connector/types';
import { IsNullOrEmpty } from 'utils/extensions';
import { CountryCode, CountryData, FlagCode, getCountryDataByCountryCode, SupportedCountriesLanguages } from 'configuration/languages';
import { dynamicActivate } from 'locales';
export { setIsHidden } from 'api/localStorage/NavigationBar'

export const appNavigationSlice = createSlice({
    name: ActionType.AppNavigation,
    initialState,
    reducers: {
        trySetToDefaultCountryData: (state, action: PayloadAction<string>) => {
            const countryData = getCountryDataByCountryCode(action.payload);
            state.selectedCountryData = countryData
            state.language = countryData.code
            localStorage.setItem('language', state.selectedCountryData.code)
            dynamicActivate(state.selectedCountryData.code)
        },
        setCountryDataByCountryCode: (state, action: PayloadAction<CountryCode>) => {
            const countryData = getCountryDataByCountryCode(action.payload);
            state.selectedCountryData = countryData
            state.language = countryData.code
            localStorage.setItem('language', countryData.code)
            dynamicActivate(countryData.code)
        },
        setSelectedCountryData: (state, action: PayloadAction<CountryData>) => {
            state.selectedCountryData = action.payload
            localStorage.setItem('language', action.payload.code)
            dynamicActivate(action.payload.code)
        },
        setLanguage: (state, action: PayloadAction<CountryCode>) => {
            state.language = action.payload
            localStorage.setItem('language', action.payload)
        },
        resetLoadingAppComponentStatus: (state) => {
            for (const loadingComponentKey of Object.keys(state.loadingScenarioComponent)) {
                state.loadingAppComponent[loadingComponentKey] = AsyncStatus.EMPTY;
            }
        },
        resetLoadingScenarioComponentStatus: (state) => {
            for (const loadingComponentKey of Object.keys(state.loadingScenarioComponent)) {
                state.loadingScenarioComponent[loadingComponentKey] = AsyncStatus.EMPTY;
            }
        },
        setLoadingAppComponentReady: (state, action: PayloadAction<LoadingAppComponent>) => {
            state.loadingAppComponent[action.payload] = AsyncStatus.READY;
        },
        setLoadingScenarioComponentReady: (state, action: PayloadAction<LoadingScenarioComponent>) => {
            state.loadingScenarioComponent[action.payload] = AsyncStatus.READY;
        },
        setLoadStatus: (state, action: PayloadAction<{ asyncStatus: AsyncStatus, loadingComponent: LoadingScenarioComponent | LoadingAppComponent }>) => {
            const { asyncStatus, loadingComponent } = action.payload
            if (typeof action.payload === typeof LoadingScenarioComponent) {
                state.loadingScenarioComponent[loadingComponent] = asyncStatus;
            } else {
                state.loadingAppComponent[loadingComponent] = asyncStatus;
            }
        },
        setProgressLoaderTarget: (state, action: PayloadAction<number>) => {
            state.loadingState.loadingCount = 0
            state.loadingState.loadingTarget = action.payload;
        },
        setAdditiveProgressLoaderTarget: (state, action: PayloadAction<number>) => {
            state.loadingState.loadingTarget += action.payload;
        },
        setColoredProgressLoaderTarget: (state, action: PayloadAction<{ color: LoadingStateColor, number: number }>) => {
            const { color, number } = action.payload
            state.loadingStateCounter[color].loadingTarget = number
        },
        clearColoredProgressLoader: (state, action: PayloadAction<LoadingStateColor>) => {
            const color = action.payload
            state.loadingStateCounter[color].loadingTarget = 1
            state.loadingStateCounter[color].loadingCount = 0
        },
        incrementColoredProgressLoader: (state, action: PayloadAction<LoadingStateColor>) => {
            state.loadingStateCounter[action.payload].loadingCount++
        },
        incrementProgressLoader: (state) => {
            state.loadingState.loadingCount++
        },
        addProgressLoader: (state, action: PayloadAction<number>) => {
            state.loadingState.loadingCount += action.payload
        },
        showProgressLoader: (state) => {
            state.loadingState.isVisible = true
        },
        hideProgressLoader: (state) => {
            state.loadingState.isVisible = false
        },
        toggleLoadProgress: (state) => {
            state.loadingState.isVisible = !state.loadingState.isVisible
        },
        resetLoadProgress: (state) => {
            state.loadingState.loadingCount = 0
        },
        toggleHidPanel: (state) => {
            state.isHidden = !state.isHidden
        },
        onLogout: (state) => {
            state.userToken = null
            state.isTokenExpired = true
        },
        setUserToken: (state, action: PayloadAction<string | null>) => {
            state.userToken = action.payload;
        },
        setIsTokenExpired: (state, action: PayloadAction<boolean>) => {
            state.isTokenExpired = action.payload;
        },
        setModalState: (state, action: PayloadAction<ModalType>) => {
            state.modalRender.modalType = action.payload;
        },
        setModalData: (state, action: PayloadAction<{ data: any, callback?: () => void }>) => {
            state.modalRender.data = action.payload.data;
            state.modalRender.callback = action.payload.callback;
        },
        reloadPipelineProgress: (state, action: PayloadAction<ProgressMessage>) => {
            state.pipeline.pipelineProgress = action.payload
        },
        clearPipelineProgress: (state) => {
            state.pipeline = {
                pipelineProgress: {
                    message: '',
                    error: '',
                    title: '',
                    runningTime: '',
                    percentage: ''
                },
                isPipelineRunning: false
            }

        },
        setPipelineProgress: (state, action: PayloadAction<ProgressMessage>) => {
            if (!state.pipeline.isPipelineRunning)
                state.pipeline.isPipelineRunning = true;
            //TODO forma mais inteligente?
            if (!IsNullOrEmpty(action.payload.error))
                state.pipeline.pipelineProgress.error = action.payload.error
            if (!IsNullOrEmpty(action.payload.message))
                state.pipeline.pipelineProgress.message = action.payload.message
            if (!IsNullOrEmpty(action.payload.percentage))
                state.pipeline.pipelineProgress.percentage = action.payload.percentage
            if (!IsNullOrEmpty(action.payload.runningTime))
                state.pipeline.pipelineProgress.runningTime = action.payload.runningTime
            if (!IsNullOrEmpty(action.payload.title))
                state.pipeline.pipelineProgress.title = action.payload.title
        },
        setIsPipelineRunning: (state, action: PayloadAction<boolean>) => {
            state.pipeline.isPipelineRunning = action.payload
        }
    },
    extraReducers: (builder) => {

    }
})

export const {
    trySetToDefaultCountryData,
    setSelectedCountryData,
    clearColoredProgressLoader,
    resetLoadingAppComponentStatus,
    incrementColoredProgressLoader,
    setColoredProgressLoaderTarget,
    setAdditiveProgressLoaderTarget,
    resetLoadingScenarioComponentStatus,
    setLoadingAppComponentReady,
    setLoadingScenarioComponentReady,
    setLoadStatus,
    showProgressLoader,
    hideProgressLoader,
    toggleLoadProgress,
    incrementProgressLoader,
    setProgressLoaderTarget,
    addProgressLoader,
    resetLoadProgress,
    setIsPipelineRunning,
    setPipelineProgress,
    toggleHidPanel,
    setUserToken,
    onLogout,
    setIsTokenExpired,
    setModalState,
    setModalData,
    reloadPipelineProgress,
    setLanguage,
    clearPipelineProgress
} = appNavigationSlice.actions;


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectHidState = (state: RootState) => state;