import { AsyncStatus } from "redux/types";
import { AppSchedulerState } from "./types";

export const initialState: AppSchedulerState = {
    schedules: {
        status: AsyncStatus.EMPTY,
        value: []
    },
    scripts: {
        status: AsyncStatus.EMPTY,
        value: []
    },
    scriptButtonRoute: {
        status: AsyncStatus.EMPTY,
        value: []
    },
    scriptButtonRouteDictionary: {},
    taskDescriptions: {
        status: AsyncStatus.EMPTY,
        value: []
    }
}