import AutoSizer, { Size } from "react-virtualized-auto-sizer";
import { LegacyRef, RefObject, forwardRef, useCallback, useMemo, useRef, useState } from 'react'
import { FixedSizeList, ListOnScrollProps, VariableSizeList } from "react-window";
import ItemRow from "../components/ItemRow";
import { recalculateAllScenarioMpsByProductId, setIsTargetStockByDaysByItemId, setTargetStockByItemId, setIsMinStockByDaysByItemId, setMininimunStockByItemId, setMinimunReorder, setMultipleReorder, selectItemsLength, toggleExpandMaterialFlowData } from "redux/AppScenarioState";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { Dictionary, UserChangeableValue } from "utils/types";
import { memoize } from "lodash";
import { GlobalPlanningGridTargetType } from "redux/AppScenarioState/dataTypes";

export interface ItemRowInput {
    isExpandedProductRow: boolean,
    updateItemGlobalTargetStockType: (targetStockValue: number, isTargetStockByDayHandler: UserChangeableValue<GlobalPlanningGridTargetType>, orderedProductId: number) => void,
    updateItemGlobalMinStockType: (minStockValue: number, isMinStockByDayHandler: UserChangeableValue<GlobalPlanningGridTargetType>, orderedProductId: number) => void
    updateItemMinStock: (minStockHandler: UserChangeableValue<number>, orderedProductId: number) => void,
    updateItemTargetStock: (targetStockHandler: UserChangeableValue<number>, orderedProductId: number) => void,
    updateItemMinimunReorderQuantity: (minimumReorderQuantityHandler: UserChangeableValue<number>, orderedProductId: number) => void,
    updateItemReorderMultiple: (reorderMultipleHandler: UserChangeableValue<number>, orderedProductId: number) => void
}

const createItemRowMemoizedData = memoize((
    isExpandedProductRow: boolean,
    updateItemGlobalTargetStockType: (targetStockValue: number, globalTargetStockType: UserChangeableValue<GlobalPlanningGridTargetType>, orderedProductId: number) => void,
    updateItemGlobalMinStockType: (minStockValue: number, globalMinStockHandler: UserChangeableValue<GlobalPlanningGridTargetType>, orderedProductId: number) => void,
    updateItemMinStock: (minStockHandler: UserChangeableValue<number>, orderedProductId: number) => void,
    updateItemTargetStock: (targetStockHandler: UserChangeableValue<number>, orderedProductId: number) => void,
    updateItemMinimunReorderQuantity: (minimumReorderQuantityHandler: UserChangeableValue<number>, orderedProductId: number) => void,
    updateItemReorderMultiple: (reorderMultipleHandler: UserChangeableValue<number>, orderedProductId: number) => void
): ItemRowInput => ({
    isExpandedProductRow,
    updateItemGlobalTargetStockType,
    updateItemGlobalMinStockType,
    updateItemMinStock,
    updateItemTargetStock,
    updateItemMinimunReorderQuantity,
    updateItemReorderMultiple
}));
interface Props {
    itemListRef: any
    itemSize: number
    itemsLength: number
    calculateItemSize: (index: number) => number
    toggleSizeContainer: (index: number, itemId: number) => void
    onScroll?: ((props: ListOnScrollProps) => any)
}

const ItemRowListContainer = forwardRef((props: Props) => {
    const { onScroll, itemListRef, itemsLength, calculateItemSize, toggleSizeContainer } = props
    const dispatch = useAppDispatch();

    const isExpandedProducts = useAppSelector(state => state.appScenarioState.isExpandedProducts);

    let timeOutLocalHandler = useRef<NodeJS.Timeout>();
    const updateLocalHandlerAndRecalculateScenario = useCallback((
        localHandler: UserChangeableValue<any>,
        orderedItemId: number,
        dispatchMethod?: any,
        callback?: () => void) => {
        if (localHandler.changedByUser === true) {
            if (dispatchMethod) {
                dispatch(dispatchMethod);
            }
            timeOutLocalHandler.current = setTimeout(() => dispatch(recalculateAllScenarioMpsByProductId(orderedItemId)), 700);
        }
        callback?.();
        return () => clearTimeout(timeOutLocalHandler.current)
    }, [])

    const updateItemGlobalTargetStockType = useCallback((targetStockValue: number, globalTargetStockTypeHandler: UserChangeableValue<GlobalPlanningGridTargetType>, orderedItemId: number) => {
        return updateLocalHandlerAndRecalculateScenario(globalTargetStockTypeHandler, orderedItemId, setIsTargetStockByDaysByItemId({ globalTargetStockType: globalTargetStockTypeHandler.value, itemId: orderedItemId }),
            () => {
                if (targetStockValue) {
                    dispatch(setTargetStockByItemId({ newTargetStock: targetStockValue, itemId: orderedItemId }))
                }
            });
    }, [])

    const updateItemGlobalMinStockType = useCallback((minStockValue: number, globalMinStockTypeHandler: UserChangeableValue<GlobalPlanningGridTargetType>, orderedItemId: number) => {
        return updateLocalHandlerAndRecalculateScenario(globalMinStockTypeHandler, orderedItemId, setIsMinStockByDaysByItemId({ globalMinStockType: globalMinStockTypeHandler.value, itemId: orderedItemId }),
            () => {
                if (minStockValue) {
                    dispatch(setMininimunStockByItemId({ newMinimunStock: minStockValue, itemId: orderedItemId }))
                }
            });
    }, [])

    const updateItemMinStock = useCallback((minStockHandler: UserChangeableValue<number>, orderedItemId: number) => {
        updateLocalHandlerAndRecalculateScenario(minStockHandler, orderedItemId, setMininimunStockByItemId({ newMinimunStock: minStockHandler.value, itemId: orderedItemId }))
    }, [])

    const updateItemTargetStock = useCallback((targetStockHandler: UserChangeableValue<number>, orderedItemId: number) => {
        updateLocalHandlerAndRecalculateScenario(targetStockHandler, orderedItemId, setTargetStockByItemId({ newTargetStock: targetStockHandler.value, itemId: orderedItemId }))
    }, [])

    const updateItemMinimunReorderQuantity = useCallback((minimumReorderQuantityHandler: UserChangeableValue<number>, orderedItemId: number) => {
        updateLocalHandlerAndRecalculateScenario(minimumReorderQuantityHandler, orderedItemId, setMinimunReorder({ minimumReorder: minimumReorderQuantityHandler.value, itemId: orderedItemId }))
    }, [])

    const updateItemReorderMultiple = useCallback((reorderMultipleHandler: UserChangeableValue<number>, orderedItemId: number) => {
        updateLocalHandlerAndRecalculateScenario(reorderMultipleHandler, orderedItemId, setMultipleReorder({ multipleReorder: reorderMultipleHandler.value, itemId: orderedItemId }))
    }, [])

    const itemRowDataMemoized = useMemo(() => createItemRowMemoizedData(
        isExpandedProducts,
        updateItemGlobalTargetStockType,
        updateItemGlobalMinStockType,
        updateItemMinStock,
        updateItemTargetStock,
        updateItemMinimunReorderQuantity,
        updateItemReorderMultiple
    ), [isExpandedProducts]);

    return (
        <AutoSizer>
            {(size: Size) => (
                <VariableSizeList
                    height={size.height}
                    width={size.width}
                    className="no-scrollbars"
                    itemCount={itemsLength}
                    itemSize={calculateItemSize}
                    onScroll={onScroll}
                    ref={itemListRef}
                    itemData={{ itemRowDataMemoized, toggleSize: toggleSizeContainer }}
                >
                    {ItemRow}
                </VariableSizeList >
            )}
        </AutoSizer>
    )
})

export default ItemRowListContainer
