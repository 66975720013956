import { useAppSelector } from 'redux/hooks';
import MaterialFlowPlanningGridRow from './MaterialFlowPlanningGridRow';
import { selectMaterialFlowPlanningGridDataByItemId } from 'redux/AppScenarioState';

interface Props {
    itemId: number
    columnIndex: number
}

function MaterialFlowPlanningGridRowContainer(props: Props) {
    const { itemId, columnIndex } = props
    const completeStructureOrderDataByItemMestre = useAppSelector(state => state.appScenarioState.completeStructureOrderDataByItemMaster[itemId]);
    const materialFlowPlanningGridData = useAppSelector(state => selectMaterialFlowPlanningGridDataByItemId(state.appScenarioState, itemId, columnIndex));
    const selectedItemIds = useAppSelector(state => state.appScenarioState.selectedItemIds)
    // const orderedItemIds = useAppSelector(state => state.appScenarioState.filterData.orderedItemIds)

    if (materialFlowPlanningGridData === undefined || materialFlowPlanningGridData.length === 0) return <></>
    return (
        <>
            {
                completeStructureOrderDataByItemMestre &&
                completeStructureOrderDataByItemMestre.isExpanded &&
                completeStructureOrderDataByItemMestre.data.map(
                    (value, index) => {
                        const localMaterialFlowPlanningGridData = materialFlowPlanningGridData[index]
                        // if (!orderedItemIds.includes(localMaterialFlowPlanningGridData.itemId)) {
                        //     return <></>
                        // }
                        return (
                            <div key={`${value.itemId}-${index}`} className={`h-60 flex-col w-full ${selectedItemIds.includes(localMaterialFlowPlanningGridData.itemId) ? 'bg-orange-200' : 'bg-yellow-50'}`}>
                                <MaterialFlowPlanningGridRow
                                    planningGridData={localMaterialFlowPlanningGridData}
                                />
                            </div>
                        )
                    }
                )
            }
        </>
    )
}
export default MaterialFlowPlanningGridRowContainer
