import { useAppSelector } from 'redux/hooks'
import NotificationFeedItem from './NotificationFeedItem'
import AutoSizer, { Size } from "react-virtualized-auto-sizer";
import { FixedSizeList } from 'react-window';
import { notificationData } from '../mockDbNotification';
import { useCallback, useEffect, useMemo, useState } from 'react';

interface Props { }

function NotificationFeedContainer(props: Props) {

    const [fiveItemsArray, setFiveItemsArray] = useState(() => notificationData.slice(0, 5));

    const populateFiveItems = useCallback(() => {
        const remainingItems = notificationData.slice(fiveItemsArray.length, fiveItemsArray.length + 5);
        setFiveItemsArray(prevArray => [...prevArray, ...remainingItems]);
    }, [fiveItemsArray.length, notificationData]);

    const memoizedFiveItemsArray = useMemo(() => fiveItemsArray, [fiveItemsArray]);
    const isEqual = memoizedFiveItemsArray.length === notificationData.length


    // const messages = useAppSelector(state => state.appNotificationState.messages)
    return (
        <>
            <div className='flex flex-col divide-gray-200 divide-y w-full h-full bg-gray-100'>


                <div className='max-w-4xl overflow-y-scroll'>
                    {memoizedFiveItemsArray.map((item, index) => {
                        return (
                            <NotificationFeedItem key={index} index={index} style={{ color: "red" }} data={notificationData} />
                        )
                    })}
                    <div className='w-full h-10 bg-yellow-500 flex items-center justify-center'>
                        <button onClick={() => { populateFiveItems() }} style={{ cursor: isEqual ? 'not-allowed' : 'pointer' }}>
                            <p className='font-semibold'>
                                {isEqual ? 'Não há novas mensagens' : 'Carregar mais itens'}
                            </p>
                        </button>
                    </div>
                </div>

                {/* <FixedSizeList
                    height={2000}
                    itemCount={notificationData.length}
                    itemSize={80}
                    width={500}
                    itemData={notificationData}
                >
                    {NotificationFeedItem}
                </FixedSizeList> */}
            </div >
        </>
    )
}

export default NotificationFeedContainer
