import { useEffect, useRef, useState } from 'react'
import 'chartjs-plugin-dragdata';
import Multiselect from 'multiselect-react-dropdown';
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { AsyncStatus } from 'redux/types'
import NeoLoader from 'components/Page/NeoLoader';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import React from 'react';
import { Dictionary } from 'utils/types';
import { useCallback } from 'react';
import SelectedProductPanel from './components/SelectedProductPanel';
import { setChartDataByResource, toggleIsColorByProduct, applyOccupationConstraintAnimation, setSelectedResources, toggleIsFiniteCapacity, applyResourceCriterias, resourceFilterBy, resourceFilterByDelete, toggleHasProcessTime, clearSelectedResources } from 'redux/AppScenarioState';
import { ResourceAggregator } from 'redux/AppScenarioState/types';
import { ResourceGroupDetails } from 'api/data/types';
import { Trans } from '@lingui/macro';
import { selectOccupationPageDataState } from 'redux/AppNavigationState';
import OccupationChartContainer from './containers/OccupationChartContainer';
import { ModuleAnimationOccupationPage, ModuleSwitchOccupationPage } from './_modules';
import { useLingui } from '@lingui/react';
import { applyResourceGroupCriteria } from 'redux/AppScenarioState/FilterCriteria';
import { resourceFilterCriteria } from 'redux/AppScenarioState/ResourceFilterCriteria/types';


const createChartReferences = (resourceGroupById: Dictionary<ResourceGroupDetails>) => {
    let chartRefDict: Dictionary<Dictionary<React.RefObject<ChartJSOrUndefined<"bar", number[], string>>>> = {};
    for (const resourceGroup of Object.values(resourceGroupById)) {
        if (!chartRefDict[resourceGroup.name]) {
            chartRefDict[resourceGroup.name] = {}
        }
        //TODO precisa buscar pelo Id?
        for (let a = 0; a < resourceGroup.resources.length; a++) {
            const planningResource = resourceGroup.resources[a];
            chartRefDict[resourceGroup.name][planningResource.name] = React.createRef<ChartJSOrUndefined<"bar", number[], string>>()
        }
    }
    return chartRefDict;
}


export interface Props {
    isTopSticky?: boolean;
}

function OccupationPage({ isTopSticky = true }: Props) {
    const dispatch = useAppDispatch();
    const { i18n } = useLingui()
    const occupationPageDataStatus = useAppSelector(selectOccupationPageDataState)
    const [key, setKey] = useState(0)

    const filterResourceData = useAppSelector(state => state.appScenarioState.filterResourceData.resourcesFiltered)

    //toggles
    const isColorToggle = useAppSelector(state => state.appScenarioState.occupationPagePreferences.isColorByProduct)
    const isFiniteToggle = useAppSelector(state => state.appScenarioState.occupationPagePreferences.isFiniteCapacity)
    const hasProcessTime = useAppSelector(state => state.appScenarioState.occupationPagePreferences.hasProcessTime)

    const selectedResources = useAppSelector((state) => state.appScenarioState.selectedResources)
    const occupationPeriodsRef = useRef(useAppSelector(state => state.appScenarioState.periods))

    useEffect(() => {
        dispatch(resourceFilterBy({ resourceFilterCriteria: resourceFilterCriteria.None }))
        dispatch((applyResourceCriterias()))


    }, [occupationPageDataStatus])




    const onSelect = (selectedList: ResourceAggregator[], selectedItem: ResourceAggregator) => {
        dispatch(setChartDataByResource(selectedItem))
        dispatch(setSelectedResources(selectedList))
    }

    const onRemove = (selectedList: ResourceAggregator[], selectedItem: ResourceAggregator) => {
        dispatch(setSelectedResources(selectedList))
    }

    const localToggleIsColorByProduct = () => {
        dispatch(toggleIsColorByProduct());
    }
    const localToggleIsFinite = () => {
        dispatch(toggleIsFiniteCapacity());
        dispatch(clearSelectedResources())
        //TODO GAMBIARRA REMOUNT
        setKey(key + 1)
        if (!isFiniteToggle) {
            dispatch(resourceFilterBy({ resourceFilterCriteria: resourceFilterCriteria.finiteCapacity }))
            dispatch(applyResourceCriterias());
        } else {
            dispatch(resourceFilterByDelete({ resourceFilterCriteria: resourceFilterCriteria.finiteCapacity }))
            dispatch(applyResourceCriterias());
        }
    }

    const localToggleHasProcessTime = () => {
        dispatch(toggleHasProcessTime());
        dispatch(clearSelectedResources())
        //TODO GAMBIARRA REMOUNT
        setKey(key + 2)
        if (!hasProcessTime) {
            dispatch(resourceFilterBy({ resourceFilterCriteria: resourceFilterCriteria.processTime }))
            dispatch(applyResourceCriterias());
        } else {
            dispatch(resourceFilterByDelete({ resourceFilterCriteria: resourceFilterCriteria.processTime }))
            dispatch(applyResourceCriterias());
        }
    }

    const periodIndexRef = useRef<number>(0);
    const localApplyOccupationConstraintAnimation = useCallback(() => {
        if (periodIndexRef.current === occupationPeriodsRef.current.length) {
            return;
        }
        sleep(180).then(() => {
            dispatch(applyOccupationConstraintAnimation(periodIndexRef.current))
            periodIndexRef.current++;
            localApplyOccupationConstraintAnimation();
        })
    }, [])

    const localStopOccupationConstraintAnimation = useCallback(() => {
        if (timeOutRef.current)
            clearTimeout(timeOutRef.current)
    }, [])

    const timeOutRef = useRef<NodeJS.Timeout>();
    const sleep = (ms) => {
        return new Promise(resolve => {
            timeOutRef.current = setTimeout(resolve, ms)
        })
    }

    const responsiveWidth = useCallback(() => {
        const groupnameLiengthmax = Math.max(...filterResourceData.map(c => {
            return c.resourceName.length
        }))

        return groupnameLiengthmax * 10
    }, [filterResourceData])

    useEffect(() => {
        responsiveWidth()
    }, [filterResourceData])

    if (occupationPageDataStatus !== AsyncStatus.READY) return <NeoLoader />

    return (
        <>
            <div className={`flex-shrink-0 flex w-full h-fit mb-2 p-2 justify-between bg-slate-200 rounded-lg ${isTopSticky && 'sticky top-0'}`}>
                <div className='flex h-full w-full'>
                    <div className='flex flex-col px-2 gap-2 w-fit whitespace-nowrap items-start justify-start'>
                        <div className='shrink-0 flex-col min-w-fit justify-center mr-2'>
                            <p className='text-left text-lg font-semibold'><Trans>Cores</Trans></p>
                            <div className="w-full space-x-3">
                                <ModuleSwitchOccupationPage firstText={i18n.t(`Itens`)} secondText={i18n.t(`Cores`)} localToggle={localToggleIsColorByProduct} checked={!isColorToggle} />
                            </div>
                            {/* <p className='text-left text-lg font-semibold'><Trans>Setup</Trans></p>
                            <div className="w-full space-x-3">
                                <p className="inline-block"><Trans>Mostrar</Trans></p>
                                <NeoSwitch className="inline-block" onChange={() => { localToggleIsSetupHidden() }} />
                                <p className="inline-block"><Trans>Esconder</Trans></p>
                        </div> */}
                        </div>
                        <div className='shrink-0 flex-col min-w-fit justify-center mr-2'>
                            <p className='text-left text-lg font-semibold'><Trans>Capacidade Finita</Trans></p>
                            <div className="w-full space-x-3">
                                <ModuleSwitchOccupationPage firstText={i18n.t(`Todos`)} secondText={i18n.t(`Somente Finita`)} localToggle={localToggleIsFinite} checked={isFiniteToggle} />
                            </div>
                        </div>
                        <div className='shrink-0 flex-col min-w-fit justify-center mr-2'>
                            <p className='text-left text-lg font-semibold'><Trans>Tempo de Processo</Trans></p>
                            <div className="w-full space-x-3">
                                <ModuleSwitchOccupationPage firstText={i18n.t(`Todos`)} secondText={i18n.t(`Somente Positivos`)} localToggle={localToggleHasProcessTime} checked={hasProcessTime} />
                            </div>
                        </div>
                    </div>
                    {/* <div className='flex-shrink h-full w-full max-w-3xl align-middle items-center m-2 bg-slate-200 rounded-lg'> */}
                    <Multiselect
                        className='h-full w-fit align-middle items-center m-2 rounded-lg z-50 hover:border-none'
                        style={{
                            multiselectContainer: {
                                width: '100%',
                                maxWidth: '90%',
                                minWidth: '200px',
                            },
                            optionContainer: {
                                minWidth: responsiveWidth(),
                            },
                            inputField: {
                                width: responsiveWidth(),
                                minWidth: '200px',
                                border: '0 !important',
                                // This line disable the blue border
                                boxShadow: '0 !important',
                                '&:focus': {
                                    boxShadow: '0 !important',
                                    border: '0 !important'
                                }
                            },
                            searchBox: {
                                width: '100%',
                                minWidth: '200px',

                            },
                            option: {
                                whiteSpace: 'nowrap',
                                minWidth: '200px'

                            },
                            groupHeading: {
                                fontWeight: 'extraBold',
                                whiteSpace: 'nowrap',
                                minWidth: '200px'

                            }
                        }}
                        hidePlaceholder
                        showArrow
                        isObject={true}
                        // showCheckbox
                        displayValue="resourceName"
                        groupBy="resourceGroupName"
                        onRemove={onRemove}
                        onSelect={onSelect}
                        key={key}
                        options={filterResourceData}
                        selectedValues={selectedResources}
                        placeholder={i18n.t('Selecionar Recurso')}
                    />
                    {/* </div> */}
                </div>
                <div className='h-full w-fit pr-4' >
                    <SelectedProductPanel />
                </div>
                {/*Ver como n~ao enviar parametros se o app_target nao utilizar*/}
                <ModuleAnimationOccupationPage
                    dispatch={dispatch}
                    localApplyOccupationConstraintAnimation={localApplyOccupationConstraintAnimation}
                    localStopOccupationConstraintAnimation={localStopOccupationConstraintAnimation}
                    periodIndexRef={periodIndexRef}
                />
            </div>
            <div className='flex-grow flex-col h-auto w-full mb-16 bg-white border rounded'>
                {/* {resourcesChartsMemoized} */}
                <OccupationChartContainer />
            </div>
        </>
    )
}

export default OccupationPage