import React from "react";
import { deleteTableAsync, getTablesAsync } from 'api/data'
import 'react-confirm-alert/src/react-confirm-alert.css';
import { t } from "@lingui/macro";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button, Switch, TextField } from "@mui/material";
import { TiArrowUnsorted, TiArrowSortedUp, TiArrowSortedDown } from 'react-icons/ti'
import { useNavigate } from "react-router-dom";
import ManageTable from "./components/ManageTable";
import Loader from "components/Loader/NeoLoader";
import { getTableGroupsAsync } from "api/data";
import { confirmAlert } from "react-confirm-alert";
export default function TableEditionPage() {
    const [manageTableHidden, setManageTableHidden] = React.useState(true);
    const [loading, setLoading] = React.useState(true);
    const [textFilter, setTextFilter] = React.useState("");
    const [data, setData] = React.useState<any>([]);
    const [sort, setSort] = React.useState("")
    const [sortDesc, setSortDesc] = React.useState(false)
    const [groups, setGroups ] = React.useState<any>([])
   
    const columns = [
        {
            field: "title",
            align: "left",
            checkBox: false,
            title: t`Título`,
            sort: true
        },
        
        {
            field: "schema",
            align: "left",
            checkBox: false,
            title: `Schema`,
            sort: true
        },
        {
            field: "name",
            align: "left",
            checkBox: false,
            sort: true,
            title: t`Tabela`,
            filter: ''
        },
    ]
    const [currentEditingTable, setCurrentEditingTable] = React.useState<any>({
        schema: "",
        name: "",
        tableEditionGroupId: 1,
        title: "",
        insertData: false,
        deleteData: false,
        id: 0
    });
    React.useEffect(() => {
        getTablesAsync().then(res => {
            setData(res.sort((a, b) => a.tableEditionGroup?.name > b.tableEditionGroup?.name ? 1 : -1))
            getTableGroupsAsync().then(res =>{
                setGroups(res)
                setLoading(false)
            })
        })
    },[])
    const refreshData = async () => {
        setLoading(true);
        getTablesAsync().then(res => {
            setData(res)
            setLoading(false);
        })
    }
    const getRows = () => {
        return data.filter(row => 
                    row.name.toLowerCase().includes(textFilter)
                ||  row.tableEditionGroup?.name?.toLowerCase().includes(textFilter)
                ||  row.title.toLowerCase().includes(textFilter)
                ||  textFilter === ""
            ).sort((a, b) => sortDesc ? 
                a[sort] > b[sort] ? 1 : -1 
            :   a[sort] > b[sort] ? -1 : 1)
    }
    const navigate = useNavigate()
    const deleteData = async (row) => {
        confirmAlert({
            title: ``,
            message: `Deseja deletar a tabela?`,
            buttons: [
                {
                    label: "Sim",
                    onClick: () => {
                        deleteTableAsync(row.id).then(res => refreshData())
                    }
                },
                {
                    label: "Não",
                    onClick: () => { }
                }
            ]
        });
    }
    return (
        <React.Fragment>
            {loading && <Loader/>}
            <ManageTable 
                hidden={manageTableHidden} 
                close={() => { 
                    setManageTableHidden(true) } 
                } 
                reloadData={refreshData}
                tableEdition={currentEditingTable}
                groups={groups}
            />
            <div style={{padding: "10px", display: "flex", flexDirection: "row"}}>
                <Button 
                    variant="contained" style={{maxWidth: "200px"}} 
                    onClick={() => { 
                        setCurrentEditingTable({
                            schema: "",
                            name: "",
                            tableEditionGroupId: 1,
                            title: "",
                            insertData: false,
                            deleteData: false,
                            requiredFilter: false,
                            columnRequiredFilter: "",
                            id: 0
                        }) 
                        setManageTableHidden(false)
                    }}>Cadastrar Tabela</Button>
                <TextField  label={"Procurar"} size="small" style={{width: "300px", marginLeft: "auto"}} onChange={({target: {value}}) => { setTextFilter(value) }}/>
            </div>
            
            <TableContainer >
                <Table stickyHeader aria-label="sticky table">
                    <TableHead >
                        <TableRow>
                            {columns.map((column, index) => 
                                <TableCell 
                                    key={index} 
                                    style={{backgroundColor: "rgb(255, 248, 225)", cursor: "pointer"}} 
                                    onClick={() => {
                                        if(column.sort)
                                        if(sort === column.field && sortDesc) {
                                            setSortDesc(false)
                                        } else if(sort === column.field && !sortDesc) {
                                            setSort("")
                                        } else if(sort !== column.field) {
                                            setSort(column.field)
                                            setSortDesc(true)
                                        }
                                    }} 
                                >
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        <label style={{ fontWeight: "initial", marginRight: "auto", textOverflow: "ellipsis", overflow: "hidden", cursor: "pointer" }} title={column.title}>
                                            {column.title}
                                        </label>
                                        {column.sort &&
                                        <div>
                                            {sort !== column.field && <TiArrowUnsorted />}
                                            {sort === column.field && !sortDesc && <TiArrowSortedUp />}
                                            {sort === column.field && sortDesc && <TiArrowSortedDown />}
                                        </div>}
                                    </div>
                                    
                                </TableCell>
                            )}
                            <TableCell style={{backgroundColor: "rgb(255, 248, 225)"}} >Inserir Dados</TableCell>
                            <TableCell style={{backgroundColor: "rgb(255, 248, 225)"}} >Deletar Dados</TableCell>
                            <TableCell style={{backgroundColor: "rgb(255, 248, 225)"}} />
                            <TableCell style={{backgroundColor: "rgb(255, 248, 225)"}} />
                            <TableCell style={{backgroundColor: "rgb(255, 248, 225)"}} />
                            <TableCell style={{backgroundColor: "rgb(255, 248, 225)"}} />
                            <TableCell style={{backgroundColor: "rgb(255, 248, 225)"}} />     
                            
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getRows().map((row, index) => 
                            <TableRow key={index}>
                                {columns.map((col, index) => 
                                    <TableCell key={index} >
                                        {row[col.field]}
                                    </TableCell>
                                )}
                                <TableCell>
                                    <Switch checked={row.insertData} disabled/>
                                </TableCell>
                                <TableCell>
                                    <Switch checked={row.deleteData} disabled/>
                                </TableCell>
                                <TableCell>
                                    <Button variant="contained" onClick={() => { setCurrentEditingTable(row); setManageTableHidden(false)} }>Editar</Button>
                                </TableCell>
                                <TableCell >
                                    <Button variant="contained" onClick={() => navigate(`data/${row.id}`)}>Dados</Button>
                                </TableCell>
                                <TableCell >
                                    <Button variant="contained" onClick={() => navigate(`columns/${row.id}`)}>Colunas</Button>
                                </TableCell>
                                <TableCell >
                                    <Button variant="contained" onClick={() => navigate(`buttons/${row.id}`)}>Botões</Button>
                                </TableCell>
                                <TableCell>
                                    <Button variant="contained" color="error" onClick={() => deleteData(row)}>Deletar</Button>
                                </TableCell>
                            </TableRow>
                            
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    )
}