import { StockStatusType } from "api/data/enums"
import { ItemAttributesCustomBundle } from "api/data/types"
import { Dictionary } from "utils/types"
import { ResourceAggregator } from "../types"

export interface FilterData {
    text?: string
    searchCriteriasData: SearchCriteriasDictionary<any>
    itemIdsByResourceGroup: Dictionary<number[]>
    itemsIdsByAttributeId: ItemAttributesCustomBundle<number[]>
    itemIdsByStockStatus: StockStatusDictionary<number[]>
    itemIdsByStockStatusByPeriod: StockStatusDictionary<Dictionary<number[]>>
    itemIdsByItemPlannerIds: Dictionary<number[]>
    itemIdsByStockGroupIds: Dictionary<number[]>
    itemIdsByLevel: Dictionary<number[]>
    orderedItemIds: number[]
}

export interface SearchList<T> {
    label: string,
    id: string,
    data?: T
}

export type StockStatusDictionary<TValue> = {
    [Key in StockStatusType]: TValue
}
export type SearchCriteriasDictionary<TValue> = {
    [Key in FilterCriteria]: TValue | undefined
}

export enum FilterCriteria {
    None = 'none',
    ResourceGroup = 'ResourceGroup',
    ItemCode = 'ItemCode',
    Level = 'Level',
    ItemPlanner = 'ItemPlanner',
    ItemStockGroup = 'ItemStockGroup',
    StockStatusTypeWithPeriod = 'StockStatusTypeWithPeriod',
    OccupationPageResourceAndPeriod = 'OccupationPageResourceAndPeriod',
    OccupationPageResourceGroup = 'OccupationPageResourceGroup',
    ItemAttribute1 = 'ItemAttribute1',
    ItemAttribute2 = 'ItemAttribute2',
    ItemAttribute3 = 'ItemAttribute3',
    ItemAttribute4 = 'ItemAttribute4',
    ItemAttribute5 = 'ItemAttribute5',
    ItemAttribute6 = 'ItemAttribute6',
    ItemAttribute7 = 'ItemAttribute7',
    ItemAttribute8 = 'ItemAttribute8',
    ItemAttribute9 = 'ItemAttribute9',
    ItemAttribute10 = 'ItemAttribute10',
}


export interface OccupationPageFilterData {
    chartId: number,
    selectedResourceData: ResourceAggregator
}
export interface OccupationPageFilterDataPeriod extends OccupationPageFilterData {
    chartPeriodIndex: number
}
