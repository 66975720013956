import { LoadingStateColor, LoadingAppComponent, LoadingScenarioComponent } from "./AppNavigationState/types";

export interface AsyncLoadProps {
    abortSignal?: AbortSignal,
    asyncDependencies?: Promise<any>[] | null
}
export interface ProgressLoaderPromiseWaiterAsyncThunkProps extends AsyncLoadProps {
    loadingStateColor: LoadingStateColor,
    loadingAppComponent?: LoadingAppComponent
    loadingScenarioComponent?: LoadingScenarioComponent
}


export enum AsyncStatus {
    EMPTY = 'EMPTY',
    READY = 'READY',
    LOADING = 'LOADING',
    FAILED = 'FAILED'
}

export enum ActionType {
    AppNavigation = 'appNavigation',
    AppScenarioState = 'AppScenarioState',
    AppSchedulerState = 'appSchedulerState',
    AppSumarizedState = 'appSumarizedState',
    AppStockState = 'appStockState'
}

export interface ReduxAsyncData<T> {
    value: T,
    status: AsyncStatus;
}

//Data type for stores
export interface SinglePeriodData {
    monthName: string
    monthNumber: number
    yearNumber: number
    group: number
    rank: number
    startDay: number
    endDay: number
}

/** @deprecated  `PeriodDataOld` needs to be update */
export interface PeriodDataOld {
    monthName: string
    monthNumber: number
    yearNumber: number
    group: number
    rank: number
    periods: {
        monthName: string
        startDay: number
        endDay: number
    }[]
}

export type EnumKeyedDictionary<TKey extends string | number | symbol, TValue> = {
    [Key in TKey]: TValue
}