import { toast } from 'react-toastify';
import ClientErrorNotification from './ClientErrorNotification';
import ServerErrorNotification from './ServerErrorNotification';

export interface ServerErrorMessage {
    detail: string,
    errors: any[],
    instance: string,
    status: number,
    title: string,
    traceId: string,
    type: string,
}

export interface ClientErrorMessage {
    details: string,
    errors: any[],
    // instance: string,
    title: string,
    // traceId: string,
    type: string,
}

export const notifyServerError = (errorData: Partial<ServerErrorMessage>) => {
    return toast(<ServerErrorNotification {...errorData} />, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    })
}

export const notifyClientError = (errorData: Partial<ClientErrorMessage>) => {
    console.warn(errorData)
    // return cogoToast.error(
    //     <ClientErrorNotification {...errorData} />, {
    //     hideAfter: 5,
    //     position: 'top-right'
    // }
    // );
}

/*
100-level (Informational) – server acknowledges a request
200-level (Success) – server completed the request as expected
300-level (Redirection) – client needs to perform further actions to complete the request
400-level (Client error) – client sent an invalid request
500-level (Server error) – server failed to fulfill a valid request due to an error with server
--
400 Bad Request – client sent an invalid request, such as lacking required request body or parameter
401 Unauthorized – client failed to authenticate with the server
403 Forbidden – client authenticated but does not have permission to access the requested resource
404 Not Found – the requested resource does not exist
412 Precondition Failed – one or more conditions in the request header fields evaluated to false
500 Internal Server Error – a generic error occurred on the server
503 Service Unavailable – the requested service is not available
 */