export function validateAndReplaceComma(input: string) {
  const regexPattern = /,/g;
  const replacedValue = String(input).replace(regexPattern, ".");
  const roundedValue = Math.round(parseFloat(replacedValue));

  return roundedValue;
}

export function validateAndTurnIntoDecimal(input: string) {
  const regexPattern = /,/g;
  const replacedValue = String(input).replace(regexPattern, ".");
  const roundedValue = Math.round(parseFloat(replacedValue));

  return roundedValue / 100;
}
