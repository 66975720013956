import { outboundSignalR } from "api/configuration";
import { HttpTransportType, HubConnectionBuilder, LogLevel, signalMiddleware } from "redux-signalr";
import { schedulerCallbacks } from "./reducers";

const outboundConnection = new HubConnectionBuilder()
    .configureLogging(LogLevel.Information)
    .withUrl(`${outboundSignalR}direct`, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
    })
    .withAutomaticReconnect()
    .build()

export const outboundSignalConnector = signalMiddleware({
    callbacks: schedulerCallbacks,
    connection: outboundConnection,
});