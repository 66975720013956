import { createAsyncThunk } from "@reduxjs/toolkit";
import { appStockSlice, setSegmentationData } from "./reducer";
import { ActionType } from "redux/types";
import { getSegmentationAsync } from "api/data/lib/stockPolicySegmentation";

export const loadSegmentationData = createAsyncThunk(
  `${ActionType.AppStockState}/GetSegmentationData`,
  async (props, { dispatch }) => {
    const { data } = await getSegmentationAsync();
    dispatch(setSegmentationData({ data }));
  }
);

export * from "./reducer";

export default appStockSlice.reducer;
