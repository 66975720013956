import { HighlightedStatusType, OldStockStatusColor } from "api/data/enums";

export const HighlightedStatusTypeColor = (highlightedStatusType: HighlightedStatusType) => {
    switch (highlightedStatusType) {
        case HighlightedStatusType.Shortage:
            return OldStockStatusColor.Rupture
        case HighlightedStatusType.Normal:
            return OldStockStatusColor.Normal
        default:
            return OldStockStatusColor.Normal
    }
}