import { RequestSignalParameter } from "api";
import {
    DemandDateRanges,
    PeriodType,
    PlanningStatus,
    ProductionType,
    ScenarioCategory,
    ScenarioCriteria,
    ScenarioPriority,
    ScenarioStatus
} from "api/data/types";
import { UserDataFlat } from "redux/AppScenarioState/dataTypes";
import { getAsync } from "..";
export const route = 'user-data'

export const getDemandDateRangesAsync = async (parameters?: RequestSignalParameter): Promise<DemandDateRanges> => {
    return await getAsync({ url: `/${route}/period-date-ranges`, ...parameters });
}

export const getPeriodTypesAsync = async (parameters?: RequestSignalParameter): Promise<PeriodType[]> => {
    return await getAsync({ url: `/${route}/period-types`, ...parameters });
}

export const getPlanningStatusAsync = async (parameters?: RequestSignalParameter): Promise<PlanningStatus[]> => {
    return await getAsync({ url: `/${route}/planning-status`, ...parameters });
}

export const getProductionTypesAsync = async (parameters?: RequestSignalParameter): Promise<ProductionType[]> => {
    return await getAsync({ url: `/${route}/production-types`, ...parameters });
}

export const getScenarioCategoriesAsync = async (parameters?: RequestSignalParameter): Promise<ScenarioCategory[]> => {
    return await getAsync({ url: `/${route}/scenario-categories`, ...parameters });
}

export const getScenarioCriteriasAsync = async (parameters?: RequestSignalParameter): Promise<ScenarioCriteria[]> => {
    return await getAsync({ url: `/${route}/scenario-criterias`, ...parameters });
}

export const getScenarioPrioritiesAsync = async (parameters?: RequestSignalParameter): Promise<ScenarioPriority[]> => {
    return await getAsync({ url: `/${route}/scenario-priorities`, ...parameters });
}
export const getScenarioStatusesAsync = async (parameters?: RequestSignalParameter): Promise<ScenarioStatus[]> => {
    return await getAsync({ url: `/${route}/scenario-statuses`, ...parameters });
}

export const getUserDataFlatAsync = async (parameters?: RequestSignalParameter): Promise<UserDataFlat> => {
    return await getAsync({ url: `/${route}/flat`, ...parameters });
}