import { Box, Button, Fab, InputLabel, Modal, TextField } from '@mui/material'
import axios from 'axios';
import React from 'react'
import { dataUrl } from 'api/configuration';
import { notifySuccess } from 'utils/notification';
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    minWidth: 300,
    maxHeight: 900,
    overflow: "auto",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function(props) {
    const fileRef = React.useRef<any>(null);
    const [analyticlink, setAnalyticLink] = React.useState<any>({
        id: 0,
        name: "",
        url: "",
        image: "",
        description: "",
        imageName: ""
    });
    function setImage(event) {
        if(event?.target?.files[0]) {
            var file = event.target.files[0]
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                resizedataURL(reader.result, 400, 400).then(image => {
                    setAnalyticLink(prevState => ({...prevState, imageName: file.name, image}))
                })
                
            };
        }
    }
    // Takes a data URI and returns the Data URI corresponding to the resized image at the wanted size.
    function resizedataURL(datas, wantedWidth, wantedHeight){
        return new Promise(async function(resolve,reject){

            // We create an image to receive the Data URI
            var img = document.createElement('img');

            // When the event "onload" is triggered we can resize the image.
            img.onload = function()
            {        
                
                // We create a canvas and get its context.
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext('2d');
                if(!ctx) return;
                // We set the dimensions at the wanted size.
                canvas.width = wantedWidth;
                canvas.height = wantedHeight;

                // We resize the image with the canvas method drawImage();
                ctx.drawImage(img, 0, 0, wantedWidth, wantedHeight);

                var dataURI = canvas.toDataURL();

                // This is the return of the Promise
                resolve(dataURI);
            };

            // We put the Data URI in the image's src attribute
            img.src = datas;

        })
    }// Use it like : var newDataURI = await resizedataURL('yourDataURIHere', 50, 50);
    React.useEffect(() => {
        if(props.currentIdEditing === 0) {
            setAnalyticLink({
                id: 0,
                name: "",
                url: "",
                image: "",
                description: "",
                imageName: ""
            })
        } else {
            axios.get(`analyticlink/${props.currentIdEditing}`, {baseURL: dataUrl}).then(({data}) => {
                if(data.type === "success") {
                    setAnalyticLink(data.analyticLink)
                }
            })
        }
    }, [props.currentIdEditing])
    async function handleSave() {
        var postData = {...analyticlink};
        if(postData.url && !postData.url.match(/^https?:\/\//i))
            postData.url = 'http://' + postData.url;
        setAnalyticLink(postData);
        var { data } = props.currentIdEditing === 0 
        ?
            await axios.post('analyticlink', postData, {baseURL: dataUrl})
        :
            await axios.put('analyticlink', postData, {baseURL: dataUrl})
        if(data.type === "success") {
            notifySuccess({message: data.message})
            props.close();
        }
    }
    return (
        <React.Fragment>
            <Modal
                open={props.open}
                onClose={() => props.close()}
            >
                <Box sx={style}>
                    <h3 style={{fontWeight: "bold", textAlign: "center", fontSize: "16px", padding: "10px"}}>Adicionar Relatório</h3>
                    <InputLabel style={{paddingTop: "10px"}}>Nome</InputLabel>
                    <TextField variant="standard" value={analyticlink.name} fullWidth onChange={({target: { value }}) => {setAnalyticLink(prevState => ({...prevState, name :value}))}} ></TextField>
                    <InputLabel style={{paddingTop: "10px"}}>Descrição</InputLabel>
                    <TextField type="text" rows={3} multiline variant="standard" value={analyticlink.description} fullWidth onChange={({target: { value }}) => {setAnalyticLink(prevState => ({...prevState, description :value}))}}></TextField>
                    <InputLabel style={{paddingTop: "10px"}}>URL</InputLabel>
                    <TextField variant="standard" value={analyticlink.url} fullWidth onChange={({target: { value }}) => { setAnalyticLink(prevState => ({...prevState, url :value}))}}></TextField>
                    <input accept='image/*' type="file" hidden ref={fileRef} onChange={setImage}/>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <Button style={{marginTop: "20px"}} color="secondary" variant='contained' onClick={() => fileRef.current.click()}>Upload Imagem</Button>
                        <label>{analyticlink.imageName}</label>
                    </div>
                    <img style={{maxHeight: "400px"}} src={analyticlink.image} alt={analyticlink.imageName}></img>
                    <div style={{display: "flex", justifyContent: "space-around", padding: "20px", marginTop :"20px"}}>
                        <Button style={{background: "red", color: "white", borderRadius: "25px"}} variant="contained" onClick={props.close}>Cancelar</Button>
                        <Button style={{background: "#EAB308", color: "white", borderRadius: "25px"}} variant="contained" onClick={handleSave}>Salvar</Button>
                    </div>
                </Box>
            </Modal>
        </React.Fragment>
    )
}