import { useAppSelector } from "redux/hooks";
import { StockInventorySummaryProps } from "../types";
import { v4 as uuidv4 } from "uuid";
import { sortArrayByTypeKeyPriority } from "../utils/sortArrayByKeyWithComparator";
import {
    validateAndReturnAbreviation,
    validateAndReturnDivisionNumber,
} from "../utils/formatValuesMillions";
import { ToolTipComponent } from "./TooltipComponent";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";

export function InventorySummaryCards({ items }: StockInventorySummaryProps) {
    const { stockPolicyAxes } = useAppSelector((state) => state.appStockState);

    const { i18n } = useLingui()

    let filteredValues = items.map(item => {
        return item.items.map(c => {
            return c.map((z, index) => {
                return {
                    id: index,
                    classificationComplexityId: z.classificationComplexityId,
                    classificationUncertaintyId: z.classificationUncertaintyId,
                    classificationVolumeId: z.classificationVolumeId,
                    classificationComplexity: z.classificationComplexity,
                    classificationUncertainty: z.classificationUncertainty,
                    classificationVolume: z.classificationVolume,
                }
            })

        })
    })
    return (
        <>
            {sortArrayByTypeKeyPriority(items).map((item, index) => {
                return (
                    <div className="flex flex-col" key={uuidv4()}>
                        {index === 0 ? (
                            <div className="w-full justify-center text-center">
                                <p className="font-semibold font-md p-2 underline text-gray-800">
                                    {stockPolicyAxes.xAxis()}
                                </p>
                            </div>
                        ) : null}
                        {index === 0 ? (
                            <div className="flex flex-row w-full p-2  justify-center">
                                <div className="flex items-center w-10 justify-center text-center"></div>

                                {item.xAxisType.map((column) => {
                                    for (let i = 0; i <= filteredValues.length; i++) {
                                        if (filteredValues[i] === undefined) {
                                            return
                                        }
                                        if (filteredValues[i].length === 0) {
                                            return
                                        }
                                        if (filteredValues[i][0].length === 0) {
                                            return
                                        }
                                        for (let j = 0; j < filteredValues[i][0].length; j++) {
                                            if (filteredValues[i][0][j].classificationComplexity === column.name || filteredValues[i][0][j].classificationUncertainty === column.name || filteredValues[i][0][j].classificationVolume === column.name) {
                                                return (
                                                    <div
                                                        className="flex flex-1 justify-center items-center"
                                                        key={uuidv4()}
                                                    >
                                                        <p className="font-semibold w-full flex-1 text-center">
                                                            {column.name}
                                                        </p>
                                                    </div>
                                                );
                                            }
                                        }
                                    }
                                })}
                            </div>
                        ) : null}

                        <div>
                            <div className="flex w-full  justify-around gap-2" key={uuidv4()}>
                                <div
                                    className="flex items-center w-10 justify-center text-center"
                                    style={{
                                        transform: "rotate(-90deg)",
                                        WebkitTransformOrigin: "52%  50%",
                                    }}
                                >
                                    <p className="font-semibold text-sm whitespace-nowrap">
                                        {item.type.name}
                                    </p>
                                </div>
                                {item.items.map((cards, index) => {
                                    return (
                                        <div
                                            className="flex p-2 rounded-md mt-2 gap-2 flex-1 text-sm justify-around items-center text-center  "
                                            key={uuidv4()}
                                        >
                                            {cards.map((card) => {
                                                return (
                                                    <div
                                                        className="flex flex-1 flex-col text-sm border border-gray-400 p-2 gap-x-4  gap-y-4 rounded-md"
                                                        style={{
                                                            boxShadow:
                                                                "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                                                            maxWidth: "200px",
                                                            backgroundColor: `${card.color}`,
                                                        }}
                                                        key={uuidv4()}
                                                    >
                                                        <div className="flex gap-1 flex-1 justify-between  items-center h-4">
                                                            <label className=" text-sm">
                                                                <Trans>Qtd. Itens</Trans>
                                                            </label>
                                                            <p className="font-bold">
                                                                {isNaN(card.itensQuantity.toFixed(0))
                                                                    ? "0"
                                                                    : card.itensQuantity.toFixed(0)}
                                                            </p>
                                                        </div>

                                                        <ToolTipComponent
                                                            color={card.color}
                                                            type="Days"
                                                            value={
                                                                (card.safetyStockDays +
                                                                    card.cycleStockDays) as number
                                                            }
                                                            label={i18n.t(`Est. Total (Dias)`)}
                                                            tooltipText={i18n.t(`Est. Total = Est. Ciclo`) + ' ' + card.cycleStockDays.toFixed(
                                                                1
                                                            ) + ' + ' + i18n.t(`Est.Seg`) + ' ' + card.safetyStockDays.toFixed(
                                                                1
                                                            )}
                                                        />

                                                        <ToolTipComponent
                                                            type="Value"
                                                            color={card.color}
                                                            value={
                                                                (card.safetyStockValue +
                                                                    card.cycleStockValue) as number
                                                            }
                                                            label={i18n.t(`Est. Total (Valor)`)}
                                                            tooltipText={i18n.t(`Est. Total = Est. Ciclo`) + ' ' + (
                                                                card.cycleStockValue /
                                                                validateAndReturnDivisionNumber(
                                                                    card.cycleStockValue
                                                                )
                                                            ).toFixed(2) +
                                                                validateAndReturnAbreviation(
                                                                    card.safetyStockValue
                                                                ) + ' + '
                                                                + i18n.t(`Est.Seg`) + ' ' + (
                                                                    card.safetyStockValue /
                                                                    validateAndReturnDivisionNumber(
                                                                        card.safetyStockValue
                                                                    )
                                                                ).toFixed(2) +
                                                                validateAndReturnAbreviation(
                                                                    card.safetyStockValue
                                                                )
                                                            }
                                                        />

                                                        <div className="flex gap-2 justify-between items-center h-4">
                                                            <label className="text-sm">
                                                                <Trans>Nível Serviço</Trans>
                                                            </label>
                                                            <p className="font-bold">
                                                                {isNaN(card.serviceLevelPerc)
                                                                    ? "0"
                                                                    : card.serviceLevelPerc.toFixed(0) + " %"}
                                                            </p>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                );
            })}
        </>
    );
}
