import { ModalType } from 'redux/AppNavigationState/types';
import { useAppSelector } from 'redux/hooks';
import ScenarioCreateModal from './ScenarioCreateModal';
import ScriptButtonCreateModal from './ScriptButtonCreateModal';
import ScriptCreateModal from './ScriptCreateModal';
import TaskCreateModal from './TaskCreateModal';
import TaskEditModal from './TaskEditModal';
import QuestionModal from './QuestionModal';
import MultiDataModal from './MultiDataModal';

function handleModalRender(modalType: ModalType, rootToRemove?: HTMLElement, data?: any): JSX.Element {
    switch (modalType) {
        case ModalType.MultiData:
            return <MultiDataModal rootToRemove={rootToRemove} {...data} />
        case ModalType.ScriptButtonCreateModal:
            return <ScriptButtonCreateModal />
        case ModalType.TaskCreateModal:
            return <TaskCreateModal />
        case ModalType.ScriptCreateModal:
            return <ScriptCreateModal />
        case ModalType.TaskEditModal:
            return <TaskEditModal />
        case ModalType.ScenarioCreateModal:
            return <ScenarioCreateModal />
        case ModalType.QuestionModal:
            return <QuestionModal rootToRemove={rootToRemove} {...data} />
    }
    return <></>
}
export const modalRenderCall = (modal: JSX.Element) => {
    return <>
        {modal}
    </>
}

export function nPlanModalRenderHandler(modalType: ModalType, rootToRemove: HTMLElement, data?: any): JSX.Element {
    return (
        <>
            {
                handleModalRender(modalType, rootToRemove, data)
            }
        </>
    )
}


function ModalRenderHandler(): JSX.Element {
    const modalType = useAppSelector((state) => state.appNavigationState.modalRender.modalType)
    return (
        <>
            {
                handleModalRender(modalType)
            }
        </>
    )
}

export default ModalRenderHandler

