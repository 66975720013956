import { Fragment, useCallback, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { IoOptions } from "react-icons/io5";
import { useAppSelector } from "redux/hooks";
import { useDispatch } from "react-redux";
import {
    setSecondaryStockFilter,
    setStockPolicyAxes,
} from "redux/AppStockState";
import { toast } from "react-toastify";
import { StockTypes } from "pages/StockPolicyPage/types";
import { Trans, t } from "@lingui/macro";
import { stockPolicyAxesTypeToLanguage } from "locales/helper";
import { useLingui } from "@lingui/react";
import { StockPolicyAxesType } from "api/data/enums";



interface FilterFlyoutMenuProps {
    StockTypes: StockTypes;
}

interface AxesProps {
    xAxis: () => string;
    yAxis: () => string;
    filter: () => string;
}

export function FilterFlyoutMenu({ StockTypes }: FilterFlyoutMenuProps) {
    const { stockPolicyAxes, stockFilterOptions } = useAppSelector(
        (state) => state.appStockState
    );
    const { i18n } = useLingui()
    const axesOptions = [
        {
            name: t`Eixo X`,
            options: [
                { id: 1, name: stockPolicyAxesTypeToLanguage(StockPolicyAxesType.Volume) },
                { id: 2, name: stockPolicyAxesTypeToLanguage(StockPolicyAxesType.Complexity) },
                { id: 3, name: stockPolicyAxesTypeToLanguage(StockPolicyAxesType.Uncertainty) },
            ],
        },
        {
            name: t`Eixo Y`,
            options: [
                { id: 1, name: stockPolicyAxesTypeToLanguage(StockPolicyAxesType.Volume) },
                { id: 2, name: stockPolicyAxesTypeToLanguage(StockPolicyAxesType.Complexity) },
                { id: 3, name: stockPolicyAxesTypeToLanguage(StockPolicyAxesType.Uncertainty) },
            ],
        },
        {
            name: t`Filtro`,
            options: [
                { id: 1, name: stockPolicyAxesTypeToLanguage(StockPolicyAxesType.Volume) },
                { id: 2, name: stockPolicyAxesTypeToLanguage(StockPolicyAxesType.Complexity) },
                { id: 3, name: stockPolicyAxesTypeToLanguage(StockPolicyAxesType.Uncertainty) },
            ],
        },
    ];


    const dispatch = useDispatch();

    const [axesObject, setAxesObject] = useState<AxesProps>(stockPolicyAxes);



    function handleAxes(option: () => string, axes: string) {
        switch (axes) {
            case i18n.t(`Eixo X`):
                setAxesObject({ ...axesObject, xAxis: option });
                break;
            case i18n.t(`Eixo Y`):
                setAxesObject({ ...axesObject, yAxis: option });
                break;
            case i18n.t(`Filtro`):
                setAxesObject({ ...axesObject, filter: option });
                break;
        }
    }

    // TODO: AVISAR USUARIO QUE NÃO PODE E PQ
    // TODO: FINALIZAR FUNÇÃO QUE ENVIA A INFORMAÇÃO DOS EIXOS
    const handleApplyFilters = useCallback(() => {
        if (
            axesObject.filter === axesObject.xAxis ||
            axesObject.filter === axesObject.yAxis ||
            axesObject.xAxis === axesObject.filter ||
            axesObject.xAxis === axesObject.yAxis ||
            axesObject.yAxis === axesObject.xAxis ||
            axesObject.yAxis === axesObject.filter ||
            axesObject.filter() === "null" ||
            axesObject.xAxis() === "null" ||
            axesObject.yAxis() === "null"
        ) {
            toast.warn(i18n.t(`Os eixos não podem ter o mesmo valor, ou serem nulos!`), {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return;
        } else {
            dispatch(
                setStockPolicyAxes({
                    filter: axesObject.filter,
                    xAxis: axesObject.xAxis,
                    yAxis: axesObject.yAxis,
                    types: StockTypes,
                })
            );
            toast.success(i18n.t(`Os eixos foram alterados com sucesso`), {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }, [
        StockTypes,
        axesObject.filter,
        axesObject.xAxis,
        axesObject.yAxis,
        i18n,
    ]);


    useEffect(() => {
        dispatch(
            setSecondaryStockFilter({
                secondaryFilterName: i18n.t(`Todos`),
                id: 0,
                code: i18n.t(`Todos`),
            })
        );
    }, [stockFilterOptions, i18n]);

    return (
        <Popover className="relative  w-full flex justify-end z-60">
            <Popover.Button className=" flex items-center gap-x-1 text-sm font-semibold leading-6 ">
                <span>
                    <IoOptions size={25} />
                </span>
            </Popover.Button>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
            >
                <Popover.Panel className="absolute left-1/2 z-60 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
                    <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                        <div className="p-4 w-full">
                            {axesOptions.map((item) => (
                                <div
                                    key={item.name}
                                    className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50"
                                >
                                    <div className="flex w-full items-center justify-center">
                                        <p className="font-semibold text-gray-900 w-20 ">
                                            {item.name}
                                        </p>
                                        <select
                                            className="w-full rounded-md"
                                            defaultValue={"null"}
                                            onChange={(e) => handleAxes(() => e.target.value, item.name)}
                                        >
                                            <option
                                                value={item.options[0].name}
                                                key={item.options[0].id}
                                            >
                                                {item.options[0].name}
                                            </option>
                                            <option
                                                value={item.options[1].name}
                                                key={item.options[1].id}
                                            >
                                                {item.options[1].name}
                                            </option>
                                            <option
                                                value={item.options[2].name}
                                                key={item.options[2].id}
                                            >
                                                {item.options[2].name}
                                            </option>
                                            <option value="null" hidden>
                                                <Trans>Selecionar Opção</Trans>
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                            {/* REVISAR O BOTÃO SE VAI FECHAR OU NÃO */}
                            <button
                                className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100"
                                onClick={() => handleApplyFilters()}
                            >
                                <Trans>Aplicar Filtros</Trans>

                            </button>

                            <Popover.Button className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100">
                                <Trans>Fechar Janela</Trans>
                            </Popover.Button>
                        </div>
                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>
    );
}
