import { ServerErrorMessage } from './helper'

function ServerErrorNotification(props: Partial<ServerErrorMessage>) {
    const { detail, instance, title, status } = props

    return (
        <div>
            <b>{status} - {title}</b>
            <div>{detail}</div>
            <div>at - {instance}</div>
        </div>
    )
}

export default ServerErrorNotification
