// @ts-ignore
import { loadItemAttributeBundleModule, selectItemAttributeToGroupModule, selectItemAttributeIdModule } from './module-APP_TARGET'

import { ItemAttributesBundle, ItemAttributesBundleData, ItemAttributesCustomBundle } from 'api/data/types'
import { ItemData } from '../dataTypes'
import { AppScenarioState } from '../types'
import { Dictionary } from 'utils/types'
import { createDictionary } from 'redux/helpers'

// interface ITest {
//     handleLoadItemAttributeBundleModule: (state: AppScenarioState, payload: ItemAttributesBundleData) => void
//     handleSelectItemAttributeToGroupModule: (productIdByAttributes: ItemAttributesCustomBundle<number[]>) => Dictionary<number[]>
//     handleSelectItemAttributeIdModule: (itemById: ItemData) => number
// }

export const handleLoadItemAttributeBundleModule = (state: AppScenarioState, payload: ItemAttributesBundleData): void => {
    return loadItemAttributeBundleModule(state, payload)
}

export const handleSelectItemAttributeToGroupModule = (productIdByAttributes: ItemAttributesCustomBundle<number[]>, itemIds: number[]): Dictionary<number[]> => {
    let itemAttributeToGroup: Dictionary<number[]> = selectItemAttributeToGroupModule(productIdByAttributes)
    if (Object.keys(itemAttributeToGroup).length === 0) {
        console.error(`selectItemAttributeToGroupModule does not have any keys to group setupTime, all itens will be considered from the same ItemAttribute`)
        return {
            '0': itemIds
        }
    }
    return selectItemAttributeToGroupModule(productIdByAttributes);
}

export const handleSelectItemAttributeIdModule = (itemById: ItemData): number => {
    return selectItemAttributeIdModule(itemById);
}

export const initializeAttributes = (payload: ItemAttributesBundleData): ItemAttributesBundle => {
    const productAttributesData: ItemAttributesBundleData = payload;
    const productAttributes: ItemAttributesBundle = {
        itemAttribute1: createDictionary(productAttributesData.itemAttribute1, x => x.id),
        itemAttribute2: createDictionary(productAttributesData.itemAttribute2, x => x.id),
        itemAttribute3: createDictionary(productAttributesData.itemAttribute3, x => x.id),
        itemAttribute4: createDictionary(productAttributesData.itemAttribute4, x => x.id),
        itemAttribute5: createDictionary(productAttributesData.itemAttribute5, x => x.id),
        itemAttribute6: createDictionary(productAttributesData.itemAttribute6, x => x.id),
        itemAttribute7: createDictionary(productAttributesData.itemAttribute7, x => x.id),
        itemAttribute8: createDictionary(productAttributesData.itemAttribute8, x => x.id),
        itemAttribute9: createDictionary(productAttributesData.itemAttribute9, x => x.id),
        itemAttribute10: createDictionary(productAttributesData.itemAttribute10, x => x.id)
    }
    return productAttributes;
}