import { RequestParamsParameters, RequestSignalParameter } from 'api';
import { PlanningGridResource } from 'api/data/types';
import { getAsync } from '..';

const route = 'planning-grid-resources'
interface UrlQuery {
    scenarioId?: number
}

export const getPlanningGridResourceByIdAsync = async (planningGridResourceId: number, parameters?: RequestSignalParameter): Promise<PlanningGridResource> => {
    return await getAsync({ url: `/${route}/${planningGridResourceId}`, ...parameters });
}
export const getPlanningGridResourceDetailsByIdAsync = async (planningGridResourceId: number, parameters?: RequestSignalParameter): Promise<PlanningGridResource> => {
    return await getAsync({ url: `/${route}/${planningGridResourceId}/details`, ...parameters });
}
export const getPlanningGridResourcesByScenarioIdAsync = async (parameters?: RequestParamsParameters<UrlQuery, null>): Promise<PlanningGridResource[]> => {
    return await getAsync({ url: `/${route}`, ...parameters });
}
export const getPlanningGridResourcesDetailsByScenarioIdAsync = async (parameters?: RequestParamsParameters<UrlQuery, null>): Promise<PlanningGridResource[]> => {
    return await getAsync({ url: `/${route}/details`, ...parameters });
}