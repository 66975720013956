import { forwardRef, useCallback, useMemo } from 'react'
import { GridOnScrollProps, VariableSizeGrid } from 'react-window'
import AutoSizer, { Size } from "react-virtualized-auto-sizer";
import PlanningGridRow from '../components/PlanningGridRow';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectFilteredMontlyPlanningGrids, selectFilteredWeeklyPlanningGrids, lockPlanningGridFromStart, unlockPlanningGridFromIndexToEnd, setPlanningGridLock, applyDownMultipleReorder, applyUpMultipleReorder, selectItemsLength, highlightMaterialFlowResources, highlightMaterialFlowResourcesClear } from 'redux/AppScenarioState';
import { Dictionary } from 'utils/types';
import { memoize } from 'lodash';
import { PlanningGridData } from 'redux/AppScenarioState/dataTypes';

const createPlanningGridExpandedMemoizedData = memoize((
    planningGrids: Dictionary<PlanningGridData[]>,
    handleOnLockFromStart: (productId: number, productPeriodIndex: number) => void,
    handleOnUnlockFromIndexToEnd: (productId: number, productPeriodIndex: number) => void,
    handleOnSingleLockClick: (productId: number, productPeriodIndex: number) => void,
    handleOnDownClick: (productId: number, productPeriodIndex: number) => void,
    handleOnUpClick: (productId: number, productPeriodIndex: number) => void,
    handleHighlightMaterialFlowResources: (itemId: number, productPeriodIndex: number) => void,
    handleHighlightMaterialFlowResourcesClear: (itemId: number, productPeriodIndex: number) => void
) => ({
    planningGrids,
    handleOnLockFromStart,
    handleOnUnlockFromIndexToEnd,
    handleOnSingleLockClick,
    handleOnDownClick,
    handleOnUpClick,
    handleHighlightMaterialFlowResources,
    handleHighlightMaterialFlowResourcesClear
}));

const createPlanningGridCollapsedMemoizedData = memoize((
    planningGrids: Dictionary<PlanningGridData[]>,
    handleOnLockFromStart: (productId: number, productPeriodIndex: number) => void,
    handleOnUnlockFromIndexToEnd: (productId: number, productPeriodIndex: number) => void,
    handleOnSingleLockClick: (productId: number, productPeriodIndex: number) => void,
    handleOnDownClick: (productId: number, productPeriodIndex: number) => void,
    handleOnUpClick: (productId: number, productPeriodIndex: number) => void,
    handleHighlightMaterialFlowResources: (itemId: number, productPeriodIndex: number) => void,
    handleHighlightMaterialFlowResourcesClear: (itemId: number, productPeriodIndex: number) => void
) => ({
    planningGrids,
    handleOnLockFromStart,
    handleOnUnlockFromIndexToEnd,
    handleOnSingleLockClick,
    handleOnDownClick,
    handleOnUpClick,
    handleHighlightMaterialFlowResources,
    handleHighlightMaterialFlowResourcesClear
}));

interface Props {
    columnCount: number;
    columnWidth: number;
    onScroll?: ((props: GridOnScrollProps) => any)
    calculateItemSize: (index: number) => number
}
const PlanningGridRowContainer = forwardRef((props: Props, ref: any) => {
    const dispatch = useAppDispatch();
    const { columnCount, columnWidth, onScroll, calculateItemSize } = props
    const itemsLength = useAppSelector(selectItemsLength);

    const monthlyPlanningGrids = useAppSelector(selectFilteredMontlyPlanningGrids);
    const weeklyPlanningGrids = useAppSelector(selectFilteredWeeklyPlanningGrids);
    const isExpandedPeriods = useAppSelector(state => state.appScenarioState.isExpandedPeriods);

    //PlanningGridRow methods
    const handleOnLockFromStart = useCallback((productId: number, productPeriodIndex: number) => {
        dispatch(lockPlanningGridFromStart({ itemId: productId, productPeriodIndex }))
    }, [])

    const handleOnUnlockFromIndexToEnd = useCallback((productId: number, productPeriodIndex: number) => {
        dispatch(unlockPlanningGridFromIndexToEnd({ itemId: productId, productPeriodIndex }))
    }, [])

    const handleOnSingleLockClick = useCallback((productId: number, productPeriodIndex: number) => {
        dispatch(setPlanningGridLock({ itemId: productId, productPeriodIndex }))
    }, [])

    const handleOnDownClick = useCallback((productId: number, itemPeriodIndex: number) => {
        dispatch(applyDownMultipleReorder({ itemId: productId, itemPeriodIndex }))
    }, [])

    const handleOnUpClick = useCallback((productId: number, productPeriodIndex: number) => {
        dispatch(applyUpMultipleReorder({ itemId: productId, itemPeriodIndex: productPeriodIndex }))
    }, [])

    const handleHighlightMaterialFlowResources = useCallback((itemId: number, productPeriodIndex: number) => {
        dispatch(highlightMaterialFlowResources({ itemId, productPeriodIndex }))
    }, [])

    const handleHighlightMaterialFlowResourcesClear = useCallback((itemId: number, productPeriodIndex: number) => {
        dispatch(highlightMaterialFlowResourcesClear({ itemId, productPeriodIndex }))
    }, [])

    const planningGridExpandedDataRowMemoized = useMemo(() => createPlanningGridExpandedMemoizedData(
        weeklyPlanningGrids,
        handleOnLockFromStart,
        handleOnUnlockFromIndexToEnd,
        handleOnSingleLockClick,
        handleOnDownClick,
        handleOnUpClick,
        handleHighlightMaterialFlowResources,
        handleHighlightMaterialFlowResourcesClear
    ), [weeklyPlanningGrids])

    const planningGridCollapsedDataRowMemoized = useMemo(() => createPlanningGridCollapsedMemoizedData(
        monthlyPlanningGrids,
        handleOnLockFromStart,
        handleOnUnlockFromIndexToEnd,
        handleOnSingleLockClick,
        handleOnDownClick,
        handleOnUpClick,
        handleHighlightMaterialFlowResources,
        handleHighlightMaterialFlowResourcesClear
    ), [monthlyPlanningGrids]);

    return (
        <AutoSizer>
            {(size: Size) => (
                <VariableSizeGrid
                    // className="no-scrollbars"
                    columnCount={columnCount}
                    columnWidth={() => columnWidth}
                    height={size.height}
                    width={size.width}
                    rowCount={itemsLength}
                    rowHeight={calculateItemSize}
                    onScroll={onScroll}
                    ref={ref}
                    itemData={isExpandedPeriods ? planningGridExpandedDataRowMemoized : planningGridCollapsedDataRowMemoized}
                >
                    {PlanningGridRow}
                </VariableSizeGrid>
            )}
        </AutoSizer>
    )
})

export default PlanningGridRowContainer