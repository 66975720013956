import React from 'react'
import { BsFillArrowDownCircleFill, BsFillArrowUpCircleFill } from 'react-icons/bs'
import { PlanningGridData } from 'redux/AppScenarioState/dataTypes'
import { getStockStatusColor } from 'utils/chartHelper'
import { HighlightedStatusTypeColor } from './helper'

interface Props {
    planningGridData: PlanningGridData
}

function MaterialFlowPlanningGridRow(props: Props) {
    const { planningGridData } = props

    return (
        <div className={`flex flex-row select-none h-60 w-66 text-sm border-t-2 `}>
            <div className={`flex flex-col w-full h-full items-center justify-evenly`}>
                <p>{planningGridData.demand?.toLocaleString()}</p>
                <p>{planningGridData.orders?.toLocaleString()}</p>
                {
                    planningGridData.isMpsHighlighted ?
                        <p
                            className={'p-1 border-solid border-4 rounded-md bg-white'}
                            style={{ borderColor: `rgb(${HighlightedStatusTypeColor(planningGridData.mpsHighlightedStatusType)})` }}
                        >{planningGridData.mps?.toLocaleString()}</p>
                        :
                        <p>{planningGridData.mps?.toLocaleString()}</p>
                }

                <p>{planningGridData.targetStock?.toLocaleString()}</p>
                <p>{planningGridData.openingStock?.toLocaleString()}</p>
                <p>{planningGridData.closingStock?.toLocaleString()}</p>
                <p>{planningGridData.totalDaysOfCover?.toLocaleString()}</p>
                <p className='rounded-md w-auto p-1 bg-green-400' style={{ background: `${getStockStatusColor(planningGridData.stockStatus)}` }} >{planningGridData.stockStatus}</p>
            </div>
        </div>
    )
}

export default MaterialFlowPlanningGridRow
