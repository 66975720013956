import { RequestParamsParameters, RequestSignalParameter } from "api";
import { ScenarioSummarized, ScenarioSummarizedDetails } from "api/data/types";
import { getAsync } from "..";
interface UrlQuery {
    scenarioId?: number
}
export const route = 'scenario-summarized'

// ScenarioSummarizedController

export const getAvailableScenarioIdsForScenarioSummarizedAsync = async (parameters?: RequestSignalParameter): Promise<number[]> => {
    return await getAsync({ url: `/${route}/available`, ...parameters });
}
export const getScenarioSummarizedByIdAsync = async (scenarioSummarizedId: number, parameters?: RequestSignalParameter): Promise<ScenarioSummarized> => {
    return await getAsync({ url: `/${route}/${scenarioSummarizedId}`, ...parameters });
}
export const getScenarioSummarizedDetailsByIdAsync = async (scenarioSummarizedId: number, parameters?: RequestSignalParameter): Promise<ScenarioSummarizedDetails> => {
    return await getAsync({ url: `/${route}/${scenarioSummarizedId}/details`, ...parameters });
}
export const getScenarioSummarizedByScenarioIdAsync = async (parameters?: RequestParamsParameters<UrlQuery, null>): Promise<ScenarioSummarized[]> => {
    return await getAsync({ url: `/${route}`, ...parameters });
}
export const getScenarioSummarizedDetailsByScenarioIdAsync = async (parameters?: RequestParamsParameters<UrlQuery, null>): Promise<ScenarioSummarizedDetails[]> => {
    return await getAsync({ url: `/${route}/details`, ...parameters });
}