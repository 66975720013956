import { StockStatusType, StockStatusColor } from "api/data/enums"

export const getStockStatusColor = (statusType: StockStatusType): string => {
    switch (statusType) {
        case StockStatusType.Excess:
            return StockStatusColor.Excess
        case StockStatusType.High:
            return StockStatusColor.High
        case StockStatusType.MTO:
            return StockStatusColor.MTO
        case StockStatusType.Locked:
            return StockStatusColor.Locked
        case StockStatusType.Low:
            return StockStatusColor.Low
        case StockStatusType.Normal:
            return StockStatusColor.Normal
        case StockStatusType.Risk:
            return StockStatusColor.Risk
        case StockStatusType.Shortage:
            return StockStatusColor.Shortage
        case StockStatusType.Stockout:
        default:
            return StockStatusColor.Stockout
    }
}