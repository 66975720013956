import { deleteAsync, postAsync } from "..";

export async function loginAsync(username: string, password: string): Promise<any> {
    let response = await postAsync<any>({
        url: '/session', body: {
            username,
            password,
        }
    });
    return response;
}

export async function logoutAsync() {
    await deleteAsync<any>({ url: '/session'});
    localStorage.clear()
}