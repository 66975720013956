import React from "react";
import { deletePowerBIAsync, getTablesAsync } from 'api/data'
import 'react-confirm-alert/src/react-confirm-alert.css';
import { confirmAlert } from "react-confirm-alert";
import { t } from "@lingui/macro";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button, Switch, TextField } from "@mui/material";
import { TiArrowUnsorted, TiArrowSortedUp, TiArrowSortedDown } from 'react-icons/ti'
import { useNavigate } from "react-router-dom";
import ManageTable from "./components/ManageTable";
import Loader from "components/Loader/NeoLoader";
import { getPowerBIAsync } from "api/data";
export default function TableEditionPage() {
    const [manageTableHidden, setManageTableHidden] = React.useState(true);
    const [loading, setLoading] = React.useState(true);
    const [textFilter, setTextFilter] = React.useState("");
    const [data, setData] = React.useState<any>([]);
    const [sort, setSort] = React.useState("")
    const [sortDesc, setSortDesc] = React.useState(false)
    const columns = [
        {
            field: "title",
            align: "left",
            checkBox: false,
            title: t`Título`,
            sort: true
        },
        {
            field: "description",
            align: "left",
            checkBox: false,
            title: t`Descrição`,
            sort: true,
            filter: ''
        },
        {
            field: "link",
            align: "left",
            checkBox: false,
            title: `Link`,
            sort: true
        },
        {
            field: "order",
            align: "left",
            checkBox: false,
            sort: true,
            title: t`Ordem`,
            filter: ''
        },
        {
            field: "active",
            align: "center",
            checkBox: true,
            sort: true,
            title: t`Ativo`,
            filter: ''
        },
    ]
    const [currentEditingPowerBI, setCurrentEditingPowerBI] = React.useState<any>({
        title: "",
        description: "",
        link: "",
        order: 1,
        active: true,
        id: 0
    });
    React.useEffect(() => {
        getPowerBIAsync().then(res => {
            setData(res.sort((a, b) => a.order > b.order ? 1 : -1))
            setLoading(false)
        })
    }, [])
    const refreshData = async () => {
        setLoading(true);
        getPowerBIAsync().then(res => {
            setData(res.sort((a, b) => a.order > b.order ? 1 : -1))
            setLoading(false)
        })
    }
    const deleteData = async (row) => {
        confirmAlert({
            title: ``,
            message: `Deseja deletar o relatório?`,
            buttons: [
                {
                    label: "Sim",
                    onClick: () => {
                        deletePowerBIAsync(row.id).then(res => refreshData())
                    }
                },
                {
                    label: "Não",
                    onClick: () => { }
                }
            ]
        });
    }
    const getRows = () => {
        return data
            .filter(row =>
                Object.values(row)
                    .join('')
                    .toLowerCase()
                    .includes(textFilter.toLowerCase()))
            .sort((a, b) => sortDesc ?
                a[sort] > b[sort] ? 1 : -1
                : a[sort] > b[sort] ? -1 : 1)
    }
    const navigate = useNavigate()
    return (
        <React.Fragment>
            {loading && <Loader />}
            <ManageTable
                hidden={manageTableHidden}
                close={() => {
                    setManageTableHidden(true)
                }
                }
                powerBiEdition={currentEditingPowerBI}
                refreshData={refreshData}
            />
            <div style={{ padding: "10px", display: "flex", flexDirection: "row" }}>
                <Button
                    variant="contained" style={{ maxWidth: "200px" }}
                    onClick={() => {
                        setCurrentEditingPowerBI({
                            title: "",
                            description: "",
                            link: "",
                            order: 1,
                            active: true,
                            id: 0
                        })
                        setManageTableHidden(false)
                    }}>Cadastrar Novo Relatório</Button>
                <TextField label={"Procurar"} size="small" style={{ width: "300px", marginLeft: "auto" }} onChange={({ target: { value } }) => { setTextFilter(value) }} />
            </div>

            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead >
                        <TableRow>
                            {columns.map((column, index) =>
                                <TableCell
                                    key={index}
                                    style={{ backgroundColor: "rgb(255, 248, 225)", cursor: "pointer" }}
                                    onClick={() => {
                                        if (column.sort)
                                            if (sort === column.field && sortDesc) {
                                                setSortDesc(false)
                                            } else if (sort === column.field && !sortDesc) {
                                                setSort("")
                                            } else if (sort !== column.field) {
                                                setSort(column.field)
                                                setSortDesc(true)
                                            }
                                    }}
                                >
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <label style={{ fontWeight: "initial", marginRight: "auto", textOverflow: "ellipsis", overflow: "hidden", cursor: "pointer" }} title={column.title}>
                                            {column.title}
                                        </label>
                                        {column.sort &&
                                            <div>
                                                {sort !== column.field && <TiArrowUnsorted />}
                                                {sort === column.field && !sortDesc && <TiArrowSortedUp />}
                                                {sort === column.field && sortDesc && <TiArrowSortedDown />}
                                            </div>}
                                    </div>

                                </TableCell>
                            )}
                            <TableCell style={{ backgroundColor: "rgb(255, 248, 225)" }} ></TableCell>
                            <TableCell style={{ backgroundColor: "rgb(255, 248, 225)" }} ></TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getRows().map((row, index) =>
                            <TableRow key={index}>
                                {columns.map((col, index) =>
                                    <TableCell key={index} >
                                        {col.field === "link" ? <a>{row[col.field]}</a> : row[col.field]}
                                    </TableCell>
                                )}
                                <TableCell>
                                    <Button variant="contained" onClick={() => { setCurrentEditingPowerBI(row); setManageTableHidden(false) }}>Editar</Button>
                                </TableCell>
                                <TableCell>
                                    <Button variant="contained" color="error" onClick={() => deleteData(row)}>Deletar</Button>
                                </TableCell>
                            </TableRow>

                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    )
}