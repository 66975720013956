import { RequestParamsParameters, RequestSignalParameter } from 'api';
import { PlanningGridAllocation, PlanningGridAllocationDetails } from 'api/data/types';
import { Dictionary } from 'utils/types';
import { getAsync } from '..';

const route = 'planning-grid-allocations'
interface UrlQuery {
    scenarioId?: number
}

export const getPlanningGridAllocationByIdAsync = async (planningGridId: number, parameters?: RequestSignalParameter): Promise<PlanningGridAllocation> => {
    return await getAsync({ url: `/${route}/${planningGridId}`, ...parameters });
}
export const getPlanningGridAllocationDetailsByIdAsync = async (planningGridId: number, parameters?: RequestSignalParameter): Promise<PlanningGridAllocationDetails> => {
    return await getAsync({ url: `/${route}/${planningGridId}/details`, ...parameters });
}
export const getPlanningGridAllocationsByScenarioIdAsync = async (parameters?: RequestParamsParameters<UrlQuery, null>): Promise<PlanningGridAllocation[]> => {
    return await getAsync({ url: `/${route}`, ...parameters });
}
export const getPlanningGridAllocationsDetailsByScenarioIdAsync = async (parameters?: RequestParamsParameters<UrlQuery, null>): Promise<PlanningGridAllocationDetails[]> => {
    return await getAsync({ url: `/${route}/details`, ...parameters });
}