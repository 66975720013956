import { IconType } from 'react-icons'
import { Link } from 'react-router-dom'
import { GrDocumentMissing } from 'react-icons/gr'
interface Props {
    icon?: IconType;
    pageName: string;
    pageUrl: string;
}

function ConfigurationPanelItem(props: Props) {
    const Icon = props.icon ? props.icon : GrDocumentMissing;

    return (
        <>
            <Link to={`/${props.pageUrl}`}>
                <div className='group flex flex-row cursor-pointer items-center h-10 hover:bg-blue-50'>
                    <Icon className='fill-current  m-4 w-4 h-4 group-hover:text-blue-400' />
                    <div className='text-lg text-gray-400 group-hover:text-blue-400'> {props.pageName}</div>
                </div>
            </Link>
        </>
    )
}

export default ConfigurationPanelItem
