import { Trans } from '@lingui/macro'
import { MenuItem, Menu, ListItemText, ListItemIcon, Typography, Icon } from '@mui/material'
import { getTableButtonsAsync } from 'api/data'
import { forceExecuteTaskByTypeWithBodyAsync } from 'api/scheduler/lib/task'
import React from 'react'
import { BsArrowsAngleContract, BsFileEarmarkText, BsTruck } from 'react-icons/bs'
import { FaExpandArrowsAlt } from 'react-icons/fa'
import { GrAddCircle, GrCommand } from 'react-icons/gr'
import { IoRefresh } from 'react-icons/io5'
import { SiMicrosoftexcel } from 'react-icons/si'
import { TbEdit, TbEditOff } from 'react-icons/tb'
import { TiCog } from 'react-icons/ti'
import { useNavigate, useParams } from 'react-router-dom'
import * as XLSX from "xlsx";

export default function TableEditionDataMenu(props: any) {
    const { id } = useParams();
    const [buttons, setButtons] = React.useState([]);
    const handleReloadData = () => {
        props.loadData();
        props.handleClose()
    }
    const handleEditionMode = () => {
        props.setEditionMode(prev => !prev);
        props.handleClose();
    }
    const handleEditionAllMode = () => {
        if (!props.editionAllMode)
            props.setEditionMode(true)
        props.setEditionAllMode(prev => !prev);
        props.handleClose();
    }
    const handleProcedure = async (button: any) => {
        let procedure = `${button.procedureSchema}.${button.procedureName}`
        await forceExecuteTaskByTypeWithBodyAsync({
            queryParams: {
                taskType: 'ExecProcedure'
            },
            body: {
                order: 0,
                data: {
                    "Stored Procedure": procedure,
                }
            }
        })
    }
    function handleExportToExcel() {
        const data = [props.columns.map((column) => column.header)];

        props.rows.forEach((row) => {
            const rowData: any = [];
            props.columns.forEach((column) => {
                var value = row[column.field];
                if (column.foreignKey && value != null)
                    value = props.foreignFields[column.field]?.filter(r => r.id == value)[0]?.name
                rowData.push(value);
            });
            data.push(rowData);
        });

        const worksheet = XLSX.utils.aoa_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, `${props.title}.xlsx`);
    }
    const navigate = useNavigate();
    
    React.useEffect(() => {
        (async() => {
            var res: any = await getTableButtonsAsync(id);
            setButtons(res)
        })()
    },[id])
    return (
        <React.Fragment>
            <Menu
                open={props.contextMenu !== null}
                onClose={props.handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    props.contextMenu !== null
                        ? { top: props.contextMenu.mouseY, left: props.contextMenu.mouseX }
                        : undefined
                }
            >
                <MenuItem onClick={handleReloadData}>
                    <Icon sx={{ color: 'primary', marginRight: 1 }}><IoRefresh /></Icon>
                    <Typography sx={{ fontWeight: 500 }}><Trans>Atualizar Dados</Trans></Typography>
                </MenuItem>
                <MenuItem onClick={ props.onAddDataClick }>
                    <Icon sx={{ color: 'primary', marginRight: 1 }}><GrAddCircle /></Icon>
                    <Typography sx={{ fontWeight: 500 }}><Trans>Adicionar Dados</Trans></Typography>
                </MenuItem>
                <MenuItem onClick={handleExportToExcel}>
                    <Icon sx={{ color: 'primary', marginRight: 1 }}>< SiMicrosoftexcel color="green" /></Icon>
                    <Typography sx={{ fontWeight: 500 }}><Trans>Exportar para excel</Trans></Typography>
                </MenuItem>
                <MenuItem onClick={handleEditionMode}>
                    <Icon sx={{ color: 'primary', marginRight: 1 }}>{props.editionMode ? <TbEdit color="green" /> : <TbEditOff color="red" />}</Icon>
                    <Typography sx={{ fontWeight: 500 }}><Trans>Edição {props.editionMode ? "On" : "Off"}</Trans></Typography>
                </MenuItem>
                <MenuItem onClick={handleEditionAllMode}>
                    <Icon sx={{ color: 'primary', marginRight: 1 }}>{props.editionAllMode ? <TbEdit color="green" /> : <TbEditOff color="red" />}</Icon>
                    <Typography sx={{ fontWeight: 500 }}><Trans>Edição em massa {props.editionAllMode ? "On" : "Off"}</Trans></Typography>
                </MenuItem>
                <MenuItem onClick={() => navigate(`/tableedition/columns/${id}`)}>
                    <Icon sx={{ color: 'primary', marginRight: 1 }}><TiCog /></Icon>
                    <Typography sx={{ fontWeight: 500 }}><Trans>Configurações</Trans></Typography>
                </MenuItem>
                <MenuItem onClick={props.handleUpdateWidthColumns}>
                    <Icon sx={{ color: 'primary', marginRight: 1 }}><BsArrowsAngleContract /></Icon>
                    <Typography sx={{ fontWeight: 500 }}><Trans>Atualizar Largura</Trans></Typography>
                </MenuItem>
                <MenuItem onClick={props.handleOpenPopover}>
                    <Icon sx={{ color: 'primary', marginRight: 1 }}><BsFileEarmarkText /></Icon>
                    <Typography sx={{ fontWeight: 500 }}><Trans>Visualizar Logs</Trans></Typography>
                </MenuItem>
                <Typography variant='caption' sx={{ml: 2}}>Comandos</Typography>
                {buttons.map((btn: any) => 
                    <MenuItem key={ btn['id'] } onClick={ () => handleProcedure(btn) }>
                        <Icon sx={ { color: 'primary', marginRight: 1 } }><BsTruck /></Icon>
                        <Typography sx={ { fontWeight: 500 } }><Trans>{btn['name']}</Trans></Typography>
                    </MenuItem>    
                )}
            </Menu>
        </React.Fragment>
    )
}