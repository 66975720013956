import { Bar } from "react-chartjs-2";
import { Chart } from "chart.js";
import SelectGraphLabel from "./SelectComponents/SelectGraphLabel";
import ChartDataLabels, { Context } from "chartjs-plugin-datalabels";
import annotationPlugin from "chartjs-plugin-annotation";
import { useAppSelector } from "redux/hooks";
import { Align, Anchor } from "chartjs-plugin-datalabels/types/options";
import { InventoryHealth } from "../types";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
Chart.register(annotationPlugin);
interface GraphComponentProps {
    inventoryHealthFilteredByCompany: InventoryHealth[];
}
export function GraphComponent({
    inventoryHealthFilteredByCompany,
}: GraphComponentProps) {
    const { scenarioStatusOptions, selectedGraphLabel } = useAppSelector(
        (state) => state.appStockState
    );

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        autoPadding: true,
        plugins: {
            annotation: {
                autocolors: false,
                annotations: [
                    // {
                    //   drawTime: "afterDraw",
                    //   id: "a-line-1",
                    //   type: "line",
                    //   mode: "horizontal",
                    //   scaleID: "y-axis-0",
                    //   yMin: 15000000,
                    //   yMax: 15000000,
                    //   borderColor: "red",
                    //   borderWidth: 2,
                    // },
                ],
            },
            legend: {
                display: false,
                label: false,
            },

            plugins: [ChartDataLabels, annotationPlugin],
        },

        scales: {
            xAxis: {
                ticks: {
                    display: false,
                },
                display: true,
                drawTicks: false,
                lineWidth: 0,
                grid: {
                    display: false,
                },
            },
            yAxis: {
                display: false,
            },
        },
    };
    const graphData = {
        labels: scenarioStatusOptions.map(
            (status) => status.estoqueStatusCodigoDesc
        ),
        datasets: [
            {
                type: "bar",
                order: 0,
                label: selectedGraphLabel,
                data: inventoryHealthFilteredByCompany.map((statusType) =>
                    selectedGraphLabel === i18n.t(`Valor`)
                        ? Math.abs(statusType.estoqueValor)
                        : statusType.quantidadeDeItens
                ),
                backgroundColor: inventoryHealthFilteredByCompany.map(
                    (statusType) => statusType.color
                ),
                datalabels: {
                    color: "#000",
                    anchor: "start" as "center" as
                        | "end"
                        | "start"
                        | "center"
                        | Anchor[]
                        | ((context: Context) => Anchor)
                        | undefined,
                    align: "top" as
                        | number
                        | "start"
                        | "center"
                        | "end"
                        | "left"
                        | "right"
                        | "bottom"
                        | "top"
                        | Align[]
                        | ((context: Context) => Align)
                        | undefined,
                    font: {
                        weight: "bold" as
                            | number
                            | "bold"
                            | "normal"
                            | "bolder"
                            | "lighter"
                            | undefined,
                        size: 16,
                    },
                    formatter: function (value) {
                        if (selectedGraphLabel === (i18n.t(`Valor`))) {
                            return value.toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                            });
                        } else {
                            return value;
                        }
                    },
                },
            },
        ],
    };
    return (
        <div
            style={{ maxHeight: "58%", minHeight: "400px" }}
            className=" relative h-full max-w-full w-full justify-center items-center text-right "
        >
            <div className="absolute top-1 left-1 z-10">
                <SelectGraphLabel />
            </div>
            <Bar
                options={options as any}
                data={graphData as any}
                plugins={[ChartDataLabels, annotationPlugin]}
            />
        </div>
    );
}
