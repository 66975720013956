import React, { useState } from 'react';
import { Button, TextField, FormControl, InputLabel, MenuItem, Select, Switch, FormGroup, FormControlLabel, Modal, Dialog, DialogTitle, DialogContent, Box, Typography } from '@mui/material';
import { postTableDataAsync } from 'api/data';
import { useParams } from 'react-router-dom';

export default function AddData({ columns, open, onClose, foreignFields, onSave }) {
    const { id } = useParams();
    const [formData, setFormData] = useState({});

    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };
    const handleToggle = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.checked });
    }
    const handleSubmit = async () => {
        var string = ""
        for(var column of columns) {
            if (column['primaryKey'] == false && !column['field'].startsWith("__seq_")) {
                var value = formData[column['field']]
                if (value == 'null' || value == undefined || value == null)
                    value = null;
                else if (column['viewType'] == 'data' || column['viewType'] == 'datahora')
                    value = value.replaceAll('-', '').replaceAll("T"," ")
                string += `${column['field']},${value};`
            }
        }
        string = string.slice(0, -1);
        var jsonToPost = {
            fieldAndValues: string,
            tableId: id
        }
        const res = await postTableDataAsync(jsonToPost)
        if(res['type'] === 'success') {
            onSave();
            onClose();
        }
            
        
    };
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Adicionar dados</DialogTitle>
            <DialogContent>
                <Box display={'flex'} flexDirection={'column'} gap={1}>
                    { columns.filter(f => f['primaryKey'] == false && !f['field'].startsWith("__seq_")).map(col =>
                        <Box key={col['id']}>
                            <Typography>{ col['header'] }</Typography>   
                            {
                                col['foreignKey'] == true ?
                                    <Select
                                        fullWidth
                                        variant='standard'
                                        value={ formData[col['field']] ?? ""}
                                        onChange={handleChange}
                                        name={col['field']}
                                    >
                                        {foreignFields[col['field']].map((el, index) =>
                                            <MenuItem key={index} value={el['id']}>{el['name']}</MenuItem>
                                        )}
                                    </Select>
                                :
                                col['viewType'] === "checkbox" ?
                                    <Switch checked={ formData[col['field']] ?? false } name={ col['field'] } onChange={ handleToggle } />
                                :
                                col['viewType'] === "data" ?
                                    <TextField
                                        fullWidth
                                        type='date'
                                        variant='standard'
                                        value={formData[col['field']] ?? ""}
                                        onChange={handleChange}
                                        name={col['field']}
                                    />
                                :
                                col['viewType'] === "datahora" ?
                                    <TextField
                                        fullWidth
                                        type='datetime-local'
                                        variant='standard'
                                        value={ formData[col['field']] ?? "" }
                                        onChange={ handleChange }
                                        name={ col['field'] }
                                    />
                                    :
                                    <TextField 
                                        variant='standard' 
                                        value={ formData[col['field']] ?? "" } 
                                        name={col['field']} 
                                        onChange={handleChange}
                                    />   
                            }
                            
                        </Box>
                    )}
                </Box>
                <Box display={'flex'} mt={3} flexDirection={'column'} gap={2}>
                    <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
                        Salvar
                    </Button>
                    <Button type="submit" variant="contained" color="error" onClick={onClose}>
                        Cancelar
                    </Button>
                </Box>
                
            </DialogContent>
        </Dialog>
    );
}
