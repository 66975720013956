import React from 'react'
import { IconType } from 'react-icons'
import { FaChartArea, FaMoneyBillAlt, FaMoneyBillWaveAlt, FaMoneyBillWave, FaChartBar, FaChartLine } from 'react-icons/fa'
import { GiFactory } from 'react-icons/gi'
import { ImStatsBars2 } from 'react-icons/im'
import { IoCaretDownOutline, IoCaretForwardOutline, IoCaretUpOutline, IoCash } from 'react-icons/io5'
import { IoMdBarcode, IoMdCash } from 'react-icons/io'
import { Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
interface Props {
    bgColorClass: string
    leftBorderColorClass: string
    children: string[]
    icons: IconType[]
}

function ComparisonLabelRow(props: Props) {
    const { bgColorClass, leftBorderColorClass, children, icons } = props
    const { i18n } = useLingui();
    return (
        <div className='flex h-auto w-full '>
            <div className='flex-row w-6 h-full border-solid border border-gray-200 divide-y divide-gray-100'>
                {
                    children.map((item, index) => {
                        let Icon = icons[index];
                        return (
                            <div key={index} className={`w-full h-6 ${leftBorderColorClass}`} >
                                <Icon className='w-full h-full p-0.5' />
                            </div>
                        )
                    })
                }
            </div>
            <div className='flex-row w-full border-solid	border border-gray-200 divide-y divide-gray-100'>
                {
                    children.map((item, index) => (<div className={`w-full pl-2 ${bgColorClass}`} key={index}>{item}</div>))
                }
            </div>
        </div>

    )
}
const MemoizedComparisonLabelRow = React.memo(ComparisonLabelRow)


function ComparisonLabel() {
    const { i18n } = useLingui();

    return (
        <div className='shrink-0 h-full  w-72 px-1 space-y-1'>
            <div className='h-auto w-full bg-gray-400 border-solid	border border-gray-200 divide-y divide-gray-100'>
                <Trans >Cenário</Trans>
                <Trans ><p className='bg-gray-300'>{i18n.t(`Período`)}</p></Trans>
            </div>
            <MemoizedComparisonLabelRow leftBorderColorClass='bg-green-500' bgColorClass='bg-green-100'
                icons={[FaChartArea, GiFactory, FaMoneyBillAlt]}
                children={
                    [
                        i18n.t({ id: 'Demanda (UN)', comment: 'Label da ComparisonLabel' }),
                        i18n.t({ id: 'Plano Mestre (UN)', comment: 'Label da ComparisonLabel' }),
                        i18n.t({ id: 'Receita (R$)', comment: 'Label da ComparisonLabel' }),
                    ]
                } />
            <MemoizedComparisonLabelRow leftBorderColorClass='bg-red-500' bgColorClass='bg-red-100'
                icons={[FaMoneyBillWaveAlt, FaMoneyBillWave]}
                children={
                    [
                        i18n.t({ id: 'Custo Fixo (R$)', comment: 'Label da ComparisonLabel' }),
                        i18n.t({ id: 'Custo Variável (R$)', comment: 'Label da ComparisonLabel' }),
                    ]
                } />
            <MemoizedComparisonLabelRow leftBorderColorClass='bg-blue-500' bgColorClass='bg-blue-100'
                icons={[FaChartArea, FaChartBar, FaChartLine]}
                children={
                    [
                        i18n.t({ id: 'Estoque Projetado (Dias)', comment: 'Label da ComparisonLabel' }),
                        i18n.t({ id: 'Estoque Projetado (UN)', comment: 'Label da ComparisonLabel' }),
                        i18n.t({ id: 'Armazenagem (Pallet)', comment: 'Label da ComparisonLabel' }),
                    ]
                } />
            <MemoizedComparisonLabelRow leftBorderColorClass='bg-purple-500' bgColorClass='bg-purple-100'
                icons={[IoCaretDownOutline, IoCaretDownOutline, IoCaretForwardOutline, IoCaretForwardOutline, IoCaretUpOutline, IoCaretDownOutline]}
                children={
                    [
                        i18n.t({ id: 'Estoque em Rutura (UN)', comment: 'Label da ComparisonLabel' }),
                        i18n.t({ id: 'Custo de Ruptura de Estoque (R$)', comment: 'Label da ComparisonLabel' }),
                        i18n.t({ id: 'Estoque em Risco (UN)', comment: 'Label da ComparisonLabel' }),
                        i18n.t({ id: 'Custo de Risco de Estoque (R$)', comment: 'Label da ComparisonLabel' }),
                        i18n.t({ id: 'Estoque em Excesso (UN)', comment: 'Label da ComparisonLabel' }),
                        i18n.t({ id: 'Custo de Estoque em Excesso (R$)', comment: 'Label da ComparisonLabel' }),
                    ]
                } />
            <MemoizedComparisonLabelRow leftBorderColorClass='bg-yellow-500' bgColorClass='bg-yellow-100'
                icons={[ImStatsBars2, IoCash, IoMdBarcode, IoMdCash, IoMdCash]}
                children={
                    [
                        i18n.t({ id: 'Ocupação de Recursos (%)', comment: 'Label da ComparisonLabel' }),
                        i18n.t({ id: 'Custo da Ociosidade (R$)', comment: 'Label da ComparisonLabel' }),
                        i18n.t({ id: 'Custo Total (R$)', comment: 'Label da ComparisonLabel' }),
                        i18n.t({ id: 'Margem Total (R$)', comment: 'Label da ComparisonLabel' }),
                    ]
                } />
        </div>
    )
}

export default ComparisonLabel
