import React from 'react'
import { TiArrowSortedDown, TiArrowSortedUp, TiArrowUnsorted } from 'react-icons/ti'
import TableEditionDataHeaderFilter from './TableEditionDataHeaderFilter';
export default function TableEditionDataHeader(props: any) {
    const [dragging, setDragging ] = React.useState(false)
    const [dragItem, setDragItem] = React.useState<any>(null);
    const [dragOverItem, setDragOverItem] = React.useState<any>(null);
    const [contextMenu, setContextMenu] = React.useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                // Other native context menus might behave different.
                // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        );
    };

    const handleCloseContextMenu = () => {
        setContextMenu(null);
    };
    const dragStart = (e, id) => {
        setDragging(true)
        setDragItem(id);
    };
    const dragEnter = (e, id) => {
        setDragOverItem(id)
    };
    const drop = (e) => {
        if(dragItem !== dragOverItem) {
            let copyColumns = [...props.columns]
            let oldOrder = copyColumns.filter(f => f.id === dragItem)[0].order;
            copyColumns.filter(f => f.id === dragItem)[0].order = copyColumns.filter(f => f.id === dragOverItem)[0].order;
            copyColumns.filter(f => f.id === dragOverItem)[0].order = oldOrder;
            copyColumns = copyColumns.sort((a,b) => a.order > b.order ? 1 : -1);
            props.setColumns(copyColumns)
        }
        setDragItem(null)
        setDragOverItem(null)
        setDragging(false)
    };
    return  (
        <div style={{ height: "80px", top: "0", position: "sticky", background: "#f2f2f2", zIndex: 1,color: "black" }}>
            {/* <ColumnMenu
                handleClose={handleCloseContextMenu}
                contextMenu={contextMenu}
            /> */}
            <div style={{height: "100%", position: "relative", display: "flex"}}>
                {props.columns?.map((col) => {
                    return (
                        !col.hidden &&
                        <React.Fragment key={col.id}>
                            <div 
                                style={{   
                                    position: "absolute", 
                                    left: `${col.init}px`, 
                                    width: `${col.width}px`, 
                                    height: "30px", 
                                    textAlign: "left", 
                                    whiteSpace: "nowrap",
                                    padding: "0 4px",
                                    cursor: dragging ? "move" : "pointer",
                                    paddingTop: "5px"
                                }}
                            >
                                <div 
                                    style={{display: "flex", flexDirection: "row"}}
                                    className={col.id === dragOverItem ? "drag-over" : ""}
                                    onDragStart={(e) => dragStart(e, col.id)}
                                    onDragEnter={(e) => dragEnter(e, col.id)}
                                    onDragEnd={drop}
                                    draggable="true"

                                >
                                    <label style={{ fontWeight: "initial", marginRight: "auto", textOverflow: "ellipsis", overflow: "hidden", cursor: dragging ? "move" : "pointer" }} title={col.header}>
                                        {col.header}
                                    </label>
                                    <div onClick={() => props.handleOrderBy(col)}>
                                        {props.columnOrderBy !== col.id && <TiArrowUnsorted />}
                                        {props.orderBy === "desc" && props.columnOrderBy === col.id && <TiArrowSortedUp />}
                                        {props.orderBy === "asc" && props.columnOrderBy === col.id && <TiArrowSortedDown />}
                                    </div>
                                </div>
                            </div>
                            <TableEditionDataHeaderFilter foreignFields={props.foreignFields} column={col} data={props.data} setFilter={props.setFilter} />
                        </React.Fragment>
                    )
                })}
            </div>
        </div>
    )
}