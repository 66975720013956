import { MutableRefObject, useCallback, useMemo, useState } from 'react'
import { FixedSizeList } from 'react-window';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import ScenarioRow from './ScenarioRow';
import AutoSizer, { Size } from "react-virtualized-auto-sizer";
import { t } from '@lingui/macro';
import { DropdownPropsDictionary } from 'components/Buttons/types';
import { RiDeleteBin2Line, RiVipCrownFill } from 'react-icons/ri';
import { notifyPromise } from 'utils/notification';
import { deleteScenarioByIdAsync, setScenarioToCurrentStatusAsync } from 'api/data';
import { loadScenariosAsync, selectScenarioStatusDataCached, selectScenarios, selectScenariosPeriodTypeNames } from 'redux/AppScenarioState';
import nPlanModalRender from 'components/Modals';
import { forceExecuteTaskByTypeWithBodyAsync } from 'api/scheduler/lib/task';

export enum ScenarioRowDropDownOptions {
    Delete = 'Delete',
    SetToCurrent = 'SetToCurrent',
}

interface Props {
    itemSize: number,
    selectedScenariosRef: MutableRefObject<Set<number>>
    onChangeCheckBoxScenario: (id: number) => void
}


function ScenarioRowContainer(props: Props) {
    const dispatch = useAppDispatch();
    const { itemSize, selectedScenariosRef, onChangeCheckBoxScenario } = props
    const scenarios = useAppSelector(selectScenarios)
    const periodTypeNames = useAppSelector(selectScenariosPeriodTypeNames)
    const scenarioStatusData = useAppSelector(selectScenarioStatusDataCached)


    const dropDownPropsMemoized = useCallback((): DropdownPropsDictionary<ScenarioRowDropDownOptions> => {
        return {
            Delete: {
                optionName: t`Deletar`,
                onSelect: () => console.error(t`DropdownOption - onSelect - Not Initialized`),
                optionIcon: RiDeleteBin2Line
            },
            SetToCurrent: {
                optionName: t`Definir como Atual`,
                onSelect: () => console.error(t`DropdownOption - onSelect - Not Initialized`),
                optionIcon: RiVipCrownFill
            },
        }
    }, [])

    const handleProcedureToDeleteScenario = useCallback((scenarioId: number, scenarioName: string) => {
        forceExecuteTaskByTypeWithBodyAsync({
            queryParams: {
                taskType: "ExecProcedure",
            },
            body: {
                order: 0,
                data: {
                    "Stored Procedure": `NTransform.fn_DeleteScenario`,
                    "Callback Action": "SchedulerMessage.Actions.DeleteScenario",
                    "scenario": `${scenarioId}`,
                    "Notification Title": `Excluindo Cenário:${scenarioName}`
                },
            },
        });
    }, [])


    const onTryDelete = useCallback((scenarioId: number, scenarioName: string) => {
        nPlanModalRender.question({
            headerText: t`Deseja deletar o Cenário ${scenarioName}?`,
            acceptButtonText: t`Confirmar`,
            cancelButtonText: t`Cancelar`,
            acceptCallbackAsync: async () => {
                handleProcedureToDeleteScenario(scenarioId, scenarioName)
                // notifyPromise(
                //     deleteScenarioByIdAsync(scenarioId),
                //     {
                //         pending: t`Deletando cenário: ${scenarioName}`,
                //         success: t`Cenário deletado!`,
                //         error: t`Erro ao deletar cenário!`
                //     })
                //     .then(() => {
                //     dispatch(loadScenariosAsync({}));
                // })
            }
        });
    }, [])

    const onSetToCurrent = (scenarioId: number, scenarioName: string) => {
        nPlanModalRender.question({
            headerText: t`Deseja definir o Cenário ${scenarioName} como Atual?`,
            acceptButtonText: t`Confirmar`,
            cancelButtonText: t`Cancelar`,
            acceptCallbackAsync: async () => {
                notifyPromise(
                    setScenarioToCurrentStatusAsync(scenarioId),
                    {
                        pending: t`Definindo cenário: ${scenarioName} como Atual`,
                        success: t`Cenario ${scenarioName} definido como Atual!`,
                        error: t`Erro ao definir cenário como Atual!`
                    }
                ).then(() => {
                    dispatch(loadScenariosAsync({}));
                })
            }
        });
    }

    return (
        <>
            <AutoSizer >
                {(size: Size) => (
                    <FixedSizeList
                        height={size.height}
                        itemCount={scenarios.length}
                        itemSize={itemSize}
                        width={size.width}
                        itemData={
                            {
                                scenarios,
                                selectedScenariosRef,
                                onTryDelete,
                                onSetToCurrent,
                                onChangeCheckBoxScenario,
                                dropdownOptions: dropDownPropsMemoized,
                                periodTypeNames,
                                scenarioStatusData
                            }
                        }
                    >
                        {ScenarioRow}
                    </FixedSizeList>
                )
                }
            </AutoSizer>
        </>
    )
}

export default ScenarioRowContainer
