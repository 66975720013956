import React, { useState } from 'react'
import { IconType } from 'react-icons'
import { boolean } from 'yup/lib/locale';

interface Props {
    className?: string
    buttonText?: string;
    icon?: IconType;
    onClick?: () => void;
    disabled?: boolean;
    lock?: boolean
    fullIcon?: boolean
    id?: string
}

function IconButton(props: Props) {
    const Icon = props.icon;
    const [isLocked, setIsLocked] = useState(false);

    const localOnClick = () => {
        if (props.lock) {
            setIsLocked(!isLocked)
        }
        props.onClick?.();
    }

    return (
        <>
            <button
                id={props.id}
                disabled={props.disabled}
                className={`flex cursor-pointer 
            disabled:opacity-75 disabled:bg-gray-200 disabled:text-yellow-500 
            ${isLocked ? 'bg-yellow-500 text-white ' : 'text-yellow-500 '} 
            w-8 h-8 border-2 border-yellow-500 rounded-full items-center justify-center 
            hover:bg-yellow-500 hover:text-white ${props.className}`} onClick={localOnClick}>
                {Icon && <Icon className={`${props.fullIcon && 'w-full h-full'}`} />}
                {props.buttonText}
            </button>
        </>
    )
}

export default IconButton
