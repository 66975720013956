import { ServerErrorMessage, notifyServerError } from "api/errorHandling/helper";
import Axios from "axios"
import { toast } from "react-toastify";
var init = false;
export const initAxios = () => {
    if(init) return;
    init = true;
    Axios.interceptors.request.use(function (config) {
        const token = localStorage.getItem(process.env.REACT_APP_NPLAN_TOKEN_NAME ?? "");
        if (typeof token === 'string' && config.headers)
            config.headers.Authorization = `Bearer ${token}`;
        return config;
    });

    Axios.interceptors.response.use(
        (response) => {
            var { type, message, showToast } = response?.data;
            if (showToast === true) {
                toast(message, {
                    type
                })
            }
            return response;
        },
        async function (error) {
            console.log(error?.response)
            if(error != null) {
                var { type, message, showToast, data } = error?.response?.data;
                if (showToast === true) {
                    toast(message, {
                        type
                    })
                } else if (error.response.data) {
                    let errorData: ServerErrorMessage = error.response.data;
                    notifyServerError(errorData);
                }
            }
            
            return error;
        }
    )
}


