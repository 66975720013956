import DropDownTreeSelectorContainer from "./DropDownTreeSelectorContainer";
import { FilterCriteriaToSelector } from "redux/AppScenarioState/FilterCriteria";
import { FilterCriteria } from "redux/AppScenarioState/FilterCriteria/types";
import { useAppSelector } from "redux/hooks";
import React from "react";
import { selectFilterFixedCriteriaOptions } from "redux/AppScenarioState/FilterCriteria/selectors";

export function FixedCriteriasDropDownContainer() {
    const selectedCriterias = useAppSelector(selectFilterFixedCriteriaOptions)
    return (
        <>
            {selectedCriterias.map((filter, index) => (
                <DropDownTreeSelectorContainer
                    key={`${index}-${filter.filterCriteria}}`}
                    filterCriteria={filter.filterCriteria}
                    selector={FilterCriteriaToSelector(FilterCriteria[filter.filterCriteria])}
                    placeholder={filter.placeholder()}
                />
            ))}
        </>
    )
}

export const FixedCriteriasDropDownContainerMemoized = React.memo(FixedCriteriasDropDownContainer)
