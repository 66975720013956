
import Yellow from 'assets/images/nplan/icon/2t.png';
export default function Loader() {
    return (
        <div
            className='disable-select'
            style={{
                position: "fixed",
                height: "100%",
                width: "100%",
                zIndex: "18",
                cursor: "wait",
                backgroundColor: "white",
                opacity:"0.7"
            }}
        >
            <div 
                style={{
                    height: "100%", 
                    width: "100%", 
                    display: "flex", 
                    alignItems: "center", 
                    justifyContent: "center"
                }}
            >
            <div style={{width: "250px", height: "250px"}}>
                <img src={Yellow} alt="" className="rotate"/>
            </div>
        </div>
        </div>
        
    )
}