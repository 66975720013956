import { deleteAsync, getAsync, postAsync, putAsync } from '..';

export function getTablesAsync(): Promise<any> {
    return getAsync<any>({ url: `/tableedition/` })
}

export function PostTableAsync(table: any): Promise<any> {
    return postAsync<any>({ url: "/tableedition", body: { ...table } });
}

export function PutTableAsync(table: any): Promise<any> {
    return putAsync<any>({ url: "/tableedition", body: { ...table } });
}

export function deleteTableAsync(tableId: any): Promise<any> {
    return deleteAsync<any>({ url: `/tableedition/${tableId}` });
} 