import { dataUrl } from 'api/configuration';
import { BaseRequestParameters, deleteBaseAsync, getBaseAsync, postBaseAsync, putBaseAsync } from 'api/';

export const getAsync = async <TReturn>(parameters: BaseRequestParameters): Promise<TReturn | any> => {
    return getBaseAsync({ baseUrl: dataUrl, ...parameters });
}

export const postAsync = async <TType>(parameters: BaseRequestParameters): Promise<TType | any> => {
    return postBaseAsync({ baseUrl: dataUrl, ...parameters });
}

export const deleteAsync = async <TType>(parameters: BaseRequestParameters): Promise<TType | any> => {
    return deleteBaseAsync({ baseUrl: dataUrl, ...parameters });
}

export const putAsync = async <TReturn>(parameters: BaseRequestParameters): Promise<TReturn | null> => {
    return putBaseAsync({ baseUrl: dataUrl, ...parameters });
}