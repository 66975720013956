import { schedulerSignalR } from "api/configuration";
import { HttpTransportType, HubConnectionBuilder, LogLevel, signalMiddleware } from "redux-signalr";
import { callbacks } from "./reducers";

const connection = new HubConnectionBuilder()
  .configureLogging(LogLevel.Error)
  .withUrl(`${schedulerSignalR}pipeline`, {
    skipNegotiation: true,
    transport: HttpTransportType.WebSockets,
  })
  .withAutomaticReconnect()
  .build()

export const schedulerSignalConnector = signalMiddleware({
  callbacks,
  connection,
});