import { RequestSignalParameter } from "api"
import { CompleteStructure } from "api/data/types"
import { getAsync } from ".."
import { CompleteStructureData } from "redux/AppScenarioState/dataTypes"

const route = 'complete-structures'

// CompleteStructures
export function getAllCompleteStructureAsync(parameters?: RequestSignalParameter): Promise<CompleteStructureData[]> {
    return getAsync({ url: `/${route}`, ...parameters })
}