import React from 'react'
import { ListItemIcon, Menu, MenuItem } from '@mui/material';
import { FiRefreshCw } from 'react-icons/fi';
import { AiOutlineUsergroupAdd } from 'react-icons/ai';

const MenuContext = ({ anchorEl, onClose, onRefreshData, onAddGroup }) => {
    const handleRefresh = () => {
        onRefreshData();
        onClose();
    };
    const handleAddGroup = () => {
        onAddGroup();
        onClose();
    };
    return (
        <>
            <Menu
                open={ Boolean(anchorEl) }
                onClose={ onClose }
                anchorReference="anchorPosition"
                anchorPosition={
                    anchorEl !== null ? {
                        top: anchorEl.mouseY,
                        left: anchorEl.mouseX,
                    } : undefined
                }
            >
                <MenuItem onClick={ handleRefresh }>
                    <ListItemIcon><FiRefreshCw /></ListItemIcon>
                    Atualizar dados
                </MenuItem>
                <MenuItem onClick={ handleAddGroup }>
                    <ListItemIcon><AiOutlineUsergroupAdd /></ListItemIcon>
                    Adicionar Grupo
                </MenuItem>
            </Menu>
        </>
    );
}
export default MenuContext