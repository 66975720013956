import { notifyLoading } from "utils/notification";
import { Task, TaskDescription } from "api/scheduler/types";
import { useFieldArray, useForm } from "react-hook-form";
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { useEffect, useState } from "react";
import DropdownButton from '../../pages/ScriptManagementPage/components/DropdownButton'
import { createTaskForScriptByIdAsync } from "api/scheduler/lib/task";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { updateTasksDataByScriptAsync } from "redux/AppSchedulerState";
import { ModalType } from "redux/AppNavigationState/types";
import { setModalState } from "redux/AppNavigationState";
import { t, Trans } from "@lingui/macro";

function TaskCreateModal() {
    const dispatch = useAppDispatch();
    const taskDescriptions = useAppSelector((state) => state.appSchedulerState.taskDescriptions.value);
    const scriptId = useAppSelector((state) => state.appNavigationState.modalRender.data);
    const options = taskDescriptions.map(x => x.name);
    const [option, setOption] = useState(options[0]);
    const { register, handleSubmit, control } = useForm<Task>();
    const { fields, append } = useFieldArray({ name: 'taskParameters', control });

    const [currentTaskDescription, setCurrentTaskDescription] = useState<TaskDescription>();

    useEffect(() => {
        let selectedTaskDescription = taskDescriptions.find(x => x.name === option)
        setCurrentTaskDescription(selectedTaskDescription);
        if (selectedTaskDescription !== undefined) {
            for (let index = 0; index < selectedTaskDescription.parameterDescriptions.length; index++) {
                const parameterDescription = selectedTaskDescription.parameterDescriptions[index];
                append({ value: '', parameterDescription: parameterDescription })
            }
        }
    }, [option])

    const onCloseModal = () => {
        dispatch(setModalState(ModalType.Closed))
    }

    const handleOnSubmit = (data: Task) => {
        if (currentTaskDescription === undefined) return;

        data.taskDescription = currentTaskDescription;
        createTaskForScriptByIdAsync(scriptId, { body: data });

        notifyLoading({
            message: t({ message: 'Criando nova Task' }),
            timer: 1500,
            action: () => {
                dispatch(updateTasksDataByScriptAsync(scriptId))
                onCloseModal()
            }
        })
    }

    const onSelect = (option: string) => {
        setOption(option);
    }

    return (
        <><div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
        >
            <div className="relative w-full my-6 mx-auto max-w-md">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                        <h3 className="text-3xl font-semibold text-gray-600">
                            <Trans>Criar nova Task</Trans>
                        </h3>
                        <AiOutlineCloseCircle
                            className="ml-auto self-center text-black float-right w-8 h-8"
                            onClick={onCloseModal}
                        >
                        </AiOutlineCloseCircle>
                    </div>

                    <div className='flex border place-content-center w-full h-16 py-2 border-gray-400'>
                        <DropdownButton taskDescriptionNames={options} onSelect={onSelect} />
                    </div>

                    {/*body*/}
                    <div className="relative p-6 flex-auto">
                        <form id='createTaskForm' className='flex-row w-full' onSubmit={handleSubmit(handleOnSubmit)}>
                            <div>
                                <h5 className='text-gray-600 my-2'>
                                    <Trans>Nome</Trans>:
                                </h5>
                                <input
                                    className='flex w-full border text-black appearance rounded-lg'
                                    {...register('name')}
                                    type='text'
                                    name='name'
                                    id='name'
                                    placeholder='Execute Stored Procedure'
                                />
                            </div>
                            <div>
                                <h5 className='text-gray-600 my-2'>
                                    <Trans>Descrição</Trans>:
                                </h5>
                                <input
                                    className='flex w-full border text-black appearance rounded-lg'
                                    {...register('description')}
                                    type='text'
                                    name='description'
                                    id='description'
                                    placeholder='Executes a stored procedure in a database'
                                />
                            </div>
                            {
                                fields.map((taskParameter, index) => (
                                    <div key={index}>
                                        <h5 className='text-gray-600 my-2'>
                                            {taskParameter.parameterDescription.name}
                                        </h5>
                                        <input
                                            className='flex w-full border text-black appearance rounded-lg'
                                            {...register(`taskParameters.${index}.value`)}
                                            type='text'
                                            name={`taskParameters.${index}.value`}
                                            id={`taskParameters.${index}.value`}
                                            placeholder={taskParameter.parameterDescription.description}
                                        />
                                    </div>
                                ))
                            }

                            {/*footer*/}
                            <div className="flex items-center justify-evenly mt-3 pt-3 border-t border-solid border-blueGray-200 rounded-b">
                                <button
                                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                                    onClick={onCloseModal}
                                >
                                    <Trans>Fechar</Trans>
                                </button>
                                <button className='w-20 h-10 font-semibold text-lg rounded-full text-white items-center justify-center bg-yellow-400 focus:outline-none'>
                                    <Trans>Salvar</Trans>
                                </button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}

export default TaskCreateModal
