import { Months, Period } from "api/data/types";
import { PeriodDataOld, ProgressLoaderPromiseWaiterAsyncThunkProps, SinglePeriodData } from "./types";
import dayjs from "dayjs";
import { Dictionary } from "utils/types";
import { Dispatch } from "@reduxjs/toolkit";
import { incrementColoredProgressLoader, incrementProgressLoader, setLoadingAppComponentReady, setLoadingScenarioComponentReady } from "./AppNavigationState";


export const baseProgressLoaderCreateAsyncThunk = async <TReturn>(
    dispatch: Dispatch<any>,
    props: ProgressLoaderPromiseWaiterAsyncThunkProps,
    action: () => Promise<TReturn>
) => {
    const { asyncDependencies, loadingStateColor, loadingAppComponent, loadingScenarioComponent } = props
    // const response = Promise.resolve(action);
    const response = await action();

    if (loadingAppComponent !== undefined)
        dispatch(setLoadingAppComponentReady(loadingAppComponent))

    if (loadingScenarioComponent !== undefined)
        dispatch(setLoadingScenarioComponentReady(loadingScenarioComponent))

    dispatch(incrementProgressLoader())
    dispatch(incrementColoredProgressLoader(loadingStateColor))
    await Promise.all(asyncDependencies ?? [])
    return response;
}

export const createSinglePeriodData = (periods: Period[]): SinglePeriodData[] => {

    if (!periods) return [];
    var monthPeriods: SinglePeriodData[] = [];

    for (let i = 0; i < periods.length; i++) {
        const period = periods[i];
        var startDateTime = dayjs(String(period.startTime)).toDate();
        var endDateTime = dayjs(String(period.endTime)).toDate();
        var monthNumber = (period.periodGroup - 1) % 12;
        if (monthNumber < 0) monthNumber = 0;

        monthPeriods.push({
            monthName: Months[monthNumber](),
            monthNumber: monthNumber,
            group: period.periodGroup,
            rank: period.rank,
            yearNumber: startDateTime.getFullYear(),
            startDay: startDateTime.getDate(),
            endDay: endDateTime.getDate()
        })
    }
    return monthPeriods.sort(x => x.rank).filter(period => period !== undefined);
}

export const createComplexPeriodDataMonthly = (periods: Period[]): PeriodDataOld[] => {

    if (!periods) return []
    let monthPeriods: PeriodDataOld[] = []

    let lastPeriodGroup = -1
    let lastPeriodMonthNumber = -1

    for (let i = 0; i < periods.length; i++) {
        const period = periods[i]
        let startDateTime = dayjs(String(period.startTime)).toDate()
        let endDateTime = dayjs(String(period.endTime)).toDate()
        let monthNumber = dayjs(String(period.startTime)).month()

        if (lastPeriodMonthNumber < 0)
            lastPeriodMonthNumber = monthNumber

        //TODO need to delete PeriodDataOld and use SinglePeriodData
        let localMonthName = Months[lastPeriodMonthNumber]
        let localMonthNumber = lastPeriodMonthNumber

        if (dayjs(period.startTime).date > dayjs(period.endTime).date) {
            localMonthNumber = lastPeriodMonthNumber - 1 < 0 ? 11 : lastPeriodMonthNumber - 1
            localMonthName = Months[localMonthNumber]
        }

        let periodGroup = period.periodGroup
        if (lastPeriodGroup !== periodGroup) {
            monthPeriods[periodGroup] = ({
                monthName: Months[lastPeriodMonthNumber](),
                monthNumber: lastPeriodMonthNumber,
                group: period.periodGroup,
                rank: period.rank,
                yearNumber: startDateTime.getFullYear(),
                periods: [{
                    monthName: Months[dayjs(period.startTime).month()](),
                    startDay: startDateTime.getDate(),
                    endDay: endDateTime.getDate()
                }]
            })
            lastPeriodMonthNumber = (lastPeriodMonthNumber + 1) % 12
        } else {
            const localLastPeriodMonthNumber = lastPeriodMonthNumber - 1 < 0 ? 11 : lastPeriodMonthNumber - 1

            monthPeriods[periodGroup].periods.push({
                monthName: Months[dayjs(period.startTime).month()](),
                startDay: startDateTime.getDate(),
                endDay: endDateTime.getDate()
            })
        }
        lastPeriodGroup = periodGroup
    }
    return monthPeriods.filter(period => period !== undefined);
}
export const createComplexPeriodDataWeekly = (periods: Period[]): PeriodDataOld[] => {

    if (!periods) return []
    let monthPeriods: PeriodDataOld[] = []

    let lastPeriodGroup = -1
    let lastPeriodMonthNumber = -1

    for (let i = 0; i < periods.length; i++) {
        const period = periods[i]
        let startDateTime = dayjs(String(period.startTime)).toDate()
        let endDateTime = dayjs(String(period.endTime)).toDate()
        let monthNumber = dayjs(String(period.startTime)).month()

        if (lastPeriodMonthNumber < 0)
            lastPeriodMonthNumber = monthNumber

        //TODO need to delete PeriodDataOld and use SinglePeriodData
        let localMonthName = Months[lastPeriodMonthNumber]
        let localMonthNumber = lastPeriodMonthNumber

        if (dayjs(period.startTime).date > dayjs(period.endTime).date) {
            localMonthNumber = lastPeriodMonthNumber - 1 < 0 ? 11 : lastPeriodMonthNumber - 1
            localMonthName = Months[localMonthNumber]
        }

        let periodGroup = period.periodGroup
        if (lastPeriodGroup !== periodGroup) {
            monthPeriods[periodGroup] = ({
                monthName: Months[lastPeriodMonthNumber](),
                monthNumber: lastPeriodMonthNumber,
                group: period.periodGroup,
                rank: period.rank,
                yearNumber: startDateTime.getFullYear(),
                periods: [{
                    monthName: Months[dayjs(period.startTime).month()](),
                    startDay: startDateTime.getDate(),
                    endDay: endDateTime.getDate()
                }]
            })
            lastPeriodMonthNumber = (lastPeriodMonthNumber + 1) % 12
        } else {
            const localLastPeriodMonthNumber = lastPeriodMonthNumber - 1 < 0 ? 11 : lastPeriodMonthNumber - 1

            monthPeriods[periodGroup].periods.push({
                monthName: Months[dayjs(period.startTime).month()](),
                startDay: startDateTime.getDate(),
                endDay: endDateTime.getDate()
            })
        }
        lastPeriodGroup = periodGroup
    }
    return monthPeriods.filter(period => period !== undefined);
}

//chartLabel
export const createMinifiedPeriods = (planningGridPeriodsData: PeriodDataOld[]) => {
    var newChartLabels: string[] = [];
    for (let i = 0; i < planningGridPeriodsData.length; i++) {
        const planningGridPeriodData = planningGridPeriodsData[i];
        for (let k = 0; k < planningGridPeriodData.periods.length; k++) {
            const period = planningGridPeriodData.periods[k];
            newChartLabels.push(`${period.monthName.slice(0, 3).toUpperCase()} ${period.startDay}`)
        }
    }
    return newChartLabels;
}

export const createChartLabelsLong = (planningGridPeriodsData: PeriodDataOld[]) => {
    var newChartLabels: string[] = [];
    for (let i = 0; i < planningGridPeriodsData.length; i++) {
        const planningGridPeriodData = planningGridPeriodsData[i];
        for (let k = 0; k < planningGridPeriodData.periods.length; k++) {
            const period = planningGridPeriodData.periods[k];
            newChartLabels.push(`${planningGridPeriodData.monthName} de ${period.startDay} a ${period.endDay}`)

        }
    }
    return newChartLabels;
}

export const createDictionaryRange = <TSource, TKey extends number | string>(source: TSource[], selector: ((x: TSource) => TKey | keyof Dictionary<TSource[]>)): Dictionary<TSource[]> => {
    let newDictionary: Dictionary<TSource[]> = {}

    for (let index = 0; index < source.length; index++) {
        const sourceElement = source[index];
        const localKey = selector(sourceElement);
        if (!newDictionary[localKey]) {
            // let newTSourceArray : TSource[] = []
            newDictionary[localKey] = []
        }
        newDictionary[localKey].push(sourceElement);
    }
    return newDictionary;
}
//TODO adicionar documentacao de ocmo utilizar metodos
export const createPropertyDictionary = <TSource, TProperty extends PropertyKey, TKey extends number | string>(
    source: TSource[],
    keySelector: ((x: TSource) => TKey | keyof Dictionary<TSource[]>),
    propertySelector: ((x: TSource) => TProperty)):
    Dictionary<TProperty> => {
    let newDictionary: Dictionary<TProperty> = {}

    for (let index = 0; index < source.length; index++) {
        const sourceElement = source[index];
        const localKey = keySelector(sourceElement);
        // if (!newDictionary[localKey]) newDictionary[localKey] = {}
        newDictionary[localKey] = propertySelector(sourceElement);
    }
    return newDictionary;
}
export const createPropertyRangeDictionary = <TSource, TProperty extends PropertyKey, TKey extends number | string>(
    source: TSource[],
    keySelector: ((x: TSource) => TKey | keyof Dictionary<TSource[]>),
    propertySelector: ((x: TSource) => TProperty)):
    Dictionary<TProperty[]> => {
    let newDictionary: Dictionary<TProperty[]> = {}

    for (let index = 0; index < source.length; index++) {
        const sourceElement = source[index];
        const localKey = keySelector(sourceElement);
        if (!newDictionary[localKey]) newDictionary[localKey] = []
        newDictionary[localKey].push(propertySelector(sourceElement));
    }
    return newDictionary;
}

export const createDictionary = <TSource, TKey extends number | string>(source: TSource[], selector: ((x: TSource) => TKey | keyof Dictionary<TSource[]>)): Dictionary<TSource> => {
    let newDictionary: Dictionary<TSource> = {}
    if (source == null) return newDictionary;
    for (let index = 0; index < source.length; index++) {
        const sourceElement = source[index];
        const localKey = selector(sourceElement);
        newDictionary[localKey] = sourceElement;
    }
    return newDictionary;
}


