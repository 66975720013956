import { Box, Typography } from "@mui/material";
import { embedDashboard } from "@superset-ui/embedded-sdk";
import { getHomeTokenAsync } from "api/data/lib/home";
import { useCallback, useEffect, useState } from "react";
const HomePage = () => {
    const [dashId, setDashId] = useState<any>(null);
    const [dashUrl, setDashUrl] = useState<any>(null)
    useEffect(() => {
        (async () => {
            var res = await getHomeTokenAsync();
            if (res.type === 'success') {
                setDashId(res.data.dashid);
                setDashUrl(res.data.url)
            }
        })()
    }, [])
    const handleFetchGuestToken = async () => {
        var res = await getHomeTokenAsync();
        if (res.type === 'success')
            return res.data.token
    }
    const handleLoadDash = useCallback(async () => {
        if (dashId == null) return;
        await embedDashboard({
            id: dashId, // given by the Superset embedding UI
            supersetDomain: dashUrl,
            //@ts-ignore
            mountPoint: document.getElementById("superset-container"), // any html element that can contain an iframe
            //@ts-ignore
            fetchGuestToken: () => handleFetchGuestToken(),

        });
        var element = document.getElementById('superset-container');
        var iframe: any = element?.childNodes[0];
        if (iframe != null && iframe.style != null) {
            iframe.style.height = '100%'
            iframe.style.width = '100%'
        }
    }, [dashId])
    useEffect(() => {
        handleLoadDash()
    }, [handleLoadDash])
    return (
        <Box
            sx={ {
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            } }
            id="superset-container"
        >
        </Box>
    )
}

export default HomePage;