import { Box, Chip, Switch } from '@mui/material'
import React from 'react'
export function defaultRender(row: any, column: any, foreignFields: any) {
    var value = row[column.field]      
    if (column.foreignKey && row[column.field] !== undefined && row[column.field] !== null)
        value = foreignFields[column.field]?.filter(c => c.id === row[column.field])[0]?.name
    return (
        <React.Fragment>
            <label style={{ cursor: column.editable ? "pointer" : "default",whiteSpace: "nowrap", width: "100%", overflow: "hidden", textOverflow: "ellipsis", display: "inline-block", textAlign: column.align  }} title={value?.toString()}>
                {column.viewType === "decimal" && decimalRender(column, value)}
                {column.viewType === "texto" && textRender(column, value)}
                {column.viewType === "inteiro" && intRender(column, value)}
            </label>
        </React.Fragment>
    )
}

function decimalRender(column: any, value: any) {
    return (
        <React.Fragment>
            {value == null ? column.nullText || "NULL" : Number(parseFloat(value).toFixed(column.numberDecimals)).toLocaleString('pt-BR')}
        </React.Fragment>
    )
}

function textRender(column: any, value: any) {
    return (
        <React.Fragment>
            {value == null ? column.nullText || "NULL" : value}
        </React.Fragment>
    )
}
function intRender(column: any, value: any) {
    return (
        <React.Fragment>
            {value == null ? column.nullText || "NULL" : parseInt(value).toLocaleString('pt-BR')}
        </React.Fragment>
    )
}

export function dateRender(row: any, column: any) {
    return (
        <React.Fragment>
            <label style={{ cursor: column.editable ? "pointer" : "default", width: "100%", overflow: "hidden", textOverflow: "ellipsis", display: "inline-block"  }} title={new Date(row[column.field]).toLocaleDateString()}>
                {new Date(row[column.field]).toLocaleDateString()}
            </label>
        </React.Fragment>
    )
}
export function dateTimeRender(row, column) {
    return (
        <React.Fragment>
            <label style={{ cursor: column.editable ? "pointer" : "default", width: "100%", overflow: "hidden", textOverflow: "ellipsis", display: "inline-block"  }} title= {`${new Date(row[column.field]).toLocaleDateString()} ${new Date(row[column.field]).toLocaleTimeString()}`}>
                {`${new Date(row[column.field]).toLocaleDateString()} ${new Date(row[column.field]).toLocaleTimeString()}`}
            </label>
        </React.Fragment>
    )
}
export function timeRender(row, column) {
    return (
        <React.Fragment>
            <label style={{ cursor: column.editable ? "pointer" : "default", width: "100%", overflow: "hidden", textOverflow: "ellipsis", display: "inline-block"  }} title={new Date(row[column.field]).toLocaleTimeString()}>
                {new Date(row[column.field]).toLocaleTimeString()}
            </label>
        </React.Fragment>
    )
}

export function checkBoxRender(row, column) {
    return (
        <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Switch checked={row[column.field] ? true : false} />
        </div>
    )
}

export function colorRender(row, col) {
    return (
        <Box sx={{width: '100%', height: '100%'}}>
            <Chip label={ row[col.field.toLowerCase()] } sx={{ bgcolor: row[col.field.toLowerCase()] }} />
        </Box>
    )
}