import { FilterButtonGroup } from "./components/FilterButtonGroup";
import { FilterFlyoutMenu } from "./components/SelectComponents/FilterFlyoutMenu";
import SelectCompanyMenu from "./components/SelectComponents/SelectCompanyMenu";
import { useAppSelector } from "redux/hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { MdSave, MdSettings } from "react-icons/md";
import { TiDelete } from "react-icons/ti";
import { CardsContainer } from "./containers/CardsContainer";
import { useDispatch } from "react-redux";
import { setInventoryData, setIsRecalculating, setMainStockFilter, setScenariosOptions, setSecondaryStockFilter, setSegmentationData, setStockEditableTableValidation, } from "redux/AppStockState";
import { PutSegmentationAsync, getSegmentationAsync, } from "api/data/lib/stockPolicySegmentation";
import { toast } from "react-toastify";
import { TableComponent } from "./containers/InventoryHealthContainer";
import { getInventorySummaryAsync } from "api/data/lib/stockPolicyInventorySummary";
import { SummaryHeader } from "./components/SummaryHeader";
import { forceExecuteTaskByTypeWithBodyAsync } from "api/scheduler/lib/task";
import { aggregateData, aggregateDataComplexityData, aggregateDataUncertaintyData, aggregateDataVolumeData, } from "./utils";
import useDataTypesHandler from "./hooks/useDataTypesHandler";
import useCreateLinesHandler from "./hooks/useCreateLinesHandler";
import SelectPeriodMenu from "./components/SelectComponents/SelectPeriodMenu";
import SelectScenarioMenu from "./components/SelectComponents/SelectScenarioMenu";
import { getScenariosAsync } from "api/data";
import { Trans, t } from "@lingui/macro";
import { stockPolicyAxesTypeToLanguage, stockPolicyCompanyOptionsToLanguage } from "locales/helper";
import { useLingui } from "@lingui/react";
import { StockPolicyAxesType } from "api/data/enums";
import { AsyncStatus } from "redux/types";
import NeoLoader from "components/Loader/NeoLoader";
import { selectAvailableScenarios } from "redux/AppScenarioState";
import { ToolTipComponentReactNodeChildren } from "./components/TooltipComponentNodeChildren";
import SippnerComponent from "./components/SpinnerComponent";
import SpinnerComponent from "./components/SpinnerComponent";

export default function StockPagePolicy() {
    const {
        selectedMainFilter,
        selectedSecondaryFilter,
        stockPolicyAxes,
        stockEditableTableValidation,
        segmentationData,
        supplyStrategyEditedObjects,
        stockInventory,
        stockFilterOptions,
        scenariosOptions,
        selectedScenario,
        isRecalculating
    } = useAppSelector((state) => state.appStockState);
    const scenarios = useAppSelector(selectAvailableScenarios)
    const pipelineProgress = useAppSelector((state) => state.appNavigationState.pipeline.pipelineProgress)


    const dispatch = useDispatch();
    const { i18n } = useLingui()

    const getData = useCallback(async () => {
        const { data: SegmentationData } = await getSegmentationAsync();
        dispatch(setSegmentationData({ data: SegmentationData }));

        dispatch(setScenariosOptions({ scenarioOptions: scenarios }));
    }, []);

    const getSummaryData = useCallback(async (scenarioId: number) => {
        const { data: stockInventory } = await getInventorySummaryAsync(scenarioId);
        dispatch(setInventoryData({ data: stockInventory }));
    }, []);

    useEffect(() => {
        getData();
        if (scenariosOptions.length > 0) {
            getSummaryData(selectedScenario.id);
        }
    }, [selectedScenario]);

    function handleProcedureToCalculateInventory() {
        forceExecuteTaskByTypeWithBodyAsync({
            queryParams: {
                taskType: "ExecProcedure",
            },
            body: {
                order: 0,
                data: {
                    "Stored Procedure": "NTransform.sp201_CalculateInventory",
                    "Callback Action": "SchedulerMessage.Actions.InventorySummary",
                    // "scenario": `${selectedScenario.id}`,
                    "Notification Title": "Recalculando Política de Estoque"
                },
            },
        });
        dispatch(setIsRecalculating({ validation: true }))
    }
    useEffect(() => {
        console.log(isRecalculating)
    }, [isRecalculating])

    //Custom Hook to validate the uniques values of types (company, complexity, volume and uncertainty)
    const { types, companyTypes } = useDataTypesHandler();


    //This part of the code aggregates data. This will be used when the user chooses "all" values on companies or other filters
    useEffect(() => {
        aggregateData(stockInventory.data);
    }, [stockInventory.data]);

    //Filters the items by the company filter on the edition table
    const mainFilterItems = useMemo(() => {
        return segmentationData.data.filter((d) => d.company.id === selectedMainFilter.id);
    }, [segmentationData.data, selectedMainFilter.id]);

    //Filters the items by the company filter on the summary table
    const mainFilterSummaryItems = useMemo(() => {
        if (selectedMainFilter.id === 0) { return aggregateData(stockInventory.data); }
        else { return stockInventory.data.filter((d) => d.companyId === selectedMainFilter.id); }
    }, [stockInventory.data, selectedMainFilter.id]);

    //Filters items by the second filter, the Z axis (standart is uncertainty)
    const secondFilterItems = useMemo(() => {

        switch (stockPolicyAxes.filter()) {
            case stockPolicyAxesTypeToLanguage(StockPolicyAxesType.Uncertainty):
                if (selectedSecondaryFilter.id === 0) {
                    return {
                        segmentationSecondFilter: mainFilterItems,
                        stockSummarySecondFilter: aggregateDataUncertaintyData(
                            aggregateData(mainFilterSummaryItems)
                        ),
                    };
                } else {
                    return {
                        segmentationSecondFilter: mainFilterItems.filter((d) => d.classificationUncertainty.id === selectedSecondaryFilter.id),
                        stockSummarySecondFilter: mainFilterSummaryItems.filter((d) => d.classificationUncertaintyId === selectedSecondaryFilter.id),
                    };
                }
            case stockPolicyAxesTypeToLanguage(StockPolicyAxesType.Volume):
                if (selectedSecondaryFilter.id === 0) {
                    return {
                        segmentationSecondFilter: mainFilterItems,
                        stockSummarySecondFilter: aggregateDataVolumeData(aggregateData(mainFilterSummaryItems)),
                    };
                } else {
                    return {
                        segmentationSecondFilter: mainFilterItems.filter((d) => d.classificationVolume.id === selectedSecondaryFilter.id),
                        stockSummarySecondFilter: mainFilterSummaryItems.filter((d) => d.classificationVolumeId === selectedSecondaryFilter.id),
                    };
                }

            case stockPolicyAxesTypeToLanguage(StockPolicyAxesType.Complexity):
                if (selectedSecondaryFilter.id === 0) {
                    return {
                        segmentationSecondFilter: mainFilterItems,
                        stockSummarySecondFilter: aggregateDataComplexityData(aggregateData(mainFilterSummaryItems)),
                    };
                } else {
                    return {
                        segmentationSecondFilter: mainFilterItems.filter((d) => d.classificationComplexity.id === selectedSecondaryFilter.id),
                        stockSummarySecondFilter: mainFilterSummaryItems.filter((d) => d.classificationComplexityId === selectedSecondaryFilter.id),
                    };
                }

            default:
                return { segmentationSecondFilter: [], stockSummarySecondFilter: [] };
        }
    }, [
        mainFilterItems,
        mainFilterSummaryItems,
        selectedSecondaryFilter.id,
        stockPolicyAxes,
    ]);

    const { showingItems } = useCreateLinesHandler({
        props: {
            segmentationData,
            stockEditableTableValidation,
            secondFilterItems,
        },
    });

    //Editable card handler

    async function handleSaveEditedObjects() {
        await PutSegmentationAsync(supplyStrategyEditedObjects)
            .then(() => {
                toast.success(i18n.t(`Os dados foram alterados com sucesso!`), {
                    position: "bottom-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            })
            .then(() => {
                handleProcedureToCalculateInventory();
            });

        dispatch(
            setStockEditableTableValidation({
                isEditable: false,
            })
        );

    }

    const handleClickOnEdit = useCallback(() => {
        dispatch(setStockEditableTableValidation({ isEditable: true, }));
        dispatch(setSecondaryStockFilter({ secondaryFilterName: stockFilterOptions[0].name, id: stockFilterOptions[0].id, code: stockFilterOptions[0].code, }));
        if (selectedMainFilter.id === 0) {
            dispatch(setMainStockFilter({ mainFilterName: () => stockPolicyCompanyOptionsToLanguage(companyTypes[1].name), id: companyTypes[1].id, }));
        } else {
            dispatch(setMainStockFilter({ mainFilterName: () => stockPolicyCompanyOptionsToLanguage(selectedMainFilter.name()), id: selectedMainFilter.id, }));
        }
    }, [companyTypes, selectedMainFilter, stockFilterOptions, i18n])


    const scenariosLoadStatus = useAppSelector((state) => state.appNavigationState.loadingAppComponent.Scenarios)
    if (scenariosLoadStatus !== AsyncStatus.READY) return <NeoLoader />

    return (
        <div className="flex flex-col h-fit w-full  bg-gray-50  rounded-lg gap-1 ">
            <div className="w-full border border-gray-400 rounded-md gap-1 flex justify-start p-1 items-center" >
                <div className="flex flex-1 flex-col gap-1 items-start text-center justify-center w-1/2 " style={{ marginLeft: '4px' }}>
                    <div className="justify-start flex flex-col items-start">
                        <div className="flex items-center justify-center" style={{ marginLeft: '4px' }}>
                            <p className="font-semibold text-lg text-center">
                                <Trans>Empresa</Trans>
                            </p>
                        </div>
                        {types.Company.length > 0 ? (
                            <SelectCompanyMenu />
                        ) : null}</div>
                </div>

                {scenariosOptions.length > 0 ? (
                    <div className="flex flex-1 gap-2 w-1/2">
                        <div className="flex flex-col  p-1 flex-1 w-full relative justify-center items-start rounded-md  ">
                            <div className="text-black-800 rounded-md ">
                                <p className="font-semibold text-lg mx-1">
                                    <Trans>Plano de Produção</Trans>
                                </p>
                            </div>
                            <div className="flex flex-1 gap-2 justify-end" >
                                <div className=" bg-white ">
                                    <SelectScenarioMenu />
                                </div>
                                <div className="flex bg-white ">
                                    <SelectPeriodMenu />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}

            </div>
            <div className="flex w-full gap-1 h-full">
                {/* ----- LADO ESQUERDO DA TELA -----*/}

                {stockInventory !== undefined && stockInventory.data.length === 0 ? (

                    <SpinnerComponent text={i18n.t(`Carregando`)} />
                ) : (
                    <div
                        className="flex flex-col items-center justify-start w-fit p-2 h-fit gap-2 border rounded-md border-gray-400"
                        style={{ minWidth: "50%" }}
                    >
                        <div className="flex w-full flex-col items-start p-1 justify-start ">
                            <p className="text-lg font-black text-left">
                                <Trans>Política de Estoque</Trans>
                            </p>
                        </div>
                        {/* ----- HEADER INFORMATIVO ----- */}
                        <SummaryHeader />

                        {/* ----- FILTROS EMPRESA E EIXO FILTRO ----- */}
                        <div className="flex justify-between w-full m-x-0 m-y-auto h-fit  rounded-md p-2 items-center">
                            <div className=" flex items-center justify-center">
                                <p className="font-lg">
                                    <Trans> Filtro selecionado:</Trans>
                                </p>
                                <p className="font-bold font-md p-2 underline text-gray-800">
                                    {stockPolicyAxes.filter()}
                                </p>
                            </div>
                            <FilterButtonGroup />
                        </div>

                        {/* ----- GRID CARDS ----- */}
                        <div className="flex flex-col w-full h-full  p-1">
                            <div className="flex flex-col w-full justify-end">
                                <div className=" flex w-full justify-end align-bottom relative mb-4">
                                    <div className="absolute right-0 flex items-center justify-end gap-2 ">
                                        {/* <button
                                            className="bg-red-50"
                                            onClick={() => handleProcedureToCalculateInventory()}
                                        >
                                            {isExecutingProcedure ? "Carregando..." : "Atualizar"}
                                        </button> */}
                                        {isRecalculating ? <SpinnerComponent text={i18n.t(`Recalculando Política`)} />
                                            : !stockEditableTableValidation.isEditable ? (
                                                <button onClick={() => handleClickOnEdit()}>
                                                    <ToolTipComponentReactNodeChildren tooltipText={i18n.t(`Modificar tabela de segmentação`)} color={""}   >
                                                        <MdSettings size={25} />
                                                    </ToolTipComponentReactNodeChildren>
                                                </button>
                                            ) : null}
                                        {stockEditableTableValidation.isEditable ? (
                                            <div className="flex gap-4 items-center justify-center">
                                                <button onClick={() => handleSaveEditedObjects()}>
                                                    <ToolTipComponentReactNodeChildren tooltipText={i18n.t(`Salvar os dados e recalcular`)} color={""}>
                                                        <MdSave size={25} />
                                                    </ToolTipComponentReactNodeChildren>
                                                </button>
                                                <button
                                                    onClick={() =>
                                                        dispatch(
                                                            setStockEditableTableValidation({
                                                                isEditable: false,
                                                            })
                                                        )
                                                    }
                                                >
                                                    <ToolTipComponentReactNodeChildren tooltipText={i18n.t(`Limpar os filtros e sair`)} color={""}>
                                                        <TiDelete size={25} />

                                                    </ToolTipComponentReactNodeChildren>
                                                </button>
                                            </div>
                                        ) : null}

                                        <ToolTipComponentReactNodeChildren tooltipText={i18n.t(`Alterar eixos`)} color={""}   >
                                            <FilterFlyoutMenu StockTypes={types} />
                                        </ToolTipComponentReactNodeChildren>
                                    </div>{" "}
                                </div>
                                <div className="flex h-fit">
                                    {showingItems ? (<CardsContainer />) : (<p>Carregando...</p>)}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {/* ----- LADO DIRETO DA TELA -----*/}
                <div
                    style={{ minWidth: "48%" }}
                    className="relative flex gap-2 h-full w-full mb-2 flex-col justify-center items-center  border border-gray-400 rounded-md p-1"
                >
                    <TableComponent />
                </div>
            </div>
        </div >
    );
}
