import { IconType } from 'react-icons'
import { GrGroup, GrUser } from 'react-icons/gr'
import { BsTable } from 'react-icons/bs'
import { MdOutlineAnalytics } from 'react-icons/md'
import { i18n } from '@lingui/core'


export interface Route<T> {
    icon?: IconType;
    pageName?: string;
    pageUrl?: string;
    subPages?: SubRoutes<T>[]
}

interface SubRoutes<T> {
    icon?: IconType;
    subPageName: T;
    subPageUrl: string;
    subPages?: SubRoutes<T>[];
}

export enum ManagementRoutesType {
    ManageUsers = 'Gerenciar Usuários',
    ManageGroups = 'Gerenciar Grupos',
    ManageTables = 'Gerenciar Tabelas',
    ManageReports = 'Gerenciar Relatórios'
}

export const managementRoutesTranslator = (managementRoutesType: ManagementRoutesType): string => {
    switch (managementRoutesType) {
        case ManagementRoutesType.ManageUsers:
            return i18n.t(`Gerenciar Usuários`)
        case ManagementRoutesType.ManageGroups:
            return i18n.t(`Gerenciar Grupos`)
        case ManagementRoutesType.ManageTables:
            return i18n.t(`Gerenciar Tabelas`)
        case ManagementRoutesType.ManageReports:
        default:
            return i18n.t(`Gerenciar Relatórios`)
    }
}

export const ManagementRoutes: Route<ManagementRoutesType>[] = [
    {
        pageUrl: 'manage',
        subPages: [
            // {
            //     icon: FaGlobeAmericas,
            //     subPageName: 'Parâmetros Globais',
            //     subPageUrl: 'global-parameters'
            // },
            {
                icon: GrUser,
                subPageName: ManagementRoutesType.ManageUsers,
                subPageUrl: 'users'
            },
            // {
            //     icon: RiRadioButtonLine,
            //     subPageName: 'Editar ScriptButtons',
            //     subPageUrl: 'script-buttons'
            // }
        ]
    },
    {
        pageUrl: 'manage',
        subPages: [
            {
                icon: GrGroup,
                subPageName: ManagementRoutesType.ManageGroups,
                subPageUrl: 'roles'
            },
            // {
            //     icon: FaSitemap,
            //     subPageName: 'Agendamento de Scripts',
            //     subPageUrl: 'scripts-scheduling'
            // }, 
            // {
            //     icon: HiOutlineClipboardList,
            //     subPageName: 'Gerenciar Trabalhos',
            //     subPageUrl: 'work-management'
            // },
        ]
    },
    {
        pageUrl: 'tableedition',
        subPages: [
            {
                icon: BsTable,
                subPageName: ManagementRoutesType.ManageTables,
                subPageUrl: ''
            },
            // {
            //     icon: FaSitemap,
            //     subPageName: 'Agendamento de Scripts',
            //     subPageUrl: 'scripts-scheduling'
            // }, 
            // {
            //     icon: HiOutlineClipboardList,
            //     subPageName: 'Gerenciar Trabalhos',
            //     subPageUrl: 'work-management'
            // },
        ]
    },
    {
        pageUrl: 'manage',
        subPages: [
            {
                icon: MdOutlineAnalytics,
                subPageName: ManagementRoutesType.ManageReports,
                subPageUrl: 'powerbi'
            },
            // {
            //     icon: FaSitemap,
            //     subPageName: 'Agendamento de Scripts',
            //     subPageUrl: 'scripts-scheduling'
            // }, 
            // {
            //     icon: HiOutlineClipboardList,
            //     subPageName: 'Gerenciar Trabalhos',
            //     subPageUrl: 'work-management'
            // },
        ]
    }

]