import { createAsyncThunk, createSelector } from "@reduxjs/toolkit";
import { createCachedSelector } from 're-reselect';
import { RootState } from "redux/store";
import { ActionType, AsyncLoadProps } from "redux/types";
import { PlanningChartData } from "pages/PlanningPage/components/PlanningGrid/types";
import { calculateChartStockStatusType, calculateWeeklyToMontlyPlanningGrids, createItemCodeSearchTree, createItemLevelSearchTree } from "./helpers";
import { appScenarioSlice } from "./reducers";
import { getDemandDateRangesAsync, getUserDataFlatAsync } from "api/data/lib/userData";
import { Dictionary } from "utils/types";
import { getItemAttributesAsync } from "api/data/lib/attribute";
import { GlobalPlanningGridTargetType, PlanningGridData } from "./dataTypes";
import { HighlightedStatusType, ScenarioStatusCodeById, StockStatusType } from "api/data/enums";
import { getCapacitiesByScenarioIdAsync, getPlanningGridsByScenarioIdAsync, getScenariosAsync, getResourceGroupsDetailsAsync, getItemsAsync, getPeriodsByScenarioIdAsync, getItemImageByItemIdAsync, updatePlanningGridsAsync, saveScenarioAsAsync } from "api/data";
import { getPlanningGridAllocationsDetailsByScenarioIdAsync } from "api/data/lib/planningGridAllocation";
import { getPlanningGridResourcesByScenarioIdAsync } from "api/data/lib/planningGridResource";
import { getAllResourcesDetailsAsync } from "api/data/lib/resource";
import { t } from "@lingui/macro";
import { AppScenarioState } from "./types";
import { LoadingAppComponent, LoadingScenarioComponent, LoadingStateColor } from "redux/AppNavigationState/types";
import { PlanningGrid } from "api/data/types";
import { getAllCompleteStructureAsync } from "api/data/lib/complete-structures";
import { baseProgressLoaderCreateAsyncThunk } from "redux/helpers";
import { getInventorySummaryAsync } from "api/data/lib/stockPolicyInventorySummary";
import { getSegmentationAsync } from "api/data/lib/stockPolicySegmentation";
import { getItemOperationsAsync } from "api/data/lib/item-operation";
import { getItemResourcesAsync } from "api/data/lib/item-resources";
import { forceExecuteTaskByTypeWithBodyAsync } from "api/scheduler/lib/task";

export const loadItemAttributesBundleAsync = createAsyncThunk(
    `${ActionType.AppScenarioState}/loadItemAttributesBundle`,
    async (props: AsyncLoadProps, { dispatch }) => {
        const { abortSignal } = props
        return await baseProgressLoaderCreateAsyncThunk(
            dispatch,
            {
                ...props,
                loadingStateColor: LoadingStateColor.Red,
                loadingScenarioComponent: LoadingScenarioComponent.ItemAttributesBundle
            },
            () => getItemAttributesAsync({ signal: abortSignal })
        )
    }
);

export const loadItemsAsync = createAsyncThunk(
    `${ActionType.AppScenarioState}/loadItemsAsync`,
    async (props: AsyncLoadProps, { dispatch }) => {
        const { abortSignal } = props
        return await baseProgressLoaderCreateAsyncThunk(
            dispatch,
            {
                ...props,
                loadingStateColor: LoadingStateColor.Red,
                loadingScenarioComponent: LoadingScenarioComponent.Items
            },
            () => getItemsAsync({ signal: abortSignal })
        )
    }
);

export const loadPlanningGridsByScenarioIdAsync = createAsyncThunk(
    `${ActionType.AppScenarioState}/loadPlanningGridsByScenarioId`,
    async (props: { scenarioId: number } & AsyncLoadProps, { dispatch }) => {
        const { abortSignal, scenarioId } = props
        return await baseProgressLoaderCreateAsyncThunk(
            dispatch,
            {
                ...props,
                loadingStateColor: LoadingStateColor.Red,
                loadingScenarioComponent: LoadingScenarioComponent.PlanningGrids
            },
            () => getPlanningGridsByScenarioIdAsync({ queryParams: { scenarioId: scenarioId }, signal: abortSignal })
        )
    }
);

export const loadResourcesDetailsAsync = createAsyncThunk(
    `${ActionType.AppScenarioState}/loadResourcesDetailsAsync`,
    async (props: AsyncLoadProps, { dispatch }) => {
        return await baseProgressLoaderCreateAsyncThunk(
            dispatch,
            {
                ...props,
                loadingStateColor: LoadingStateColor.Yellow,
                loadingAppComponent: LoadingAppComponent.Resources
            },
            () => getAllResourcesDetailsAsync({ signal: props.abortSignal })
        )
    }
);

export const loadResourceGroupDetailsAsync = createAsyncThunk(
    `${ActionType.AppScenarioState}/loadResourceGroupDetailsAsync`,
    async (props: AsyncLoadProps, { dispatch }) => {
        return await baseProgressLoaderCreateAsyncThunk(
            dispatch,
            {
                ...props,
                loadingStateColor: LoadingStateColor.Blue,
                loadingScenarioComponent: LoadingScenarioComponent.ResourceGroup
            },
            () => getResourceGroupsDetailsAsync({ signal: props.abortSignal })
        )
    }
);

export const loadPlanningGridAllocationsDetailsByScenarioIdAsync = createAsyncThunk(
    `${ActionType.AppScenarioState}/loadPlanningGridAllocationsDetailsByScenarioIdAsync`,
    async (props: { scenarioId: number } & AsyncLoadProps, { dispatch }) => {
        const { abortSignal, scenarioId } = props
        return await baseProgressLoaderCreateAsyncThunk(
            dispatch,
            {
                ...props,
                loadingStateColor: LoadingStateColor.Blue,
                loadingScenarioComponent: LoadingScenarioComponent.PlanningGridAllocations
            },
            () => getPlanningGridAllocationsDetailsByScenarioIdAsync({ queryParams: { scenarioId: scenarioId }, signal: abortSignal })
        )
    }
);

export const loadPlanningGridResourcesByScenarioIdAsync = createAsyncThunk(
    `${ActionType.AppScenarioState}/loadPlanningGridResourcesByScenarioIdAsync`,
    async (props: { scenarioId: number } & AsyncLoadProps, { dispatch }) => {
        const { abortSignal, scenarioId } = props
        return await baseProgressLoaderCreateAsyncThunk(
            dispatch,
            {
                ...props,
                loadingStateColor: LoadingStateColor.Blue,
                loadingScenarioComponent: LoadingScenarioComponent.PlanningGridResources
            },
            () => getPlanningGridResourcesByScenarioIdAsync({ queryParams: { scenarioId: scenarioId }, signal: abortSignal })
        )
    }
);

export const loadPeriodsByScenarioIdAsync = createAsyncThunk(
    `${ActionType.AppScenarioState}/loadPeriodsByScenarioIdAsync`,
    async (props: { scenarioId: number } & AsyncLoadProps, { dispatch }) => {
        const { abortSignal, scenarioId } = props
        return await baseProgressLoaderCreateAsyncThunk(
            dispatch,
            {
                ...props,
                loadingStateColor: LoadingStateColor.Red,
                loadingScenarioComponent: LoadingScenarioComponent.Periods
            },
            () => getPeriodsByScenarioIdAsync({ queryParams: { scenarioId: scenarioId }, signal: abortSignal })
        )
    }
);


export const loadCapacitiesByScenarioIdAsync = createAsyncThunk(
    `${ActionType.AppScenarioState}/loadCapacitiesByScenarioId`,
    async (props: { scenarioId: number } & AsyncLoadProps, { dispatch }) => {
        const { abortSignal, scenarioId } = props
        return await baseProgressLoaderCreateAsyncThunk(
            dispatch,
            {
                ...props,
                loadingStateColor: LoadingStateColor.Blue,
                loadingScenarioComponent: LoadingScenarioComponent.Capacities
            },
            () => getCapacitiesByScenarioIdAsync({ queryParams: { scenarioId: scenarioId }, signal: abortSignal })
        )
    }
);


export const selectMinifiedPeriods = (state: RootState) => state.appScenarioState.minifiedPeriods


export const loadItemOperationAsync = createAsyncThunk(
    `${ActionType.AppScenarioState}/loadItemOperationAsync`,
    async (props: AsyncLoadProps, { dispatch }) => {
        const { abortSignal } = props
        return await baseProgressLoaderCreateAsyncThunk(
            dispatch,
            {
                ...props,
                loadingStateColor: LoadingStateColor.Yellow,
                loadingAppComponent: LoadingAppComponent.ItemOperation
            },
            () => getItemOperationsAsync({ signal: abortSignal })
        )
    }
)

export const loadItemResourceAsync = createAsyncThunk(
    `${ActionType.AppScenarioState}/loadItemResourceAsync`,
    async (props: AsyncLoadProps, { dispatch }) => {
        const { abortSignal } = props
        return await baseProgressLoaderCreateAsyncThunk(
            dispatch,
            {
                ...props,
                loadingStateColor: LoadingStateColor.Yellow,
                loadingAppComponent: LoadingAppComponent.ItemResource
            },
            () => getItemResourcesAsync({ signal: abortSignal })
        )
    }
)

export const loadUserDataFlatAsync = createAsyncThunk(
    `${ActionType.AppScenarioState}/loadUserDataFlatAsync`,
    async (props: AsyncLoadProps, { dispatch }) => {
        const { abortSignal } = props
        return await baseProgressLoaderCreateAsyncThunk(
            dispatch,
            {
                ...props,
                loadingStateColor: LoadingStateColor.Yellow,
                loadingAppComponent: LoadingAppComponent.UserFlatData
            },
            () => getUserDataFlatAsync({ signal: abortSignal })
        )
    }
)

export const loadDemandDataRangesAsync = createAsyncThunk(
    `${ActionType.AppScenarioState}/loadDemandDataRangesAsync`,
    async (props: AsyncLoadProps, { dispatch }) => {
        const { abortSignal } = props
        return await baseProgressLoaderCreateAsyncThunk(
            dispatch,
            {
                ...props,
                loadingStateColor: LoadingStateColor.Yellow,
                loadingAppComponent: LoadingAppComponent.DemandDataRange
            },
            () => getDemandDateRangesAsync({ signal: abortSignal })
        )
    }
)

export const loadCompleteStructureAsync = createAsyncThunk(
    `${ActionType.AppScenarioState}/loadCompleteStructureAsync`,
    async (props: AsyncLoadProps, { dispatch }) => {
        const { abortSignal } = props
        return await baseProgressLoaderCreateAsyncThunk(
            dispatch,
            {
                ...props,
                loadingStateColor: LoadingStateColor.Red,
                loadingAppComponent: LoadingAppComponent.CompleteStructures
            },
            () => getAllCompleteStructureAsync({ signal: abortSignal })
        )
    }
);

export const loadScenariosAsync = createAsyncThunk(
    `${ActionType.AppScenarioState}/loadScenariosAsync`,
    async (props: AsyncLoadProps, { dispatch }) => {
        const { abortSignal } = props
        return await baseProgressLoaderCreateAsyncThunk(
            dispatch,
            {
                ...props,
                loadingStateColor: LoadingStateColor.Yellow,
                loadingAppComponent: LoadingAppComponent.Scenarios
            },
            () => getScenariosAsync({ signal: abortSignal })
        )
    }
);
export const loadInventoryDataAsync = createAsyncThunk(
    `${ActionType.AppStockState}/loadInventoryDataAsync`,
    async (props: { scenarioId: number } & AsyncLoadProps, { dispatch }) => {
        const { scenarioId } = props
        const { data: inventoryData } = await getInventorySummaryAsync(scenarioId);
        return {
            inventoryData,

        }
    }
);
export const loadSegmentationDataAsync = createAsyncThunk(
    `${ActionType.AppStockState}/loadSegmentationDataAsync`,
    async (props: AsyncLoadProps, { dispatch }) => {
        const { data: segmentationData } = await getSegmentationAsync();
        return {
            segmentationData

        }
    }
);
//savePlanningGridAsync
export const savePlanningGridAsync = createAsyncThunk(
    `${ActionType.AppScenarioState}/savePlanningGridAsync`,
    async (props: AsyncLoadProps, { dispatch, getState }) => {
        const state: any = getState();
        const appScenarioState: AppScenarioState = state.appScenarioState;
        const selectedScenarioId = appScenarioState.selectedScenarioId;
        if (selectedScenarioId === undefined) {
            console.error("Error at 'savePlanningGridAsync' there is no scenario selected")
            return;
        }
        const planningGrids: PlanningGrid[] = []
        for (const localPlanningGrids of Object.values(appScenarioState.planningGridsDataByItemId)) {
            planningGrids.push(...localPlanningGrids.filter(x => x.isDirty === true))
        }
        const { abortSignal } = props
        await updatePlanningGridsAsync({ body: planningGrids, signal: abortSignal })
        return;
    }
);

export const saveAsScenarioAsync = createAsyncThunk(
    `${ActionType.AppScenarioState}/saveAsScenarioAsync`,
    async (props: { scenarioName: string } & AsyncLoadProps, { dispatch, getState }) => {
        const { scenarioName } = props
        const state: any = getState();
        const appScenarioState: AppScenarioState = state.appScenarioState;
        const selectedScenarioId = appScenarioState.selectedScenarioId;
        if (selectedScenarioId === undefined) {
            console.error("Error at 'saveAsScenarioAsync' there is no scenario selected")
            return;
        }
        const planningGrids: PlanningGrid[] = []
        for (const localPlanningGrids of Object.values(appScenarioState.planningGridsDataByItemId)) {
            planningGrids.push(...localPlanningGrids.filter(x => x.isDirty === true))
        }
        const { abortSignal } = props
        let scenarioId = await saveScenarioAsAsync(selectedScenarioId, { body: { planningGrids, scenarioName }, signal: abortSignal })
        await forceExecuteTaskByTypeWithBodyAsync({
            queryParams: {
                taskType: "ExecProcedure",
            },
            body: {
                order: 0,
                data: {
                    "Stored Procedure": "NTransform.fn_EvaluateSavedScenario",
                    "scenario": `${scenarioId}`
                },
            },
        })
    }
);

//Scenario
export const selectCurrentScenarioId = (state: RootState) => state.appScenarioState.selectedScenarioId
export const selectCurrentScenario = (state: RootState) => state.appScenarioState.scenarios?.find(x => x.id === state.appScenarioState.selectedScenarioId)
export const selectScenarios = (state: RootState) => state.appScenarioState.scenarios;

//PeriodType
export const selectPeriodTypeName = (state: RootState, periodTypeId: number) => state.appScenarioState.periodTypeById[periodTypeId].name
export const selectScenariosPeriodTypeNames = createSelector(
    (state: RootState) => state.appScenarioState.periodTypeById,
    selectScenarios,
    (periodTypeById, scenarios) => {
        const names: string[] = []
        for (let i = 0; i < scenarios.length; i++) {
            const scenario = scenarios[i];
            const periodType = periodTypeById[scenario.periodTypeId]
            if (periodType) {
                names.push(periodType.name)
            }
        }
        return names;
    }
)


//ComplexPeriods
export const selectComplexPeriodsLength = (state: RootState) => state.appScenarioState.complexPeriods.length
export const selectLastPeriodGroupNumber = (state: RootState) => state.appScenarioState.complexPeriods[state.appScenarioState.complexPeriods.length - 1].group
export const selectLastRankGroupNumber = (state: RootState) => state.appScenarioState.complexPeriods[state.appScenarioState.complexPeriods.length - 1].rank
export const selectComplexPeriods = (state: RootState) => state.appScenarioState.complexPeriods

//Get planning grids filtered by attributes
export const selectOrderedProductIds = (state: RootState) => state.appScenarioState.filterData.orderedItemIds

//SimplePeriods
export const selectSimplePeriodsLength = (state: RootState) => state.appScenarioState.periods.length

//SelectedProductsIds
export const selectSelectedItemsCount = (state: RootState) => state.appScenarioState.selectedItemIds.length;
export const selectPlanningChartText = (state: RootState) => state.appScenarioState.selectedItemIds.length === 1 ?
    `${t({ message: 'Produto' })} ${state.appScenarioState.itemById[state.appScenarioState.selectedItemIds[0]].itemCode}`
    :
    `${t({ message: 'Produtos Selecionados' })}: ${state.appScenarioState.selectedItemIds.length}`

export const selectSelectedProductIds = (state: RootState) => state.appScenarioState.selectedItemIds
export const localIsProductSelected = (appScenarioState: AppScenarioState, itemId: number) => appScenarioState.selectedItemIds.includes(itemId)
export const isProductSelected = createCachedSelector(
    localIsProductSelected,
    (isSelected) => isSelected,
)(
    (_state_, productId) => productId
)
export const selectIsWeekly = (state: RootState) => state.appScenarioState.isWeekly

//PlanningGridData


const localSelectPlanningGridByProductIdAndByIndex = (state: RootState, productId: number, planningGridIndex: number) => state.appScenarioState.planningGridsDataByItemId[productId][planningGridIndex]
export const selectPlanningGridByProductIdAndByIndexCached = createCachedSelector(
    localSelectPlanningGridByProductIdAndByIndex,
    (planningGridData) => planningGridData,
)(
    (_state_, itemId, planningGridIndex) => itemId + '-' + planningGridIndex
)

// (state: RootState, statusId: number) => 
const localSelectScenarioStatusName = (state: RootState, statusId: number) => state.appScenarioState.scenarioStatuses.find(x => x.id === statusId)?.name
// const localSelectScenarioStatusName = (state: RootState) => state.appScenarioState.scenarioStatuses.map(x => x.name)
export const selectScenarioStatusNameCached = createCachedSelector(
    localSelectScenarioStatusName,
    (scenarioStatus) => scenarioStatus
)(
    (_state_, scenarioStatus) => scenarioStatus
)

export const selectScenarioStatusDataCached = createSelector(
    selectScenarios,
    (state: RootState) => state.appScenarioState.scenarioStatuses,
    (scenarios, scenarioStatuses) => {
        const statusData: { scenarioStatusName: string, scenarioStatusColor: string }[] = []
        for (let i = 0; i < scenarios.length; i++) {
            const scenario = scenarios[i];
            const scenarioStatusData = scenarioStatuses.find(x => x.id === scenario.scenarioStatusId)
            if (scenarioStatusData) {
                statusData.push({ scenarioStatusName: scenarioStatusData.name, scenarioStatusColor: scenarioStatusData.color })
            }
        }
        return statusData;
    }
)




export const selectPlanningGrids = (state: RootState) => state.appScenarioState.planningGridsDataByItemId
const localSelectPlanningGridsByProductId = (state: RootState, productId: number) => state.appScenarioState.planningGridsDataByItemId[productId]
export const selectPlanningGridsByProductIdCached = createCachedSelector(
    localSelectPlanningGridsByProductId,
    (planningGridData) => planningGridData,
)(
    (_state_, productId) => productId
)

const localSelectPlanningGridFirstStockStatus = (planningGridsByItemId: Dictionary<PlanningGridData[]>, productId: number) => planningGridsByItemId[productId][0].stockStatus

export const selectPlanningGridFirstStockStatusCached = createCachedSelector(
    (appScenarioState: AppScenarioState) => appScenarioState.planningGridsDataByItemId,
    (state, productId: number) => productId,
    (planningGridsByItemId, productId: number) => localSelectPlanningGridFirstStockStatus(planningGridsByItemId, productId)
)(
    (_state_, productId) => productId
)

const localSelectPlanningGridLastStockStatus = (planningGridsByItemId: Dictionary<PlanningGridData[]>, productId: number) => planningGridsByItemId[productId][planningGridsByItemId[productId].length - 1].stockStatus
export const selectPlanningGridLastStockStatusCached = createCachedSelector(
    (appScenarioState: AppScenarioState) => appScenarioState.planningGridsDataByItemId,
    (state, productId: number) => productId,
    (planningGridsByItemId, productId: number) => localSelectPlanningGridLastStockStatus(planningGridsByItemId, productId)
)(
    (_state_, productId) => productId
)

const localFetchImage = async (itemId: number) => {
    const blob = await getItemImageByItemIdAsync(itemId);
    return URL.createObjectURL(blob);
}
export const selectItemImageCached = createCachedSelector(
    (itemId: number) => itemId,
    (itemId: number) => localFetchImage(itemId)
)(
    (itemImage) => itemImage
)

const localSelectPlanningGridFirstOpeningStock = (appScenarioState: AppScenarioState, productId: number) => appScenarioState.planningGridsDataByItemId[productId][0].openingStock
export const selectPlanningGridFirstOpeningStockCached = createCachedSelector(
    localSelectPlanningGridFirstOpeningStock,
    (openingStock) => openingStock,
)(
    (_state_, productId) => productId
)


export const selectItemById = (state: RootState, itemId: number) => state.appScenarioState.itemById[itemId]

export const selectDynamicPeriodCount = (state: RootState) => state.appScenarioState.isWeekly && state.appScenarioState.isExpandedPeriods ? selectSimplePeriodsLength(state) : selectComplexPeriodsLength(state)

const localLastScenarioId = (state: RootState) => Math.max(...state.appScenarioState.scenarios.map(x => x.id))
export const selectLastScenario = createSelector(
    (_state_: RootState) => _state_.appScenarioState,
    localLastScenarioId,
    (_appScenarioState_, lastScenarioId) => _appScenarioState_.scenarios.filter(x => x.id === lastScenarioId)[0]
)

const localLastScenarioPriorityId = (state: RootState) => Math.max(...state.appScenarioState.scenarioPriorities.map(x => x.scenarioId))
export const selectLastScenarioPriorities = createSelector(
    (_state_: RootState) => _state_.appScenarioState,
    localLastScenarioPriorityId,
    (_appScenarioState_, lastScenarioPriorityId) => _appScenarioState_.scenarioPriorities.filter(x => x.scenarioId === lastScenarioPriorityId)
)

export const selectItemsLength = createSelector(
    (state: RootState) => state.appScenarioState.filterData.orderedItemIds,
    orderedProducts => orderedProducts.length
)


export const selectMaterialFlowPlanningGridDataByItemId = (appScenarioState: AppScenarioState, itemId: number, columnIndex: number):
    PlanningGridData[] => {
    const completeStructureOrderDataByItemMestre = appScenarioState.completeStructureOrderDataByItemMaster[itemId];
    if (completeStructureOrderDataByItemMestre === undefined) return []
    const localPlanningGridData: PlanningGridData[] = []

    for (let i = 0; i < completeStructureOrderDataByItemMestre.data.length; i++) {
        const { itemId: localItemId, level } = completeStructureOrderDataByItemMestre.data[i];
        const planningGridByItemIdByColumnIndex = appScenarioState.planningGridsDataByItemId[localItemId][columnIndex]
        localPlanningGridData.push(planningGridByItemIdByColumnIndex)
    }
    return localPlanningGridData;
}

export const selectMaterialFlowItemDataByItemId = (appScenarioState: AppScenarioState, itemId: number): {
    isSelected: boolean,
    globalMinStockType: GlobalPlanningGridTargetType,
    globalTargetStockType: GlobalPlanningGridTargetType,
    planningGridStatus: StockStatusType,
    firstPlanningGridOpeningStock: number,
    minimumReorder: number,
    multipleReorder: number,
    localTargetStockValue: number,
    localMinStockValue: number,
    itemCode: string,
    materialItemId: number,
    itemDescription: string,
    isOpeningStockHighlighted: boolean,
    mpsHighlightedStatusType: HighlightedStatusType,
    openingStockHighlightedStatusType: HighlightedStatusType
}[] => {
    const completeStructureOrderDataByItemMestre = appScenarioState.completeStructureOrderDataByItemMaster[itemId];
    if (completeStructureOrderDataByItemMestre === undefined) return []
    const materialFlowData: {
        materialItemId: number,
        isSelected: boolean,
        globalMinStockType: GlobalPlanningGridTargetType,
        globalTargetStockType: GlobalPlanningGridTargetType,
        planningGridStatus: StockStatusType,
        firstPlanningGridOpeningStock: number,
        minimumReorder: number,
        multipleReorder: number,
        localTargetStockValue: number,
        localMinStockValue: number,
        itemCode: string,
        itemDescription: string,
        isOpeningStockHighlighted: boolean,
        mpsHighlightedStatusType,
        openingStockHighlightedStatusType

    }[] = []
    for (let i = 0; i < completeStructureOrderDataByItemMestre.data.length; i++) {
        const { itemId: localItemId, level } = completeStructureOrderDataByItemMestre.data[i];
        const { minimumReorder, multipleReorder, minDaysOfCover, targetDaysOfCover,
            minStock, targetStock, isOpeningStockHighlighted,
            openingStockHighlightedStatusType,
            mpsHighlightedStatusType } = appScenarioState.planningGridsDataByItemId[localItemId][0]
        const { itemCode, itemDescription, id: materialItemId } = selectItemRowDataCached(appScenarioState, localItemId)
        const { globalMinStockType, globalTargetStockType } = appScenarioState.planningGridGlobalValuesByItemId[localItemId]
        const isSelected = isProductSelected(appScenarioState, localItemId)
        const firstPlanningGridStatus = selectPlanningGridFirstStockStatusCached(appScenarioState, localItemId)
        const firstPlanningGridOpeningStock = selectPlanningGridFirstOpeningStockCached(appScenarioState, localItemId)
        let localTargetStockValue = globalTargetStockType === GlobalPlanningGridTargetType.UnitaryGlobalTargetValue ? targetStock : targetDaysOfCover;
        let localMinStockValue = globalMinStockType === GlobalPlanningGridTargetType.UnitaryGlobalTargetValue ? minStock : minDaysOfCover;

        if (materialItemId === undefined) {
            console.error(`on selectMaterialFlowItemDataByItemId, materialItemId is null`);
            throw new Error(`on selectMaterialFlowItemDataByItemId, materialItemId is null`)
        } if (firstPlanningGridOpeningStock === undefined) {
            console.error(`on selectMaterialFlowItemDataByItemId, firstPlanningGridOpeningStock is null`);
            throw new Error(`on selectMaterialFlowItemDataByItemId, firstPlanningGridOpeningStock is null`)
        } if (localMinStockValue === undefined) {
            console.error(`on selectMaterialFlowItemDataByItemId, localMinStockValue is null`);
            throw new Error(`on selectMaterialFlowItemDataByItemId, localMinStockValue is null`)
        } if (localTargetStockValue === undefined) {
            console.error(`on selectMaterialFlowItemDataByItemId, localTargetStockValue is null`);
            throw new Error(`on selectMaterialFlowItemDataByItemId, localTargetStockValue is null`)
        } if (minimumReorder === undefined) {
            console.error(`on selectMaterialFlowItemDataByItemId, minimumReorder is null`);
            throw new Error(`on selectMaterialFlowItemDataByItemId, minimumReorder is null`)
        } if (multipleReorder === undefined) {
            console.error(`on selectMaterialFlowItemDataByItemId, multipleReorder is null`);
            throw new Error(`on selectMaterialFlowItemDataByItemId, multipleReorder is null`)
        }
        materialFlowData.push({
            materialItemId: materialItemId,
            firstPlanningGridOpeningStock: firstPlanningGridOpeningStock,
            globalMinStockType: globalMinStockType,
            globalTargetStockType: globalTargetStockType,
            isSelected: isSelected,
            itemCode: itemCode,
            itemDescription: itemDescription,
            localMinStockValue: localMinStockValue,
            localTargetStockValue: localTargetStockValue,
            minimumReorder: minimumReorder,
            multipleReorder: multipleReorder,
            planningGridStatus: firstPlanningGridStatus,
            isOpeningStockHighlighted: isOpeningStockHighlighted,
            mpsHighlightedStatusType: mpsHighlightedStatusType,
            openingStockHighlightedStatusType: openingStockHighlightedStatusType
        }
        )
    }
    return materialFlowData;
}

export const selectItemRowData = (appScenarioState: AppScenarioState, itemIndex: number): {
    orderedItemId: number,
    isSelected: boolean,
    globalMinStockType: GlobalPlanningGridTargetType,
    globalTargetStockType: GlobalPlanningGridTargetType,
    planningGridStatus: StockStatusType,
    firstPlanningGridOpeningStock: number,
    minimumReorder: number,
    multipleReorder: number,
    localTargetStockValue: number,
    localMinStockValue: number,
    itemCode: string,
    itemDescription: string
} => {
    const orderedItemId = appScenarioState.filterData.orderedItemIds[itemIndex];
    const isSelected = isProductSelected(appScenarioState, orderedItemId)
    const { globalMinStockType, globalTargetStockType } = appScenarioState.planningGridGlobalValuesByItemId[orderedItemId]
    // const lastPlanningGridStatus = selectPlanningGridLastStockStatusCached(appScenarioState, orderedItemId)
    const firstPlanningGridStatus = selectPlanningGridFirstStockStatusCached(appScenarioState, orderedItemId)
    const firstPlanningGridOpeningStock = selectPlanningGridFirstOpeningStockCached(appScenarioState, orderedItemId)
    const { minimumReorder, multipleReorder, minDaysOfCover, targetDaysOfCover, minStock, targetStock } = appScenarioState.planningGridsDataByItemId[orderedItemId][0]
    const { itemCode, itemDescription } = selectItemRowDataCached(appScenarioState, orderedItemId)
    let localTargetStockValue = globalTargetStockType === GlobalPlanningGridTargetType.UnitaryGlobalTargetValue ? targetStock : targetDaysOfCover;
    let localMinStockValue = globalMinStockType === GlobalPlanningGridTargetType.UnitaryGlobalTargetValue ? minStock : minDaysOfCover;

    if (firstPlanningGridOpeningStock === undefined) {
        console.error(`on selectItemRowData, firstPlanningGridOpeningStock is null`);
        throw new Error(`on selectItemRowData, firstPlanningGridOpeningStock is null`)
    } if (minimumReorder === undefined) {
        console.error(`on selectItemRowData, minimumReorder is null`);
        throw new Error(`on selectItemRowData, minimumReorder is null`)
    } if (multipleReorder === undefined) {
        console.error(`on selectItemRowData, multipleReorder is null`);
        throw new Error(`on selectItemRowData, multipleReorder is null`)
    } if (localTargetStockValue === undefined) {
        console.error(`on selectItemRowData, localTargetStockValue is null`);
        throw new Error(`on selectItemRowData, localTargetStockValue is null`)
    } if (localMinStockValue === undefined) {
        console.error(`on selectItemRowData, localMinStockValue is null`);
        throw new Error(`on selectItemRowData, localMinStockValue is null`)
    }
    return {
        orderedItemId: orderedItemId,
        isSelected: isSelected,
        globalMinStockType: globalMinStockType,
        globalTargetStockType: globalTargetStockType,
        planningGridStatus: firstPlanningGridStatus,
        firstPlanningGridOpeningStock: firstPlanningGridOpeningStock,
        minimumReorder: minimumReorder,
        multipleReorder: multipleReorder,
        localTargetStockValue: localTargetStockValue,
        localMinStockValue: localMinStockValue,
        itemCode: itemCode,
        itemDescription: itemDescription,
    }
}

export const selectItemRowDataCached = createCachedSelector(
    (appScenarioState: AppScenarioState, itemId: number) => appScenarioState.itemById[itemId],
    (product) => product,
)(
    (_state_, itemId) => itemId
)


//Used for planningChart 
export const selectMontlyPlanningGrids = createSelector(
    selectPlanningGrids,
    selectComplexPeriods,
    (planningGrids, complexPeriods) => calculateWeeklyToMontlyPlanningGrids(planningGrids, complexPeriods)
)

export const selectItemCodeSearchTree = createSelector(
    (_state_: RootState) => _state_.appScenarioState.itemById,
    (_state_: RootState) => _state_.appScenarioState.itemsIdsWithPlanningGrid,
    (itemById, itemsIdsWithPlanningGrid) => createItemCodeSearchTree(itemById, itemsIdsWithPlanningGrid)
)

export const selectItemLevelSearchTree = createSelector(
    (_state_: RootState) => _state_.appScenarioState.filterData.itemIdsByLevel,
    (itemIdsByLevel) => createItemLevelSearchTree(itemIdsByLevel)
)

//Sum all planningGrids based on selectedProductIndexes
const localCalculateSummedSelectedPlanningGrids = (selectedProductIds: number[], simplePeriodLenght: number, planningGridsByItemId: Dictionary<PlanningGridData[]>) => {
    // let selectedProductIds: number[] = selectSelectedProductIds(state)

    let selectedPlanningGrids: PlanningChartData[] = []
    for (let i = 0; i < simplePeriodLenght; i++) {
        selectedPlanningGrids.push({
            closingStock: 0,
            openingStock: 0,
            orders: 0,
            demand: 0,
            minStock: 0,
            mps: 0,
            targetStock: 0,
            maxStock: 0,
            safetyStock: 0,
            locked: true,
            stockStatus: StockStatusType.Shortage
        })
    }

    for (let i = 0; i < selectedProductIds.length; i++) {
        const itemId = selectedProductIds[i];
        let localPlanningGrids = planningGridsByItemId[itemId];
        if (localPlanningGrids) {
            for (let k = 0; k < localPlanningGrids.length; k++) {
                const planningGrid = localPlanningGrids[k];
                selectedPlanningGrids[k].closingStock += planningGrid.closingStock ?? 0;
                selectedPlanningGrids[k].openingStock += planningGrid.openingStock ?? 0;
                selectedPlanningGrids[k].orders += planningGrid.orders ?? 0;
                selectedPlanningGrids[k].demand += planningGrid.demand ?? 0;
                selectedPlanningGrids[k].minStock += planningGrid.minStock ?? 0;
                selectedPlanningGrids[k].mps += planningGrid.mps ?? 0;
                selectedPlanningGrids[k].targetStock += planningGrid.targetStock ?? 0;
                selectedPlanningGrids[k].maxStock += planningGrid.maxStock ?? 0;
                selectedPlanningGrids[k].safetyStock += planningGrid.safetyStock ?? 0;
                selectedPlanningGrids[k].stockStatus = calculateChartStockStatusType(selectedPlanningGrids[k]);
            }
        }
        else {
            console.error(`productId: ${itemId} not found in planningGrids dictionary`)
        }
    }
    return selectedPlanningGrids;
};

export const calculateSummedSelectedPlanningGrids = createSelector(
    selectSelectedProductIds,
    selectSimplePeriodsLength,
    selectPlanningGrids,
    (selectedProductsIds, simplePeriodLenght, planningGrids) => localCalculateSummedSelectedPlanningGrids(selectedProductsIds, simplePeriodLenght, planningGrids)
)


const localFilterPlanningGridsData = (planningGrids: Dictionary<PlanningGridData[]>, filteredByAttributes: number[]) => {
    if (filteredByAttributes.length === 0)
        return planningGrids;

    const filteredPlanningGrids: Dictionary<PlanningGridData[]> = {};
    for (let i = 0; i < filteredByAttributes.length; i++) {
        const productId = filteredByAttributes[i];
        if (planningGrids[productId]) {
            filteredPlanningGrids[productId] = planningGrids[productId];
        }
    }
    return filteredPlanningGrids;
}

export const selectFilteredWeeklyPlanningGrids = createSelector(
    selectPlanningGrids,
    selectOrderedProductIds,
    (planningGrids, orderedProducts) => localFilterPlanningGridsData(planningGrids, orderedProducts)
)

export const selectFilteredMontlyPlanningGrids = createSelector(
    selectMontlyPlanningGrids,
    selectOrderedProductIds,
    (montlyPlanningGrids, orderedProducts) => localFilterPlanningGridsData(montlyPlanningGrids, orderedProducts)
)

export const selectPlanningGridsLocks = (state: RootState): boolean[] => {
    const selectedProductIds = selectSelectedProductIds(state)
    const simplePeriodLenght = selectSimplePeriodsLength(state)
    const planningGrids = selectPlanningGrids(state)

    if (selectedProductIds.length > 1) {
        return Array(simplePeriodLenght).fill(false)
    }
    const singleSelectedProductId = selectedProductIds[0];
    let locks: boolean[] = []
    for (let i = 0; i < simplePeriodLenght; i++) {
        let localPlanningGrid = planningGrids[singleSelectedProductId][i];
        if (localPlanningGrid.locked === undefined) {
            console.error(`on updatePlanningPageByUnitOfTime, localPlanningGrid.locked is null`);
            throw new Error(`on updatePlanningPageByUnitOfTime, localPlanningGrid.locked is null`);
        } locks.push(localPlanningGrid.locked);
    }
    return locks;
}

export const selectCachedAvailableScenarios = createSelector(
    (_state_: RootState) => _state_.appScenarioState.scenarios,
    (_state_: RootState) => _state_.appScenarioState.scenarioStatuses.filter(x => x.id === ScenarioStatusCodeById.Planejado || x.id === ScenarioStatusCodeById.Atual),
    (scenarios, plannedScenarioStatuses) => scenarios.filter(x => x.scenarioStatusId === plannedScenarioStatuses[0]?.id || x.scenarioStatusId === plannedScenarioStatuses[1]?.id)
)
export const selectAvailableScenarios = (state: RootState) => {
    const scenarios = state.appScenarioState.scenarios;
    const scenarioStatuses = state.appScenarioState.scenarioStatuses.filter(x => x.id === ScenarioStatusCodeById.Planejado || x.id === ScenarioStatusCodeById.Atual);
    return scenarios.filter(x => x.scenarioStatusId === scenarioStatuses[0]?.id || x.scenarioStatusId === scenarioStatuses[1]?.id);
}

export * from './reducers'
export default appScenarioSlice.reducer;