import { t } from '@lingui/macro';
import { toast, ToastPromiseParams } from 'react-toastify';

interface Notification {
    message: string,
    timer: number,
    action: () => void
};

export const notifyResponse = (data) => {
    if (data?.type)
        switch (data.type) {
            case "success":
                notifySuccess({ message: data.message });
                break;
            case "error":
                notifyError({ message: data.message });
                break;
            default:

        }
}

export const notifySuccess = (notification: Partial<Notification>) => {
    return toast.success(notification.message, {
        onClose: notification.action,
        position: "top-right",
        autoClose: notification.timer ?? 3000,
        hideProgressBar: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
}
export const notifyMessage = (notification: Partial<Notification>) => {
    return toast.info(notification.message, {
        onClose: notification.action,
        position: "top-right",
        autoClose: notification.timer ?? 3000,
        hideProgressBar: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
}

export const notifyInformation = (notification: Partial<Notification>) => {
    return toast.info(notification.message, {
        onClose: notification.action,
        position: "top-center",
        autoClose: notification.timer ?? 3000,
        hideProgressBar: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
}

export const notifyLoading = <T>(notification: Partial<Notification>) => {
    return toast(notification.message, {
        onClose: notification.action,
        position: "top-right",
        autoClose: notification.timer ?? 3000,
        hideProgressBar: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
}
export const notifyPromise = <T>(action: Promise<T>, notification: ToastPromiseParams) => {
    return toast.promise(
        action,
        {
            pending: t`${notification.pending}`,
            success: t`${notification.success}`,
            error: t`${notification.error}`
        }
    )
}
//TODO GAMBIARRA
export const notifyPromiseProcedure = <T>(action: Promise<T>, notification: ToastPromiseParams) => {
    return toast.promise(
        action,
        {
            pending: t`${notification.pending}`,
            success: {
                render({ data }) {
                    if (data) {
                        return `A procedure foi cancelada!`
                    } else {
                        return `Não existe procedure na fila!`
                    }
                },
                // other options
                icon: false,
            },
            error: t`${notification.error}`
        }
    )
}

export const notifyWarning = (notification: Partial<Notification>) => {
    return toast.warning(notification.message, {
        onClose: notification.action,
        position: "top-right",
        autoClose: notification.timer ?? 3000,
        hideProgressBar: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
}

export const notifyError = (notification: Partial<Notification>) => {
    return toast.error(notification.message, {
        onClose: notification.action,
        position: "top-right",
        autoClose: notification.timer ?? 3000,
        hideProgressBar: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
}