import { deleteAsync, getAsync, postAsync, putAsync } from '..';

export function getPowerBIAsync(): Promise<any> {
    return getAsync<any>({ url: `/powerbi/` })
}
export function getPowerBIByIdAsync(id: any): Promise<any> {
    return getAsync<any>({ url: `/powerbi/${id}` })
}

export function PostPowerBIAsync(powerbi: any): Promise<any> {
    return postAsync<any>({ url: "/powerbi", body: { ...powerbi } });
}

export function PutPowerBIAsync(powerbi: any): Promise<any> {
    return putAsync<any>({ url: "/powerbi", body: { ...powerbi } });
}

export function deletePowerBIAsync(powerbiId: any): Promise<any> {
    return deleteAsync<any>({ url: `/powerbi/${powerbiId}` });
} 