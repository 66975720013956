import Tippy from "@tippyjs/react";

interface ToolTipProps {
  label: string;
  tooltipText: string;
}

export function ToolTipComponentOnlyText({ label, tooltipText }: ToolTipProps) {
  return (
    <div className="flex gap-2 justify-between items-center h-4">
      <Tippy
        delay={[200, 50]}
        animation="fade"
        offset={[10, 10]}
        content={
          <span className="bg-gray-200 border border-gray-600 rounded-md font-semibold text-xs p-1 w-fit ">
            {tooltipText}
          </span>
        }
      >
        <label className="text-xs cursor-help">{label}</label>
      </Tippy>
    </div>
  );
}
