import { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setShowingItemsReducer } from "redux/AppStockState";
import { SegmentationDataType, StockInventoryData } from "../types";
import {
    sortArrayByKey,
    sortArrayByTypeComparatorComplexity,
    sortArrayByKeyWithComparatorComplexityPrioritySummary,
    sortArrayByTypeComparatorUncertainty,
    sortArrayByKeyWithComparatorUncertaintyPrioritySummary,
    sortArrayByTypeComparatorVolume,
    sortArrayByKeyWithComparatorVolumePrioritySummary,
} from "../utils";
import { useAppSelector } from "redux/hooks";
import useDataTypesHandler from "./useDataTypesHandler";
import { stockPolicyAxesTypeToLanguage } from "locales/helper";
import { StockPolicyAxesType } from "api/data/enums";

interface UseCreateLinesHandlerProps {
    props: {
        segmentationData: {
            data: SegmentationDataType[];
        };
        stockEditableTableValidation: {
            isEditable: boolean;
        };
        secondFilterItems: {
            segmentationSecondFilter: SegmentationDataType[];
            stockSummarySecondFilter: StockInventoryData[];
        };
    };
}

const useCreateLinesHandler = ({ props }: UseCreateLinesHandlerProps) => {
    const { segmentationData, stockEditableTableValidation, secondFilterItems } =
        props;
    const { types, complexityTypes, uncertaintyTypes, volumeTypes } =
        useDataTypesHandler();
    const { stockPolicyAxes } = useAppSelector((state) => state.appStockState);
    const dispatch = useDispatch();

    const [showingItems, setShowingItems] = useState<{
        segmentationShowingItems: {
            items: SegmentationDataType[][];
            type: any;
            xAxisType: any;
        };
        summaryShowingItems: {
            items: StockInventoryData[][];
            type: any;
            xAxisType: any;
        };
    }>();

    //  CRIAR GRUPOS (QUE SERÃO LINHAS) COM O NÚMERO DE LINHAS DIFERENTES (EIXO Y)
    const createLines = useCallback(() => {
        let xAxisType: any[];
        switch (stockPolicyAxes.xAxis()) {
            case stockPolicyAxesTypeToLanguage(StockPolicyAxesType.Uncertainty):
                xAxisType = sortArrayByKey(types.Uncertainty, "priority");
                break;
            case stockPolicyAxesTypeToLanguage(StockPolicyAxesType.Volume):
                xAxisType = sortArrayByKey(types.Volume, "priority");
                break;
            case stockPolicyAxesTypeToLanguage(StockPolicyAxesType.Complexity):
                xAxisType = sortArrayByKey(types.Complexity, "priority");
                break;
        }

        const groupItems: any = [];
        const groupSummaryItems: any = [];
        switch (stockPolicyAxes.yAxis()) {
            case stockPolicyAxesTypeToLanguage(StockPolicyAxesType.Volume):
                volumeTypes.map((type) => {
                    const groupedLineItems: any = [];
                    const groupedSummaryItems: any = [];
                    if (stockPolicyAxes.xAxis() === stockPolicyAxesTypeToLanguage(StockPolicyAxesType.Complexity)) {
                        groupedLineItems.push(
                            sortArrayByTypeComparatorComplexity(
                                secondFilterItems.segmentationSecondFilter.filter(
                                    (item) => item.classificationVolume.id === type.id
                                )
                            )
                        );
                        groupedSummaryItems.push(
                            sortArrayByKeyWithComparatorComplexityPrioritySummary(
                                secondFilterItems.stockSummarySecondFilter.filter(
                                    (item) => item.classificationVolumeId === type.id
                                )
                            )
                        );
                    } else {
                        groupedLineItems.push(
                            sortArrayByTypeComparatorUncertainty(
                                secondFilterItems.segmentationSecondFilter.filter(
                                    (item) => item.classificationVolume.id === type.id
                                )
                            )
                        );
                        groupedSummaryItems.push(
                            sortArrayByKeyWithComparatorUncertaintyPrioritySummary(
                                secondFilterItems.stockSummarySecondFilter.filter(
                                    (item) => item.classificationVolumeId === type.id
                                )
                            )
                        );
                    }
                    groupItems.push({ type: type, items: groupedLineItems, xAxisType });
                    groupSummaryItems.push({ type: type, items: groupedSummaryItems, xAxisType, });
                });
                break;
            case stockPolicyAxesTypeToLanguage(StockPolicyAxesType.Complexity):
                complexityTypes.map((type) => {
                    const groupedLineItems: any = [];
                    const groupedSummaryItems: any = [];

                    if (stockPolicyAxes.xAxis() === stockPolicyAxesTypeToLanguage(StockPolicyAxesType.Volume)) {
                        groupedLineItems.push(
                            sortArrayByTypeComparatorVolume(
                                secondFilterItems.segmentationSecondFilter.filter(
                                    (item) => item.classificationComplexity.id === type.id
                                )
                            )
                        );
                        groupedSummaryItems.push(
                            sortArrayByKeyWithComparatorVolumePrioritySummary(
                                secondFilterItems.stockSummarySecondFilter.filter(
                                    (item) => item.classificationComplexityId === type.id
                                )
                            )
                        );
                    } else {
                        groupedLineItems.push(
                            sortArrayByTypeComparatorUncertainty(
                                secondFilterItems.segmentationSecondFilter.filter(
                                    (item) => item.classificationComplexity.id === type.id
                                )
                            )
                        );
                        groupedSummaryItems.push(
                            sortArrayByKeyWithComparatorUncertaintyPrioritySummary(
                                secondFilterItems.stockSummarySecondFilter.filter(
                                    (item) => item.classificationComplexityId === type.id
                                )
                            )
                        );
                    }
                    groupItems.push({ type: type, items: groupedLineItems, xAxisType });
                    groupSummaryItems.push({ type: type, items: groupedSummaryItems, xAxisType, });
                });
                break;
            case stockPolicyAxesTypeToLanguage(StockPolicyAxesType.Uncertainty):
                uncertaintyTypes.map((type) => {
                    const groupedLineItems: any = [];
                    const groupedSummaryItems: any = [];

                    if (stockPolicyAxes.xAxis() === stockPolicyAxesTypeToLanguage(StockPolicyAxesType.Volume)) {
                        groupedLineItems.push(
                            sortArrayByTypeComparatorVolume(
                                secondFilterItems.segmentationSecondFilter.filter(
                                    (item) => item.classificationUncertainty.id === type.id
                                )
                            )
                        );
                        groupedSummaryItems.push(
                            sortArrayByKeyWithComparatorVolumePrioritySummary(
                                secondFilterItems.stockSummarySecondFilter.filter(
                                    (item) => item.classificationUncertaintyId === type.id
                                )
                            )
                        );
                    } else {
                        groupedLineItems.push(
                            sortArrayByTypeComparatorComplexity(
                                secondFilterItems.segmentationSecondFilter.filter(
                                    (item) => item.classificationUncertainty.id === type.id
                                )
                            )
                        );
                        groupedSummaryItems.push(
                            sortArrayByKeyWithComparatorUncertaintyPrioritySummary(
                                secondFilterItems.stockSummarySecondFilter.filter(
                                    (item) => item.classificationUncertaintyId === type.id
                                )
                            )
                        );
                    }

                    groupItems.push({ type: type, items: groupedLineItems, xAxisType });
                    groupSummaryItems.push({ type: type, items: groupedSummaryItems, xAxisType, });
                });
                break;
        }
        setShowingItems({
            segmentationShowingItems: groupItems,
            summaryShowingItems: groupSummaryItems,
        });

        dispatch(
            setShowingItemsReducer({
                segmentationShowingItems: groupItems,
                summaryShowingItems: groupSummaryItems,
            })
        );
    }, [secondFilterItems, stockPolicyAxes]);

    useEffect(() => {
        createLines();
    }, [
        createLines,
        secondFilterItems,
        stockPolicyAxes,
        complexityTypes,
        stockPolicyAxes.xAxis,
        stockPolicyAxes.yAxis,
    ]);

    return { showingItems };
};

export default useCreateLinesHandler;
