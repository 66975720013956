import React from "react";
import { t, Trans } from "@lingui/macro";
import Modal from '@mui/material/Modal';
import Box from "@mui/material/Box";
import { Button, FormControlLabel, MenuItem, Select, Switch, TextField } from "@mui/material";
import { PostPowerBIAsync, PostTableAsync, PutPowerBIAsync, PutTableAsync } from "api/data";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};
export default function ManageTable({hidden, close, powerBiEdition, refreshData}) {
    const [powerBI, setPowerBi] = React.useState<any>({
        title: "",
        description: "",
        link: "",
        order: 1,
        active: true,
        id: 0
    });
    const [loading, setLoading] = React.useState(false);
    React.useEffect(() => {
        setPowerBi(powerBiEdition)
    }, [powerBiEdition])
    const saveData = async () => {
        setLoading(true)
        try {
            var res = powerBI.id === 0 ? await PostPowerBIAsync(powerBI) : await PutPowerBIAsync(powerBI)
            if(res.type === "success") {
                setLoading(false)
                refreshData()
                close()
            }
        }
        catch {
            setLoading(false)
        }
    }
    return(
        <React.Fragment>
        <Modal
            open={!hidden}
            onClose={close}
        >
            <Box sx={style}>
                <div style={{ display: "flex", flexDirection: "column", gap: "20px"}}>
                    <h3 style={{textAlign: "center", textTransform: "uppercase", fontWeight: "bold", padding: "10px"}}>{powerBI.id === 0 ? "Criar" : "Editar"} Relatório</h3>
                    <TextField label="Título" value={powerBI.title} onChange={({ target: { value } }) => setPowerBi(prev => ({...prev, title: value}))} />
                        <TextField label="Descrição" value={powerBI.description} onChange={({ target: { value } }) => setPowerBi(prev => ({ ...prev, description: value}))} />
                        <TextField label="Link" value={powerBI.link} onChange={({ target: { value } }) => setPowerBi(prev => ({ ...prev, link: value}))} />
                        <TextField label="Ordem" value={powerBI.order} onChange={({ target: { value } }) => setPowerBi(prev => ({ ...prev, order: value}))} />
                        {powerBI.id !== 0 && <FormControlLabel
                            control={<Switch aria-label="Teste" checked={powerBI.active} onChange={({ target: { checked } }) => setPowerBi(prev => ({ ...prev, active: checked }))} />}
                            label="Ativo"
                        />}
                    <Button variant="contained" onClick={saveData} disabled={loading}>
                        Salvar
                    </Button>
                    <Button variant="contained" color="error" onClick={close} disabled={loading}>
                        Cancelar
                    </Button>
                </div>
            </Box>
        </Modal>
        </React.Fragment>
        

    )
}
