import Tippy from "@tippyjs/react";


interface ToolTipProps {
  tooltipText: string;
  children: React.ReactNode
}

export function TippyComponent({ tooltipText, children }: ToolTipProps) {
  return (
    <div className="flex gap-2 justify-between items-center ">
      <Tippy
        delay={[100, null]}
        animation="fade"
        offset={[10, 10]}
        maxWidth={1000}
        content={
          <span className="bg-yellow-500 border border-gray-600 rounded-md font-semibold text-xs p-1 w-fit ">
            {tooltipText}
          </span>
        }
      >
        <label className="text-sm">
          {" "}
          {children}
        </label>
      </Tippy>
    </div>
  );
}
