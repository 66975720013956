import DropdownTreeSelect, {
    TreeNode,
    TreeNodeProps,
} from "react-dropdown-tree-select";
import { applyCriterias, filterBy } from "redux/AppScenarioState";
import { FilterCriteria } from "redux/AppScenarioState/FilterCriteria/types";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { RootState } from "redux/store";

interface Props {
    filterCriteria: FilterCriteria;
    selector: (state: RootState) => TreeNodeProps;
    placeholder?: string | undefined;
}

function DropDownTreeSelectorContainer(props: Props) {
    const { filterCriteria, selector, placeholder } = props;


    const dispatch = useAppDispatch();

    const onChangeSearch = (currentNode, selectedNodes: TreeNode[]) => {
        dispatch(
            filterBy({
                filterCriteria: filterCriteria,
                searchList: selectedNodes.map((node) => {
                    return { label: node.label, id: node.value };
                }),
            })
        );
        dispatch(applyCriterias());
    };
    const searchTree = useAppSelector(selector);

    return (
        <DropdownTreeSelect
            className="min-w-fit"
            inlineSearchInput={true}
            texts={{ placeholder: placeholder }}
            showDropdown={"default"}
            data={searchTree}
            keepTreeOnSearch={true}
            keepChildrenOnSearch={true}
            onChange={onChangeSearch}
            showPartiallySelected
        />
    );
}

export default DropDownTreeSelectorContainer;
