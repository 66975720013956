import { StockInventoryData } from "../types";

export function aggregateDataComplexityData(data: StockInventoryData[]) {
  const distinctConcatenatedIds = [
    ...new Set(
      data.map(
        (obj) =>
          `${obj.classificationUncertaintyId}-${obj.classificationVolumeId}`
      )
    ),
  ];
  //console.log(distinctConcatenatedIds);

  const result = distinctConcatenatedIds.map((concatenatedId) => {
    const filteredObjects = data.filter(
      (obj) =>
        `${obj.classificationUncertaintyId}-${obj.classificationVolumeId}` ===
        concatenatedId
    );
    //console.log(filteredObjects);

    const totalCycleStockValue = filteredObjects.reduce(
      (sum, obj) => sum + obj.cycleStockValue,
      0
    );
    //console.log(totalCycleStockValue);

    const weightedSumSafetyStockDays = filteredObjects.reduce(
      (sum, obj) =>
        sum +
        obj.cycleStockValue *
          (isNaN(obj.safetyStockDays) ? 0 : obj.safetyStockDays),
      0
    );
    //console.log(weightedSumSafetyStockDays);
    const weightedAvgSafetyStockDays =
      weightedSumSafetyStockDays / totalCycleStockValue;
    //console.log(weightedAvgSafetyStockDays);

    const weightedSumCycleStockDays = filteredObjects.reduce(
      (sum, obj) =>
        sum +
        obj.cycleStockValue *
          (isNaN(obj.cycleStockDays) ? 0 : obj.cycleStockDays),
      0
    );
    //console.log(weightedSumCycleStockDays);

    const weightedAvgCycleStockDays =
      weightedSumCycleStockDays / totalCycleStockValue;

    //console.log(weightedAvgCycleStockDays);

    const weightedSumServiceLevelPerc = filteredObjects.reduce(
      (sum, obj) =>
        sum +
        obj.cycleStockValue *
          (isNaN(obj.serviceLevelPerc) ? 0 : obj.serviceLevelPerc),
      0
    );
    //console.log(weightedSumServiceLevelPerc);
    const weightedAvgServiceLevelPerc =
      weightedSumServiceLevelPerc / totalCycleStockValue;
    //console.log(weightedAvgServiceLevelPerc);

    const totalItensQuantity = filteredObjects.reduce(
      (sum, obj) => sum + obj.itensQuantity,
      0
    );
    //console.log(totalItensQuantity);

    const totalSafetyStockValue = filteredObjects.reduce(
      (sum, obj) => sum + obj.safetyStockValue,
      0
    );
    //console.log(totalSafetyStockValue);

    // Extracting the first object to include all other keys from the original object
    const {
      classificationVolumeId,
      classificationComplexityId,
      classificationComplexity,
      classificationComplexityPriority,
      classificationVolume,
      classificationVolumePriority,
      classificationUncertainty,
      classificationUncertaintyId,
      classificationUncertaintyPriority,
      color,
      company,
      companyId,
    } = filteredObjects[0];
    return {
      classificationComplexity: "Todos",
      classificationComplexityId: 0,
      classificationComplexityPriority: 0,
      classificationVolume,
      classificationVolumeId,
      classificationVolumePriority,
      classificationUncertainty,
      classificationUncertaintyId,
      classificationUncertaintyPriority,
      serviceLevelPerc: weightedAvgServiceLevelPerc,
      company,
      companyId,
      cycleStockDays: weightedAvgCycleStockDays,
      cycleStockValue: totalCycleStockValue,
      itensQuantity: totalItensQuantity,
      safetyStockDays: weightedAvgSafetyStockDays,
      safetyStockValue: totalSafetyStockValue,
      color,
    };
  });

  return result;
}
