import { ItemAttributesBundleData, ItemAttributesCustomBundle } from "api/data/types";
import { ItemAttributeData3, ItemData } from "redux/AppScenarioState/dataTypes";
import { createAttributesFilter, hexToRGBA } from "redux/AppScenarioState/helpers";
import { AppScenarioState } from "redux/AppScenarioState/types";
import { Dictionary } from "utils/types";
import { initializeAttributes } from ".";

//redux
var randomColor = require('randomcolor');

//TODO backend foi modificado, adaptar para novo formato
export const loadItemAttributeBundleModule = (state: AppScenarioState, payload: ItemAttributesBundleData) => {
    const productAttributes = initializeAttributes(payload)

    //itemAttribute
    let attributeToColor: Dictionary<ItemAttributeData3> = productAttributes.itemAttribute3

    //Only for attribute3
    for (let [attKey, attValue] of Object.entries(attributeToColor)) {
        state.colorByAttributeId[attKey] = hexToRGBA(attValue.colour)
    }

    state.itemAttributes = productAttributes;
    state.filterData.itemsIdsByAttributeId = createAttributesFilter(state.itemsIdsWithPlanningGrid, state.itemById, state.itemAttributes)

    //itemAttribute
    let productIdsByAttributeId: Dictionary<number[]> = state.filterData.itemsIdsByAttributeId.ItemAttribute3
    if (Object.keys(productIdsByAttributeId).length === 0) {
        console.error(`selectItemAttributeToGroupModule does not have any keys to group setupTime, all itens will be considered from the same ItemAttribute`)
        productIdsByAttributeId = {
            '0': Object.keys(state.itemById).map(Number)
        }
    }
    for (const key in productIdsByAttributeId) {
        const productIds: number[] = productIdsByAttributeId[key];
        const productCount = productIds.length;

        const itemColor = randomColor({
            count: productCount,
            hue: 'random',
            format: 'rgba',
            alpha: 1
        });

        for (let i = 0; i < productIds.length; i++) {
            const productId = productIds[i];
            state.colorByProductId[productId] = itemColor[i]
        }
    }
}


export const selectItemAttributeToGroupModule = (productIdByAttributes: ItemAttributesCustomBundle<number[]>): Dictionary<number[]> => {
    return productIdByAttributes.ItemAttribute3;
}

export const selectItemAttributeIdModule = (itemById: ItemData): number => {
    return itemById.itemAttribute3Id ?? 0;
}