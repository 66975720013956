import React, { useEffect, useState } from 'react';
import { Box, InputLabel, MenuItem, Paper, Popover, Select, TextField, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { getTableEditionLogAsync } from 'api/data';
import LogPopoverItem from './LogPopoverItem';
export default function LogPopover(props: any) {
    const [users, setUsers] = useState<any>([]);
    const [usersFilter, setUsersFilter] = useState<any>([]);
    const [fields, setFields] = useState<any>([]);
    const [fieldsFilter, setFieldsFilter] = useState<any>([]);
    const [dataFilter, setDataFilter] = useState<any>(null);
    const [data, setData] = useState<any>([])
    const [dataAux, setDataAux] = useState<any>([])
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const handleFilter = ({ target }) => {
        var usersFilterCopy = usersFilter,
            fieldsFilterCopy = fieldsFilter,
            dataFilterCopy = dataFilter
        if (target.name === "user") {
            setUsersFilter(target.value);
            usersFilterCopy = target.value;
        } else if (target.name === "column") {
            fieldsFilterCopy = target.value
            setFieldsFilter(target.value)
        } else if (target.name === "date") {
            dataFilterCopy = target.value
            setDataFilter(target.value)
        }
        let dataAuxCopy = JSON.parse(JSON.stringify(data));
        dataAuxCopy = dataAuxCopy.map(row => {
            row.rows = row.rows.filter(f => {
                if (usersFilterCopy.length > 0) {
                    if (!usersFilterCopy.includes(f.userName)) return false;
                }
                if (fieldsFilterCopy.length > 0) {
                    if (!fieldsFilterCopy.includes(f.columnName)) return false;
                }
                if (dataFilterCopy != null) {
                    return f.date.substr(0, 10) === dataFilterCopy;
                }
                return true;
            })
            return row
        }).filter(a => a.rows.length > 0);
        setDataAux(dataAuxCopy);
    }
    const refreshData = () => {
        setLoading(true);
        getTableEditionLogAsync(id).then((res) => {
            if (res.type === "success" && Array.isArray(res.data) && res.data.length > 0) {
                var newData: any = [];
                const today = new Date();
                const formattedToday = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();
                const formattedyesterday = today.getDate() - 1 + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();
                setUsers([...new Set(res.data.sort((a, b) => a.userName > b.userName ? 1 : -1).map(a => a.userName))])
                setFields([...new Set(res.data.sort((a, b) => a.columnName > b.columnName ? 1 : -1).map(a => a.columnName))])
                setFieldsFilter([])
                setUsersFilter([])
                setDataFilter(null)
                res.data.sort((a, b) => new Date(a.date) > new Date(b.date) ? 1 : -1).map(row => {
                    var logDate = new Date(row.date)
                    const formattedDate = logDate.getDate() + '/' + (logDate.getMonth() + 1) + '/' + logDate.getFullYear()
                    const timeDifference = today.getTime() - logDate.getTime();
                    const dayDifference = timeDifference / (1000 * 3600 * 24);
                    if (formattedDate === formattedToday) {
                        if (newData.filter(a => a.label === "Hoje").length === 0) {
                            newData.push({ label: "Hoje", rows: [row], order: 0 })
                        } else {
                            newData.filter(a => a.label === "Hoje")[0].rows.push(row);
                        }
                    } else if (formattedyesterday === formattedDate) {
                        if (newData.filter(a => a.label === "Ontem").length === 0) {
                            newData.push({ label: "Ontem", rows: [row], order: 1 })
                        } else {
                            newData.filter(a => a.label === "Ontem")[0].rows.push(row);
                        }
                    } else if (dayDifference <= 7) {
                        if (newData.filter(a => a.label === "Última Semana").length === 0) {
                            newData.push({ label: "Última Semana", rows: [row], order: 2 })
                        } else {
                            newData.filter(a => a.label === "Última Semana")[0].rows.push(row);
                        }
                    } else if (dayDifference <= 30) {
                        if (newData.filter(a => a.label === "Último Mês").length === 0) {
                            newData.push({ label: "Último Mês", rows: [row], order: 3 })
                        } else {
                            newData.filter(a => a.label === "Último Mês")[0].rows.push(row);
                        }
                    } else if (today.getMonth() !== logDate.getMonth() && today.getFullYear() === logDate.getFullYear()) {
                        if (newData.filter(a => a.label === "Este Ano").length === 0) {
                            newData.push({ label: "Este Ano", rows: [row], order: 4 })
                        } else {
                            newData.filter(a => a.label === "Este Ano")[0].rows.push(row);
                        }
                    } else {
                        if (newData.filter(a => a.label === "Mais Antigo").length === 0) {
                            newData.push({ label: "Mais Antigo", rows: [row], order: 5 })
                        } else {
                            newData.filter(a => a.label === "Mais Antigo")[0].rows.push(row);
                        }
                    }
                })
                setData([...newData]);
                setDataAux([...newData]);
                setLoading(false);
            }
        })
    }
    useEffect(() => {
        if (props.open) {
            refreshData();
        }
    }, [props.open, id])

    return (
        <div>
            <Popover
                open={props.open}
                anchorEl={props.anchorEl}
                onClose={props.handleClose}
                anchorReference="anchorPosition"
                anchorPosition={props.anchorPosition()}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Paper sx={{ width: 600 }}>
                    <Typography variant="h5" sx={{ width: "100%", height: "50px", textAlign: "center", color: 'white', backgroundColor: 'rgb(234, 179, 8)', m: 0, display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                        Controle de versão
                    </Typography>
                    {data.length === 0 && !loading
                        ?
                        <Typography variant="h5" sx={{ width: "100%", height: "50px", textAlign: "center", color: 'black', m: 0, display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                            Não existem logs
                        </Typography>
                        :
                        loading ?
                            <Typography variant="h5" sx={{ width: "100%", height: "50px", textAlign: "center", color: 'black', m: 0, display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                                Aguarde, carregando logs...
                            </Typography>
                            :
                            <React.Fragment>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', p: 2 }}>
                                    <Box sx={{ display: 'flex', gap: '8px' }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                                            <InputLabel htmlFor="username">Nome de usuário</InputLabel>
                                            <Select
                                                fullWidth
                                                variant='standard'
                                                value={usersFilter}
                                                multiple
                                                name="user"
                                                onChange={handleFilter}
                                                sx={{
                                                    width: 180
                                                }}
                                            >
                                                {users.map((user, index) =>
                                                    <MenuItem key={index} value={user}>{user}</MenuItem>
                                                )}
                                            </Select>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                                            <InputLabel htmlFor="date">Data</InputLabel>
                                            <TextField
                                                id="date"
                                                variant="standard"
                                                type={"date"}
                                                name="date"
                                                onChange={handleFilter}
                                                value={dataFilter || ""}
                                                sx={{
                                                    width: 180
                                                }}
                                            />
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                                            <InputLabel htmlFor="campo">Campo</InputLabel>
                                            <Select
                                                fullWidth
                                                variant='standard'
                                                value={fieldsFilter}
                                                multiple
                                                name="column"
                                                onChange={handleFilter}
                                                sx={{
                                                    width: 180
                                                }}
                                            >
                                                {fields.map((field, index) =>
                                                    <MenuItem key={index} value={field}>{field}</MenuItem>
                                                )}
                                            </Select>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', p: 2 }}>
                                    {dataAux.sort((a, b) => a.order < b.order ? -1 : 1).map(row =>

                                        <React.Fragment key={row.label} >
                                            <Typography variant="h6" sx={{ width: "100%", height: "25px", background: "#999999", textAlign: "center", color: 'black', m: 0, display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                                                {row.label}
                                            </Typography>
                                            {
                                                row.rows.sort((a, b) => a.date > b.date ? -1 : 1).map(row =>
                                                    <LogPopoverItem refreshData={refreshData} row={row} key={row.id} rowId={row.id} setNeedRefresh={props.setNeedRefresh} />
                                                )
                                            }
                                        </React.Fragment>
                                    )}

                                </Box>
                            </React.Fragment>
                    }
                </Paper>
            </Popover >
        </div >
    );
}