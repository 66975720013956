const NavigationBarLS = 'navigationBarData.'

export enum NavigationBarTypes {
    IsHidden = 'isHidden'
}

export function setIsHidden(isHidden: boolean) {
    localStorage.setItem(NavigationBarLS + NavigationBarTypes.IsHidden, JSON.stringify(isHidden))
}

export function getIsHidden(): boolean {
    return localStorage.getItem(NavigationBarLS + NavigationBarTypes.IsHidden) === "true";
}