import { RequestSignalParameter } from 'api';
import { Resource, ResourceDetails } from 'api/data/types';
import { getAsync } from '..';

const route = 'resources'

export const getResourceByIdAsync = async (resourceId: number, parameters?: RequestSignalParameter): Promise<Resource> => {
    return await getAsync({ url: `/${route}/${resourceId}`, ...parameters });
}
export const getResourceDetailsByIdAsync = async (resourceId: number, parameters?: RequestSignalParameter): Promise<ResourceDetails> => {
    return await getAsync({ url: `/${route}/${resourceId}/details`, ...parameters });
}
export const getResourcesAsync = async (parameters?: RequestSignalParameter): Promise<Resource[]> => {
    return await getAsync({ url: `/${route}`, ...parameters });
}
export const getAllResourcesDetailsAsync = async (parameters?: RequestSignalParameter): Promise<ResourceDetails[]> => {
    return await getAsync({ url: `/${route}/details`, ...parameters });
}