import { Controller, useForm } from "react-hook-form";
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { setModalState } from "redux/AppNavigationState";
import { ModalType } from "redux/AppNavigationState/types";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import NeoActionButton from "components/Buttons/NeoActionButton"
import { Resource } from "api/data/types";
import { createScenarioAsync } from "api/data";
import NeoSwitch from "components/NeoSwitch";
import DayJS from "dayjs";
import { Range, getTrackBackground } from 'react-range'
import { useEffect, useState } from "react";
import { ScenarioCriteriaData } from "redux/AppScenarioState/dataTypes";
import { Dictionary } from "utils/types";
import { ResourceCreationData, ScenarioCreationData } from "api/data/lib/scenarios/types";
import { forceExecuteTaskByTypeAsync } from "api/scheduler/lib/task";
import { notifyLoading, notifyWarning } from "utils/notification";
import { loadResourcesDetailsAsync, loadScenariosAsync, selectLastScenario, selectLastScenarioPriorities } from "redux/AppScenarioState";
import { t, Trans } from "@lingui/macro";
import { StoredProcedure } from "api/scheduler/types";


// const newDatasetSchema = yup.object().shape({
//     name: yup.string().required(),
//     description: yup.string().required(),
// })

interface ScenarioModalData {
    resource: {
        efficiency: number[]
        finiteCapacity: boolean[]
        setupRestriction: boolean[]
    },
    scenario: {
        name: string,
        periodTypeId: number,
        scenarioCategoryId: number,
        finiteProductionCapacity: boolean,
        finiteSecondaryConstraintCapacity: boolean,
        finiteStorageCapacity: boolean,
        finiteLaborCapacity: boolean,
        multiLevelSynchronism: boolean,
        startDate: Date
        frozenEndDate: Date,
        endDate: Date,
    }
    scenarioCriteria: number[]
}

function ScenarioCreateModal() {
    const dispatch = useAppDispatch();
    const { register, handleSubmit, formState: { isSubmitting }, control } = useForm<ScenarioModalData>({});

    const resources = useAppSelector(state => state.appScenarioState.resources)
    let localResourcesInput: Resource[] = []
    for (let i = 0; i < resources.length; i++) {
        const resource = resources[i];
        localResourcesInput.push({
            ...resource
        })
    }
    const [localResources, setLocalResources] = useState(localResourcesInput);

    const lastScenario = useAppSelector(selectLastScenario)
    const lastScenarioPriorities = useAppSelector(selectLastScenarioPriorities)

    const scenarioCriterias = useAppSelector(state => state.appScenarioState.scenarioCriterias)
    //TODO fazer um metodo com usecallback, algo assim
    let localScenarioCriteriaInput: ScenarioCriteriaData[] = []
    for (let i = 0; i < scenarioCriterias.length; i++) {
        const scenarioCriteria = scenarioCriterias[i];
        localScenarioCriteriaInput.push({
            ...scenarioCriteria
        })
    }
    const [localScenarioCriterias, setLocalScenarioCriterias] = useState(localScenarioCriteriaInput)
    const periodTypes = useAppSelector(state => state.appScenarioState.periodTypes)
    const scenarioCategories = useAppSelector(state => state.appScenarioState.scenarioCategories)

    //TODO Criar metodo generalista que transforma Enum em string[]
    const demandDateRange = useAppSelector((state) => state.appScenarioState.userData.demandDateRange)

    const handleOnSubmit = (scenarioModalData: ScenarioModalData) => {
        //TODO ver oir que esta submitting duas vezes!
        if (isSubmitting) return;

        const resourceCreationData: Dictionary<ResourceCreationData> = {}
        for (let i = 0; i < scenarioModalData.resource.efficiency.length; i++) {
            const localFiniteCapacity = scenarioModalData.resource.finiteCapacity[i];
            const localEfficiency = scenarioModalData.resource.efficiency[i];
            const localSetupRestriction = scenarioModalData.resource.setupRestriction[i];
            const resourceId = resources[i].id
            resourceCreationData[resourceId] = {
                efficiency: localEfficiency,
                finiteCapacity: localFiniteCapacity,
                setupRestriction: localSetupRestriction
            }
        }

        const scenarioPriorityData: Dictionary<number> = {}
        for (let i = 0; i < scenarioModalData.scenarioCriteria.length; i++) {
            const localScenarioCriteria = scenarioModalData.scenarioCriteria[i];
            const scenarioCriteriaId = localScenarioCriterias[i].id
            scenarioPriorityData[scenarioCriteriaId] = localScenarioCriteria
        }

        const localScenario = scenarioModalData.scenario
        const scenarioCreationData: ScenarioCreationData = {
            endDate: localScenario.endDate,
            startDate: localScenario.startDate,
            frozenEndDate: localScenario.frozenEndDate,
            finiteLaborCapacity: localScenario.finiteLaborCapacity,
            finiteProductionCapacity: localScenario.finiteProductionCapacity,
            finiteSecondaryConstraintCapacity: localScenario.finiteSecondaryConstraintCapacity,
            finiteStorageCapacity: localScenario.finiteStorageCapacity,
            multiLevelSynchronism: localScenario.multiLevelSynchronism,
            name: localScenario.name,
            periodTypeId: localScenario.periodTypeId,
            scenarioCategoryId: localScenario.scenarioCategoryId,
            //Backend preenche
            createdBy: ""
        }

        createScenarioAsync({
            body: {
                resourceCreationData: resourceCreationData,
                scenarioCreationData: scenarioCreationData,
                scenarioPriorityData: scenarioPriorityData
            }
        }).then(async () => {
            notifyLoading({
                message: t({ message: 'Criando novo Cenário' }),
                timer: 1500,
                action: async () => {
                    await forceExecuteTaskByTypeAsync({
                        queryParams: {
                            taskType: StoredProcedure.GenerateNeoScenario
                        }
                    })
                    dispatch(loadScenariosAsync({}))
                    dispatch(loadResourcesDetailsAsync({}))
                    onCloseModal()
                }
            })
        })
    }

    const onCloseModal = () => {
        dispatch(setModalState(ModalType.Closed))
    }
    useEffect(() => {
        const close = (e) => {
            if (e.key === 'Escape') {
                dispatch(setModalState(ModalType.Closed))
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])
    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative h-full py-6 w-full mx-auto max-w-screen-xl">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                            <h3 className="text-3xl font-semibold text-gray-600">
                                <Trans>Criar novo Cenário</Trans>
                            </h3>
                            <div className="ml-auto self-center text-black float-right">
                                <NeoActionButton form="createScriptForm"><Trans>Gerar novo cenário</Trans></NeoActionButton>
                            </div>
                            <AiOutlineCloseCircle
                                className=" self-center cursor-pointer text-gray-600 hover:text-gray-900 float-right w-8 h-8"
                                onClick={onCloseModal}
                            >
                            </AiOutlineCloseCircle>

                        </div>
                        {/*body*/}
                        <div className="relative p-6 flex-auto">
                            <form id='createScriptForm' className='flex-row w-full' onSubmit={handleSubmit(handleOnSubmit)}>
                                <div className="flex flex-row">
                                    <div style={{ width: "400px", padding: "4px" }}>
                                        <div>
                                            <h5 className='modal-header'>
                                                <Trans>Cenário</Trans>:
                                            </h5>
                                            <input
                                                className='modal-input'
                                                {...register('scenario.name')}
                                                type='text'
                                                name='scenario.name'
                                                id='scenario.name'
                                                required={true}
                                                placeholder='Nome do Cenário'
                                            />
                                        </div>

                                        <div>
                                            <h5 className='modal-header'>
                                                <Trans>Tipo de Período</Trans>:
                                            </h5>
                                            <select {...register('scenario.periodTypeId')} className="w-full rounded-lg">
                                                {periodTypes.map((row, index) =>
                                                    <option key={index} value={row.id}>{row.name}</option>
                                                )}
                                            </select>
                                        </div>

                                        <div>
                                            <h5 className='modal-header'>
                                                <Trans>Início do Plano</Trans>:
                                            </h5>
                                            <input
                                                className='modal-input'
                                                {...register('scenario.startDate')}
                                                type='date'
                                                name='scenario.startDate'
                                                id='scenario.startDate'
                                                defaultValue={DayJS(new Date()).format("YYYY-MM-DD")}
                                            />
                                            {/* {errors.description && "Descrição do Script é necessária."} */}
                                        </div>

                                        <div>
                                            <h5 className='modal-header'>
                                                <Trans>Fim do Período Congelado</Trans>:
                                            </h5>
                                            <input
                                                className='modal-input'
                                                {...register('scenario.frozenEndDate')}
                                                type='date'
                                                name='scenario.frozenEndDate'
                                                id='scenario.frozenEndDate'
                                                defaultValue={DayJS(new Date()).format("YYYY-MM-DD")}
                                            />
                                            {/* {errors.description && "Descrição do Script é necessária."} */}
                                        </div>

                                        <div>
                                            <h5 className='modal-header'>
                                                <Trans>Fim do Plano</Trans>:
                                            </h5>
                                            <input
                                                className='modal-input'
                                                {...register('scenario.endDate')}
                                                type='date'
                                                name='scenario.endDate'
                                                id='scenario.endDate'
                                                defaultValue={DayJS(demandDateRange.finalDate).format("YYYY-MM-DD")}
                                            />
                                            {/* {errors.description && "Descrição do Script é necessária."} */}
                                        </div>

                                        <div>
                                            <h5 className='modal-header'>
                                                <Trans>Tipo de Cenário</Trans>:
                                            </h5>
                                            <select {...register('scenario.scenarioCategoryId')} defaultValue={2} className="w-full rounded-lg">
                                                {scenarioCategories.map((row, index) =>
                                                    <option key={index} value={row.id}>{row.name}</option>
                                                )}
                                            </select>
                                        </div>
                                        <div className="flex-col">
                                            <div className="place-content-between">
                                                <div className="inline-block w-1/2">
                                                    <p className='text-gray-600 my-2 text-xs'>
                                                        <Trans>Capacidade Finita de Armazenagem</Trans>:
                                                    </p>
                                                    <Controller
                                                        name="scenario.finiteStorageCapacity"
                                                        control={control}
                                                        defaultValue={lastScenario.finiteStorageCapacity}
                                                        render={({ field: { onChange, value } }) =>
                                                            <div className="space-x-3 place-self-end">
                                                                <p className="inline-block"><Trans>Não</Trans></p>
                                                                <NeoSwitch className="inline-block" checked={Boolean(value)} onChange={() => onChange(!value)} />
                                                                <p className="inline-block"><Trans>Sim</Trans></p>
                                                            </div>
                                                        }
                                                    />
                                                </div>

                                                <div className="inline-block w-1/2">
                                                    <p className='text-gray-600 my-2 text-xs'>
                                                        <Trans>Capacidade Finita de Produção</Trans>:
                                                    </p>
                                                    <Controller
                                                        name="scenario.finiteProductionCapacity"
                                                        control={control}
                                                        defaultValue={lastScenario.finiteProductionCapacity}
                                                        render={({ field: { onChange, value } }) =>
                                                            <div className="space-x-3 place-self-end">
                                                                <p className="inline-block"><Trans>Não</Trans></p>
                                                                <NeoSwitch className="inline-block" checked={Boolean(value)} onChange={() => onChange(!value)} />
                                                                <p className="inline-block"><Trans>Sim</Trans></p>
                                                            </div>
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            <div className="flex place-content-between">
                                                <div className="inline-block w-1/2">
                                                    <p className='text-gray-600 my-2 text-xs'>
                                                        <Trans>Capacidade Finita de Ferramental</Trans>:
                                                    </p>
                                                    <Controller
                                                        name="scenario.finiteSecondaryConstraintCapacity"
                                                        control={control}
                                                        defaultValue={lastScenario.finiteSecondaryConstraintCapacity}
                                                        render={({ field: { onChange, value } }) =>
                                                            <div className="space-x-3 place-self-end">
                                                                <p className="inline-block"><Trans>Não</Trans></p>
                                                                <NeoSwitch className="inline-block" checked={Boolean(value)} onChange={() => onChange(!value)} />
                                                                <p className="inline-block"><Trans>Sim</Trans></p>
                                                            </div>
                                                        }
                                                    />
                                                </div>

                                                <div className="inline-block w-1/2">
                                                    <p className='text-gray-600 my-2 text-xs'>
                                                        <Trans>Capacidade Finita de Mão de Obra</Trans>:
                                                    </p>
                                                    <Controller
                                                        name="scenario.finiteLaborCapacity"
                                                        control={control}
                                                        defaultValue={lastScenario.finiteLaborCapacity}
                                                        render={({ field: { onChange, value } }) =>
                                                            <div className="space-x-3 place-self-end">
                                                                <p className="inline-block"><Trans>Não</Trans></p>
                                                                <NeoSwitch className="inline-block" checked={Boolean(value)} onChange={() => onChange(!value)} />
                                                                <p className="inline-block"><Trans>Sim</Trans></p>
                                                            </div>
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            <div className="flex place-content-between">
                                                <div className="inline-block w-1/2">
                                                    <p className='text-gray-600 my-2 text-xs'>
                                                        <Trans>Sincronismo Multinível</Trans>:
                                                    </p>
                                                    <Controller
                                                        name="scenario.multiLevelSynchronism"
                                                        control={control}
                                                        defaultValue={lastScenario.multiLevelSynchronism}
                                                        render={({ field: { onChange, value } }) =>
                                                            <div className="space-x-3 place-self-end">
                                                                <p className="inline-block"><Trans>Não</Trans></p>
                                                                <NeoSwitch className="inline-block" checked={Boolean(value)} onChange={() => onChange(!value)} />
                                                                <p className="inline-block"><Trans>Sim</Trans></p>
                                                            </div>
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ width: "760px", padding: "4px", marginLeft: "80px" }}>
                                        <div>
                                            <h5 className='modal-header'>
                                                <Trans>Critérios de prioridade</Trans>:
                                            </h5>
                                            <div style={{ border: "1px solid black", borderRadius: "15px" }}>
                                                {
                                                    localScenarioCriterias.map((scenarioCriteria, index) =>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                justifyContent: "center",
                                                                flexWrap: "nowrap",
                                                                margin: "2em"
                                                            }}
                                                            key={index}
                                                        >
                                                            <label style={{ marginRight: "15px", float: "left", whiteSpace: "nowrap", minWidth: "200px" }}>
                                                                {scenarioCriteria.name}
                                                            </label>
                                                            <Controller
                                                                name={`scenarioCriteria.${index}`}
                                                                control={control}
                                                                defaultValue={lastScenarioPriorities[index].value}
                                                                render={({ field: { onChange, value } }) =>
                                                                    <Range
                                                                        values={[value]}
                                                                        step={1}
                                                                        min={0}
                                                                        max={10}
                                                                        onChange={(rangeValues) => {
                                                                            let oldState = localScenarioCriterias;
                                                                            oldState[index].value = rangeValues[0];
                                                                            let updatedState = oldState;
                                                                            setLocalScenarioCriterias([...updatedState]);
                                                                            onChange(rangeValues[0])
                                                                        }}
                                                                        renderTrack={({ props, children }) => (
                                                                            <div
                                                                                onMouseDown={props.onMouseDown}
                                                                                onTouchStart={props.onTouchStart}
                                                                                style={{
                                                                                    ...props.style,
                                                                                    height: "36px",
                                                                                    display: "flex",
                                                                                    width: "100%",
                                                                                    borderRadius: "35px",
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    ref={props.ref}
                                                                                    style={{
                                                                                        height: "15px",
                                                                                        width: "100%",
                                                                                        borderRadius: "35px",
                                                                                        background: getTrackBackground({
                                                                                            values: [scenarioCriteria.value],
                                                                                            colors: ["#44546a", "#ccc"],
                                                                                            min: 0,
                                                                                            max: 10
                                                                                        }),
                                                                                        alignSelf: "center"
                                                                                    }}
                                                                                >
                                                                                    {children}
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        renderThumb={({ props, isDragged, value }) => (
                                                                            <div
                                                                                {...props}
                                                                                style={{
                                                                                    ...props.style,
                                                                                    height: "42px",
                                                                                    width: "42px",
                                                                                    borderRadius: "25px",
                                                                                    backgroundColor: "#fbbf24",
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    boxShadow: "0px 2px 6px #AAA"
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        height: "0px",
                                                                                        width: "0px",
                                                                                        backgroundColor: isDragged ? "#548BF4" : "#CCC"
                                                                                    }}
                                                                                />
                                                                                {value}
                                                                            </div>
                                                                        )} />
                                                                }
                                                            />
                                                        </div>
                                                    )}

                                            </div>
                                            <div style={{ border: "1px solid black", borderRadius: "15px", marginTop: "2em" }}>
                                                <table style={{ width: "95%", margin: "20px" }}>
                                                    <thead style={{ textAlign: "center" }}>
                                                        <tr>
                                                            <th style={{ textAlign: "left" }}><Trans>Recurso</Trans></th>
                                                            <th><Trans>Capacidade Finita</Trans></th>
                                                            <th><Trans>Eficiência</Trans></th>
                                                            <th><Trans>Restrição Setup</Trans></th>
                                                        </tr>

                                                    </thead>
                                                    <tbody style={{ background: "#e7e7e7" }}>
                                                        {localResources.map((resource, index) =>
                                                            <tr key={index} style={{ textAlign: "center" }}>
                                                                <td style={{ border: "1px solid white", textAlign: "left" }}>{resource.name}</td>
                                                                <td style={{ textAlign: "center", border: "1px solid white" }}>
                                                                    <Controller
                                                                        name={`resource.finiteCapacity.${index}`}
                                                                        control={control}
                                                                        defaultValue={resource.finiteCapacity}
                                                                        render={({ field: { onChange, value: isFiniteCapacity } }) =>
                                                                            <NeoSwitch
                                                                                className="inline-block"
                                                                                checked={isFiniteCapacity}
                                                                                onChange={() => {
                                                                                    let oldLocalResourcesState = localResources;
                                                                                    oldLocalResourcesState[index].finiteCapacity = !oldLocalResourcesState[index].finiteCapacity
                                                                                    onChange(oldLocalResourcesState[index].finiteCapacity)
                                                                                    setLocalResources([...oldLocalResourcesState]);
                                                                                }}
                                                                            />
                                                                        }
                                                                    />
                                                                </td>
                                                                <td style={{ border: "1px solid white" }}>
                                                                    <Controller
                                                                        name={`resource.efficiency.${index}`}
                                                                        control={control}
                                                                        defaultValue={resource.efficiency}
                                                                        render={({ field: { onChange, value: efficiency } }) =>
                                                                            <div className="select-none">
                                                                                <button
                                                                                    className="cursor-pointer"
                                                                                    onClick={(event) => {
                                                                                        event.preventDefault();
                                                                                        let oldLocalResourcesState = localResources;
                                                                                        oldLocalResourcesState[index].efficiency--;
                                                                                        onChange(oldLocalResourcesState[index].efficiency)
                                                                                        setLocalResources([...oldLocalResourcesState]);
                                                                                    }}
                                                                                >
                                                                                    [-]&nbsp;
                                                                                </button>
                                                                                {efficiency}%
                                                                                <button
                                                                                    className="cursor-pointer"
                                                                                    onClick={(event) => {
                                                                                        event.preventDefault();
                                                                                        let oldLocalResourcesState = localResources;
                                                                                        oldLocalResourcesState[index].efficiency++;
                                                                                        onChange(oldLocalResourcesState[index].efficiency)
                                                                                        setLocalResources([...oldLocalResourcesState]);
                                                                                    }}
                                                                                >
                                                                                    &nbsp;[+]
                                                                                </button>
                                                                            </div>
                                                                        }
                                                                    />
                                                                </td>
                                                                <td style={{ textAlign: "center", border: "1px solid white" }}>
                                                                    <Controller
                                                                        name={`resource.setupRestriction.${index}`}
                                                                        control={control}
                                                                        defaultValue={resource.setupRestriction}
                                                                        render={({ field: { onChange, value: setupRestriction } }) =>
                                                                            <NeoSwitch
                                                                                className="inline-block"
                                                                                checked={setupRestriction}
                                                                                onChange={() => {
                                                                                    let oldLocalResourcesState = localResources;
                                                                                    oldLocalResourcesState[index].setupRestriction = !oldLocalResourcesState[index].setupRestriction
                                                                                    onChange(oldLocalResourcesState[index].setupRestriction)
                                                                                    setLocalResources([...oldLocalResourcesState]);
                                                                                }}
                                                                            />
                                                                        }
                                                                    />
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}

export default ScenarioCreateModal
