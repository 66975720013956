import { TransProps } from '@lingui/react';
import React, { FC, HTMLInputTypeAttribute, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { UserChangeableValue } from 'utils/types'

interface Props {
    text?: string,
    textReactNode?: ReactNode
    inputValue: string | ReadonlyArray<string> | number | undefined,
    type: HTMLInputTypeAttribute,
    id: string,
    onUserChange?: (value: UserChangeableValue<any>) => void,
    onSimpleChange?: (value: React.SetStateAction<any>) => void,
    delay?: number,
    unit?: string,
    onChangeUnit?: () => void
}

function EditableInput(props: Props) {
    const { delay, text, id, inputValue, onUserChange, type, unit, onChangeUnit, onSimpleChange, textReactNode: TextReactNode } = props
    const [localValue, setLocalValue] = useState(inputValue);
    let timeoutRef = useRef<NodeJS.Timeout>();

    const updateLocalValueDelayed = useCallback((userChangeableHandler: UserChangeableValue<any>) => {
        setLocalValue(userChangeableHandler.value)
        let localDelay = delay ?? 0

        clearTimeout(timeoutRef.current);
        if (onUserChange) {
            timeoutRef.current = setTimeout(() => onUserChange?.(userChangeableHandler), localDelay)
        } else {
            timeoutRef.current = setTimeout(() => onSimpleChange?.(userChangeableHandler.value), localDelay)
        }

    }, [delay, onUserChange])

    useEffect(() => {
        if (inputValue !== localValue)
            setLocalValue(inputValue)
    }, [inputValue]);

    return (
        <div className='flex place-items-center font-medium select-none'>
            <div className='h-auto w-28 text-left text-xs'>{text ?? TextReactNode}</div>
            <input className='justify-self-end h-8 w-16 mr-2 rounded-lg text-center align-middle' value={localValue} id={id} type={type} onChange={event => {
                updateLocalValueDelayed({ value: Number(event.target.value), changedByUser: true })
            }} />
            {
                onChangeUnit ?
                    <div onClick={() => onChangeUnit()} className='h-6 w-6 cursor-pointer text-center border rounded border-black'>{unit}</div>
                    :
                    <div className='h-6 w-6 text-center'>{unit}</div>
            }
        </div>
    )
}

export default EditableInput
