import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Months } from 'api/data/types';
import { memo } from 'react';
import { areEqual, ListChildComponentProps } from 'react-window'
import { PeriodDataOld } from 'redux/types';

const GridExpandedHeaderCol = memo((props: ListChildComponentProps) => {
    const { complexPeriods, isWeekly }: { complexPeriods: PeriodDataOld[], isWeekly: boolean } = props.data;
    useLingui();

    const periodData = complexPeriods[props.index];

    //TODO Remover quando consertar o GroupNumber.
    if (periodData === undefined) return <></>

    return (

        <div key={props.index} style={props.style} className={`flex flex-col h-full w-full ${!isWeekly && 'text-center'}`}>
            <div className='flex justify-center h-1/2 items-center border-b-2'>
                <p className='font-bold content-evenly'> {Months[periodData.monthNumber]()} - {periodData.yearNumber}</p>
            </div>

            <div className={`flex flex-row h-1/2 items-center text-center`}>
                {
                    periodData.periods.map((period, index) => (
                        <p className='w-36' key={`${props.index}-${index}`}>
                            <Trans>de {period.startDay} a {(period.endDay)}</Trans>
                        </p>
                    )
                    )
                }
            </div>
        </div>
    )
}, areEqual)

export default GridExpandedHeaderCol