import DashboardCover from 'assets/images/cover/bg-menu.jpg'
import NavigationPanel from 'pages/DashboardPage/components/NavigationPanel/NavigationPanel'
import { Route, Routes } from 'react-router-dom';
import TopPanel from './components/TopPanel/TopPanel';
import PlanningPage from 'pages/PlanningPage/PlanningPage'
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectIsHidden, setIsHidden, toggleHidPanel } from 'redux/AppNavigationState';
import React, { useEffect } from 'react';
import ScenarioPage from 'pages/ScenarioPage/ScenarioPage';
import ModalRenderHandler from 'components/Modals/ModalManager';
import OccupationPage from 'pages/OccupationPage/OccupationPage';
import ComparisonPage from 'pages/ComparisonPage/ComparisonPage';
import TableEditionPage from 'pages/TableEditionPage/TableEditionPage';
import TableEditionColumnPage from 'pages/TableEditionColumnPage/TableEditionColumnPage'
import TableEditionDataPage from 'pages/TableEditionDataPage/TableEditionDataPage'
import TableEditionButtonPage from 'pages/TableEditionButtonsPage/TableEditionButtonPage';
import { initialzeStaticAppData, loadAppData } from 'api/helper';
//Rotas usuários
import UserManagePage from 'pages/UserManagementPage/UserManagePage';
import RoleManagePage from 'pages/RoleManagementPage/RoleManagePage'
import AnalyticLinkPage from 'pages/AnalyticLinkPage/AnalyticLinkPage';
import PowerBIPage from 'pages/PowerBIPage/PowerBIPage'
import PowerBIViewerPage from 'pages/PowerBIViewerPage/PowerBIViewerPage'
import 'react-toastify/dist/ReactToastify.css';
import { StockPolicyPageContaniner } from 'pages/StockPolicyPage/containers/StockPolicyPageContainer';
import HomePage from 'pages/HomePage/HomePage';



function DashboardPage() {
    const isMenuHidden = useAppSelector(selectIsHidden)
    const dispatch = useAppDispatch();
    const [user, setUser] = React.useState({})
    useEffect(() => {
        setIsHidden(isMenuHidden);
    }, [isMenuHidden])

    //TODO adicionar bool para cancelar caso chame 2 vezes.
    useEffect(() => {
        loadAppData(dispatch);
        initialzeStaticAppData(dispatch);
    }, [])


    return (
        <>
            <div className='flex flex-col w-screen h-screen z-30' style={{ backgroundImage: `url(${DashboardCover})` }}>
                <ModalRenderHandler />
                <TopPanel user={user} handleOnMenuHidMenu={() => { dispatch(toggleHidPanel()) }} isMenuCollapsed={isMenuHidden} />
                <div style={{ display: "flex", flexDirection: "row", width: "100vw", height: "100%", overflow: "auto" }}>
                    <NavigationPanel />
                    <div className='flex flex-col w-full place-content-start p-2 bg-white overflow-y-auto' style={{ width: "100%" }}>
                        {/* <ScriptButtonPanel /> */}
                        <Routes>
                            <Route path='' element={<HomePage />}></Route>
                            <Route path='home' element={ <HomePage /> }></Route>
                            <Route path='manage'>
                                {/* <Route path='global-parameters' element={} /> */}
                                <Route path='users' element={<UserManagePage />} />
                                {/* <Route path='scripts' element={<ScriptManagementPage />} /> */}
                                <Route path='roles' element={<RoleManagePage />} />
                                <Route path="powerbi" element={<PowerBIPage />} />
                            </Route>
                            {/* <Route path='integration' element={<IntegrationPage />} /> */}
                            <Route path='planning' element={<PlanningPage />} />
                            <Route path='scenarios' element={<ScenarioPage />} />
                            <Route path='analysis'>
                                <Route path='occupation' element={<OccupationPage />} />
                                <Route path='comparison-page' element={<ComparisonPage />} />
                            </Route>
                            <Route path='tableedition'
                            >
                                <Route path="" element={<TableEditionPage />} />
                                <Route path='columns/:id' element={< TableEditionColumnPage />} />
                                <Route path='data/:id' element={< TableEditionDataPage />} />
                                <Route path='buttons/:id' element={< TableEditionButtonPage />} />
                            </Route>
                            <Route path="analytics" element={<AnalyticLinkPage user={user} />} />
                            <Route path="powerbi/:id" element={<PowerBIViewerPage />} />
                            <Route path="inventory-policy" element={<StockPolicyPageContaniner />} />

                        </Routes>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DashboardPage