export enum DatasetCode {
    Test = 'Teste',
    Official = 'Oficial',
    Simulation = 'Simulação'
}

export enum ScenarioStatusCode {
    Planejado = 'Planejado',
    Firme = 'Firme',
    Previsto = 'Previsto',
    Atual = 'Atual'
}

export enum ScenarioStatusCodeById {
    Planejado = 1,
    Firme = 2,
    Previsto = 3,
    Atual = 4
}

export enum PeriodTypeCode {
    Mensal = 1,
    Semanal = 2
}
export enum HighlightedStatusType {
    // MTO = 'MTO',
    // Stockout = 'Stockout',
    Shortage = 'Falta',
    // Risk = 'Risk',
    Normal = 'Normal',
    // High = 'High',
    // Excess = 'Excess',
    // Locked = 'Locked'
}

// export enum StockStatusType {
//     Normal = 'Normal',
//     Critical = 'Crítico',
//     Excess = 'Excesso',
//     Rupture = 'Ruptura',
//     Locked = 'Travado'
// }

export enum StockStatusType {
    MTO = 'MTO',
    Stockout = 'Ruptura',
    Shortage = 'Falta',
    Risk = 'Risco',
    Low = 'Baixo',
    Normal = 'Normal',
    High = 'Alto',
    Excess = 'Excesso',
    Locked = 'Trancado'

}

export enum OldStockStatusColor {
    Normal = '77,166,81',
    Critical = '214,165,63',
    Excess = '98,115,183',
    Rupture = '153,89,89',
    Locked = '68,75,87'
}

export enum StockStatusColor {
    MTO = '#DFDEDE',
    Stockout = '#FFC4C4',
    Shortage = '#FFE190',
    Risk = '#FFFFBF',
    Low = '#FFFFBF',
    Normal = '#CEFFC6',
    High = '#B4FDFE',
    Excess = '#F1C7F7',
    Locked = '#444b57'

}

export enum StockPolicyAxesType {
    Uncertainty = 'Incerteza',
    Complexity = 'Complexidade',
    Volume = 'Volume',
}

export enum SelectCompanyMenuProps {
    All = 'Todas'
}

export enum ToolTipPlanningGridOptions {
    multipleEdit = 'Edição Multipla',
    removeFilters = 'Limpar Filtros',
    selectAll = 'Selecionar Todos',
    periodChanger = 'Mensal/Semanal',
    freezePanels = 'Congelar/Descongelar Gráfico',
    hideUnhide = 'Esconder/Mostrar Gráfico',
    occupationPage = 'Visão de Ocupação'
}
export enum ToolTipOccupationPageOptions {
    reset = 'Resetar',
    calculateWithoutAnimation = 'Calcular sem animação',
    calculateWithAnimation = 'Calcular com animação',
    pauseAnimation = 'Parar animação'

}