import { Trans, t } from "@lingui/macro";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setSecondaryStockFilter } from "redux/AppStockState";
import { useAppSelector } from "redux/hooks";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export function FilterButtonGroup() {
    const dispatch = useDispatch();
    const { selectedSecondaryFilter, stockFilterOptions, stockEditableTableValidation, } = useAppSelector((state) => state.appStockState);

    const handleFilterSelection = useCallback((secondaryFilterName, id, code) => {
        dispatch(setSecondaryStockFilter({ secondaryFilterName, id, code, }));
    }, [dispatch]
    );
    return (
        <>
            <div className="mt-5 flex flex-row lg:ml-4 lg:mt-0 flex-1 justify-end gap-4">
                {stockEditableTableValidation.isEditable === false ? (
                    <span className="hidden sm:block">
                        <button
                            type="button"
                            className={classNames(
                                selectedSecondaryFilter.id === 0
                                    ? "bg-indigo-800 text-white"
                                    : "text-gray-900 bg-white",
                                "inline-flex items-center rounded-md  px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300  "
                            )}
                            onClick={() => handleFilterSelection("Todos", 0, "Todos")}
                        >
                            <p>
                                <Trans>Todos</Trans>
                            </p>
                        </button>
                    </span>
                ) : null}
                {stockFilterOptions.map((option) => {
                    return (
                        <span className="hidden sm:block" key={option.id}>
                            <button
                                type="button"
                                className={classNames(
                                    option.id === selectedSecondaryFilter.id
                                        ? "bg-indigo-800 text-white"
                                        : "text-gray-900 bg-white",
                                    "inline-flex items-center rounded-md  px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300  "
                                )}
                                onClick={() =>
                                    handleFilterSelection(option.name, option.id, option.code)
                                }
                            >
                                <p> {option.name}</p>
                            </button>
                        </span>
                    );
                })}
            </div>
        </>
    );
}
